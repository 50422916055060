define('quantuvis-cm-spa/components/cm-timeline/menus/cm-menu-base', ['exports', 'ember', 'quantuvis-cm-spa/constants/permissions', 'quantuvis-cm-spa/constants/contract-status'], function (exports, _ember, _quantuvisCmSpaConstantsPermissions, _quantuvisCmSpaConstantsContractStatus) {
  exports['default'] = _ember['default'].Component.extend({
    PERMISSIONS: _quantuvisCmSpaConstantsPermissions.PERMISSIONS,
    workflowStepMenu: _ember['default'].inject.service(),
    dialog: _ember['default'].inject.service(),
    contractDetails: _ember['default'].inject.service(),
    permissionService: _ember['default'].inject.service(),
    init: function init() {
      this._super.apply(this, arguments);
      this.set('contract', this.get('contractDetails').getContract());
    },
    isDisabledRemoveButton: _ember['default'].computed('data', function () {
      return !this.hasPermissionToEdit() || this.get('data.events') && this.get('data.events').length > 0 || this.hasAttachments() || this.get('data.isRequired');
    }),
    editWorkflowDisabled: _ember['default'].computed('data', 'contract.status', function () {
      return !this.hasPermissionToEdit() || this.get('contract.status.id') === _quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.EXECUTED || this.get('contract.status.id') === _quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.TERMINATED;
    }),

    hasPermissionToEdit: function hasPermissionToEdit() {
      return this.get('permissionService').checkCompanyUserPermission(_quantuvisCmSpaConstantsPermissions.PERMISSIONS.ID.EDIT_WORKFLOW) && this.get('permissionService').checkCompanyUserPermission(_quantuvisCmSpaConstantsPermissions.PERMISSIONS.ID.READ_TIMELINE);
    },

    contractId: _ember['default'].computed.alias('contractDetails.contractId'),
    isViewNotesDisabled: _ember['default'].computed.empty('data.notes'),

    menuSettings: {
      horizontalPosition: 'right',
      contentWrapperClass: 'cm-timeline-item-menu-content',
      triggerWrapperClass: 'cm-timeline-item-menu-trigger',
      contentVerticalOffset: 18,
      renderInPlace: false
    },

    actions: {
      onOpen: function onOpen() {
        this.set('isMenuOpen', true);
      },
      onClose: function onClose() {
        this.set('isMenuOpen', false);
      },
      voidStep: function voidStep() {
        this.get('workflowStepMenu').voidStep(this.get('data.id'));
      },
      completeStep: function completeStep() {
        this.get('workflowStepMenu').completeStep(this.get('data.id'));
      },
      deleteStep: function deleteStep() {
        var _this = this;

        var stepName = this.get('data.name');
        var options = {
          confirmMessage: 'Are you sure you would like to delete the "' + stepName + '" step?\n         Deleting this step is permanent and irreversible'
        };

        this.get('dialog').show('dialogs/cm-dialog', 'dialog-templates/cm-confirmation', this, options).then(function () {
          return _this.get('workflowStepMenu').deleteWorkflowStep(_this.get('data.id'));
        })['catch'](function () {});
      },
      viewNotes: function viewNotes() {
        var options = {
          className: 'cm-note-dialog',
          title: 'Note',
          infoMessage: this.get('data.notes'),
          hideAcceptButton: true,
          declineText: 'Close'
        };

        this.get('dialog').show('dialogs/cm-dialog', 'dialog-templates/cm-info', this, options).then(function () {})['catch'](function () {});
      }
    },
    hasAttachments: function hasAttachments() {
      var hasAttachments = this.get('data.attachments') && this.get('data.attachments').length > 0;

      var substeps = this.get('data.substeps');

      if (substeps != null) {
        substeps.forEach(function (substep) {
          hasAttachments = hasAttachments || substep.get('attachments.length') > 0;
        });
      }

      return hasAttachments;
    }
  });
});