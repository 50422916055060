define('quantuvis-cm-spa/components/cm-timeline/cm-timeline-item/event-bodies/cm-basic', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['cm-basic'],
    timeline: _ember['default'].inject.service(),

    executor: _ember['default'].computed('data', function () {
      var executor = this.get('data.executor');

      return this.get('timeline').createPersonInfo(executor);
    })
  });
});