define('quantuvis-cm-spa/services/notifications', ['exports', 'ember'], function (exports, _ember) {

  var NUMBER_OF_PAGE = 1;
  var SIZE_OF_PAGE = 100;
  var SORT = 'createdDate,desc';

  exports['default'] = _ember['default'].Service.extend({
    store: _ember['default'].inject.service(),
    switchAccounts: _ember['default'].inject.service(),
    showOnlyMyAccounts: _ember['default'].computed.alias('switchAccounts.showMyAccounts'),
    viewAccounts: _ember['default'].computed.alias('switchAccounts.viewAccounts'),
    eventsLoading: false,
    tasksLoading: false,
    reload: new Date().getTime(),

    notificationsCount: _ember['default'].computed('reload', 'showOnlyMyAccounts', function () {
      return this.get('store').queryRecord('notifications/count-new', this.getQuery());
    }),

    notifications: _ember['default'].computed('reload', function () {
      return this.get('store').query('notification', this.getQuery(this.getPaginationOptions()));
    }),

    events: _ember['default'].computed('reload', 'showOnlyMyAccounts', function () {
      var _this = this;

      this.set('eventsLoading', true);
      var promise = this.get('store').query('notifications/event', this.getQuery(this.getPaginationOptions()));

      promise.then(function () {
        _this.set('eventsLoading', false);
      })['catch'](function () {
        _this.set('eventsLoading', false);
      });

      return promise;
    }),

    tasks: _ember['default'].computed('reload', 'showOnlyMyAccounts', function () {
      var _this2 = this;

      this.set('tasksLoading', true);
      var promise = this.get('store').query('notifications/task', this.getQuery(this.getPaginationOptions()));

      promise.then(function () {
        _this2.set('tasksLoading', false);
      })['catch'](function () {
        _this2.set('tasksLoading', false);
      });

      return promise;
    }),

    refresh: function refresh() {
      this.set('reload', this.getReloadTriggerTime());
    },

    getQuery: function getQuery(additional) {
      var query = additional || {};

      if (this.get('showOnlyMyAccounts')) {
        Object.assign(query, { companies: this.getSerialisedViewAccounts() });
      }

      return query;
    },

    getPaginationOptions: function getPaginationOptions() {
      return { page: NUMBER_OF_PAGE, size: SIZE_OF_PAGE, sort: SORT };
    },

    getSerialisedViewAccounts: function getSerialisedViewAccounts() {
      return this.get('viewAccounts').join(',');
    },

    getReloadTriggerTime: function getReloadTriggerTime() {
      return new Date().getTime();
    }
  });
});