define('quantuvis-cm-spa/components/cm-contract-details/sidebar-menus/sidebar-base-menu', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['sidebar-base-menu'],
    menuSettings: {
      horizontalPosition: 'right',
      contentVerticalOffset: 16,
      classNamesForMenu: 'v-align-content',
      renderInPlace: true,

      calculatePosition: function calculatePosition(trigger, content, destinationElement, options) {
        var _trigger$getBoundingClientRect = trigger.getBoundingClientRect();

        var top = _trigger$getBoundingClientRect.top;
        var left = _trigger$getBoundingClientRect.left;
        var height = _trigger$getBoundingClientRect.height;
        var width = _trigger$getBoundingClientRect.width;

        var _content$getBoundingClientRect = content.getBoundingClientRect();

        var contentWidth = _content$getBoundingClientRect.width;
        var horizontalPositionOffset = 0;
        var style = {
          left: left + window.pageXOffset + horizontalPositionOffset,
          top: top + window.pageYOffset + height + options.dropdown.contentVerticalOffset
        };

        if (options.horizontalPosition) {
          horizontalPositionOffset = -contentWidth + width;
        }

        if (options.renderInPlace) {
          style.left = 0 + horizontalPositionOffset, style.top = 0 + height + options.dropdown.contentVerticalOffset;
        }

        return { style: style };
      }
    }
  });
});