define('quantuvis-cm-spa/controllers/notifications', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    notifications: _ember['default'].inject.service(),

    isSpinnerVisible: false,

    notificationsList: _ember['default'].computed('notifications.notifications.isPending', function () {
      var notifications = this.get('notifications.notifications');
      var isNotificationsLoading = notifications.get('isPending');

      this.set('isNotificationsLoading', isNotificationsLoading);

      return notifications;
    }),
    title: _ember['default'].computed('notifications.showOnlyMyAccounts', function () {
      var isMyAccounts = this.get('notifications.showOnlyMyAccounts');
      var title = 'Notifications';

      return isMyAccounts ? title + ' (My Accounts)' : title;
    })
  });
});