define('quantuvis-cm-spa/routes/rates', ['exports', 'ember', 'quantuvis-cm-spa/mixins/authenticated-route-mixin'], function (exports, _ember, _quantuvisCmSpaMixinsAuthenticatedRouteMixin) {
  exports['default'] = _ember['default'].Route.extend(_quantuvisCmSpaMixinsAuthenticatedRouteMixin['default'], {
    profile: _ember['default'].inject.service(),

    model: function model() {
      return {
        profile: this.get('profile').getUserProfile()
      };
    },
    actions: {
      back: function back() {
        this.transitionTo('contracts');
      }
    }
  });
});