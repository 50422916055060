define('quantuvis-cm-spa/components/cm-contract-details/cm-contract-content', ['exports', 'ember', 'quantuvis-cm-spa/constants/permissions', 'quantuvis-cm-spa/constants/key-code'], function (exports, _ember, _quantuvisCmSpaConstantsPermissions, _quantuvisCmSpaConstantsKeyCode) {
  exports['default'] = _ember['default'].Component.extend({
    POLL_TIMEOUT: 5000,
    PERMISSIONS: _quantuvisCmSpaConstantsPermissions.PERMISSIONS,
    classNames: ['cm-contract-content'],
    store: _ember['default'].inject.service(),
    file: _ember['default'].inject.service(),
    contractDetails: _ember['default'].inject.service(),
    documentPreviewUrl: '',
    previewErrorMessage: '',
    pollForPreviewInterval: null,
    findControllerWrapper: _ember['default'].Object.create({}),

    search: _ember['default'].inject.service(),

    hideSearchBar: true,
    predefinedSearch: '',
    keyDownFunction: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.initDocumentUrl();

      var textSearch = this.get('search.textSearch');
      var searchIsActive = textSearch && !this.get('search.searchInVersion');

      this.set('hideSearchBar', !searchIsActive);
      this.set('predefinedSearch', searchIsActive ? textSearch : '');

      this.set('keyDownFunction', this.keyDown.bind(this));
      window.addEventListener('keydown', this.get('keyDownFunction'));
    },

    keyDown: function keyDown(event) {
      switch (event.keyCode) {
        case _quantuvisCmSpaConstantsKeyCode['default'].F3:
          this.set('hideSearchBar', false);
          event.preventDefault();
          break;
        case _quantuvisCmSpaConstantsKeyCode['default'].F:
          if (event.ctrlKey || event.metaKey) {
            this.set('hideSearchBar', false);
            event.preventDefault();
          }
          break;
        case _quantuvisCmSpaConstantsKeyCode['default'].ESC:
          this.set('hideSearchBar', true);
          break;
        default:
      }
    },

    initDocumentUrl: function initDocumentUrl() {
      this.set('documentPreviewUrl', null);
      this.set('previewErrorMessage', null);

      var contractFile = this.get('model.file');
      var key = contractFile ? contractFile.get('previewKey') : '';
      var version = contractFile ? contractFile.get('version') : '';
      var errorMessage = contractFile ? contractFile.get('errorMessage') : '';

      if (!this.checkFilePreview(key, errorMessage, version)) {
        this.set('pollForPreviewInterval', setInterval(this.pollForFilePreview.bind(this), this.get('POLL_TIMEOUT')));
      }
    },

    contractFileObserver: _ember['default'].observer('model.file.version', function () {
      this.initDocumentUrl();
    }),

    setPresignedUrlByKey: function setPresignedUrlByKey(key, version) {
      var _this = this;

      this.get('file').viewDocumentVersion(this.get('model.file.id'), this.get('model.id'), version).then(function () {
        _this.get('store').queryRecord('contracts/file/download-presigned-url', {
          key: key,
          version: version,
          contractId: _this.get('contractDetails.contractId')
        }).then(function (response) {
          _this.set('documentPreviewUrl', response.get('url'));
        })['catch'](function () {});
      });
    },

    pollForFilePreview: function pollForFilePreview() {
      var _this2 = this;

      this.get('store').findRecord('contracts/attachment', this.get('model.file.id'), { reload: true }).then(function (response) {
        _this2.checkFilePreview(response.get('previewKey'), response.get('errorMessage'), response.get('version'));
      })['catch'](function () {});
    },

    checkFilePreview: function checkFilePreview(previewKey, errorMessage, version) {
      this.set('previewErrorMessage', errorMessage);
      if (previewKey) {
        this.setPresignedUrlByKey(previewKey, version);
      }
      if (previewKey || errorMessage) {
        clearInterval(this.get('pollForPreviewInterval'));

        return true;
      }

      return false;
    },

    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      clearInterval(this.get('pollForPreviewInterval'));
      window.removeEventListener('keydown', this.get('keyDownFunction'));
    },

    actions: {
      createAmendment: function createAmendment() {
        this.sendAction('createAmendment');
      }
    }
  });
});