define('quantuvis-cm-spa/validations/company-contact-person', ['exports', 'ember-changeset-validations/validators', 'quantuvis-cm-spa/validations/custom-validators/custom-contact-person', 'quantuvis-cm-spa/validations/custom-validators/index'], function (exports, _emberChangesetValidationsValidators, _quantuvisCmSpaValidationsCustomValidatorsCustomContactPerson, _quantuvisCmSpaValidationsCustomValidatorsIndex) {
  exports['default'] = function (_ref) {
    var options = _ref.options;

    return {
      contactPerson: [(0, _emberChangesetValidationsValidators.validatePresence)(true), (0, _quantuvisCmSpaValidationsCustomValidatorsCustomContactPerson['default'])()],
      email: [(0, _emberChangesetValidationsValidators.validateFormat)({ type: 'email' }), (0, _emberChangesetValidationsValidators.validatePresence)(true), (0, _emberChangesetValidationsValidators.validateLength)({ max: 200 }), (0, _quantuvisCmSpaValidationsCustomValidatorsIndex.validateUnique)({ uniqueBy: 'email', options: options })],
      role: [(0, _emberChangesetValidationsValidators.validateLength)({ allowBlank: true, max: 100 })],
      phone: [(0, _emberChangesetValidationsValidators.validateLength)({ allowBlank: true, max: 30 })],
      cell: [(0, _emberChangesetValidationsValidators.validateLength)({ allowBlank: true, max: 30 })]
    };
  };
});