define('quantuvis-cm-spa/components/cm-navbar/profile-menu', ['exports', 'ember', 'quantuvis-cm-spa/constants/applications', 'quantuvis-cm-spa/constants/tooltip-messages'], function (exports, _ember, _quantuvisCmSpaConstantsApplications, _quantuvisCmSpaConstantsTooltipMessages) {
  exports['default'] = _ember['default'].Component.extend({
    contentVerticalOffset: 9,
    classNames: ['profile-menu'],
    session: _ember['default'].inject.service(),
    dialog: _ember['default'].inject.service(),
    file: _ember['default'].inject.service(),
    applicationNavigation: _ember['default'].inject.service(),
    currentFormPresenter: null,
    showDialogSpinner: false,
    TOOLTIP_MESSAGES: _quantuvisCmSpaConstantsTooltipMessages['default'],

    calculatePosition: function calculatePosition(trigger, content, destinationElement, options) {
      var _trigger$getBoundingClientRect = trigger.getBoundingClientRect();

      var top = _trigger$getBoundingClientRect.top;
      var left = _trigger$getBoundingClientRect.left;
      var height = _trigger$getBoundingClientRect.height;
      var width = _trigger$getBoundingClientRect.width;

      var _content$getBoundingClientRect = content.getBoundingClientRect();

      var contentWidth = _content$getBoundingClientRect.width;
      var style = {
        left: left - contentWidth + width,
        top: top + window.pageYOffset + height + options.dropdown.contentVerticalOffset
      };

      return { style: style };
    },

    actions: {
      logout: function logout() {
        var _this = this;

        var options = {
          title: 'Log Out',
          acceptText: 'Log out',
          declineText: 'Cancel',
          confirmMessage: '<b>Are you sure you want to log out ?</b> <br> <br>' + 'You will be asked to verify your identity the next time you log in.'
        };

        this.get('dialog').show('dialogs/cm-dialog', 'dialog-templates/cm-confirmation', this, options).then(function () {
          return _this.get('session').invalidate();
        });
      },

      accepted: function accepted() {
        var presenter = this.get('currentFormPresenter');

        this.set('showDialogSpinner', false);
        presenter.accept();
      },

      contactQuantuvis: function contactQuantuvis() {
        var options = {
          className: 'cm-contact-quantuvis',
          componentName: 'cm-profile-menu/cm-contact-quantuvis',
          title: 'Contact Quantuvis',
          acceptText: 'Send',
          declineText: 'Close',
          acceptHandler: 'contactQuantuvisAcceptHandler'
        };

        this.get('dialog').show('dialogs/cm-dialog', null, this, options);
      },

      showTermsOfUse: function showTermsOfUse() {
        this.get('file').showTermsOfUse();
      },

      showPrivacyPolicy: function showPrivacyPolicy() {
        this.get('file').showPrivacyPolicy();
      },

      contactQuantuvisAcceptHandler: function contactQuantuvisAcceptHandler(presenter) {
        this.currentFormPresenter = presenter;
        _ember['default'].$('#contact-quantuvis-form').submit();
      },

      redirectToHelpCenter: function redirectToHelpCenter() {
        this.get('applicationNavigation').redirectTo(_quantuvisCmSpaConstantsApplications['default'].HELP_CENTER_ID);
      },

      redirectToDeviceActivity: function redirectToDeviceActivity() {
        this.get('applicationNavigation').redirectTo(_quantuvisCmSpaConstantsApplications['default'].SYSTEM_ADMINISTRATION_ID, '/device-activity');
      }
    }
  });
});