define('quantuvis-cm-spa/services/upload-version', ['exports', 'ember', 'ember-changeset', 'ember-changeset-validations', 'quantuvis-cm-spa/validations/upload-version', 'quantuvis-cm-spa/constants/version-source', 'quantuvis-cm-spa/constants/upload-version-fulfilling', 'quantuvis-cm-spa/constants/contract-status', 'quantuvis-cm-spa/constants/http-statuses'], function (exports, _ember, _emberChangeset, _emberChangesetValidations, _quantuvisCmSpaValidationsUploadVersion, _quantuvisCmSpaConstantsVersionSource, _quantuvisCmSpaConstantsUploadVersionFulfilling, _quantuvisCmSpaConstantsContractStatus, _quantuvisCmSpaConstantsHttpStatuses) {
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var UPLOAD_VERSION_FULFILLING_TYPES = {
    STEP: 1,
    SUBSTEP: 2
  };

  var NOT_AVAILABLE_IN_SIGNATURE_DOCUMENTS_FULFILLING = [_quantuvisCmSpaConstantsUploadVersionFulfilling.FULFILLING_IDS.SIGNED_INTERNALLY, _quantuvisCmSpaConstantsUploadVersionFulfilling.FULFILLING_IDS.SIGNED_BY_BOTH_PARTIES];

  var NOT_AVAILABLE_IN_SIGNATURE_DOCUMENTS_OTHER_PARTY_FULFILLING = [_quantuvisCmSpaConstantsUploadVersionFulfilling.FULFILLING_IDS.SIGNED_BY_BOTH_PARTIES];

  exports['default'] = _ember['default'].Service.extend({
    dialog: _ember['default'].inject.service(),
    store: _ember['default'].inject.service(),
    contractDetails: _ember['default'].inject.service(),
    alertService: _ember['default'].inject.service(),
    timelineAttachment: _ember['default'].inject.service(),
    reviewPostfix: ' Review',
    versionSources: [{
      key: _quantuvisCmSpaConstantsVersionSource['default'].INTERNAL,
      label: 'Internal Version'
    }, {
      key: _quantuvisCmSpaConstantsVersionSource['default'].OTHER,
      label: 'Received from other company'
    }],

    confirmationMessages: _defineProperty({}, _quantuvisCmSpaConstantsUploadVersionFulfilling.FULFILLING_IDS.SIGNED_BY_BOTH_PARTIES, 'Uploading a version as \'Signed by Both Parties\' will void\n      all in progress and pending workflow steps and will execute the contract. Are you sure you want to upload?'),

    fulfillings: [],
    otherPartyFulfillings: [],
    model: {},
    changeset: {},
    contractId: null,

    showDialogSpinner: false,

    showModal: function showModal(selectedFulfillingId) {
      this.initModel();
      this.initChangeset();
      this.generateFulfillings(selectedFulfillingId);
      this.generateOtherPartyFulfillings();

      var options = {
        className: 'cm-upload-version-dialog',
        componentName: 'cm-contract-details/cm-upload-version',
        title: 'Upload New Version',
        acceptText: 'Upload',
        changeset: this.get('changeset'),
        versionSources: this.get('versionSources'),
        fulfillings: this.get('fulfillings'),
        otherPartyFulfillings: this.get('otherPartyFulfillings'),
        acceptHandler: 'uploadHandler'
      };

      this.get('dialog').show('dialogs/cm-dialog', null, this, options);
    },

    initModel: function initModel() {
      this.set('model.versionSource', this.get('versionSources.firstObject.key'));
      this.set('model.fulfilling', null);
      this.set('model.fileKey', null);
      this.set('model.fileName', null);
    },

    initChangeset: function initChangeset() {
      var changeset = new _emberChangeset['default'](this.get('model'), (0, _emberChangesetValidations['default'])(_quantuvisCmSpaValidationsUploadVersion['default']), _quantuvisCmSpaValidationsUploadVersion['default'], {
        skipValidate: true
      });

      this.set('changeset', changeset);
    },

    getFulfillingName: function getFulfillingName(fulfillings) {
      var lowerCaseWorkflowStepName = fulfillings.get('name').toLowerCase();

      return lowerCaseWorkflowStepName.includes('review') || lowerCaseWorkflowStepName.includes('overview') ? fulfillings.get('name') : fulfillings.get('name') + this.get('reviewPostfix');
    },

    getWorkflowStepInProgress: function getWorkflowStepInProgress() {
      return this.get('store').query('workflows/steps/upload-version-fulfilling', { contractId: this.get('contractDetails').get('contractId') });
    },

    setFulfillings: function setFulfillings(stepsFulfilling) {
      var fulfillings = [];

      if (stepsFulfilling) {
        fulfillings = fulfillings.concat(this.prepareFulfillings(stepsFulfilling));
      }

      if (this.get('contractDetails').isContractLead()) {
        var allowedFulfillings = undefined;

        if (this.isDocumentPartiallyExecutedInternally()) {
          allowedFulfillings = [_quantuvisCmSpaConstantsUploadVersionFulfilling.INTERNAL_FULFILLINGS.OTHER_REVIEW_FULFILLING, _quantuvisCmSpaConstantsUploadVersionFulfilling.INTERNAL_FULFILLINGS.SIGNED_BY_BOTH_FULFILLING];
        } else {
          allowedFulfillings = [_quantuvisCmSpaConstantsUploadVersionFulfilling.INTERNAL_FULFILLINGS.OTHER_REVIEW_FULFILLING, _quantuvisCmSpaConstantsUploadVersionFulfilling.INTERNAL_FULFILLINGS.SIGNED_INTERNALLY_FULFILLING, _quantuvisCmSpaConstantsUploadVersionFulfilling.INTERNAL_FULFILLINGS.SIGNED_BY_BOTH_FULFILLING];
        }

        fulfillings = fulfillings.concat(this.filterFulfillingsByDocumentStatus(allowedFulfillings, NOT_AVAILABLE_IN_SIGNATURE_DOCUMENTS_FULFILLING));
      }

      this.set('fulfillings', fulfillings);
    },

    generateOtherPartyFulfillings: function generateOtherPartyFulfillings() {
      if (this.get('contractDetails').isContractLead()) {
        var allowedFulfillings = [_quantuvisCmSpaConstantsUploadVersionFulfilling.OTHER_PARTY_FULFILLINGS.OTHER_REVIEW_FULFILLING, _quantuvisCmSpaConstantsUploadVersionFulfilling.OTHER_PARTY_FULFILLINGS.SIGNED_INTERNALLY_FULFILLING, _quantuvisCmSpaConstantsUploadVersionFulfilling.OTHER_PARTY_FULFILLINGS.SIGNED_BY_BOTH_FULFILLING];

        var fulfillings = this.filterFulfillingsByDocumentStatus(allowedFulfillings, NOT_AVAILABLE_IN_SIGNATURE_DOCUMENTS_OTHER_PARTY_FULFILLING);

        this.set('otherPartyFulfillings', fulfillings);
      }
    },

    filterFulfillingsByDocumentStatus: function filterFulfillingsByDocumentStatus(fulfillings, notAvailableFulfillings) {
      var status = this.get('contractDetails').getContract().get('status.id');

      return status !== _quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.IN_SIGNATURE ? fulfillings : fulfillings.filter(function (fulfilling) {
        return !notAvailableFulfillings.includes(fulfilling.id);
      });
    },

    prepareFulfillings: function prepareFulfillings(fulfillings) {
      var _this = this;

      var fulfillingsList = [];

      fulfillings.forEach(function (fulfilling) {
        fulfillingsList.push({
          stepId: fulfilling.get('id'),
          name: _this.getFulfillingName(fulfilling),
          typeId: fulfilling.get('typeId')
        });
      });

      return fulfillingsList;
    },

    isDocumentPartiallyExecutedInternally: function isDocumentPartiallyExecutedInternally() {
      var document = this.get('contractDetails').getContract();

      return document.get('status.id') === _quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.PARTIALLY_EXECUTED_INTERNALLY || this.get('timelineAttachment').hasSignedInternallyVersion(this.get('contractDetails').get('timelineItems'));
    },

    generateFulfillings: function generateFulfillings(selectedFulfillingId) {
      var _this2 = this;

      this.getWorkflowStepInProgress().then(function (response) {
        _this2.setFulfillings(response);
      })['catch'](function (error) {
        _ember['default'].Logger.error('Error occurred on querying workflow step in progress', error);
      }).then(function () {
        _this2.selectDefaultFulfillingById(selectedFulfillingId);
      });
    },

    uploadVersion: function uploadVersion(presenter) {
      var _this3 = this;

      var requestObj = this.createRequestObj();

      this.set('showDialogSpinner', true);
      var uploadVersion = this.get('store').createRecord('contracts/file/upload-version', requestObj);

      return uploadVersion.save().then(function () {
        var promises = [_this3.get('contractDetails').reloadContract(), _this3.get('alertService').loadAlerts()];

        return Promise.all(promises);
      }).then(function () {
        _this3.set('showDialogSpinner', false);
        presenter.accept();
        _this3.get('contractDetails').loadTimelineItems();
        _this3.get('contractDetails').loadVersions();
      })['catch'](function (error) {
        _this3.handleUploadError(presenter, error);
      });
    },

    handleUploadError: function handleUploadError(presenter, error) {
      presenter.accept();
      this.set('showDialogSpinner', false);

      var options = {
        title: 'Error',
        infoMessage: this.getErrorMessage(error.status),
        hideDeclineButton: true,
        acceptText: 'Ok',
        acceptHandler: 'uploadFailedHandler'
      };

      this.get('dialog').show('dialogs/cm-dialog', 'dialog-templates/cm-info', this, options);
    },

    getErrorMessage: function getErrorMessage(status) {
      switch (status) {
        case _quantuvisCmSpaConstantsHttpStatuses['default'].CONFLICT:
          return 'Changes cannot be saved. Please refresh the page or contact Quantuvis Administrator';

        default:
          return 'Upload failed. Please try again.';
      }
    },

    handleUpload: function handleUpload(presenter) {
      var fulfillingId = this.get('changeset.fulfilling.id');

      switch (fulfillingId) {
        case _quantuvisCmSpaConstantsUploadVersionFulfilling.FULFILLING_IDS.SIGNED_BY_BOTH_PARTIES:
          {
            var confirmationMessage = this.get('confirmationMessages')[fulfillingId];

            this.showConfirmation(confirmationMessage, presenter);
            break;
          }
        default:
          this.uploadVersion(presenter);
          break;
      }
    },

    showConfirmation: function showConfirmation(confirmMessage, presenter) {
      var _this4 = this;

      this.get('dialog').show('dialogs/cm-dialog', 'dialog-templates/cm-confirmation', null, { confirmMessage: confirmMessage }).then(function () {
        return _this4.uploadVersion(presenter);
      })['catch'](function () {});
    },

    actions: {
      uploadHandler: function uploadHandler(presenter) {
        var _this5 = this;

        var changeset = this.get('changeset');

        changeset.validate().then(function () {
          if (changeset.get('isInvalid')) {
            return Promise.reject();
          } else {
            _this5.handleUpload(presenter);
          }
        });
      },
      uploadFailedHandler: function uploadFailedHandler(presenter) {
        var _this6 = this;

        presenter.accept();

        var promises = [this.get('contractDetails').reloadContract(), this.get('alertService').loadAlerts()];

        Promise.all(promises).then(function () {
          return _this6.get('contractDetails').loadTimelineItems();
        });
      }
      // add handler for decline with cancelling upload promise!
    },

    createRequestObj: function createRequestObj() {
      var changeset = this.get('changeset');
      var versionSource = changeset.get('versionSource');
      var fileKey = changeset.get('fileKey');
      var fileName = changeset.get('fileName');
      var stepId = undefined;
      var workflowStepId = null;
      var workflowSubstepId = null;

      var fulfillingId = undefined;

      if (versionSource === _quantuvisCmSpaConstantsVersionSource['default'].INTERNAL) {
        fulfillingId = changeset.get('fulfilling.id');
        stepId = changeset.get('fulfilling.stepId');

        if (this.isSubstepReview(changeset.get('fulfilling.typeId'))) {
          workflowSubstepId = stepId;
        } else {
          workflowStepId = stepId;
        }
      } else {
        fulfillingId = changeset.get('otherPartyFulfilling.id');
      }

      return {
        workflowSubstepId: workflowSubstepId,
        workflowStepId: workflowStepId,
        contractId: this.get('contractDetails').get('contractId'),
        versionSource: versionSource,
        key: fileKey,
        fulfillingId: fulfillingId,
        name: fileName
      };
    },

    isSubstepReview: function isSubstepReview(typeId) {
      return typeId === UPLOAD_VERSION_FULFILLING_TYPES.SUBSTEP;
    },

    selectDefaultFulfillingById: function selectDefaultFulfillingById(fulfillingId) {
      if (fulfillingId) {
        var requestedFulfilling = this.get('fulfillings').find(function (fulfilling) {
          return fulfilling.stepId == fulfillingId;
        });

        if (requestedFulfilling) {
          this.get('changeset').set('fulfilling', requestedFulfilling);
        } else if (fulfillingId) {
          var defaultFulfilling = this.get('fulfillings').find(function (fulfilling) {
            return fulfilling.stepId !== null;
          });

          this.get('changeset').set('fulfilling', defaultFulfilling);
        }
      }
    }
  });
});