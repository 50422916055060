define('quantuvis-cm-spa/core-ui/components/cm-wizard/cm-timeline/item/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: ['li'],
    classNames: ['cm-step-title'],
    classNameBindings: ['isActive:active'],

    isActive: _ember['default'].computed('current', function () {
      return this.get('current').position === this.get('step').position;
    }),

    stepNumber: _ember['default'].computed('index', function () {
      return this.get('index') + 1;
    })
  });
});