define("quantuvis-cm-spa/constants/applications", ["exports"], function (exports) {
  var APPLICATIONS = {
    REBATE_NEGOTIATIONS_ID: 1,
    CONTRACT_MANAGEMENT_ID: 2,
    HELP_CENTER_ID: 3,
    SYSTEM_ADMINISTRATION_ID: 4,
    HELP_CENTER_SA_ID: 5,
    IQ_ID: 6,
    STRATEGY_MANAGEMENT_ID: 7,
    INVOICING_SYSTEM_ID: 8
  };

  exports["default"] = APPLICATIONS;
});