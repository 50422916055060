define('quantuvis-cm-spa/models/contracts/file/upload-version', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    contractId: _emberData['default'].attr('string'),
    versionSource: _emberData['default'].attr('string'),
    fulfillingId: _emberData['default'].attr('string'),
    workflowSubstepId: _emberData['default'].attr('string'),
    workflowStepId: _emberData['default'].attr('string'),
    /**
     * Uploaded file key
     */
    key: _emberData['default'].attr('string'),
    /**
     * Uploaded file name
     */
    name: _emberData['default'].attr('string')
  });
});