define('quantuvis-cm-spa/services/business-admin', ['exports', 'ember', 'quantuvis-cm-spa/constants/permissions'], function (exports, _ember, _quantuvisCmSpaConstantsPermissions) {
  exports['default'] = _ember['default'].Service.extend({
    permissionService: _ember['default'].inject.service(),

    selectedMenuRoute: '',
    sidebarMenuList: [{
      title: 'Document Types',
      icon: 'document-type',
      route: 'business-admin.document-types',
      getIsAvailable: function getIsAvailable(permissionService) {
        return permissionService.checkGlobalUserPermission(_quantuvisCmSpaConstantsPermissions.PERMISSIONS.ID.CM_ADMIN) || permissionService.checkAdminPermissionByApplication(_quantuvisCmSpaConstantsPermissions.PERMISSIONS.ID.CM_READ, _quantuvisCmSpaConstantsPermissions.PERMISSIONS.APPLICATION.CM);
      }
    }, {
      title: 'Document Templates',
      icon: 'document-template',
      route: 'business-admin.document-templates',
      getIsAvailable: function getIsAvailable(permissionService) {
        return permissionService.checkGlobalUserPermission(_quantuvisCmSpaConstantsPermissions.PERMISSIONS.ID.CM_ADMIN);
      }
    }, {
      title: 'Placeholders',
      icon: 'document-placeholder',
      route: 'business-admin.placeholders',
      getIsAvailable: function getIsAvailable(permissionService) {
        return permissionService.checkGlobalUserPermission(_quantuvisCmSpaConstantsPermissions.PERMISSIONS.ID.CM_ADMIN);
      }
    }, {
      title: 'Workflow Templates',
      icon: 'workflow-template',
      route: 'business-admin.workflow-templates',
      getIsAvailable: function getIsAvailable(permissionService) {
        return permissionService.checkGlobalUserPermission(_quantuvisCmSpaConstantsPermissions.PERMISSIONS.ID.CM_ADMIN);
      }
    }],

    availableItems: _ember['default'].computed('', function () {
      var _this = this;

      return this.get('sidebarMenuList').filter(function (item) {
        return item.getIsAvailable(_this.get('permissionService'));
      });
    }),
    init: function init() {
      var availableUsers = this.get('availableItems');

      if (availableUsers.length > 0) {
        this.set('selectedMenuRoute', availableUsers[0].route);
      }
    }
  });
});