define('quantuvis-cm-spa/mixins/scroll-to-validation-error', ['exports', 'ember'], function (exports, _ember) {

  var TOP_DISTANCE = 20;

  exports['default'] = _ember['default'].Mixin.create({
    scrollToValidationError: function scrollToValidationError() {
      var $errors = _ember['default'].$('.cm-group-error');

      if (!$errors.length) {
        return;
      }

      return setTimeout(function () {
        return _ember['default'].$([document.documentElement, document.body]).animate({ scrollTop: $errors.first().offset().top - TOP_DISTANCE });
      }, 0);
    }
  });
});