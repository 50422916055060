define('quantuvis-cm-spa/controllers/business-admin/document-templates', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    currentFormPresenter: null,
    dialog: _ember['default'].inject.service(),
    showDialogSpinner: false,
    selectedTemplate: this,

    selectFirstTemplate: function selectFirstTemplate() {
      this.set('selectedTemplate', this.get('templates.firstObject'));
    },
    buildDeleteConfirmationTemplate: function buildDeleteConfirmationTemplate(template) {
      return 'Are you sure you would like to delete\n    "' + template.get('name') + '"\n    template. This action is permanent and irreversible.';
    },

    actions: {
      handleTemplateSelected: function handleTemplateSelected(template) {
        this.set('selectedTemplate', template);
      },
      accepted: function accepted() {
        var presenter = this.get('currentFormPresenter');

        this.set('showDialogSpinner', false);
        presenter.accept();
      },
      createTemplate: function createTemplate() {
        var options = {
          className: 'cm-create-template',
          componentName: 'business-admin/cm-template/cm-create-template',
          title: 'Add Document Template',
          acceptText: 'SAVE',
          declineText: 'CANCEL',
          acceptHandler: 'createTemplateAcceptHandler'
        };

        this.get('dialog').show('dialogs/cm-dialog', null, this, options);
      },
      editTemplate: function editTemplate() {
        var options = {
          className: 'cm-edit-template',
          componentName: 'business-admin/cm-template/cm-edit-template',
          title: 'Edit Document Template',
          acceptText: 'SAVE',
          declineText: 'CANCEL',
          acceptHandler: 'editTemplateAcceptHandler'
        };

        this.get('dialog').show('dialogs/cm-dialog', null, this, options);
      },
      deleteTemplate: function deleteTemplate() {
        var _this = this;

        var template = this.get('selectedTemplate');
        var options = {
          className: 'cm-delete-template',
          title: 'Delete Template',
          acceptText: 'DELETE',
          declineText: 'CANCEL',
          confirmMessage: this.buildDeleteConfirmationTemplate(template)
        };

        this.get('dialog').show('dialogs/cm-dialog', 'dialog-templates/cm-confirmation', this, options).then(function () {
          _this.set('showDialogSpinner', true);
          template.destroyRecord().then(function () {
            _this.set('showDialogSpinner', false);
            _this.selectFirstTemplate();
          });
        })['catch'](function () {});
      },
      createTemplateAcceptHandler: function createTemplateAcceptHandler(presenter) {
        this.currentFormPresenter = presenter;
        _ember['default'].$('#create-template-form').submit();
      },
      editTemplateAcceptHandler: function editTemplateAcceptHandler(presenter) {
        this.currentFormPresenter = presenter;
        _ember['default'].$('#edit-template-form').submit();
      }
    }
  });
});