define('quantuvis-cm-spa/components/cm-timeline/cm-timeline', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    signOffPreview: _ember['default'].inject.service(),

    // TODO remane folder and component to cm-details-timeline
    classNames: ['cm-details-timeline', 'cm-view-timeline'],
    classNameBindings: ['signOffPreview.isPanelVisible:contains-sign-off-preview'],

    actions: {
      scrollToSignature: function scrollToSignature(isOpen) {
        if (!isOpen) {
          return;
        }

        var delay = 700;

        _ember['default'].$('.cm-details-content').animate({
          scrollTop: this.$('.cm-sign-off-preview').offset().top
        }, delay);
      }
    }
  });
});