define('quantuvis-cm-spa/components/cm-timeline/cm-timeline-item/cm-timeline-department-review', ['exports', 'quantuvis-cm-spa/components/cm-timeline/cm-timeline-item/cm-workflow-step-base', 'quantuvis-cm-spa/constants/workflow-step-state', 'quantuvis-cm-spa/mixins/cm-multistep-data', 'ember'], function (exports, _quantuvisCmSpaComponentsCmTimelineCmTimelineItemCmWorkflowStepBase, _quantuvisCmSpaConstantsWorkflowStepState, _quantuvisCmSpaMixinsCmMultistepData, _ember) {
  exports['default'] = _quantuvisCmSpaComponentsCmTimelineCmTimelineItemCmWorkflowStepBase['default'].extend(_quantuvisCmSpaMixinsCmMultistepData['default'], {
    classNames: ['cm-timeline-department-review'],
    classNameBindings: ['hasSubItems:cm-timeline-multi'],
    contractDetails: _ember['default'].inject.service(),

    init: function init() {
      this._super.apply(this, arguments);
      var stepStateId = this.get('data.state.id');

      if (stepStateId === _quantuvisCmSpaConstantsWorkflowStepState['default'].IN_PROGRESS) {
        this.storeResponsiblePersonsIds();
      }
    },

    storeResponsiblePersonsIds: function storeResponsiblePersonsIds() {
      var responsiblePersons = this.get('data.responsiblePersonsAccounts');
      var serviceResponsiblePersons = this.get('contractDetails.inProgressStepResponsiblePersons');

      responsiblePersons.forEach(function (person) {
        if (!serviceResponsiblePersons.includes(person.get('id'))) {
          serviceResponsiblePersons.pushObject(person.get('id'));
        }
      });
    },

    hasSubItems: _ember['default'].computed('computedSubItems', function () {
      return this.get('computedSubItems').length > 0;
    })
  });
});