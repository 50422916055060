define('quantuvis-cm-spa/core-ui/components/cm-button/component', ['exports', 'ember', 'quantuvis-cm-spa/mixins/add-test-id-attribute'], function (exports, _ember, _quantuvisCmSpaMixinsAddTestIdAttribute) {
  exports['default'] = _ember['default'].Component.extend(_quantuvisCmSpaMixinsAddTestIdAttribute['default'], {
    classNames: ['cm-button'],
    tagName: 'button',
    classNameBindings: ['buttonTypeClass'],
    attributeBindings: ['disabled'],

    buttonTypeClass: _ember['default'].computed('type', 'disabled', function () {
      var buttonType = this.get('type') ? this.get('type') : 'default';

      if (this.get('disabled')) {
        return 'cm-button-disabled';
      }

      return 'cm-button-' + buttonType;
    })
  });
});