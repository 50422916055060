define('quantuvis-cm-spa/controllers/application-error', ['exports', 'ember', 'quantuvis-cm-spa/constants/application-errors'], function (exports, _ember, _quantuvisCmSpaConstantsApplicationErrors) {

  var DEFAULT_ERROR = 500;

  exports['default'] = _ember['default'].Controller.extend({
    sentryAdapter: _ember['default'].inject.service('sentry-adapter'),

    errorObserver: _ember['default'].observer('model', function () {
      var _get = this.get('model');

      var status = _get.status;
      var payload = _get.payload;

      this.get('sentryAdapter').handleError(status, payload);

      this.redirectUserBasedOnErrorCode(status);
    }),

    redirectUserBasedOnErrorCode: function redirectUserBasedOnErrorCode(status) {
      var errorCode = _quantuvisCmSpaConstantsApplicationErrors.APPLICATION_ERRORS_ROUTES.hasOwnProperty(status) ? status : DEFAULT_ERROR;
      var redirectRoute = _quantuvisCmSpaConstantsApplicationErrors.APPLICATION_ERRORS_ROUTES[errorCode];

      this.transitionToRoute(redirectRoute);
    }
  });
});