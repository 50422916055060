define('quantuvis-cm-spa/core-ui/components/cm-phone-mask/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['cm-phone-mask'],

    actions: {
      focusOut: function focusOut(value) {
        this.sendAction('onPhoneChange', value);
        this.get('onUpdate') && this.get('onUpdate')(value);
      }
    }
  });
});