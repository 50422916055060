define('quantuvis-cm-spa/components/business-admin/cm-table', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'table',
    classNames: ['cm-table', 'cm-static-table'],

    selectedRecord: null,
    isSelectable: true,

    init: function init() {
      this._super.apply(this, arguments);
    },

    actions: {
      selectRecord: function selectRecord(record) {
        if (this.get('isSelectable')) {
          this.set('selectedRecord', record);
          this.sendAction('recordSelected', {
            record: record
          });
        }
      }
    }
  });
});