define('quantuvis-cm-spa/components/cm-filters-panel', ['exports', 'ember', 'quantuvis-cm-spa/constants/search-attributes', 'quantuvis-cm-spa/constants/tooltip-messages', 'ember-changeset-validations', 'ember-changeset', 'quantuvis-cm-spa/validations/date-range-filter', 'quantuvis-cm-spa/transforms/unix-date', 'quantuvis-cm-spa/constants/date-format', 'moment'], function (exports, _ember, _quantuvisCmSpaConstantsSearchAttributes, _quantuvisCmSpaConstantsTooltipMessages, _emberChangesetValidations, _emberChangeset, _quantuvisCmSpaValidationsDateRangeFilter, _quantuvisCmSpaTransformsUnixDate, _quantuvisCmSpaConstantsDateFormat, _moment) {

  var dateRangeValidationMessage = 'Invalid range';

  var isBlank = _ember['default'].isBlank;
  exports['default'] = _ember['default'].Component.extend({
    tagName: '',
    search: _ember['default'].inject.service(),
    date: _ember['default'].inject.service(),
    dateTransformer: null,
    animationTime: 300,
    fadeDuration: 200,
    SEARCH_ATTRIBUTES: _quantuvisCmSpaConstantsSearchAttributes['default'],
    searchCompany: '',
    searchLeads: '',
    TOOLTIP_MESSAGES: _quantuvisCmSpaConstantsTooltipMessages['default'],
    dateFilterCount: 0,

    dateRangeChangeset: null,

    dateRangeModel: {
      startDateFrom: '',
      startDateTo: '',
      endDateFrom: '',
      endDateTo: ''
    },

    attributes: _ember['default'].computed.alias('search.attributes'),

    init: function init() {
      this._super.apply(this, arguments);
      this.initChangesets();
      this.dateTransformer = new _quantuvisCmSpaTransformsUnixDate['default']();
      this.presetDatesFilter();
    },

    /**
     * Filter options by value
     * @param attribute
     * @param value
     */
    filterOptions: function filterOptions(attribute, value) {
      attribute.filterOptions(value);
    },

    initChangesets: function initChangesets() {
      var changeset = new _emberChangeset['default'](this.get('dateRangeModel'), (0, _emberChangesetValidations['default'])(_quantuvisCmSpaValidationsDateRangeFilter['default']), _quantuvisCmSpaValidationsDateRangeFilter['default'], {
        skipValidate: false
      });

      this.set('dateRangeChangeset', changeset);
    },

    presetDatesFilter: function presetDatesFilter() {
      var _this = this;

      var dateRangeModel = this.get('search.dateRangeSearch');

      Object.keys(dateRangeModel).filter(function (value) {
        return !isBlank(dateRangeModel[value]);
      }).forEach(function (value) {
        var formattedValue = _this.get('date').formatDate(+dateRangeModel[value]);

        _this.get('dateRangeChangeset').set(value, formattedValue);
      });

      this.calculateDateRangeFilterCount();
    },

    actions: {
      /**
       * Handle options selection change
       *
       * @param option
       * @param attribute
       */
      onOptionStateChange: function onOptionStateChange(option, attribute) {
        this.get('search').onChange(option, attribute);
      },

      /**
       * Reset predefined filters
       */
      onFiltersReset: function onFiltersReset() {
        this.get('search').reset();
        this.set('searchCompany', '');
        this.set('searchLeads', '');
        this.get('dateRangeChangeset').rollback();
        this.calculateDateRangeFilterCount();
      },

      /**
       * Hide panel with filters
       */
      hideSearchPanel: function hideSearchPanel() {
        var _this2 = this;

        _ember['default'].$('.cm-sidebar-filters').animate({ width: 'hide' }, this.animationTime, function () {
          _ember['default'].$('.cm-show-filters-button').fadeIn(_this2.fadeDuration);
        });
        this.sendAction('on-filters-hide');
      },

      onDateRangeChanged: function onDateRangeChanged(fi, value) {
        var oldValue = this.get('dateRangeChangeset').get(fi.name);

        fi.update(value);
        var isStartDateRangeValid = this.validateRangeFilter('startDateFrom', 'startDateTo');
        var isEndDateRangeValid = this.validateRangeFilter('endDateFrom', 'endDateTo');

        if (!isStartDateRangeValid || !isEndDateRangeValid) {
          return;
        }
        if (value === oldValue || this.get('dateRangeChangeset').get('isInvalid')) {
          return;
        }

        this.calculateDateRangeFilterCount();

        this.get('search').set('dateRangeSearch', {
          startDateFrom: this.get('dateTransformer').serialize(this.get('dateRangeChangeset.startDateFrom')),
          startDateTo: this.get('dateTransformer').serialize(this.get('dateRangeChangeset.startDateTo')),
          endDateFrom: this.get('dateTransformer').serialize(this.get('dateRangeChangeset.endDateFrom')),
          endDateTo: this.get('dateTransformer').serialize(this.get('dateRangeChangeset.endDateTo'))
        });
      }
    },

    calculateDateRangeFilterCount: function calculateDateRangeFilterCount() {
      var dateRangeChangeSet = this.get('dateRangeChangeset');
      var isStartDateSet = Boolean(dateRangeChangeSet.get('startDateFrom') || dateRangeChangeSet.get('startDateTo'));
      var isEndDateSet = Boolean(dateRangeChangeSet.get('endDateFrom') || dateRangeChangeSet.get('endDateTo'));

      this.set('dateFilterCount', Number(isStartDateSet) + Number(isEndDateSet));
    },

    validateRangeFilter: function validateRangeFilter(keyFrom, keyTo) {
      var dateFrom = (0, _moment['default'])(this.get('dateRangeChangeset').get(keyFrom), _quantuvisCmSpaConstantsDateFormat['default'].MM_DD_YYYY);
      var dateTo = (0, _moment['default'])(this.get('dateRangeChangeset').get(keyTo), _quantuvisCmSpaConstantsDateFormat['default'].MM_DD_YYYY);

      if (dateFrom.isAfter(dateTo)) {
        this.get('dateRangeChangeset').addError(keyFrom, [dateRangeValidationMessage]);
        this.get('dateRangeChangeset').addError(keyTo, [dateRangeValidationMessage]);

        return false;
      }
      this.get('dateRangeChangeset').validate(keyFrom);
      this.get('dateRangeChangeset').validate(keyTo);

      return true;
    },

    /**
     * Listener for toggle open
     */
    isOpenObserver: _ember['default'].observer('isOpen', function () {
      if (this.get('isOpen')) {
        _ember['default'].$('.cm-show-filters-button').fadeOut(this.fadeDuration);
        _ember['default'].$('.cm-sidebar-filters').animate({ width: 'show' }, this.animationTime);
      }
    })
  });
});