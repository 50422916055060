define('quantuvis-cm-spa/services/password', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Service.extend({
    store: _ember['default'].inject.service(),

    sendForgotPassword: function sendForgotPassword(email, applicationName) {
      return this.get('store').queryRecord('user-management/users/passwords/reset-password', {
        email: email,
        applicationName: applicationName
      });
    },

    sendResetPassword: function sendResetPassword(token, data) {
      return this.get('store').adapterFor('application').put('user-management/users/passwords/reset-password', token, { data: data });
    },

    getSecretQuestion: function getSecretQuestion(token) {
      return this.get('store').queryRecord('user-management/users/passwords/secret-question', { tokenId: token });
    }
  });
});