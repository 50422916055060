define('quantuvis-cm-spa/services/initiate-sign-off-first-step', ['exports', 'ember', 'moment', 'quantuvis-cm-spa/constants/attachment-status'], function (exports, _ember, _moment, _quantuvisCmSpaConstantsAttachmentStatus) {
  exports['default'] = _ember['default'].Service.extend({
    store: _ember['default'].inject.service(),

    account: {},
    documentVersion: {},

    createPartyFromCompany: function createPartyFromCompany(company) {
      var _company$getProperties = company.getProperties('id', 'name', 'type');

      var id = _company$getProperties.id;
      var name = _company$getProperties.name;
      var type = _company$getProperties.type;

      return _ember['default'].Object.create({
        id: Number(id),
        signingOrder: null,
        signatories: [],
        isChecked: false,
        isDisabled: this.checkIfCompanyDisabled(company),
        name: name,
        type: type
      });
    },

    checkIfCompanyDisabled: function checkIfCompanyDisabled(company) {
      var _getProperties = this.getProperties('documentVersion', 'account', 'contract');

      var contract = _getProperties.contract;
      var documentVersion = _getProperties.documentVersion;
      var account = _getProperties.account;

      var isSignedInternally = company.get('id') === account.get('id') && documentVersion.get('status.id') === _quantuvisCmSpaConstantsAttachmentStatus['default'].SIGNED_INTERNALLY;
      var isSignedExternally = company.get('id') === contract.get('company.id') && documentVersion.get('status.id') === _quantuvisCmSpaConstantsAttachmentStatus['default'].SIGNED_EXTERNALLY;

      return isSignedInternally || isSignedExternally;
    },

    generateParties: function generateParties(companies) {
      var _this = this;

      return companies.map(function (company) {
        return _this.createPartyFromCompany(company);
      });
    },

    generateDataToSave: function generateDataToSave(_ref) {
      var changeset = _ref.changeset;
      var originContractId = _ref.originContractId;

      var _changeset$getProperties = changeset.getProperties('documentVersion', 'dueDate', 'parties', 'subject', 'message');

      var documentVersion = _changeset$getProperties.documentVersion;
      var dueDate = _changeset$getProperties.dueDate;
      var partiesWithSignatories = _changeset$getProperties.parties;
      var subject = _changeset$getProperties.subject;
      var message = _changeset$getProperties.message;

      var company = partiesWithSignatories.find(function (_ref2) {
        var isChecked = _ref2.isChecked;
        var isDisabled = _ref2.isDisabled;
        return isChecked && !isDisabled;
      });

      var signers = company.get('signatories').map(function (signatory) {
        var _signatory$getProperties = signatory.getProperties('id', 'name', 'email', 'title');

        var id = _signatory$getProperties.id;
        var name = _signatory$getProperties.name;
        var email = _signatory$getProperties.email;
        var title = _signatory$getProperties.title;

        var signatoryObj = { name: name, email: email, title: title };

        if (id) {
          signatoryObj.userId = id;
        }

        return signatoryObj;
      });

      return {
        originAttachmentId: documentVersion.get('id'),
        dueDate: _moment['default'].utc(dueDate).valueOf(),
        originContractId: originContractId,
        signers: signers,
        emailSubject: subject,
        emailMessage: message,
        companyId: company.get('id')
      };
    }
  });
});