define('quantuvis-cm-spa/routes/accept-sign-off', ['exports', 'ember', 'rsvp', 'quantuvis-cm-spa/mixins/accept-sign-off'], function (exports, _ember, _rsvp, _quantuvisCmSpaMixinsAcceptSignOff) {
  exports['default'] = _ember['default'].Route.extend(_quantuvisCmSpaMixinsAcceptSignOff['default'], {
    initiateSignOff: _ember['default'].inject.service(),

    // signoff: Object (accept-sign-off mixin)
    // contract: Object (accept-sign-off mixin)
    // versions: Array (accept-sign-off mixin)

    model: function model() {
      var _getProperties = this.getProperties('contract', 'versions', 'signOff');

      var contract = _getProperties.contract;
      var versions = _getProperties.versions;
      var signOff = _getProperties.signOff;

      return _rsvp['default'].hash({
        signOff: signOff,
        contract: contract,
        versions: versions,
        account: this.get('store').findRecord('user-management/company', contract.get('accountId'), { reload: true }),
        lock: this.get('store').findRecord('signoff/contract-signoff/lock', contract.get('id'), { reload: true })
      });
    },

    activate: function activate() {
      var lock = this.modelFor('accept-sign-off').lock;

      if (lock.get('expirationDate')) {
        this.get('initiateSignOff').showLockNotification(lock.get('expirationDate'));
      }
    },

    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller.set('processingInitiateSignOff', false);
    }
  });
});