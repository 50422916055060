define('quantuvis-cm-spa/services/send-document', ['exports', 'ember', 'ember-changeset', 'ember-changeset-validations', 'quantuvis-cm-spa/validations/send-document', 'quantuvis-cm-spa/constants/contract-status', 'quantuvis-cm-spa/constants/permissions', 'quantuvis-cm-spa/constants/send-document-type', 'quantuvis-cm-spa/constants/attachment-status'], function (exports, _ember, _emberChangeset, _emberChangesetValidations, _quantuvisCmSpaValidationsSendDocument, _quantuvisCmSpaConstantsContractStatus, _quantuvisCmSpaConstantsPermissions, _quantuvisCmSpaConstantsSendDocumentType, _quantuvisCmSpaConstantsAttachmentStatus) {
  exports['default'] = _ember['default'].Service.extend({
    PERMISSIONS: _quantuvisCmSpaConstantsPermissions.PERMISSIONS,
    dialog: _ember['default'].inject.service(),
    contractDetails: _ember['default'].inject.service(),
    permissionService: _ember['default'].inject.service(),
    store: _ember['default'].inject.service(),
    timelineAttachment: _ember['default'].inject.service(),
    sendDialogPresenter: null,
    showDialogSpinner: false,
    fieldsToCheckChanges: ['to', 'subject', 'message'],

    sendDocumentType: [{
      key: 1,
      label: 'Internally (within your company)'
    }, {
      key: 2,
      label: 'Externally (to other company)'
    }],
    sendDocumentAvailableTypes: _ember['default'].computed('permissionService.companyId', function () {
      var _this = this;

      var allTypes = this.get('sendDocumentType');

      if (!this.get('permissionService').checkCompanyUserPermission(_quantuvisCmSpaConstantsPermissions.PERMISSIONS.ID.SEND_DOCUMENT_INTERNALLY)) {
        allTypes = allTypes.filter(function (type) {
          return type.key !== _this.get('sendDocumentType')[0].key;
        });
      }
      if (!this.get('permissionService').checkCompanyUserPermission(_quantuvisCmSpaConstantsPermissions.PERMISSIONS.ID.SEND_DOCUMENT_EXTERNALLY)) {
        allTypes = allTypes.filter(function (type) {
          return type.key !== _this.get('sendDocumentType')[1].key;
        });
      }

      return allTypes;
    }),

    documentVersions: [],

    model: {},
    changeset: {},

    showModal: function showModal() {
      this.set('showDialogSpinner', false);

      this.initModel();
      this.initChangeset();

      var options = {
        className: 'cm-send-document-dialog',
        componentName: 'cm-contract-details/cm-send-document',
        title: 'Send Document',
        acceptText: 'Send',
        documentVersion: this.get('documentVersions'),
        readyForSignOff: this.get('readyForSignOff')
      };

      this.get('dialog').show('dialogs/cm-dialog', null, this, options);
    },

    initModel: function initModel() {
      this.set('model', {
        sendDocumentType: this.get('sendDocumentAvailableTypes.firstObject.key'),
        subject: null,
        message: null,
        documentVersion: this.get('documentVersions.lastObject'),
        sendCopy: true,
        recipients: _ember['default'].A([]),
        readyForSignOff: null
      });
    },

    initChangeset: function initChangeset() {
      var changeset = new _emberChangeset['default'](this.get('model'), (0, _emberChangesetValidations['default'])(_quantuvisCmSpaValidationsSendDocument['default']), _quantuvisCmSpaValidationsSendDocument['default'], {
        skipValidate: true
      });

      this.set('changeset', changeset);
    },

    changeRecipient: function changeRecipient(_ref) {
      var index = _ref.index;
      var recipient = _ref.recipient;

      var _recipient$getProperties = recipient.getProperties('id', 'name', 'email');

      var id = _recipient$getProperties.id;
      var name = _recipient$getProperties.name;
      var email = _recipient$getProperties.email;

      var recipients = this.get('changeset.recipients');

      recipients.objectAt(index).setProperties({ id: id, name: name, email: email });
    },

    addRecipient: function addRecipient(recipient) {
      this.get('changeset.recipients').pushObject(recipient);
    },

    isAllowedToSendExternally: function isAllowedToSendExternally() {
      var documentType = this.get('contractDetails').getContract();
      var disAllowSendExternally = [_quantuvisCmSpaConstantsContractStatus['default'].EXECUTED, _quantuvisCmSpaConstantsContractStatus['default'].TERMINATED, _quantuvisCmSpaConstantsContractStatus['default'].DEACTIVATED];

      return disAllowSendExternally.includes(documentType.get('status.id'));
    },

    fillFileVersions: function fillFileVersions() {
      var _this2 = this;

      this.get('store').query('workflows/attachment-version', { contractId: this.get('contractDetails.contractId') }).then(function (versions) {
        var documentVersions = _this2.get('timelineAttachment').prepareVersionsForDropdown(versions);

        if (versions.get('length') > 0) {
          _this2.set('changeset.documentVersion', _this2.get('timelineAttachment').prepareLastVersion(versions.get('lastObject')));
        }

        _this2.set('documentVersions', documentVersions);
      });
    },

    deleteItemFromRecipientsList: function deleteItemFromRecipientsList(index) {
      this.get('changeset.recipients').removeAt(index);
    },

    resetRecipientsList: function resetRecipientsList() {
      this.set('changeset.recipients', _ember['default'].A([]));
    },

    handleLastVersion: function handleLastVersion(lastObject) {
      if (lastObject.get('status.id') === _quantuvisCmSpaConstantsAttachmentStatus['default'].NOT_SIGNED) {
        lastObject.set('name', lastObject.get('name').concat(' (current)'));
      }

      this.set('changeset.documentVersion', lastObject);
    },

    sendDocument: function sendDocument(presenter) {
      var sendDocumentRecord = this.createRecord();

      if (sendDocumentRecord) {
        this.saveRecord(sendDocumentRecord, presenter);
      }
    },

    createRecord: function createRecord() {
      var requestObj = this.getRequestObj();

      var type = this.get('model').sendDocumentType;

      switch (type) {
        case _quantuvisCmSpaConstantsSendDocumentType['default'].SEND_DOCUMENT_INTERNALLY:
          return this.get('store').createRecord('workflows/send-document/internally', requestObj);
        case _quantuvisCmSpaConstantsSendDocumentType['default'].SEND_DOCUMENT_EXTERNALLY:
          return this.get('store').createRecord('workflows/send-document/externally', requestObj);
        default:
          break;
      }
    },

    saveRecord: function saveRecord(sendDocument, presenter) {
      var _this3 = this;

      this.set('showDialogSpinner', true);

      sendDocument.save().then(function () {
        _this3.set('showDialogSpinner', false);
        _this3.get('contractDetails').loadTimelineItems();
        presenter.accept();
      })['catch'](function () {
        _this3.set('showDialogSpinner', false);
        presenter.accept();
        var options = {
          errorMessage: 'Something went wrong'
        };

        _this3.get('dialog').show('dialogs/cm-error', null, null, options);
      });
    },

    serializeReceivers: function serializeReceivers(receivers) {
      return receivers.map(function (item) {
        var personId = item.get('id');

        if (personId) {
          return { personId: personId };
        }

        return {
          userName: item.get('name'),
          email: item.get('email')
        };
      });
    },

    getRequestObj: function getRequestObj() {
      var model = this.get('model');
      var recipients = model.recipients;
      var subject = model.subject;
      var message = model.message;
      var documentVersion = model.documentVersion;
      var sendCopy = model.sendCopy;
      var readyForSignOff = model.readyForSignOff;

      var serializedReceivers = this.serializeReceivers(recipients);

      return {
        documentId: this.get('contractDetails.contractId'),
        receivers: serializedReceivers,
        subject: subject,
        message: message,
        attachmentId: documentVersion.id,
        isSendMeCopy: sendCopy,
        isReadyForSignOff: readyForSignOff
      };
    },

    actions: {
      accept: function accept(presenter) {
        var _this4 = this;

        var changeset = this.get('changeset');

        changeset.validate().then(function () {
          if (changeset.get('isInvalid')) {
            return Promise.reject();
          }
        }).then(function () {
          changeset.save();
          _this4.sendDocument(presenter);
        });
      },
      decline: function decline(presenter) {
        var options = {
          title: 'Confirmation',
          acceptText: 'Ok',
          confirmMessage: 'You have not yet saved your changes. All the data entered will be lost. Are you sure you want' + ' to navigate away?',
          acceptHandler: 'exitFromDialog',
          declineHandler: 'stayOnDialog'
        };

        if (this.isChanged()) {
          this.set('sendDialogPresenter', presenter);
          this.get('dialog').show('dialogs/cm-dialog', 'dialog-templates/cm-confirmation', this, options);
        } else {
          presenter.accept();
        }
      },

      exitFromDialog: function exitFromDialog(presenter) {
        presenter.accept();
        if (this.get('sendDialogPresenter')) {
          this.get('sendDialogPresenter').decline();
        }
      },
      stayOnDialog: function stayOnDialog(presenter) {
        presenter.accept();
      }
    },

    isChanged: function isChanged() {
      var _this5 = this;

      return this.get('changeset.changes').some(function (change) {
        return _this5.get('fieldsToCheckChanges').includes(change.key) && change.value.length > 0;
      });
    }
  });
});