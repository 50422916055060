define('quantuvis-cm-spa/core-ui/components/cm-preview-text-search/component', ['exports', 'ember', 'quantuvis-cm-spa/constants/pdf-search-command', 'quantuvis-cm-spa/constants/key-code'], function (exports, _ember, _quantuvisCmSpaConstantsPdfSearchCommand, _quantuvisCmSpaConstantsKeyCode) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['cm-preview-text-search'],

    totalMatchesCount: 0,
    currentMatchNumber: 0,

    predefinedSearch: '',
    searchKeyword: '',
    isCloseablePanel: false,
    hidden: false,

    hiddenListener: _ember['default'].observer('hidden', function () {
      var context = this;
      var searchWords = this.get('searchKeyword');

      if (this.get('hidden')) {
        searchWords = '';
      } else {
        _ember['default'].run.scheduleOnce('afterRender', this, function () {
          context.$('.preview-search-field>input').focus();
        });
      }
      this.executeFindCommand(_quantuvisCmSpaConstantsPdfSearchCommand['default'].FIND, false, searchWords);
    }),

    findControllerWrapper: {
      findController: null
    },

    findControllerInit: _ember['default'].observer('findControllerWrapper.findController', function () {
      var findController = this.get('findControllerWrapper.findController');

      findController.onUpdateResultsCount = this.findControllerOnUpdateResultsCount.bind(this);
      findController.onUpdateState = this.findControllerOnUpdateState.bind(this);
      if (this.get('predefinedSearch')) {
        this.set('searchKeyword', this.get('predefinedSearch'));
        this.executeFindCommand(_quantuvisCmSpaConstantsPdfSearchCommand['default'].FIND);
      }
    }),

    findControllerOnUpdateResultsCount: function findControllerOnUpdateResultsCount(matchCount) {
      this.set('totalMatchesCount', matchCount);
      if (matchCount && !this.get('currentMatchNumber')) {
        this.set('currentMatchNumber', 1);
      }
    },

    findControllerOnUpdateState: function findControllerOnUpdateState(state, previous, matchCount) {
      if (!matchCount) {
        this.set('currentMatchNumber', 0);
        this.set('totalMatchesCount', 0);

        return;
      }
      var currentMatchNumber = 1;
      var matchedPages = this.get('findControllerWrapper.findController').pageMatches;
      var offset = this.get('findControllerWrapper.findController').offset;

      for (var i = 0; i < offset.pageIdx; i++) {
        currentMatchNumber += matchedPages[i].length;
      }
      currentMatchNumber += offset.matchIdx;
      this.set('currentMatchNumber', currentMatchNumber);
    },

    keyDown: function keyDown(event) {
      switch (event.keyCode) {
        case _quantuvisCmSpaConstantsKeyCode['default'].ARROW_UP:
          this.actions.previousMatch.bind(this)();
          break;
        case _quantuvisCmSpaConstantsKeyCode['default'].ARROW_DOWN:
          this.actions.nextMatch.bind(this)();
          break;
        default:
      }
    },

    actions: {
      nextMatch: function nextMatch() {
        this.executeFindCommand(_quantuvisCmSpaConstantsPdfSearchCommand['default'].FINDAGAIN);
      },
      previousMatch: function previousMatch() {
        this.executeFindCommand(_quantuvisCmSpaConstantsPdfSearchCommand['default'].FINDAGAIN, true);
      },
      onEnter: function onEnter() {
        this.executeFindCommand(_quantuvisCmSpaConstantsPdfSearchCommand['default'].FIND);
      },
      close: function close() {
        this.set('hidden', true);
      }
    },

    executeFindCommand: function executeFindCommand(type) {
      var findPrevious = arguments.length <= 1 || arguments[1] === undefined ? false : arguments[1];
      var query = arguments.length <= 2 || arguments[2] === undefined ? this.get('searchKeyword') : arguments[2];

      this.get('findControllerWrapper.findController').executeCommand(type, {
        caseSensitive: false,
        findPrevious: findPrevious,
        highlightAll: true,
        phraseSearch: true,
        query: query
      });
    }
  });
});