define('quantuvis-cm-spa/components/cm-contract-details/cm-permissions', ['exports', 'ember', 'quantuvis-cm-spa/constants/applications'], function (exports, _ember, _quantuvisCmSpaConstantsApplications) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['cm-permissions'],

    sortParams: _ember['default'].Object.create({
      columnName: 'userName',
      multiplier: 1
    }),

    rawRecords: [],

    init: function init() {
      this._super.apply(this, arguments);
      this.initRawRecords();
      if (this.get('records')[0]) {
        this.set('selectedId', this.get('records')[0].id);
        this.recalculatePermissions();
      }
    },

    initRawRecords: function initRawRecords() {
      var records = [];
      var companyPermissionGrants = this.get('model.permissionGrants.companyPermissionGrants');

      this.get('model.users').forEach(function (user) {
        var departments = user.get('departments').map(function (department) {
          return department.name;
        }).join(', ');
        var presetName = companyPermissionGrants.filter(function (grant) {
          return grant.userId == user.get('id');
        }).map(function (grant) {
          return grant.presets && grant.presets.filter(function (item) {
            return item.application.id === _quantuvisCmSpaConstantsApplications['default'].CONTRACT_MANAGEMENT_ID;
          }).map(function (preset) {
            return preset.name;
          });
        }).join(', ');

        records.push({
          id: user.get('id'),
          userName: user.get('fullName'),
          departmentName: departments,
          preset: presetName || 'None(set unique permissions)'
        });
      });

      this.set('rawRecords', records);
    },

    recalculatePermissions: function recalculatePermissions() {
      var _this = this;

      var allGrants = this.get('model.permissionGrants.companyPermissionGrants');
      var appliedGrant = allGrants.find(function (grant) {
        return grant.userId == _this.get('selectedId');
      });
      var permissions = appliedGrant && appliedGrant.permissions || [];

      this.set('availablePermissions', permissions);
    },

    selectedIdObserver: _ember['default'].observer('selectedId', function () {
      this.recalculatePermissions();
    }),

    records: _ember['default'].computed('sortParams.columnName', 'sortParams.multiplier', function () {
      var rawRecords = this.get('rawRecords');
      var params = this.get('sortParams');

      rawRecords.sort(function (first, second) {
        if (first[params.columnName] > second[params.columnName]) {
          return params.multiplier;
        } else if (first[params.columnName] < second[params.columnName]) {
          return -params.multiplier;
        } else {
          return 0;
        }
      });

      return rawRecords;
    }),

    actions: {
      handleRowSelected: function handleRowSelected(id) {
        this.set('selectedId', id);
      },

      handleSortingApplied: function handleSortingApplied(columnName) {
        var defaultMultiplier = 1;
        var sortParams = this.get('sortParams');

        if (columnName === sortParams.columnName) {
          sortParams.set('multiplier', -sortParams.get('multiplier'));
        } else {
          sortParams.set('multiplier', defaultMultiplier);
          sortParams.set('columnName', columnName);
        }
      }
    }
  });
});