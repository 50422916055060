define('quantuvis-cm-spa/components/cm-contract-details/cm-alert-list', ['exports', 'ember', 'quantuvis-cm-spa/constants/contract-alert-type', 'quantuvis-cm-spa/constants/contract-status'], function (exports, _ember, _quantuvisCmSpaConstantsContractAlertType, _quantuvisCmSpaConstantsContractStatus) {
  exports['default'] = _ember['default'].Component.extend({
    alertService: _ember['default'].inject.service(),
    contractDetails: _ember['default'].inject.service(),
    date: _ember['default'].inject.service(),

    inactiveAmendmentStatuses: [_quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.EXECUTED, _quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.DEACTIVATED, _quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.TERMINATED, _quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.NEW],

    init: function init() {
      this._super.apply(this, arguments);
    },

    alertsAlias: _ember['default'].computed.alias('alertService.alerts'),

    contractAlerts: _ember['default'].computed('alertsAlias.[]', function () {
      var _this = this;

      var alerts = [];
      var prevAlert = this.get('alertService.alerts.firstObject');

      if (prevAlert) {
        (function () {
          var prevAlertState = _this.alertClassName(prevAlert);

          _this.get('alertService.alerts').forEach(function (alert, index) {
            var currentAlertState = _this.alertClassName(alert);

            if (index !== 0 && prevAlertState === currentAlertState) {
              currentAlertState += '-darken';
            }
            alerts.push(_this.createContractAlert(alert, currentAlertState));
            prevAlertState = currentAlertState;
          });
        })();
      }

      return alerts;
    }),

    createContractAlert: function createContractAlert(alert, className) {
      return {
        alert: alert,
        className: className
      };
    },

    alertClassName: function alertClassName(alert) {
      var payload = alert.get('payload');
      var suffix = '';

      switch (alert.get('type').id) {
        case _quantuvisCmSpaConstantsContractAlertType.CONTRACT_ALERT_TYPE.EXPIRED_ID:
        case _quantuvisCmSpaConstantsContractAlertType.CONTRACT_ALERT_TYPE.DEACTIVATED_ID:
        case _quantuvisCmSpaConstantsContractAlertType.CONTRACT_ALERT_TYPE.TERMINATED_ID:
          suffix = 'expired';
          break;
        case _quantuvisCmSpaConstantsContractAlertType.CONTRACT_ALERT_TYPE.PENDING_NEXT_STEP_ID:
          suffix = 'pending-step';
          break;
        case _quantuvisCmSpaConstantsContractAlertType.CONTRACT_ALERT_TYPE.PENDING_TERMINATION_ID:
          suffix = 'expiring';
          break;
        case _quantuvisCmSpaConstantsContractAlertType.CONTRACT_ALERT_TYPE.EXECUTED_ID:
          suffix = this.get('alertService').isAlertExpiring(payload.contractEndDate, alert.get('dueDate')) ? 'expiring' : 'executed';
          break;
        case _quantuvisCmSpaConstantsContractAlertType.CONTRACT_ALERT_TYPE.CONTRACT_RECEIVED_ID:
        case _quantuvisCmSpaConstantsContractAlertType.CONTRACT_ALERT_TYPE.SIGN_OFF_IN_PROGRESS_ID:
        case _quantuvisCmSpaConstantsContractAlertType.CONTRACT_ALERT_TYPE.SIGN_OFF_NOTIFICATION_ID:
        case _quantuvisCmSpaConstantsContractAlertType.CONTRACT_ALERT_TYPE.CONTRACT_LEAD_REVIEW_ID:
        case _quantuvisCmSpaConstantsContractAlertType.CONTRACT_ALERT_TYPE.MULTI_DEPARTMENT_REVIEW_ID:
        case _quantuvisCmSpaConstantsContractAlertType.CONTRACT_ALERT_TYPE.SINGLE_DEPARTMENT_REVIEW_ID:
        case _quantuvisCmSpaConstantsContractAlertType.CONTRACT_ALERT_TYPE.COMPARE_AND_COMBINE_ID:
          suffix = this.get('date').isOverdue(alert.get('dueDate')) ? 'overdue' : 'in-progress';
          break;
        default:
          return '';
      }

      return 'cm-contract-alert-' + suffix;
    },

    actions: {
      createAmendment: function createAmendment() {
        this.sendAction('createAmendment');
      },
      refreshAlerts: function refreshAlerts() {
        this.get('alertService').loadAlerts();
      }
    }
  });
});