define('quantuvis-cm-spa/components/table-components/cm-version-name', ['exports', 'ember', 'quantuvis-cm-spa/constants/permissions', 'quantuvis-cm-spa/constants/attachment-type'], function (exports, _ember, _quantuvisCmSpaConstantsPermissions, _quantuvisCmSpaConstantsAttachmentType) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['cm-contract-name', 'contract-name'],
    permissionService: _ember['default'].inject.service(),
    file: _ember['default'].inject.service(),
    search: _ember['default'].inject.service(),
    router: _ember['default'].inject.service('-routing'),
    isDisabled: _ember['default'].computed('document.current', function () {
      return !this.hasPermissionForVersion(this.get('record.company.id'));
    }),

    hasPermissionForVersion: function hasPermissionForVersion(companyId) {
      var versionPermission = this.get('document.current') ? _quantuvisCmSpaConstantsPermissions.PERMISSIONS.ID.READ_DOWNLOAD_CURRENT_VERSION : _quantuvisCmSpaConstantsPermissions.PERMISSIONS.ID.READ_DOWNLOAD_PREVIOUS_VERSIONS;

      return this.get('permissionService').checkCompanyUserPermission(versionPermission, companyId);
    },

    actions: {
      navigateToContract: function navigateToContract(record) {
        var _this = this;

        var companyId = record.get('company.id');

        if (!this.hasPermissionForVersion(companyId)) {
          return;
        }

        var hasPermission = this.get('permissionService').checkCompanyUserPermission(_quantuvisCmSpaConstantsPermissions.PERMISSIONS.ID.READ_DOCUMENT, companyId);

        if (hasPermission) {
          (function () {
            var recordId = record.get('id');

            _this.get('search').set('searchInVersion', true);
            _this.get('router').transitionTo('contract-details', [recordId]).then(function () {
              _this.get('file').previewDocument(_this.get('document.file'), _quantuvisCmSpaConstantsAttachmentType['default'].CONTRACT_ATTACHMENT, recordId, record.get('company.id'), _this.get('search').get('textSearch'));
            });
          })();
        } else {
          this.get('router').transitionTo('access-denied');
        }
      }
    }
  });
});