define('quantuvis-cm-spa/controllers/contracts', ['exports', 'ember', 'quantuvis-cm-spa/constants/tooltip-messages'], function (exports, _ember, _quantuvisCmSpaConstantsTooltipMessages) {
  exports['default'] = _ember['default'].Controller.extend({
    search: _ember['default'].inject.service(),
    file: _ember['default'].inject.service(),
    notifications: _ember['default'].inject.service(),
    TOOLTIP_MESSAGES: _quantuvisCmSpaConstantsTooltipMessages['default'],

    queryParams: [{ company: 'partnerIds' }, 'lineOfBusiness', { startDateTo: 'endDate' }, { endDateFrom: 'startDate' }],

    textSearchResults: _ember['default'].computed('search.textSearch', 'search.totalElements', 'search.totalSubElements', function () {
      var versionPostfix = this.get('search.totalSubElements') === 1 ? '' : 's';
      var documentPostfix = this.get('search.totalElements') === 1 ? '' : 's';
      var versionsTemplate = this.get('search.totalSubElements') ? ' / ' + this.get('search.totalSubElements') + ' Version' + versionPostfix : '';

      return this.get('search.textSearch') + ' (' + this.get('search.totalElements') + ' Document' + ('' + documentPostfix + versionsTemplate + ')');
    }),

    isFiltersPanelOpen: false,

    events: _ember['default'].computed.alias('notifications.events'),
    eventsLoading: _ember['default'].computed.alias('notifications.eventsLoading'),
    tasks: _ember['default'].computed.alias('notifications.tasks'),
    tasksLoading: _ember['default'].computed.alias('notifications.tasksLoading'),

    init: function init() {
      _ember['default'].run.scheduleOnce('afterRender', this, function () {
        var isQueryParametersExists = Boolean(this.company || this.lineOfBusiness || this.startDateTo || this.endDateFrom);

        this.set('isFiltersPanelOpen', isQueryParametersExists);
      });
    },

    actions: {
      showFiltersPanel: function showFiltersPanel() {
        this.toggleProperty('isFiltersPanelOpen');
      },
      onFiltersPanelHide: function onFiltersPanelHide() {
        this.toggleProperty('isFiltersPanelOpen');
      }
    }
  });
});