define('quantuvis-cm-spa/components/cm-company-contact-person/cm-person-data', ['exports', 'ember', 'quantuvis-cm-spa/constants/contact-type'], function (exports, _ember, _quantuvisCmSpaConstantsContactType) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['contact-person'],
    contactPerson: _ember['default'].inject.service(),
    isCustom: false,
    classNameBindings: ['bindContactBlockClass'],

    bindContactBlockClass: _ember['default'].computed('index', function () {
      return this.createContactBlockClassName(this.get('index'));
    }),

    isContactNameNotSelected: _ember['default'].computed('changeset.contactType', 'changeset.contactPerson', function () {
      return this.get('changeset.contactType') === _quantuvisCmSpaConstantsContactType['default'].EXISTING && !this.get('changeset.contactPerson');
    }),

    contactTypeObserver: (function () {
      var isCustom = this.get('changeset.contactType') === _quantuvisCmSpaConstantsContactType['default'].CUSTOM;

      if (isCustom === this.get('isCustom')) {
        return;
      }

      this.set('isCustom', isCustom);
      if (isCustom) {
        this.restorePreviousPerson();
      }
      this.clearContactInputFields();
    }).observes('changeset.contactType'),

    createContactBlockClassName: function createContactBlockClassName(index) {
      return 'contact-block-' + index;
    },

    currentContactIndex: _ember['default'].computed('index', function () {
      var index = this.get('index');

      return ++index;
    }),

    init: function init() {
      this._super.apply(this, arguments);
      this.preselectPersons();

      this.set('isCustom', this.get('changeset.contactType') === _quantuvisCmSpaConstantsContactType['default'].CUSTOM);
    },

    preselectPersons: function preselectPersons() {
      var personId = this.get('changeset.personId');

      if (personId) {
        var selectedPerson = this.get('contactNamesOptions').find(function (contact) {
          return contact.id === personId;
        });

        if (selectedPerson) {
          this.set('changeset.personId', null);
          this.set('changeset.id', selectedPerson.id);
          this.set('changeset.contactPerson', selectedPerson);
          this.removeChosenPersonFromOptions(selectedPerson);
        }
      }
    },

    tooltipMessages: {
      contact: 'Primary contact from the company with whom you are creating a contract'
    },

    contacts: [{
      key: _quantuvisCmSpaConstantsContactType['default'].EXISTING,
      label: 'Existing Contact'
    }, {
      key: _quantuvisCmSpaConstantsContactType['default'].CUSTOM,
      label: 'Custom Contact'
    }],

    clearContactInputFields: function clearContactInputFields() {
      this.set('changeset.id', null);
      this.set('changeset.role', null);
      this.set('changeset.email', null);
      this.set('changeset.phone', null);
      this.set('changeset.cell', null);
      this.set('changeset.contactPerson', null);
    },

    removeChosenPersonFromOptions: function removeChosenPersonFromOptions(selectedPerson) {
      this.get('contactNamesOptions').removeObject(selectedPerson);
    },

    restorePreviousPerson: function restorePreviousPerson() {
      var contactPersonService = this.get('contactPerson');

      var previousPersonId = this.get('changeset.id');

      if (previousPersonId) {
        var contactPerson = contactPersonService.getContactPersonById(this.get('companyContactPersons'), previousPersonId);

        var contactNamesOptions = this.get('contactNamesOptions');

        if (contactPerson && !contactNamesOptions.includes(contactPerson)) {
          contactNamesOptions.pushObject(contactPerson);
          this.set('contactNamesOptions', contactNamesOptions.sortBy('id'));
        }
      }
    },

    actions: {
      contactNameChanged: function contactNameChanged(updateCallback, selectedPerson) {
        updateCallback(selectedPerson);
        this.restorePreviousPerson();
        this.updateChangeset(selectedPerson);
        this.removeChosenPersonFromOptions(selectedPerson);
      },

      onDelete: function onDelete(index) {
        this.sendAction('deleteContactPerson', index);
      }
    },

    updateChangeset: function updateChangeset(person) {
      var changeset = this.get('changeset');

      changeset.set('id', person.id);
      changeset.set('role', person.role);
      changeset.set('email', person.email);
      changeset.set('phone', person.phone);
      changeset.set('cell', person.cell);
    }

  });
});