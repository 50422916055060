define('quantuvis-cm-spa/core-ui/components/cm-switch/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'label',
    classNames: ['cm-switch'],
    classNameBindings: ['value:cm-switch-checked:cm-switch-unchecked', 'switchType'],

    switchType: _ember['default'].computed('type', function () {
      var switchType = this.get('type') ? this.get('type') : 'checkbox';

      return 'type-' + switchType;
    }),

    actions: {
      onChange: function onChange(value) {
        this.sendAction('on-change', value);
        if (this.get('onUpdate')) {
          this.get('onUpdate')(value);
        }
      }
    }
  });
});