define('quantuvis-cm-spa/models/signoff', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    attachmentId: _emberData['default'].attr('number'),
    contractId: _emberData['default'].attr('number'),
    companyId: _emberData['default'].attr('number'),
    creatorAccountId: _emberData['default'].attr('number'),
    creatorId: _emberData['default'].attr('number'),
    originContractId: _emberData['default'].attr('number'),
    dueDate: _emberData['default'].attr('unix-date'),
    initiatedDate: _emberData['default'].attr('unix-timestamp'),
    status: _emberData['default'].attr(),
    signers: _emberData['default'].attr(),
    company: _emberData['default'].attr()
  });
});