define('quantuvis-cm-spa/core-ui/components/cm-dropdown-menu/cm-menu-header/component', ['exports', 'ember', 'quantuvis-cm-spa/core-ui/components/cm-dropdown-menu/cm-menu-item/component'], function (exports, _ember, _quantuvisCmSpaCoreUiComponentsCmDropdownMenuCmMenuItemComponent) {
  exports['default'] = _quantuvisCmSpaCoreUiComponentsCmDropdownMenuCmMenuItemComponent['default'].extend({
    classNames: ['cm-menu-header', 'cm-menu-item'],
    classNameBindings: ['bindCursorClass'],

    bindCursorClass: _ember['default'].computed('cursor', function () {
      switch (this.get('cursor')) {
        case 'default':
          return 'cursor-default';
        default:
          return '';
      }
    }),

    click: function click() {
      if (!this.get('forbidClick')) {
        this._super.apply(this, arguments);
      }
    }
  });
});