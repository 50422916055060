define('quantuvis-cm-spa/validations/custom-validators/amendment-start-date', ['exports', 'quantuvis-cm-spa/constants/date-format', 'moment'], function (exports, _quantuvisCmSpaConstantsDateFormat, _moment) {
  exports['default'] = validateAmendmentStartDate;

  function validateAmendmentStartDate() {
    var endDateMessage = 'Must be after linked document start date';

    return function (key, newValue, oldValue, changes, content) {
      if (!changes.linkToContract) {
        return true;
      }
      var parentContractStartDate = (0, _moment['default'])(changes.linkToContract.get('startDate')).startOf('day');
      var amendmentStartDate = newValue ? (0, _moment['default'])(newValue, _quantuvisCmSpaConstantsDateFormat['default'].MM_DD_YYYY) : dateForCheck(changes.startDate, content.startDate);

      if (parentContractStartDate.isAfter(amendmentStartDate)) {
        return endDateMessage;
      }

      return true;
    };
  }

  function dateForCheck(changesDate, contentDate) {
    return (0, _moment['default'])(changesDate ? changesDate : contentDate, _quantuvisCmSpaConstantsDateFormat['default'].MM_DD_YYYY);
  }
});