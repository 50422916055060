define('quantuvis-cm-spa/constants/signature-status', ['exports'], function (exports) {
  var _SIGNATURE_STATUS_NAMES;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var SIGNATURE_STATUS = {
    PENDING: 1,
    IN_PROGRESS: 2,
    SIGNED: 3,
    CANCELLED: 4,
    DECLINED: 5,
    EXPIRED: 6,
    NOT_RECEIVED: 7
  };

  var SIGNATURE_STATUS_NAMES = (_SIGNATURE_STATUS_NAMES = {}, _defineProperty(_SIGNATURE_STATUS_NAMES, SIGNATURE_STATUS.PENDING, 'In Signature'), _defineProperty(_SIGNATURE_STATUS_NAMES, SIGNATURE_STATUS.IN_PROGRESS, 'In Signature'), _defineProperty(_SIGNATURE_STATUS_NAMES, SIGNATURE_STATUS.SIGNED, 'Signed'), _defineProperty(_SIGNATURE_STATUS_NAMES, SIGNATURE_STATUS.CANCELLED, 'Cancelled'), _defineProperty(_SIGNATURE_STATUS_NAMES, SIGNATURE_STATUS.DECLINED, 'Declined'), _defineProperty(_SIGNATURE_STATUS_NAMES, SIGNATURE_STATUS.EXPIRED, 'Overdued'), _defineProperty(_SIGNATURE_STATUS_NAMES, SIGNATURE_STATUS.NOT_RECEIVED, 'In Signature'), _SIGNATURE_STATUS_NAMES);

  exports.SIGNATURE_STATUS = SIGNATURE_STATUS;
  exports.SIGNATURE_STATUS_NAMES = SIGNATURE_STATUS_NAMES;
});