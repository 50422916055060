define('quantuvis-cm-spa/validations/custom-validators/date-frame', ['exports', 'moment'], function (exports, _moment) {
  exports['default'] = dateFrame;

  var ERROR = 'You can\'t terminate contract before start date or after it end';

  function dateFrame(contract) {
    var _contract$getProperties = contract.getProperties('startDate', 'endDate');

    var startDate = _contract$getProperties.startDate;
    var endDate = _contract$getProperties.endDate;

    return function (key, newValue) {
      var utcStartDate = _moment['default'].utc(startDate);
      var utcEndDate = _moment['default'].utc(endDate);
      var utcNewValue = _moment['default'].utc(newValue);
      var isAfterStartDate = utcNewValue.isAfter(utcStartDate, 'day');
      var isBeforeEndDate = utcNewValue.isBefore(utcEndDate, 'day');
      var isSameDate = utcNewValue.isSame(utcStartDate, 'day') || utcNewValue.isSame(utcEndDate, 'day');

      if (isAfterStartDate && isBeforeEndDate || isSameDate) {
        return true;
      }

      return ERROR;
    };
  }
});