define('quantuvis-cm-spa/models/workflows/template', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    name: _emberData['default'].attr('string'),
    description: _emberData['default'].attr('string'),
    isSystem: _emberData['default'].attr('boolean'),
    steps: _emberData['default'].hasMany('workflows/template/step', { inverse: null, async: false }),

    positionSortingAsc: ['position:asc'],
    stepsSortedByPosition: _ember['default'].computed.sort('steps', 'positionSortingAsc')
  });
});