define('quantuvis-cm-spa/services/dialog-manager', ['exports', 'ember', 'quantuvis-cm-spa/constants/error-messages'], function (exports, _ember, _quantuvisCmSpaConstantsErrorMessages) {
  exports['default'] = _ember['default'].Service.extend({
    dialog: _ember['default'].inject.service(),

    closeOpenDialogs: function closeOpenDialogs() {
      var dialogs = this.get('dialog.dialogs');
      var areOpenDialogs = dialogs && dialogs.length;

      if (areOpenDialogs) {
        dialogs.filter(function (_ref) {
          var alwaysOpen = _ref.presenter.alwaysOpen;
          return !alwaysOpen;
        }).forEach(function (_ref2) {
          var presenter = _ref2.presenter;
          return presenter.decline();
        });
      }
    },

    showDefaultError: function showDefaultError() {
      var defaultId = 'cm-dialog-default-error';
      var dialogs = this.get('dialog.dialogs');
      var isDefaultErrorOpen = dialogs.find(function (_ref3) {
        var id = _ref3.id;
        return id === defaultId;
      });

      if (isDefaultErrorOpen) {
        return;
      }

      var options = {
        defaultId: defaultId,
        errorMessage: _quantuvisCmSpaConstantsErrorMessages.DEFAULT_ERROR_MESSAGE
      };

      this.get('dialog').show('dialogs/cm-error', null, null, options);
    },

    showDialogConfirmation: function showDialogConfirmation(confirmMessage) {
      var confirmationOptions = {
        confirmMessage: confirmMessage
      };

      return this.get('dialog').show('dialogs/cm-dialog', 'dialog-templates/cm-confirmation', this, confirmationOptions);
    }
  });
});