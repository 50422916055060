define('quantuvis-cm-spa/constants/application-errors', ['exports', 'quantuvis-cm-spa/constants/http-statuses'], function (exports, _quantuvisCmSpaConstantsHttpStatuses) {
  var _APPLICATION_ERRORS_ROUTES;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var APPLICATION_ERRORS = {
    ERROR_OCCURRED: {
      title: 'Error Occurred',
      component: 'application-error/error-occurred'
    },

    NOT_FOUND: {
      title: 'Not Found'
    },

    ACCESS_DENIED: {
      title: 'Access denied'
    }
  };

  exports.APPLICATION_ERRORS = APPLICATION_ERRORS;
  var APPLICATION_ERRORS_ROUTES = (_APPLICATION_ERRORS_ROUTES = {}, _defineProperty(_APPLICATION_ERRORS_ROUTES, _quantuvisCmSpaConstantsHttpStatuses['default'].NOT_FOUND, 'not-found'), _defineProperty(_APPLICATION_ERRORS_ROUTES, _quantuvisCmSpaConstantsHttpStatuses['default'].FORBIDDEN, 'access-denied'), _defineProperty(_APPLICATION_ERRORS_ROUTES, _quantuvisCmSpaConstantsHttpStatuses['default'].INTERNAL_SERVER_ERROR, 'error-occurred'), _APPLICATION_ERRORS_ROUTES);
  exports.APPLICATION_ERRORS_ROUTES = APPLICATION_ERRORS_ROUTES;
});