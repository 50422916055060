define('quantuvis-cm-spa/constants/upload-version-fulfilling', ['exports'], function (exports) {
  var FULFILLING_IDS = {
    OTHER_REVIEW: 1,
    SIGNED_INTERNALLY: 2,
    SIGNED_BY_BOTH_PARTIES: 3
  };

  var INTERNAL_FULFILLINGS = {
    OTHER_REVIEW_FULFILLING: {
      id: FULFILLING_IDS.OTHER_REVIEW,
      name: 'Other Review'
    },

    SIGNED_INTERNALLY_FULFILLING: {
      id: FULFILLING_IDS.SIGNED_INTERNALLY,
      name: 'Signed Internally'
    },

    SIGNED_BY_BOTH_FULFILLING: {
      id: FULFILLING_IDS.SIGNED_BY_BOTH_PARTIES,
      name: 'Signed by Both Parties'
    }
  };

  var OTHER_PARTY_FULFILLINGS = {
    OTHER_REVIEW_FULFILLING: {
      id: FULFILLING_IDS.OTHER_REVIEW,
      name: 'New Version'
    },

    SIGNED_INTERNALLY_FULFILLING: {
      id: FULFILLING_IDS.SIGNED_INTERNALLY,
      name: 'Signed Externally'
    },

    SIGNED_BY_BOTH_FULFILLING: {
      id: FULFILLING_IDS.SIGNED_BY_BOTH_PARTIES,
      name: 'Signed by Both Parties'
    }
  };

  exports.FULFILLING_IDS = FULFILLING_IDS;
  exports.INTERNAL_FULFILLINGS = INTERNAL_FULFILLINGS;
  exports.OTHER_PARTY_FULFILLINGS = OTHER_PARTY_FULFILLINGS;
});