define('quantuvis-cm-spa/services/unique-document-validator', ['exports', 'ember'], function (exports, _ember) {

  var CONTRACT_ID_IS_NOT_UNIQUE = 'Contract ID is not unique';
  var REQUIRED_FIELD_MESSAGE = 'Required';

  exports['default'] = _ember['default'].Service.extend({
    store: _ember['default'].inject.service('store'),
    isLoading: false,

    validateUniqueness: function validateUniqueness(documentId) {
      var _this = this;

      return function (key, newValue) {

        if (newValue) {
          return _this.contractLookupRequest(documentId, newValue);
        }

        return REQUIRED_FIELD_MESSAGE;
      };
    },

    contractLookupRequest: function contractLookupRequest(documentId, newValue) {
      var _this2 = this;

      this.set('isLoading', true);

      return new _ember['default'].RSVP.Promise(function (resolve) {
        _this2.get('store').queryRecord('contracts/lookup', { number: newValue }).then(function (data) {
          _this2.set('isLoading', false);
          if (data.get('__isGeneratedPrimaryID') || documentId && documentId == data.id) {
            resolve(true);
          }
          resolve(CONTRACT_ID_IS_NOT_UNIQUE);
        })['catch'](function () {
          _this2.set('isLoading', false);
          resolve(CONTRACT_ID_IS_NOT_UNIQUE);
        });
      });
    }
  });
});