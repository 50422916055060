define('quantuvis-cm-spa/components/cm-workflow-step/cm-department', ['exports', 'ember', 'ember-changeset', 'ember-changeset-validations', 'quantuvis-cm-spa/validations/workflow-department', 'moment', 'quantuvis-cm-spa/constants/date-format', 'quantuvis-cm-spa/constants/tooltip-messages'], function (exports, _ember, _emberChangeset, _emberChangesetValidations, _quantuvisCmSpaValidationsWorkflowDepartment, _moment, _quantuvisCmSpaConstantsDateFormat, _quantuvisCmSpaConstantsTooltipMessages) {
  exports['default'] = _ember['default'].Component.extend({
    // RENAME COMPONENT TO CM-STEP-DEPARTMENT!!!
    // becouse it is hard to find component and styles with different classnames!!!!
    classNames: ['cm-step-department'],
    workflow: _ember['default'].inject.service(),
    animationTime: 400,
    lastSelected: null,
    TOOLTIP_MESSAGES: _quantuvisCmSpaConstantsTooltipMessages['default'],
    events: [],
    responsiblePersons: [],
    leads: [],
    departmentNumber: _ember['default'].computed('position', function () {
      var position = this.get('position');

      return ++position;
    }),

    isDepartmentNotSelected: _ember['default'].computed('changeset.department', function () {
      return _ember['default'].isEmpty(this.get('changeset.department'));
    }),

    departments: _ember['default'].computed('selectedDepartments', 'companyDepartments', function () {
      var _this = this;

      return this.get('companyDepartments').filter(function (department) {
        return !_this.get('selectedDepartments').includes(parseInt(department.id));
      });
    }),

    isAllowedToDelete: _ember['default'].computed('departmentLength', function () {
      if (this.get('showDeleteButton')) {
        return this.get('departmentLength') > 1 && !this.get('hasAttachments');
      }

      return false;
    }),

    isDepartmentControlDisabled: _ember['default'].computed('hasAttachments', 'events', function () {
      return this.get('hasAttachments') || !_ember['default'].isEmpty(this.get('events'));
    }),

    hasAttachments: _ember['default'].computed.notEmpty('department.attachments'),

    isMultiDepartmentStep: _ember['default'].computed('departmentLength', function () {
      return this.get('departmentLength') > 1;
    }),

    event: _ember['default'].computed('events', function () {
      var _this2 = this;

      var event = null;

      if (this.get('events')) {
        event = this.get('events').find(function (event) {
          var positionAdjustment = event.position - _this2.get('department').get('position');

          return positionAdjustment == 1;
        });
      }

      return event;
    }),

    init: function init() {
      this._super.apply(this, arguments);
      this.initSelectedLeads();
      this.initChangeset();
      this.initSelectedDepartments();
      this.setResponsiblePersons();
    },

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.$().slideDown(this.get('animate') ? this.animationTime : 0);
    },

    willDestroyElement: function willDestroyElement() {
      var clone = this.$().clone();

      this.$().parent().append(clone);

      clone.slideUp(this.animationTime, function () {
        clone.remove();
      });
    },

    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);
      this.get('changesetMap')['delete'](this.elementId);
      this.unregisterDepartmentFromSelected();
    },

    unregisterDepartmentFromSelected: function unregisterDepartmentFromSelected() {
      var department = this.get('changeset').get('department');

      /**
       * If department selected in control
       */
      if (department) {
        var selected = this.get('selectedDepartments');
        var index = selected.indexOf(parseInt(department.get('id')));

        /**
         * If element present in list of selected elements
         * then remove this element by index and refresh selected list
         */
        if (index !== -1) {
          selected.splice(index, 1);
          this.set('selectedDepartments', selected.slice());
        }
      }
    },

    /**
     * @todo add tests for initChangeset method
     */
    initChangeset: function initChangeset() {
      var validators = this.get('department').get('isContractLeadReviewSubstep') ? _quantuvisCmSpaValidationsWorkflowDepartment.ContractLeadSubstepValidator : _quantuvisCmSpaValidationsWorkflowDepartment.DepartmentSubstepValidator;

      var department = this.get('department');

      if (this.get('expandOnErrors')) {
        department.set('expandOnInvalid', this.expandOnInvalid.bind(this));
      }

      this.prepareModelDatesProperties();
      var changeset = new _emberChangeset['default'](department, (0, _emberChangesetValidations['default'])(validators), validators, {
        skipValidate: true
      });

      this.set('changeset', changeset);
      this.get('changesetMap').set(this.elementId, changeset);
    },

    expandOnInvalid: function expandOnInvalid() {
      this.sendAction('expandOnInvalid');
    },

    /**
     * @todo add tests for initChangeset method
     */
    prepareModelDatesProperties: function prepareModelDatesProperties() {
      var date = this.get('department.dueDate'),
          isDateValid = (0, _moment['default'])(date).isValid();

      if (date && isDateValid) {
        this.set('department.dueDate', (0, _moment['default'])(date).format(_quantuvisCmSpaConstantsDateFormat['default'].MM_DD_YYYY));
      }
    },

    /**
     * Set Responsible Persons for Department
     */
    setResponsiblePersons: function setResponsiblePersons() {
      var department = this.get('changeset').get('department');

      if (department) {
        var allPersons = this.get('leads');
        var responsiblePersons = allPersons.filter(function (item) {
          return item.get('departments').find(function (item) {
            return item.id == department.id;
          });
        });

        this.set('responsiblePersons', responsiblePersons);
      }
    },

    actions: {
      submit: function submit() {},
      /**
       * @todo add tests for onDelete action
       */
      onDelete: function onDelete() {
        this.sendAction('deleteDepartmentSubstep', { position: this.get('position') });
      }
    },

    /**
     * Reset Leads when department changed
     *
     * @param control
     * @param department
     * @todo Add tests for departmentChanged
     */
    departmentChanged: function departmentChanged(control, department) {
      control.update(department);

      var selectedList = this.get('selectedDepartments');
      var index = selectedList.indexOf(this.get('lastSelected'));

      if (index !== -1) {
        selectedList.splice(index, 1);
      }

      var id = parseInt(department.id);

      this.set('lastSelected', id);

      if (selectedList.indexOf(id) == -1) {
        selectedList.push(id);
        this.set('selectedDepartments', selectedList.slice());
      }

      this.get('changeset').set('leads', []);
      this.setResponsiblePersons();
    },

    /**
     * @todo Add tests for initSelectedLeads
     */
    initSelectedLeads: function initSelectedLeads() {
      if (this.get('department.leads') && this.get('department.leads').length > 0) {
        return;
      }
      var leads = this.get('department').get('responsiblePersons').map(function (responsiblePerson) {
        return responsiblePerson.userId;
      });

      var departmentLeads = this.get('leads').filter(function (lead) {
        return leads.includes(parseInt(lead.get('id')));
      });

      this.get('department').set('leads', departmentLeads);
    },

    /**
     * Replace ID with real objects
     * @todo remove after spring feign integration in Workflow Service
     */
    initSelectedDepartments: function initSelectedDepartments() {
      var departmentId = this.get('department').get('departmentId');
      var department = this.get('companyDepartments').find(function (department) {
        return department.get('id') == departmentId;
      });

      if (department) {
        this.get('department').set('department', department);
        this.set('lastSelected', parseInt(department.get('id')));
      }
    }
  });
});