define('quantuvis-cm-spa/helpers/prevent-events', ['exports', 'ember'], function (exports, _ember) {

  var TAB_KEY = 9;

  exports['default'] = {
    preventTab: function preventTab() {
      _ember['default'].$('body').on('keydown.tab', function (event) {
        if (event.keyCode === TAB_KEY) {
          event.preventDefault();
        }
      });
    },

    unbindPreventedTab: function unbindPreventedTab() {
      _ember['default'].$('body').unbind('keydown.tab');
    }
  };
});