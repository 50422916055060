define('quantuvis-cm-spa/constants/search-attributes', ['exports'], function (exports) {
  var STATUS = {
    ID: 'status',
    LABEL: 'Status',
    POSITION: 10
  };
  var COMPANY = {
    ID: 'company',
    LABEL: 'Company',
    POSITION: 20
  };

  var TYPE = {
    ID: 'type',
    LABEL: 'Type',
    POSITION: 30
  };

  var LEAD = {
    ID: 'leads',
    LABEL: 'Lead',
    POSITION: 40
  };

  var LINE_OF_BUSINESS = {
    ID: 'lineOfBusiness',
    LABEL: 'Line Of Business',
    POSITION: 50
  };

  var SEARCH_ATTRIBUTES = {
    STATUS: STATUS,
    COMPANY: COMPANY,
    TYPE: TYPE,
    LEAD: LEAD,
    LINE_OF_BUSINESS: LINE_OF_BUSINESS
  };

  exports['default'] = SEARCH_ATTRIBUTES;
});