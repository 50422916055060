define('quantuvis-cm-spa/services/sign-off-preview', ['exports', 'ember', 'moment', 'quantuvis-cm-spa/constants/contract-status', 'quantuvis-cm-spa/constants/signature-status', 'quantuvis-cm-spa/constants/sign-off-status', 'quantuvis-cm-spa/constants/date-format'], function (exports, _ember, _moment, _quantuvisCmSpaConstantsContractStatus, _quantuvisCmSpaConstantsSignatureStatus, _quantuvisCmSpaConstantsSignOffStatus, _quantuvisCmSpaConstantsDateFormat) {
  var _STATUSES;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var STATUSES = (_STATUSES = {}, _defineProperty(_STATUSES, _quantuvisCmSpaConstantsSignatureStatus.SIGNATURE_STATUS.PENDING, 'Pending:'), _defineProperty(_STATUSES, _quantuvisCmSpaConstantsSignatureStatus.SIGNATURE_STATUS.NOT_RECEIVED, 'Pending:'), _defineProperty(_STATUSES, _quantuvisCmSpaConstantsSignatureStatus.SIGNATURE_STATUS.IN_PROGRESS, 'Pending:'), _defineProperty(_STATUSES, _quantuvisCmSpaConstantsSignatureStatus.SIGNATURE_STATUS.SIGNED, 'Signed by'), _defineProperty(_STATUSES, 'SIGN_OFF_INITIATED', 'Sign Off initiated'), _defineProperty(_STATUSES, 'WAITING_FOR_APPROVE', 'Waiting for approval by'), _STATUSES);

  exports['default'] = _ember['default'].Service.extend({
    contractDetails: _ember['default'].inject.service(),
    profile: _ember['default'].inject.service(),

    dueDate: _ember['default'].computed.alias('contractDetails.activeSignOff.dueDate'),

    isPanelVisible: _ember['default'].computed('contractDetails.activeSignOff', 'contractDetails.contractId', function () {
      return this.get('contractDetails').getContract().get('status.id') === _quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.IN_SIGNATURE;
    }),

    items: _ember['default'].computed('contractDetails.activeSignOff', function () {
      return this.get('contractDetails.activeSignOff') ? this.generatePreviewItems() : [];
    }),

    formatDate: function formatDate(date) {
      return _moment['default'].tz(date, this.getTimezone()).format(_quantuvisCmSpaConstantsDateFormat['default'].MM_DD_YYYY);
    },

    getTimezone: function getTimezone() {
      return this.get('profile').getUserTimezone();
    },

    generatePreviewItems: function generatePreviewItems() {
      var _this = this;

      var items = [];
      var signOff = this.get('contractDetails.activeSignOff');
      var company = signOff.get('company.name');
      var isSignOffInPendingApproval = signOff.get('status.id') === _quantuvisCmSpaConstantsSignOffStatus['default'].PENDING_APPROVAL_ID;

      items.push(this.generateInitiatedDateItem(signOff.get('initiatedDate')));

      if (isSignOffInPendingApproval) {
        items.push(this.generateWaitingForApprovalItem(company));
      }

      if (!isSignOffInPendingApproval && signOff.get('signers.length')) {
        var signers = signOff.get('signers').sortBy('signatureStatus.id').reverse().map(function (signer) {
          return _this.generateSignerItem(signer, company);
        });

        items = items.concat(signers);
      }

      return items;
    },

    generateInitiatedDateItem: function generateInitiatedDateItem(date) {
      return _ember['default'].Object.create({
        status: STATUSES.SIGN_OFF_INITIATED,
        date: this.formatDate(date),
        checked: true
      });
    },

    generateWaitingForApprovalItem: function generateWaitingForApprovalItem(company) {
      return _ember['default'].Object.create({
        company: company,
        status: STATUSES.WAITING_FOR_APPROVE
      });
    },

    generateSignerItem: function generateSignerItem(_ref, company) {
      var name = _ref.name;
      var statusId = _ref.signatureStatus.id;
      var title = _ref.title;
      var signedDate = _ref.signedDate;

      return _ember['default'].Object.create({
        checked: statusId === _quantuvisCmSpaConstantsSignatureStatus.SIGNATURE_STATUS.SIGNED,
        date: signedDate ? this.formatDate(signedDate) : null,
        status: STATUSES[statusId],
        role: title,
        company: company,
        name: name
      });
    }
  });
});