define("quantuvis-cm-spa/constants/task-type", ["exports"], function (exports) {
  var TASK_TYPE = {
    CONTRACT_RECEIVED_FROM_OTHER_PARTY: 1,
    WORKFLOW_STEP_IN_PROGRESS: 2,
    SIGN_OFF_IN_PROGRESS: 3,
    WORKFLOW_STEP_OVERDUE: 4,
    SIGN_OFF_OVERDUE: 5
  };

  exports["default"] = TASK_TYPE;
});