define('quantuvis-cm-spa/services/request-queue', ['exports', 'ember', 'quantuvis-cm-spa/constants/request-state'], function (exports, _ember, _quantuvisCmSpaConstantsRequestState) {
  exports['default'] = _ember['default'].Service.extend({
    queueLength: 0,

    isEmpty: function isEmpty() {
      return this.get('queueLength') === 0;
    },

    updateQueue: function updateQueue(state) {
      if (state === _quantuvisCmSpaConstantsRequestState.REQUEST_STATE_STARTED) {
        this.incrementProperty('queueLength');
      }

      if (state === _quantuvisCmSpaConstantsRequestState.REQUEST_STATE_FINISHED) {
        !this.isEmpty() && this.decrementProperty('queueLength');
      }
    }
  });
});