define('quantuvis-cm-spa/core-ui/components/cm-browser-checker/supportedBrowserVersion', ['exports'], function (exports) {
  exports['default'] = [{
    name: 'Opera',
    version: 31
  }, {
    name: 'Firefox',
    version: 31
  }, {
    name: 'Chrome',
    version: 31
  }, {
    name: 'Safari',
    version: 8
  }, {
    name: 'IE',
    version: 11
  }];
});