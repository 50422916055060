define('quantuvis-cm-spa/components/cm-timeline/cm-timeline-item/event-bodies/cm-terminated', ['exports', 'ember', 'quantuvis-cm-spa/constants/event-property-name'], function (exports, _ember, _quantuvisCmSpaConstantsEventPropertyName) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['cm-terminated'],
    timeline: _ember['default'].inject.service(),
    date: _ember['default'].inject.service(),

    effectiveDate: _ember['default'].computed('data', function () {
      var properties = this.get('data.properties') || [];

      return this.get('date').formatDateWithUtcTimezone(this.get('timeline').getEventPropertyValueByName(properties, _quantuvisCmSpaConstantsEventPropertyName['default'].EFFECTIVE_DATE));
    }),

    isShowEffectiveDate: _ember['default'].computed('data', function () {
      var properties = this.get('data.properties') || [];

      return this.get('timeline').isPresentEventPropertyByName(properties, _quantuvisCmSpaConstantsEventPropertyName['default'].EFFECTIVE_DATE);
    }),

    actions: {
      showDetails: function showDetails() {
        this.get('timeline').showEventDetails(this.get('data'));
      }
    }
  });
});