define('quantuvis-cm-spa/components/cm-contract-details/summary-components/attachment-list', ['exports', 'ember', 'quantuvis-cm-spa/constants/attachment-type', 'quantuvis-cm-spa/constants/date-format', 'quantuvis-cm-spa/constants/permissions'], function (exports, _ember, _quantuvisCmSpaConstantsAttachmentType, _quantuvisCmSpaConstantsDateFormat, _quantuvisCmSpaConstantsPermissions) {
  exports['default'] = _ember['default'].Component.extend({
    PERMISSIONS: _quantuvisCmSpaConstantsPermissions.PERMISSIONS,
    DATE_FORMAT: _quantuvisCmSpaConstantsDateFormat['default'],
    classNames: ['cm-attachment-list'],
    file: _ember['default'].inject.service(),
    attachment: _ember['default'].inject.service(),
    contractDetails: _ember['default'].inject.service(),
    downloadFile: _ember['default'].inject.service(),
    showSpinner: false,

    actions: {
      previewDocument: function previewDocument(attachment) {
        this.get('file').previewDocument(attachment, _quantuvisCmSpaConstantsAttachmentType['default'].SUMMARY_ATTACHMENT, this.get('contractDetails.contractId'));
      },
      downloadDocument: function downloadDocument(attachment) {
        var _this = this;

        this.get('file').getFileUrl(attachment.get('key'), attachment.get('version')).then(function (response) {
          _this.get('downloadFile').download(response.get('url'));
        })['catch'](function (error) {
          _ember['default'].Logger.error('Error occured on retrieve URL', error);
        });
      },
      deleteDocument: function deleteDocument(attachment) {
        var _this2 = this;

        this.set('showSpinner', true);
        attachment.destroyRecord().then(function () {
          return _this2.set('showSpinner', false);
        })['catch'](function () {
          return _this2.set('showSpinner', false);
        });
      },
      createAttachment: function createAttachment() {
        this.get('attachment').showModal(this.get('attachments'));
      }
    }
  });
});