define('quantuvis-cm-spa/models/workflows/send-document', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    documentId: _emberData['default'].attr('number'),
    receivers: _emberData['default'].attr(),
    subject: _emberData['default'].attr('string'),
    message: _emberData['default'].attr('string'),
    attachmentId: _emberData['default'].attr('number'),
    isSendMeCopy: _emberData['default'].attr('boolean'),
    isReadyForSignOff: _emberData['default'].attr('boolean')
  });
});