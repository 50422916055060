define('quantuvis-cm-spa/components/cm-notes/cm-notes-form', ['exports', 'ember', 'ember-changeset', 'ember-changeset-validations', 'quantuvis-cm-spa/validations/contract-note', 'quantuvis-cm-spa/constants/permissions'], function (exports, _ember, _emberChangeset, _emberChangesetValidations, _quantuvisCmSpaValidationsContractNote, _quantuvisCmSpaConstantsPermissions) {
  exports['default'] = _ember['default'].Component.extend({
    PERMISSIONS: _quantuvisCmSpaConstantsPermissions.PERMISSIONS,
    contractDetails: _ember['default'].inject.service('contract-details'),
    loadingSpinner: _ember['default'].inject.service(),
    classNames: ['cm-notes-form'],

    init: function init() {
      this._super.apply(this, arguments);
      this.initChangeset();
    },

    initChangeset: function initChangeset() {
      var changeset = new _emberChangeset['default'](this.get('contractDetails.contractNoteModel'), (0, _emberChangesetValidations['default'])(_quantuvisCmSpaValidationsContractNote['default']), _quantuvisCmSpaValidationsContractNote['default'], {
        skipValidate: true
      });

      this.set('changeset', changeset);
    },

    actions: {
      submit: function submit(model) {
        var _this = this;

        this.get('loadingSpinner').show('internal-notes-spinner');
        this.get('contractDetails').saveContractNote(model).then(function () {
          _this.get('changeset').rollback();
          _this.get('loadingSpinner').hide('internal-notes-spinner');
        });
      }
    }
  });
});