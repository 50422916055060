define('quantuvis-cm-spa/components/cm-workflow-templates/templates-list', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'ul',
    classNames: 'cm-templates-list',
    selected: {},

    actions: {
      templateSelected: function templateSelected(template) {
        this.sendAction('onselected', template);
      }
    }
  });
});