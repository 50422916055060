define('quantuvis-cm-spa/services/login', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Service.extend({
    redirectUrl: '',
    setRedirectUrl: function setRedirectUrl(redirectUrl) {
      if (redirectUrl !== '/login') {
        this.set('redirectUrl', redirectUrl);
      }
    },
    redirect: function redirect(callback) {
      var redirectUrl = this.get('redirectUrl');

      if (redirectUrl !== '') {
        this.set('redirectUrl', '');
        window.location.href = redirectUrl;
      } else {
        callback();
      }
    }
  });
});