define('quantuvis-cm-spa/index', ['exports', 'ember-uuid'], function (exports, _emberUuid) {
  Object.defineProperty(exports, 'v4', {
    enumerable: true,
    get: function get() {
      return _emberUuid.v4;
    }
  });
  Object.defineProperty(exports, 'v1', {
    enumerable: true,
    get: function get() {
      return _emberUuid.v1;
    }
  });
  Object.defineProperty(exports, 'parse', {
    enumerable: true,
    get: function get() {
      return _emberUuid.parse;
    }
  });
  Object.defineProperty(exports, 'unparse', {
    enumerable: true,
    get: function get() {
      return _emberUuid.unparse;
    }
  });
});