define('quantuvis-cm-spa/components/cm-edit-workflow/workflow-substep-lead-review', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['cm-workflow-substep-lead-review'],
    reviewers: _ember['default'].computed('data.responsiblePersonsAccounts', function () {
      var leads = this.get('data.responsiblePersonsAccounts');
      var leadsNames = [];

      leads.forEach(function (lead) {
        leadsNames.push(lead.get('fullName'));
      });

      return leadsNames.join(', ');
    })
  });
});