define('quantuvis-cm-spa/components/cm-contract-details/sidebar-menus/timeline-menu', ['exports', 'ember', 'quantuvis-cm-spa/components/cm-contract-details/sidebar-menus/sidebar-base-menu', 'quantuvis-cm-spa/constants/permissions', 'quantuvis-cm-spa/constants/toast-notifications', 'quantuvis-cm-spa/constants/contract-status', 'quantuvis-cm-spa/validations/cancel-sign-off-request', 'ember-changeset', 'ember-changeset-validations'], function (exports, _ember, _quantuvisCmSpaComponentsCmContractDetailsSidebarMenusSidebarBaseMenu, _quantuvisCmSpaConstantsPermissions, _quantuvisCmSpaConstantsToastNotifications, _quantuvisCmSpaConstantsContractStatus, _quantuvisCmSpaValidationsCancelSignOffRequest, _emberChangeset, _emberChangesetValidations) {

  var SEND_DISABLE_STATUSES = [_quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.NEW];

  exports['default'] = _quantuvisCmSpaComponentsCmContractDetailsSidebarMenusSidebarBaseMenu['default'].extend({
    toastNotification: _ember['default'].inject.service(),
    PERMISSIONS: _quantuvisCmSpaConstantsPermissions.PERMISSIONS,
    classNames: ['timeline-menu'],
    sendDocumentService: _ember['default'].inject.service('send-document'),
    contractDetails: _ember['default'].inject.service(),
    permissionService: _ember['default'].inject.service(),
    contractPermissionValidator: _ember['default'].inject.service(),
    dialogManager: _ember['default'].inject.service(),
    signOff: _ember['default'].inject.service(),
    dialog: _ember['default'].inject.service(),
    loadingSpinner: _ember['default'].inject.service(),

    cancelSignOffChangeset: {},

    isDocumentDeactivated: _ember['default'].computed('model.status.id', function () {
      return this.get('model.status.id') === _quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.DEACTIVATED;
    }),

    mayNotEditWorkflow: _ember['default'].computed('contractDetails.contractId', 'model.status.id', function () {
      return !this.hasPermissionToEdit() || this.get('model.status.id') === _quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.EXECUTED || this.get('model.status.id') === _quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.TERMINATED;
    }),

    isSendDisabled: _ember['default'].computed('contractDetails.contractId', 'model.status.id', function () {
      var contractStatus = this.get('model.status.id');

      return SEND_DISABLE_STATUSES.includes(contractStatus);
    }),

    isInitiateSignOffDisabled: _ember['default'].computed('model.status.id', 'contractDetails.versions', function () {
      return !this.get('contractPermissionValidator').checkSignOffInitAvailability(this.get('contractDetails').getContract(), this.get('contractDetails.versions'));
    }),

    isCancelSignOffDisabled: _ember['default'].computed('model.status.id', function () {
      return !this.get('contractPermissionValidator').checkSignOffCancelAvailability(this.get('contractDetails').getContract());
    }),

    isResendSignOffEmailsDisabled: _ember['default'].computed('model.status.id', function () {
      return !this.get('contractPermissionValidator').checkResendSignOffEmailsAvailability(this.get('contractDetails').getContract(), this.get('contractDetails.activeSignOff'));
    }),

    isEditSignOffDisabled: _ember['default'].computed('model', 'contractDetails.activeSignOff', function () {
      return !this.get('contractPermissionValidator').isEditSignOffAvailable(this.get('model'), this.get('contractDetails.activeSignOff'));
    }),

    hasPermissionToEdit: function hasPermissionToEdit() {
      return this.get('permissionService').checkCompanyUserPermission(_quantuvisCmSpaConstantsPermissions.PERMISSIONS.ID.EDIT_WORKFLOW) && this.get('permissionService').checkCompanyUserPermission(_quantuvisCmSpaConstantsPermissions.PERMISSIONS.ID.READ_TIMELINE);
    },

    initCancelSignOffRequestChangeset: function initCancelSignOffRequestChangeset() {
      var model = _ember['default'].Object.create({
        reason: ''
      });
      var changeset = new _emberChangeset['default'](model, (0, _emberChangesetValidations['default'])(_quantuvisCmSpaValidationsCancelSignOffRequest['default']), _quantuvisCmSpaValidationsCancelSignOffRequest['default'], {
        skipValidate: true
      });

      this.set('cancelSignOffChangeset', changeset);
    },

    cancelSignOffRequest: function cancelSignOffRequest() {
      var _this = this;

      this.initCancelSignOffRequestChangeset();

      var reasonOptions = {
        className: 'cancel-sign-off-request-dialog',
        componentName: 'cm-timeline/menus/dialog/cancel-sign-off-request-dialog',
        title: 'Cancel Sign Off',
        acceptText: 'Cancel Sign Off',
        declineText: 'Close',
        acceptHandler: 'sendCancelSignOffRequest'
      };

      this.get('dialogManager').showDialogConfirmation('Do you want to cancel Sign Off?').then(function () {
        return _this.get('dialog').show('dialogs/cm-dialog', null, _this, reasonOptions);
      });
    },

    actions: {
      sendDocument: function sendDocument() {
        this.get('sendDocumentService').showModal();
      },

      cancelSignOff: function cancelSignOff() {
        this.cancelSignOffRequest();
      },

      resendSignOffEmails: function resendSignOffEmails() {
        var _this2 = this;

        this.get('signOff').resendEmails(this.get('contractDetails.activeSignOff.id')).then(function () {
          _this2.get('toastNotification').add(_quantuvisCmSpaConstantsToastNotifications.RESEND_EMAILS_SUCCESS);
        })['catch'](function () {
          _this2.get('toastNotification').add(_quantuvisCmSpaConstantsToastNotifications.RESEND_EMAILS_ERROR);
        });
      },

      sendCancelSignOffRequest: function sendCancelSignOffRequest(presenter) {
        var _this3 = this;

        this.set('showDialogSpinner', true);
        var changeset = this.get('cancelSignOffChangeset');
        var data = {
          reason: changeset.get('reason')
        };

        changeset.validate().then(function () {
          if (changeset.get('isInvalid')) {
            return Promise.reject();
          }

          return _this3.get('signOff').cancel(_this3.get('contractDetails.activeSignOff.id'), data)['catch'](function () {
            _this3.get('dialogManager').showDefaultError();

            return Promise.reject();
          });
        }).then(function () {
          _this3.set('showDialogSpinner', false);

          presenter.accept();

          return Promise.all([_this3.get('contractDetails').loadTimelineItems(), _this3.get('contractDetails').reloadContract()]).then(function () {
            _this3.set('contractDetails.activeSignOff', null);
            _this3.get('toastNotification').add(_quantuvisCmSpaConstantsToastNotifications.CANCEL_SIGN_OFF);
          });
        })['catch'](function () {
          return _this3.set('showDialogSpinner', false);
        });
      },

      editSignOff: function editSignOff() {
        var _this4 = this;

        this.get('loadingSpinner').show('contract-details-spinner');

        this.get('signOff').edit(this.get('contractDetails.activeSignOff.id'), this.get('contractDetails.contractId')).then(function () {
          return _this4.get('loadingSpinner').hide('contract-details-spinner');
        })['catch'](function () {
          return _this4.get('loadingSpinner').hide('contract-details-spinner');
        });
      }
    }
  });
});