define('quantuvis-cm-spa/components/cm-workflow-templates/templates-list-item', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'li',

    click: function click() {
      var template = this.get('template');

      this.sendAction('templateSelected', template);
    }
  });
});