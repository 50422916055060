define('quantuvis-cm-spa/services/download-versions', ['exports', 'ember', 'quantuvis-cm-spa/constants/permissions'], function (exports, _ember, _quantuvisCmSpaConstantsPermissions) {
  exports['default'] = _ember['default'].Service.extend({
    PERMISSIONS: _quantuvisCmSpaConstantsPermissions.PERMISSIONS,
    dialog: _ember['default'].inject.service(),
    store: _ember['default'].inject.service(),
    session: _ember['default'].inject.service(),
    downloadFile: _ember['default'].inject.service(),
    contractDetails: _ember['default'].inject.service(),
    isValid: true,

    documentVersions: [],

    showModal: function showModal() {
      var _this = this;

      this.set('documentVersions', []);

      var options = {
        className: 'cm-download-versions-dialog',
        componentName: 'cm-contract-details/cm-download-versions',
        title: 'Download Versions',
        acceptText: 'Download'
      };

      this.get('store').query('workflows/attachment-version', { contractId: this.get('contractDetails.contractId') }).then(function (versions) {
        versions.forEach(function (version) {
          version.set('isChecked', false);
        });

        _this.set('documentVersions', versions);
      });

      this.get('dialog').show('dialogs/cm-dialog', null, this, options);
    },

    downloadZipFile: function downloadZipFile(ids, presenter) {
      var _this2 = this;

      var contractId = this.get('contractDetails.contractId');

      this.set('showDialogSpinner', true);
      this.get('store').queryRecord('contracts/file/zip-version', { contractId: contractId, ids: ids }).then(function (response) {
        _this2.set('showDialogSpinner', false);
        presenter.accept();
        _this2.get('downloadFile').download(response.get('url'));
      })['catch'](function (error) {
        _this2.set('showDialogSpinner', false);
        _ember['default'].Logger.error('Error occured on retrieve URL', error);
      });
    },

    actions: {
      accept: function accept(presenter) {
        var checkedVersions = this.get('documentVersions').filter(function (version) {
          return version.isChecked;
        }).map(function (version) {
          return version.id;
        });

        var isValid = checkedVersions.length > 0;

        if (isValid) {
          this.downloadZipFile(checkedVersions.join(','), presenter);
        }

        this.set('isValid', isValid);
      }
    }
  });
});