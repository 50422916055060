define('quantuvis-cm-spa/instance-initializers/ember-useragent', ['exports', 'ember-useragent/instance-initializers/ember-useragent'], function (exports, _emberUseragentInstanceInitializersEmberUseragent) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberUseragentInstanceInitializersEmberUseragent['default'];
    }
  });
  Object.defineProperty(exports, 'initialize', {
    enumerable: true,
    get: function get() {
      return _emberUseragentInstanceInitializersEmberUseragent.initialize;
    }
  });
});