define('quantuvis-cm-spa/components/cm-sign-off-preview', ['exports', 'ember', 'quantuvis-cm-spa/constants/date-format'], function (exports, _ember, _quantuvisCmSpaConstantsDateFormat) {

  var COLLAPSE_NAME = 'sign-off-preview';

  exports['default'] = _ember['default'].Component.extend({
    DATE_FORMAT: _quantuvisCmSpaConstantsDateFormat['default'],
    panelActions: _ember['default'].inject.service(),

    classNames: ['cm-sign-off-preview'],

    COLLAPSE_NAME: COLLAPSE_NAME,
    items: [],
    open: false,

    didDestroyElement: function didDestroyElement() {
      this.resetOpenState();
    },

    isOpen: _ember['default'].computed.readOnly('panelActions.state.' + COLLAPSE_NAME + '.isOpen'),

    openStateObserver: _ember['default'].observer('isOpen', function () {
      this.sendAction('onToggle', this.get('isOpen'));
    }),

    resetOpenState: function resetOpenState() {
      this.get('panelActions').close(COLLAPSE_NAME);
    }
  });
});