define('quantuvis-cm-spa/services/sentry-adapter', ['exports', 'ember', '@sentry/browser'], function (exports, _ember, _sentryBrowser) {

  var SENTRY_ERROR_CODE_TAG = 'error_code';

  exports['default'] = _ember['default'].Service.extend({
    sentry: _sentryBrowser['default'],

    handleError: function handleError(status, payload) {
      var _this = this;

      this.get('sentry').withScope(function (scope) {
        scope.setTag(SENTRY_ERROR_CODE_TAG, status);

        _this.get('sentry').captureException(payload, scope);
      });
    }
  });
});