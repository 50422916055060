define('quantuvis-cm-spa/authenticators/oauth2', ['exports', 'ember', 'ember-simple-auth/authenticators/oauth2-password-grant', 'quantuvis-cm-spa/app-config', 'quantuvis-cm-spa/constants/request-state', 'quantuvis-cm-spa/constants/permissions'], function (exports, _ember, _emberSimpleAuthAuthenticatorsOauth2PasswordGrant, _quantuvisCmSpaAppConfig, _quantuvisCmSpaConstantsRequestState, _quantuvisCmSpaConstantsPermissions) {

  var BAD_REQUEST = 400;
  var UNAUTHENTICATED = 401;
  var UNAUTHENTICATED_STATUSES = [BAD_REQUEST, UNAUTHENTICATED];
  var REFRESH_TOKEN_DELAY = 100;
  var TOKEN_REFRESH_OFFSET_MIN = 60;
  var TOKEN_REFRESH_OFFSET_MAX = 120;
  var MILLISECONDS_IN_SECOND = 1000;

  exports['default'] = _emberSimpleAuthAuthenticatorsOauth2PasswordGrant['default'].extend({
    refreshAccessTokens: true,
    profileSettings: _ember['default'].inject.service(),
    profile: _ember['default'].inject.service(),
    permissionService: _ember['default'].inject.service(),
    store: _ember['default'].inject.service(),
    session: _ember['default'].inject.service(),
    requestQueue: _ember['default'].inject.service(),
    router: _ember['default'].inject.service('-routing'),
    enrollmentsService: _ember['default'].inject.service(),

    /**
     * Must be a range of numbers
     * avoid to refresh token from different tabs in one time
     */
    tokenRefreshOffset: _ember['default'].computed(function () {
      var interval = TOKEN_REFRESH_OFFSET_MAX - TOKEN_REFRESH_OFFSET_MIN;

      return (Math.floor(Math.random() * interval) + TOKEN_REFRESH_OFFSET_MIN) * MILLISECONDS_IN_SECOND;
    }).volatile(),

    applicationId: _quantuvisCmSpaAppConfig['default'].applicationId,
    applicationKey: _quantuvisCmSpaAppConfig['default'].applicationKey,

    serverTokenEndpoint: _quantuvisCmSpaAppConfig['default'].apiUrl + '/' + _quantuvisCmSpaAppConfig['default'].apiPrefix + '/user-management/oauth/token',
    serverCheckTokenEndpoint: _quantuvisCmSpaAppConfig['default'].apiUrl + '/' + _quantuvisCmSpaAppConfig['default'].apiPrefix + '/user-management/oauth/check_token',

    _clientIdHeader: _ember['default'].computed('applicationId', 'applicationKey', function () {
      var applicationId = this.get('applicationId');
      var applicationKey = this.get('applicationKey');

      if (!_ember['default'].isEmpty(applicationId)) {
        return this.createAuthenticationHeaders(applicationId, applicationKey);
      }
    }),

    createAuthenticationHeaders: function createAuthenticationHeaders(applicationId, applicationKey) {
      var base64authentication = window.btoa(applicationId.concat(':').concat(applicationKey));

      return {
        Authorization: 'Basic ' + base64authentication
      };
    },

    /**
     * Prefetch user personal data
     */
    prefetchPersonalizationProperties: function prefetchPersonalizationProperties() {
      var _this = this;

      return this.forceSessionRestoring().then(function () {
        return _this.checkTokenInfo().then(function () {
          return _ember['default'].RSVP.hash([_this.prefetchUserPermission(), _this.prefetchUserProfile(), _this.prefetchCompanyEnrollments()]);
        });
      });
    },

    prefetchUserProfile: function prefetchUserProfile() {
      return this.get('profile').fetchUserProfile();
    },

    prefetchCompanyEnrollments: function prefetchCompanyEnrollments() {
      return this.get('enrollmentsService').loadEnrollments();
    },

    prefetchUserPermission: function prefetchUserPermission() {
      var _this2 = this;

      return this.get('permissionService').getUserPermissions().then(function (permissions) {
        _this2.checkCMAccess();

        return permissions;
      });
    },

    checkCMAccess: function checkCMAccess() {
      if (!this.get('permissionService').checkGlobalUserPermission(_quantuvisCmSpaConstantsPermissions.PERMISSIONS.ID.CM_ACCESS) && !this.get('permissionService').checkAdminPermissionByApplication(_quantuvisCmSpaConstantsPermissions.PERMISSIONS.ID.CM_READ, _quantuvisCmSpaConstantsPermissions.PERMISSIONS.APPLICATION.CM)) {
        this.get('router').transitionTo('application-prohibited');
      }
    },

    checkTokenInfo: function checkTokenInfo() {
      var _this3 = this;

      var token = this.get('session').get('data.authenticated.access_token');

      this.get('requestQueue').updateQueue(_quantuvisCmSpaConstantsRequestState.REQUEST_STATE_STARTED);

      return this.makeRequest(this.serverCheckTokenEndpoint, { token: token }, this.createAuthenticationHeaders(this.get('applicationId'), this.get('applicationKey'))).then(function () {
        return _this3.get('requestQueue').updateQueue(_quantuvisCmSpaConstantsRequestState.REQUEST_STATE_FINISHED);
      });
    },

    forceSessionRestoring: function forceSessionRestoring() {
      var expiresAt = this.get('session.data.authenticated.expires_at');
      var now = new Date().getTime();
      var offsetMin = TOKEN_REFRESH_OFFSET_MIN * MILLISECONDS_IN_SECOND;
      var lifetime = expiresAt - now;

      if (expiresAt && lifetime < offsetMin && lifetime > 0) {
        var refreshToken = this.get('session.data.authenticated.refresh_token');
        var expiresIn = this.get('session.data.authenticated.expires_in');

        _ember['default'].Logger.warn('Session not refreshed in schedule. Restoring:', {
          refreshToken: refreshToken,
          lifetime: lifetime,
          expiresAt: expiresAt
        });

        return this.makeRefreshTokenRequest(expiresIn, refreshToken);
      }

      return Promise.resolve();
    },

    forceInvalidateSession: function forceInvalidateSession() {
      this.get('session.store').clear();
      _ember['default'].set(this.get('session.session.content'), 'authenticated', {});
    },

    restore: function restore(data) {
      var _this4 = this;

      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        var now = new Date().getTime();
        var offset = _this4.get('tokenRefreshOffset');

        if (!_this4._validate(data) || _ember['default'].isEmpty(data['expires_at'])) {
          reject();
        } else {
          if (data['expires_at'] >= now - offset && data['expires_at'] <= now) {
            _this4._refreshAccessToken(data['expires_in'], data['refresh_token']).then(resolve, reject);
          } else {
            _this4._scheduleAccessTokenRefresh(data['expires_in'], data['expires_at'], data['refresh_token']);
            resolve(data);
          }
        }
      });
    },

    _refreshAccessToken: function _refreshAccessToken(expiresIn, refreshToken) {
      var _this5 = this;

      if (this.get('requestQueue').isEmpty()) {
        return this.makeRefreshTokenRequest(expiresIn, refreshToken);
      } else {
        return _ember['default'].run.later(this, function () {
          return _this5._refreshAccessToken(expiresIn, refreshToken);
        }, REFRESH_TOKEN_DELAY);
      }
    },

    makeRefreshTokenRequest: function makeRefreshTokenRequest(expiresIn, refreshToken) {
      var _this6 = this;

      var data = { 'grant_type': 'refresh_token', 'refresh_token': refreshToken };
      var serverTokenEndpoint = this.get('serverTokenEndpoint');

      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        _this6.makeRequest(serverTokenEndpoint, data).then(function (response) {
          _ember['default'].run(function () {
            expiresIn = response['expires_in'] || expiresIn;
            refreshToken = response['refresh_token'] || refreshToken;
            var expiresAt = _this6._absolutizeExpirationTime(expiresIn);
            var data = _ember['default'].assign(response, { 'expires_in': expiresIn, 'expires_at': expiresAt, 'refresh_token': refreshToken });

            _this6._scheduleAccessTokenRefresh(expiresIn, null, refreshToken);
            _this6.trigger('sessionDataUpdated', data);
            resolve(data);
          });
        }, function (response) {
          if (response.status && UNAUTHENTICATED_STATUSES.includes(response.status)) {
            _ember['default'].Logger.warn('Refresh token failed', response);
            _this6.forceInvalidateSession();
          }

          _ember['default'].Logger.warn('Access token could not be refreshed - server responded with ' + response.responseJSON + '.', false, { id: 'ember-simple-auth.failedOAuth2TokenRefresh' });
          reject();
        });
      });
    }
  });
});