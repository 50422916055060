define('quantuvis-cm-spa/core-ui/components/cm-search-input/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['cm-search'],
    /**
     * Text for search
     */
    searchKeyword: '',
    search: _ember['default'].inject.service(),

    init: function init() {
      this._super.apply(this, arguments);
      this.set('search.textSearch', '');
      this.set('search.dashboardTextSearchModeEnabled', false);
    },

    actions: {
      onChevronActivated: function onChevronActivated() {
        this.sendAction('on-show-extended', this.collectActionData());
      },
      onSearchActivated: function onSearchActivated() {
        this.executeTextSearch();
      },
      onEnter: function onEnter() {
        this.executeTextSearch();
      },
      onSearchClear: function onSearchClear() {
        this.get('search').set('textSearch', '');
        this.set('searchKeyword', '');
        this.get('search').fireTextSearchUpdated();
      }
    },

    collectActionData: function collectActionData() {
      return {
        searchKeyword: this.get('searchKeyword')
      };
    },

    executeTextSearch: function executeTextSearch() {
      if (this.get('searchKeyword') !== this.get('search').get('textSearch')) {
        this.get('search').set('textSearch', this.get('searchKeyword'));
        this.get('search').fireTextSearchUpdated();
      }
    }
  });
});