define('quantuvis-cm-spa/core-ui/components/cm-spinner/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['cm-spinner', 'block-content'],
    classNameBindings: ['visible', 'bindTypeClass'],
    visible: false,
    spinnerService: _ember['default'].inject.service('loading-spinner'),
    options: _ember['default'].computed.alias('spinnerService.options'),

    bindTypeClass: _ember['default'].computed('type', function () {
      switch (this.get('type')) {
        case 'fixed':
          return 'spinner-fixed';
        default:
          return '';
      }
    }),

    show: function show() {
      this.set('visible', true);
    },

    hide: function hide() {
      this.set('visible', false);
    },

    setVisible: function setVisible(value) {
      this.set('visible', value);
    },

    didInsertElement: function didInsertElement() {
      this.get('spinnerService').registerComponent(this);
    },

    willDestroyElement: function willDestroyElement() {
      this.get('spinnerService').unregisterComponent(this);
    }
  });
});