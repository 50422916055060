define('quantuvis-cm-spa/core-ui/components/cm-toast-notification/component', ['exports', 'ember'], function (exports, _ember) {

  var DEFAULT_DELAY = 3400;
  var ACCELERATED_DELAY = 1500;
  var OPACITY = {
    HOVER: 1,
    STANDART: 0.85
  };

  exports['default'] = _ember['default'].Component.extend({
    toastNotification: _ember['default'].inject.service(),

    classNames: ['cm-toast-notification'],
    classNameBindings: ['className', 'type'],

    timer: null,
    delay: null,
    message: '',
    autoClear: true,

    isHidden: false,

    didInsertElement: function didInsertElement() {
      this.show();
      this.setTimersAndEventHandlers();
    },

    isAutoClearAllowed: _ember['default'].computed('autoClear', function () {
      var autoClear = this.get('autoClear');

      return autoClear === undefined ? true : autoClear;
    }),

    setTimersAndEventHandlers: function setTimersAndEventHandlers() {
      this.bindEventHandlers();

      if (this.get('isAutoClearAllowed')) {
        this.setTimerToState();
      }
    },

    setTimerToState: function setTimerToState() {
      var timer = this.setupTimer(this.get('delay') || DEFAULT_DELAY);

      this.set('timer', timer);
    },

    bindEventHandlers: function bindEventHandlers() {
      var _this = this;

      var $element = this.$();

      $element.on('mouseover', function () {
        _this.setOpacity(OPACITY.HOVER);

        if (_this.get('timer') && _this.get('isAutoClearAllowed')) {
          clearTimeout(_this.get('timer'));
        }
      });

      $element.on('mouseout', function () {
        _this.setOpacity(OPACITY.STANDART);

        if (_this.get('isAutoClearAllowed')) {
          var timer = _this.setupTimer(ACCELERATED_DELAY);

          _this.set('timer', timer);
        }
      });
    },

    setupTimer: function setupTimer(delay) {
      var _this2 = this;

      return setTimeout(function () {
        return _this2.hide();
      }, delay);
    },

    setOpacity: function setOpacity(opacity) {
      this.$().css({ opacity: opacity });
    },

    show: function show() {
      return this.$().animate({ opacity: OPACITY.STANDART });
    },

    hide: function hide() {
      var _this3 = this;

      if (this.isHidden) {
        return;
      }
      this.isHidden = true;

      return this.$().fadeOut(function () {
        return _this3.removeFromList();
      });
    },

    removeFromList: function removeFromList() {
      var _this4 = this;

      var index = this.get('index');

      if (index >= 0) {
        _ember['default'].run(function () {
          return _this4.get('toastNotification').remove(index);
        });
      }
    },

    actions: {
      close: function close() {
        this.hide();
      }
    }
  });
});