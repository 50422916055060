define('quantuvis-cm-spa/components/sign-off/form/recipient-company', ['exports', 'ember', 'ember-changeset', 'ember-changeset-validations', 'quantuvis-cm-spa/validations/initiate-sign-off-signatory'], function (exports, _ember, _emberChangeset, _emberChangesetValidations, _quantuvisCmSpaValidationsInitiateSignOffSignatory) {

  var MAX_SIGNATORIES = 5;
  var MIN_SIGNATORIES = 1;

  exports['default'] = _ember['default'].Component.extend({
    initiateSignOffValidator: _ember['default'].inject.service(),

    companyInfo: null,

    showRemoveSignatoryButton: _ember['default'].computed('companyInfo.company.signatories.length', function () {
      return this.get('companyInfo.company.signatories').length > MIN_SIGNATORIES;
    }),

    showAddSignatoryButton: _ember['default'].computed('companyInfo.company.signatories.length', function () {
      return this.get('companyInfo.company.signatories').length < MAX_SIGNATORIES;
    }),

    optionsWithoutSelected: _ember['default'].computed('companyInfo.options', 'companyInfo.company.signatories.@each.id', function () {
      var selectedItemsIds = this.get('companyInfo.company.signatories').filterBy('id').mapBy('id');

      return this.get('companyInfo.options').filter(function (option) {
        return !selectedItemsIds.includes(option.get('id'));
      });
    }),

    init: function init() {
      this._super.apply(this, arguments);
      this.createDefaultChangesetsIfNeeded();
    },

    companyInfoObserver: _ember['default'].observer('companyInfo', function () {
      this.createDefaultChangesetsIfNeeded();
    }),

    createDefaultChangesetsIfNeeded: function createDefaultChangesetsIfNeeded() {
      if (this.get('companyInfo.company.signatories').length === 0) {
        this.get('companyInfo.company.signatories').pushObject(this.createSignatoryChangeset());
      }
    },

    createSignatoryChangeset: function createSignatoryChangeset() {
      var position = arguments.length <= 0 || arguments[0] === undefined ? 1 : arguments[0];

      return new _emberChangeset['default'](_ember['default'].Object.create({ id: null, name: '', email: '', title: '', position: position }), (0, _emberChangesetValidations['default'])((0, _quantuvisCmSpaValidationsInitiateSignOffSignatory['default'])()), (0, _quantuvisCmSpaValidationsInitiateSignOffSignatory['default'])(), {
        skipValidate: true
      });
    },

    actions: {
      addSignatory: function addSignatory() {
        var signatoryLength = this.get('companyInfo.company.signatories.length');

        if (signatoryLength >= MAX_SIGNATORIES) {
          return;
        }

        var signatoryChangeset = this.createSignatoryChangeset(signatoryLength + 1);

        this.get('companyInfo.company.signatories').pushObject(signatoryChangeset);
      },

      removeSignatory: function removeSignatory(position) {
        this.get('companyInfo.company.signatories').removeAt(position - 1);
        this.get('companyInfo.company.signatories').forEach(function (element, index) {
          element.set('position', index + 1);
        });
      }
    }
  });
});