define('quantuvis-cm-spa/core-ui/components/cm-checkbox-panel/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    disabled: false,
    checked: false,
    iconsPath: '/assets/icons/',
    tagName: '',

    actions: {
      onClick: function onClick() {
        if (!this.get('disabled')) {
          this.toggleProperty('checked');
          this.sendAction('onClick', this.get('checked'));
        }
      }
    }
  });
});