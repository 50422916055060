define('quantuvis-cm-spa/controllers/edit-workflow-template', ['exports', 'ember', 'quantuvis-cm-spa/mixins/scroll-to-validation-error'], function (exports, _ember, _quantuvisCmSpaMixinsScrollToValidationError) {
  exports['default'] = _ember['default'].Controller.extend(_quantuvisCmSpaMixinsScrollToValidationError['default'], {
    workflowTemplate: _ember['default'].inject.service(),
    leads: [],
    loadingSpinner: _ember['default'].inject.service(),

    actions: {
      saveWorkflowTemplate: function saveWorkflowTemplate(_ref) {
        var _this = this;

        var template = _ref.template;
        var changesetMap = _ref.changesetMap;

        this.get('loadingSpinner').show('workflow-template-spinner');
        this.get('workflowTemplate').update(template, changesetMap).then(function () {
          _this.get('loadingSpinner').hide('workflow-template-spinner');
          _this.transitionToRoute('business-admin.workflow-templates');
        })['catch'](function () {
          _this.get('loadingSpinner').hide('workflow-template-spinner');
          _this.scrollToValidationError();
        });
      },

      backToWorkflowTemplates: function backToWorkflowTemplates() {
        this.transitionToRoute('business-admin.workflow-templates');
      }
    }
  });
});