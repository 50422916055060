define('quantuvis-cm-spa/services/profile', ['exports', 'ember'], function (exports, _ember) {

  var DEFAULT_PROFILE_TIMEZONE = 'UTC';

  exports['default'] = _ember['default'].Service.extend({
    userProfile: null,
    userTimezone: DEFAULT_PROFILE_TIMEZONE,
    store: _ember['default'].inject.service(),
    myCompanies: [],

    fetchUserProfile: function fetchUserProfile() {
      var _this = this;

      return this.get('store').queryRecord('user-management/users/profile', {}).then(function (profile) {
        _this.set('userProfile', profile);
        _this.set('userTimezone', profile.get('timezone.key'));
        var myCompanies = profile.get('myAccounts').map(function (_ref) {
          var id = _ref.id;
          return id;
        });

        _this.set('myCompanies', myCompanies);
      })['catch'](function (error) {
        _ember['default'].Logger.error('Failed to fetch user profile', error);
      });
    },

    getUserTimezone: function getUserTimezone() {
      return this.get('userTimezone');
    },

    getUserProfile: function getUserProfile() {
      return this.get('userProfile');
    },

    getUserProfileAccountId: function getUserProfileAccountId() {
      return Number(this.get('userProfile.company.id'));
    }
  });
});