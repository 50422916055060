define('quantuvis-cm-spa/components/cm-workflow-step/due-date-event', ['exports', 'ember', 'quantuvis-cm-spa/constants/due-date-events', 'ember-changeset', 'ember-changeset-validations'], function (exports, _ember, _quantuvisCmSpaConstantsDueDateEvents, _emberChangeset, _emberChangesetValidations) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['cm-due-date-event'],

    events: _ember['default'].computed('isGeneral', function () {
      return this.get('isGeneral') ? [_quantuvisCmSpaConstantsDueDateEvents.DAYS_FROM_CONTRACT_CREATION_EVENT, _quantuvisCmSpaConstantsDueDateEvents.WEEKS_FROM_CONTRACT_CREATION_EVENT] : [_quantuvisCmSpaConstantsDueDateEvents.DAYS_FROM_CONTRACT_CREATION_EVENT, _quantuvisCmSpaConstantsDueDateEvents.WEEKS_FROM_CONTRACT_CREATION_EVENT, _quantuvisCmSpaConstantsDueDateEvents.DAYS_AFTER_PREVIOUS_STEP_EVENT];
    }),
    unitOptions: _ember['default'].computed('unitsRange', function () {
      return new Array(this.get('unitsRange')).fill().map(function (value, index) {
        return ++index;
      });
    }),

    unitsVerticalPosition: 'auto',

    init: function init() {
      this._super.apply(this, arguments);

      this.initChangeset();
    },

    calculateUnitsVerticalPosition: function calculateUnitsVerticalPosition() {
      var dropdownMaxHeight = 224; // Set by ember-power-select styles
      var $trigger = this.$('.due-date-units .ember-power-select-trigger');
      var $footer = _ember['default'].$('.cm-footer');
      var isFit = $footer.offset().top - ($trigger.offset().top + dropdownMaxHeight + $trigger.outerHeight()) <= 0;
      var position = isFit ? 'above' : 'below';

      this.set('unitsVerticalPosition', position);
    },

    initChangeset: function initChangeset() {
      var changeset = new _emberChangeset['default'](this.get('model.dueDateTemplate'), (0, _emberChangesetValidations['default'])({}), {}, { skipValidate: false });

      this.set('changeset', changeset);
      this.set('model.dueDateChangeset', changeset);
    },

    updateDueDateProperty: function updateDueDateProperty(control, value) {
      this.refreshChangesetProperty('units');
      this.refreshChangesetProperty('dueDateEvent');

      control.update(value);
    },

    refreshChangesetProperty: function refreshChangesetProperty(propertyName) {
      this.get('changeset').set(propertyName, this.get('changeset').get(propertyName));
    }
  });
});