define('quantuvis-cm-spa/models/inflector/custom-inflector-rules', ['exports', 'ember-inflector'], function (exports, _emberInflector) {

  /**
   * Configuration for custom model pluralization
   */
  var inflector = _emberInflector['default'].inflector;

  inflector.irregular('line-of-business', 'lines-of-business');
  inflector.irregular('history', 'history');
  inflector.irregular('lookup', 'lookup');
  inflector.irregular('internally', 'internally');
  inflector.irregular('externally', 'externally');
  inflector.irregular('navigation', 'navigation');
  inflector.irregular('disband-expiring', 'disband-expiring');
  inflector.irregular('prolong', 'prolong');
  inflector.irregular('new-document-attachment-status', 'new-document-attachment-status');
  inflector.irregular('with-permission', 'with-permission');
  inflector.irregular('extended', 'extended');
  inflector.irregular('profile', 'profile');
  inflector.irregular('envelope', 'envelope');
  inflector.irregular('signoff', 'signoff');
  inflector.irregular('accept', 'accept');
  inflector.irregular('decline', 'decline');
  inflector.irregular('cancel', 'cancel');
  inflector.irregular('available-for-linking', 'available-for-linking');

  exports['default'] = {};
});