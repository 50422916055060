define('quantuvis-cm-spa/components/cm-system-menu', ['exports', 'ember', 'quantuvis-cm-spa/constants/applications', 'quantuvis-cm-spa/constants/icon-name'], function (exports, _ember, _quantuvisCmSpaConstantsApplications, _quantuvisCmSpaConstantsIconName) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['cm-system-menu'],
    window: _ember['default'].inject.service(),
    enrollmentsService: _ember['default'].inject.service(),
    applicationNavigation: _ember['default'].inject.service(),
    menuItemsGroups: [[{ iconName: 'quantuvis-logo-short', tooltipText: null, link: '/', iconClass: 'system-link' }], [{ iconName: _quantuvisCmSpaConstantsIconName['default'].SM, tooltipText: 'Rates', applicationId: _quantuvisCmSpaConstantsApplications['default'].STRATEGY_MANAGEMENT_ID,
      iconClass: 'sm-link system-link' }, { iconName: _quantuvisCmSpaConstantsIconName['default'].RN, tooltipText: 'Rebate Negotiation', applicationId: _quantuvisCmSpaConstantsApplications['default'].REBATE_NEGOTIATIONS_ID,
      iconClass: 'system-link' }, { iconName: _quantuvisCmSpaConstantsIconName['default'].IA, tooltipText: 'Invoice Administration', applicationId: _quantuvisCmSpaConstantsApplications['default'].INVOICING_SYSTEM_ID,
      iconClass: 'system-link' }, { iconName: _quantuvisCmSpaConstantsIconName['default'].CM, tooltipText: 'Contract Management', link: '/', iconClass: 'system-link active' }], [{ iconName: _quantuvisCmSpaConstantsIconName['default'].IQ, tooltipText: 'iQ', applicationId: _quantuvisCmSpaConstantsApplications['default'].IQ_ID, iconClass: 'iq-link system-link' }], [{ iconName: _quantuvisCmSpaConstantsIconName['default'].SA, tooltipText: 'System Administration',
      applicationId: _quantuvisCmSpaConstantsApplications['default'].SYSTEM_ADMINISTRATION_ID, iconClass: 'system-link' }]],
    actions: {
      redirectTo: function redirectTo(_ref) {
        var link = _ref.link;
        var applicationId = _ref.applicationId;

        var currentLocation = this.get('window').getLocation();

        if (link && link !== currentLocation.pathname) {
          currentLocation.href = link;
        } else if (!isNaN(applicationId)) {
          this.get('applicationNavigation').redirectTo(applicationId);
        }
      }
    },

    init: function init() {
      this._super.apply(this, arguments);
      this.prepareDisplayedMenuItems();
    },

    prepareDisplayedMenuItems: function prepareDisplayedMenuItems() {
      var menuItemsGroups = this.get('menuItemsGroups');
      var hasIaEnrollments = this.get('enrollmentsService').hasIaEnrollments();
      var hasSmEnrollments = this.get('enrollmentsService').hasSmEnrollments();

      if (!hasIaEnrollments) {
        menuItemsGroups[1] = this.removeMenuItemIcon(menuItemsGroups[1], _quantuvisCmSpaConstantsIconName['default'].IA);
      }

      if (!hasSmEnrollments) {
        menuItemsGroups[1] = this.removeMenuItemIcon(menuItemsGroups[1], _quantuvisCmSpaConstantsIconName['default'].SM);
      }

      this.set('menuItemsGroups', menuItemsGroups);
    },

    removeMenuItemIcon: function removeMenuItemIcon(menuConfig, iconName) {
      return menuConfig.filter(function (item) {
        return item.iconName !== iconName;
      });
    }
  });
});