define('quantuvis-cm-spa/constants/email-preferences', ['exports'], function (exports) {
  var allAccounts = {
    id: 1,
    name: 'All Accounts',
    description: 'Receive notification emails for All Accounts'
  };

  var myAccounts = {
    id: 2,
    name: 'My Accounts',
    description: 'Receive notification emails only for My Accounts'
  };

  var noEmails = {
    id: 3,
    name: 'No Emails',
    description: 'Do not receive any notification emails'
  };

  var EMAIL_PREFERENCES = {
    allAccounts: allAccounts,
    myAccounts: myAccounts,
    noEmails: noEmails
  };

  var EMAIL_PREFERENCES_ARRAY = [allAccounts, myAccounts, noEmails];

  exports.EMAIL_PREFERENCES = EMAIL_PREFERENCES;
  exports.EMAIL_PREFERENCES_ARRAY = EMAIL_PREFERENCES_ARRAY;
});