define('quantuvis-cm-spa/components/cm-task-card', ['exports', 'ember', 'quantuvis-cm-spa/constants/date-format', 'quantuvis-cm-spa/constants/task-type', 'quantuvis-cm-spa/constants/task-object-type', 'moment'], function (exports, _ember, _quantuvisCmSpaConstantsDateFormat, _quantuvisCmSpaConstantsTaskType, _quantuvisCmSpaConstantsTaskObjectType, _moment) {
  exports['default'] = _ember['default'].Component.extend({
    DATE_FORMAT: _quantuvisCmSpaConstantsDateFormat['default'],
    classNames: ['cm-task-card'],

    description: _ember['default'].computed('task.description', 'task.createdDate', function () {
      var createdDate = (0, _moment['default'])(this.get('task.createdDate')).format(_quantuvisCmSpaConstantsDateFormat['default'].MM_DD_YYYY);

      return this.get('task.description').replace('{createdDate}', createdDate);
    }),

    redirectRoute: _ember['default'].computed('task.type.id', function () {
      var taskType = this.get('task.type.id');

      switch (taskType) {
        case _quantuvisCmSpaConstantsTaskType['default'].CONTRACT_RECEIVED_FROM_OTHER_PARTY:
          return 'create-contract';
        default:
          return 'contract-details';
      }
    }),

    redirectParam: _ember['default'].computed('task.type.id', 'task.contractId', 'task.objects', function () {
      var taskType = this.get('task.type.id');

      switch (taskType) {
        case _quantuvisCmSpaConstantsTaskType['default'].CONTRACT_RECEIVED_FROM_OTHER_PARTY:
          return this.getDocumentTypeId();
        default:
          return this.get('task.contractId');
      }
    }),

    getDocumentTypeId: function getDocumentTypeId() {
      return this.get('task.objects').find(function (object) {
        return object.type.id === _quantuvisCmSpaConstantsTaskObjectType['default'].DOCUMENT_TYPE;
      }).objectId;
    }
  });
});