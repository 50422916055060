define('quantuvis-cm-spa/controllers/business-admin/placeholders', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    session: _ember['default'].inject.service(),
    downloadFile: _ember['default'].inject.service(),
    loadingSpinner: _ember['default'].inject.service(),
    spinnerName: 'business-admin-spinner',
    actions: {
      download: function download() {
        var _this = this;

        this.get('loadingSpinner').show(this.get('spinnerName'));
        this.get('store').queryRecord('contracts/template-placeholders/download-presigned-url', {}).then(function (response) {
          _this.get('downloadFile').download(response.get('url'));
          _this.get('loadingSpinner').hide(_this.get('spinnerName'));
        })['catch'](function () {
          _this.get('loadingSpinner').hide(_this.get('spinnerName'));
        });
      }
    }
  });
});