define('quantuvis-cm-spa/routes/login/index', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    login: _ember['default'].inject.service(),

    beforeModel: function beforeModel() {
      var url = this.get('router.url');

      this.get('login').setRedirectUrl(url);
    }
  });
});