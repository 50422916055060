define('quantuvis-cm-spa/components/application-error/application-error', ['exports', 'ember', 'moment', 'quantuvis-cm-spa/constants/application-errors', 'quantuvis-cm-spa/constants/permissions'], function (exports, _ember, _moment, _quantuvisCmSpaConstantsApplicationErrors, _quantuvisCmSpaConstantsPermissions) {
  exports['default'] = _ember['default'].Component.extend({
    loadingSpinner: _ember['default'].inject.service(),
    permissionService: _ember['default'].inject.service(),
    router: _ember['default'].inject.service('-routing'),
    session: _ember['default'].inject.service(),

    currentYear: (0, _moment['default'])().year(),
    title: '',
    component: '',
    status: '',
    isUserHasCmAccess: false,
    isAuthenticatedUserWithNotFound: false,

    init: function init() {
      this._super.apply(this, arguments);
      this.get('loadingSpinner').stopGlobal();
      this.set('isUserHasCmAccess', this.get('permissionService').checkGlobalUserPermission(_quantuvisCmSpaConstantsPermissions.PERMISSIONS.ID.CM_ACCESS));

      var status = this.get('status');
      var statusProperties = _quantuvisCmSpaConstantsApplicationErrors.APPLICATION_ERRORS[status];

      if (statusProperties === _quantuvisCmSpaConstantsApplicationErrors.APPLICATION_ERRORS.NOT_FOUND) {
        this.set('isAuthenticatedUserWithNotFound', this.get('session.isAuthenticated'));
      }

      this.setProperties(statusProperties);
    },

    actions: {
      backToLoginPage: function backToLoginPage() {
        if (this.get('session.isAuthenticated')) {
          this.get('session').invalidate();
        } else {
          this.get('router').transitionTo('login');
        }
      }
    }
  });
});