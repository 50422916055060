define('quantuvis-cm-spa/components/thank-you-page/constants/brand-items', ['exports'], function (exports) {
  var brandItems = [{
    title: 'Mitigate Risks',
    messages: ['Lessen the volume and impact of expensive and relationship-breaking audits', 'Protect company documentation that can be compromised by M&A, employee turnover, and file loss', 'Allow quick identification of key challenges that impact profitability'],
    icon: '/assets/icons/check-mark'
  }, {
    title: 'Save Time',
    messages: ['Improved transparency in RFP/bid process enhances submission response time', 'Reduction in time to aggregate and analyze RFPs/bids', 'Retrieve documentation of bid negotiation in seconds', 'Offers a consistent report of the rates and terms that can be utilized across the company'],
    icon: '/assets/icons/sand-clock'
  }, {
    title: 'Reduce Errors',
    messages: ['Automation and standardization of bids into a consistent format all in one place', 'Replace a previously manual process', 'Reduce data entry errors that compromise profitability'],
    icon: '/assets/icons/target'
  }, {
    title: 'Increase Revenue',
    messages: ['Optimize formulary value', 'Real-time aggregation and financial evaluation of offers in a single comparative format in seconds', 'Allow focus to center on negotiation strategies'],
    icon: '/assets/icons/graph'
  }, {
    title: 'Improve Profitability',
    messages: ['Holistic evaluation of the complete contract value resulting in more informed business decisions', 'Better utilize resources needed to manage process', 'Improve receivables'],
    icon: '/assets/icons/dollar'
  }];

  exports['default'] = brandItems;
});