define('quantuvis-cm-spa/controllers/thank-you-page', ['exports', 'ember', 'quantuvis-cm-spa/components/thank-you-page/constants/index'], function (exports, _ember, _quantuvisCmSpaComponentsThankYouPageConstantsIndex) {

  var DEFAULT_EVENT = 'default';

  exports['default'] = _ember['default'].Controller.extend({
    session: _ember['default'].inject.service(),

    queryParams: ['event', 'envelopeId'],
    event: null,
    envelopeId: null,
    brandItems: _quantuvisCmSpaComponentsThankYouPageConstantsIndex.brandItems,

    eventData: _ember['default'].computed('event', function () {
      var event = this.get('event');
      var eventData = _quantuvisCmSpaComponentsThankYouPageConstantsIndex.events[event] || _quantuvisCmSpaComponentsThankYouPageConstantsIndex.events[DEFAULT_EVENT];

      return eventData;
    })
  });
});