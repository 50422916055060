define('quantuvis-cm-spa/controllers/edit-workflow', ['exports', 'ember', 'quantuvis-cm-spa/mixins/workflow-steps', 'quantuvis-cm-spa/mixins/scroll-to-validation-error'], function (exports, _ember, _quantuvisCmSpaMixinsWorkflowSteps, _quantuvisCmSpaMixinsScrollToValidationError) {
  exports['default'] = _ember['default'].Controller.extend(_quantuvisCmSpaMixinsWorkflowSteps['default'], _quantuvisCmSpaMixinsScrollToValidationError['default'], {
    queryParams: ['step', 'newStepPosition'],
    /**
     * Id of workflow step to scroll
     */
    step: null,
    /**
     * Position for new workflow step
     */
    newStepPosition: null,

    dialog: _ember['default'].inject.service(),
    workflow: _ember['default'].inject.service(),
    loadingSpinner: _ember['default'].inject.service(),
    changesetMap: new Map(),
    leads: [],

    actions: {
      saveWorkflow: function saveWorkflow(workflow) {
        var _this = this;

        this.get('loadingSpinner').show('cm-edit-workflow-spinner');
        this.get('workflow').update(workflow, this.get('changesetMap')).then(function () {
          _this.transitionToRoute('contract-details', workflow.get('contractId'));
          _this.get('loadingSpinner').hide('cm-edit-workflow-spinner');
        })['catch'](function (error) {
          _this.get('loadingSpinner').hide('cm-edit-workflow-spinner');
          _this.scrollToValidationError();

          _ember['default'].Logger.error('Error on update workflow', {
            error: error,
            workflow: workflow
          });
        });
      },

      backToContractWithConfirm: function backToContractWithConfirm(id) {
        var _this2 = this;

        var options = {
          confirmMessage: 'You have not yet saved your changes. All the data entered will be lost.\n         Are you sure you want to navigate away?'
        };

        if (this.isWorkflowChanged()) {
          this.get('dialog').show('dialogs/cm-dialog', 'dialog-templates/cm-confirmation', this, options).then(function () {
            _this2.transitionToRoute('contract-details', id);
          })['catch'](function () {});
        } else {
          this.transitionToRoute('contract-details', id);
        }
      },
      /**
       * Reset URL query params
       * @param data
       */
      resetQueryParams: function resetQueryParams(data) {
        window.history.replaceState({}, this.routeName, data.id);
      }
    },

    isWorkflowChanged: function isWorkflowChanged() {
      var isChanged = false;

      this.get('changesetMap').forEach(function (ch) {
        isChanged = isChanged || ch.get('isDirty');
      });

      return isChanged;
    }
  });
});