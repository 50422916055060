define('quantuvis-cm-spa/constants/workflow-step-departments-state', ['exports'], function (exports) {
  var _WORKFLOW_SUBSTEP_NAMES;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var WORKFLOW_STEP_DEPARTMENT_STATE = {
    PENDING: 1,
    COMPLETED: 2,
    VOIDED: 3,
    IN_PROGRESS: 4
  };

  var WORKFLOW_SUBSTEP_NAMES = (_WORKFLOW_SUBSTEP_NAMES = {}, _defineProperty(_WORKFLOW_SUBSTEP_NAMES, WORKFLOW_STEP_DEPARTMENT_STATE.PENDING, 'Pending'), _defineProperty(_WORKFLOW_SUBSTEP_NAMES, WORKFLOW_STEP_DEPARTMENT_STATE.COMPLETED, 'Completed'), _defineProperty(_WORKFLOW_SUBSTEP_NAMES, WORKFLOW_STEP_DEPARTMENT_STATE.VOIDED, 'Voided'), _defineProperty(_WORKFLOW_SUBSTEP_NAMES, WORKFLOW_STEP_DEPARTMENT_STATE.IN_PROGRESS, 'In Review'), _WORKFLOW_SUBSTEP_NAMES);

  exports.WORKFLOW_STEP_DEPARTMENT_STATE = WORKFLOW_STEP_DEPARTMENT_STATE;
  exports.WORKFLOW_SUBSTEP_NAMES = WORKFLOW_SUBSTEP_NAMES;
});