define('quantuvis-cm-spa/validations/custom-validators/unique', ['exports'], function (exports) {
  exports['default'] = unique;

  function unique(_ref) {
    var _ref$uniqueBy = _ref.uniqueBy;
    var uniqueBy = _ref$uniqueBy === undefined ? '' : _ref$uniqueBy;
    var options = _ref.options;
    var _ref$optionsIncludeVerifiedChangeset = _ref.optionsIncludeVerifiedChangeset;
    var optionsIncludeVerifiedChangeset = _ref$optionsIncludeVerifiedChangeset === undefined ? true : _ref$optionsIncludeVerifiedChangeset;

    var errorMessage = 'Must be unique';

    return function (key, newValue) {
      var optionsToCheck = uniqueBy ? options.mapBy(uniqueBy) : options;
      var hasDuplicate = undefined;

      if (optionsIncludeVerifiedChangeset) {
        hasDuplicate = optionsToCheck.filter(function (option) {
          return option === newValue;
        }).length > 1;
      } else {
        hasDuplicate = optionsToCheck.includes(newValue);
      }

      return hasDuplicate ? errorMessage : true;
    };
  }
});