define('quantuvis-cm-spa/adapters/contracts/note', ['exports', 'ember', 'quantuvis-cm-spa/adapters/application'], function (exports, _ember, _quantuvisCmSpaAdaptersApplication) {
  exports['default'] = _quantuvisCmSpaAdaptersApplication['default'].extend({
    contractDetails: _ember['default'].inject.service('contractDetails'),

    urlForFindAll: function urlForFindAll() {
      var contractId = this.get('contractDetails').get('contractId');

      return this.get('host') + '/' + this.get('namespace') + '/contracts/' + contractId + '/notes';
    },
    urlForQuery: function urlForQuery() {
      var contractId = this.get('contractDetails').get('contractId');

      return this.get('host') + '/' + this.get('namespace') + '/contracts/' + contractId + '/notes';
    },
    urlForCreateRecord: function urlForCreateRecord() {
      var contractId = this.get('contractDetails').get('contractId');

      return this.get('host') + '/' + this.get('namespace') + '/contracts/' + contractId + '/note';
    }
  });
});