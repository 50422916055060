define('quantuvis-cm-spa/services/download-file', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Service.extend({
    dialog: _ember['default'].inject.service(),

    download: function download(directFileUrl) {
      var newWindowHandle = window.open(directFileUrl);

      if (!newWindowHandle) {
        var options = {
          hideDeclineButton: true,
          context: this,
          title: 'Direct download not allowed'
        };

        this.get('dialog').show('dialogs/cm-dialog', 'dialog-templates/cm-download', { directFileUrl: directFileUrl }, options);
      }
    }
  });
});