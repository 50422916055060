define('quantuvis-cm-spa/constants/event-type', ['exports'], function (exports) {
  var EVENT_TYPE = {
    DOCUMENT_CREATED: 1,
    DOCUMENT_RECEIVED: 2,
    DOCUMENT_SENT_TO_COMPANY: 3,
    SIGNED_DOCUMENT_RECEIVED: 4,
    DOCUMENT_SIGNED_INTERNALLY: 5,
    DOCUMENT_EXECUTED: 6,
    DOCUMENT_DEACTIVATED: 7,
    DOCUMENT_ACTIVATED: 8,
    DOCUMENT_EXPIRED: 9,
    DOCUMENT_TERMINATED: 10,
    AMENDMENT_ADDED: 11,
    STEP_VOIDED: 12,
    NEW_VERSION_UPLOADED: 13,
    DOCUMENT_SENT_FOR_SIGN_OFF: 14,
    AMENDMENT_EXECUTED: 15,
    SIGNED_BY_BOTH_COMPANIES: 16,
    PARENT_CONTRACT_EXECUTED: 17,
    DOCUMENT_REINSTATED: 18,
    DOCUMENT_RECEIVED_READY_FOR_SIGN_OFF: 19,
    DOCUMENT_SENT_READY_FOR_SIGN_OFF: 20,
    SIGNED_DOCUMENT_SENT: 21,
    EXECUTED_DOCUMENT_CREATED: 22,
    SIGN_OFF_REQUEST_SENT: 23,
    SIGN_OFF_REQUEST_RECEIVED: 24,
    SIGN_OFF_REQUEST_ACCEPTED: 25,
    SIGN_OFF_REQUEST_ACCEPTED_COUNTERPARTY: 26,
    SIGN_OFF_REQUEST_DECLINED: 27,
    SIGN_OFF_REQUEST_DECLINED_COUNTERPARTY: 28,
    SIGN_OFF_CANCELLED: 29,
    SIGN_OFF_CANCELLED_COUNTERPARTY: 30,
    SIGN_OFF_SIGNED_BY_SIGNATORY: 31,
    SIGN_OFF_DECLINED: 32,
    SIGN_OFF_COMPLETED: 33,
    SIGN_OFF_UPDATED: 34,
    SIGN_OFF_UPDATED_COUNTERPARTY: 35,
    SIGN_OFF_EXPIRED: 36,
    SIGN_OFF_REASSIGNED: 37,
    AMENDMENT_TERMINATED: 38,
    AMENDMENT_REINSTATED: 39,
    EXECUTED_AMENDMENT_ADDED: 40
  };

  var EVENT_TYPE_TITLES = {};

  EVENT_TYPE_TITLES[EVENT_TYPE.DOCUMENT_CREATED] = '{document} created';
  EVENT_TYPE_TITLES[EVENT_TYPE.EXECUTED_DOCUMENT_CREATED] = 'Executed {document} created';
  EVENT_TYPE_TITLES[EVENT_TYPE.DOCUMENT_RECEIVED] = '{document} received';
  EVENT_TYPE_TITLES[EVENT_TYPE.DOCUMENT_SENT_TO_COMPANY] = '{document} sent to company';
  EVENT_TYPE_TITLES[EVENT_TYPE.SIGNED_DOCUMENT_RECEIVED] = 'Signed {document} received';
  EVENT_TYPE_TITLES[EVENT_TYPE.DOCUMENT_SIGNED_INTERNALLY] = '{document} signed internally';
  EVENT_TYPE_TITLES[EVENT_TYPE.DOCUMENT_EXECUTED] = '{document} executed';
  EVENT_TYPE_TITLES[EVENT_TYPE.DOCUMENT_DEACTIVATED] = '{document} deactivated';
  EVENT_TYPE_TITLES[EVENT_TYPE.DOCUMENT_ACTIVATED] = '{document} reactivated';
  EVENT_TYPE_TITLES[EVENT_TYPE.DOCUMENT_EXPIRED] = '{document} expired';
  EVENT_TYPE_TITLES[EVENT_TYPE.DOCUMENT_TERMINATED] = '{document} Terminated';
  EVENT_TYPE_TITLES[EVENT_TYPE.AMENDMENT_ADDED] = 'Amendment added';
  EVENT_TYPE_TITLES[EVENT_TYPE.STEP_VOIDED] = 'Step voided';
  EVENT_TYPE_TITLES[EVENT_TYPE.NEW_VERSION_UPLOADED] = 'New version uploaded';
  EVENT_TYPE_TITLES[EVENT_TYPE.DOCUMENT_SENT_FOR_SIGN_OFF] = '{document} sent for sign off';
  EVENT_TYPE_TITLES[EVENT_TYPE.AMENDMENT_EXECUTED] = 'Amendment executed';
  EVENT_TYPE_TITLES[EVENT_TYPE.SIGNED_BY_BOTH_COMPANIES] = 'Signed by both companies';
  EVENT_TYPE_TITLES[EVENT_TYPE.PARENT_CONTRACT_EXECUTED] = '{document} executed';
  EVENT_TYPE_TITLES[EVENT_TYPE.DOCUMENT_REINSTATED] = '{document} Reinstated';
  EVENT_TYPE_TITLES[EVENT_TYPE.DOCUMENT_RECEIVED_READY_FOR_SIGN_OFF] = '{document} received ready for sign off';
  EVENT_TYPE_TITLES[EVENT_TYPE.DOCUMENT_SENT_READY_FOR_SIGN_OFF] = '{document} sent ready for sign off';
  EVENT_TYPE_TITLES[EVENT_TYPE.SIGNED_DOCUMENT_SENT] = 'Signed {document} sent to company';
  EVENT_TYPE_TITLES[EVENT_TYPE.SIGN_OFF_REQUEST_SENT] = 'Sign Off Request was sent';
  EVENT_TYPE_TITLES[EVENT_TYPE.SIGN_OFF_REQUEST_RECEIVED] = 'Sign Off Request was received';
  EVENT_TYPE_TITLES[EVENT_TYPE.SIGN_OFF_REQUEST_ACCEPTED] = 'Sign Off Request was accepted';
  EVENT_TYPE_TITLES[EVENT_TYPE.SIGN_OFF_REQUEST_ACCEPTED_COUNTERPARTY] = 'Sign Off Request was accepted';
  EVENT_TYPE_TITLES[EVENT_TYPE.SIGN_OFF_REQUEST_DECLINED] = 'Sign Off Request was declined';
  EVENT_TYPE_TITLES[EVENT_TYPE.SIGN_OFF_REQUEST_DECLINED_COUNTERPARTY] = 'Sign Off Request was declined';
  EVENT_TYPE_TITLES[EVENT_TYPE.SIGN_OFF_CANCELLED] = 'Sign Off was cancelled';
  EVENT_TYPE_TITLES[EVENT_TYPE.SIGN_OFF_CANCELLED_COUNTERPARTY] = 'Sign Off was cancelled';
  EVENT_TYPE_TITLES[EVENT_TYPE.SIGN_OFF_COMPLETED] = 'Sign Off Completed';
  EVENT_TYPE_TITLES[EVENT_TYPE.SIGN_OFF_UPDATED_COUNTERPARTY] = 'Signature request was updated and resent';
  EVENT_TYPE_TITLES[EVENT_TYPE.SIGN_OFF_UPDATED] = 'Signature request was updated and resent';
  EVENT_TYPE_TITLES[EVENT_TYPE.SIGN_OFF_EXPIRED] = 'Sign Off Request Expired';
  EVENT_TYPE_TITLES[EVENT_TYPE.AMENDMENT_TERMINATED] = 'Amendment terminated';
  EVENT_TYPE_TITLES[EVENT_TYPE.AMENDMENT_REINSTATED] = 'Amendment reinstated';
  EVENT_TYPE_TITLES[EVENT_TYPE.EXECUTED_AMENDMENT_ADDED] = 'Executed Amendment added';

  exports.EVENT_TYPE = EVENT_TYPE;
  exports.EVENT_TYPE_TITLES = EVENT_TYPE_TITLES;
});