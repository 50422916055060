define('quantuvis-cm-spa/core-ui/components/cm-profile-image/component', ['exports', 'ember', 'quantuvis-cm-spa/mixins/add-test-id-attribute'], function (exports, _ember, _quantuvisCmSpaMixinsAddTestIdAttribute) {
  exports['default'] = _ember['default'].Component.extend(_quantuvisCmSpaMixinsAddTestIdAttribute['default'], {
    classNames: ['cm-profile-image-wrapper'],

    url: '',
    styles: _ember['default'].computed('url', function () {
      var url = this.get('url');

      return 'background-image: url(\'' + url + '\')';
    }),
    defaultImage: '/assets/images/profile/no-avatar.svg',
    hover: false,

    defaultImageClassNames: _ember['default'].computed('hover', function () {
      var classNames = ['cm-profile-image'];

      if (this.get('hover')) {
        classNames.push('cm-profile-image-hover');
      }

      return classNames.join(' ');
    })
  });
});