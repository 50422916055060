define('quantuvis-cm-spa/routes/initiate-sign-off', ['exports', 'ember', 'quantuvis-cm-spa/mixins/initiate-sign-off', 'rsvp'], function (exports, _ember, _quantuvisCmSpaMixinsInitiateSignOff, _rsvp) {
  exports['default'] = _ember['default'].Route.extend(_quantuvisCmSpaMixinsInitiateSignOff['default'], {
    signOff: _ember['default'].inject.service(),

    // contract: Object (initiate-sign-off mixin)
    // versions: Array (initiate-sign-off mixin)

    model: function model() {
      var _getProperties = this.getProperties('contract', 'versions');

      var contract = _getProperties.contract;
      var versions = _getProperties.versions;

      return _rsvp['default'].hash({
        contract: contract,
        versions: versions,
        account: this.get('store').findRecord('user-management/company', contract.get('accountId'), { reload: true }),
        lock: this.get('store').findRecord('signoff/contract-signoff/lock', contract.get('id'), { reload: true }),
        hasActiveLinkedContract: this.get('store').adapterFor('signoff/active-linked-lookup').activeLinkedContractLookup(contract.get('id')).then(function (_ref) {
          var data = _ref.data;

          return Promise.resolve(data.value);
        })
      });
    },

    activate: function activate() {
      var lock = this.modelFor('initiate-sign-off').lock;

      if (lock.get('expirationDate')) {
        this.get('signOff').showLockNotification(lock.get('expirationDate'));
      }
    },

    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller.set('processingInitiateSignOff', false);
    }
  });
});