define('quantuvis-cm-spa/constants/workflow-step-type', ['exports'], function (exports) {
  var CONTRACT_LEAD_REVIEW_ID = 1;
  var DEPARTMENT_REVIEW_ID = 2;
  var MULTI_DEPARTMENT_REVIEW_ID = 3;
  var DIGITAL_SIGN_OFF_ID = 4;
  var MANUAL_SIGN_OFF_ID = 5;

  var CONTRACT_LEAD_REVIEW = {
    id: CONTRACT_LEAD_REVIEW_ID,
    name: 'Contract Lead Review'
  };

  var DEPARTMENT_REVIEW = {
    id: DEPARTMENT_REVIEW_ID,
    name: 'Department Review'
  };

  var MULTI_DEPARTMENT_REVIEW = {
    id: MULTI_DEPARTMENT_REVIEW_ID,
    name: 'Multi-Department Review'
  };

  var DIGITAL_SIGN_OFF = {
    id: DIGITAL_SIGN_OFF_ID,
    name: 'Sign Off'
  };

  var MANUAL_SIGN_OFF = {
    id: MANUAL_SIGN_OFF_ID,
    name: 'Sign Off'
  };

  var STEP_TYPES = [CONTRACT_LEAD_REVIEW, DEPARTMENT_REVIEW, MULTI_DEPARTMENT_REVIEW, DIGITAL_SIGN_OFF, MANUAL_SIGN_OFF];

  var SELECTABLE_STEP_TYPES = [DEPARTMENT_REVIEW, CONTRACT_LEAD_REVIEW];

  exports.CONTRACT_LEAD_REVIEW_ID = CONTRACT_LEAD_REVIEW_ID;
  exports.DEPARTMENT_REVIEW_ID = DEPARTMENT_REVIEW_ID;
  exports.MULTI_DEPARTMENT_REVIEW_ID = MULTI_DEPARTMENT_REVIEW_ID;
  exports.DIGITAL_SIGN_OFF_ID = DIGITAL_SIGN_OFF_ID;
  exports.MANUAL_SIGN_OFF_ID = MANUAL_SIGN_OFF_ID;
  exports.CONTRACT_LEAD_REVIEW = CONTRACT_LEAD_REVIEW;
  exports.DEPARTMENT_REVIEW = DEPARTMENT_REVIEW;
  exports.MULTI_DEPARTMENT_REVIEW = MULTI_DEPARTMENT_REVIEW;
  exports.DIGITAL_SIGN_OFF = DIGITAL_SIGN_OFF;
  exports.MANUAL_SIGN_OFF = MANUAL_SIGN_OFF;
  exports.STEP_TYPES = STEP_TYPES;
  exports.SELECTABLE_STEP_TYPES = SELECTABLE_STEP_TYPES;
});