define('quantuvis-cm-spa/components/cm-timeline/cm-timeline-item/cm-timeline-header', ['exports', 'ember', 'quantuvis-cm-spa/constants/workflow-step-state', 'quantuvis-cm-spa/constants/workflow-substep-type', 'quantuvis-cm-spa/constants/event-type'], function (exports, _ember, _quantuvisCmSpaConstantsWorkflowStepState, _quantuvisCmSpaConstantsWorkflowSubstepType, _quantuvisCmSpaConstantsEventType) {

  var EVENTS_WITH_VIEW_DETAILS = [_quantuvisCmSpaConstantsEventType.EVENT_TYPE.SIGN_OFF_DECLINED, _quantuvisCmSpaConstantsEventType.EVENT_TYPE.SIGN_OFF_REASSIGNED];

  exports['default'] = _ember['default'].Component.extend({
    classNames: ['cm-timeline-header'],
    classNameBindings: ['bindStateClass', 'overdue:header-overdue'],
    timeline: _ember['default'].inject.service(),

    menuComponent: _ember['default'].computed('state', 'isSubitem', 'data', function () {
      var stateId = this.get('state.id');
      var menuNamePrefix = null;

      switch (stateId) {
        case _quantuvisCmSpaConstantsWorkflowStepState['default'].IN_PROGRESS:
          menuNamePrefix = 'cm-progress';
          break;
        case _quantuvisCmSpaConstantsWorkflowStepState['default'].PENDING:
          menuNamePrefix = 'cm-pending';
          break;
        case _quantuvisCmSpaConstantsWorkflowStepState['default'].COMPLETED:
        case _quantuvisCmSpaConstantsWorkflowStepState['default'].VOIDED:
          menuNamePrefix = 'cm-completed';
          break;
        default:
          return null;
      }

      var componentPath = 'cm-timeline/menus/';

      if (this.get('isSubitem')) {
        var typeId = this.get('data.type.id');
        var typeSuffix = '';

        if (stateId === _quantuvisCmSpaConstantsWorkflowStepState['default'].IN_PROGRESS) {
          switch (typeId) {
            case _quantuvisCmSpaConstantsWorkflowSubstepType['default'].CONTRACT_LEAD_REVIEW_ID:
              typeSuffix = '-lead';
              break;
            case _quantuvisCmSpaConstantsWorkflowSubstepType['default'].DEPARTMENT_REVIEW_ID:
              typeSuffix = '-department';
              break;
            default:
              return null;
          }
        }

        return '' + componentPath + menuNamePrefix + typeSuffix + '-sub-step-menu';
      } else {
        return '' + componentPath + menuNamePrefix + '-step-menu';
      }
    }),

    bindStateClass: _ember['default'].computed('state', function () {
      var stateId = this.get('state.id');

      switch (stateId) {
        case _quantuvisCmSpaConstantsWorkflowStepState['default'].IN_PROGRESS:
          return 'header-progress';
        case _quantuvisCmSpaConstantsWorkflowStepState['default'].PENDING:
          return 'header-pending';
        case _quantuvisCmSpaConstantsWorkflowStepState['default'].COMPLETED:
          return 'header-completed';
        case _quantuvisCmSpaConstantsWorkflowStepState['default'].VOIDED:
          return 'header-voided';
        default:
          return '';
      }
    }),

    isShowViewDetailsPopup: _ember['default'].computed('typeId', function () {
      return EVENTS_WITH_VIEW_DETAILS.includes(this.get('typeId'));
    }),

    actions: {
      showDetails: function showDetails() {
        var event = _ember['default'].Object.create({ properties: this.get('properties'), eventType: { id: this.get('typeId') } });

        this.get('timeline').showEventDetails(event);
      }
    }
  });
});