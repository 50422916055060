define('quantuvis-cm-spa/routes/profile-settings', ['exports', 'ember', 'quantuvis-cm-spa/mixins/authenticated-route-mixin'], function (exports, _ember, _quantuvisCmSpaMixinsAuthenticatedRouteMixin) {
  exports['default'] = _ember['default'].Route.extend(_quantuvisCmSpaMixinsAuthenticatedRouteMixin['default'], {
    profile: _ember['default'].inject.service(),

    model: function model() {
      return _ember['default'].RSVP.hash({
        profile: this.get('profile').getUserProfile(),
        timezones: this.get('store').findAll('user-management/users/timezone')
      });
    }
  });
});