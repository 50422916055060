define('quantuvis-cm-spa/models/workflows/template/step', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {
  exports['default'] = _emberData['default'].Model.extend(_ember['default'].Copyable, {
    positionSortingAsc: ['position:asc'],

    name: _emberData['default'].attr('string'),
    position: _emberData['default'].attr('number'),
    isRequired: _emberData['default'].attr('boolean'),
    notes: _emberData['default'].attr('string'),
    dueDateTemplate: _emberData['default'].attr(),
    type: _emberData['default'].attr(),

    substeps: _emberData['default'].hasMany('workflows/template/substep', { inverse: null, async: false }),
    substepsSortedByPosition: _ember['default'].computed.sort('substeps', 'positionSortingAsc'),

    copy: function copy() {
      var object = this.toJSON();
      var substeps = [];

      this.get('substeps').forEach(function (substep) {
        substeps.push(substep.toJSON());
      });

      object.substeps = substeps;

      return object;
    }
  });
});