define('quantuvis-cm-spa/transforms/unix-date', ['exports', 'ember-data', 'ember', 'moment', 'quantuvis-cm-spa/constants/date-format'], function (exports, _emberData, _ember, _moment, _quantuvisCmSpaConstantsDateFormat) {
  exports['default'] = _emberData['default'].Transform.extend({
    deserialize: function deserialize(serialized) {
      return _ember['default'].isEmpty(serialized) ? null : _moment['default'].tz(serialized, 'UTC').format(_quantuvisCmSpaConstantsDateFormat['default'].ISO_DATETIME);
    },

    serialize: function serialize(deserialized) {
      if ((0, _moment['default'])(deserialized, _quantuvisCmSpaConstantsDateFormat['default'].MM_DD_YYYY).isValid()) {
        return _moment['default'].tz(deserialized, _quantuvisCmSpaConstantsDateFormat['default'].MM_DD_YYYY, 'UTC').format('x');
      }

      return _ember['default'].isEmpty(deserialized) ? null : _moment['default'].utc(deserialized).format('x');
    }
  });
});