define('quantuvis-cm-spa/adapters/contracts/alert', ['exports', 'ember', 'quantuvis-cm-spa/adapters/application'], function (exports, _ember, _quantuvisCmSpaAdaptersApplication) {
  exports['default'] = _quantuvisCmSpaAdaptersApplication['default'].extend({
    contractDetails: _ember['default'].inject.service('contractDetails'),

    urlForFindAll: function urlForFindAll() {
      var contractId = this.get('contractDetails').get('contractId');

      return this.get('host') + '/' + this.get('namespace') + '/contracts/' + contractId + '/alerts';
    }
  });
});