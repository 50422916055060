define('quantuvis-cm-spa/services/contract-service', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Service.extend({
    store: _ember['default'].inject.service(),

    findById: function findById(id) {
      var reload = arguments.length <= 1 || arguments[1] === undefined ? false : arguments[1];

      return this.get('store').findRecord('contract', id, { reload: reload });
    },

    isContractLead: function isContractLead(_ref) {
      var userId = _ref.userId;
      var contract = _ref.contract;

      return contract.get('leads').isAny('id', '' + userId);
    }
  });
});