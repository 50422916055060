define('quantuvis-cm-spa/services/terminate-contract', ['exports', 'ember', 'moment', 'ember-changeset', 'ember-changeset-validations', 'quantuvis-cm-spa/validations/terminate-contract'], function (exports, _ember, _moment, _emberChangeset, _emberChangesetValidations, _quantuvisCmSpaValidationsTerminateContract) {
  exports['default'] = _ember['default'].Service.extend({
    dialog: _ember['default'].inject.service(),
    contractDetails: _ember['default'].inject.service(),
    alertService: _ember['default'].inject.service(),
    store: _ember['default'].inject.service(),
    model: {},
    changeset: {},

    showTerminateModal: function showTerminateModal() {
      this.initModel();
      this.initChangeset();
      var options = {
        className: 'cm-terminate-contract-dialog',
        componentName: 'cm-contract-details/cm-terminate-contract/cm-terminate-contract',
        title: 'Terminate this document',
        acceptText: 'Terminate',
        changeset: this.get('changeset'),
        acceptHandler: 'acceptEffectiveDate'
      };

      this.get('dialog').show('dialogs/cm-dialog', null, this, options);
    },

    initModel: function initModel() {
      this.set('model.effectiveDate', null);
    },

    initChangeset: function initChangeset() {
      var contract = this.get('contractDetails').getContract();

      var changeset = new _emberChangeset['default'](this.get('model'), (0, _emberChangesetValidations['default'])((0, _quantuvisCmSpaValidationsTerminateContract['default'])(contract)), (0, _quantuvisCmSpaValidationsTerminateContract['default'])(contract), {
        skipValidate: true
      });

      this.set('changeset', changeset);
    },

    showTerminateConfirmation: function showTerminateConfirmation(presenter) {
      var _this = this;

      var effectiveDate = this.get('changeset.effectiveDate');
      var options = {
        title: 'Confirmation',
        confirmMessage: 'Are you sure you would like to terminate this document on ' + effectiveDate + '?',
        acceptHandler: 'terminateDocument'
      };

      this.get('dialog').show('dialogs/cm-dialog', 'dialog-templates/cm-confirmation', this, options).then(function () {
        presenter.accept();
        _this.get('contractDetails').reloadContract();
        _this.get('alertService').loadAlerts();
        _this.get('contractDetails').loadTimelineItems();
      });
    },

    showReinstateConfirmation: function showReinstateConfirmation() {
      var _this2 = this;

      var options = {
        title: 'Reinstate this document',
        confirmMessage: 'Are you sure you would like to reinstate this document?',
        acceptHandler: 'reinstateDocument'
      };

      this.get('dialog').show('dialogs/cm-dialog', 'dialog-templates/cm-confirmation', this, options).then(function () {
        _this2.get('contractDetails').reloadContract();
        _this2.get('alertService').loadAlerts();
        _this2.get('contractDetails').loadTimelineItems();
      });
    },

    actions: {
      acceptEffectiveDate: function acceptEffectiveDate(presenter) {
        var _this3 = this;

        var changeset = this.get('changeset');

        changeset.validate().then(function () {
          if (changeset.get('isInvalid')) {
            return Promise.reject();
          } else {
            _this3.showTerminateConfirmation(presenter);
          }
        }).then()['catch']();
      },
      terminateDocument: function terminateDocument(presenter) {
        var _this4 = this;

        var contractId = this.get('contractDetails.contractId');

        this.set('showDialogSpinner', true);

        return this.get('store').adapterFor('application').put('contracts/' + contractId + '/terminate', '', {
          data: {
            effectiveDate: _moment['default'].utc(this.get('changeset.effectiveDate')).format('x'),
            reason: this.get('changeset.reason')
          }
        }).then(function () {
          _this4.set('showDialogSpinner', false);
          presenter.accept();
        })['catch'](function () {
          return _this4.set('showDialogSpinner', false);
        });
      },
      reinstateDocument: function reinstateDocument(presenter) {
        var _this5 = this;

        var contractId = this.get('contractDetails.contractId');

        this.set('showDialogSpinner', true);

        return this.get('store').adapterFor('application').put('contracts/' + contractId + '/reinstate', '').then(function () {
          _this5.set('showDialogSpinner', false);
          presenter.accept();
        })['catch'](function () {
          return _this5.set('showDialogSpinner', false);
        });
      }
    }
  });
});