define('quantuvis-cm-spa/services/delete-contract', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Service.extend({
    dialog: _ember['default'].inject.service(),
    contractDetails: _ember['default'].inject.service(),
    router: _ember['default'].inject.service('-routing'),
    store: _ember['default'].inject.service(),
    model: {},

    showConfirmation: function showConfirmation(model) {
      this.set('model', model);
      var options = {
        title: 'Confirmation',
        confirmMessage: 'Are you sure you would like to Delete the document? Deleting this document is permanent' + ' and irreversible',
        acceptHandler: 'deleteDocument'
      };

      this.get('dialog').show('dialogs/cm-dialog', 'dialog-templates/cm-confirmation', this, options);
    },

    actions: {
      deleteDocument: function deleteDocument(presenter) {
        var _this = this;

        this.set('showDialogSpinner', true);

        return this.get('model').destroyRecord().then(function () {
          presenter.accept();
          _this.set('showDialogSpinner', false);
          _this.get('router').transitionTo('contracts');
        })['catch'](function (error) {
          var options = {
            title: 'Error',
            infoMessage: error.payload.message,
            hideDeclineButton: true,
            acceptText: 'Ok'
          };

          presenter.accept();
          _this.set('showDialogSpinner', false);
          _this.get('dialog').show('dialogs/cm-dialog', 'dialog-templates/cm-info', _this, options);
        });
      }
    }
  });
});