define('quantuvis-cm-spa/components/sign-off/form/recipients', ['exports', 'ember', 'quantuvis-cm-spa/constants/user-query-type'], function (exports, _ember, _quantuvisCmSpaConstantsUserQueryType) {
  exports['default'] = _ember['default'].Component.extend({
    store: _ember['default'].inject.service(),
    contractDetails: _ember['default'].inject.service(),
    tagName: '',

    contract: {},

    externalCompany: null,
    internalCompany: null,

    internalCompanyInfo: null,
    externalCompanyInfo: null,

    recipientCompany: null,

    userOptions: [],
    contactPersonsOptions: [],

    partiesCheckedObserver: _ember['default'].on('init', _ember['default'].observer('internalCompany.isChecked', 'externalCompany.isChecked', function () {
      var newRecipient = null;

      if (this.get('internalCompany.isChecked')) {
        newRecipient = this.get('internalCompanyInfo');
      } else if (this.get('externalCompany.isChecked')) {
        newRecipient = this.get('externalCompanyInfo');
      }
      this.set('recipientCompany', newRecipient);
    })),

    init: function init() {
      this._super.apply(this, arguments);
      this.setInitData();
    },

    setInitData: function setInitData() {
      this.set('internalCompanyInfo', _ember['default'].Object.create({
        company: this.get('internalCompany'),
        options: this.get('userOptions')
      }));
      this.set('externalCompanyInfo', _ember['default'].Object.create({
        company: this.get('externalCompany'),
        options: this.get('contactPersonsOptions')
      }));
      this.fetchUsers();
      this.fetchContactPersons();
    },

    fetchUsers: function fetchUsers() {
      var _this = this;

      this.get('store').query('user-management/user', {
        type: _quantuvisCmSpaConstantsUserQueryType['default'].COMMON
      }).then(function (data) {
        _this.setProperties({ userOptions: _this.prepareUsersOptions(data) });
        _this.get('internalCompanyInfo').set('options', _this.get('userOptions'));
      })['catch'](function (error) {
        _ember['default'].Logger.warn('fetchUsers Error:', error);
      });
    },

    fetchContactPersons: function fetchContactPersons() {
      var _this2 = this;

      this.set('contractDetails.contractId', this.get('contract.id'));
      this.get('store').unloadAll('contracts/contact-person');
      this.get('store').findAll('contracts/contact-person', { reload: true }).then(function (data) {
        _this2.setProperties({ contactPersonsOptions: _this2.prepareContactPersonsOptions(data) });
        _this2.get('externalCompanyInfo').set('options', _this2.get('contactPersonsOptions'));
      })['catch'](function (error) {
        _ember['default'].Logger.warn('fetchContactPersons Error:', error);
      });
    },

    prepareContactPersonsOptions: function prepareContactPersonsOptions(persons) {
      return persons.map(function (person) {
        var _person$getProperties = person.getProperties('id', 'name', 'email', 'role');

        var id = _person$getProperties.id;
        var name = _person$getProperties.name;
        var email = _person$getProperties.email;
        var role = _person$getProperties.role;

        return _ember['default'].Object.create({ id: id, name: name, email: email, title: role || '' });
      });
    },

    prepareUsersOptions: function prepareUsersOptions(users) {
      return users.map(function (person) {
        var _person$getProperties2 = person.getProperties('id', 'fullName', 'email', 'jobTitle');

        var id = _person$getProperties2.id;
        var fullName = _person$getProperties2.fullName;
        var email = _person$getProperties2.email;
        var jobTitle = _person$getProperties2.jobTitle;

        return _ember['default'].Object.create({ id: id, name: fullName, email: email, title: jobTitle || '' });
      });
    }
  });
});