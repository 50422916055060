define('quantuvis-cm-spa/models/notification', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    title: _emberData['default'].attr('string'),
    contractId: _emberData['default'].attr(),
    message: _emberData['default'].attr('string'),
    createdDate: _emberData['default'].attr('unix-timestamp'),
    completedDate: _emberData['default'].attr('unix-timestamp'),
    dueDate: _emberData['default'].attr('unix-date'),
    type: _emberData['default'].attr('string'),
    isNew: _emberData['default'].attr('boolean'),
    isCompleted: _emberData['default'].attr('boolean'),
    isOverdue: _emberData['default'].attr('boolean'),
    taskType: _emberData['default'].attr(),
    taskObjects: _emberData['default'].attr(),
    eventType: _emberData['default'].attr()
  });
});