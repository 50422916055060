define('quantuvis-cm-spa/models/search/workflow-substep', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    name: _emberData['default'].attr('string'),
    stateId: _emberData['default'].attr('number'),
    typeId: _emberData['default'].attr('number'),
    position: _emberData['default'].attr('number'),
    dueDate: _emberData['default'].attr('unix-date'),
    departmentName: _emberData['default'].attr('string'),
    completedDate: _emberData['default'].attr('unix-timestamp')
  });
});