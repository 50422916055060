define('quantuvis-cm-spa/constants/tooltip-messages', ['exports'], function (exports) {
  var MESSAGES = {
    CLOSE_AMENDMENTS: 'Close Amendments',
    VIEW_AMENDMENTS: 'View Amendments',
    PREVIEW_DOCUMENT: 'Preview document',
    DEVICE_ACTIVITY: 'Review recent device activity on your account',
    CLOSE_FILTER_PANEL: 'Close filter panel',
    NOTIFICATIONS: 'Notifications',
    PROFILE: 'Profile',
    VIEW_VERSION: 'View Version',
    DOWNLOAD_VERSION: 'Download Version',
    COLLAPSE_STEP: 'Collapse step',
    EXPAND_STEP: 'Expand step',
    WORKFLOW_STEP_DUE_DATE: 'Selecting a due date creates automated emails and alerts\n    within the system to users as a due date approaches or is passed',
    WORKFLOW_STEP_DEPARTMENT: 'A specific department within your organization',
    WORKFLOW_STEP_EXTENDED_MOVE_UP: 'Move Step up in workflow order',
    WORKFLOW_STEP_EXTENDED_MOVE_DOWN: 'Move Step down in workflow order',
    WORKFLOW_STEP_EXTENDED_DELETE: 'Delete step',
    WORKFLOW_STEP_EXTENDED_DRAG_AND_DROP: 'drag and drop to rearrange step order in workflow',
    WORKFLOW_STEP_NOTES: 'Add any notes related to this step',
    WORKFLOW_STEP_TYPE_INPUT: 'Lead review is a review of the contract by the contract lead.\n    Department review is assigned to a specific department within your company.',
    CREATE_DOCUMENT_COMPANY: 'Company you are creating a contract with',
    OPEN_FILTER_PANEL: 'Open filter panel',
    TASKS: 'These are tasks that you are responsible for',
    EVENTS: 'These are events related to your contracts',
    START_DATE_RANGE: 'The date range in which the document start date is or was effective.',
    END_DATE_RANGE: 'The date range in which the document end date is or was effective.'
  };

  exports['default'] = MESSAGES;
});