define('quantuvis-cm-spa/services/accept-sign-off', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
  exports['default'] = _ember['default'].Service.extend({
    generateParties: function generateParties(_ref) {
      var companies = _ref.companies;
      var internalCompanyId = _ref.internalCompanyId;

      return companies.map(function (company) {
        var _company$getProperties = company.getProperties('id', 'name', 'type');

        var id = _company$getProperties.id;
        var name = _company$getProperties.name;
        var type = _company$getProperties.type;

        return _ember['default'].Object.create({
          id: Number(id),
          signingOrder: null,
          signatories: [],
          isChecked: internalCompanyId === company.get('id'),
          isDisabled: true,
          name: name,
          type: type
        });
      });
    },

    generateDataToSave: function generateDataToSave(_ref2) {
      var changeset = _ref2.changeset;
      var originContractId = _ref2.originContractId;

      var _changeset$getProperties = changeset.getProperties('documentVersion', 'dueDate', 'parties', 'subject', 'message');

      var documentVersion = _changeset$getProperties.documentVersion;
      var dueDate = _changeset$getProperties.dueDate;
      var partiesWithSignatories = _changeset$getProperties.parties;
      var subject = _changeset$getProperties.subject;
      var message = _changeset$getProperties.message;

      var company = partiesWithSignatories.find(function (_ref3) {
        var isChecked = _ref3.isChecked;
        return isChecked;
      });

      var signers = company.get('signatories').map(function (signatory) {
        var _signatory$getProperties = signatory.getProperties('id', 'name', 'email', 'title');

        var id = _signatory$getProperties.id;
        var name = _signatory$getProperties.name;
        var email = _signatory$getProperties.email;
        var title = _signatory$getProperties.title;

        var signatoryObj = { name: name, email: email, title: title };

        if (id) {
          signatoryObj.userId = id;
        }

        return signatoryObj;
      });

      return {
        originAttachmentId: documentVersion.get('id'),
        companyId: company.get('id'),
        dueDate: _moment['default'].utc(dueDate).valueOf(),
        originContractId: originContractId,
        signers: signers,
        emailSubject: subject,
        emailMessage: message
      };
    }
  });
});