define('quantuvis-cm-spa/models/workflows/workflow-substep-preset', ['exports', 'quantuvis-cm-spa/constants/workflow-step-departments-state', 'quantuvis-cm-spa/constants/workflow-substep-type'], function (exports, _quantuvisCmSpaConstantsWorkflowStepDepartmentsState, _quantuvisCmSpaConstantsWorkflowSubstepType) {
  function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError('Cannot call a class as a function'); } }

  var _default = function _default() {
    _classCallCheck(this, _default);

    this.id = null;
    this.dueDate = null;
    this.notes = null;
    this.type = {
      id: _quantuvisCmSpaConstantsWorkflowSubstepType.DEPARTMENT_REVIEW_ID
    };
    this.state = {
      id: _quantuvisCmSpaConstantsWorkflowStepDepartmentsState.WORKFLOW_STEP_DEPARTMENT_STATE.PENDING
    };
    this.position = 0;
    this.responsiblePersons = [];
    this.department = null;
    this.departmentId = null;
  };

  exports['default'] = _default;
});