define('quantuvis-cm-spa/models/search/contracts/executed-contract', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    name: _emberData['default'].attr('string'),
    number: _emberData['default'].attr('string'),
    companyId: _emberData['default'].attr('number'),
    companyName: _emberData['default'].attr('string'),
    lineOfBusinessName: _emberData['default'].attr('string'),
    evergreen: _emberData['default'].attr('boolean'),
    endDate: _emberData['default'].attr('unix-date'),
    startDate: _emberData['default'].attr('unix-date'),
    contracts: _emberData['default'].attr(),
    bids: _emberData['default'].attr(),
    leadIds: _emberData['default'].attr()
  });
});