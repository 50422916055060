define('quantuvis-cm-spa/app-config', ['exports', 'quantuvis-cm-spa/config/environment'], function (exports, _quantuvisCmSpaConfigEnvironment) {

  var host = _quantuvisCmSpaConfigEnvironment['default'].APP.services.api.host ? _quantuvisCmSpaConfigEnvironment['default'].APP.services.api.host : window.location.hostname;
  var protocol = _quantuvisCmSpaConfigEnvironment['default'].APP.services.api.protocol ? _quantuvisCmSpaConfigEnvironment['default'].APP.services.api.protocol : window.location.protocol;
  var port = _quantuvisCmSpaConfigEnvironment['default'].APP.services.api.port ? ':' + _quantuvisCmSpaConfigEnvironment['default'].APP.services.api.port : '';

  exports['default'] = {
    apiUrl: protocol + '//' + host + port,
    apiPrefix: _quantuvisCmSpaConfigEnvironment['default'].APP.services.api.prefix,

    applicationId: _quantuvisCmSpaConfigEnvironment['default'].APP.services.auth.applicationId,
    applicationKey: _quantuvisCmSpaConfigEnvironment['default'].APP.services.auth.applicationKey
  };
});