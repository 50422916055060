define('quantuvis-cm-spa/components/cm-timeline/cm-timeline-item/cm-timeline-digital-sign-off-substep', ['exports', 'ember', 'quantuvis-cm-spa/constants/workflow-step-state', 'quantuvis-cm-spa/components/cm-timeline/cm-timeline-item/cm-workflow-step-base'], function (exports, _ember, _quantuvisCmSpaConstantsWorkflowStepState, _quantuvisCmSpaComponentsCmTimelineCmTimelineItemCmWorkflowStepBase) {
  exports['default'] = _quantuvisCmSpaComponentsCmTimelineCmTimelineItemCmWorkflowStepBase['default'].extend({
    timeline: _ember['default'].inject.service(),
    classNames: ['cm-timeline-digital-sign-off-substep'],

    classNameBindings: ['bindWorkflowStepClass'],

    state: _ember['default'].computed('data.state', function () {
      var stateId = this.get('data.state.id');

      switch (stateId) {
        case _quantuvisCmSpaConstantsWorkflowStepState['default'].IN_PROGRESS:
        case _quantuvisCmSpaConstantsWorkflowStepState['default'].PENDING:
          return 'Pending';
        case _quantuvisCmSpaConstantsWorkflowStepState['default'].COMPLETED:
          return 'Signed';
        case _quantuvisCmSpaConstantsWorkflowStepState['default'].VOIDED:
        case _quantuvisCmSpaConstantsWorkflowStepState['default'].REJECTED:
          return 'Rejected';
        default:
          return '';
      }
    }),

    titleElement: _ember['default'].computed('data.responsiblePersonsAccounts', function () {
      var responsiblePersons = this.get('data.responsiblePersonsAccounts');

      if (responsiblePersons && responsiblePersons.length !== 0) {
        var firstResponsiblePerson = responsiblePersons[0];

        var departmentsString = this.get('timeline').getDepartmentString(firstResponsiblePerson.departments);

        return {
          personName: firstResponsiblePerson.firstName + ' ' + firstResponsiblePerson.lastName,
          departmentsString: departmentsString
        };
      }
    })
  });
});