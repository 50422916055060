define('quantuvis-cm-spa/adapters/workflows/steps/change-state', ['exports', 'quantuvis-cm-spa/adapters/application', 'ember-inflector'], function (exports, _quantuvisCmSpaAdaptersApplication, _emberInflector) {
  exports['default'] = _quantuvisCmSpaAdaptersApplication['default'].extend({
    pathForType: function pathForType(type) {
      return (0, _emberInflector.singularize)(type);
    },

    put: function put(type, id, options) {
      return this.ajax(this.buildURL(type, id), 'PUT', options);
    }
  });
});