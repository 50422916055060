define('quantuvis-cm-spa/controllers/accept-sign-off', ['exports', 'ember', 'quantuvis-cm-spa/mixins/scroll-to-validation-error', 'quantuvis-cm-spa/constants/sign-off/quote'], function (exports, _ember, _quantuvisCmSpaMixinsScrollToValidationError, _quantuvisCmSpaConstantsSignOffQuote) {
  exports['default'] = _ember['default'].Controller.extend(_quantuvisCmSpaMixinsScrollToValidationError['default'], {
    window: _ember['default'].inject.service(),
    dialogManager: _ember['default'].inject.service(),
    dialog: _ember['default'].inject.service(),
    signOffView: _ember['default'].inject.service(),
    acceptSignOffValidator: _ember['default'].inject.service(),
    signOff: _ember['default'].inject.service(),
    userSession: _ember['default'].inject.service(),

    processingInitiateSignOff: false,

    redirectToContractDetails: function redirectToContractDetails() {
      return this.transitionToRoute('contract-details', this.get('model.contract.id'));
    },

    showConfirmDialog: function showConfirmDialog() {
      var options = {
        confirmMessage: 'Are you sure you want to navigate out of the wizard?\n        All the data already entered will be lost.'
      };

      return this.get('dialog').show('dialogs/cm-dialog', 'dialog-templates/cm-confirmation', this, options);
    },

    acceptSignOff: function acceptSignOff(data) {
      var _this = this;

      this.get('signOff').accept(this.get('model.signOff.id'), data)['catch'](function (error) {
        return _ember['default'].Logger.error('Failed to accept sign off', error);
      }).then(function () {
        return _this.get('userSession').refreshToken();
      }).then(function () {
        return _this.get('signOffView').createSenderViewLink(_this.get('model.signOff.id'));
      }).then(function (senderViewUrl) {
        _this.get('acceptSignOffValidator').removeValidations();
        _this.get('window').getLocation().href = senderViewUrl;
      })['catch'](function () {
        _this.set('processingInitiateSignOff', false);
        _this.get('dialogManager').showDefaultError();
      });
    },

    showQuoteExceededErrorPopup: function showQuoteExceededErrorPopup() {
      var options = {
        componentName: 'sign-off/quote-exceeded',
        title: 'Error',
        hideDeclineButton: true,
        acceptText: 'Ok'
      };

      this.get('dialog').show('dialogs/cm-dialog', null, null, options);
    },

    actions: {
      onFinish: function onFinish() {
        var _this2 = this;

        this.get('acceptSignOffValidator').validate().then(function (isAllValid) {
          if (!isAllValid) {
            return _this2.scrollToValidationError();
          }

          _this2.set('processingInitiateSignOff', true);
          _this2.get('signOff').getLock(_this2.get('model.contract.id')).then(function (lock) {
            _this2.set('processingInitiateSignOff', false);

            if (lock.get('expirationDate')) {
              _this2.get('signOff').showLockNotification(lock.get('expirationDate'));
            } else {
              return _ember['default'].$('#sign-off-form').submit();
            }
          })['catch'](function () {
            return _this2.set('processingInitiateSignOff', false);
          });
        });
      },

      onSubmit: function onSubmit(data) {
        var _this3 = this;

        this.set('processingInitiateSignOff', true);

        this.get('signOff').getQuote(this.get('model.contract.id'), this.get('model.contract.company.id')).then(function (quote) {
          if (quote && quote.quote < _quantuvisCmSpaConstantsSignOffQuote['default']) {
            _this3.set('processingInitiateSignOff', false);

            _this3.showQuoteExceededErrorPopup();
          } else {
            _this3.acceptSignOff(data);
          }
        })['catch'](function () {
          _this3.set('processingInitiateSignOff', false);
        });
      },

      handleCancel: function handleCancel() {
        var _this4 = this;

        return this.showConfirmDialog().then(function () {
          _this4.get('acceptSignOffValidator').removeValidations();
          _this4.redirectToContractDetails();
        });
      }
    }
  });
});