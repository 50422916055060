define('quantuvis-cm-spa/services/contact-person', ['exports', 'ember', 'quantuvis-cm-spa/constants/permissions', 'quantuvis-cm-spa/constants/contact-type'], function (exports, _ember, _quantuvisCmSpaConstantsPermissions, _quantuvisCmSpaConstantsContactType) {
  exports['default'] = _ember['default'].Service.extend({
    dialog: _ember['default'].inject.service(),
    store: _ember['default'].inject.service(),
    companyId: null,
    contactPersonsCheckForUpdateProperties: ['email', 'role', 'phone', 'cell'],

    dialogOptions: {
      confirmMessage: 'Do you want to remove this Contact?'
    },

    animationDuration: 200,

    getEmptyContactModel: function getEmptyContactModel() {
      return {
        id: null,
        contactType: _quantuvisCmSpaConstantsContactType['default'].EXISTING,
        isContactNameNotSelected: true,
        contactPerson: null,
        role: null,
        email: null,
        phone: null,
        cell: null
      };
    },

    showPersonDeleteConfirmationDialog: function showPersonDeleteConfirmationDialog() {
      return this.get('dialog').show('dialogs/cm-dialog', 'dialog-templates/cm-confirmation', this, this.dialogOptions);
    },

    /**
     * Delete contact person from changesets
     * @param index - the index of person on UI
     * @param contractPersonsChangesets - local array of changesets in component
     * @param modelChangesetPersons - array of changesets taken from model
     */
    deletePerson: function deletePerson(index, contractPersonsChangesets, modelChangesetPersons) {
      var changesetToDelete = contractPersonsChangesets[index];

      contractPersonsChangesets.removeObject(changesetToDelete);

      var personToDelete = modelChangesetPersons[index];

      modelChangesetPersons.removeObject(personToDelete);

      return changesetToDelete.get('id');
    },

    getContactPersonById: function getContactPersonById(options, personId) {
      return options.find(function (person) {
        return person.id === personId;
      });
    },

    /**
     * Validate persons and return validation promises
     * @param changesets - array of changesets
     * @returns array of validation promises
     */
    validatePersons: function validatePersons(changesets) {
      return changesets.map(function (changeset) {
        return changeset.validate();
      });
    },

    fetchContactPersons: function fetchContactPersons(companyId) {
      return this.get('store').query('user-management/companies/contact-person', {
        companyId: companyId,
        applicationIds: _quantuvisCmSpaConstantsPermissions.PERMISSIONS.APPLICATION.CM
      }).then(function (contactPersons) {
        return contactPersons.map(function (person) {
          return _ember['default'].merge({ id: person.get('id') }, person.get('data'));
        });
      });
    },

    isContactPersonChanged: function isContactPersonChanged(contactPerson) {
      var oldContactPerson = _ember['default'].get(contactPerson, 'contactPerson');

      if (!_ember['default'].get(oldContactPerson, 'id')) {
        return true;
      }

      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = this.contactPersonsCheckForUpdateProperties[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var prop = _step.value;

          if (_ember['default'].get(oldContactPerson, prop) !== _ember['default'].get(contactPerson, prop)) {
            return true;
          }
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator['return']) {
            _iterator['return']();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      return false;
    }
  });
});