define('quantuvis-cm-spa/components/create-document/cm-company', ['exports', 'ember', 'quantuvis-cm-spa/core-ui/components/cm-wizard/cm-step/component', 'ember-changeset', 'quantuvis-cm-spa/validations/add-company', 'quantuvis-cm-spa/mixins/scroll-to-validation-error', 'quantuvis-cm-spa/validations/company-contact-person', 'ember-changeset-validations', 'quantuvis-cm-spa/constants/permissions', 'quantuvis-cm-spa/constants/tooltip-messages'], function (exports, _ember, _quantuvisCmSpaCoreUiComponentsCmWizardCmStepComponent, _emberChangeset, _quantuvisCmSpaValidationsAddCompany, _quantuvisCmSpaMixinsScrollToValidationError, _quantuvisCmSpaValidationsCompanyContactPerson, _emberChangesetValidations, _quantuvisCmSpaConstantsPermissions, _quantuvisCmSpaConstantsTooltipMessages) {
  exports['default'] = _quantuvisCmSpaCoreUiComponentsCmWizardCmStepComponent['default'].extend(_quantuvisCmSpaMixinsScrollToValidationError['default'], {
    company: _ember['default'].inject.service(),
    store: _ember['default'].inject.service(),
    documentWizard: _ember['default'].inject.service(),
    contactPerson: _ember['default'].inject.service(),
    workflowTemplate: _ember['default'].inject.service(),
    isCompanyNameNotSelected: true,
    isLoading: false,
    companySelectorDisabled: false,
    companyContactPersons: [],
    contractPersonsChangesets: [],
    changeset: null,
    TOOLTIP_MESSAGES: _quantuvisCmSpaConstantsTooltipMessages['default'],

    init: function init() {
      this._super.apply(this, arguments);
      this.fetchCompanies();
      this.initChangesets();
      this.setServiceData();
    },

    setServiceData: function setServiceData() {
      var selectedCompany = this.get('documentWizard.model.company');

      this.set('contactNamesOptions', []);
      this.set('isCompanyNameNotSelected', !selectedCompany);

      if (selectedCompany) {
        this.setContactPersonsOptions(selectedCompany.id);
      }
    },

    setContactPersonsOptions: function setContactPersonsOptions(companyId) {
      var _this = this;

      this.get('contactPerson').fetchContactPersons(companyId).then(function (contactPersons) {
        var alreadyChosenPersons = _this.get('documentWizard.model.contactPersons');
        var companyContactPersons = _ember['default'].copy(contactPersons, true);

        if (alreadyChosenPersons) {
          (function () {
            var alreadyChosenPersonsIds = alreadyChosenPersons.map(function (person) {
              return person.id;
            });

            companyContactPersons = companyContactPersons.filter(function (person) {
              return !alreadyChosenPersonsIds.includes(person.id);
            });
          })();
        }

        _this.set('companyContactPersons', contactPersons);
        _this.set('contactNamesOptions', companyContactPersons);
        _this.set('isLoading', false);
      })['catch'](function () {
        _this.set('isLoading', false);
      });
    },

    createContactPerson: function createContactPerson() {
      var emptyContactPerson = this.get('contactPerson').getEmptyContactModel();

      this.get('documentWizard.model.contactPersons').addObject(emptyContactPerson);
      this.createContactPersonChangeset(emptyContactPerson);
    },

    createContactPersonChangeset: function createContactPersonChangeset(contactPerson) {
      var changeset = new _emberChangeset['default'](contactPerson, (0, _emberChangesetValidations['default'])((0, _quantuvisCmSpaValidationsCompanyContactPerson['default'])({ options: this.get('contractPersonsChangesets') })), (0, _quantuvisCmSpaValidationsCompanyContactPerson['default'])({ options: this.get('contractPersonsChangesets') }), {
        skipValidate: true
      });

      this.get('contractPersonsChangesets').addObject(changeset);
    },

    initChangesets: function initChangesets() {
      var changeset = new _emberChangeset['default'](this.get('documentWizard.model'), (0, _emberChangesetValidations['default'])(_quantuvisCmSpaValidationsAddCompany['default']), _quantuvisCmSpaValidationsAddCompany['default'], {
        skipValidate: true
      });

      this.set('changeset', changeset);

      var documentWizardContactPersons = this.get('documentWizard.model.contactPersons');

      this.set('contractPersonsChangesets', []);

      if (documentWizardContactPersons.length) {
        for (var i = 0; i < documentWizardContactPersons.length; i++) {
          this.createContactPersonChangeset(documentWizardContactPersons[i]);
        }
      } else {
        this.createContactPerson();
      }
    },

    fetchCompanies: function fetchCompanies() {
      var _this2 = this;

      this.set('isLoading', true);
      this.get('store').query('user-management/company', { permission: _quantuvisCmSpaConstantsPermissions.PERMISSIONS.ID.CREATE_DOCUMENT }).then(function (companies) {
        _this2.set('companiesOptions', companies);
        _this2.prepopulateCompanyIfNeeded(companies);
        _this2.set('isLoading', false);
      })['catch'](function () {
        _this2.set('isLoading', false);
      });
    },

    prepopulateCompanyIfNeeded: function prepopulateCompanyIfNeeded(companies) {
      var prepopulatedId = this.get('documentWizard.prepopulatedProperties.companyId');

      if (prepopulatedId) {
        var matchingCompany = companies.find(function (company) {
          return company.id === prepopulatedId;
        });

        if (matchingCompany) {
          this.set('companySelectorDisabled', true);

          if (this.get('changeset.company.id') !== prepopulatedId) {
            this.set('changeset.company', matchingCompany);
            this.companyChanged(matchingCompany);
            this.get('workflowTemplate').resetSelectedTemplate();
          }
        }
      }
    },

    companyChanged: function companyChanged(company) {
      var _this3 = this;

      this.get('contactPerson').companyId = company.id;
      this.set('changeset.company.fullAddress', this.get('company').joinCompanyAddress(company.get('address')).join('\n'));

      this.set('isLoading', true);
      this.get('contactPerson').fetchContactPersons(company.id).then(function (contactPersons) {
        _this3.set('companyContactPersons', contactPersons);
        _this3.set('contactNamesOptions', _ember['default'].copy(contactPersons, true));

        _this3.set('isCompanyNameNotSelected', false);
        _this3.get('changeset').set('selectedBids', null);

        _this3.set('isLoading', false);
      })['catch'](function () {
        _this3.set('isLoading', false);
      });
    },

    saveContactPersonsChangesets: function saveContactPersonsChangesets() {
      var changesets = this.get('contractPersonsChangesets');

      for (var i = 0; i < changesets.length; i++) {
        changesets[i].save();
      }
    },

    actions: {
      submit: function submit(model) {
        var _this4 = this;

        var changesets = this.get('contractPersonsChangesets');
        var promises = this.get('contactPerson').validatePersons(changesets);

        Promise.all(promises).then(function () {
          var invalidChangesets = changesets.filter(function (changeset) {
            return changeset.get('isInvalid');
          });

          if (invalidChangesets.length === 0) {
            (function () {

              _this4.saveContactPersonsChangesets();

              _this4.set('documentWizard.wizardSteps.company.isCompleted', true);
              var resolveFunc = _this4.get('resolve');

              model.save().then(function () {
                resolveFunc(true);
              })['catch'](function () {
                // set server errors
              });
            })();
          }
        });
      },

      addContactPerson: function addContactPerson() {
        _ember['default'].run.scheduleOnce('render', this, function () {
          this.$('.contact-person').last().css('display', 'none');
        });

        _ember['default'].run.scheduleOnce('afterRender', this, function () {
          this.$('.contact-person').last().show(this.animationDuration);
        });

        this.createContactPerson();
      },

      companyNameChanged: function companyNameChanged(updateCallback, company) {
        updateCallback(company);
        this.companyChanged(company);
        this.clearChanges();
        this.createContactPerson();
      },

      deleteContactPerson: function deleteContactPerson(index) {
        var _this5 = this;

        this.get('contactPerson').showPersonDeleteConfirmationDialog().then(function () {
          _this5.deletePerson(index);
        })['catch'](function () {});
      }
    },

    /**
     * @todo add tests!!!
     */
    deletePerson: function deletePerson(index) {
      var _this6 = this;

      this.$('.contact-block-' + index).hide(this.get('contactPerson').animationDuration, function () {
        var deletedContactPersonId = _this6.get('contactPerson').deletePerson(index, _this6.get('contractPersonsChangesets'), _this6.get('documentWizard.model.contactPersons'));

        if (deletedContactPersonId) {
          var deletedContactPersonOption = _this6.get('contactPerson').getContactPersonById(_this6.get('companyContactPersons'), deletedContactPersonId);

          _this6.get('contactNamesOptions').pushObject(deletedContactPersonOption);
          _this6.set('contactNamesOptions', _this6.get('contactNamesOptions').sortBy('id'));
        }
      });
    },

    clearChanges: function clearChanges() {
      this.get('documentWizard').clearModel();
      this.set('contractPersonsChangesets', []);
    },

    next: function next(resolve) {
      if (!this.get('resolve')) {
        this.set('resolve', resolve);
      }

      this.$('#company-information-form').submit();
      this.scrollToValidationError();
    },

    back: function back() {
      this.saveContactPersonsChangesets();
      this.get('changeset').save();
      this._super.apply(this, arguments);
    }
  });
});