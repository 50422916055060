define('quantuvis-cm-spa/components/cm-contract-details/cm-summary', ['exports', 'ember', 'quantuvis-cm-spa/constants/contract-type'], function (exports, _ember, _quantuvisCmSpaConstantsContractType) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: 'cm-summary',
    contractDetails: _ember['default'].inject.service(),
    date: _ember['default'].inject.service(),
    documentSummary: _ember['default'].inject.service(),
    company: _ember['default'].inject.service(),
    workflow: _ember['default'].inject.service(),
    store: _ember['default'].inject.service(),

    tooltipMessages: {
      evergreen: 'Agreement between two parties that automatically\n      renews the contractual provisions after the Contract End Date,\n      until a new contract is agreed upon or the contact is terminated'
    },
    currentContract: {},
    isWorkflowLoading: false,

    init: function init() {
      this._super.apply(this, arguments);
      var currentContract = this.get('contractDetails').getContract();

      this.set('currentContract', currentContract);
      this.fetchWorkflow(currentContract.get('id'));
      this.fetchLinkedDocuments();
    },

    getEndDates: function getEndDates(currentContract) {
      var endDate = currentContract.data && currentContract.data.endDate || currentContract.get('endDate');

      return {
        endDate: this.get('date').formatDate(endDate),
        originEndDate: this.get('date').formatDate(currentContract.get('originEndDate'))
      };
    },

    fetchLinkedDocuments: function fetchLinkedDocuments() {
      var _this = this;

      if (this.currentContract.get('type.id') === _quantuvisCmSpaConstantsContractType['default'].AMENDMENT) {
        this.get('store').findRecord('contract', this.currentContract.get('contractId')).then(function (data) {
          _this.set('currentContract.linkedDocuments', [_ember['default'].Object.create({
            id: data.get('id'),
            name: data.get('name')
          })]);
        });
      }
    },

    generalListItems: _ember['default'].computed('currentContract', 'currentContract.linkedDocuments.[]', function () {
      var currentContract = this.get('currentContract');

      if (!currentContract) {
        return [];
      }

      return [{
        caption: 'Name',
        value: currentContract.get('name')
      }, {
        caption: 'Current Version',
        value: currentContract.get('file.version')
      }, {
        caption: 'ID',
        value: currentContract.get('number')
      }, {
        caption: 'Type',
        value: currentContract.get('type.name')
      }, {
        caption: 'Status',
        value: currentContract.get('status.name')
      }, {
        caption: 'Link to Bid',
        value: this.formatBidsNames(currentContract.get('bids'))
      }, {
        caption: 'Line of Business',
        value: currentContract.get('lineOfBusiness.name')
      }, {
        caption: 'Related Document(s)',
        value: currentContract.get('linkedDocuments'),
        componentName: 'cm-contract-details/summary-components/linked-documents'
      }, {
        caption: 'Created by',
        value: currentContract.get('user.firstName') + ' ' + currentContract.get('user.lastName')
      }, {
        caption: 'Created on',
        value: this.get('date').formatDate(currentContract.get('createdDate'))
      }, {
        caption: 'Start Date',
        value: this.get('date').formatDate(currentContract.get('startDate'))
      }, {
        caption: 'End Date',
        value: this.getEndDates(currentContract),
        componentName: 'cm-contract-details/summary-components/end-date'
      }, {
        caption: 'Evergreen',
        value: this.getEvergreenText(currentContract.get('evergreen')),
        tooltipText: this.get('tooltipMessages.evergreen')
      }];
    }),

    fetchWorkflow: function fetchWorkflow(contractId) {
      var _this2 = this;

      this.set('isWorkflowLoading', true);

      this.get('workflow').findWorkflowByContractId(contractId).then(function (workflow) {
        return _this2.setProperties({ workflow: workflow, isWorkflowLoading: false });
      })['catch'](function () {
        return _this2.set('isWorkflowLoading', false);
      });
    },

    rolesListItems: _ember['default'].computed('currentContract', 'workflow', function () {
      var _getProperties = this.getProperties('currentContract', 'isWorkflowLoading');

      var currentContract = _getProperties.currentContract;
      var isWorkflowLoading = _getProperties.isWorkflowLoading;

      if (!currentContract) {
        return [];
      }

      return [{
        caption: 'Leads(s)',
        value: this.getContractLeadsString(currentContract.get('leads'))
      }, {
        caption: 'Internal Reviewers',
        value: this.get('documentSummary').getContractReviewers(this.get('workflow'), currentContract.get('leadIds')),
        templateName: 'components/cm-contract-details/summary-partials/internal-reviewers',
        isLoading: isWorkflowLoading
      }, {
        caption: 'Document Permissions',
        value: currentContract.get('id'),
        componentName: 'cm-contract-details/summary-components/view-permissions'
      }];
    }),

    companyInformationItems: _ember['default'].computed('currentContract', function () {
      var currentContract = this.get('currentContract');

      if (!currentContract) {
        return [];
      }

      var companyInformationItems = [{
        caption: 'Company',
        value: this.get('company').joinCompanyData(currentContract.get('company')),
        templateName: 'components/cm-contract-details/summary-partials/company-info'
      }];

      return companyInformationItems.concat(this.getContactPersonsItems(currentContract));
    }),

    getContactPersonsItems: function getContactPersonsItems(currentContract) {
      return currentContract.get('contactPersons').map(function (person, index) {
        return {
          caption: 'Contact ' + (index + 1),
          value: person,
          componentName: 'cm-contract-details/summary-components/contact-info',
          rowClass: 'contact-person'
        };
      });
    },

    /**
     * Return "On" or "Off" string depends on evergreen value
     * @param {boolean} evergreenValue
     * @returns {string} - Evergreen value string representation
     */
    getEvergreenText: function getEvergreenText(evergreenValue) {
      return evergreenValue ? 'On' : 'Off';
    },

    /**
     * Concatenate users first and last names by comma
     * @param {array} leads
     * @returns {string} - concatenated users
     */
    getContractLeadsString: function getContractLeadsString(leads) {
      var leadsStringArray = leads.map(function (lead) {
        return lead.get('fullName');
      });

      return leadsStringArray.join(', ');
    },

    formatBidsNames: function formatBidsNames(bids) {
      var _this3 = this;

      var result = '';

      if (bids) {
        result = bids.map(function (bid) {
          return _this3.getBidTitle(bid);
        }).join(', ');
      }

      return result;
    },

    getBidTitle: function getBidTitle(bid) {
      var startDate = this.get('date').formatDate(bid.contractStartDate);
      var endDate = this.get('date').formatDate(bid.contractEndDate);

      return bid.title + ' (' + startDate + '-' + endDate + ') - ' + bid.lineOfBusiness.name;
    }
  });
});