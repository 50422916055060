define('quantuvis-cm-spa/mixins/data-adapter-mixin', ['exports', 'ember', 'ember-simple-auth/mixins/data-adapter-mixin', 'quantuvis-cm-spa/constants/request-state'], function (exports, _ember, _emberSimpleAuthMixinsDataAdapterMixin, _quantuvisCmSpaConstantsRequestState) {
  exports['default'] = _ember['default'].Mixin.create(_emberSimpleAuthMixinsDataAdapterMixin['default'], {
    requestQueue: _ember['default'].inject.service(),

    ajaxOptions: function ajaxOptions() {
      var _this = this;

      var authorizer = this.get('authorizer');
      var hash = this._super.apply(this, arguments);
      var beforeSend = hash.beforeSend;

      hash.beforeSend = function (xhr) {
        _this.get('session').authorize(authorizer, function (headerName, headerValue) {
          xhr.setRequestHeader(headerName, headerValue);
        });
        _this.get('requestQueue').updateQueue(_quantuvisCmSpaConstantsRequestState.REQUEST_STATE_STARTED);
        if (beforeSend) {
          beforeSend(xhr);
        }
      };

      return hash;
    },

    handleResponse: function handleResponse(status, headers, payload, requestData) {
      this.get('requestQueue').updateQueue(_quantuvisCmSpaConstantsRequestState.REQUEST_STATE_FINISHED);

      return this._super(status, headers, payload, requestData);
    }
  });
});