define('quantuvis-cm-spa/constants/due-date-events', ['exports'], function (exports) {
  var DAYS_FROM_CONTRACT_CREATION = 1;
  var WEEKS_FROM_CONTRACT_CREATION = 2;
  var DAYS_AFTER_PREVIOUS_STEP = 3;
  var DAYS_FROM_CONTRACT_CREATION_LABEL = 'Days from Contract Creation Date';
  var WEEKS_FROM_CONTRACT_CREATION_LABEL = 'Weeks from Contract Creation Date';
  var DAYS_AFTER_PREVIOUS_STEP_LABEL = 'Days after Previous Step';

  var DAYS_FROM_CONTRACT_CREATION_EVENT = {
    id: DAYS_FROM_CONTRACT_CREATION,
    name: DAYS_FROM_CONTRACT_CREATION_LABEL
  };

  var WEEKS_FROM_CONTRACT_CREATION_EVENT = {
    id: WEEKS_FROM_CONTRACT_CREATION,
    name: WEEKS_FROM_CONTRACT_CREATION_LABEL
  };

  var DAYS_AFTER_PREVIOUS_STEP_EVENT = {
    id: DAYS_AFTER_PREVIOUS_STEP,
    name: DAYS_AFTER_PREVIOUS_STEP_LABEL
  };

  exports.DAYS_FROM_CONTRACT_CREATION_EVENT = DAYS_FROM_CONTRACT_CREATION_EVENT;
  exports.WEEKS_FROM_CONTRACT_CREATION_EVENT = WEEKS_FROM_CONTRACT_CREATION_EVENT;
  exports.DAYS_AFTER_PREVIOUS_STEP_EVENT = DAYS_AFTER_PREVIOUS_STEP_EVENT;
});