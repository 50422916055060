define('quantuvis-cm-spa/components/cm-contract-status', ['exports', 'ember', 'quantuvis-cm-spa/constants/contract-status', 'quantuvis-cm-spa/constants/date-format', 'quantuvis-cm-spa/constants/permissions', 'quantuvis-cm-spa/constants/workflow-step-type', 'quantuvis-cm-spa/constants/workflow-step-departments-state', 'quantuvis-cm-spa/constants/message-bus-event'], function (exports, _ember, _quantuvisCmSpaConstantsContractStatus, _quantuvisCmSpaConstantsDateFormat, _quantuvisCmSpaConstantsPermissions, _quantuvisCmSpaConstantsWorkflowStepType, _quantuvisCmSpaConstantsWorkflowStepDepartmentsState, _quantuvisCmSpaConstantsMessageBusEvent) {

  /**
   * Input attributes:
   * - model
   * - progressBarEnabled
   */
  exports['default'] = _ember['default'].Component.extend({
    DATE_FORMAT: _quantuvisCmSpaConstantsDateFormat['default'],
    PERMISSIONS: _quantuvisCmSpaConstantsPermissions.PERMISSIONS,
    date: _ember['default'].inject.service(),
    classNames: ['cm-contract-status'],
    isInReview: false,
    isNew: false,
    isInSignature: false,
    isWaitingForActivation: false,
    showProgressBar: false,
    progressBarEnabled: true,
    progressBarValue: null,
    progressBarColor: '',
    enableDetailsPopup: false,
    currentStatusText: '',
    stepDueDate: null,
    overdueState: false,
    contractStatusService: _ember['default'].inject.service(),
    signatureStatusService: _ember['default'].inject.service(),
    dialog: _ember['default'].inject.service(),
    store: _ember['default'].inject.service(),
    session: _ember['default'].inject.service('session'),
    router: _ember['default'].inject.service('-routing'),
    messageBus: _ember['default'].inject.service('message-bus'),
    deleteDialogOptions: {
      confirmMessage: 'Are you sure you would like to Delete the document? This action is permanent and irreversible.',
      acceptText: 'Delete',
      acceptHandler: 'deleteHandler'
    },
    cancelReactivationDialogOptions: {
      confirmMessage: 'Are you sure you would like to cancel {document_type} reactivation?',
      acceptHandler: 'cancelReactivationHandler'
    },
    reactivateDialogOptions: {
      confirmMessage: 'Are you sure you would like to reactivate this {document_type}?',
      acceptHandler: 'reactivateHandler'
    },
    showDialogSpinner: false,

    substepsSorting: ['position'],
    substeps: _ember['default'].computed.sort('model.workflowStep.substeps', 'substepsSorting'),

    items: [],

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      var status = this.get('model.status');

      this.set('currentStatusText', status.name);

      switch (status.id) {
        case _quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.NEW:
          this.processNewStatus();
          break;
        case _quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.DEACTIVATED:
          this.processDeactivatedStatus();
          break;
        case _quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.EXECUTED:
          this.processExecutedStatus();
          break;
        case _quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.TERMINATED:
          this.processTerminatedStatus();
          break;
        case _quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.IN_REVIEW:
          this.processReviewStatus();
          break;
        case _quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.IN_SIGNATURE:
          this.processSignatureStatus();
          break;
        case _quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.PARTIALLY_EXECUTED_INTERNALLY:
        case _quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.PARTIALLY_EXECUTED_EXTERNALLY:
          this.processPartiallyExecutedStatus();
          break;
        default:
          this.processDefaultStatus();
      }
    },

    processDefaultStatus: function processDefaultStatus() {
      this.set('showProgressBar', true);
      this.set('progressBarColor', 'purple');
      this.set('progressBarValue', this.calculateProgressBarValue(false));
    },

    processNewStatus: function processNewStatus() {
      this.set('isNew', true);
    },

    processDeactivatedStatus: function processDeactivatedStatus() {
      this.set('isWaitingForActivation', this.get('model.isWaitingForActivation'));
    },

    processPartiallyExecutedStatus: function processPartiallyExecutedStatus() {
      this.processDefaultStatus();

      var substeps = this.processSubstepsForPopover();

      this.set('items', substeps);
      this.set('enableDetailsPopup', substeps.length > 0);
    },

    processSignatureStatus: function processSignatureStatus() {
      this.set('isInSignature', true);
      this.processDefaultStatus();

      var signOff = this.get('model.signOff');

      var substeps = this.processSubstepsForPopover();
      var signers = this.get('signatureStatusService').processSigners(_ember['default'].get(signOff, 'signers') || []);
      var items = signers.concat(substeps);

      this.set('items', items);
      this.set('enableDetailsPopup', items.length > 0);
      this.set('stepDueDate', signOff.get('dueDate'));

      this.defineInSignatureStatusColors(signOff);
    },

    processSubstepsForPopover: function processSubstepsForPopover() {
      var _this = this;

      var substeps = this.get('contractStatusService').processSubsteps(this.get('substeps'));
      var workflowStep = this.get('model.workflowStep');

      if (workflowStep && workflowStep.get('typeId') === _quantuvisCmSpaConstantsWorkflowStepType.CONTRACT_LEAD_REVIEW_ID) {
        var date = this.get('date');
        var leadReviewStep = _ember['default'].Object.create({
          stateText: 'In Review by Contract Lead',
          isOverdue: date.isOverdue(workflowStep.get('dueDate')),
          dateText: workflowStep.get('dueDate') ? 'due ' + date.formatDate(workflowStep.get('dueDate')) : ''
        });

        substeps.push(leadReviewStep);
      }

      substeps.forEach(function (substep) {
        if (substep.get('isOverdue')) {
          _this.set('progressBarColor', 'red');
        }
      });

      return substeps;
    },

    processTerminatedStatus: function processTerminatedStatus() {
      this.set('showProgressBar', false);
    },

    processExecutedStatus: function processExecutedStatus() {
      this.setProperties({
        showProgressBar: true,
        progressBarColor: 'green',
        progressBarValue: this.calculateProgressBarValue(true)
      });
    },

    processReviewStatus: function processReviewStatus() {
      var _this2 = this;

      this.set('isInReview', true);
      this.processDefaultStatus();

      var status = this.get('model.status');
      var workflowStep = this.get('model.workflowStep');
      var minSubstepsLengthForPopup = 2;

      if (!workflowStep) {
        return;
      }
      this.set('stepDueDate', workflowStep.get('dueDate'));

      var substeps = this.get('contractStatusService').processSubsteps(this.get('substeps') || []);

      switch (workflowStep.get('typeId')) {
        case _quantuvisCmSpaConstantsWorkflowStepType.MULTI_DEPARTMENT_REVIEW_ID:
        case _quantuvisCmSpaConstantsWorkflowStepType.DEPARTMENT_REVIEW_ID:
          if (substeps) {
            this.defineStatusWithDepartment(substeps);
            substeps.forEach(function (substep) {
              return _this2.defineInReviewStatusColors(substep);
            });
          }
          break;
        case _quantuvisCmSpaConstantsWorkflowStepType.CONTRACT_LEAD_REVIEW_ID:
          this.set('currentStatusText', status.name + ' by Contract Lead');
          break;
        default:
          break;
      }

      this.defineInReviewStatusColors(workflowStep);

      if (substeps && substeps.length >= minSubstepsLengthForPopup) {
        this.set('items', substeps);
        this.set('enableDetailsPopup', true);
      }
    },

    defineStatusWithDepartment: function defineStatusWithDepartment(substeps) {
      var firstNotCompletedSubstep = substeps.find(function (substep) {
        return substep.get('stateId') === _quantuvisCmSpaConstantsWorkflowStepDepartmentsState.WORKFLOW_STEP_DEPARTMENT_STATE.IN_PROGRESS || substep.get('stateId') === _quantuvisCmSpaConstantsWorkflowStepDepartmentsState.WORKFLOW_STEP_DEPARTMENT_STATE.PENDING;
      });

      if (firstNotCompletedSubstep) {
        this.set('currentStatusText', this.get('contractStatusService').defineStateText(firstNotCompletedSubstep));
      }
    },

    defineInReviewStatusColors: function defineInReviewStatusColors(workflowElement) {
      if (!workflowElement.get('completedDate') && this.get('date').isOverdue(workflowElement.get('dueDate'))) {
        this.set('overdueState', true);
        this.set('progressBarColor', 'red');
      }
    },

    defineInSignatureStatusColors: function defineInSignatureStatusColors(signOff) {
      if (this.get('date').isOverdue(signOff.get('dueDate'))) {
        this.set('overdueState', true);
        this.set('progressBarColor', 'red');
      }
    },

    calculateProgressBarValue: function calculateProgressBarValue(isCompleted) {
      var fullProgressBarValue = 100;
      var totalSteps = this.get('model.totalSteps');
      var completedSteps = this.get('model.completedSteps');

      var isInSignature = this.get('isInSignature');

      if (isInSignature) {
        totalSteps++;
      }

      if (totalSteps > 0) {
        if (!isCompleted && totalSteps === completedSteps) {
          totalSteps++;
        }

        return completedSteps * fullProgressBarValue / totalSteps;
      } else {
        return isCompleted ? fullProgressBarValue : 0;
      }
    },

    prepareDialogOptions: function prepareDialogOptions(options) {
      options.confirmMessage = options.confirmMessage.replace('{document_type}', this.get('model.type.name').toLowerCase());

      return options;
    },

    actions: {
      'delete': function _delete() {
        this.get('dialog').show('dialogs/cm-dialog', 'dialog-templates/cm-confirmation', this, this.deleteDialogOptions);
      },
      cancelReactivation: function cancelReactivation() {
        this.get('dialog').show('dialogs/cm-dialog', 'dialog-templates/cm-confirmation', this, this.prepareDialogOptions(this.cancelReactivationDialogOptions));
      },

      reactivate: function reactivate() {
        this.get('dialog').show('dialogs/cm-dialog', 'dialog-templates/cm-confirmation', this, this.prepareDialogOptions(this.reactivateDialogOptions));
      },

      deleteHandler: function deleteHandler(presenter) {
        var _this3 = this;

        var contractId = this.get('model.id');

        this.set('showDialogSpinner', true);
        this.get('store').adapterFor('application').remove('contract', contractId).then(function () {
          presenter.accept();
          _this3.set('showDialogSpinner', false);
          _this3.get('store').peekRecord('search/contract', contractId).unloadRecord();
          _this3.get('messageBus').publish(_quantuvisCmSpaConstantsMessageBusEvent['default'].DOCUMENT_REMOVED);
        })['catch'](function (error) {
          presenter.accept();
          _this3.set('showDialogSpinner', false);

          var options = {
            errorMessage: error.payload.message
          };

          _this3.get('dialog').show('dialogs/cm-error', null, _this3, options);
        });
      },
      cancelReactivationHandler: function cancelReactivationHandler(presenter) {
        var _this4 = this;

        var contractId = this.get('model.id');

        this.set('showDialogSpinner', true);
        this.get('store').adapterFor('contracts/cancel-reactivation').put('contracts/' + contractId + '/cancel-reactivation', '', {}).then(function () {
          _this4.set('isWaitingForActivation', false);
          presenter.accept();
          _this4.set('showDialogSpinner', false);
        })['catch'](function () {
          presenter.accept();
          _this4.set('showDialogSpinner', false);
        });
      },
      reactivateHandler: function reactivateHandler(presenter) {
        var _this5 = this;

        var contractId = this.get('model.id');

        this.set('showDialogSpinner', true);
        this.get('store').adapterFor('contracts/reactivate').put('contracts/' + contractId + '/reactivate', '', {}).then(function () {
          presenter.accept();
          _this5.set('showDialogSpinner', false);
          _this5.get('router').transitionTo('contract-details', [_this5.get('model.id')]);
        })['catch'](function () {
          presenter.accept();
          _this5.set('showDialogSpinner', false);
        });
      }
    }

  });
});