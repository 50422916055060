define('quantuvis-cm-spa/serializers/workflows/timeline-step', ['exports', 'quantuvis-cm-spa/serializers/application', 'ember-data'], function (exports, _quantuvisCmSpaSerializersApplication, _emberData) {
  exports['default'] = _quantuvisCmSpaSerializersApplication['default'].extend(_emberData['default'].EmbeddedRecordsMixin, {
    attrs: {
      workflowStepElements: { embedded: 'always', serialize: 'records' },
      substeps: { embedded: 'always', serialize: 'records' },
      responsiblePersonsAccounts: { embedded: 'always', serialize: 'records' },
      executor: { embedded: 'always' },
      attachments: { embedded: 'always', serialize: 'records' },
      events: { embedded: 'always', serialize: 'records' }
    }
  });
});