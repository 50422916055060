define('quantuvis-cm-spa/services/sign-off-view', ['exports', 'ember', 'quantuvis-cm-spa/constants/sender-view-statuses', 'quantuvis-cm-spa/mixins/envelope-validator-mixin'], function (exports, _ember, _quantuvisCmSpaConstantsSenderViewStatuses, _quantuvisCmSpaMixinsEnvelopeValidatorMixin) {

  var ACTION_CREATE = 'create';
  var ACTION_CORRECT = 'correct';
  var VALID_ACTIONS = [ACTION_CREATE, ACTION_CORRECT];

  exports['default'] = _ember['default'].Service.extend(_quantuvisCmSpaMixinsEnvelopeValidatorMixin['default'], {
    store: _ember['default'].inject.service(),

    createSenderViewLink: function createSenderViewLink(signOffId) {
      return this.get('store').adapterFor('signoff/sender-view').getSenderViewUrl(signOffId).then(function (_ref) {
        var data = _ref.data;
        return Promise.resolve(data.senderViewUrl);
      })['catch'](function (error) {
        return _ember['default'].Logger.error('Failed to retrieve sender URL', error);
      });
    },

    handleViewCallback: function handleViewCallback(requestParams) {
      if (!this.isValidRequest(requestParams)) {
        return;
      }

      var envelopeId = requestParams.envelopeId;
      var event = requestParams.event;
      var action = requestParams.action;

      if (this.isErrorEvent(event)) {
        _ember['default'].Logger.error('Received error response from DocuSign', { envelopeId: envelopeId, event: event });
      }

      return this.isProcessableEvent(event) && this.processEvent(action, envelopeId, event);
    },

    processEvent: function processEvent(action, envelopeId, eventName) {
      _ember['default'].Logger.info('Process response from DocuSign', { envelopeId: envelopeId, eventName: eventName, action: action });

      switch (action) {
        case ACTION_CREATE:
          return this.processSenderViewEvent(envelopeId, eventName);
        case ACTION_CORRECT:
          return this.processCorrectViewEvent(envelopeId, eventName);
        default:
          break;
      }
    },

    processSenderViewEvent: function processSenderViewEvent(envelopeId, eventName) {
      return this.get('store').adapterFor('signoff').updateSignOffByEnvelope(envelopeId)['catch'](function (error) {
        return _ember['default'].Logger.error('SignOff update by Envelope failed', { envelopeId: envelopeId, eventName: eventName, error: error });
      });
    },

    processCorrectViewEvent: function processCorrectViewEvent(envelopeId, eventName) {
      return this.get('store').adapterFor('signoff').correctSignOff(envelopeId)['catch'](function (error) {
        return _ember['default'].Logger.error('SignOff correct failed', { envelopeId: envelopeId, eventName: eventName, error: error });
      });
    },

    isValidRequest: function isValidRequest(requestParams) {
      return this.requestHasValidEnvelopeId(requestParams) && this.requestHasValidEvent(requestParams) && this.requestHasValidAction(requestParams);
    },

    requestHasValidEnvelopeId: function requestHasValidEnvelopeId(requestParams) {
      return requestParams.hasOwnProperty('envelopeId') && this.isValidEnvelopeId(requestParams.envelopeId);
    },

    requestHasValidEvent: function requestHasValidEvent(requestParams) {
      return requestParams.hasOwnProperty('event') && _quantuvisCmSpaConstantsSenderViewStatuses.SENDER_VIEW_ENVELOPE_STATUSES.includes(requestParams.event);
    },

    requestHasValidAction: function requestHasValidAction(requestParams) {
      return requestParams.hasOwnProperty('action') && VALID_ACTIONS.includes(requestParams.action);
    },

    isErrorEvent: function isErrorEvent(eventName) {
      return _quantuvisCmSpaConstantsSenderViewStatuses.SENDER_VIEW_ENVELOPE_ERROR_STATUSES.includes(eventName);
    },

    isProcessableEvent: function isProcessableEvent(eventName) {
      return _quantuvisCmSpaConstantsSenderViewStatuses.SENDER_VIEW_ENVELOPE_PROCESSABLE_STATUSES.includes(eventName);
    }
  });
});