define('quantuvis-cm-spa/mixins/authenticated-route-mixin', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin', 'quantuvis-cm-spa/constants/http-statuses'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _quantuvisCmSpaConstantsHttpStatuses) {
  var Mixin = _ember['default'].Mixin;
  var getOwner = _ember['default'].getOwner;
  exports['default'] = Mixin.create(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    sentryAdapter: _ember['default'].inject.service('sentry-adapter'),

    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);

      var _getProperties = this.getProperties('session');

      var session = _getProperties.session;

      if (session.get('isAuthenticated')) {
        var authorizer = getOwner(this).lookup('authenticator:oauth2');

        return authorizer.prefetchPersonalizationProperties();
      }
    },

    isCheckTokenError: function isCheckTokenError(status) {
      return status === _quantuvisCmSpaConstantsHttpStatuses['default'].UNAUTHORISED;
    },

    invalidateSessions: function invalidateSessions() {
      this.get('session').invalidate();
    },

    actions: {
      error: function error(response) {
        var status = response.status;

        if (this.isCheckTokenError(status)) {
          this.get('sentryAdapter').handleError(status, response);

          this.invalidateSessions();

          return _ember['default'].Logger.error('Failed to retrieve user personalization data. Logout', response);
        }

        return true;
      }
    }
  });
});