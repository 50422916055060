define('quantuvis-cm-spa/services/window', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Service.extend({
    getLocation: function getLocation() {
      return window.location;
    },

    getWindow: function getWindow() {
      return window;
    }
  });
});