define('quantuvis-cm-spa/components/cm-notifications/notification-entry', ['exports', 'ember', 'moment', 'quantuvis-cm-spa/constants/date-format', 'quantuvis-cm-spa/constants/notification-placeholders', 'quantuvis-cm-spa/constants/task-type', 'quantuvis-cm-spa/constants/task-object-type'], function (exports, _ember, _moment, _quantuvisCmSpaConstantsDateFormat, _quantuvisCmSpaConstantsNotificationPlaceholders, _quantuvisCmSpaConstantsTaskType, _quantuvisCmSpaConstantsTaskObjectType) {
  exports['default'] = _ember['default'].Component.extend({
    DATE_FORMAT: _quantuvisCmSpaConstantsDateFormat['default'],
    classNames: ['notification-entry'],
    tagName: 'tr',
    init: function init() {
      this._super.apply(this, arguments);

      var notification = this.get('notification');
      var message = notification.get('message');

      var startDate = (0, _moment['default'])(notification.get('createdDate')).format(_quantuvisCmSpaConstantsDateFormat['default'].MM_DD_YYYY);
      var completedDate = (0, _moment['default'])(notification.get('completedDate')).format(_quantuvisCmSpaConstantsDateFormat['default'].MM_DD_YYYY);
      var dueDate = (0, _moment['default'])(notification.get('dueDate')).format(_quantuvisCmSpaConstantsDateFormat['default'].MM_DD_YYYY);

      var populatedMessage = message.replace(_quantuvisCmSpaConstantsNotificationPlaceholders['default'].CREATED_DATE, startDate).replace(_quantuvisCmSpaConstantsNotificationPlaceholders['default'].COMPLETED_DATE, completedDate).replace(_quantuvisCmSpaConstantsNotificationPlaceholders['default'].DUE_DATE, dueDate);

      notification.set('populatedMessage', populatedMessage);
    },

    redirectRoute: _ember['default'].computed('notification.taskType.id', function () {
      var taskType = this.get('notification.taskType.id');

      switch (taskType) {
        case _quantuvisCmSpaConstantsTaskType['default'].CONTRACT_RECEIVED_FROM_OTHER_PARTY:
          return 'create-contract';
        default:
          return 'contract-details';
      }
    }),

    redirectParam: _ember['default'].computed('notification.taskType.id', 'notification.contractId', function () {
      var taskType = this.get('notification.taskType.id');

      switch (taskType) {
        case _quantuvisCmSpaConstantsTaskType['default'].CONTRACT_RECEIVED_FROM_OTHER_PARTY:
          return this.getDocumentTypeId();
        default:
          return this.get('notification.contractId');
      }
    }),

    getDocumentTypeId: function getDocumentTypeId() {
      return this.get('notification.taskObjects').find(function (object) {
        return object.type.id === _quantuvisCmSpaConstantsTaskObjectType['default'].DOCUMENT_TYPE;
      }).objectId;
    }
  });
});