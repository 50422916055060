define('quantuvis-cm-spa/routes/login', ['exports', 'ember', 'ember-simple-auth/mixins/unauthenticated-route-mixin', 'ember-changeset', 'ember-changeset-validations', 'quantuvis-cm-spa/validations/login'], function (exports, _ember, _emberSimpleAuthMixinsUnauthenticatedRouteMixin, _emberChangeset, _emberChangesetValidations, _quantuvisCmSpaValidationsLogin) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsUnauthenticatedRouteMixin['default'], {
    /**
     * User credentials
     */
    credentials: {
      username: '',
      password: ''
    },

    credentialsChangeset: {},

    initChangeset: function initChangeset() {
      var changeset = new _emberChangeset['default'](this.credentials, (0, _emberChangesetValidations['default'])(_quantuvisCmSpaValidationsLogin['default']), _quantuvisCmSpaValidationsLogin['default'], {
        skipValidate: true
      });

      return changeset;
    },

    model: function model() {
      return {
        credentials: this.initChangeset()
      };
    }
  });
});