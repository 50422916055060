define('quantuvis-cm-spa/core-ui/components/cm-navigation/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'nav',
    classNames: ['cm-navigation'],

    actions: {
      toggleMenu: function toggleMenu() {
        this.$('ul').toggleClass('collapsed');
      }
    }
  });
});