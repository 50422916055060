define('quantuvis-cm-spa/services/application-navigation', ['exports', 'ember', 'quantuvis-cm-spa/constants/applications'], function (exports, _ember, _quantuvisCmSpaConstantsApplications) {

  var visibleApplicationIds = [_quantuvisCmSpaConstantsApplications['default'].REBATE_NEGOTIATIONS_ID, _quantuvisCmSpaConstantsApplications['default'].HELP_CENTER_ID];

  exports['default'] = _ember['default'].Service.extend({
    store: _ember['default'].inject.service(),
    dialog: _ember['default'].inject.service(),
    loadingSpinner: _ember['default'].inject.service(),
    applicationNavigationItems: [],

    init: function init() {
      this.loadApplicationNavigation();
    },

    loadApplicationNavigation: function loadApplicationNavigation() {
      var _this = this;

      return this.get('store').findAll('user-management/application').then(function (navigationItems) {
        var filteredNavigationItems = navigationItems.filter(function (item) {
          var itemId = item.get('id');

          item.set('iconName', _this.getIconNameForApplication(itemId));

          return visibleApplicationIds.includes(parseInt(itemId));
        });

        _this.set('applicationNavigationItems', filteredNavigationItems);
      });
    },

    getIconNameForApplication: function getIconNameForApplication(id) {
      if (id == _quantuvisCmSpaConstantsApplications['default'].REBATE_NEGOTIATIONS_ID) {
        return 'rebate-negotiations-navigation';
      } else if (id == _quantuvisCmSpaConstantsApplications['default'].HELP_CENTER_ID) {
        return 'help-center-navigation';
      }if (id == _quantuvisCmSpaConstantsApplications['default'].SYSTEM_ADMINISTRATION_ID) {
        return 'user-management-navigation';
      } else {
        return '';
      }
    },

    redirectTo: function redirectTo(applicationId) {
      var _this2 = this;

      var path = arguments.length <= 1 || arguments[1] === undefined ? '' : arguments[1];

      this.get('loadingSpinner').show('main-application-spinner');
      this.get('store').adapterFor('user-management/applications/resource').redirectPath(applicationId).then(function (_ref) {
        var data = _ref.data;

        _this2.get('loadingSpinner').hide('main-application-spinner');
        if (data) {
          var uri = data.uri + path;
          var newWindowHandle = window.open(uri, '_blank');

          if (!newWindowHandle) {
            var options = {
              hideDeclineButton: true,
              context: _this2,
              title: 'Redirect blocked by browser'
            };

            _this2.get('dialog').show('dialogs/cm-dialog', 'dialog-templates/cm-open-prohibited', { directFileUrl: uri }, options);
          }
        }
      })['catch'](function (error) {
        _this2.get('loadingSpinner').hide('main-application-spinner');
        _ember['default'].Logger.error('Error occurred on retrieve URL', error);
      });
    }
  });
});