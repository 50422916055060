define('quantuvis-cm-spa/controllers/initiate-sign-off', ['exports', 'ember', 'moment', 'quantuvis-cm-spa/mixins/scroll-to-validation-error', 'quantuvis-cm-spa/constants/sign-off/quote'], function (exports, _ember, _moment, _quantuvisCmSpaMixinsScrollToValidationError, _quantuvisCmSpaConstantsSignOffQuote) {
  exports['default'] = _ember['default'].Controller.extend(_quantuvisCmSpaMixinsScrollToValidationError['default'], {
    dialog: _ember['default'].inject.service(),
    signOff: _ember['default'].inject.service(),
    signOffView: _ember['default'].inject.service(),
    window: _ember['default'].inject.service(),
    initiateSignOffValidator: _ember['default'].inject.service(),
    dialogManager: _ember['default'].inject.service(),
    userSession: _ember['default'].inject.service(),

    processingInitiateSignOff: false,

    redirectToContractDetails: function redirectToContractDetails() {
      return this.transitionToRoute('contract-details', this.get('model.contract.id'));
    },

    showConfirmDialog: function showConfirmDialog() {
      var options = {
        confirmMessage: 'Are you sure you want to navigate out of the wizard?\n        All the data already entered will be lost.'
      };

      return this.get('dialog').show('dialogs/cm-dialog', 'dialog-templates/cm-confirmation', this, options);
    },

    saveSignOff: function saveSignOff(data) {
      var _this = this;

      this.get('signOff').save(data).then(function (signOff) {
        _this.get('userSession').refreshToken().then(function () {
          _this.get('signOffView').createSenderViewLink(signOff.get('id')).then(function (senderViewUrl) {
            _this.get('initiateSignOffValidator').removeValidations();

            _this.get('window').getLocation().href = senderViewUrl;
          });
        });
      })['catch'](function (error) {
        _ember['default'].Logger.error('Error occurred on init sign off', error);

        _this.set('processingInitiateSignOff', false);
        _this.get('dialogManager').showDefaultError();
      });
    },

    showQuoteExceededErrorPopup: function showQuoteExceededErrorPopup() {
      var options = {
        componentName: 'sign-off/quote-exceeded',
        title: 'Error',
        hideDeclineButton: true,
        acceptText: 'Ok'
      };

      this.get('dialog').show('dialogs/cm-dialog', null, null, options);
    },

    actions: {
      onFinish: function onFinish() {
        var _this2 = this;

        return this.get('initiateSignOffValidator').validate().then(function (isAllValid) {
          if (isAllValid) {
            _this2.set('processingInitiateSignOff', true);
            _this2.get('signOff').getLock(_this2.get('model.contract.id')).then(function (lock) {
              _this2.set('processingInitiateSignOff', false);

              if (lock.get('expirationDate')) {
                _this2.get('signOff').showLockNotification(lock.get('expirationDate'));
              } else {
                return _ember['default'].$('#sign-off-form').submit();
              }
            })['catch'](function (error) {
              _ember['default'].Logger.error('Error occurred on retrieving lock', error);

              _this2.set('processingInitiateSignOff', false);
            });
          } else {
            _this2.scrollToValidationError();
          }
        });
      },

      onSubmit: function onSubmit(data, hasActiveLinkedContract) {
        var _this3 = this;

        this.set('processingInitiateSignOff', true);

        data.dueDate = parseInt(_moment['default'].utc(data.dueDate).format('x'));

        if (hasActiveLinkedContract && this.get('signOff.sendExternallyMode')) {
          this.get('signOff').request(data).then(function () {
            _this3.get('initiateSignOffValidator').removeValidations();
            _this3.redirectToContractDetails();
          })['catch'](function (error) {
            _ember['default'].Logger.error('Error occurred on init sign off request', error);

            _this3.set('processingInitiateSignOff', false);
            _this3.get('dialogManager').showDefaultError();
          });

          return;
        }

        this.get('signOff').getQuote(data.originContractId, data.companyId).then(function (quote) {
          if (quote && quote.quote < _quantuvisCmSpaConstantsSignOffQuote['default']) {
            _this3.set('processingInitiateSignOff', false);

            _this3.showQuoteExceededErrorPopup();
          } else {
            _this3.saveSignOff(data);
          }
        })['catch'](function () {
          _this3.set('processingInitiateSignOff', false);
        });
      },

      handleCancel: function handleCancel() {
        var _this4 = this;

        return this.showConfirmDialog().then(function () {
          _this4.get('initiateSignOffValidator').removeValidations();
          _this4.redirectToContractDetails();
        });
      }
    }
  });
});