define('quantuvis-cm-spa/initializers/env-config', ['exports', 'quantuvis-cm-spa/config/environment'], function (exports, _quantuvisCmSpaConfigEnvironment) {
  exports.initialize = initialize;

  function initialize() {
    _quantuvisCmSpaConfigEnvironment['default'].sentry.enabled = CM_ENV_CONFIG.CM_SPA_SENTRY_ENABLED || _quantuvisCmSpaConfigEnvironment['default'].sentry.enabled;
    _quantuvisCmSpaConfigEnvironment['default'].sentry.dsn = CM_ENV_CONFIG.CM_SPA_SENTRY_DSN || _quantuvisCmSpaConfigEnvironment['default'].sentry.dsn;
    _quantuvisCmSpaConfigEnvironment['default'].sentry.environment = CM_ENV_CONFIG.CM_SPA_SENTRY_ENVIRONMENT || _quantuvisCmSpaConfigEnvironment['default'].sentry.environment;
  }

  exports['default'] = {
    name: 'env-config',
    initialize: initialize
  };
});