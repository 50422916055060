define('quantuvis-cm-spa/models/contract', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    name: _emberData['default'].attr('string'),
    description: _emberData['default'].attr('string'),
    origin: _emberData['default'].attr(),
    linkedDocuments: _emberData['default'].attr(),
    evergreen: _emberData['default'].attr('boolean'),
    number: _emberData['default'].attr('string'),
    type: _emberData['default'].attr(),
    lineOfBusiness: _emberData['default'].attr(),
    modifiedDate: _emberData['default'].attr('unix-timestamp'),
    statusModifiedDate: _emberData['default'].attr('unix-timestamp'),
    startDate: _emberData['default'].attr('unix-date'),
    endDate: _emberData['default'].attr('unix-date'),
    originEndDate: _emberData['default'].attr('unix-date'),
    status: _emberData['default'].attr(),
    leadIds: _emberData['default'].attr(),
    createdDate: _emberData['default'].attr('unix-timestamp'),
    companyId: _emberData['default'].attr('number'),
    accountId: _emberData['default'].attr('number'),
    bids: _emberData['default'].attr(),
    contactPersons: _emberData['default'].attr(),
    contracts: _emberData['default'].hasMany('contracts/child-contract', { inverse: null, async: false }),
    contractId: _emberData['default'].attr('string'),
    workflow: _emberData['default'].attr(),
    file: _emberData['default'].belongsTo('contracts/file'),
    company: _emberData['default'].attr(),
    leads: _emberData['default'].hasMany('user-management/user'),
    user: _emberData['default'].attr(),
    attachments: _emberData['default'].hasMany('contracts/attachment')
  });
});