define('quantuvis-cm-spa/components/cm-timeline/cm-timeline-item/event-bodies/sign-off-handle-details', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['sign-off-handle-details'],
    timeline: _ember['default'].inject.service(),

    executor: _ember['default'].computed('data', function () {
      var executor = this.get('data.executor');

      return this.get('timeline').createPersonInfo(executor);
    }),

    actions: {
      showDetails: function showDetails() {
        this.get('timeline').showEventDetails(this.get('data'));
      }
    }
  });
});