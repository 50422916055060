define('quantuvis-cm-spa/routes/business-admin', ['exports', 'ember', 'quantuvis-cm-spa/mixins/authenticated-route-mixin', 'quantuvis-cm-spa/constants/permissions'], function (exports, _ember, _quantuvisCmSpaMixinsAuthenticatedRouteMixin, _quantuvisCmSpaConstantsPermissions) {

  var BUSINESS_ADMIN_ROOT_ROUTE_PATH = 'business-admin.index';

  exports['default'] = _ember['default'].Route.extend(_quantuvisCmSpaMixinsAuthenticatedRouteMixin['default'], {
    businessAdmin: _ember['default'].inject.service(),
    profile: _ember['default'].inject.service(),
    permissionService: _ember['default'].inject.service(),
    model: function model() {
      return _ember['default'].RSVP.hash({
        profile: this.get('profile').getUserProfile(),
        templates: this.get('store').findAll('contracts/file-template')
      });
    },
    afterModel: function afterModel() {
      if (!this.hasUserPermission()) {
        this.transitionTo('access-denied');
      }
    },
    hasUserPermission: function hasUserPermission() {
      return this.get('permissionService').checkGlobalUserPermission(_quantuvisCmSpaConstantsPermissions.PERMISSIONS.ID.CM_ADMIN) || this.get('permissionService').checkAdminPermissionByApplication(_quantuvisCmSpaConstantsPermissions.PERMISSIONS.ID.CM_READ, _quantuvisCmSpaConstantsPermissions.PERMISSIONS.APPLICATION.CM);
    },
    actions: {
      didTransition: function didTransition() {
        // if current route state equal index
        if (this.getRouterCurrentStateParams().hasOwnProperty(BUSINESS_ADMIN_ROOT_ROUTE_PATH)) {
          // replace current state with predefined route
          // this option allows to avoid index route storing in browser history
          this.replaceWith(this.get('businessAdmin.selectedMenuRoute'));
        }

        return true;
      }
    },

    getRouterCurrentStateParams: function getRouterCurrentStateParams() {
      return this.get('router.router.state.params');
    }
  });
});