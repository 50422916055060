define('quantuvis-cm-spa/components/cm-timeline/cm-timeline-item/event-bodies/cm-new-received', ['exports', 'ember', 'quantuvis-cm-spa/constants/event-property-name'], function (exports, _ember, _quantuvisCmSpaConstantsEventPropertyName) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['cm-new-received'],
    timeline: _ember['default'].inject.service(),

    isShowViewEventDetails: _ember['default'].computed('data', function () {
      var properties = this.get('data.properties') || [];

      return this.get('timeline').isPresentEventPropertyByName(properties, _quantuvisCmSpaConstantsEventPropertyName['default'].MESSAGE);
    }),

    actions: {
      showDetails: function showDetails() {
        this.get('timeline').showEventDetails(this.get('data'));
      }
    }
  });
});