define('quantuvis-cm-spa/validations/amendment-general-details', ['exports', 'ember-changeset-validations/validators', 'quantuvis-cm-spa/validations/custom-validators'], function (exports, _emberChangesetValidationsValidators, _quantuvisCmSpaValidationsCustomValidators) {

  var fileKeyMessage = 'Please, upload new or select existing contract template';

  exports['default'] = {
    contractOrigin: [(0, _emberChangesetValidationsValidators.validatePresence)(true)],
    contractName: [(0, _emberChangesetValidationsValidators.validatePresence)(true), (0, _emberChangesetValidationsValidators.validateLength)({ max: 150 }), (0, _emberChangesetValidationsValidators.validateFormat)({ regex: /^[\w\d!\-_.*'() ]+$/ })],
    contractDescription: [(0, _emberChangesetValidationsValidators.validateLength)({ allowBlank: true, max: 255 })],
    linkToContract: [(0, _emberChangesetValidationsValidators.validatePresence)(true)],
    contractLead: [(0, _emberChangesetValidationsValidators.validatePresence)(true), (0, _emberChangesetValidationsValidators.validateLength)({ max: 25 })],
    startDate: [(0, _emberChangesetValidationsValidators.validatePresence)(true), (0, _quantuvisCmSpaValidationsCustomValidators.validateDateFormat)(), (0, _quantuvisCmSpaValidationsCustomValidators.validateAmendmentStartDate)()],
    endDate: [(0, _emberChangesetValidationsValidators.validatePresence)(true), (0, _quantuvisCmSpaValidationsCustomValidators.validateDateFormat)(), (0, _quantuvisCmSpaValidationsCustomValidators.validateEndDate)(), (0, _quantuvisCmSpaValidationsCustomValidators.validateAmendmentEndDate)()],
    contractId: [(0, _emberChangesetValidationsValidators.validatePresence)(true), (0, _emberChangesetValidationsValidators.validateLength)({ max: 25 })],
    templateComboValue: validateFileKey()
  };

  function validateFileKey() {
    return function (key, newValue, oldValue, changes, content) {
      if (changes.fileKey || content.fileKey) {
        return true;
      } else {
        return fileKeyMessage;
      }
    };
  }
});