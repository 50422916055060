define('quantuvis-cm-spa/constants/sign-events', ['exports'], function (exports) {
  var SIGN_COMPLETED = 'complete';
  var SIGN_COMPLETED_VIEW = 'signing_complete';
  var SIGN_DECLINED = 'decline';
  var SIGN_FINISH_LATER = 'finishLater';

  exports.SIGN_COMPLETED = SIGN_COMPLETED;
  exports.SIGN_DECLINED = SIGN_DECLINED;
  exports.SIGN_FINISH_LATER = SIGN_FINISH_LATER;
  exports.SIGN_COMPLETED_VIEW = SIGN_COMPLETED_VIEW;
});