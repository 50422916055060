define('quantuvis-cm-spa/components/cm-server-model-table', ['exports', 'ember-models-table/components/models-table-server-paginated', 'ember'], function (exports, _emberModelsTableComponentsModelsTableServerPaginated, _ember) {
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  var isBlank = _ember['default'].isBlank;
  exports['default'] = _emberModelsTableComponentsModelsTableServerPaginated['default'].extend({
    search: _ember['default'].inject.service(),

    metaPagesCountProperty: 'totalPages',
    metaItemsCountProperty: 'totalElements',

    filterQueryParameters: {
      sort: 'sort',
      sortDirection: 'sortDirection',
      page: 'page',
      pageSize: 'size',
      globalFilter: 'fullTextSearch'
    },

    /**
    *
    * @param {ModelsTable~ModelsTableColumn} column
    * @param {string} sortedBy
    * @param {string} newSorting 'asc|desc|none'
    */
    _singleColumnSorting: function _singleColumnSorting(column, sortedBy, newSorting) {
      var sort = 'none' === newSorting ? 'asc' : newSorting;
      var sortProperties = [sortedBy + ':' + sort];

      get(this, 'processedColumns').setEach('sorting', 'none');
      set(column, 'sorting', sort);
      set(this, 'sortProperties', sortProperties);
    },

    init: function init() {
      /**
       * Attach listener for search service attributes
       */
      this.get('search').addObserver('attributes', this.applySearchServiceAttributes.bind(this));
      this.get('search').addObserver('textSearch', this.applyFullTextSearch.bind(this));
      this.get('search').addObserver('dateRangeSearch', this._loadData.bind(this));
      this.addObserver('filteredContent', this.setResultToSearchService.bind(this));
      this._super.apply(this, arguments);
    },

    didInsertElement: function didInsertElement() {
      var currentPageNumber = this.get('currentPageNumber');
      var page = this.get('data.query.page');

      if (page && currentPageNumber !== page) {
        this.set('currentPageNumber', page);
      }
    },

    forceHidingColumnsObserver: _ember['default'].observer('visibleColumns', function () {
      var visibleColumns = this.get('visibleColumns');

      get(this, 'processedColumns').forEach(function (column, index) {
        column.set('isHidden', visibleColumns[index].isHidden);
      });
    }),

    /**
     * Apply search service attributes to columns
     * @param searchService
     */
    applySearchServiceAttributes: function applySearchServiceAttributes(searchService) {
      var columns = get(this, 'processedColumns');

      searchService.get('attributes').forEach(function (attribute) {
        var _this = this;

        var selection = attribute.getAttributeSelection();

        columns.forEach(function (column) {
          var filterTitle = _this.getCustomFilterTitle(column);

          if (filterTitle === attribute.id) {
            var filterString = selection.length ? selection.join(',') : '';

            _ember['default'].set(column, 'filterString', filterString);
          }
        });
      }, this);
    },

    applyFullTextSearch: function applyFullTextSearch() {
      if (this.isDestroyed) {
        return;
      }
      var expandedRowTemplate = this.get('search').textSearch ? 'components/models-table/versions-expanded-row' : 'components/models-table/expanded-row';

      this.set('filterString', this.get('search').textSearch);

      this.set('expandedRowTemplate', expandedRowTemplate);
    },

    setResultToSearchService: function setResultToSearchService() {
      this.get('search').set('totalSubElements', this.get('filteredContent.meta.totalSubElements'));
      this.get('search').set('totalElements', this.get('filteredContent.meta.totalElements'));
    },

    sortingWrapper: function sortingWrapper(query, sortBy, sortDirection) {
      query[_ember['default'].get(this, 'filterQueryParameters.sort')] = sortBy + ',' + sortDirection.toLowerCase();

      return query;
    },

    isLoadingObserver: _ember['default'].observer('isLoading', function () {
      this.collapseAllExpandedRows();
      this.sendAction('isLoadingChange', this.get('isLoading'));
    }),

    collapseAllExpandedRows: function collapseAllExpandedRows() {
      get(this, '_expandedRowIndexes').clear();
    },

    didReceiveAttrs: function didReceiveAttrs() {
      var filteredContent = get(this, 'filteredContent');

      set(this, 'filteredContent', filteredContent.length ? filteredContent : get(this, 'data'));
    },

    _loadData: function _loadData() {
      var query = get(this, 'data.query');
      var dateRangeModel = this.get('search.dateRangeSearch');

      Object.keys(dateRangeModel).map(function (value) {
        delete query[value];
        if (!isBlank(dateRangeModel[value])) {
          query[value] = dateRangeModel[value];
        }
      });
      this._super();
    }
  });
});