define('quantuvis-cm-spa/models/user-management/user', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    email: _emberData['default'].attr('string'),
    firstName: _emberData['default'].attr('string'),
    lastName: _emberData['default'].attr('string'),
    accountId: _emberData['default'].attr('number'),
    departmentsIds: _emberData['default'].attr(),
    type: _emberData['default'].attr(),
    jobTitle: _emberData['default'].attr('string'),

    fullName: _ember['default'].computed('firstName', 'lastName', function () {
      return this.get('firstName') + ' ' + this.get('lastName');
    })
  });
});