define('quantuvis-cm-spa/instance-initializers/ember-dialog', ['exports', 'quantuvis-cm-spa/config/environment', 'ember-dialog/configuration'], function (exports, _quantuvisCmSpaConfigEnvironment, _emberDialogConfiguration) {
  exports.initialize = initialize;

  function initialize(application) {
    _emberDialogConfiguration['default'].load(_quantuvisCmSpaConfigEnvironment['default']);
    application.inject('controller', 'dialog', 'service:dialog');
  }

  exports['default'] = {
    name: 'ember-dialog',
    initialize: initialize
  };
});