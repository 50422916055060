define('quantuvis-cm-spa/components/cm-contract-details/permissions/permissions-list', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['permissions-list'],
    permissionService: _ember['default'].inject.service(),

    availablePermissions: [],

    init: function init() {
      this._super.apply(this, arguments);
      this.fetchPermissions();
    },

    fetchPermissions: function fetchPermissions() {
      var _this = this;

      this.get('permissionService').getPermissionList({ applicationIds: 2, permissionResourceIds: 2 }).then(function (permissions) {
        _ember['default'].run(function () {
          _this.set('allPermissions', permissions);
          _this.updatePermissions();
        });
      });
    },

    updatePermissions: function updatePermissions() {
      var availablePermissions = this.get('availablePermissions');

      this.get('allPermissions').forEach(function (permission) {
        var foundPermission = availablePermissions.find(function (availablePermission) {
          return availablePermission.id == permission.id;
        });
        var permissionAllowed = !!foundPermission;

        permission.set('isAllowed', permissionAllowed);
      });
    },

    availablePermissionsObserver: _ember['default'].observer('availablePermissions', function () {
      var _this2 = this;

      _ember['default'].run(function () {
        _this2.updatePermissions();
      });
    })
  });
});