define('quantuvis-cm-spa/constants/contract-status', ['exports'], function (exports) {
  var CONTRACT_STATUS = {
    NEW: 1,
    IN_REVIEW: 2,
    IN_SIGNATURE: 3,
    PARTIALLY_EXECUTED_INTERNALLY: 5,
    PARTIALLY_EXECUTED_EXTERNALLY: 6,
    EXECUTED: 7,
    TERMINATED: 8,
    DEACTIVATED: 9,
    DELETED: 10
  };

  var STATUS_DEFAULT_FILTER = '1,2,3,5,6,7';

  exports.CONTRACT_STATUS = CONTRACT_STATUS;
  exports.STATUS_DEFAULT_FILTER = STATUS_DEFAULT_FILTER;
});