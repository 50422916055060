define('quantuvis-cm-spa/components/cm-contract-details/cm-contract-section', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['cm-contract-section'],

    actions: {
      handleBackClick: function handleBackClick() {
        this.sendAction('handleBackClick');
      },
      createAmendment: function createAmendment() {
        this.sendAction('createAmendment');
      }
    }
  });
});