define('quantuvis-cm-spa/mixins/workflow-request-builder', ['exports', 'ember', 'quantuvis-cm-spa/constants/workflow-step-type', 'quantuvis-cm-spa/constants/workflow-substep-type'], function (exports, _ember, _quantuvisCmSpaConstantsWorkflowStepType, _quantuvisCmSpaConstantsWorkflowSubstepType) {
  exports['default'] = _ember['default'].Mixin.create({

    toPlainObject: function toPlainObject(steps) {
      var normalized = this.buildWorkflowStepsCollection(steps);

      return normalized.map(function (step) {
        var stepObject = step.toJSON();

        stepObject.substeps = [];

        step.get('substeps').forEach(function (substep) {
          stepObject.substeps.push(substep.toJSON());
        });

        return stepObject;
      });
    },

    buildWorkflowStepsCollection: function buildWorkflowStepsCollection(workflowSteps) {
      var _this = this;

      return workflowSteps.map(function (step, index) {
        step.set('position', index);

        if (step.get('substepsSortedByPosition') && step.get('type.id') === _quantuvisCmSpaConstantsWorkflowStepType.DEPARTMENT_REVIEW_ID) {
          step.set('substeps', _this.buildWorkflowSubstepsCollection(step));
        } else {
          step.set('substeps', []);
        }

        return step;
      });
    },

    buildWorkflowSubstepsCollection: function buildWorkflowSubstepsCollection(stepClone) {
      return stepClone.get('substepsSortedByPosition').map(function (substep, index) {
        substep.set('position', index);

        var predefinedStepName = substep.get('type.id') !== _quantuvisCmSpaConstantsWorkflowSubstepType.CONTRACT_LEAD_REVIEW_ID ? null : substep.get('name');

        substep.set('name', predefinedStepName);

        if (substep.get('department')) {
          substep.set('name', substep.department.get('name'));
          substep.set('departmentId', substep.get('department.id'));
        }

        if (stepClone.get('substeps.length') === 1) {
          substep.set('dueDate', stepClone.get('dueDate'));
        }

        if (substep.get('leads')) {
          var responsiblePersons = substep.get('leads').map(function (lead) {

            return {
              userId: lead.id
            };
          });

          substep.set('responsiblePersons', responsiblePersons);
        }

        return substep;
      });
    }
  });
});