define('quantuvis-cm-spa/components/cm-contract-details/cm-contract-info', ['exports', 'ember', 'quantuvis-cm-spa/constants/contract-status', 'quantuvis-cm-spa/constants/file-source', 'quantuvis-cm-spa/constants/date-format', 'quantuvis-cm-spa/constants/permissions', 'quantuvis-cm-spa/constants/tooltip-messages'], function (exports, _ember, _quantuvisCmSpaConstantsContractStatus, _quantuvisCmSpaConstantsFileSource, _quantuvisCmSpaConstantsDateFormat, _quantuvisCmSpaConstantsPermissions, _quantuvisCmSpaConstantsTooltipMessages) {
  exports['default'] = _ember['default'].Component.extend({
    DATE_FORMAT: _quantuvisCmSpaConstantsDateFormat['default'],
    TOOLTIP_MESSAGES: _quantuvisCmSpaConstantsTooltipMessages['default'],
    PERMISSIONS: _quantuvisCmSpaConstantsPermissions.PERMISSIONS,
    classNames: ['cm-contract-info'],
    file: _ember['default'].inject.service(),
    latestFileUrl: '#',
    init: function init() {
      this._super.apply(this, arguments);
    },

    latestAttachment: _ember['default'].computed('contract.file', 'contract.company', function () {
      var _this = this;

      var file = this.get('contract.file');
      var contractId = this.get('contract.id');

      if (file) {
        this.get('file').getFileUrl(file.get('key'), file.get('version'), contractId).then(function (response) {
          _this.set('latestFileUrl', response.get('url'));
        })['catch'](function (error) {
          _ember['default'].Logger.error('Error occured on retrieve URL', error);
        });

        return {
          documentVersion: file.get('version'),
          title: this.getAttachmentTitle(file),
          uploadedKey: file.get('key'),
          date: file.get('uploadedDate')
        };
      }
    }),

    actions: {
      downloadCurrentAttachment: function downloadCurrentAttachment() {
        var _this2 = this;

        return new Promise(function (resolve) {
          var currentVersion = _this2.get('contract.file');

          return _this2.get('file').downloadDocumentVersion(currentVersion.id, _this2.get('contract.id'), currentVersion.version).then(function () {
            resolve(true);
          })['catch'](function () {
            resolve(false);
          });
        });
      }
    },

    getAttachmentTitle: function getAttachmentTitle(file) {
      switch (file.get('source.id')) {
        case _quantuvisCmSpaConstantsFileSource['default'].RESPONSIBLE_PERSON:
          {
            var department = file.get('user').departments.length ? file.get('user').departments[0].name : '';

            return 'Uploaded by ' + file.get('user').firstName + ' ' + file.get('user').lastName + ' of ' + department;
          }
        case _quantuvisCmSpaConstantsFileSource['default'].OTHER_COMPANY:
          {
            var companyName = this.get('contract.company.name');

            return 'Received from ' + companyName;
          }
        default:
          return null;
      }
    },

    contractStatusClass: _ember['default'].computed('contract.status.id', function () {
      var statusId = this.get('contract.status.id');

      switch (statusId) {
        case _quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.NEW:
        case _quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.IN_REVIEW:
        case _quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.IN_SIGNATURE:
        case _quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.PARTIALLY_EXECUTED_INTERNALLY:
        case _quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.PARTIALLY_EXECUTED_EXTERNALLY:
          return 'contract-info-in-progress';
        case _quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.EXECUTED:
          return 'contract-info-executed';
        case _quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.TERMINATED:
        case _quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.DEACTIVATED:
          return 'contract-info-terminated';
        default:
          return '';
      }
    })
  });
});