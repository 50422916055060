define('quantuvis-cm-spa/services/unique-document-template-validator', ['exports', 'ember'], function (exports, _ember) {

  var NOT_UNIQUE_NAME_MESSAGE = 'Title is not unique';
  var REQUIRED_FIELD_MESSAGE = 'Required';
  var DOCUMENT_TEMPLATE_SPINNER_ID = 'templates-spinner';

  exports['default'] = _ember['default'].Service.extend({
    store: _ember['default'].inject.service('store'),
    loadingSpinner: _ember['default'].inject.service(),

    validateUniqueness: function validateUniqueness(id) {
      var _this = this;

      return function (key, newValue) {
        return newValue ? _this.lookup(id, newValue) : REQUIRED_FIELD_MESSAGE;
      };
    },

    lookup: function lookup(id, name) {
      var _this2 = this;

      this.get('loadingSpinner').show(DOCUMENT_TEMPLATE_SPINNER_ID);

      return new _ember['default'].RSVP.Promise(function (resolve) {
        _this2.get('store').queryRecord('contracts/file-templates/lookup', { name: name }).then(function (lookupData) {
          _this2.get('loadingSpinner').hide(DOCUMENT_TEMPLATE_SPINNER_ID);

          var isUnique = lookupData.get('__isGeneratedPrimaryID') || id && id == lookupData.id;

          isUnique ? resolve(true) : resolve(NOT_UNIQUE_NAME_MESSAGE);
        })['catch'](function () {
          _this2.get('loadingSpinner').hide(DOCUMENT_TEMPLATE_SPINNER_ID);
          resolve(NOT_UNIQUE_NAME_MESSAGE);
        });
      });
    }
  });
});