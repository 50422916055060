define('quantuvis-cm-spa/components/cm-timeline/menus/event-menus/cm-signed-internally-menu', ['exports', 'ember', 'quantuvis-cm-spa/components/cm-timeline/menus/cm-menu-base', 'quantuvis-cm-spa/constants/attachment-status', 'quantuvis-cm-spa/constants/contract-status'], function (exports, _ember, _quantuvisCmSpaComponentsCmTimelineMenusCmMenuBase, _quantuvisCmSpaConstantsAttachmentStatus, _quantuvisCmSpaConstantsContractStatus) {
  exports['default'] = _quantuvisCmSpaComponentsCmTimelineMenusCmMenuBase['default'].extend({
    classNames: ['cm-signed-internally-menu'],
    store: _ember['default'].inject.service(),
    cancelProhibitedStatuses: [_quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.IN_SIGNATURE, _quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.DEACTIVATED, _quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.TERMINATED, _quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.EXECUTED],

    isDeclineVersionDisabled: _ember['default'].computed('contractDetails.contractId', 'data', function () {
      return !this.get('contractDetails').isContractLead() || !this.getSignedAttachment() || this.get('cancelProhibitedStatuses').includes(this.get('contractDetails').getContract().get('status.id'));
    }),

    getSignedAttachment: function getSignedAttachment() {
      var attachments = this.get('data');

      return attachments.find(function (attachment) {
        return _ember['default'].get(attachment, 'status.id') === _quantuvisCmSpaConstantsAttachmentStatus['default'].SIGNED_INTERNALLY;
      });
    },

    actions: {
      declineVersion: function declineVersion() {
        var options = {
          acceptHandler: 'declineVersionHandler',
          confirmMessage: 'Are you sure you would like to decline this version?'
        };

        this.get('dialog').show('dialogs/cm-dialog', 'dialog-templates/cm-confirmation', this, options);
      },

      declineVersionHandler: function declineVersionHandler(presenter) {
        var _this = this;

        var signedAttachment = this.getSignedAttachment();

        if (signedAttachment) {
          this.set('showDialogSpinner', true);

          this.get('store').adapterFor('workflows/attachments/decline').put('workflows/attachments/' + signedAttachment.id + '/decline').then(function () {
            _this.set('showDialogSpinner', false);
            presenter.accept();
            _this.get('contractDetails').reloadContract();
            _this.get('contractDetails').loadTimelineItems();
          })['catch'](function (error) {
            _this.set('showDialogSpinner', false);
            presenter.accept();
            _ember['default'].Logger.error('Error occurred during declining signed version', error);
          });
        } else {
          presenter.accept();
        }
      }
    }
  });
});