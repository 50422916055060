define('quantuvis-cm-spa/components/sign-off/form/signatory', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    selectedSignatories: null,
    optionsWithoutSelected: null,

    init: function init() {
      this._super.apply(this, arguments);
    },

    actions: {
      setSignatory: function setSignatory(value) {
        var changeset = this.get('changeset');

        var _value$getProperties = value.getProperties('id', 'name', 'email', 'title');

        var id = _value$getProperties.id;
        var name = _value$getProperties.name;
        var email = _value$getProperties.email;
        var title = _value$getProperties.title;

        changeset.setProperties({ id: id, name: name, email: email, title: title });
      },

      setCustomSignatory: function setCustomSignatory(value) {
        var changeset = this.get('changeset');

        if (changeset.get('id')) {
          changeset.set('id', null);
        }

        changeset.set('name', value);
      },

      removeSignatory: function removeSignatory() {
        this.sendAction('removeSignatory', this.get('changeset').get('position'));
      }
    }
  });
});