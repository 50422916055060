define('quantuvis-cm-spa/validations/custom-validators/file-key', ['exports'], function (exports) {
  exports['default'] = fileKey;

  function fileKey() {

    var errorMessage = 'Please, upload new or select existing contract template';

    return function (key, newValue, oldValue, changes, content) {
      var checkValidFileKey = function checkValidFileKey(changes, content) {
        // new changes in changeset
        if (changes.fileKey) {
          return true;
        }
        // no new changes, but old content exist
        if (changes.fileKey === undefined && content.fileKey) {
          return true;
        }

        return false;
      };

      if (checkValidFileKey(changes, content)) {
        return true;
      } else {
        return errorMessage;
      }
    };
  }
});