define('quantuvis-cm-spa/core-ui/components/cm-wizard/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: 'cm-wizard',
    MIN_COUNT_OF_STEPS_FOR_TIMELINE: 2,
    /**
     * Steps configuration
     */
    steps: [],

    /**
     * Index of current step
     */
    currentStepIndex: 0,

    // TODO: add tests for observer!
    /**
     * Observer for 'forcedStepIndex'
     * run 'onBack' action on forced step index change by one
     */
    forceBack: _ember['default'].observer('forcedStepIndex', function () {
      if (this.get('forcedStepIndex') === this.get('currentStepIndex') - 1) {
        this.send('onBack');
      }
    }),

    /**
     * Wizard transition (link to current cm-step component)
     */
    transition: {},

    /**
     * Current step
     */
    currentStep: _ember['default'].computed('currentStepIndex', function () {
      return this.get('steps')[this.get('currentStepIndex')];
    }),

    /**
     * Indicate that step is last in wizard
     */
    isLastStep: _ember['default'].computed('currentStep', function () {
      var currentStep = this.get('currentStep');
      var steps = this.get('steps');

      return steps[steps.length - 1].position === currentStep.position;
    }),

    isShowWizardTimeline: _ember['default'].computed('currentStep', function () {
      return this.get('steps').length >= this.MIN_COUNT_OF_STEPS_FOR_TIMELINE;
    }),

    /**
     * Indicate that step is first in wizard
     */
    isFirstStep: _ember['default'].computed('currentStep', function () {
      var currentStep = this.get('currentStep');
      var steps = this.get('steps');

      return steps[0].position === currentStep.position;
    }),

    actions: {
      /**
       * On next step action
       */
      onNext: function onNext() {
        var _this = this;

        if (!this.get('isLastStep')) {
          this.getTransitionNextPromise().then(function (isValid) {
            if (isValid) {
              _this.incrementProperty('currentStepIndex');
              _this.set('forcedStepIndex', null);
            }
          });
        }
      },

      /**
       * On back step action
       */
      onBack: function onBack() {
        var _this2 = this;

        if (!this.get('isFirstStep')) {
          this.getTransitionBackPromise().then(function () {
            _this2.decrementProperty('currentStepIndex');
            _this2.set('forcedStepIndex', null);
          });
        }
      },

      /**
       * Finish wizard
       */
      onFinish: function onFinish() {
        var _this3 = this;

        this.getTransitionFinishPromise().then(function (data) {
          _this3.sendAction('wizardFinish', data);
        });
      },
      /**
       * Cancel wizard
       */
      onCancel: function onCancel() {
        this.get('transition').cancel();
        this.sendAction('onCancel');
      }
    },

    getTransitionNextPromise: function getTransitionNextPromise() {
      return new Promise(this.get('transition').next.bind(this.get('transition')));
    },

    getTransitionBackPromise: function getTransitionBackPromise() {
      return new Promise(this.get('transition').back.bind(this.get('transition')));
    },

    getTransitionFinishPromise: function getTransitionFinishPromise() {
      return new Promise(this.get('transition').finish.bind(this.get('transition')));
    }
  });
});