define('quantuvis-cm-spa/components/cm-profile-settings/company-list', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['company-list'],
    classNameBindings: ['disabled'],
    profileSettings: _ember['default'].inject.service(),

    disabled: false,

    actions: {
      deleteCompany: function deleteCompany(company, formInput) {
        formInput.update(formInput.value.slice().removeObject(company));
      },
      addCompanies: function addCompanies(formInput) {
        this.get('profileSettings').showAddCompanyDialog(formInput);
      }
    }
  });
});