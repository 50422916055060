define('quantuvis-cm-spa/components/cm-contract-details/summary-components/add-attachment', ['exports', 'ember', 'quantuvis-cm-spa/constants/file-source'], function (exports, _ember, _quantuvisCmSpaConstantsFileSource) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['add-attachment-modal-content'],
    uploadFileMode: true,
    fileQueue: _ember['default'].inject.service(),
    file: _ember['default'].inject.service(),
    contractDetails: _ember['default'].inject.service(),
    fileTypes: ['.pdf', '.doc', '.docx', '.odt', '.txt', '.rtf', '.xls', '.xlsx', '.png', '.jpg', '.jpeg'],
    fileTypeMatcher: /\.(pdf|doc|docx|odt|txt|rtf|xls|xlsx|png|jpg|jpeg)$/i,

    actions: {
      onFileDrop: function onFileDrop(fi, evt) {
        this.get('file').standardFileDropHandler(fi, evt);
      },
      onSourceChange: function onSourceChange(changeset) {
        this.toggleProperty('uploadFileMode');
        var sourceId = this.get('uploadFileMode') ? _quantuvisCmSpaConstantsFileSource['default'].UNKNOWN_SOURCE : _quantuvisCmSpaConstantsFileSource['default'].LINKED_BID_SOURCE;

        changeset.set('source', { id: sourceId });
      },
      uploadNewVersion: function uploadNewVersion(fi, file) {
        this.get('file').uploadTemplate(fi, file, this.get('changeset'), { fileTypeMatcher: this.get('fileTypeMatcher') });
      },
      moreThenOneFilesWarning: function moreThenOneFilesWarning(fi) {
        fi.model.addError('fileKey', ['Only one file can be chosen']);
        this.get('fileQueue').set('files', []);
      },
      removeFile: function removeFile(changeset) {
        changeset.set('fileKey', null);
        changeset.set('fileName', null);
      },
      cancelUpload: function cancelUpload() {
        this.get('file').cancelUpload();
      }
    }
  });
});