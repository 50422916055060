define('quantuvis-cm-spa/services/unauthorized-interceptor', ['exports', 'ember', 'quantuvis-cm-spa/constants/http-statuses'], function (exports, _ember, _quantuvisCmSpaConstantsHttpStatuses) {
  exports['default'] = _ember['default'].Service.extend({
    router: _ember['default'].inject.service('-routing'),

    /**
     * @param {number} status
     */
    handleResponseStatus: function handleResponseStatus(status) {
      if (status === _quantuvisCmSpaConstantsHttpStatuses['default'].UNAUTHORISED) {
        this.get('router').transitionTo('login');
      }
    }
  });
});