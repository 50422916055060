define('quantuvis-cm-spa/constants/workflow-substep-type', ['exports'], function (exports) {
  var DEPARTMENT_REVIEW_ID = 1;
  var CONTRACT_LEAD_REVIEW_ID = 2;
  var SIGN_OFF_ID = 3;

  var CONTRACT_LEAD_REVIEW = {
    id: CONTRACT_LEAD_REVIEW_ID,
    name: 'Contract Lead Review'
  };

  var DEPARTMENT_REVIEW = {
    id: DEPARTMENT_REVIEW_ID,
    name: 'Department Review'
  };

  var SIGN_OFF = {
    id: SIGN_OFF_ID,
    name: 'Sign Off'
  };

  var SUBSTEP_TYPES = [CONTRACT_LEAD_REVIEW, DEPARTMENT_REVIEW, SIGN_OFF];

  exports.CONTRACT_LEAD_REVIEW_ID = CONTRACT_LEAD_REVIEW_ID;
  exports.DEPARTMENT_REVIEW_ID = DEPARTMENT_REVIEW_ID;
  exports.SIGN_OFF_ID = SIGN_OFF_ID;
  exports.CONTRACT_LEAD_REVIEW = CONTRACT_LEAD_REVIEW;
  exports.DEPARTMENT_REVIEW = DEPARTMENT_REVIEW;
  exports.SIGN_OFF = SIGN_OFF;
  exports.SUBSTEP_TYPES = SUBSTEP_TYPES;
});