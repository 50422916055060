define('quantuvis-cm-spa/components/sign-off/form', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    hasActiveLinkedContract: false,
    versionDisabled: false,

    actions: {
      submit: function submit() {},
      onPartyCheckboxClick: function onPartyCheckboxClick() {}
    }
  });
});