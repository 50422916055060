define('quantuvis-cm-spa/helpers/inline-style', ['exports', 'ember'], function (exports, _ember) {
  exports.inlineStyle = inlineStyle;

  function inlineStyle(param, paramsObj) {
    var minWidtg = paramsObj.min ? 'min-width: ' + paramsObj.min + 'px;' : '';
    var maxWidth = paramsObj.max ? 'max-width: ' + paramsObj.max + 'px;' : '';

    return _ember['default'].String.htmlSafe('' + minWidtg + maxWidth);
  }

  exports['default'] = _ember['default'].Helper.helper(inlineStyle);
});