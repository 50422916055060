define('quantuvis-cm-spa/initializers/ember-simple-auth', ['exports', 'quantuvis-cm-spa/config/environment', 'ember-simple-auth/configuration', 'ember-simple-auth/initializers/setup-session', 'ember-simple-auth/initializers/setup-session-service'], function (exports, _quantuvisCmSpaConfigEnvironment, _emberSimpleAuthConfiguration, _emberSimpleAuthInitializersSetupSession, _emberSimpleAuthInitializersSetupSessionService) {
  exports['default'] = {
    name: 'ember-simple-auth',

    initialize: function initialize(registry) {
      var config = _quantuvisCmSpaConfigEnvironment['default']['ember-simple-auth'] || {};
      config.baseURL = _quantuvisCmSpaConfigEnvironment['default'].rootURL || _quantuvisCmSpaConfigEnvironment['default'].baseURL;
      _emberSimpleAuthConfiguration['default'].load(config);

      (0, _emberSimpleAuthInitializersSetupSession['default'])(registry);
      (0, _emberSimpleAuthInitializersSetupSessionService['default'])(registry);
    }
  };
});