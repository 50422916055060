define('quantuvis-cm-spa/components/cm-create-document-menu', ['exports', 'ember', 'quantuvis-cm-spa/constants/permissions', 'quantuvis-cm-spa/constants/contract-type'], function (exports, _ember, _quantuvisCmSpaConstantsPermissions, _quantuvisCmSpaConstantsContractType) {
  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  exports['default'] = _ember['default'].Component.extend({
    CONTRACT_TYPE: _quantuvisCmSpaConstantsContractType['default'],
    classNames: ['cm-create-document-menu'],
    permissionService: _ember['default'].inject.service(),

    canCreateDocuments: _ember['default'].computed('permissionService.assignedPermissions', function () {
      return this.get('permissionService').hasPermissionForAnyCompany(_quantuvisCmSpaConstantsPermissions.PERMISSIONS.ID.CREATE_DOCUMENT);
    }),

    sortedTypes: _ember['default'].computed('contractTypes', function () {
      var types = this.get('contractTypes');
      var contractType = types.find(function (type) {
        return Number(type.get('id')) === _quantuvisCmSpaConstantsContractType['default'].CONTRACT;
      });
      var amendmentType = types.find(function (type) {
        return Number(type.get('id')) === _quantuvisCmSpaConstantsContractType['default'].AMENDMENT;
      });

      var sortedTypes = types.filter(function (t) {
        return Number(t.get('id')) !== _quantuvisCmSpaConstantsContractType['default'].CONTRACT && Number(t.get('id')) !== _quantuvisCmSpaConstantsContractType['default'].AMENDMENT;
      }).sort(function (t1, t2) {
        return t1.get('name').localeCompare(t2.get('name'));
      });

      return [contractType, amendmentType].concat(_toConsumableArray(sortedTypes));
    })
  });
});