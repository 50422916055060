define('quantuvis-cm-spa/components/create-document/cm-amendment-general', ['exports', 'ember', 'ember-concurrency', 'quantuvis-cm-spa/core-ui/components/cm-wizard/cm-step/component', 'ember-changeset', 'ember-changeset-validations', 'quantuvis-cm-spa/validations/amendment-general-details', 'moment', 'quantuvis-cm-spa/constants/date-format', 'quantuvis-cm-spa/constants/contract-origin', 'quantuvis-cm-spa/constants/contract-type', 'quantuvis-cm-spa/constants/contract-status', 'quantuvis-cm-spa/constants/create-document-file-source', 'quantuvis-cm-spa/constants/permissions', 'quantuvis-cm-spa/constants/user-query-type', 'quantuvis-cm-spa/mixins/scroll-to-validation-error', 'quantuvis-cm-spa/constants/error-messages', 'quantuvis-cm-spa/core-ui/components/cm-form/form-config', 'ember-changeset-validations/validators'], function (exports, _ember, _emberConcurrency, _quantuvisCmSpaCoreUiComponentsCmWizardCmStepComponent, _emberChangeset, _emberChangesetValidations, _quantuvisCmSpaValidationsAmendmentGeneralDetails, _moment, _quantuvisCmSpaConstantsDateFormat, _quantuvisCmSpaConstantsContractOrigin, _quantuvisCmSpaConstantsContractType, _quantuvisCmSpaConstantsContractStatus, _quantuvisCmSpaConstantsCreateDocumentFileSource, _quantuvisCmSpaConstantsPermissions, _quantuvisCmSpaConstantsUserQueryType, _quantuvisCmSpaMixinsScrollToValidationError, _quantuvisCmSpaConstantsErrorMessages, _quantuvisCmSpaCoreUiComponentsCmFormFormConfig, _emberChangesetValidationsValidators) {
  exports['default'] = _quantuvisCmSpaCoreUiComponentsCmWizardCmStepComponent['default'].extend(_quantuvisCmSpaMixinsScrollToValidationError['default'], {
    formConfig: _quantuvisCmSpaCoreUiComponentsCmFormFormConfig['default'],
    CreateDocumentFileSource: _quantuvisCmSpaConstantsCreateDocumentFileSource['default'],
    store: _ember['default'].inject.service(),
    documentWizard: _ember['default'].inject.service(),
    dialog: _ember['default'].inject.service(),
    loadingSpinner: _ember['default'].inject.service(),
    contractLeads: [],
    uniqueDocumentValidator: _ember['default'].inject.service(),

    contractTemplateUploadMode: true,

    isEvergreenChanged: false,

    isBidsDisabled: true,
    isLinkToContractDisabled: false,

    linkToContractStatuses: [_quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.EXECUTED, _quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.DEACTIVATED, _quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.TERMINATED, _quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.DELETED, _quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.NEW],

    fileUploadModeRadioDisabled: _ember['default'].computed('changeset.fileKey', 'changeset.fileName', 'changeset.templateComboValue', 'changeset.contractOrigin', function () {
      return this.get('changeset.fileKey') && this.get('changeset.fileName') && this.get('changeset.templateComboValue') === _quantuvisCmSpaConstantsCreateDocumentFileSource['default'].UPLOAD || this.get('changeset.contractOrigin') === _quantuvisCmSpaConstantsContractOrigin['default'].RECEIVED_FROM_OTHER_PARTY;
    }),

    contractOriginRadioDisabled: _ember['default'].computed('documentWizard.activateMode', 'changeset.templateComboValue', function () {
      return this.get('documentWizard.activateMode') || this.get('changeset.templateComboValue') === _quantuvisCmSpaConstantsCreateDocumentFileSource['default'].SELECT;
    }),

    templateTypes: [{
      key: _quantuvisCmSpaConstantsCreateDocumentFileSource['default'].UPLOAD,
      label: 'Upload an Amendment'
    }, {
      key: _quantuvisCmSpaConstantsCreateDocumentFileSource['default'].SELECT,
      label: 'Select from Amendment Templates'
    }],

    tooltipMessages: {
      startDate: 'Date that an amendment will become effective',
      endDate: 'A change in this field will update the parent document end date \n      once the amendment has been executed.',
      evergreen: 'Agreement between two parties that automatically \n      renews the contractual provisions after the Document End Date, \n      until a new document is agreed upon or the document is terminated',
      amendmentId: 'Unique amendment number in the system'
    },

    contractOrigins: [{
      key: _quantuvisCmSpaConstantsContractOrigin['default'].CREATED_INTERNALLY,
      label: 'Creating New Amendment'
    }, {
      key: _quantuvisCmSpaConstantsContractOrigin['default'].RECEIVED_FROM_OTHER_PARTY,
      label: 'Amendment received from other party'
    }],

    init: function init() {
      this._super.apply(this, arguments);
      this.clearExecutedContractsFromStorage();
      this.initChangeset();
      this.prefillLinkToContract();
    },

    clearExecutedContractsFromStorage: function clearExecutedContractsFromStorage() {
      this.get('store').unloadAll('search/contracts/executed-contract');
    },

    /**
     * @todo Add tests
     */
    fetchLeads: function fetchLeads(leadIds) {
      var _this = this;

      return this.get('store').query('user-management/user', {
        permissions: _quantuvisCmSpaConstantsPermissions.PERMISSION_GROUPS.LEAD,
        applicationId: _quantuvisCmSpaConstantsPermissions.PERMISSIONS.APPLICATION.CM,
        resourceId: _quantuvisCmSpaConstantsPermissions.PERMISSIONS.RESOURCE.COMPANY,
        resourceValue: this.get('changeset.linkToContract.companyId'),
        type: _quantuvisCmSpaConstantsUserQueryType['default'].PERMISSION
      }).then(function (data) {
        _this.set('contractLeads', data);

        var preselectedUsers = data.toArray().filter(function (user) {
          return leadIds.contains(parseInt(user.id));
        });

        _this.get('changeset').set('contractLead', preselectedUsers);
      })['catch'](function (error) {
        return _ember['default'].Logger.warn('fetchLeads Error:', error);
      });
    },

    fetchBids: function fetchBids(companyId) {
      var _this2 = this;

      return this.get('store').query('rebate-negotiations/bid', { companyId: companyId }).then(function (data) {
        _this2.set('bids', data);

        return data;
      })['catch'](function (error) {
        return _ember['default'].Logger.warn('fetchBids Error:', error);
      });
    },

    /**
     * @todo add tests for prefillLinkToContract method
     */
    prefillLinkToContract: function prefillLinkToContract() {
      var contract = this.get('documentWizard.amendmentContract');

      if (contract && this.isValidContractForAmendment(contract)) {
        this.setLinkToContract(contract);
      }
    },

    setLinkToContract: function setLinkToContract(contract) {
      var executedContract = this.generateExecutedContractModel(contract);
      var onContractSelectFunction = this.get('actions.onContractSelect').bind(this);

      this.get('changeset').set('linkToContract', executedContract);
      this.set('isLinkToContractDisabled', true);
      onContractSelectFunction(null, executedContract);
    },

    isValidContractForAmendment: function isValidContractForAmendment(contract) {
      return contract.get('type.id') !== _quantuvisCmSpaConstantsContractType['default'].AMENDMENT && this.get('linkToContractStatuses').includes(contract.get('status.id'));
    },

    /**
     * @todo add tests for initChangeset method
     */
    initChangeset: function initChangeset() {
      var validators = _quantuvisCmSpaValidationsAmendmentGeneralDetails['default'];

      this.prepareValidationRules(validators);

      var changeset = new _emberChangeset['default'](this.get('documentWizard.model'), (0, _emberChangesetValidations['default'])(validators), validators, {
        skipValidate: true
      });

      this.set('changeset', changeset);
    },

    prepareValidationRules: function prepareValidationRules(validators) {
      this.attachUniqueNumberValidator(validators);
      this.prepareLinkToContractValidator(validators);
    },

    prepareLinkToContractValidator: function prepareLinkToContractValidator(validators) {
      validators.linkToContract = [(0, _emberChangesetValidationsValidators.validatePresence)(true)];
    },

    /**
     * @todo add tests for initChangeset method
     */
    attachUniqueNumberValidator: function attachUniqueNumberValidator(validators) {
      validators.contractId = [this.get('uniqueDocumentValidator').validateUniqueness(null)];
    },

    generateExecutedContractModel: function generateExecutedContractModel(contract) {
      var alreadyHasRecord = this.get('store').hasRecordForId('search/contracts/executed-contract', contract.get('id'));
      var selectedContract = undefined;

      if (alreadyHasRecord) {
        selectedContract = this.get('store').recordForId('search/contracts/executed-contract', contract.get('id'));

        return selectedContract;
      } else {
        selectedContract = this.get('store').createRecord('search/contracts/executed-contract', {
          id: contract.get('id')
        });
      }
      selectedContract.set('name', contract.get('name'));
      selectedContract.set('number', contract.get('number'));
      selectedContract.set('companyId', contract.get('company.id'));
      selectedContract.set('companyName', contract.get('company.name'));
      selectedContract.set('lineOfBusinessName', contract.get('lineOfBusiness.name'));
      selectedContract.set('evergreen', contract.get('evergreen'));
      selectedContract.set('endDate', contract.get('endDate'));
      selectedContract.set('startDate', contract.get('startDate'));
      selectedContract.set('bids', contract.get('bids'));
      selectedContract.set('contracts', contract.get('contracts').map(function (value) {
        return { id: value.id, statusId: value.status.id };
      }));
      selectedContract.set('leadIds', contract.get('leads').map(function (lead) {
        return parseInt(lead.id);
      }));

      return selectedContract;
    },

    /**
     * @todo add tests for clearChangesetFile method
     */
    clearChangesetFile: function clearChangesetFile() {
      this.get('changeset').set('fileKey', null);
      this.get('changeset').set('fileName', null);
    },

    updateContractData: function updateContractData(value) {
      var _this3 = this;

      this.get('loadingSpinner').show('create-contract-spinner');
      var promises = [];

      this.get('changeset').set('endDate', (0, _moment['default'])(value.get('endDate')).format(_quantuvisCmSpaConstantsDateFormat['default'].MM_DD_YYYY));
      this.get('changeset').set('evergreen', value.get('evergreen'));
      this.get('changeset').set('lineOfBusiness', value.get('lineOfBusinessName'));
      this.set('isEvergreenChanged', false);
      promises.push(this.updateBids(value.get('companyId')));
      this.set('contractLeads', []);
      this.get('changeset').set('contractLead', []);
      promises.push(this.fetchLeads(value.get('leadIds')));

      Promise.all(promises).then(function () {
        return _this3.get('loadingSpinner').hide('create-contract-spinner');
      })['catch'](function () {
        return _this3.get('loadingSpinner').hide('create-contract-spinner');
      });
    },

    clearContractData: function clearContractData() {
      this.get('changeset').set('endDate', null);
      this.get('changeset').set('evergreen', false);
      this.get('changeset').set('lineOfBusiness', null);
      this.set('isEvergreenChanged', false);
      this.updateBids(null);
      this.set('contractLeads', []);
      this.get('changeset').set('contractLead', []);
    },

    /**
     * @todo add tests for actions
     */
    actions: {
      submit: function submit(model) {
        if (this.get('documentWizard.executedMode')) {
          return this.processFinishButton(model);
        }

        this.processNextButton(model);
      },

      onContractTemplateModeChange: function onContractTemplateModeChange() {
        this.clearChangesetFile();
        this.toggleProperty('contractTemplateUploadMode');
        var isUpload = this.get('contractTemplateUploadMode');
        var templateTypes = this.get('templateTypes');

        this.get('changeset').set('templateComboValue', isUpload ? templateTypes[0].key : templateTypes[1].key);
      },

      onEndDateChange: function onEndDateChange(updateCallback, newDate) {
        var _this4 = this;

        updateCallback(newDate);
        if (this.checkEndDateDialogNecessity(newDate)) {
          var options = {
            confirmMessage: 'Would you like to update original Contract End Date?',
            declineHandler: 'endDateDialogDecline'
          };

          this.get('dialog').show('dialogs/cm-dialog', 'dialog-templates/cm-confirmation', this, options).then(function () {
            updateCallback(newDate);
          })['catch'](function () {
            return updateCallback(_this4.get('changeset').get('endDate'));
          }); // It rollback date if user just
          // close the modal window
        }
      },

      endDateDialogDecline: function endDateDialogDecline(presenter) {
        this.get('changeset').set('endDate', (0, _moment['default'])(this.get('changeset').get('linkToContract.endDate')).format(_quantuvisCmSpaConstantsDateFormat['default'].MM_DD_YYYY));
        presenter.decline();
      },

      onEvergreenChange: function onEvergreenChange() {
        var _this5 = this;

        if (!this.get('isEvergreenChanged') && this.get('changeset').get('linkToContract')) {
          var options = {
            confirmMessage: 'Would you like to enable/disable evergreen option for the original Contract?'
          };

          this.get('dialog').show('dialogs/cm-dialog', 'dialog-templates/cm-confirmation', this, options).then(function () {
            _this5.set('isEvergreenChanged', true);
          })['catch'](function () {
            return _this5.get('changeset').toggleProperty('evergreen');
          });
        }
      },

      onContractSelect: function onContractSelect(fi, value) {
        fi && fi.update(value);
        this.get('changeset').validate('linkToContract').then(function () {});
        if (value) {
          this.updateContractData(value);
        } else {
          this.clearContractData();
        }
      },

      onBidSelect: function onBidSelect(fi, value) {
        fi && fi.update(value);
      }
    },

    processNextButton: function processNextButton(model) {
      var _this6 = this;

      var resolveFunc = this.get('resolve');

      model.save().then(function () {
        _this6.markStepAsComplete();
        resolveFunc(true);
      })['catch'](function () {
        // set server errors
      });
    },

    processFinishButton: function processFinishButton(model) {
      var _this7 = this;

      var resolveFunc = this.get('resolve');

      model.set('steps', []);
      this.get('loadingSpinner').show('create-contract-spinner');

      return model.save().then(function () {
        return _this7.saveExecutedAmendment(resolveFunc);
      })['catch'](function () {
        _this7.get('loadingSpinner').hide('create-contract-spinner');
        var options = {
          errorMessage: _quantuvisCmSpaConstantsErrorMessages.DEFAULT_ERROR_MESSAGE
        };

        _this7.get('dialog').show('dialogs/cm-error', null, _this7, options);
      });
    },

    saveExecutedAmendment: function saveExecutedAmendment(resolveFunc) {
      var _this8 = this;

      return this.get('documentWizard').saveContract().then(function (contract) {
        _this8.get('loadingSpinner').hide('create-contract-spinner');
        _this8.get('documentWizard').setInitData();
        resolveFunc(contract);
      });
    },

    updateBids: function updateBids(companyId) {
      var isValidLinkToContract = this.isValidLinkToContract();

      this.set('isBidsDisabled', !isValidLinkToContract);
      this.get('changeset').set('selectedBids', null);

      if (isValidLinkToContract) {
        return this.fetchBids(companyId);
      } else {
        this.set('bids', []);
      }
    },

    isValidLinkToContract: function isValidLinkToContract() {
      return !this.get('changeset').get('error', 'linkToContract').linkToContract;
    },

    checkEndDateDialogNecessity: function checkEndDateDialogNecessity(newDate) {
      return this.get('changeset').get('linkToContract') && !(0, _moment['default'])(newDate, _quantuvisCmSpaConstantsDateFormat['default'].MM_DD_YYYY).isSame(this.get('changeset').get('linkToContract.endDate'), 'day');
    },

    searchContract: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0(term) {
      var searchRequestTimeout;
      return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            searchRequestTimeout = 600;
            context$1$0.next = 3;
            return (0, _emberConcurrency.timeout)(searchRequestTimeout);

          case 3:
            return context$1$0.abrupt('return', this.get('store').query('search/contracts/executed-contract', {
              searchText: term,
              permissions: [_quantuvisCmSpaConstantsPermissions.PERMISSIONS.ID.CREATE_DOCUMENT],
              companyId: this.get('documentWizard.prepopulatedProperties.companyId')
            }));

          case 4:
          case 'end':
            return context$1$0.stop();
        }
      }, callee$0$0, this);
    })),

    next: function next(resolve) {
      if (!this.get('resolve')) {
        this.set('resolve', resolve);
      }
      this.$('#general-data-form').submit();
      this.scrollToValidationError();
    },

    finish: function finish(resolve) {
      this.set('resolve', resolve);
      this.$('#general-data-form').submit();
    },

    uniqueDocumentValidatorObserve: _ember['default'].observer('uniqueDocumentValidator.isLoading', function (self) {
      var isLoading = self.get('uniqueDocumentValidator').get('isLoading');

      if (isLoading) {
        self.get('loadingSpinner').show('create-contract-spinner');
      } else {
        self.get('loadingSpinner').hide('create-contract-spinner');
      }
    })
  });
});