define('quantuvis-cm-spa/mixins/add-step-position-mixin', ['exports', 'ember'], function (exports, _ember) {

  var POSITION_STEP = 1;

  exports['default'] = _ember['default'].Mixin.create({
    beforeCurrentStepPosition: _ember['default'].computed('data.position', function () {
      return this.get('data.position');
    }),
    afterCurrentStepPosition: _ember['default'].computed('data.position', function () {
      return this.get('data.position') + POSITION_STEP;
    })
  });
});