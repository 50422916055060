define("quantuvis-cm-spa/constants/workflow-step-state", ["exports"], function (exports) {
  var WORKFLOW_STEP_STATE = {
    PENDING: 1,
    COMPLETED: 2,
    VOIDED: 3,
    IN_PROGRESS: 4,
    REJECTED: 5
  };

  exports["default"] = WORKFLOW_STEP_STATE;
});