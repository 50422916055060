define('quantuvis-cm-spa/components/cm-timeline/sign-off-sent-details', ['exports', 'ember', 'quantuvis-cm-spa/constants/date-format', 'quantuvis-cm-spa/constants/event-property-name', 'moment'], function (exports, _ember, _quantuvisCmSpaConstantsDateFormat, _quantuvisCmSpaConstantsEventPropertyName, _moment) {
  exports['default'] = _ember['default'].Component.extend({
    DATE_FORMAT: _quantuvisCmSpaConstantsDateFormat['default'],
    classNames: ['cm-event-details'],
    timeline: _ember['default'].inject.service(),
    date: _ember['default'].inject.service(),

    message: _ember['default'].computed('contextObject.event', function () {
      return this.get('timeline').getEventPropertyValueByName(this.get('contextObject.event.properties'), _quantuvisCmSpaConstantsEventPropertyName['default'].MESSAGE);
    }),

    subject: _ember['default'].computed('contextObject.event', function () {
      return this.get('timeline').getEventPropertyValueByName(this.get('contextObject.event.properties'), _quantuvisCmSpaConstantsEventPropertyName['default'].SUBJECT);
    }),

    dueDate: _ember['default'].computed('contextObject.event', function () {
      var dueDate = this.get('timeline').getEventPropertiesValuesByName(this.get('contextObject.event.properties'), _quantuvisCmSpaConstantsEventPropertyName['default'].DUE_DATE);

      return this.get('date').formatDate((0, _moment['default'])(dueDate, _quantuvisCmSpaConstantsDateFormat['default'].ISO_DATETIME));
    }),

    isShowRecipients: _ember['default'].computed('contextObject.event', function () {
      return this.get('timeline').isPresentEventPropertyByName(this.get('contextObject.event.properties'), _quantuvisCmSpaConstantsEventPropertyName['default'].SIGN_OFF_RECEIVER);
    }),

    recipients: _ember['default'].computed('contextObject.event', function () {
      return this.get('timeline').getEventPropertiesValuesByName(this.get('contextObject.event.properties'), _quantuvisCmSpaConstantsEventPropertyName['default'].SIGN_OFF_RECEIVER);
    })
  });
});