define('quantuvis-cm-spa/components/application-error/error-occurred', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['error-occurred'],
    dialog: _ember['default'].inject.service(),
    session: _ember['default'].inject.service(),

    actions: {
      contactQuantuvis: function contactQuantuvis() {
        var options = {
          className: 'cm-contact-quantuvis',
          componentName: 'cm-profile-menu/cm-contact-quantuvis',
          title: 'Contact Quantuvis',
          acceptText: 'Send',
          declineText: 'Close',
          acceptHandler: 'contactQuantuvisAcceptHandler'
        };

        this.get('dialog').show('dialogs/cm-dialog', null, this, options);
      },

      contactQuantuvisAcceptHandler: function contactQuantuvisAcceptHandler(presenter) {
        this.currentFormPresenter = presenter;
        _ember['default'].$('#contact-quantuvis-form').submit();
      },

      accepted: function accepted() {
        var presenter = this.get('currentFormPresenter');

        presenter.accept();
      }
    }
  });
});