define('quantuvis-cm-spa/models/search/attribute', ['exports', 'ember', 'quantuvis-cm-spa/models/search/option', 'quantuvis-cm-spa/constants/search-attributes'], function (exports, _ember, _quantuvisCmSpaModelsSearchOption, _quantuvisCmSpaConstantsSearchAttributes) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  var _createClass = (function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ('value' in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; })();

  var _get = function get(_x, _x2, _x3) { var _again = true; _function: while (_again) { var object = _x, property = _x2, receiver = _x3; _again = false; if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { _x = parent; _x2 = property; _x3 = receiver; _again = true; desc = parent = undefined; continue _function; } } else if ('value' in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } } };

  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError('Cannot call a class as a function'); } }

  function _inherits(subClass, superClass) { if (typeof superClass !== 'function' && superClass !== null) { throw new TypeError('Super expression must either be null or a function, not ' + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

  var Attribute = (function (_Ember$Object) {
    _inherits(Attribute, _Ember$Object);

    function Attribute(id, name, position, createShowAllOption, isHidden, customSort) {
      if (createShowAllOption === undefined) createShowAllOption = true;
      if (isHidden === undefined) isHidden = true;

      _classCallCheck(this, Attribute);

      _get(Object.getPrototypeOf(Attribute.prototype), 'constructor', this).call(this);

      this.id = id;
      this.name = name;
      this.isHidden = isHidden;
      this.options = new Map();
      this.position = position;
      this.showAllOptionEnabled = createShowAllOption;
      this.customSort = customSort;
      this.set('selectedCount', 0);

      if (createShowAllOption) {
        this.createShowAllOption();
      }
    }

    _createClass(Attribute, [{
      key: 'createShowAllOption',
      value: function createShowAllOption() {
        this.addOption(new _quantuvisCmSpaModelsSearchOption.AttributeOption(_quantuvisCmSpaModelsSearchOption.ALL_OPTION_ID, _quantuvisCmSpaModelsSearchOption.ALL_OPTION_NAME, true, true));
      }

      /**
       * Reset attribute selection, and isHidden state
       */
    }, {
      key: 'reset',
      value: function reset() {
        var _this = this;

        this.options.forEach(function (option) {
          _ember['default'].set(option, 'isHidden', false);
          if (option.id !== _quantuvisCmSpaModelsSearchOption.ALL_OPTION_ID) {
            _ember['default'].set(option, 'isSelected', false);
          }
          _this.set('selectedCount', 0);
        });
      }
    }, {
      key: 'hasSelected',
      value: function hasSelected() {
        return undefined !== Array.from(this.options.values()).find(function (option) {
          return option.isSelected;
        });
      }
    }, {
      key: 'clearOptions',
      value: function clearOptions() {
        this.options.clear();

        if (this.showAllOptionEnabled) {
          this.createShowAllOption();
        }
      }

      /**
       * Make all options selected
       */
    }, {
      key: 'selectAllOptions',
      value: function selectAllOptions() {
        this.options.forEach(function (option) {
          if (option.id !== _quantuvisCmSpaModelsSearchOption.ALL_OPTION_ID && option.isActive) {
            _ember['default'].set(option, 'isSelected', true);
          }
        });
      }
    }, {
      key: 'selectOptions',
      value: function selectOptions(optionsIds) {
        this.options.forEach(function (option) {
          _ember['default'].set(option, 'isSelected', optionsIds.includes(option.id));
        });
        this.calculateSelectedCount();
      }

      /**
       * Make filtered options selected
       */
    }, {
      key: 'selectFilteredOptions',
      value: function selectFilteredOptions() {
        this.options.forEach(function (option) {
          if (option.id !== _quantuvisCmSpaModelsSearchOption.ALL_OPTION_ID && option.isHidden !== true && option.isActive) {
            _ember['default'].set(option, 'isSelected', true);
          }
        });
      }

      /**
       * Make all options not selected
       */
    }, {
      key: 'clearAllSelection',
      value: function clearAllSelection() {
        this.options.forEach(function (option) {
          if (option.id !== _quantuvisCmSpaModelsSearchOption.ALL_OPTION_ID && option.isActive) {
            _ember['default'].set(option, 'isSelected', false);
          }
        });
      }
    }, {
      key: 'calculateSelectedCount',
      value: function calculateSelectedCount() {
        var selectedOptions = Array.from(this.options).filter(function (_ref) {
          var _ref2 = _slicedToArray(_ref, 2);

          var option = _ref2[1];

          return option.isCountAsSelected();
        });

        this.set('selectedCount', selectedOptions.length);
      }

      /**
       * Switch show all state
       * @param state
       */
    }, {
      key: 'toggleShowAll',
      value: function toggleShowAll(state) {
        _ember['default'].set(this.options.get(_quantuvisCmSpaModelsSearchOption.ALL_OPTION_ID), 'isSelected', state);
      }

      /**
       * @param {AttributeOption} option
       */
    }, {
      key: 'toggleOption',
      value: function toggleOption(option) {
        var isSelected = !option.isSelected;

        /**
         * Select All activated
         * If option checked reset other options
         * If option unchecked make other options selected
         */
        if (option.id === _quantuvisCmSpaModelsSearchOption.ALL_OPTION_ID) {
          isSelected ? this.reset() : this.selectAllOptions();
        } else {
          this.toggleShowAll(false);
        }

        /**
         * Update option selection
         */
        _ember['default'].set(option, 'isSelected', isSelected);

        if (!this.hasSelected()) {
          this.toggleShowAll(true);
        }

        this.calculateSelectedCount();
      }

      /**
       * filter options by value
       * @param value
       */
    }, {
      key: 'filterOptions',
      value: function filterOptions(value) {
        this.options.forEach(function (element) {
          var isHideElement = !element.name.toLowerCase().includes(value.toLowerCase());

          _ember['default'].set(element, 'isHidden', isHideElement);
        });
      }

      /**
       *
       * @param option {AttributeOption}
       */
    }, {
      key: 'addOption',
      value: function addOption(option) {
        var _this2 = this;

        this.options.set(option.id, option);
        if (this.customSort) {
          _ember['default'].set(this, 'options', new Map([].concat(_toConsumableArray(this.options.entries())).sort(function (a, b) {
            return _this2.customSort(a[1], b[1]);
          })));
        }
        this.notifyPropertyChange('options');
      }

      /**
       * Present selected options as list of ID
       */
    }, {
      key: 'getAttributeSelection',
      value: function getAttributeSelection() {
        if (this.options.get(_quantuvisCmSpaModelsSearchOption.ALL_OPTION_ID) && this.options.get(_quantuvisCmSpaModelsSearchOption.ALL_OPTION_ID).isSelected) {
          return this.id === _quantuvisCmSpaConstantsSearchAttributes['default'].COMPANY.ID ? this.createSelectionFromAll() : [];
        }

        return Array.from(this.options.values()).reduce(function (accumulator, currentValue) {
          if (currentValue.isSelected) {
            accumulator.push(currentValue.id);
          }

          return accumulator;
        }, []);
      }
    }, {
      key: 'createSelectionFromAll',
      value: function createSelectionFromAll() {
        return Array.from(this.options.values()).reduce(function (accumulator, currentValue) {
          if (!currentValue.isHidden && currentValue.isActive && currentValue.id !== _quantuvisCmSpaModelsSearchOption.ALL_OPTION_ID) {
            accumulator.push(currentValue.id);
          }

          return accumulator;
        }, []);
      }
    }]);

    return Attribute;
  })(_ember['default'].Object);

  exports['default'] = Attribute;
});