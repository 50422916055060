define('quantuvis-cm-spa/adapters/user-management/user', ['exports', 'quantuvis-cm-spa/adapters/application', 'quantuvis-cm-spa/constants/user-query-type'], function (exports, _quantuvisCmSpaAdaptersApplication, _quantuvisCmSpaConstantsUserQueryType) {
  exports['default'] = _quantuvisCmSpaAdaptersApplication['default'].extend({
    urlForQuery: function urlForQuery(query) {
      var uri = this.urlPrefix() + '/user-management/users';

      if (query.type === _quantuvisCmSpaConstantsUserQueryType['default'].PERMISSION) {
        uri += '/with-permission';
      }

      query.type = undefined;

      return uri;
    }
  });
});