define('quantuvis-cm-spa/services/workflow-due-date-validator', ['exports', 'ember', 'quantuvis-cm-spa/constants/due-date-events'], function (exports, _ember, _quantuvisCmSpaConstantsDueDateEvents) {

  var DAYS_IN_WEEK = 7;
  var CHANGES_SET_ERRORS_PATH = 'dueDateChangeset._errors';

  exports['default'] = _ember['default'].Service.extend({
    previousUnits: null,
    validationMessage: 'Due Date condition is invalid',
    errorsCount: 0,

    isValid: function isValid(steps) {
      this.reset();
      steps.forEach(this.validateStepTemplates, this);

      return this.get('errorsCount') === 0;
    },

    validateStepTemplates: function validateStepTemplates(step) {
      if (step.get('substeps.length') > 1) {
        this.validateSubstepsTemplates(step.get('substeps'));
      }
      this.validateUnits(step);
    },

    validateSubstepsTemplates: function validateSubstepsTemplates(substeps) {
      var _this = this;

      substeps.forEach(function (substep) {
        return _this.validateUnits(substep, false);
      });

      this.previousUnits = substeps.reduce(this.reduceMaxDueDateUnits.bind(this), 0);
    },

    reduceMaxDueDateUnits: function reduceMaxDueDateUnits(maxValue, currentValue) {
      return Math.max(maxValue, this.calculateUnits(currentValue.get('dueDateChangeset')));
    },

    validateUnits: function validateUnits(object) {
      var adjustPreviusUnits = arguments.length <= 1 || arguments[1] === undefined ? true : arguments[1];

      var units = this.calculateUnits(object.get('dueDateChangeset'));

      this.isInvalidUnitsValue(units) ? this.addError(object) : this.removeError(object);

      if (adjustPreviusUnits) {
        this.previousUnits = units;
      }
    },

    isInvalidUnitsValue: function isInvalidUnitsValue(units) {
      return this.previousUnits !== null && units < this.previousUnits;
    },

    reset: function reset() {
      this.previousUnits = null;
      this.set('errorsCount', 0);
    },

    addError: function addError(object) {
      object.get('dueDateChangeset').addError('units', ['']);
      object.get('dueDateChangeset').addError('dueDateEvent', [this.validationMessage]);

      this.incrementProperty('errorsCount');
    },

    removeError: function removeError(object) {
      _ember['default'].set(object, CHANGES_SET_ERRORS_PATH, {});
      object.get('dueDateChangeset').execute();
    },

    calculateUnits: function calculateUnits(dueDateTemplate) {
      var units = 0;

      var templateUnits = dueDateTemplate.get('units');

      switch (dueDateTemplate.get('dueDateEvent').id) {
        case _quantuvisCmSpaConstantsDueDateEvents.DAYS_FROM_CONTRACT_CREATION_EVENT.id:
          units = templateUnits;
          break;

        case _quantuvisCmSpaConstantsDueDateEvents.WEEKS_FROM_CONTRACT_CREATION_EVENT.id:
          units = templateUnits * DAYS_IN_WEEK;
          break;

        case _quantuvisCmSpaConstantsDueDateEvents.DAYS_AFTER_PREVIOUS_STEP_EVENT.id:
          units = this.previousUnits + templateUnits;
          break;

        default:
      }

      return units;
    }
  });
});