define('quantuvis-cm-spa/components/cm-contract-details/cm-details-menu', ['exports', 'ember', 'quantuvis-cm-spa/constants/contract-status', 'quantuvis-cm-spa/constants/toast-notifications', 'quantuvis-cm-spa/constants/contract-type', 'quantuvis-cm-spa/constants/details-menu-items', 'quantuvis-cm-spa/constants/event-type', 'quantuvis-cm-spa/constants/permissions', 'quantuvis-cm-spa/validations/cancel-sign-off-request', 'ember-changeset', 'ember-changeset-validations'], function (exports, _ember, _quantuvisCmSpaConstantsContractStatus, _quantuvisCmSpaConstantsToastNotifications, _quantuvisCmSpaConstantsContractType, _quantuvisCmSpaConstantsDetailsMenuItems, _quantuvisCmSpaConstantsEventType, _quantuvisCmSpaConstantsPermissions, _quantuvisCmSpaValidationsCancelSignOffRequest, _emberChangeset, _emberChangesetValidations) {
  exports['default'] = _ember['default'].Component.extend({
    PERMISSIONS: _quantuvisCmSpaConstantsPermissions.PERMISSIONS,
    classNames: ['cm-details-menu'],
    amendmentTypeId: _quantuvisCmSpaConstantsContractType['default'].AMENDMENT,
    amendmentMenuItemId: _quantuvisCmSpaConstantsDetailsMenuItems['default'].AMENDMENTS,
    restrictedEventsForDeleteContract: [_quantuvisCmSpaConstantsEventType.EVENT_TYPE.DOCUMENT_SENT_FOR_SIGN_OFF, _quantuvisCmSpaConstantsEventType.EVENT_TYPE.DOCUMENT_SENT_TO_COMPANY, _quantuvisCmSpaConstantsEventType.EVENT_TYPE.SIGNED_DOCUMENT_SENT, _quantuvisCmSpaConstantsEventType.EVENT_TYPE.DOCUMENT_RECEIVED, _quantuvisCmSpaConstantsEventType.EVENT_TYPE.SIGNED_DOCUMENT_RECEIVED, _quantuvisCmSpaConstantsEventType.EVENT_TYPE.DOCUMENT_SENT_READY_FOR_SIGN_OFF, _quantuvisCmSpaConstantsEventType.EVENT_TYPE.DOCUMENT_RECEIVED_READY_FOR_SIGN_OFF],

    contractDetails: _ember['default'].inject.service(),
    dialogManager: _ember['default'].inject.service(),
    signOff: _ember['default'].inject.service(),
    toastNotification: _ember['default'].inject.service(),
    dialog: _ember['default'].inject.service(),
    contractDetailsSidebarNavigator: _ember['default'].inject.service(),
    contractPermissionValidator: _ember['default'].inject.service(),
    uploadVersionService: _ember['default'].inject.service('upload-version'),
    sendDocumentService: _ember['default'].inject.service('send-document'),
    printDocumentService: _ember['default'].inject.service('print-document'),
    downloadVersionsService: _ember['default'].inject.service('download-versions'),
    terminateContractService: _ember['default'].inject.service('terminate-contract'),
    deleteContractService: _ember['default'].inject.service('delete-contract'),
    deactivateDocumentService: _ember['default'].inject.service('deactivate-document'),
    permissionService: _ember['default'].inject.service(),
    loadingSpinner: _ember['default'].inject.service(),
    date: _ember['default'].inject.service(),

    cancelSignOffChangeset: {},
    addAmendmentDisabled: true,
    amendmentsTabDisabled: true,
    isSendDisable: false,
    isDeleteDisabled: _ember['default'].computed('contractDetails.timelineItems', function () {
      return !this.hasPermissionToActivateDeactivateDelete() || this.get('model.status.id') === _quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.IN_SIGNATURE || this.get('model.status.id') === _quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.EXECUTED || this.get('model.status.id') === _quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.TERMINATED || this.isTimelinePreventsToDeleteContract(this.get('contractDetails.timelineItems'));
    }),

    isTerminateDisabled: _ember['default'].computed('model.status.id', 'model.type.id', function () {
      return this.get('model.status.id') !== _quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.TERMINATED && this.get('model.status.id') !== _quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.EXECUTED || !this.amendmentCanBeProcessed(_quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.TERMINATED) || this.hasChildDocumentsInSignature(this.get('model')) || this.get('date').isOverdue(this.get('model.endDate'));
    }),

    amendmentCanBeProcessed: function amendmentCanBeProcessed(amendmentStatusId) {
      return this.get('model.status.id') !== amendmentStatusId || this.get('model.type.id') !== _quantuvisCmSpaConstantsContractType['default'].AMENDMENT || this.get('contractDetails.parentContract.status.id') === _quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.EXECUTED;
    },

    hasChildDocumentsInSignature: function hasChildDocumentsInSignature(document) {
      return document.get('contracts').any(function (contract) {
        return contract.get('status.id') === _quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.IN_SIGNATURE;
      });
    },

    isInitiateSignOffDisabled: _ember['default'].computed('model.status.id', 'contractDetails.versions', function () {
      return !this.get('contractPermissionValidator').checkSignOffInitAvailability(this.get('contractDetails').getContract(), this.get('contractDetails.versions'));
    }),

    isCancelSignOffDisabled: _ember['default'].computed('model.status.id', function () {
      return !this.get('contractPermissionValidator').checkSignOffCancelAvailability(this.get('contractDetails').getContract());
    }),

    isResendSignOffEmailsDisabled: _ember['default'].computed('model.status.id', function () {
      return !this.get('contractPermissionValidator').checkResendSignOffEmailsAvailability(this.get('contractDetails').getContract(), this.get('contractDetails.activeSignOff'));
    }),

    terminateTabLabel: _ember['default'].computed('model.status.id', function () {
      return this.get('model.status.id') === _quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.TERMINATED ? 'Reinstate' : 'Terminate';
    }),

    isDocumentDeactivated: _ember['default'].computed('model.status.id', function () {
      return this.get('model.status.id') === _quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.DEACTIVATED;
    }),

    editWorkflowDisabled: _ember['default'].computed('model.status.id', function () {
      return !this.hasPermissionToEdit() || this.get('model.status.id') === _quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.DEACTIVATED || this.get('model.status.id') === _quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.EXECUTED || this.get('model.status.id') === _quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.TERMINATED;
    }),

    hasPermissionToEdit: function hasPermissionToEdit() {
      return this.get('permissionService').checkCompanyUserPermission(_quantuvisCmSpaConstantsPermissions.PERMISSIONS.ID.EDIT_WORKFLOW) && this.get('permissionService').checkCompanyUserPermission(_quantuvisCmSpaConstantsPermissions.PERMISSIONS.ID.READ_TIMELINE);
    },

    deactivateMenuItemTitle: _ember['default'].computed('model.status.id', function () {
      return this.get('model.status.id') === _quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.DEACTIVATED ? 'Reactivate' : 'Deactivate';
    }),

    isDeactivateDisabled: _ember['default'].computed('model.status.id', function () {
      return !this.hasPermissionToActivateDeactivateDelete() || this.parentHasOtherActiveAmendments() || !this.amendmentCanBeProcessed(_quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.DEACTIVATED) || this.get('model.status.id') === _quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.IN_SIGNATURE || this.get('model.status.id') === _quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.TERMINATED || this.get('model.status.id') === _quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.EXECUTED || this.get('model.status.id') === _quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.NEW;
    }),

    parentHasOtherActiveAmendments: function parentHasOtherActiveAmendments() {
      var _this = this;

      var contracts = this.get('contractDetails.parentContract.contracts');

      return contracts && contracts.filter(function (child) {
        return _this.get('model.id') !== child.get('id') && _this.get('contractDetails').isActiveStatus(child.get('status'));
      }).length;
    },

    isEditSignOffDisabled: _ember['default'].computed('model', 'contractDetails.activeSignOff', function () {
      return !this.get('contractPermissionValidator').isEditSignOffAvailable(this.get('model'), this.get('contractDetails.activeSignOff'));
    }),

    hasPermissionToActivateDeactivateDelete: function hasPermissionToActivateDeactivateDelete() {
      return this.get('permissionService').checkCompanyUserPermission(_quantuvisCmSpaConstantsPermissions.PERMISSIONS.ID.ACTIVATE_DEACTIVATE_DELETE_DOCUMENT);
    },

    isUploadVersionDisabled: _ember['default'].computed('model.status.id', function () {
      return !this.isResponsibleOrLead() || !this.hasPermissionToUploadNewVersion() || this.get('model.status.id') === _quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.DEACTIVATED || this.get('model.status.id') === _quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.TERMINATED || this.get('model.status.id') === _quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.EXECUTED || this.get('model.status.id') === _quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.NEW;
    }),

    isResponsibleOrLead: function isResponsibleOrLead() {
      return this.get('contractDetails').isContractLead() || this.get('contractDetails').isCurrentStepResponsiblePerson();
    },

    hasPermissionToUploadNewVersion: function hasPermissionToUploadNewVersion() {
      return this.get('permissionService').checkCompanyUserPermission(_quantuvisCmSpaConstantsPermissions.PERMISSIONS.ID.UPLOAD_DOCUMENT);
    },

    isSendDisabled: _ember['default'].computed('model.status.id', function () {
      return this.get('model.status.id') === _quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.NEW;
    }),

    selectedMenuItem: _ember['default'].computed.readOnly('contractDetailsSidebarNavigator.selectedMenuItem'),

    modelObserver: _ember['default'].observer('model', 'model.status.id', 'model.type.id', function () {
      this.setButtonsPermissions();
    }),

    init: function init() {
      this._super.apply(this, arguments);
      this.setButtonsPermissions();
    },

    setButtonsPermissions: function setButtonsPermissions() {
      this.set('addAmendmentDisabled', this.isAddAmendmentDisabled());
      this.set('amendmentsTabDisabled', this.get('model.type.id') === this.get('amendmentTypeId'));
    },

    calculatePosition: function calculatePosition(trigger) {
      var contentVerticalOffset = 21;

      var _trigger$getBoundingClientRect = trigger.getBoundingClientRect();

      var top = _trigger$getBoundingClientRect.top;
      var left = _trigger$getBoundingClientRect.left;
      var height = _trigger$getBoundingClientRect.height;
      var style = {
        left: left,
        top: top + window.pageYOffset + height + contentVerticalOffset
      };

      return { style: style };
    },

    isAddAmendmentDisabled: function isAddAmendmentDisabled() {
      return this.get('model.type.id') === this.get('amendmentTypeId') || this.get('model.status.id') !== _quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.EXECUTED && this.get('model.status.id') !== _quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.TERMINATED || !this.hasPermissionToAddAmendment();
    },

    hasPermissionToAddAmendment: function hasPermissionToAddAmendment() {
      return this.get('permissionService').checkCompanyUserPermission(_quantuvisCmSpaConstantsPermissions.PERMISSIONS.ID.CREATE_DOCUMENT);
    },

    isTimelinePreventsToDeleteContract: function isTimelinePreventsToDeleteContract(timelineItems) {
      var _this2 = this;

      if (!timelineItems) {
        return false;
      }

      return timelineItems.any(function (item) {
        return _this2.get('restrictedEventsForDeleteContract').includes(item.get('event.eventType.id')) || _this2.areStepEventsPreventToDeleteContract(item.get('workflowStep.events'));
      });
    },

    areStepEventsPreventToDeleteContract: function areStepEventsPreventToDeleteContract(stepEvents) {
      var _this3 = this;

      if (!stepEvents) {
        return false;
      }

      return stepEvents.any(function (item) {
        return _this3.get('restrictedEventsForDeleteContract').includes(item.get('event.eventType.id'));
      });
    },

    initCancelSignOffRequestChangeset: function initCancelSignOffRequestChangeset() {
      var model = _ember['default'].Object.create({
        reason: ''
      });
      var changeset = new _emberChangeset['default'](model, (0, _emberChangesetValidations['default'])(_quantuvisCmSpaValidationsCancelSignOffRequest['default']), _quantuvisCmSpaValidationsCancelSignOffRequest['default'], {
        skipValidate: true
      });

      this.set('cancelSignOffChangeset', changeset);
    },

    cancelSignOffRequest: function cancelSignOffRequest() {
      var _this4 = this;

      this.initCancelSignOffRequestChangeset();

      var reasonOptions = {
        className: 'cancel-sign-off-request-dialog',
        componentName: 'cm-timeline/menus/dialog/cancel-sign-off-request-dialog',
        title: 'Cancel Sign Off',
        acceptText: 'Cancel Sign Off',
        declineText: 'Close',
        acceptHandler: 'sendCancelSignOffRequest'
      };

      this.get('dialogManager').showDialogConfirmation('Do you want to cancel Sign Off?').then(function () {
        _this4.get('dialog').show('dialogs/cm-dialog', null, _this4, reasonOptions);
      });
    },

    actions: {
      uploadNew: function uploadNew() {
        this.get('uploadVersionService').showModal();
      },

      itemSelected: function itemSelected(urlHash) {
        this.get('contractDetailsSidebarNavigator').setUrlHash(urlHash);
      },

      sendDocument: function sendDocument() {
        this.get('sendDocumentService').showModal();
      },

      printDocument: function printDocument() {
        this.get('printDocumentService').printImmediately(this.get('model'));
      },

      downloadVersions: function downloadVersions() {
        this.get('downloadVersionsService').showModal();
      },

      toggleTermination: function toggleTermination() {
        if (this.get('model.status.id') !== _quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.TERMINATED) {
          this.get('terminateContractService').showTerminateModal();
        } else {
          this.get('terminateContractService').showReinstateConfirmation();
        }
      },

      toggleDeactivation: function toggleDeactivation() {
        if (this.get('model.status.id') !== _quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.DEACTIVATED) {
          this.get('deactivateDocumentService').showDeactivateConfirmation();
        } else {
          this.get('deactivateDocumentService').showReactivateConfirmation();
        }
      },

      'delete': function _delete() {
        if (this.get('contractDetails.timelineItems.length') === 0 || this.get('contractDetails.isTimelineItemsLoading') || this.get('isDeleteDisabled')) {
          return;
        }
        this.get('deleteContractService').showConfirmation(this.get('model'));
      },

      cancelSignOff: function cancelSignOff() {
        this.cancelSignOffRequest();
      },

      resendSignOffEmails: function resendSignOffEmails() {
        var _this5 = this;

        this.get('signOff').resendEmails(this.get('contractDetails.activeSignOff.id')).then(function () {
          _this5.get('toastNotification').add(_quantuvisCmSpaConstantsToastNotifications.RESEND_EMAILS_SUCCESS);
        })['catch'](function () {
          _this5.get('toastNotification').add(_quantuvisCmSpaConstantsToastNotifications.RESEND_EMAILS_ERROR);
        });
      },

      sendCancelSignOffRequest: function sendCancelSignOffRequest(presenter) {
        var _this6 = this;

        this.set('showDialogSpinner', true);
        var changeset = this.get('cancelSignOffChangeset');
        var data = {
          reason: changeset.get('reason')
        };

        changeset.validate().then(function () {
          if (changeset.get('isInvalid')) {
            return Promise.reject();
          }

          return _this6.get('signOff').cancel(_this6.get('contractDetails.activeSignOff.id'), data);
        }).then(function () {
          _this6.set('showDialogSpinner', false);

          presenter.accept();

          return Promise.all([_this6.get('contractDetails').loadTimelineItems(), _this6.get('contractDetails').reloadContract()]).then(function () {
            _this6.set('contractDetails.activeSignOff', null);
            _this6.get('toastNotification').add(_quantuvisCmSpaConstantsToastNotifications.CANCEL_SIGN_OFF);
          });
        })['catch'](function () {
          return _this6.set('showDialogSpinner', false);
        });
      },
      editSignOff: function editSignOff() {
        var _this7 = this;

        this.get('loadingSpinner').show('contract-details-spinner');

        this.get('signOff').edit(this.get('contractDetails.activeSignOff.id'), this.get('contractDetails.contractId')).then(function () {
          return _this7.get('loadingSpinner').hide('contract-details-spinner');
        })['catch'](function () {
          return _this7.get('loadingSpinner').hide('contract-details-spinner');
        });
      }
    }
  });
});