define('quantuvis-cm-spa/initializers/user-agent', ['exports', 'ember-useragent/initializers/user-agent'], function (exports, _emberUseragentInitializersUserAgent) {

  /**
   * Ember UserAgent initializer
   *
   * Supports auto injection of the userAgent service app-wide.
   *
   * Generated by the ember-useragent addon.
   * Customize to change injection.
   */

  exports['default'] = {
    name: 'user-agent',
    initialize: _emberUseragentInitializersUserAgent.initialize
  };
});