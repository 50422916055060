define('quantuvis-cm-spa/components/cm-document-summary/cm-edit-summary', ['exports', 'ember', 'moment', 'ember-changeset', 'ember-changeset-validations', 'quantuvis-cm-spa/validations/document-summary', 'quantuvis-cm-spa/validations/company-contact-person', 'quantuvis-cm-spa/constants/date-format', 'quantuvis-cm-spa/constants/contract-status', 'quantuvis-cm-spa/constants/contract-type', 'quantuvis-cm-spa/models/dropdown-type-options', 'quantuvis-cm-spa/mixins/scroll-to-validation-error', 'ember-concurrency', 'quantuvis-cm-spa/constants/line-of-business', 'quantuvis-cm-spa/constants/contact-type'], function (exports, _ember, _moment, _emberChangeset, _emberChangesetValidations, _quantuvisCmSpaValidationsDocumentSummary, _quantuvisCmSpaValidationsCompanyContactPerson, _quantuvisCmSpaConstantsDateFormat, _quantuvisCmSpaConstantsContractStatus, _quantuvisCmSpaConstantsContractType, _quantuvisCmSpaModelsDropdownTypeOptions, _quantuvisCmSpaMixinsScrollToValidationError, _emberConcurrency, _quantuvisCmSpaConstantsLineOfBusiness, _quantuvisCmSpaConstantsContactType) {
  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  exports['default'] = _ember['default'].Component.extend(_quantuvisCmSpaMixinsScrollToValidationError['default'], {
    DATE_FORMAT: _quantuvisCmSpaConstantsDateFormat['default'],
    classNames: ['cm-edit-summary'],
    store: _ember['default'].inject.service(),
    dialog: _ember['default'].inject.service(),
    documentSummary: _ember['default'].inject.service(),
    contactPerson: _ember['default'].inject.service(),
    company: _ember['default'].inject.service(),
    uniqueDocumentValidator: _ember['default'].inject.service(),
    loadingSpinner: _ember['default'].inject.service(),
    editSummary: _ember['default'].inject.service(),
    date: _ember['default'].inject.service(),
    leadOptions: [],
    isEvergreenChanged: false,
    tooltipMessages: {
      startDate: 'Date that a document will become effective',
      endDate: ' Date that a document will end on',
      evergreen: 'Agreement between two parties that automatically\n      renews the contractual provisions after the Document End Date,\n      until a new document is agreed upon or the document is terminated',
      contractId: 'Unique document number in the system'
    },
    lineOfBusinessesOptions: [],
    isAmendment: _ember['default'].computed('editSummary.changeset', function () {
      return this.get('editSummary.changeset.type.id') === _quantuvisCmSpaConstantsContractType['default'].AMENDMENT;
    }),

    init: function init() {
      this._super.apply(this, arguments);
      this.initChangeset();
      this.fetchLinesOfBusiness();
      this.fetchLeads();
      this.fetchLinkToContract();
    },

    initChangeset: function initChangeset() {
      var _this = this;

      this.attachUniqueNumberValidator(_quantuvisCmSpaValidationsDocumentSummary['default']);
      this.formatDates();
      this.set('contractInfo.lineOfBusiness', new _quantuvisCmSpaModelsDropdownTypeOptions.DropdownOption(this.get('contractInfo.lineOfBusiness')));

      var changeset = new _emberChangeset['default'](this.get('contractInfo'), (0, _emberChangesetValidations['default'])(_quantuvisCmSpaValidationsDocumentSummary['default']), _quantuvisCmSpaValidationsDocumentSummary['default'], {
        skipValidate: true
      });

      this.get('editSummary').set('changeset', changeset);

      var contractContactPersons = this.get('editSummary.changeset.contactPersons');

      this.get('editSummary').set('contractPersonsChangesets', []);
      this.get('editSummary').set('contractContactPersons', contractContactPersons);

      this.get('contactPerson').fetchContactPersons(this.get('editSummary.changeset.company.id')).then(function (contactPersons) {
        _this.set('companyContactPersons', contactPersons);
        _this.set('contactNamesOptions', _ember['default'].copy(contactPersons, true));

        contractContactPersons.forEach(function (contactPerson) {
          _this.createContactPersonChangeset(contactPerson);
        });

        var originalLength = _this.get('editSummary.contractPersonsChangesets').length;

        _this.get('editSummary').set('contractPersonsOriginalLength', originalLength);
      });

      this.set('companyInfo', this.get('company').joinCompanyData(this.get('editSummary.changeset.company')));
      var isInTerminalStage = [_quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.DEACTIVATED, _quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.TERMINATED, _quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.EXECUTED].includes(this.get('editSummary.changeset.status.id'));
      var isDeactivatedOrTerminated = [_quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.DEACTIVATED, _quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.TERMINATED].includes(this.get('editSummary.changeset.status.id'));

      this.set('isInTerminalStage', isInTerminalStage);
      this.set('isDeactivatedOrTerminated', isDeactivatedOrTerminated);
      this.set('reviewers', this.get('documentSummary').getContractReviewers(this.get('workflow')));
    },

    formatDates: function formatDates() {
      this.set('contractInfo.endDate', (0, _moment['default'])(this.get('contractInfo.endDate')).format(_quantuvisCmSpaConstantsDateFormat['default'].MM_DD_YYYY));
      this.set('originalEndDate', (0, _moment['default'])(this.get('contractInfo.endDate')).format(_quantuvisCmSpaConstantsDateFormat['default'].MM_DD_YYYY));
      this.set('contractInfo.startDate', (0, _moment['default'])(this.get('contractInfo.startDate')).format(_quantuvisCmSpaConstantsDateFormat['default'].MM_DD_YYYY));
    },

    attachUniqueNumberValidator: function attachUniqueNumberValidator(validators) {
      validators.number = [this.get('uniqueDocumentValidator').validateUniqueness(this.get('contractInfo').id)];
    },

    /**
     * @todo Add tests
     */
    fetchLeads: function fetchLeads() {
      var _this2 = this;

      this.get('documentSummary').getUsers(this.get('contractInfo.company.id')).then(function (allUsers) {
        _this2.set('leadOptions', allUsers);
      })['catch'](function (error) {
        _ember['default'].Logger.warn('fetchLeads Error:', error);
      });
    },

    fetchLinkToContract: function fetchLinkToContract() {
      var _this3 = this;

      if (this.get('editSummary.changeset.type.id') === _quantuvisCmSpaConstantsContractType['default'].AMENDMENT) {
        this.get('store').findRecord('contract', this.get('contractInfo.contractId')).then(function (data) {
          _this3.get('editSummary.changeset').set('linkToContract', _ember['default'].Object.create({
            startDate: data.get('startDate')
          }));

          _this3.set('editSummary.changeset.relatedDocuments', [_ember['default'].Object.create({ name: data.get('name') })]);
        });
      } else {
        var linkedDocuments = this.get('contractInfo.linkedDocuments');

        if (linkedDocuments && linkedDocuments.length > 0) {
          this.get('store').query('search/contracts/available-for-linking', {
            companyId: this.get('editSummary.changeset.company.id'),
            ids: linkedDocuments.map(function (document) {
              return document.id;
            })
          }).then(function (data) {
            return _this3.set('editSummary.changeset.relatedDocuments', data);
          });
        } else {
          this.set('editSummary.changeset.relatedDocuments', []);
        }
      }
    },

    searchContract: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0(term) {
      var searchRequestTimeout;
      return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
        var _this4 = this;

        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            searchRequestTimeout = 600;
            context$1$0.next = 3;
            return (0, _emberConcurrency.timeout)(searchRequestTimeout);

          case 3:
            return context$1$0.abrupt('return', this.get('store').query('search/contracts/available-for-linking', {
              companyId: this.get('editSummary.changeset.company.id'),
              searchText: term
            }).then(function (data) {
              return data.filter(function (elem) {
                return elem.get('id') !== _this4.get('contractInfo.id');
              });
            }));

          case 4:
          case 'end':
            return context$1$0.stop();
        }
      }, callee$0$0, this);
    })),

    createContactPerson: function createContactPerson() {
      var emptyContactPerson = this.get('contactPerson').getEmptyContactModel();

      this.get('editSummary.contractContactPersons').addObject(emptyContactPerson);

      this.createContactPersonChangeset(emptyContactPerson);
    },

    preselectPerson: function preselectPerson(contactPerson) {
      var personId = contactPerson.personId;
      var selectedPerson = null;

      if (personId) {
        selectedPerson = this.get('contactNamesOptions').find(function (contact) {
          return contact.id == personId;
        });

        if (selectedPerson) {
          this.populateExistingPersonDataToContactPerson(contactPerson, selectedPerson);
        }
      }

      if (!selectedPerson) {
        this.populateCustomPersonDataToContactPerson(contactPerson);
      }
    },

    populateExistingPersonDataToContactPerson: function populateExistingPersonDataToContactPerson(contactPerson, selectedPerson) {
      contactPerson.personId = null;
      contactPerson.id = selectedPerson.id;
      contactPerson.contactPerson = selectedPerson;
      contactPerson.contactType = _quantuvisCmSpaConstantsContactType['default'].EXISTING;
      this.get('contactNamesOptions').removeObject(selectedPerson);
    },

    populateCustomPersonDataToContactPerson: function populateCustomPersonDataToContactPerson(contactPerson) {
      contactPerson.contactType = _quantuvisCmSpaConstantsContactType['default'].CUSTOM;
      contactPerson.contactPerson = contactPerson.name;
    },

    createContactPersonChangeset: function createContactPersonChangeset(contactPerson) {
      var options = { options: this.get('editSummary.contractPersonsChangesets') };

      this.preselectPerson(contactPerson);
      var changeset = new _emberChangeset['default'](contactPerson, (0, _emberChangesetValidations['default'])((0, _quantuvisCmSpaValidationsCompanyContactPerson['default'])(options)), (0, _quantuvisCmSpaValidationsCompanyContactPerson['default'])(options), {
        skipValidate: true
      });

      this.get('editSummary.contractPersonsChangesets').addObject(changeset);
    },

    fetchLinesOfBusiness: function fetchLinesOfBusiness() {
      var _this5 = this;

      this.get('documentSummary').getLinesOfBusiness().then(function (data) {
        _this5.set('lineOfBusinessesOptions', new _quantuvisCmSpaModelsDropdownTypeOptions.SelectDropdownOptions(_this5.sortLineOfBusiness(data)).options);
      });
    },

    sortLineOfBusiness: function sortLineOfBusiness(lineOfBusiness) {
      var noLineOfBusiness = lineOfBusiness.find(function (lob) {
        return Number(lob.get('id')) === _quantuvisCmSpaConstantsLineOfBusiness['default'].NO_LINE_OF_BUSINESS;
      });

      var otherLineOfBusiness = lineOfBusiness.filter(function (lob) {
        return Number(lob.get('id')) !== _quantuvisCmSpaConstantsLineOfBusiness['default'].NO_LINE_OF_BUSINESS;
      }).sort(function (lob1, lob2) {
        return lob1.get('name').localeCompare(lob2.get('name'));
      });

      return [noLineOfBusiness].concat(_toConsumableArray(otherLineOfBusiness));
    },

    deletePerson: function deletePerson(index) {
      var _this6 = this;

      this.$('.contact-block-' + index).hide(this.get('contactPerson').animationDuration, function () {
        var deletedContactPersonId = _this6.get('contactPerson').deletePerson(index, _this6.get('editSummary.contractPersonsChangesets'), _this6.get('editSummary.contractContactPersons'));

        if (deletedContactPersonId) {
          var deletedContactPersonOption = _this6.get('contactPerson').getContactPersonById(_this6.get('companyContactPersons'), deletedContactPersonId);

          if (deletedContactPersonOption) {
            var contactNamesOptions = _this6.get('contactNamesOptions');

            contactNamesOptions.pushObject(deletedContactPersonOption);
            _this6.set('contactNamesOptions', contactNamesOptions.sortBy('id'));
          }
        }
      });
    },

    checkEndDateDialogNecessity: function checkEndDateDialogNecessity(newDate) {
      return this.get('isAmendment') && (0, _moment['default'])(newDate).format(_quantuvisCmSpaConstantsDateFormat['default'].MM_DD_YYYY) !== (0, _moment['default'])(this.get('contractInfo.endDate')).format(_quantuvisCmSpaConstantsDateFormat['default'].MM_DD_YYYY);
    },

    saveContactPersonsChangesets: function saveContactPersonsChangesets() {
      var _this7 = this;

      this.get('editSummary.contractPersonsChangesets').forEach(function (changeset) {
        changeset.set('companyId', _this7.get('contractInfo.company.id'));
        changeset.set('name', changeset.get('contactPerson.name') ? changeset.get('contactPerson.name') : changeset.get('contactPerson'));
        var isContactPersonChanged = _this7.get('contactPerson').isContactPersonChanged(changeset);

        changeset.set('personId', isContactPersonChanged ? null : changeset.get('id'));
        changeset.set('id', null);
        changeset.save();
      });
    },

    actions: {
      submit: function submit(changeset) {
        var _this8 = this;

        this.get('loadingSpinner').show('cm-document-summary-spinner');
        var changesets = this.get('editSummary.contractPersonsChangesets');
        var promises = this.get('contactPerson').validatePersons(changesets);

        Promise.all(promises).then(function () {
          var invalidChangesets = changesets.filter(function (changeset) {
            return changeset.get('isInvalid');
          });

          if (invalidChangesets.length === 0) {
            _this8.saveContactPersonsChangesets();
            var savedChangesets = _this8.get('editSummary.contractContactPersons');

            changeset.set('contactPersons', savedChangesets);
            changeset.set('leadIds', changeset.get('leads').map(function (lead) {
              return lead.id;
            }));

            var relatedDocuments = changeset.get('relatedDocuments');

            if (_this8.get('editSummary.changeset.type.id') !== _quantuvisCmSpaConstantsContractType['default'].AMENDMENT && relatedDocuments) {
              changeset.set('linkedDocuments', relatedDocuments.map(function (ld) {
                return { id: ld.get('id') };
              }));
            } else {
              changeset.set('linkedDocuments', []);
            }

            changeset.save().then(function () {
              _this8.formatDates();
              _this8.sendAction('saveButtonClick');
              _this8.get('loadingSpinner').hide('cm-document-summary-spinner');
            })['catch'](function () {
              _this8.get('loadingSpinner').hide('cm-document-summary-spinner');
            });
          } else {
            _this8.get('loadingSpinner').hide('cm-document-summary-spinner');
          }
        });
      },

      onEndDateChange: function onEndDateChange(updateCallback, newDate) {
        var _this9 = this;

        updateCallback(newDate);
        if (this.checkEndDateDialogNecessity(newDate)) {
          var options = {
            confirmMessage: 'Would you like to update original Contract End Date?'
          };

          this.get('dialog').show('dialogs/cm-dialog', 'dialog-templates/cm-confirmation', this, options).then(function () {})['catch'](function () {
            updateCallback(_this9.get('originalEndDate'));
          });
        }
      },

      onEvergreenChange: function onEvergreenChange() {
        var _this10 = this;

        if (!this.get('isEvergreenChanged') && this.get('editSummary.changeset.type.id') === _quantuvisCmSpaConstantsContractType['default'].AMENDMENT) {
          var options = {
            confirmMessage: 'Would you like to enable/disable evergreen option for the original Contract?'
          };

          this.get('dialog').show('dialogs/cm-dialog', 'dialog-templates/cm-confirmation', this, options).then(function () {
            _this10.set('isEvergreenChanged', true);
          })['catch'](function () {
            return _this10.get('editSummary.changeset').toggleProperty('evergreen');
          });
        }
      },

      handleSaveClick: function handleSaveClick() {
        this.get('editSummary.contractPersonsChangesets').forEach(function (personChangeset) {
          return personChangeset.validate();
        });
        this.$('#edit-summary-form').submit();
        this.scrollToValidationError();
      },

      handleCancelClick: function handleCancelClick() {
        this.sendAction('cancelButtonClick');
      },

      addContactPerson: function addContactPerson() {
        _ember['default'].run.scheduleOnce('render', this, function () {
          this.$('.contact-person').last().css('display', 'none');
        });

        _ember['default'].run.scheduleOnce('afterRender', this, function () {
          this.$('.contact-person').last().show(this.get('contactPerson').animationDuration);
        });

        this.createContactPerson();
      },

      deleteContactPerson: function deleteContactPerson(index) {
        var _this11 = this;

        this.get('contactPerson').showPersonDeleteConfirmationDialog().then(function () {
          _this11.deletePerson(index);
        })['catch'](function () {});
      }
    },

    bidsList: _ember['default'].computed('contractInfo.bids', function () {
      var _this12 = this;

      var bids = this.get('contractInfo').get('bids');
      var result = null;

      if (bids.length > 0) {
        result = bids.map(function (bid) {
          return _this12.getBidTitle(bid);
        }).join(', ');
      }

      return result;
    }),

    getBidTitle: function getBidTitle(bid) {
      var startDate = this.get('date').formatDate(bid.contractStartDate);
      var endDate = this.get('date').formatDate(bid.contractEndDate);

      return bid.title + ' (' + startDate + '-' + endDate + ') - ' + bid.lineOfBusiness.name;
    },

    uniqueDocumentValidatorObserve: _ember['default'].observer('uniqueDocumentValidator.isLoading', function (self) {
      var isLoading = self.get('uniqueDocumentValidator').get('isLoading');

      if (isLoading) {
        self.get('loadingSpinner').show('cm-document-summary-spinner');
      } else {
        self.get('loadingSpinner').hide('cm-document-summary-spinner');
      }
    })
  });
});