define('quantuvis-cm-spa/components/cm-timeline/cm-timeline-controls', ['exports', 'ember', 'quantuvis-cm-spa/constants/contract-status', 'quantuvis-cm-spa/constants/contract-type', 'quantuvis-cm-spa/constants/permissions'], function (exports, _ember, _quantuvisCmSpaConstantsContractStatus, _quantuvisCmSpaConstantsContractType, _quantuvisCmSpaConstantsPermissions) {
  exports['default'] = _ember['default'].Component.extend({
    PERMISSIONS: _quantuvisCmSpaConstantsPermissions.PERMISSIONS,
    classNames: ['cm-timeline-controls', 'v-align-wrapper'],
    uploadVersionService: _ember['default'].inject.service('upload-version'),
    sendDocument: _ember['default'].inject.service(),
    contractDetails: _ember['default'].inject.service(),
    permissionService: _ember['default'].inject.service(),
    contractPermissionValidator: _ember['default'].inject.service(),
    loadingSpinner: _ember['default'].inject.service(),
    signOff: _ember['default'].inject.service(),
    router: _ember['default'].inject.service('-routing'),

    isPanelForCompleted: _ember['default'].computed('model.status.id', function () {
      return this.get('model.status.id') === _quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.EXECUTED || this.get('model.status.id') === _quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.TERMINATED;
    }),

    isContractInSignature: _ember['default'].computed('model.status.id', function () {
      return this.get('model.status.id') === _quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.IN_SIGNATURE;
    }),

    isAddAmendmentAvailable: _ember['default'].computed('model', function () {
      return this.get('model.type.id') !== _quantuvisCmSpaConstantsContractType['default'].AMENDMENT;
    }),

    amendmentTypeId: _quantuvisCmSpaConstantsContractType['default'].AMENDMENT,

    isInitiateSignOffDisabled: _ember['default'].computed('model.status.id', 'contractDetails.versions', function () {
      return !this.get('contractPermissionValidator').checkSignOffInitAvailability(this.get('contractDetails').getContract(), this.get('contractDetails.versions'));
    }),

    init: function init() {
      this._super.apply(this, arguments);
    },

    isSendDisabled: _ember['default'].computed('model.status.id', function () {
      var statusId = this.get('model.status.id');

      return statusId === _quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.NEW;
    }),

    isUploadVersionDisabled: _ember['default'].computed('contractDetails.leads.[]', 'contractDetails.inProgressStepResponsiblePersons.[]', 'contractDetails.isTimelineItemsLoading', function () {
      var statusId = this.get('model.status.id');

      return !this.hasPermissionToUploadNewVersion() || !this.isResponsibleOrLead() || statusId === _quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.DEACTIVATED || statusId === _quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.TERMINATED || statusId === _quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.EXECUTED || statusId === _quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.NEW;
    }),

    isEditSignOffDisabled: _ember['default'].computed('model', 'contractDetails.activeSignOff', function () {
      return !this.get('contractPermissionValidator').isEditSignOffAvailable(this.get('model'), this.get('contractDetails.activeSignOff'));
    }),

    isResponsibleOrLead: function isResponsibleOrLead() {
      return this.get('contractDetails').isContractLead() || this.get('contractDetails').isCurrentStepResponsiblePerson();
    },

    hasPermissionToUploadNewVersion: function hasPermissionToUploadNewVersion() {
      return this.get('permissionService').checkCompanyUserPermission(_quantuvisCmSpaConstantsPermissions.PERMISSIONS.ID.UPLOAD_DOCUMENT);
    },

    actions: {
      onUpload: function onUpload() {
        if (!this.get('isUploadVersionDisabled')) {
          this.get('uploadVersionService').showModal();
        }
      },

      onSend: function onSend() {
        this.get('sendDocument').showModal();
      },

      redirectToInitiateSignOff: function redirectToInitiateSignOff() {
        this.get('router').transitionTo('initiate-sign-off', [this.get('contractDetails.contractId')]);
      },

      editSignOff: function editSignOff() {
        var _this = this;

        this.get('loadingSpinner').show('contract-details-spinner');

        this.get('signOff').edit(this.get('contractDetails.activeSignOff.id'), this.get('contractDetails.contractId')).then(function () {
          return _this.get('loadingSpinner').hide('contract-details-spinner');
        })['catch'](function () {
          return _this.get('loadingSpinner').hide('contract-details-spinner');
        });
      }
    }
  });
});