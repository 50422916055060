define('quantuvis-cm-spa/components/cm-contract-details/cm-send-document', ['exports', 'ember', 'quantuvis-cm-spa/constants/user-query-type', 'quantuvis-cm-spa/constants/send-document-type'], function (exports, _ember, _quantuvisCmSpaConstantsUserQueryType, _quantuvisCmSpaConstantsSendDocumentType) {
  exports['default'] = _ember['default'].Component.extend({
    sendDocument: _ember['default'].inject.service(),
    contractDetails: _ember['default'].inject.service(),
    store: _ember['default'].inject.service(),
    classNames: ['cm-send-document'],

    isInternally: false,
    allowToChooseDestination: false,
    userOptions: [],
    contactPersonsOptions: [],
    isUsersLoading: false,
    isContactPersonsLoading: false,
    maxAmountOfItems: 20,

    recipients: _ember['default'].computed('isInternally', 'userOptions.[]', 'contactPersonsOptions.[]', function () {
      return this.get('isInternally') ? this.get('userOptions') : this.get('contactPersonsOptions');
    }),
    selectedRecipients: _ember['default'].computed.alias('sendDocument.changeset.recipients'),

    init: function init() {
      this._super.apply(this, arguments);
      this.setInitData();
    },

    setInitData: function setInitData() {
      this.preselectSendType();
      this.set('allowToChooseDestination', this.get('sendDocument').isAllowedToSendExternally());
      this.get('sendDocument').fillFileVersions();
      this.fetchUsers();
      this.fetchContactPersons();
    },

    preselectSendType: function preselectSendType() {
      var isInternally = this.get('contextObject.sendDocumentAvailableTypes').some(function (sendType) {
        return sendType.key === _quantuvisCmSpaConstantsSendDocumentType['default'].SEND_DOCUMENT_INTERNALLY;
      });

      this.set('isInternally', isInternally);
    },

    willDestroyElement: function willDestroyElement() {
      this.get('sendDocument').resetRecipientsList();
    },

    prepareContactPersonsOptions: function prepareContactPersonsOptions(persons) {
      var personsOptions = [];

      persons.forEach(function (person) {
        personsOptions.push(_ember['default'].Object.create({
          id: person.get('id'),
          name: person.get('name'),
          email: person.get('email')
        }));
      });

      return personsOptions;
    },

    prepareUsersOptions: function prepareUsersOptions(users) {
      var usersOptions = [];

      users.forEach(function (person) {
        usersOptions.push(_ember['default'].Object.create({
          id: person.get('id'),
          name: person.get('fullName'),
          email: person.get('email')
        }));
      });

      return usersOptions;
    },

    actions: {
      setEditRecipientMode: function setEditRecipientMode(_ref) {
        var changeset = _ref.changeset;
        var index = _ref.index;

        this.setProperties({
          changesetToEdit: changeset,
          changesetToEditIndex: '' + index
        });
      },

      resetEditRecipientMode: function resetEditRecipientMode() {
        this.setProperties({
          changesetToEdit: null,
          changesetToEditIndex: null
        });
      },

      sendTypeChange: function sendTypeChange() {
        this.toggleProperty('isInternally');
        this.get('sendDocument').resetRecipientsList();
      }
    },

    fetchUsers: function fetchUsers() {
      var _this = this;

      this.set('isUsersLoading', true);
      this.get('store').query('user-management/user', {
        type: _quantuvisCmSpaConstantsUserQueryType['default'].COMMON
      }).then(function (data) {
        _this.set('userOptions', _this.prepareUsersOptions(data));
        _this.set('isUsersLoading', false);
      })['catch'](function (error) {
        _ember['default'].Logger.warn('fetchUsers Error:', error);
        _this.set('isUsersLoading', false);
      });
    },
    fetchContactPersons: function fetchContactPersons() {
      var _this2 = this;

      this.set('isContactPersonsLoading', true);
      this.get('store').unloadAll('contracts/contact-person');
      this.get('store').findAll('contracts/contact-person', { reload: true }).then(function (data) {
        _this2.set('contactPersonsOptions', _this2.prepareContactPersonsOptions(data));
        _this2.set('isContactPersonsLoading', false);
      })['catch'](function (error) {
        _ember['default'].Logger.warn('fetchContactPersons Error:', error);
        _this2.set('isContactPersonsLoading', false);
      });
    }
  });
});