define('quantuvis-cm-spa/controllers/error-page', ['exports', 'ember', 'quantuvis-cm-spa/components/thank-you-page/constants/index'], function (exports, _ember, _quantuvisCmSpaComponentsThankYouPageConstantsIndex) {

  var DEFAULT_CAUSE = 'default';
  var TITLES = {
    'default': 'Something went wrong. Please try again or contact Quantuvis Administrator',
    authentication_failed: 'Authentication failed. Please try again or contact Quantuvis Administrator',
    session_expired: 'Your session has expired'
  };

  exports['default'] = _ember['default'].Controller.extend({
    session: _ember['default'].inject.service(),

    queryParams: ['cause'],
    cause: null,
    brandItems: _quantuvisCmSpaComponentsThankYouPageConstantsIndex.brandItems,

    title: _ember['default'].computed('cause', function () {
      var cause = this.get('cause');

      return TITLES[cause] || TITLES[DEFAULT_CAUSE];
    })
  });
});