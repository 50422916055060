define('quantuvis-cm-spa/components/thank-you-page/event', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['thank-you-page-event-wrapper'],

    type: '',
    title: '',
    description: '',
    icon: '',
    isAuthenticated: false,

    isDescriptionVisible: _ember['default'].computed('type', 'isAuthenticated', function () {
      var _getProperties = this.getProperties('type', 'isAuthenticated');

      var type = _getProperties.type;
      var isAuthenticated = _getProperties.isAuthenticated;

      return type !== 'default' || !isAuthenticated;
    })
  });
});