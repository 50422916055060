define('quantuvis-cm-spa/services/timeline', ['exports', 'ember', 'quantuvis-cm-spa/constants/event-type'], function (exports, _ember, _quantuvisCmSpaConstantsEventType) {
  exports['default'] = _ember['default'].Service.extend({
    dialog: _ember['default'].inject.service(),

    concatenatePersonDepartments: function concatenatePersonDepartments(departments) {
      if (!departments) {
        _ember['default'].Logger.warn('concatenatePersonDepartments - departments is undefined or null');

        return '';
      }
      var departmentNames = departments.map(function (department) {
        return department.name;
      });

      return departmentNames.join(', ');
    },

    createPersonInfo: function createPersonInfo(person, separator) {
      if (!person || !person.get('fullName')) {
        _ember['default'].Logger.warn('createPersonInfo - person is undefined or null');

        return '';
      }
      var departmentsString = this.getDepartmentString(person.get('departments'), separator);

      return person.get('firstName') + ' ' + person.get('lastName') + departmentsString;
    },

    //TODO: Add tests
    getDepartmentString: function getDepartmentString(departments) {
      var separator = arguments.length <= 1 || arguments[1] === undefined ? 'of' : arguments[1];

      var concatenatedDepartments = this.concatenatePersonDepartments(departments);

      return concatenatedDepartments ? ' ' + separator + ' ' + concatenatedDepartments : '';
    },

    createResponsiblePersonsString: function createResponsiblePersonsString(responsiblePersons) {
      var _this = this;

      if (!responsiblePersons) {
        _ember['default'].Logger.warn('createResponsiblePersonsString - responsiblePersons is undefined or null');

        return '';
      }
      var responsiblePersonsNames = responsiblePersons.map(function (person) {
        return _this.createPersonInfo(person);
      });

      return responsiblePersonsNames.join(', ');
    },

    getEventPropertiesValuesByName: function getEventPropertiesValuesByName(properties, nameId) {
      return properties.filter(function (property) {
        return Number(property.get('name.id')) === nameId;
      }).sortBy('position').map(function (property) {
        return property.get('value');
      });
    },

    getEventPropertyValueByName: function getEventPropertyValueByName(properties, nameId) {
      var property = properties.find(function (property) {
        return Number(property.get('name.id')) === nameId;
      });

      return property ? property.get('value') : null;
    },

    isPresentEventPropertyByName: function isPresentEventPropertyByName(properties, nameId) {
      return properties.find(function (property) {
        return Number(property.get('name.id')) === nameId;
      }) !== undefined;
    },

    getEventDetailsComponent: function getEventDetailsComponent(eventTypeId) {
      switch (eventTypeId) {
        case _quantuvisCmSpaConstantsEventType.EVENT_TYPE.SIGN_OFF_REQUEST_DECLINED_COUNTERPARTY:
        case _quantuvisCmSpaConstantsEventType.EVENT_TYPE.SIGN_OFF_REQUEST_DECLINED:
          return 'cm-timeline/sign-off-request-decline-details';
        case _quantuvisCmSpaConstantsEventType.EVENT_TYPE.SIGN_OFF_CANCELLED_COUNTERPARTY:
        case _quantuvisCmSpaConstantsEventType.EVENT_TYPE.SIGN_OFF_CANCELLED:
          return 'cm-timeline/sign-off-cancel-details';
        case _quantuvisCmSpaConstantsEventType.EVENT_TYPE.SIGN_OFF_DECLINED:
          return 'cm-timeline/sign-off-decline-details';
        case _quantuvisCmSpaConstantsEventType.EVENT_TYPE.SIGN_OFF_REASSIGNED:
          return 'cm-timeline/sign-off-reassigned-details';
        case _quantuvisCmSpaConstantsEventType.EVENT_TYPE.SIGN_OFF_REQUEST_ACCEPTED:
        case _quantuvisCmSpaConstantsEventType.EVENT_TYPE.SIGN_OFF_UPDATED_COUNTERPARTY:
        case _quantuvisCmSpaConstantsEventType.EVENT_TYPE.SIGN_OFF_REQUEST_ACCEPTED_COUNTERPARTY:
          return 'cm-timeline/init-signoff-details';
        case _quantuvisCmSpaConstantsEventType.EVENT_TYPE.SIGN_OFF_REQUEST_SENT:
        case _quantuvisCmSpaConstantsEventType.EVENT_TYPE.SIGN_OFF_REQUEST_RECEIVED:
        case _quantuvisCmSpaConstantsEventType.EVENT_TYPE.SIGN_OFF_UPDATED:
          return 'cm-timeline/sign-off-sent-details';
        case _quantuvisCmSpaConstantsEventType.EVENT_TYPE.DOCUMENT_TERMINATED:
        case _quantuvisCmSpaConstantsEventType.EVENT_TYPE.AMENDMENT_TERMINATED:
          return 'cm-timeline/terminate-details';
        default:
          return 'cm-timeline/cm-event-details';
      }
    },

    showEventDetails: function showEventDetails(event) {
      var options = {
        className: 'cm-event-details-dialog',
        componentName: this.getEventDetailsComponent(event.get('eventType.id')),
        title: 'Details',
        hideAcceptButton: true
      };

      this.get('dialog').show('dialogs/cm-dialog', null, { event: event }, options);
    }
  });
});