define('quantuvis-cm-spa/components/cm-timeline/cm-event-details', ['exports', 'ember', 'quantuvis-cm-spa/constants/date-format', 'quantuvis-cm-spa/constants/event-property-name'], function (exports, _ember, _quantuvisCmSpaConstantsDateFormat, _quantuvisCmSpaConstantsEventPropertyName) {
  exports['default'] = _ember['default'].Component.extend({
    DATE_FORMAT: _quantuvisCmSpaConstantsDateFormat['default'],
    classNames: ['cm-event-details'],
    timeline: _ember['default'].inject.service(),

    message: _ember['default'].computed('contextObject.event', function () {
      return this.get('timeline').getEventPropertyValueByName(this.get('contextObject.event.properties'), _quantuvisCmSpaConstantsEventPropertyName['default'].MESSAGE);
    }),

    subject: _ember['default'].computed('contextObject.event', function () {
      return this.get('timeline').getEventPropertyValueByName(this.get('contextObject.event.properties'), _quantuvisCmSpaConstantsEventPropertyName['default'].SUBJECT);
    }),

    recipients: _ember['default'].computed('contextObject.event', function () {
      return this.get('timeline').getEventPropertiesValuesByName(this.get('contextObject.event.properties'), _quantuvisCmSpaConstantsEventPropertyName['default'].SENT_FILE_RECEIVER);
    })
  });
});