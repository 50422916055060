define('quantuvis-cm-spa/components/cm-workflow-step/cm-notes-input', ['exports', 'ember', 'quantuvis-cm-spa/constants/tooltip-messages'], function (exports, _ember, _quantuvisCmSpaConstantsTooltipMessages) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['cm-notes-input'],
    isActive: false,
    animationDuration: 200,
    classNameBindings: ['isActive:active:in-active'],
    TOOLTIP_MESSAGES: _quantuvisCmSpaConstantsTooltipMessages['default'],

    init: function init() {
      this._super.apply(this, arguments);
      this.initTextAreaControl();
    },

    initTextAreaControl: function initTextAreaControl() {
      if (this.get('form.model.notes')) {
        this.set('isActive', true);
      }
    },

    actions: {
      /**
       * Show note textarea
       */
      onAddNote: function onAddNote() {
        this.toggleProperty('isActive');
        this.$('textarea').show(this.animationDuration).focus();
      },
      /**
       * On focus out hide textarea if model is empty
       */
      focusOut: function focusOut(control, notes) {
        var _this = this;

        control.update(notes);

        if (!notes.trim()) {
          this.$('textarea').hide(this.animationDuration, function () {
            _this.set('isActive', false);
          });
        }
      }
    }
  });
});