define('quantuvis-cm-spa/services/deactivate-document', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Service.extend({
    dialog: _ember['default'].inject.service(),
    contractDetails: _ember['default'].inject.service(),
    store: _ember['default'].inject.service(),
    alertService: _ember['default'].inject.service(),
    DEACTIVATE_CONFIRMATION_MESSAGE: 'Are you sure you would like to change the document status to Deactivated?',
    ACTIVATE_CONFIRMATION_MESSAGE: 'Are you sure you would like to change the document status to In Review?',

    showDeactivateConfirmation: function showDeactivateConfirmation() {
      var options = {
        confirmMessage: this.get('DEACTIVATE_CONFIRMATION_MESSAGE'),
        acceptHandler: 'deactivateDocument'
      };

      this.get('dialog').show('dialogs/cm-dialog', 'dialog-templates/cm-confirmation', this, options);
    },

    showReactivateConfirmation: function showReactivateConfirmation() {
      var options = {
        confirmMessage: this.get('ACTIVATE_CONFIRMATION_MESSAGE'),
        acceptHandler: 'reactivateDocument'
      };

      this.get('dialog').show('dialogs/cm-dialog', 'dialog-templates/cm-confirmation', this, options);
    },

    processPutRequest: function processPutRequest(promise, presenter) {
      var _this = this;

      promise.then(function () {
        _this.set('showDialogSpinner', false);
        presenter.accept();
        _this.get('contractDetails').reloadContract().then(function () {
          _this.reloadContractAndRelativeItems();
        });
      })['catch'](function () {
        _this.set('showDialogSpinner', false);
        presenter.accept();
      });
    },

    reloadContractAndRelativeItems: function reloadContractAndRelativeItems() {
      this.get('contractDetails').loadTimelineItems();
      this.get('alertService').loadAlerts();
    },

    actions: {
      reactivateDocument: function reactivateDocument(presenter) {
        var contractId = this.get('contractDetails.contractId');

        this.set('showDialogSpinner', true);
        var putPromise = this.get('store').adapterFor('contracts/reactivate').put('contracts/' + contractId + '/reactivate', '', {});

        this.processPutRequest(putPromise, presenter);
      },
      deactivateDocument: function deactivateDocument(presenter) {
        var contractId = this.get('contractDetails.contractId');

        this.set('showDialogSpinner', true);
        var putPromise = this.get('store').adapterFor('contracts/deactivate').put('contracts/' + contractId + '/deactivate', '', {});

        this.processPutRequest(putPromise, presenter);
      }
    }
  });
});