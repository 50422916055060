define('quantuvis-cm-spa/models/notifications/task', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    name: _emberData['default'].attr('string'),
    contractId: _emberData['default'].attr(),
    description: _emberData['default'].attr(),
    type: _emberData['default'].attr(),
    status: _emberData['default'].attr(),
    dueDate: _emberData['default'].attr('unix-date'),
    createdDate: _emberData['default'].attr('unix-timestamp'),
    completedDate: _emberData['default'].attr('unix-timestamp'),
    expireDate: _emberData['default'].attr('unix-timestamp'),
    objects: _emberData['default'].attr()
  });
});