define('quantuvis-cm-spa/services/contract-permission-validator', ['exports', 'ember', 'quantuvis-cm-spa/constants/contract-status', 'quantuvis-cm-spa/constants/permissions', 'quantuvis-cm-spa/constants/attachment-status', 'quantuvis-cm-spa/constants/sign-off-status'], function (exports, _ember, _quantuvisCmSpaConstantsContractStatus, _quantuvisCmSpaConstantsPermissions, _quantuvisCmSpaConstantsAttachmentStatus, _quantuvisCmSpaConstantsSignOffStatus) {
  exports['default'] = _ember['default'].Service.extend({
    permissionService: _ember['default'].inject.service(),
    profile: _ember['default'].inject.service(),

    allowedContractStatusesToSend: [_quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.IN_REVIEW, _quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.PARTIALLY_EXECUTED_INTERNALLY, _quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.PARTIALLY_EXECUTED_EXTERNALLY],
    allowedVersionStatusesToSend: [_quantuvisCmSpaConstantsAttachmentStatus['default'].SIGNED_INTERNALLY, _quantuvisCmSpaConstantsAttachmentStatus['default'].NOT_SIGNED, _quantuvisCmSpaConstantsAttachmentStatus['default'].SIGNED_EXTERNALLY],

    checkSignOffInitAvailability: function checkSignOffInitAvailability(contract, versions) {
      var _this = this;

      if (!versions || !versions.get('length')) {
        return false;
      }

      var isAllowedVersionStatusesToSend = versions.any(function (value) {
        return _this.get('allowedVersionStatusesToSend').includes(value.get('status.id'));
      });
      var isAllowedContractStatusesToSend = this.get('allowedContractStatusesToSend').includes(contract.get('status.id'));
      var hasCompanyUserPermission = this.get('permissionService').checkCompanyUserPermission(_quantuvisCmSpaConstantsPermissions.PERMISSIONS.ID.INITIATE_SIGN_OFF, contract.get('company.id'));

      return isAllowedVersionStatusesToSend && isAllowedContractStatusesToSend && hasCompanyUserPermission;
    },

    checkSignOffCancelAvailability: function checkSignOffCancelAvailability(contract) {
      var contractStatus = contract.get('status.id');
      var hasCompanyUserPermission = this.get('permissionService').checkCompanyUserPermission(_quantuvisCmSpaConstantsPermissions.PERMISSIONS.ID.INITIATE_SIGN_OFF, contract.get('company.id'));

      return hasCompanyUserPermission && contractStatus === _quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.IN_SIGNATURE;
    },

    checkResendSignOffEmailsAvailability: function checkResendSignOffEmailsAvailability(contract, signOff) {
      var isContractInSignature = contract.get('status.id') === _quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.IN_SIGNATURE;
      var hasCompanyUserPermission = this.get('permissionService').checkCompanyUserPermission(_quantuvisCmSpaConstantsPermissions.PERMISSIONS.ID.INITIATE_SIGN_OFF, contract.get('company.id'));
      var isSignOffInProgress = signOff !== null && signOff.get('status.id') === _quantuvisCmSpaConstantsSignOffStatus['default'].IN_PROGRESS_ID;

      return hasCompanyUserPermission && isContractInSignature && isSignOffInProgress;
    },

    isEditSignOffAvailable: function isEditSignOffAvailable(contract, signOff) {
      var currentUserAccountId = this.get('profile').getUserProfileAccountId();
      var contractStatus = contract.get('status.id');
      var hasCompanyUserPermission = this.get('permissionService').checkCompanyUserPermission(_quantuvisCmSpaConstantsPermissions.PERMISSIONS.ID.INITIATE_SIGN_OFF, contract.get('company.id'));

      var userBelongsToCompanyWithEditPrivilege = _ember['default'].isPresent(signOff) && (currentUserAccountId === signOff.get('company.id') || _ember['default'].isEmpty(signOff.get('contractId')) && signOff.get('creatorAccountId') === currentUserAccountId);

      return hasCompanyUserPermission && contractStatus === _quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.IN_SIGNATURE && userBelongsToCompanyWithEditPrivilege;
    }
  });
});