define('quantuvis-cm-spa/components/cm-status-details-popover', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['cm-status-details-popover'],
    date: _ember['default'].inject.service(),
    departmentPartToRemove: ' Department',

    actions: {
      firePopoverClose: function firePopoverClose(basicDropdown) {
        _ember['default'].run(this.closePopover.bind(this, basicDropdown));
      },
      handlePopoverOpen: function handlePopoverOpen(basicDropdown) {
        _ember['default'].$('.models-contracts-table tbody').on('scroll', this.closePopover.bind(this, basicDropdown));
      },
      handlePopoverClose: function handlePopoverClose() {
        _ember['default'].$('.models-contracts-table tbody').unbind('scroll');
      }
    },

    closePopover: function closePopover(basicDropdown) {
      basicDropdown.actions.close();
    }
  });
});
/**
 * Input attributes:
 * - items
 */