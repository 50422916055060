define('quantuvis-cm-spa/services/edit-summary', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Service.extend({
    changeset: {},
    contractContactPersons: [],
    contractPersonsChangesets: [],
    contractPersonsOriginalLength: 0,
    isModelDirty: function isModelDirty() {
      var contractPersonsChangesets = this.get('contractPersonsChangesets');

      return !!this.get('changeset.isDirty') || !!contractPersonsChangesets.find(function (changeset) {
        return changeset.get('isDirty');
      }) || this.get('contractPersonsOriginalLength') !== contractPersonsChangesets.length;
    },

    reset: function reset() {
      this.setProperties({
        changeset: {},
        contractContactPersons: [],
        contractPersonsChangesets: [],
        contractPersonsOriginalLength: 0
      });
    }
  });
});