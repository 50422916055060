define('quantuvis-cm-spa/helpers/read-contract-status-const', ['exports', 'ember', 'quantuvis-cm-spa/constants/contract-status'], function (exports, _ember, _quantuvisCmSpaConstantsContractStatus) {
  exports.readContractStatusConst = readContractStatusConst;

  function readContractStatusConst(params) {
    if (params.length === 0) {
      return undefined;
    }

    return _ember['default'].Object.create(_quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS).get(params[0]);
  }

  exports['default'] = _ember['default'].Helper.helper(readContractStatusConst);
});