define('quantuvis-cm-spa/services/timeline-event-title', ['exports', 'ember', 'quantuvis-cm-spa/constants/event-type', 'quantuvis-cm-spa/constants/event-property-name'], function (exports, _ember, _quantuvisCmSpaConstantsEventType, _quantuvisCmSpaConstantsEventPropertyName) {
  exports['default'] = _ember['default'].Service.extend({
    contractDetails: _ember['default'].inject.service(),
    timeline: _ember['default'].inject.service(),

    buildSecondaryTitle: function buildSecondaryTitle(event) {
      //TODO: QCM-3191 Apply ember-data dependencies to all current models
      if (!event.get) {
        event = _ember['default'].Object.create(event);
      }
      var type = event.get('eventType.id');
      var title = undefined;

      switch (type) {
        case _quantuvisCmSpaConstantsEventType.EVENT_TYPE.DOCUMENT_TERMINATED:
        case _quantuvisCmSpaConstantsEventType.EVENT_TYPE.AMENDMENT_TERMINATED:
          title = this.documentTerminatedSecondaryTitle(event);
          break;
        case _quantuvisCmSpaConstantsEventType.EVENT_TYPE.SIGN_OFF_SIGNED_BY_SIGNATORY:
        case _quantuvisCmSpaConstantsEventType.EVENT_TYPE.SIGN_OFF_DECLINED:
          title = this.signatureReceivedSecondaryTitleStrategy(event);
          break;
        case _quantuvisCmSpaConstantsEventType.EVENT_TYPE.SIGN_OFF_REASSIGNED:
          title = this.signatureReassignedSecondaryTitleStrategy(event);
          break;
        default:
          title = '';
          break;
      }

      return title;
    },

    buildTitle: function buildTitle(event) {
      //TODO: QCM-3191 Apply ember-data dependencies to all current models
      if (!event.get) {
        event = _ember['default'].Object.create(event);
      }
      var type = event.get('eventType.id');
      var title = '';

      switch (type) {
        case _quantuvisCmSpaConstantsEventType.EVENT_TYPE.SIGN_OFF_SIGNED_BY_SIGNATORY:
          title = this.signatureReceivedTitleStrategy(event);
          break;
        case _quantuvisCmSpaConstantsEventType.EVENT_TYPE.SIGN_OFF_DECLINED:
          title = this.signatureDeclinedTitleStrategy(event);
          break;
        case _quantuvisCmSpaConstantsEventType.EVENT_TYPE.SIGN_OFF_REASSIGNED:
          title = this.signatureReassignedTitleStrategy(event);
          break;
        default:
          title = this.defaultTitleStrategy(event);
          break;
      }

      return title;
    },

    documentTerminatedSecondaryTitle: function documentTerminatedSecondaryTitle(event) {
      return !event.get('data.executorId') && 'via expiration';
    },

    signatureReceivedTitleStrategy: function signatureReceivedTitleStrategy(event) {
      var signerName = this.getPropertyValue(event, _quantuvisCmSpaConstantsEventPropertyName['default'].SIGNER_NAME);

      return 'Signed by ' + signerName;
    },

    signatureDeclinedTitleStrategy: function signatureDeclinedTitleStrategy(event) {
      var signerName = this.getPropertyValue(event, _quantuvisCmSpaConstantsEventPropertyName['default'].SIGNER_NAME);

      return 'Sign Off was declined by ' + signerName;
    },

    signatureReassignedTitleStrategy: function signatureReassignedTitleStrategy(event) {
      var signerName = this.getPropertyValue(event, _quantuvisCmSpaConstantsEventPropertyName['default'].SIGN_OFF_ASSIGNER_NAME);

      return 'Sign Off was reassigned by ' + signerName;
    },

    signatureReceivedSecondaryTitleStrategy: function signatureReceivedSecondaryTitleStrategy(event) {
      var signerTitle = this.getPropertyValue(event, _quantuvisCmSpaConstantsEventPropertyName['default'].SIGNER_TITLE);

      return this.buildSignerTitle(signerTitle, event);
    },

    signatureReassignedSecondaryTitleStrategy: function signatureReassignedSecondaryTitleStrategy(event) {
      var signerTitle = this.getPropertyValue(event, _quantuvisCmSpaConstantsEventPropertyName['default'].SIGN_OFF_ASSIGNER_TITLE);

      return this.buildSignerTitle(signerTitle, event);
    },

    buildSignerTitle: function buildSignerTitle(signerTitle, event) {
      var company = event.get('company.name');
      var title = '';

      if (signerTitle) {
        title = title.concat(' ' + signerTitle);
      }

      return title.concat(' - ' + company);
    },

    defaultTitleStrategy: function defaultTitleStrategy(event) {
      var type = event.get('eventType.id');

      return _quantuvisCmSpaConstantsEventType.EVENT_TYPE_TITLES.hasOwnProperty(type) ? _quantuvisCmSpaConstantsEventType.EVENT_TYPE_TITLES[type].replace('{document}', this.getDocumentTypeName(type, event)) : '';
    },

    getDocumentTypeName: function getDocumentTypeName(eventTypeId, event) {
      var eventDocument = event.get('document.type');

      switch (eventTypeId) {
        case _quantuvisCmSpaConstantsEventType.EVENT_TYPE.PARENT_CONTRACT_EXECUTED:
          return eventDocument ? eventDocument : '';
        default:
          return this.get('contractDetails').getDocumentTypeName();
      }
    },

    getPropertyValue: function getPropertyValue(event, propertyName) {
      var properties = event.get('properties');

      return this.get('timeline').isPresentEventPropertyByName(properties, propertyName) ? this.get('timeline').getEventPropertyValueByName(properties, propertyName) : null;
    }
  });
});