define('quantuvis-cm-spa/core-ui/components/cm-wizard/cm-step/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['cm-step'],
    step: {},

    init: function init() {
      this._super(arguments);
      this.get('wizard').set('transition', this);
    },

    /**
     * Async next transition validation
     *
     * @returns {boolean} - indicate that transition is valid
     */
    next: function next(resolve) {
      resolve(true);
    },

    /**
     * Async back transition validation
     *
     * @returns {boolean} - indicate that transition is valid
     */
    back: function back(resolve) {
      resolve(true);
    },

    /**
     * Finish activated
     *
     * @returns {boolean}
     */
    finish: function finish(resolve) {
      resolve();
    },
    /**
     * Cancel activated
     *
     * @returns {boolean}
     */
    cancel: function cancel() {},

    /**
     * Current step is incompleted
     */
    markStepAsIncomplete: function markStepAsIncomplete() {
      _ember['default'].set(this.step, 'isCompleted', false);
    },

    /**
     * Current step is completed
     */
    markStepAsComplete: function markStepAsComplete() {
      _ember['default'].set(this.step, 'isCompleted', true);
    }
  });
});