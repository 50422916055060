define('quantuvis-cm-spa/routes/thank-you-page', ['exports', 'ember', 'quantuvis-cm-spa/mixins/query-params-url-cleaner'], function (exports, _ember, _quantuvisCmSpaMixinsQueryParamsUrlCleaner) {
  exports['default'] = _ember['default'].Route.extend(_quantuvisCmSpaMixinsQueryParamsUrlCleaner['default'], {
    signHandler: _ember['default'].inject.service(),

    beforeModel: function beforeModel(transition) {
      var parentPromises = this._super.apply(this, arguments);
      var handleSignPromise = this.get('signHandler').handleSign(transition.queryParams);

      return _ember['default'].RSVP.hash({ parentPromises: parentPromises, handleSignPromise: handleSignPromise });
    }
  });
});