define('quantuvis-cm-spa/components/cm-contract-details/cm-amendments', ['exports', 'ember', 'moment', 'quantuvis-cm-spa/constants/contract-status', 'quantuvis-cm-spa/constants/date-format', 'quantuvis-cm-spa/constants/attachment-type'], function (exports, _ember, _moment, _quantuvisCmSpaConstantsContractStatus, _quantuvisCmSpaConstantsDateFormat, _quantuvisCmSpaConstantsAttachmentType) {
  exports['default'] = _ember['default'].Component.extend({
    ATTACHMENT_TYPE: _quantuvisCmSpaConstantsAttachmentType['default'],
    DATE_FORMAT: _quantuvisCmSpaConstantsDateFormat['default'],
    classNames: ['cm-amendments'],
    amendments: [],
    contractEndDate: null,
    router: _ember['default'].inject.service('-routing'),
    contractDetailsSidebarNavigator: _ember['default'].inject.service(),
    emptyListMessage: 'There are no amendments for this contract',

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      this.set('contractEndDate', this.get('model.originEndDate') || this.get('model.endDate'));
      this.set('amendments', this.get('model.contracts').filter(function (amendment) {
        return amendment.get('status.id') !== _quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.DELETED;
      }));
      this.get('amendments').forEach(function (amendment) {
        _ember['default'].set(amendment, 'statusColor', _this.defineStatusColor(amendment));
        _ember['default'].set(amendment, 'shiftedContractEndDate', (0, _moment['default'])(amendment.get('endDate')).isAfter(_this.get('contractEndDate')));
        _ember['default'].set(amendment, 'description', amendment.get('description') || 'None');
      });
    },

    actions: {
      toAmendment: function toAmendment(id) {
        var _this2 = this;

        this.get('router.router').transitionTo('contract-details', id).then(function () {
          return _this2.get('contractDetailsSidebarNavigator').setDefaultActiveTab();
        });
      }
    },

    defineStatusColor: function defineStatusColor(amendment) {
      var color = undefined;

      switch (amendment.get('status.id')) {
        case _quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.IN_REVIEW:
          color = 'yellow';
          break;
        case _quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.TERMINATED:
        case _quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.EXECUTED:
          color = 'green';
          break;
        case _quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.DEACTIVATED:
          color = 'gray';
          break;
        default:
          color = 'purple';
      }

      return color;
    }
  });
});