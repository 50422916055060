define('quantuvis-cm-spa/services/switch-accounts', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Service.extend({
    search: _ember['default'].inject.service(),
    profile: _ember['default'].inject.service(),
    showMyAccounts: false,
    viewAccounts: _ember['default'].computed.alias('profile.myCompanies'),

    updateViewAccounts: function updateViewAccounts(showMyAccounts) {
      this.set('showMyAccounts', showMyAccounts);

      this.updateSearchAttributes();
    },

    updateSearchAttributes: function updateSearchAttributes() {
      var _this = this;

      var accounts = this.get('showMyAccounts') ? this.get('viewAccounts') : [];

      this.get('search').updateByMyAccounts(accounts).then(function () {
        return _this.get('search').fireUpdated();
      });
    }
  });
});