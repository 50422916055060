define('quantuvis-cm-spa/core-ui/components/cm-browser-checker/component', ['exports', 'ember', 'quantuvis-cm-spa/core-ui/components/cm-browser-checker/supportedBrowserVersion'], function (exports, _ember, _quantuvisCmSpaCoreUiComponentsCmBrowserCheckerSupportedBrowserVersion) {
  exports['default'] = _ember['default'].Component.extend({
    vailableVersion: _quantuvisCmSpaCoreUiComponentsCmBrowserCheckerSupportedBrowserVersion['default'],
    userAgent: _ember['default'].inject.service(),
    tagName: '',
    currentBrowser: '',
    browserMassage: false,

    init: function init() {
      this._super.apply(this, arguments);

      var browserInfo = this.get('userAgent.browser.info');

      this.set('currentBrowser', browserInfo.name + ' ' + browserInfo.major);
      this.checkOldBrowser(browserInfo);
    },

    checkOldBrowser: function checkOldBrowser(browser) {
      var _this = this;

      this.vailableVersion.forEach(function (minimumSupported) {
        if (minimumSupported.name === browser.name) {
          if (parseInt(browser.major) < minimumSupported.version) {
            _this.set('browserMassage', true);
          }
        }
      });
    }
  });
});