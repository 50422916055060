define('quantuvis-cm-spa/components/cm-navbar/cm-accounts-switch', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['cm-account-switch'],
    classNameBindings: ['isDisabled:disabled'],
    switchAccounts: _ember['default'].inject.service(),
    onlyMyAccounts: _ember['default'].computed.alias('switchAccounts.showMyAccounts'),
    isDisabled: _ember['default'].computed.empty('profile.myAccounts'),

    disabledSwitcherTooltip: 'You must populate My Accounts section of Profile Settings to switch your Documents List,' + ' Task, Events and Notifications pages between My Accounts and All Accounts',

    actions: {
      switchAccounts: function switchAccounts(value) {
        this.set('onlyMyAccounts', value);

        this.get('switchAccounts').updateViewAccounts(value);
      }
    }
  });
});