define('quantuvis-cm-spa/services/contract-details-sidebar-navigator', ['exports', 'ember', 'quantuvis-cm-spa/services/contract-details-sidebar-navigator/menu-items'], function (exports, _ember, _quantuvisCmSpaServicesContractDetailsSidebarNavigatorMenuItems) {
  exports['default'] = _ember['default'].Service.extend({
    window: _ember['default'].inject.service(),

    menuItems: _quantuvisCmSpaServicesContractDetailsSidebarNavigatorMenuItems['default'],
    selectedMenuItem: {},
    activeTabHash: '',

    setActiveTab: function setActiveTab() {
      var _getProperties = this.getProperties('menuItems', 'activeTabHash');

      var menuItems = _getProperties.menuItems;
      var activeTabHash = _getProperties.activeTabHash;

      var currentUrlHash = this.get('window').getLocation().hash.substr(1);
      var findMenuItemIndexByHash = function findMenuItemIndexByHash(hash) {
        return menuItems.findIndex(function (_ref) {
          var urlHash = _ref.urlHash;
          return urlHash.toLowerCase() === hash.toLowerCase();
        });
      };

      var previouslySelectedItemIndex = undefined;

      if (currentUrlHash) {
        previouslySelectedItemIndex = findMenuItemIndexByHash(currentUrlHash);

        if (previouslySelectedItemIndex < 0) {
          return activeTabHash ? this.setUrlHash(activeTabHash) : this.setDefaultActiveTab();
        }

        this.set('activeTabHash', currentUrlHash);
        this.setSelectedItem(previouslySelectedItemIndex);
      } else {
        if (!activeTabHash) {
          return this.setDefaultActiveTab();
        }

        previouslySelectedItemIndex = findMenuItemIndexByHash(activeTabHash);

        this.setUrlHash(activeTabHash);

        return this.setSelectedItem(previouslySelectedItemIndex);
      }
    },

    setDefaultActiveTab: function setDefaultActiveTab() {
      var defaultUrlHash = this.get('menuItems.firstObject.urlHash');

      this.setUrlHash(defaultUrlHash);
    },

    setSelectedItem: function setSelectedItem(index) {
      var selectedItem = this.get('menuItems').objectAt(index);

      if (selectedItem) {
        this.set('selectedMenuItem', selectedItem);
      }
    },

    setUrlHash: function setUrlHash(hash) {
      var windowLocation = this.get('window').getLocation();

      windowLocation.replace('#' + hash);
    },

    resetActiveTab: function resetActiveTab() {
      if (this.get('activeTabHash')) {
        this.set('activeTabHash', '');
      }
    }
  });
});