define('quantuvis-cm-spa/components/cm-profile-settings/profile-settings-form', ['exports', 'ember', 'quantuvis-cm-spa/constants/email-preferences', 'quantuvis-cm-spa/models/dropdown-type-options'], function (exports, _ember, _quantuvisCmSpaConstantsEmailPreferences, _quantuvisCmSpaModelsDropdownTypeOptions) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['profile-settings-form'],
    profileSettings: _ember['default'].inject.service(),
    toastNotification: _ember['default'].inject.service(),
    changeset: {},

    tooltipMessages: {
      emailPreferenceComponentPath: 'cm-profile-settings/email-preferences-tooltip-content',
      myAccounts: 'Allows you to filter which accounts you \n                 receive emails on and which accounts are visible on \n                 the Documents list, Tasks, Events and Notifications pages'
    },

    init: function init() {
      this._super.apply(this, arguments);
      this.initChangeset();
      this.initTimezoneOptions();
      this.initEmailPreference();
      this.showViewOnlyNotification();
    },

    willDestroyElement: function willDestroyElement() {
      this.get('toastNotification').removeAll();
    },

    initChangeset: function initChangeset() {
      var changeset = this.get('profileSettings').createChangeset(this.get('profile'));

      this.set('changeset', changeset);
    },

    initTimezoneOptions: function initTimezoneOptions() {
      var timezoneOptions = this.get('timezones');

      this.set('timezoneOptions', timezoneOptions);
    },

    initEmailPreference: function initEmailPreference() {
      var emailPreferencesOptions = new _quantuvisCmSpaModelsDropdownTypeOptions.SelectDropdownOptions(_quantuvisCmSpaConstantsEmailPreferences.EMAIL_PREFERENCES_ARRAY).options;

      this.set('emailPreferencesOptions', emailPreferencesOptions);
    },

    showViewOnlyNotification: function showViewOnlyNotification() {
      this.get('toastNotification').add({
        type: 'warning',
        autoClear: false,
        message: 'T‌o change your profile setting use the corresponding page in the\n        Rebate Negotiation Module or contact your Administrator.',
        className: 'profile-settings-form-view-only-toast'
      });
    },

    actions: {
      handleSaveProfile: function handleSaveProfile() {
        // TODO: Uncomment when System Administration will be implemented
        // this.$('#profile-settings-form').submit();
        this.sendAction('saveProfile');
      },
      handleCancel: function handleCancel() {
        this.sendAction('cancel');
      },
      submit: function submit(changeset) {
        var _this = this;

        if (changeset.get('isDirty')) {
          this.get('profileSettings').showConfirmation().then(function () {
            _this.saveSettings(changeset);
          });
        } else {
          this.saveSettings(changeset);
        }
      }
    },

    saveSettings: function saveSettings(changeset) {
      var _this2 = this;

      changeset.save().then(function () {
        _this2.sendAction('saveProfile');
      })['catch']();
    }
  });
});