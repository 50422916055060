define('quantuvis-cm-spa/components/cm-timeline/sign-off-reassigned-details', ['exports', 'ember', 'quantuvis-cm-spa/constants/event-property-name'], function (exports, _ember, _quantuvisCmSpaConstantsEventPropertyName) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['reassign-details', 'cm-event-details'],
    timeline: _ember['default'].inject.service(),

    reason: _ember['default'].computed('data', function () {
      var isReasonPresent = this.get('timeline').isPresentEventPropertyByName(this.get('contextObject.event.properties'), _quantuvisCmSpaConstantsEventPropertyName['default'].SIGN_OFF_REASSIGN_REASON);

      return isReasonPresent ? this.get('timeline').getEventPropertyValueByName(this.get('contextObject.event.properties'), _quantuvisCmSpaConstantsEventPropertyName['default'].SIGN_OFF_REASSIGN_REASON) : '';
    }),

    reassignedBy: _ember['default'].computed('data', function () {
      var assignerName = this.get('timeline').getEventPropertyValueByName(this.get('contextObject.event.properties'), _quantuvisCmSpaConstantsEventPropertyName['default'].SIGN_OFF_ASSIGNER_NAME);
      var assignerEmail = this.get('timeline').getEventPropertyValueByName(this.get('contextObject.event.properties'), _quantuvisCmSpaConstantsEventPropertyName['default'].SIGN_OFF_ASSIGNER_EMAIL);
      var isAssignerTitlePresent = this.get('timeline').isPresentEventPropertyByName(this.get('contextObject.event.properties'), _quantuvisCmSpaConstantsEventPropertyName['default'].SIGN_OFF_ASSIGNER_TITLE);

      var reassignedBy = assignerName + ' (' + assignerEmail + ')';

      if (isAssignerTitlePresent) {
        var assignerTitle = this.get('timeline').getEventPropertyValueByName(this.get('contextObject.event.properties'), _quantuvisCmSpaConstantsEventPropertyName['default'].SIGN_OFF_ASSIGNER_TITLE);

        reassignedBy = reassignedBy + ' - ' + assignerTitle;
      }

      return reassignedBy;
    }),

    reassignedTo: _ember['default'].computed('data', function () {
      return this.get('timeline').getEventPropertyValueByName(this.get('contextObject.event.properties'), _quantuvisCmSpaConstantsEventPropertyName['default'].SIGN_OFF_ASSIGNEE);
    })
  });
});