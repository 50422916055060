define('quantuvis-cm-spa/components/cm-timeline/cm-timeline-item/cm-workflow-step-base', ['exports', 'ember', 'quantuvis-cm-spa/constants/workflow-step-state'], function (exports, _ember, _quantuvisCmSpaConstantsWorkflowStepState) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['cm-timeline-item'],
    timeline: _ember['default'].inject.service(),
    date: _ember['default'].inject.service(),

    classNameBindings: ['bindWorkflowStepClass', 'isOverdue:step-overdue'],

    computedState: _ember['default'].computed('data.state', function () {
      var state = this.get('data.state');

      switch (state.id) {
        case _quantuvisCmSpaConstantsWorkflowStepState['default'].REJECTED:
          return {
            id: state.id,
            name: 'Not Completed'
          };
        default:
          return state;
      }
    }),

    computedDate: _ember['default'].computed('data.state', function () {
      var stateId = this.get('data.state.id');

      switch (stateId) {
        case _quantuvisCmSpaConstantsWorkflowStepState['default'].IN_PROGRESS:
        case _quantuvisCmSpaConstantsWorkflowStepState['default'].PENDING:
          return {
            datePrefix: 'due',
            date: this.get('data.dueDate')
          };
        case _quantuvisCmSpaConstantsWorkflowStepState['default'].COMPLETED:
        case _quantuvisCmSpaConstantsWorkflowStepState['default'].VOIDED:
        case _quantuvisCmSpaConstantsWorkflowStepState['default'].REJECTED:
          return {
            datePrefix: 'on',
            date: this.get('data.completedDate')
          };
        default:
          return {
            datePrefix: '',
            date: ''
          };
      }
    }),

    computedUsers: _ember['default'].computed('data.state', function () {
      var stateId = this.get('data.state.id');

      switch (stateId) {
        case _quantuvisCmSpaConstantsWorkflowStepState['default'].IN_PROGRESS:
        case _quantuvisCmSpaConstantsWorkflowStepState['default'].PENDING:
          return this.getResponsiblePersons();
        case _quantuvisCmSpaConstantsWorkflowStepState['default'].COMPLETED:
        case _quantuvisCmSpaConstantsWorkflowStepState['default'].VOIDED:
        case _quantuvisCmSpaConstantsWorkflowStepState['default'].REJECTED:
          return this.getExecutor();
        default:
          return '';
      }
    }),

    getResponsiblePersons: function getResponsiblePersons() {
      var responsiblePersons = this.get('data.responsiblePersonsAccounts') || [];

      return this.get('timeline').createResponsiblePersonsString(responsiblePersons);
    },

    getExecutor: function getExecutor() {
      var executor = this.get('data.executor');

      return this.get('timeline').createPersonInfo(executor);
    },

    bindWorkflowStepClass: _ember['default'].computed('data.state', function () {
      var stateId = this.get('data.state.id');

      switch (stateId) {
        case _quantuvisCmSpaConstantsWorkflowStepState['default'].IN_PROGRESS:
          return 'step-in-progress';
        case _quantuvisCmSpaConstantsWorkflowStepState['default'].PENDING:
          return 'step-pending';
        case _quantuvisCmSpaConstantsWorkflowStepState['default'].COMPLETED:
          return 'step-completed';
        case _quantuvisCmSpaConstantsWorkflowStepState['default'].VOIDED:
          return 'step-voided';
        case _quantuvisCmSpaConstantsWorkflowStepState['default'].REJECTED:
          return 'step-rejected';
        default:
          return '';
      }
    }),

    // TODO add tests for computed
    isOverdue: _ember['default'].computed('data.state', 'data.date', function () {
      var stateId = this.get('data.state.id');

      if (stateId === _quantuvisCmSpaConstantsWorkflowStepState['default'].IN_PROGRESS) {
        var dueDate = this.get('data.dueDate');

        return this.get('date').isOverdue(dueDate);
      }
    })
  });
});