define('quantuvis-cm-spa/validations/workflow-department', ['exports', 'ember-changeset-validations/validators', 'quantuvis-cm-spa/validations/custom-validators', 'quantuvis-cm-spa/constants/workflow-step-validations'], function (exports, _emberChangesetValidationsValidators, _quantuvisCmSpaValidationsCustomValidators, _quantuvisCmSpaConstantsWorkflowStepValidations) {

  var DepartmentSubstepValidator = {
    department: [(0, _emberChangesetValidationsValidators.validatePresence)(true)],
    leads: [(0, _emberChangesetValidationsValidators.validatePresence)(true)],
    notes: [(0, _emberChangesetValidationsValidators.validateLength)({ allowBlank: true, max: _quantuvisCmSpaConstantsWorkflowStepValidations.MAX_LENGTH.notes })],
    dueDate: [(0, _quantuvisCmSpaValidationsCustomValidators.validateDateFormat)()]
  };

  var ContractLeadSubstepValidator = {
    notes: [(0, _emberChangesetValidationsValidators.validateLength)({ allowBlank: true, max: _quantuvisCmSpaConstantsWorkflowStepValidations.MAX_LENGTH.notes })]
  };

  exports.DepartmentSubstepValidator = DepartmentSubstepValidator;
  exports.ContractLeadSubstepValidator = ContractLeadSubstepValidator;
});