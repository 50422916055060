define('quantuvis-cm-spa/components/business-admin/cm-sidebar-menu/cm-menu-item', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    businessAdmin: _ember['default'].inject.service(),
    tagName: '',
    actions: {
      selectMenu: function selectMenu(route) {
        this.get('businessAdmin').set('selectedMenuRoute', route);
      }
    }
  });
});