define('quantuvis-cm-spa/validations/custom-validators/phone-number', ['exports'], function (exports) {
  exports['default'] = phoneNumber;
  var ERROR_MESSAGE = 'Invalid phone number format';
  var PHONE_NUMBER_FORMAT = /[0-9]-[0-9]{3}-[0-9]{3}-[0-9]{4}$/i;

  function phoneNumber() {

    return function (key, newValue) {
      return newValue && !PHONE_NUMBER_FORMAT.test(newValue) ? ERROR_MESSAGE : true;
    };
  }
});