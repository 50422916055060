define("quantuvis-cm-spa/svgs", ["exports"], function (exports) {
  exports["default"] = {
    "assets": {
      "icons": {
        "CM": "<svg viewBox=\"0 0 32 32\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"M8.625 4.25a2.257 2.257 0 0 0-2.25 2.25v18a2.257 2.257 0 0 0 2.25 2.25h4.5v-2.137l11.25-11.25V11l-6.75-6.75h-9M16.5 5.938l6.188 6.187H16.5V5.937m7.988 10.688c-.113 0-.338.113-.45.225l-1.125 1.125 2.362 2.362 1.125-1.125c.225-.224.225-.674 0-.9l-1.462-1.462c-.113-.113-.226-.225-.45-.225m-2.25 2.025l-6.863 6.863v2.362h2.363l6.862-6.863-2.363-2.362z\"/></svg>",
        "IA": "<svg width=\"25\" height=\"25\" viewBox=\"0 0 10 20\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"M10 0H2C.9 0 .01.9.01 2L0 18c0 1.1.89 2 1.99 2H14c1.1 0 2-.9 2-2V6l-6-6zm1 10H7v1h3c.55 0 1 .45 1 1v3c0 .55-.45 1-1 1H9v1H7v-1H5v-2h4v-1H6c-.55 0-1-.45-1-1V9c0-.55.45-1 1-1h1V7h2v1h2v2zM9 6V1.5L13.5 6H9z\" fill=\"#323232\"/></svg>",
        "IQ": "<svg viewBox=\"0 0 32 32\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"M13.563 3.5c.237 0 .45.138.55.325l2.774 4.95.113.35-.113.35-2.774 4.95a.631.631 0 0 1-.55.325H7.936a.631.631 0 0 1-.55-.325l-2.774-4.95-.113-.35.112-.35 2.776-4.95a.631.631 0 0 1 .55-.325h5.625m0 13.75c.237 0 .45.137.55.325l2.774 4.95.113.35-.113.35-2.774 4.95a.631.631 0 0 1-.55.325H7.936a.631.631 0 0 1-.55-.325l-2.774-4.95-.113-.35.112-.35 2.776-4.95a.631.631 0 0 1 .55-.325h5.625m11.562-6.875c.238 0 .45.137.55.325l2.775 4.95.113.35-.113.35-2.775 4.95a.631.631 0 0 1-.55.325H19.5a.631.631 0 0 1-.55-.325l-2.775-4.95-.113-.35.113-.35 2.775-4.95a.631.631 0 0 1 .55-.325h5.625z\"/></svg>",
        "RN": "<svg viewBox=\"0 0 32 32\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"M18.333 4.333H9a2.333 2.333 0 0 0-2.333 2.334v18.666A2.333 2.333 0 0 0 9 27.667h14a2.333 2.333 0 0 0 2.333-2.334v-14m-7 11.667v-2.333h-4.666V23l-3.5-3.5 3.5-3.5v2.333h4.666V16l3.5 3.5m-4.666-7V6.083l6.416 6.417h-6.416z\"/></svg>",
        "SA": "<svg viewBox=\"0 0 32 32\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"M23.43 16.98c.04-.32.07-.64.07-.98 0-.34-.03-.66-.07-.98l2.11-1.65c.19-.15.24-.42.12-.64l-2-3.46c-.12-.22-.39-.3-.61-.22l-2.49 1c-.52-.4-1.08-.73-1.69-.98l-.38-2.65A.488.488 0 0 0 18 6h-4c-.25 0-.46.18-.49.42l-.38 2.65c-.61.25-1.17.59-1.69.98l-2.49-1c-.23-.09-.49 0-.61.22l-2 3.46c-.13.22-.07.49.12.64l2.11 1.65c-.04.32-.07.65-.07.98 0 .33.03.66.07.98l-2.11 1.65c-.19.15-.24.42-.12.64l2 3.46c.12.22.39.3.61.22l2.49-1c.52.4 1.08.73 1.69.98l.38 2.65c.03.24.24.42.49.42h4c.25 0 .46-.18.49-.42l.38-2.65c.61-.25 1.17-.59 1.69-.98l2.49 1c.23.09.49 0 .61-.22l2-3.46c.12-.22.07-.49-.12-.64l-2.11-1.65zM16 19.5c-1.93 0-3.5-1.57-3.5-3.5s1.57-3.5 3.5-3.5 3.5 1.57 3.5 3.5-1.57 3.5-3.5 3.5z\"/></svg>",
        "SM": "<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 32 32\"><path d=\"M16 7.093l8 4.494v8.826l-8 4.494-7.92-4.454L16 16V7.093M28 22c0 .507-.28.947-.707 1.173l-10.533 5.92c-.213.16-.48.24-.76.24s-.547-.08-.76-.24l-10.533-5.92A1.32 1.32 0 0 1 4 22V10c0-.507.28-.947.707-1.173l10.533-5.92c.213-.16.48-.24.76-.24s.547.08.76.24l10.533 5.92c.427.226.707.666.707 1.173v12M16 5.533l-9.333 5.254v10.426L16 26.467l9.333-5.254V10.787L16 5.533z\"/></svg>",
        "add-box": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"18\" height=\"18\"><path fill-rule=\"evenodd\" d=\"M16 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14c1.1 0 2-.9 2-2V2c0-1.1-.9-2-2-2zm-2 10h-4v4H8v-4H4V8h4V4h2v4h4v2z\"/></svg>",
        "back-icon": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"8\" height=\"13\"><path fill=\"#4D287D\" fill-rule=\"evenodd\" d=\"M7.641 1.634L2.409 6.497l5.3 4.931c.174.167.28.393.28.643 0 .514-.447.929-.999.929-.269 0-.513-.099-.693-.26L.305 7.169A.904.904 0 0 1 0 6.5v-.003c0-.263.118-.502.305-.669L6.297.257H6.3C6.479.099 6.723 0 6.99 0c.552 0 .999.415.999.929a.908.908 0 0 1-.348.705\"/></svg>",
        "check-mark": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"36\" height=\"52\"><path fill=\"#4D287D\" fill-rule=\"evenodd\" d=\"M22.169 6.048h-.562V3.687a3.687 3.687 0 0 0-7.375 0v2.361h-.579a5.694 5.694 0 0 0-5.695 5.694v1.465h19.905v-1.465a5.694 5.694 0 0 0-5.694-5.694zM17.92 4.902a1.875 1.875 0 1 1 0-3.75 1.875 1.875 0 0 1 0 3.75zM31 6.048h-3.137c1.296.742 2.179 2.122 2.179 3.722v1.389a4.305 4.305 0 0 1-4.306 4.305H10.347a4.304 4.304 0 0 1-4.305-4.305V9.612c0-1.525.832-2.844 2.059-3.564H5a5 5 0 0 0-5 5V47a5 5 0 0 0 5 5h26a5 5 0 0 0 5-5V11.048a5 5 0 0 0-5-5zM10.182 42.431h-.001l-1.917 3a1.54 1.54 0 0 1-1.137.706l-.169.01a1.56 1.56 0 0 1-1.095-.454l-1.5-1.5a1.54 1.54 0 0 1-.455-1.096A1.551 1.551 0 0 1 6.554 42l.137.137.879-1.375a1.552 1.552 0 0 1 2.141-.471c.348.222.589.568.678.973.089.404.016.818-.207 1.167zm0-10.208h-.001l-1.917 3a1.54 1.54 0 0 1-1.137.706l-.169.009c-.408 0-.807-.165-1.095-.454l-1.5-1.5a1.54 1.54 0 0 1-.455-1.096 1.55 1.55 0 0 1 2.646-1.096l.137.136.879-1.374a1.556 1.556 0 0 1 2.138-.473c.351.224.592.569.681.974a1.54 1.54 0 0 1-.207 1.168zm0-10.209h-.001l-1.917 3a1.54 1.54 0 0 1-1.137.706l-.169.01c-.408 0-.807-.165-1.095-.454l-1.5-1.5a1.54 1.54 0 0 1-.455-1.096 1.551 1.551 0 0 1 2.646-1.097l.137.137.879-1.375a1.553 1.553 0 0 1 2.138-.472c.351.223.592.569.681.974.089.404.016.818-.207 1.167zm19.901 23.258H14.75a1.551 1.551 0 0 1 0-3.101h15.333a1.551 1.551 0 0 1 0 3.101zm0-10.208H14.75a1.551 1.551 0 0 1 0-3.101h15.333a1.551 1.551 0 0 1 0 3.101zm0-10.209H14.75a1.551 1.551 0 0 1 0-3.101h15.333a1.551 1.551 0 0 1 0 3.101z\"/></svg>",
        "check": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"19\" height=\"19\"><path fill-rule=\"evenodd\" d=\"M11.069 18.867C5.894 19.735.995 16.245.128 11.071-.743 5.897 2.749.997 7.924.129c5.173-.869 10.071 2.622 10.941 7.797.868 5.174-2.624 10.073-7.796 10.941zM8.217 1.886c-4.197.704-7.039 4.692-6.334 8.889.704 4.198 4.693 7.04 8.889 6.335 4.198-.704 7.04-4.692 6.335-8.89-.703-4.197-4.691-7.038-8.89-6.334zm1.568 10.911a.886.886 0 0 1-.578.361.882.882 0 0 1-.664-.152l-3.42-2.436a.881.881 0 0 1-.36-.615.878.878 0 0 1 .151-.628.894.894 0 0 1 .544-.349.877.877 0 0 1 .699.142l2.693 1.918 3.381-4.745a.887.887 0 0 1 .615-.361.882.882 0 0 1 .629.153.883.883 0 0 1 .348.544.876.876 0 0 1-.139.698l-3.899 5.47z\"/></svg>",
        "checked-round-checkbox": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"16\" height=\"16\"><path fill=\"#31787F\" fill-rule=\"evenodd\" d=\"M8.001 16a8 8 0 1 1 0-16.001A8 8 0 0 1 8 16zM11 5.25a.75.75 0 0 0-.531.219L7.001 8.94l-1.72-1.721A.749.749 0 1 0 4.22 8.28l2.249 2.25a.751.751 0 0 0 1.061 0l4.001-4A.75.75 0 0 0 11 5.25z\"/></svg>",
        "checked": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"20\" height=\"20\"><path fill=\"#6436a1\" fill-rule=\"evenodd\" d=\"M11.069 18.867C5.894 19.735.995 16.245.128 11.071-.743 5.897 2.749.997 7.924.129c5.173-.869 10.071 2.622 10.941 7.797.868 5.174-2.624 10.073-7.796 10.941zM8.217 1.886c-4.197.704-7.039 4.692-6.334 8.889.704 4.198 4.693 7.04 8.889 6.335 4.198-.704 7.04-4.692 6.335-8.89-.703-4.197-4.691-7.038-8.89-6.334zm1.568 10.911a.886.886 0 0 1-.578.361.882.882 0 0 1-.664-.152l-3.42-2.436a.881.881 0 0 1-.36-.615.878.878 0 0 1 .151-.628.894.894 0 0 1 .544-.349.877.877 0 0 1 .699.142l2.693 1.918 3.381-4.745a.887.887 0 0 1 .615-.361.882.882 0 0 1 .629.153.883.883 0 0 1 .348.544.876.876 0 0 1-.139.698l-3.899 5.47z\"/></svg>",
        "clear-all": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"16\" height=\"16\"><path fill-rule=\"evenodd\" d=\"M8.927 12.415H7.806L10.3 9.921v1.121c0 .758-.615 1.373-1.373 1.373zm2.886.16c0 .744-.609 1.353-1.353 1.353H6.294L5.211 15.01h5.249a2.436 2.436 0 0 0 2.435-2.435V7.327l-1.082 1.082v4.166zm2.1-2.101c0 .328-.123.625-.318.86v1.336a2.428 2.428 0 0 0 1.4-2.196V5.226l-1.082 1.082v4.166zM16 .762L.763 15.999l-.767-.768 1.37-1.37a2.414 2.414 0 0 1-.375-1.286V5.541a2.436 2.436 0 0 1 2.435-2.435h7.034c.473 0 .912.141 1.286.375l1.299-1.299a1.346 1.346 0 0 0-.485-.094H5.527c-.328 0-.625.123-.86.318H3.331a2.428 2.428 0 0 1 2.196-1.4h7.033c.474 0 .913.14 1.287.375l1.385-1.386.768.767zM2.168 13.06l1.516-1.516a1.356 1.356 0 0 1-.098-.502V7.074c0-.758.615-1.373 1.373-1.373h3.968c.177 0 .345.036.501.098l1.516-1.516a1.325 1.325 0 0 0-.484-.095H3.426c-.744 0-1.352.61-1.352 1.353v7.034c0 .171.035.333.094.485z\"/></svg>",
        "create-new": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"19\" height=\"19\"><path fill-rule=\"evenodd\" d=\"M2.78 16.216a9.5 9.5 0 0 1 0-13.435c3.71-3.71 9.726-3.71 13.435-.001 3.71 3.711 3.71 9.726.001 13.436-3.71 3.71-9.726 3.709-13.436 0zM14.955 4.04c-3.009-3.009-7.906-3.009-10.916 0-3.01 3.01-3.009 7.907 0 10.916 3.01 3.008 7.907 3.009 10.917 0 3.009-3.01 3.008-7.907-.001-10.916zm-4.857 9.886a.886.886 0 0 1-.6.24.88.88 0 0 1-.6-.24.877.877 0 0 1-.292-.65v-2.888H5.72a.884.884 0 0 1-.652-.291.883.883 0 0 1-.238-.599.881.881 0 0 1 .239-.6.884.884 0 0 1 .651-.29h2.886V5.721c0-.259.115-.489.292-.65a.865.865 0 0 1 .6-.24.88.88 0 0 1 .6.239c.177.163.29.391.29.65l-.001 2.888h2.889c.259 0 .487.114.649.29a.88.88 0 0 1 .24.6.873.873 0 0 1-.24.599.88.88 0 0 1-.65.291h-2.887v2.888a.88.88 0 0 1-.29.65z\"/></svg>",
        "delete-click": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"11\" height=\"11\"><path fill-rule=\"evenodd\" d=\"M10.628 9.438c-.01.304-.128.603-.356.835a1.261 1.261 0 0 1-.84.359c-.333.012-.675-.1-.932-.359l-2.87-2.87-2.87 2.87a1.244 1.244 0 0 1-.939.359 1.254 1.254 0 0 1-.832-.359 1.232 1.232 0 0 1-.358-.835 1.236 1.236 0 0 1 .358-.935l2.869-2.871L.989 2.763a1.242 1.242 0 0 1-.358-.937c.012-.303.125-.602.358-.834.233-.232.532-.346.832-.359.342-.014.68.101.939.359l2.87 2.869L8.5.992c.257-.258.599-.373.932-.359.306.013.607.127.84.359.228.232.346.531.356.834.013.337-.1.678-.356.937L7.401 5.632l2.871 2.871c.256.258.369.598.356.935z\"/></svg>",
        "delete": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"19\" height=\"19\"><path fill-rule=\"evenodd\" d=\"M9.498 18.999c-5.247 0-9.501-4.254-9.501-9.5s4.254-9.5 9.501-9.5c5.246 0 9.498 4.254 9.498 9.5s-4.252 9.5-9.498 9.5zm0-17.219c-4.256 0-7.72 3.462-7.72 7.719 0 4.256 3.464 7.719 7.72 7.719 4.255 0 7.717-3.463 7.717-7.719 0-4.257-3.462-7.719-7.717-7.719zm3.554 10.426a.88.88 0 0 1-.254.594.89.89 0 0 1-.595.255.88.88 0 0 1-.664-.255l-2.041-2.042L7.456 12.8a.885.885 0 0 1-.667.255.891.891 0 0 1-.592-.255.867.867 0 0 1-.255-.594.88.88 0 0 1 .255-.665l2.041-2.042-2.041-2.041a.884.884 0 0 1-.255-.666.869.869 0 0 1 .847-.848.884.884 0 0 1 .667.254L9.498 8.24l2.041-2.042a.879.879 0 0 1 .664-.254.88.88 0 0 1 .595.254.881.881 0 0 1 .254.594.887.887 0 0 1-.254.666l-2.042 2.041 2.042 2.042a.883.883 0 0 1 .254.665z\"/></svg>",
        "document": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"63\" height=\"81\"><path fill=\"#6436A1\" fill-rule=\"evenodd\" d=\"M14.074 77.206H55.44c0 2.1-1.692 3.795-3.78 3.795H7.56c-4.174 0-7.56-3.403-7.56-7.59V11.433a3.784 3.784 0 0 1 3.78-3.794v61.978c0 4.187 3.386 7.589 7.56 7.589h2.734zM39.06 29.154H63v40.463c0 2.1-1.692 3.794-3.78 3.794H11.34a3.784 3.784 0 0 1-3.78-3.794V3.844A3.784 3.784 0 0 1 11.34.05H31.5v21.502c0 4.2 3.386 7.602 7.56 7.602zm20.16 3.795H39.06c-6.253 0-11.34-5.113-11.34-11.397V3.844H11.34v65.773h47.88V32.949zM39.06 25.36H63L35.28-.001v21.553c0 2.1 1.692 3.808 3.78 3.808zM16.958 40.854h36.645v-3.057H16.958v3.057zm36.645 4.362H16.958v3.056h36.645v-3.056zM16.958 55.691h36.645v-3.057H16.958v3.057zm0 7.418h36.645v-3.057H16.958v3.057z\"/></svg>",
        "dollar": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"29\" height=\"44\"><path fill=\"#4D287D\" fill-rule=\"evenodd\" d=\"M16.093 18.762V8.551c2.544.345 4.993 1.542 6.287 3.788a3.003 3.003 0 0 0 4.105 1.103 3.008 3.008 0 0 0 1.102-4.108c-2.47-4.289-6.983-6.423-11.494-6.829V1.499a1.503 1.503 0 1 0-3.006 0v.997a16.764 16.764 0 0 0-2.911.475C4.47 4.459.883 8.562.815 13.662c-.132 6.869 6.536 8.972 12.272 10.521v10.364c-3.623-.495-6.655-2.169-6.914-4.116a3.006 3.006 0 1 0-5.958.795c.731 5.492 6.619 8.76 12.872 9.376v1.883a1.503 1.503 0 1 0 3.006 0V40.69c.191-.008.383-.007.573-.019 7.487-.498 12.325-4.829 12.325-11.033 0-7.177-6.993-9.289-12.898-10.876zm-9.268-5.003c.045-3.348 3.418-4.588 4.866-4.966a10.87 10.87 0 0 1 1.396-.263v9.404c-3.879-1.128-6.299-2.214-6.262-4.175zm9.443 20.908c-.058.004-.117.004-.175.007v-9.667c4.227 1.214 6.887 2.383 6.887 4.631 0 4.137-4.694 4.895-6.712 5.029z\"/></svg>",
        "edit": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"19\" height=\"19\"><path fill-rule=\"evenodd\" d=\"M15.824 4.325L11.674.174A.598.598 0 0 0 11.249 0a.597.597 0 0 0-.424.174L-.001 11v5H5L15.824 5.174a.59.59 0 0 0 .175-.424.592.592 0 0 0-.175-.425zM2.279 10.719l6.94-6.939 3.001 3-6.941 6.939-3-3zm-.78 1.341l2.439 2.44H1.499v-2.44zM13.28 5.72l-3-3.001.97-.969 3 3-.97.97z\"/></svg>",
        "edit2": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"19\" height=\"19\" viewBox=\"0 0 1024 1024\"><title/><path fill=\"#8a8a8a\" d=\"M-.935 827.549v172.99c0 15.898 12.524 28.422 28.422 28.422h173.047c7.377 0 14.811-2.859 19.901-8.521l621.391-620.819-213.364-213.364L7.586 807.076c-5.259 5.1-8.523 12.231-8.523 20.125l.002.366v-.018zm1007.802-592.912c10.282-10.26 16.642-24.445 16.642-40.116s-6.361-29.857-16.641-40.116L873.679 21.216c-10.26-10.282-24.445-16.642-40.116-16.642s-29.857 6.361-40.116 16.641L689.309 125.353l213.364 213.364 104.194-104.08z\"/></svg>",
        "error": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"24\" height=\"24\"><path fill=\"#FFF\" fill-rule=\"evenodd\" d=\"M11.999 24C5.382 24 0 18.616 0 12 0 5.383 5.382 0 11.999 0 18.616 0 24 5.383 24 12c0 6.616-5.384 12-12.001 12zm1.335-17.335c0-.828-.505-1.332-1.335-1.332-.829 0-1.333.504-1.333 1.332V12c0 .828.504 1.332 1.333 1.332.83 0 1.335-.504 1.335-1.332V6.665zM11.999 15a1.5 1.5 0 1 0 .004 3 1.5 1.5 0 0 0-.004-3z\"/></svg>",
        "graph": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"48\" height=\"42\"><path fill=\"#4D287D\" fill-rule=\"evenodd\" d=\"M46 38H6c-1.103 0-2-.897-2-2V24.837l7.103-3.375a3.992 3.992 0 0 0 3.613 2.309 3.975 3.975 0 0 0 2.976-1.351l7.695 6.223c0 .015-.004.029-.004.044a4 4 0 0 0 8 0c0-1.138-.481-2.16-1.245-2.888l9.809-11.957c.275.06.559.095.852.095a4 4 0 1 0-4-4c0 .815.247 1.57.665 2.202L29.15 24.711a3.976 3.976 0 0 0-2.516 1.082l-7.96-6.438c-.21-2.011-1.891-3.584-3.958-3.584a3.999 3.999 0 0 0-3.657 2.389L4 21.515V2a2 2 0 1 0-4 0v34c0 3.309 2.691 6 6 6h40a2 2 0 0 0 0-4z\"/></svg>",
        "info": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"24\" height=\"24\"><path fill=\"#FFF\" fill-rule=\"evenodd\" d=\"M12.001 24C5.384 24 0 18.617 0 12 0 5.384 5.384 0 12.001 0 18.618 0 24 5.384 24 12c0 6.617-5.382 12-11.999 12zm-1.335-6.665c0 .828.505 1.332 1.335 1.332.829 0 1.333-.504 1.333-1.332V12c0-.828-.504-1.332-1.333-1.332-.83 0-1.335.504-1.335 1.332v5.335zM11.998 6a1.5 1.5 0 1 0 .003 3.001A1.5 1.5 0 0 0 11.998 6z\"/></svg>",
        "mail": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"20\" height=\"15\"><path fill=\"#4D287D\" fill-rule=\"evenodd\" d=\"M0 .006v14.987h20V.006H0zm6.947 7.469l-5.995 5.91V1.608l5.995 5.867zM1.636.95h16.728L10 9.137 1.636.95zm5.988 7.188L10 10.464l2.376-2.326 5.994 5.911H1.63l5.994-5.911zm5.429-.663l5.995-5.867v11.777l-5.995-5.91z\"/></svg>",
        "minus": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"19\" height=\"3\"><path fill=\"#848484\" fill-rule=\"evenodd\" d=\"M17.761 2.739H1.239a1.238 1.238 0 1 1 0-2.478h16.522a1.239 1.239 0 1 1 0 2.478\"/></svg>",
        "payer-icon": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"22\" height=\"19\"><path fill=\"#BCBCBC\" fill-rule=\"evenodd\" d=\"M13.75 10.857V9.5c0-.636-.708-1.357-1.375-1.357h-2.75c-.668 0-1.375.721-1.375 1.357v1.357H0V4.071c0-.636.708-1.357 1.374-1.357h5.501V1.357C6.875.721 7.582 0 8.25 0h5.5c.667 0 1.374.721 1.374 1.357v1.357h5.501c.667 0 1.375.721 1.375 1.357v6.786h-8.25zm0-9.5h-5.5v1.357h5.5V1.357zM9.625 13.571h2.75c.667 0 1.375-.721 1.375-1.357h6.875v5.429c0 .636-.707 1.357-1.375 1.357H2.749c-.666 0-1.375-.721-1.375-1.357v-5.429H8.25c0 .636.707 1.357 1.375 1.357z\"/></svg>",
        "pharma-icon": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"18\" height=\"19\"><path fill=\"#BCBCBC\" fill-rule=\"evenodd\" d=\"M16.319 1.966l-.226-.24c-2.185-2.307-5.728-2.307-7.915 0L1.621 8.641c-2.185 2.308-2.185 6.045 0 8.349l.227.239c2.186 2.304 5.728 2.304 7.914 0l6.557-6.918c2.186-2.304 2.186-6.042 0-8.345zM8.405 15.799c-1.435 1.515-3.764 1.515-5.201 0l-.226-.239c-1.436-1.515-1.436-3.971 0-5.485l3.28-3.461 5.426 5.724-3.279 3.461z\"/></svg>",
        "phone": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"20\" height=\"20\"><path fill=\"#4D287D\" fill-rule=\"evenodd\" d=\"M19.985 15.117c-.003-.096-.006-.192-.05-.287-.107-.285-.799-.566-1.083-.695-.808-.416-1.63-.817-2.425-1.246-.369-.209-.828-.607-1.26-.595-.851.026-2.016 2.545-2.769 2.567-.377.012-.875-.317-1.216-.499-2.58-1.31-4.391-2.986-5.876-5.441-.205-.323-.568-.793-.58-1.163-.022-.741 2.464-2.037 2.439-2.873-.013-.425-.445-.852-.679-1.202-.484-.754-.941-1.537-1.411-2.306-.148-.27-.475-.933-.771-1.02A.71.71 0 0 0 4.01.324c-.502.015-1.472.264-1.926.483C1.41 1.115.987 1.897.657 2.524c-.421.823-.661 1.654-.633 2.573.04 1.275.605 2.412 1.073 3.565.332.827.734 1.625 1.231 2.366 1.536 2.288 4.481 5.015 6.896 6.385.781.444 1.615.79 2.475 1.066 1.199.39 2.386.877 3.683.838.934-.028 1.763-.313 2.573-.776.617-.362 1.384-.824 1.656-1.505.196-.459.389-1.425.374-1.919z\"/></svg>",
        "plus": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"19\" height=\"19\"><path fill=\"#848484\" fill-rule=\"evenodd\" d=\"M17.761 10.739h-7.022v7.022c0 .686-.555 1.239-1.239 1.239-.685 0-1.24-.553-1.24-1.239v-7.022H1.239a1.238 1.238 0 1 1 0-2.478H8.26V1.239a1.239 1.239 0 1 1 2.479 0v7.022h7.022a1.239 1.239 0 1 1 0 2.478\"/></svg>",
        "quantuvis-logo-short": "<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 32 32\"><path d=\"M14.572 2c8.048 0 14.572 6.3 14.572 14.074a13.694 13.694 0 0 1-1.888 6.933l-3.353-2.716a9.578 9.578 0 0 0 .947-4.155c0-5.447-4.572-9.864-10.213-9.864-5.64 0-10.213 4.417-10.213 9.864C4.424 21.584 8.996 26 14.637 26c2.217 0 4.27-.682 5.944-1.84l3.342 2.708c-2.53 2.047-5.792 3.28-9.351 3.28C6.524 30.147 0 23.845 0 16.073 0 8.3 6.524 2 14.572 2zm2.552 13.693c1.29 0 2.335 1.022 2.335 2.282 0 .288-.054.562-.153.815l9.353 7.614c.155-.04.317-.06.485-.06 1.05 0 1.9.821 1.9 1.835s-.85 1.836-1.9 1.836c-1.05 0-1.9-.822-1.9-1.836 0-.304.076-.591.212-.844l-9.11-7.415a2.362 2.362 0 0 1-1.222.338c-1.29 0-2.335-1.022-2.335-2.283 0-1.26 1.046-2.282 2.335-2.282zm12.02 11.339c-.656 0-1.188.513-1.188 1.147s.532 1.147 1.188 1.147c.656 0 1.188-.513 1.188-1.147s-.532-1.147-1.188-1.147z\"/></svg>",
        "sand-clock": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"37\" height=\"46\"><path fill=\"#4D287D\" fill-rule=\"evenodd\" d=\"M18.5-.006C8.283-.006 0 2.251 0 5.036v35.92c0 2.785 8.074 5.043 18.292 5.043C28.509 45.999 37 43.741 37 40.956V5.036c0-2.785-8.283-5.042-18.5-5.042zm15.458 7.809v30.476a19.112 19.112 0 0 0-2.494-.858c-.297-7.146-4.857-12.929-9.743-14.864 4.669-1.85 9.044-7.211 9.683-13.919.951-.254 1.82-.531 2.554-.835zM18.5 10.079c4.325 0 8.28-.412 11.428-1.09-.866 6.01-5.383 12.347-10.97 12.823v-2.71c3.097-.261 4.718-1.685 5.709-3.605-1.875-.624-3.542 1.912-4.625 1.621-2.792-.499-3.25-5.736-8-2.536.44 2.639 2.616 4.02 6 4.525v2.734c-5.805-.187-10.543-6.755-11.398-12.938 3.208.733 7.339 1.176 11.856 1.176zM5.167 8.525c.6 6.761 5.001 12.172 9.696 14.032-4.886 1.935-9.446 7.718-9.743 14.864-.799.221-1.522.462-2.162.72V7.765a19.17 19.17 0 0 0 2.209.76zm13.125 34.279c-5.041 0-9.394-2.36-11.511-5.792l-.238.055c.511-6.415 5.425-13.597 11.499-13.793v12.522c-.204.021-.422.06-.667.129-3.902.267-5.063-2.743-5.687-2.743-.626 0-3.309 1.649-2.563 2.764 1.757 2.623 5.314 4.417 9.416 4.417 3.868 0 7.252-1.595 9.098-3.977-4.701-4.491-6.389 1.846-7.889.626.023-.455-.217-.935-.792-1.137V23.306c5.881.502 10.584 7.495 11.084 13.761l-.24-.055c-2.117 3.432-6.47 5.792-11.51 5.792z\"/></svg>",
        "select-all": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"14\" height=\"14\"><path fill-rule=\"evenodd\" d=\"M9.466 2.1H2.434A2.434 2.434 0 0 0 0 4.534v7.032A2.434 2.434 0 0 0 2.434 14h7.032a2.434 2.434 0 0 0 2.434-2.434V4.534A2.434 2.434 0 0 0 9.466 2.1zm1.352 9.466c0 .744-.608 1.352-1.352 1.352H2.434a1.355 1.355 0 0 1-1.352-1.352V4.534c0-.743.608-1.352 1.352-1.352h7.032c.744 0 1.352.609 1.352 1.352v7.032zM9.306 6.067v3.966c0 .758-.615 1.373-1.373 1.373H3.966a1.373 1.373 0 0 1-1.372-1.373V6.067c0-.758.615-1.373 1.372-1.373h3.967c.758 0 1.373.615 1.373 1.373zM14 2.434v7.032a2.43 2.43 0 0 1-1.4 2.196v-1.337c.195-.234.318-.531.318-.859V2.434c0-.744-.608-1.352-1.352-1.352H4.534a1.34 1.34 0 0 0-.859.318H2.339A2.428 2.428 0 0 1 4.534 0h7.032A2.434 2.434 0 0 1 14 2.434z\"/></svg>",
        "signing-complete-icon": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"73\" height=\"86\"><path fill=\"#6436A1\" fill-rule=\"evenodd\" d=\"M33.199 56.817H17.121v-3.118h17.788a23.095 23.095 0 0 0-1.71 3.118zM11.453 74.896h21.1a23.212 23.212 0 0 1-1.097-3.871H11.453V3.923H27.98v18.066c0 6.411 5.133 11.627 11.442 11.627h20.342v10.581c1.322.355 2.596.822 3.814 1.4V29.745H39.422c-4.212 0-7.628-3.472-7.628-7.756V.052H11.453c-2.107 0-3.814 1.729-3.814 3.871v67.102c0 2.142 1.707 3.871 3.814 3.871zm27.969-49.023h24.156L35.608.001v21.988c0 2.142 1.708 3.884 3.814 3.884zM17.121 64.385h14.025c.026-.273.073-.54.108-.811.046-.333.09-.667.15-.995.077-.442.167-.878.267-1.311l.001-.001H17.121v3.118zm36.974-25.823H17.121v3.119h36.974v-3.119zM14.212 78.768h-2.759c-4.212 0-7.628-3.472-7.628-7.743V7.795c-2.107 0-3.814 1.729-3.814 3.871v63.23c0 4.272 3.416 7.743 7.628 7.743h29.725a23.268 23.268 0 0 1-2.931-3.871H14.212zm24.562-29.519a22.96 22.96 0 0 1 3.799-2.773c.198-.115.391-.236.592-.345h.001-26.045v3.118h21.653zm34.214 17.382c0 10.673-8.554 19.356-19.07 19.356-10.515 0-19.07-8.683-19.07-19.356 0-10.674 8.555-19.357 19.07-19.357 10.516 0 19.07 8.683 19.07 19.357zm-2.542 0c0-9.25-7.415-16.776-16.528-16.776-9.112 0-16.527 7.526-16.527 16.776 0 9.249 7.415 16.775 16.527 16.775 9.113 0 16.528-7.526 16.528-16.775zm-9.093-7.822c-.486 0-.939.244-1.212.656l-7.606 11.229-5.021-5.098a1.44 1.44 0 0 0-1.03-.432 1.47 1.47 0 0 0-1.459 1.479c0 .395.151.767.427 1.046l6.262 6.355c.616.628 1.752.516 2.242-.223l8.589-12.681c.175-.254.266-.547.266-.852a1.47 1.47 0 0 0-1.458-1.479z\"/></svg>",
        "signing-declined-icon": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"73\" height=\"86\"><path fill=\"#6436A1\" fill-rule=\"evenodd\" d=\"M7.637 71.021V3.919c0-2.142 1.707-3.871 3.814-3.871h20.341v21.937c0 4.284 3.416 7.756 7.628 7.756h24.155v15.851a22.507 22.507 0 0 0-3.814-1.399V33.612H39.42c-6.309 0-11.442-5.216-11.442-11.627V3.919H11.451v67.102h20.002a23.308 23.308 0 0 0 1.097 3.871H11.451c-2.107 0-3.814-1.729-3.814-3.871zm9.482-6.64h14.024c.026-.274.074-.54.109-.811.046-.333.09-.667.15-.995.077-.442.166-.878.267-1.311l.001-.001H17.119v3.118zM39.42 25.869h24.155L35.606-.003v21.988c0 2.142 1.707 3.884 3.814 3.884zm-6.223 30.944c.247-.537.505-1.068.791-1.582.282-.52.584-1.027.909-1.519l.01-.017H17.119v3.118h16.078zm-18.988 21.95h-2.758c-4.212 0-7.628-3.471-7.628-7.742V7.791C1.716 7.791.009 9.52.009 11.662v63.23c0 4.272 3.416 7.743 7.628 7.743h29.725a23.234 23.234 0 0 1-2.931-3.872H14.209zm24.563-29.518a22.96 22.96 0 0 1 3.799-2.773c.198-.115.391-.236.592-.345H17.119v3.118h21.653zm15.321-10.687H17.119v3.119h36.974v-3.119zm18.893 28.069c0 10.673-8.554 19.356-19.07 19.356s-19.07-8.683-19.07-19.356c0-10.674 8.554-19.356 19.07-19.356s19.07 8.682 19.07 19.356zm-2.543 0c0-9.25-7.414-16.776-16.527-16.776-9.113 0-16.527 7.526-16.527 16.776 0 9.249 7.414 16.775 16.527 16.775 9.113 0 16.527-7.526 16.527-16.775zm-7.741-7.197a1.53 1.53 0 0 0-1.519-1.542 1.51 1.51 0 0 0-1.074.453l-6.016 6.106-6.015-6.106a1.51 1.51 0 0 0-1.075-.453 1.53 1.53 0 0 0-1.519 1.542c0 .427.17.812.445 1.09l6.016 6.107-6.016 6.106a1.544 1.544 0 0 0-.445 1.09c0 .853.68 1.542 1.519 1.542.42 0 .8-.175 1.075-.452l6.015-6.107 6.016 6.107c.275.277.655.452 1.074.452a1.53 1.53 0 0 0 1.519-1.542c0-.427-.17-.812-.444-1.09l-6.016-6.106 6.016-6.107c.274-.278.444-.663.444-1.09z\"/></svg>",
        "signing-default-icon": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"63\" height=\"81\"><path fill=\"#6436A1\" fill-rule=\"evenodd\" d=\"M14.074 77.206H55.44c0 2.1-1.692 3.795-3.78 3.795H7.56c-4.174 0-7.56-3.403-7.56-7.59V11.433a3.784 3.784 0 0 1 3.78-3.794v61.978c0 4.187 3.386 7.589 7.56 7.589h2.734zM39.06 29.154H63v40.463c0 2.1-1.692 3.794-3.78 3.794H11.34a3.784 3.784 0 0 1-3.78-3.794V3.844A3.784 3.784 0 0 1 11.34.05H31.5v21.502c0 4.2 3.386 7.602 7.56 7.602zm20.16 3.795H39.06c-6.253 0-11.34-5.113-11.34-11.397V3.844H11.34v65.773h47.88V32.949zM39.06 25.36H63L35.28-.001v21.553c0 2.1 1.692 3.808 3.78 3.808zM16.958 40.854h36.645v-3.057H16.958v3.057zm36.645 4.362H16.958v3.056h36.645v-3.056zM16.958 55.691h36.645v-3.057H16.958v3.057zm0 7.418h36.645v-3.057H16.958v3.057z\"/></svg>",
        "signing-finish-later-icon": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"63\" height=\"81\"><path fill=\"#6436A1\" fill-rule=\"evenodd\" d=\"M14.074 77.206H55.44c0 2.1-1.692 3.795-3.78 3.795H7.56c-4.174 0-7.56-3.403-7.56-7.59V11.433a3.784 3.784 0 0 1 3.78-3.794v61.978c0 4.187 3.386 7.589 7.56 7.589h2.734zM39.06 29.154H63v40.463c0 2.1-1.692 3.794-3.78 3.794H11.34a3.784 3.784 0 0 1-3.78-3.794V3.844A3.784 3.784 0 0 1 11.34.05H31.5v21.502c0 4.2 3.386 7.602 7.56 7.602zm20.16 3.795H39.06c-6.253 0-11.34-5.113-11.34-11.397V3.844H11.34v65.773h47.88V32.949zM39.06 25.36H63L35.28-.001v21.553c0 2.1 1.692 3.808 3.78 3.808zM16.958 40.854h36.645v-3.057H16.958v3.057zm36.645 4.362H16.958v3.056h36.645v-3.056zM16.958 55.691h36.645v-3.057H16.958v3.057zm0 7.418h36.645v-3.057H16.958v3.057z\"/></svg>",
        "target": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"53\" height=\"55\"><path fill=\"#4D287D\" fill-rule=\"evenodd\" d=\"M16.625 32.892c0 2.992 2.415 5.426 5.383 5.426 2.832 0 5.134-2.223 5.342-5.025l2.706-2.745c.214.745.335 1.53.335 2.344 0 4.66-3.761 8.451-8.383 8.451-4.623 0-8.384-3.791-8.384-8.451s3.761-8.45 8.384-8.45c.379 0 .75.034 1.116.084l-3.451 3.5a5.42 5.42 0 0 0-3.048 4.866zm11.956-13.903a15.055 15.055 0 0 0-6.573-1.521c-8.418 0-15.267 6.904-15.267 15.389 0 8.486 6.849 15.389 15.267 15.389s15.266-6.903 15.266-15.389c0-2.763-.736-5.352-2.007-7.597l-2.217 2.25a12.345 12.345 0 0 1 1.223 5.347c0 6.818-5.502 12.364-12.265 12.364-6.763 0-12.266-5.546-12.266-12.364 0-6.817 5.503-12.364 12.266-12.364 1.515 0 2.961.292 4.301.801l2.272-2.305zm9.44 3.478a19.138 19.138 0 0 1 2.993 10.279c0 10.564-8.526 19.159-19.006 19.159S3.001 43.31 3.001 32.746c0-10.565 8.527-19.16 19.007-19.16 3.422 0 6.627.93 9.403 2.532l2.187-2.219a21.737 21.737 0 0 0-11.59-3.338C9.873 10.561 0 20.513 0 32.746 0 44.978 9.873 54.93 22.008 54.93c12.134 0 22.007-9.952 22.007-22.184 0-4.625-1.413-8.922-3.824-12.481l-2.17 2.202zm8.922-15.415L46.545.947l-5.659 5.704.31 4.747L21.14 31.3l1.404 1.437 20.047-19.894 4.75.314L53 7.453l-6.057-.401z\"/></svg>",
        "unchecked-round-checkbox": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"17.5\" height=\"17.5\"><path fill=\"#FFF\" fill-rule=\"evenodd\" stroke=\"#C6C6C6\" d=\"M8.5 16.5A8.001 8.001 0 1 1 8.5.498a8.001 8.001 0 0 1 0 16.002z\"/></svg>",
        "unchecked": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"20\" height=\"20\"><path fill=\"#C1C1C1\" fill-rule=\"evenodd\" d=\"M11.069 18.867C5.894 19.735.994 16.245.126 11.07-.743 5.897 2.749.997 7.923.128a9.502 9.502 0 0 1 3.146 18.739zM8.219 1.886c-4.199.704-7.041 4.693-6.336 8.889.704 4.198 4.692 7.039 8.889 6.335 4.198-.704 7.041-4.692 6.334-8.89-.703-4.197-4.692-7.039-8.887-6.334z\"/></svg>",
        "upload-doc-icon": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\"><path fill=\"none\" d=\"M0 0h24v24H0V0z\"/><path d=\"M20.55 5.22l-1.39-1.68A1.51 1.51 0 0 0 18 3H6c-.47 0-.88.21-1.15.55L3.46 5.22C3.17 5.57 3 6.01 3 6.5V19a2 2 0 0 0 2 2h14c1.1 0 2-.9 2-2V6.5c0-.49-.17-.93-.45-1.28zm-8.2 4.63L17.5 15H14v2h-4v-2H6.5l5.15-5.15c.19-.19.51-.19.7 0zM5.12 5l.82-1h12l.93 1H5.12z\"/></svg>",
        "warning-bold": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"24\" height=\"21\"><path fill=\"#2E2E2E\" fill-rule=\"evenodd\" d=\"M24 19.95c0 .57-.497 1.032-1.115 1.038v.01H1.135v-.01l-.011.001c-.622 0-1.123-.466-1.123-1.039 0-.211.069-.404.183-.568l-.005-.003L11.053.468l.007.004c.2-.284.546-.472.941-.472.407 0 .762.204.958.506l.01-.003 10.872 18.91-.003.004a.978.978 0 0 1 .162.533zM13.5 8.515c0-.765-.673-1.386-1.499-1.386-.827 0-1.503.621-1.503 1.386v5.198c0 .765.676 1.385 1.503 1.385.826 0 1.499-.62 1.499-1.385V8.515zm-1.499 7.623c-.827 0-1.503.621-1.503 1.386 0 .766.676 1.386 1.503 1.386.826 0 1.499-.62 1.499-1.386 0-.765-.673-1.386-1.499-1.386z\"/></svg>",
        "warning": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"168\" height=\"154\"><path fill=\"#4D287D\" fill-rule=\"evenodd\" d=\"M64.469 13.246c10.003-17.665 29.196-17.686 39.218-.003l60.112 106.052c9.94 17.537.195 34.122-20.056 34.122H24.492c-20.271 0-30.02-16.548-20.071-34.119L64.469 13.246zm24.64 8.142c-3.62-6.387-7.253-5.146-10.057-.003L19.003 127.438c-2.852 5.559-1.988 9.37 5.489 9.37h119.251c5.477 0 9.146-2.894 5.477-9.367L89.109 21.388zm-5.035 107.115c-4.619 0-8.364-3.718-8.364-8.305 0-4.586 3.745-8.304 8.364-8.304 4.62 0 8.365 3.718 8.365 8.304 0 4.587-3.745 8.305-8.365 8.305zM75.71 53.785c0-4.6 3.713-8.329 8.364-8.329 4.62 0 8.365 3.725 8.365 8.329V95.26c0 4.6-3.713 8.329-8.365 8.329-4.619 0-8.364-3.725-8.364-8.329V53.785z\"/></svg>"
      },
      "images": {
        "profile": {
          "no-avatar": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"38\" height=\"38\"><path fill-rule=\"evenodd\" d=\"M18.999 38c-10.493 0-19-8.506-19-19 0-10.493 8.507-19 19-19C29.493 0 38 8.507 38 19c0 10.494-8.507 19-19.001 19zm0-34.437C10.473 3.563 3.562 10.474 3.562 19c0 3.987 1.524 7.609 4.006 10.349 2.236-1.082 1.415-.182 4.341-1.386 2.992-1.23 3.702-1.66 3.702-1.66l.028-2.836s-1.121-.852-1.47-3.52c-.701.201-.933-.818-.974-1.467-.037-.626-.406-2.583.45-2.407-.176-1.306-.301-2.484-.239-3.109.215-2.191 2.341-4.481 5.616-4.648 3.854.167 5.38 2.455 5.594 4.646.062.625-.076 1.804-.25 3.108.856-.174.483 1.78.443 2.407-.039.648-.276 1.664-.976 1.463-.349 2.669-1.471 3.513-1.471 3.513l.027 2.822s.708.402 3.702 1.633c2.925 1.204 2.104.357 4.34 1.441 2.481-2.739 4.007-6.362 4.007-10.349 0-8.526-6.913-15.437-15.439-15.437z\"/></svg>"
        }
      }
    }
  };
});