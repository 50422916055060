define('quantuvis-cm-spa/services/workflow', ['exports', 'ember', 'quantuvis-cm-spa/mixins/workflow-request-builder', 'quantuvis-cm-spa/constants/edit-workflow', 'quantuvis-cm-spa/constants/workflow-step-type', 'quantuvis-cm-spa/constants/workflow-step-state', 'quantuvis-cm-spa/models/workflows/workflow-substep-preset', 'quantuvis-cm-spa/constants/permissions', 'quantuvis-cm-spa/constants/user-query-type', 'quantuvis-cm-spa/constants/workflow-substep-type', 'quantuvis-cm-spa/constants/workflow-step-departments-state'], function (exports, _ember, _quantuvisCmSpaMixinsWorkflowRequestBuilder, _quantuvisCmSpaConstantsEditWorkflow, _quantuvisCmSpaConstantsWorkflowStepType, _quantuvisCmSpaConstantsWorkflowStepState, _quantuvisCmSpaModelsWorkflowsWorkflowSubstepPreset, _quantuvisCmSpaConstantsPermissions, _quantuvisCmSpaConstantsUserQueryType, _quantuvisCmSpaConstantsWorkflowSubstepType, _quantuvisCmSpaConstantsWorkflowStepDepartmentsState) {
  exports['default'] = _ember['default'].Service.extend(_quantuvisCmSpaMixinsWorkflowRequestBuilder['default'], {
    scrollDuration: 600,

    store: _ember['default'].inject.service(),

    update: function update(workflow, changeSetList) {
      var _this = this;

      return this.validateChangeSetList(changeSetList).then(function () {
        var normalizedSteps = _this.normalizeWorkflowStepStates(workflow.get('steps'));

        workflow.set('steps', _this.buildWorkflowStepsCollection(normalizedSteps));

        return workflow.save();
      });
    },

    normalizeWorkflowStepStates: function normalizeWorkflowStepStates(steps) {
      var _this2 = this;

      var inProgressStepDefined = false;

      steps.filter(function (step) {
        return !step.get('isInactive');
      }).forEach(function (step) {
        if (!inProgressStepDefined) {
          _this2.switchStepState(step, _quantuvisCmSpaConstantsWorkflowStepState['default'].IN_PROGRESS, _quantuvisCmSpaConstantsWorkflowStepDepartmentsState.WORKFLOW_STEP_DEPARTMENT_STATE.IN_PROGRESS, [_quantuvisCmSpaConstantsWorkflowStepDepartmentsState.WORKFLOW_STEP_DEPARTMENT_STATE.PENDING]);
          inProgressStepDefined = true;
        } else {
          _this2.switchStepState(step, _quantuvisCmSpaConstantsWorkflowStepState['default'].PENDING, _quantuvisCmSpaConstantsWorkflowStepDepartmentsState.WORKFLOW_STEP_DEPARTMENT_STATE.PENDING, [_quantuvisCmSpaConstantsWorkflowStepDepartmentsState.WORKFLOW_STEP_DEPARTMENT_STATE.IN_PROGRESS]);
        }
      });

      return steps;
    },

    switchStepState: function switchStepState(step, stepState, substepState, filter) {
      step.set('state.id', stepState);
      step.get('substeps').filter(function (substep) {
        return substep.get('type.id') === _quantuvisCmSpaConstantsWorkflowSubstepType.DEPARTMENT_REVIEW_ID;
      }).forEach(function (substep) {
        if (filter.includes(substep.get('state.id'))) {
          substep.set('state.id', substepState);
        }
      });
    },

    /**
     * Flush local stores
     */
    flush: function flush() {
      this.get('store').unloadAll('workflows/step');
      this.get('store').unloadAll('workflows/substep');
      this.get('store').unloadAll('workflow');
    },

    validateChangeSetList: function validateChangeSetList(changeSetList) {
      var _this3 = this;

      var promises = [];

      changeSetList.forEach(function (changeSet, name) {
        if (!_this3.isVirtualChangeset(name)) {
          promises.push(changeSet.validate());
        }
      });

      return Promise.all(promises).then(function () {
        var invalidList = Array.from(changeSetList.values()).filter(function (changeSet) {
          return changeSet.get('isInvalid');
        });

        if (invalidList.length !== 0) {
          invalidList.forEach(function (item) {
            item.get('expandOnInvalid') && item.get('expandOnInvalid')();
          });

          return Promise.reject(false);
        }
        _this3.saveWorkflowChangeSetList(changeSetList);
      });
    },

    saveWorkflowChangeSetList: function saveWorkflowChangeSetList(changeSetList) {
      var _this4 = this;

      changeSetList.forEach(function (changeSet, name) {
        if (!_this4.isVirtualChangeset(name)) {
          changeSet.execute();
        }
      });
    },

    fetchResponsibleUsers: function fetchResponsibleUsers(companyId) {
      return this.get('store').query('user-management/users/extended', {
        permissions: _quantuvisCmSpaConstantsPermissions.PERMISSION_GROUPS.REVIEWER,
        applicationId: _quantuvisCmSpaConstantsPermissions.PERMISSIONS.APPLICATION.CM,
        resourceId: _quantuvisCmSpaConstantsPermissions.PERMISSIONS.RESOURCE.COMPANY,
        resourceValue: companyId,
        type: _quantuvisCmSpaConstantsUserQueryType['default'].PERMISSION
      })['catch'](function (error) {
        _ember['default'].Logger.error('Failed to fetch responsible persons', error);
      });
    },

    /**
     * Returns promise of the workflow for requested contractId
     */
    findWorkflowByContractId: function findWorkflowByContractId(contractId) {
      return this.get('store').queryRecord('workflow', { contractId: contractId });
    },

    /**
     * Check for virtual changest
     * @param name
     * @returns {boolean}
     */
    isVirtualChangeset: function isVirtualChangeset(name) {
      return name === _quantuvisCmSpaConstantsEditWorkflow.VIRTUAL_WORKFLOW_CHANGESET;
    },

    createStep: function createStep() {
      return this.get('store').createRecord('workflows/step', {
        name: '',
        type: _quantuvisCmSpaConstantsWorkflowStepType.DEPARTMENT_REVIEW,
        state: {
          id: _quantuvisCmSpaConstantsWorkflowStepState['default'].PENDING
        },
        events: [],
        substeps: [this.createSubstep()],
        isCollapsed: false
      });
    },

    createSubstep: function createSubstep() {
      return this.get('store').createRecord('workflows/substep', new _quantuvisCmSpaModelsWorkflowsWorkflowSubstepPreset['default']());
    },

    getStepPositionClass: function getStepPositionClass(index) {
      return 'step-' + index;
    },

    scrollToStepById: function scrollToStepById(id) {
      var element = _ember['default'].$('#workflow-step-' + id);

      this.scrollToElement(element);
    },

    scrollToStepByIndex: function scrollToStepByIndex(index) {
      var element = _ember['default'].$('.cm-workflow-step.' + this.getStepPositionClass(index));

      this.scrollToElement(element);
    },

    scrollToElement: function scrollToElement(element) {
      if (element.length) {
        _ember['default'].$('html, body').animate({ scrollTop: element.offset().top }, this.get('scrollDuration'));
      }
    }
  });
});