define('quantuvis-cm-spa/models/workflows/workflow-template-substep-preset', ['exports', 'quantuvis-cm-spa/constants/workflow-substep-type', 'quantuvis-cm-spa/constants/due-date-events'], function (exports, _quantuvisCmSpaConstantsWorkflowSubstepType, _quantuvisCmSpaConstantsDueDateEvents) {
  function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError('Cannot call a class as a function'); } }

  var _default = function _default() {
    _classCallCheck(this, _default);

    this.id = null;
    this.notes = null;
    this.type = _quantuvisCmSpaConstantsWorkflowSubstepType.DEPARTMENT_REVIEW;
    this.position = 0;
    this.responsiblePersons = [];
    this.department = null;
    this.departmentId = null;
    this.dueDateTemplate = {
      units: 10,
      dueDateEvent: _quantuvisCmSpaConstantsDueDateEvents.DAYS_AFTER_PREVIOUS_STEP_EVENT
    };
  };

  exports['default'] = _default;
});