define('quantuvis-cm-spa/constants/sender-view-statuses', ['exports'], function (exports) {
  var SENDER_VIEW_ENVELOPE_SENT = 'Send';
  var SENDER_VIEW_ENVELOPE_SAVE = 'Save';
  var SENDER_VIEW_ENVELOPE_CANCEL = 'Cancel';
  var SENDER_VIEW_ENVELOPE_ERROR = 'Error';
  var SENDER_VIEW_ENVELOPE_SESSION_END = 'SessionEnd';

  var SENDER_VIEW_ENVELOPE_STATUSES = [SENDER_VIEW_ENVELOPE_SENT, SENDER_VIEW_ENVELOPE_SAVE, SENDER_VIEW_ENVELOPE_CANCEL, SENDER_VIEW_ENVELOPE_ERROR, SENDER_VIEW_ENVELOPE_SESSION_END];

  var SENDER_VIEW_ENVELOPE_PROCESSABLE_STATUSES = [SENDER_VIEW_ENVELOPE_SENT, SENDER_VIEW_ENVELOPE_SAVE];

  var SENDER_VIEW_ENVELOPE_ERROR_STATUSES = [SENDER_VIEW_ENVELOPE_ERROR, SENDER_VIEW_ENVELOPE_SESSION_END];

  exports.SENDER_VIEW_ENVELOPE_SENT = SENDER_VIEW_ENVELOPE_SENT;
  exports.SENDER_VIEW_ENVELOPE_SAVE = SENDER_VIEW_ENVELOPE_SAVE;
  exports.SENDER_VIEW_ENVELOPE_CANCEL = SENDER_VIEW_ENVELOPE_CANCEL;
  exports.SENDER_VIEW_ENVELOPE_ERROR = SENDER_VIEW_ENVELOPE_ERROR;
  exports.SENDER_VIEW_ENVELOPE_SESSION_END = SENDER_VIEW_ENVELOPE_SESSION_END;
  exports.SENDER_VIEW_ENVELOPE_STATUSES = SENDER_VIEW_ENVELOPE_STATUSES;
  exports.SENDER_VIEW_ENVELOPE_PROCESSABLE_STATUSES = SENDER_VIEW_ENVELOPE_PROCESSABLE_STATUSES;
  exports.SENDER_VIEW_ENVELOPE_ERROR_STATUSES = SENDER_VIEW_ENVELOPE_ERROR_STATUSES;
});