define('quantuvis-cm-spa/models/search/option', ['exports'], function (exports) {
  var _createClass = (function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ('value' in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; })();

  function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError('Cannot call a class as a function'); } }

  /**
   * Show all option id
   * @type {string}
   */
  var ALL_OPTION_ID = 'ALL';

  exports.ALL_OPTION_ID = ALL_OPTION_ID;
  /**
   * Show option label
   * @type {string}
   */
  var ALL_OPTION_NAME = 'All';

  exports.ALL_OPTION_NAME = ALL_OPTION_NAME;

  var AttributeOption = (function () {
    _createClass(AttributeOption, null, [{
      key: 'createAllOption',

      /**
       * @param {boolean} isSelected
       * @param {boolean} isActive
       * @param {boolean} isHidden
       * @returns {AttributeOption}
       */
      value: function createAllOption() {
        var isSelected = arguments.length <= 0 || arguments[0] === undefined ? false : arguments[0];
        var isActive = arguments.length <= 1 || arguments[1] === undefined ? false : arguments[1];
        var isHidden = arguments.length <= 2 || arguments[2] === undefined ? false : arguments[2];

        return new AttributeOption(ALL_OPTION_ID, ALL_OPTION_NAME, isSelected, isActive, isHidden);
      }

      /**
       * @param {number|string} id
       * @param {string} name
       * @param {boolean} isSelected
       * @param {boolean} isActive
       * @param {boolean} isHidden
       */
    }]);

    function AttributeOption(id, name) {
      var isSelected = arguments.length <= 2 || arguments[2] === undefined ? false : arguments[2];
      var isActive = arguments.length <= 3 || arguments[3] === undefined ? false : arguments[3];
      var isHidden = arguments.length <= 4 || arguments[4] === undefined ? false : arguments[4];

      _classCallCheck(this, AttributeOption);

      this.id = id;
      this.name = name;
      this.isSelected = isSelected;
      this.isActive = isActive;
      this.isHidden = isHidden;
    }

    /**
     * @returns {boolean}
     */

    _createClass(AttributeOption, [{
      key: 'isCountAsSelected',
      value: function isCountAsSelected() {
        return this.id !== ALL_OPTION_ID && this.isSelected;
      }
    }]);

    return AttributeOption;
  })();

  exports.AttributeOption = AttributeOption;
});