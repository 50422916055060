define('quantuvis-cm-spa/components/cm-workflow-step/cm-workflow-template-extended', ['exports', 'ember', 'quantuvis-cm-spa/components/cm-workflow-step/cm-extended'], function (exports, _ember, _quantuvisCmSpaComponentsCmWorkflowStepCmExtended) {
  exports['default'] = _quantuvisCmSpaComponentsCmWorkflowStepCmExtended['default'].extend({
    workflowTemplate: _ember['default'].inject.service(),

    createSubstepWithPosition: function createSubstepWithPosition(position) {
      var substep = this.get('workflowTemplate').createSubstep();

      substep.set('position', position);

      return substep;
    },

    createEmptyDepartmentSubstep: function createEmptyDepartmentSubstep() {
      this.set('model.substeps', [this.get('workflowTemplate').createSubstep()]);
    }
  });
});