define('quantuvis-cm-spa/services/contract-details', ['exports', 'ember', 'quantuvis-cm-spa/constants/permissions', 'quantuvis-cm-spa/constants/contract-status'], function (exports, _ember, _quantuvisCmSpaConstantsPermissions, _quantuvisCmSpaConstantsContractStatus) {
  exports['default'] = _ember['default'].Service.extend({
    activeStatuses: [_quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.IN_REVIEW, _quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.IN_SIGNATURE, _quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.PARTIALLY_EXECUTED_INTERNALLY, _quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.PARTIALLY_EXECUTED_EXTERNALLY],

    contractId: null,
    notes: [],
    currentVersion: null,
    store: _ember['default'].inject.service(),
    session: _ember['default'].inject.service('session'),
    permissionService: _ember['default'].inject.service(),
    contractNoteModel: null,
    isTimelineItemsLoading: false,
    isHistoryLoading: false,
    isAmendment: false,

    activeSignOff: null,
    pendingSignOff: null,
    selectedMenuItem: null,
    inProgressStepResponsiblePersons: [],
    timelineItems: [],
    versions: [],
    signOffs: [],

    init: function init() {
      this.set('contractNoteModel', this.getEmptyContractNoteModel());
    },

    /**
     * Set contract id
     * @param {number} id - contract ID
     */
    setContractId: function setContractId(id) {
      this.set('contractId', id);
    },

    // TODO add tests and jsDoc
    saveContractNote: function saveContractNote(model) {
      var _this = this;

      var requestObj = {
        'content': model.get('content'),
        'contractId': this.get('contractId'),
        'createdDate': new Date()
      };

      var contractNote = this.get('store').createRecord('contracts/note', requestObj);

      return contractNote.save().then(function () {
        return _this.getContractNotes();
      });
    },

    getEmptyContractNoteModel: function getEmptyContractNoteModel() {
      return {
        content: null,
        contractId: null,
        createdDate: null
      };
    },

    /**
     * Get current document type name
     * @return {string} Document type name
     */
    getDocumentTypeName: function getDocumentTypeName() {
      var contract = this.get('store').peekRecord('contract', this.get('contractId'));
      var documentTypeName = contract ? contract.get('type.name') : '';

      return documentTypeName;
    },

    checkCompanyPermission: function checkCompanyPermission(permissionName) {
      return this.get('permissionService').checkCompanyUserPermission(permissionName);
    },

    /**
     * Load timeline items and set them to timelineItems variable
     */
    loadTimelineItems: function loadTimelineItems() {
      var _this2 = this;

      if (this.checkCompanyPermission(_quantuvisCmSpaConstantsPermissions.PERMISSIONS.ID.READ_TIMELINE)) {
        this.set('isTimelineItemsLoading', true);

        return this.get('store').query('workflows/timeline-item', { contractId: this.get('contractId') }).then(function (items) {
          _this2.get('inProgressStepResponsiblePersons').clear();
          _this2.set('isTimelineItemsLoading', false);
          _this2.set('timelineItems', items);
        })['catch'](function (error) {
          _this2.get('inProgressStepResponsiblePersons').clear();
          _this2.set('timelineItems', []);
          _this2.set('isTimelineItemsLoading', false);
          _ember['default'].Logger.warn('loadTimelineItems error occurred:', error);
        });
      }
    },

    loadVersions: function loadVersions() {
      var _this3 = this;

      return this.get('store').query('workflows/attachment-version', { contractId: this.get('contractId') }).then(function (data) {
        _this3.set('versions', data);
      });
    },

    loadParentContract: function loadParentContract(parentContractId) {
      var _this4 = this;

      return this.get('store').findRecord('contract', parentContractId, { reload: true }).then(function (data) {
        _this4.set('parentContract', data);
      });
    },

    loadActiveSignOff: function loadActiveSignOff(contractId) {
      var _this5 = this;

      return this.get('store').adapterFor('signoff').getActiveByContractId(contractId).then(function (activeSignOff) {
        return _this5.set('activeSignOff', activeSignOff);
      });
    },

    loadPendingSignOff: function loadPendingSignOff(contractId) {
      var _this6 = this;

      return this.get('store').adapterFor('signoff').getPendingByContractId(contractId).then(function (pendingSignOff) {
        return _this6.set('pendingSignOff', pendingSignOff);
      });
    },

    loadHistory: function loadHistory() {
      var _this7 = this;

      if (this.checkCompanyPermission(_quantuvisCmSpaConstantsPermissions.PERMISSIONS.ID.READ_HISTORY)) {
        this.set('isHistoryLoading', true);

        this.get('store').query('history', { documentId: this.get('contractId') }).then(function (records) {
          return _this7.setProperties({ isHistoryLoading: false, history: records });
        })['catch'](function () {
          return _this7.setProperties({ isHistoryLoading: false, history: [] });
        });
      }
    },

    reloadContract: function reloadContract() {
      return this.get('store').findRecord('contract', this.get('contractId'), { reload: true });
    },

    /**
     * Peek existing contract from store by Id
     * @return {object} contract
     */
    getContract: function getContract() {
      return this.get('store').peekRecord('contract', this.get('contractId'));
    },

    // TODO add tests and jsDoc
    getContractNotes: function getContractNotes() {
      var _this8 = this;

      if (this.checkCompanyPermission(_quantuvisCmSpaConstantsPermissions.PERMISSIONS.ID.READ_NOTES)) {
        return this.get('store').query('contracts/note', { contractId: this.get('contractId') }).then(function (result) {
          _this8.set('notes', result);
        })['catch'](function (error) {
          _ember['default'].Logger.warn('get contract notes error:', error);
        });
      }

      return Promise.reject('Access is denied');
    },

    getCountOfUnreadContractNotes: function getCountOfUnreadContractNotes() {
      if (this.checkCompanyPermission(_quantuvisCmSpaConstantsPermissions.PERMISSIONS.ID.READ_NOTES)) {
        return this.get('store').queryRecord('contracts/unread-note', {});
      }

      return Promise.reject('Access is denied');
    },

    /**
     * Check if current user is contract lead of current document
     * @return {boolean} contract
     */
    isContractLead: function isContractLead() {
      var currentContract = this.getContract();

      if (!currentContract) {
        _ember['default'].Logger.error('isContractLead: currentContract is not loaded');

        return false;
      }
      /**
       * @todo refactoring getting user_id
       */
      var currentUserId = this.get('session.data.authenticated.user_id');

      return currentContract.get('leads').isAny('id', currentUserId.toString());
    },

    /**
     * Check if current user is contract lead of specified document
     * @return {boolean} contract
     */
    isContractLeadFor: function isContractLeadFor(contract) {
      var currentUserId = this.get('session.data.authenticated.user_id');

      return contract.get('leads').isAny('id', currentUserId.toString());
    },

    isCurrentStepResponsiblePerson: function isCurrentStepResponsiblePerson() {
      var currentUserId = this.get('session.data.authenticated.user_id');

      return this.get('inProgressStepResponsiblePersons').includes(currentUserId);
    },

    isResponsibleFor: function isResponsibleFor(data) {
      var currentUserId = this.get('session.data.authenticated.user_id');

      return data.get('responsiblePersonsAccounts').map(function (person) {
        return person.get('id');
      }).includes(currentUserId) || this.isContractLead();
    },

    isActiveStatus: function isActiveStatus(status) {
      return this.get('activeStatuses').contains(_ember['default'].get(status, 'id'));
    }
  });
});