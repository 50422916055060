define('quantuvis-cm-spa/components/cm-workflow-step/cm-add-step', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['cm-add-step'],
    actions: {
      onClick: function onClick() {
        this.sendAction('addWorkflowStep', {
          position: this.get('position')
        });
      }
    }
  });
});