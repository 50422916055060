define('quantuvis-cm-spa/components/cm-contract-details/cm-upload-version', ['exports', 'ember', 'quantuvis-cm-spa/constants/file-types'], function (exports, _ember, _quantuvisCmSpaConstantsFileTypes) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['cm-upload-version'],
    internalVersionMode: true,

    store: _ember['default'].inject.service(),
    fileQueue: _ember['default'].inject.service(),
    // TODO move filetypes to constant and add dynamic fileTypeMatcher
    fileTypes: _quantuvisCmSpaConstantsFileTypes.FILE_ALLOWED_TYPES.FILE_TYPES,
    contractDetails: _ember['default'].inject.service(),
    file: _ember['default'].inject.service(),
    isContractLead: _ember['default'].computed('contractDetails.leads.[]', function () {
      return this.get('contractDetails').isContractLead();
    }),

    actions: {
      onFileDrop: function onFileDrop(fi, evt) {
        this.get('file').standardFileDropHandler(fi, evt);
      },
      onversionSourceChange: function onversionSourceChange() {
        this.toggleProperty('internalVersionMode');
      },
      // TODO forbid upload new file while uploading previous!
      // TODO
      uploadNewVersion: function uploadNewVersion(fi, file) {
        this.get('file').uploadTemplate(fi, file);
      },
      moreThenOneFilesWarning: function moreThenOneFilesWarning(fi) {
        fi.model.addError('fileKey', ['Only one file can be chosen']);
        this.get('fileQueue').set('files', []);
      },
      removeFile: function removeFile(changeset) {
        changeset.set('fileKey', null);
        changeset.set('fileName', null);
      },
      cancelUpload: function cancelUpload() {
        this.get('file').cancelUpload();
      }
    }
  });
});