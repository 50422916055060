define('quantuvis-cm-spa/components/cm-timeline/cm-timeline-item/event-bodies/cm-sign-off-updated', ['exports', 'ember', 'quantuvis-cm-spa/components/cm-timeline/cm-timeline-item/event-bodies/cm-basic'], function (exports, _ember, _quantuvisCmSpaComponentsCmTimelineCmTimelineItemEventBodiesCmBasic) {
  exports['default'] = _quantuvisCmSpaComponentsCmTimelineCmTimelineItemEventBodiesCmBasic['default'].extend({
    classNames: ['cm-sign-off-updated'],
    timeline: _ember['default'].inject.service(),

    actions: {
      showDetails: function showDetails() {
        this.get('timeline').showEventDetails(this.get('data'));
      }
    }
  });
});