define('quantuvis-cm-spa/validations/add-attachment', ['exports', 'quantuvis-cm-spa/constants/attachment-source'], function (exports, _quantuvisCmSpaConstantsAttachmentSource) {

  var fileKeyMessage = 'Please, upload new file';
  var bidPrintTypeMessage = 'Please, select type of bid attachment';

  exports['default'] = {
    fileKey: validateFileKey(),
    bidPrintType: validateBidPrintType()
  };

  function validateFileKey() {
    return function (key, newValue, oldValue, changes, content) {
      if (getAttachmentSource(changes, content) !== _quantuvisCmSpaConstantsAttachmentSource['default'].UPLOAD || newValue) {
        return true;
      } else {
        return fileKeyMessage;
      }
    };
  }

  function validateBidPrintType() {
    return function (key, newValue, oldValue, changes, content) {
      if (getAttachmentSource(changes, content) !== _quantuvisCmSpaConstantsAttachmentSource['default'].BID || newValue) {
        return true;
      } else {
        return bidPrintTypeMessage;
      }
    };
  }

  function getAttachmentSource(changes, content) {
    if (changes.hasOwnProperty('attachmentSource')) {
      return changes.attachmentSource;
    } else {
      return content.attachmentSource;
    }
  }
});