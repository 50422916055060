define('quantuvis-cm-spa/core-ui/components/cm-limitable-counter/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['cm-limitable-counter'],
    tagName: 'span',
    classNameBindings: ['count:active'],
    MAX_COUNTER_VALUE: 99,
    GREATER_THAN_MAX_COUNTER_LABEL: '99+',
    count: _ember['default'].computed('data', function () {
      var count = parseInt(this.get('data'));

      if (isNaN(count)) {
        return 0;
      } else {
        return count > this.MAX_COUNTER_VALUE ? this.GREATER_THAN_MAX_COUNTER_LABEL : count.toString();
      }
    })
  });
});