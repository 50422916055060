define('quantuvis-cm-spa/constants/toast-notifications', ['exports'], function (exports) {
  var CANCEL_SIGN_OFF = {
    type: 'warning',
    message: 'The document Sign Off was canceled'
  };

  var RESEND_EMAILS_SUCCESS = {
    type: 'info',
    message: 'Sign Off requests were resent successfully'
  };

  var RESEND_EMAILS_ERROR = {
    type: 'error',
    message: 'Sign Off requests resent failed. Refresh the page and try again or contact Quantuvis'
  };

  exports.CANCEL_SIGN_OFF = CANCEL_SIGN_OFF;
  exports.RESEND_EMAILS_SUCCESS = RESEND_EMAILS_SUCCESS;
  exports.RESEND_EMAILS_ERROR = RESEND_EMAILS_ERROR;
});