define('quantuvis-cm-spa/components/cm-contract-details/sidebar-menus/summary-menu', ['exports', 'ember', 'quantuvis-cm-spa/components/cm-contract-details/sidebar-menus/sidebar-base-menu', 'quantuvis-cm-spa/constants/contract-status', 'quantuvis-cm-spa/constants/permissions'], function (exports, _ember, _quantuvisCmSpaComponentsCmContractDetailsSidebarMenusSidebarBaseMenu, _quantuvisCmSpaConstantsContractStatus, _quantuvisCmSpaConstantsPermissions) {
  exports['default'] = _quantuvisCmSpaComponentsCmContractDetailsSidebarMenusSidebarBaseMenu['default'].extend({
    classNames: ['summary-menu'],
    permissionService: _ember['default'].inject.service(),

    isDocumentDeactivated: _ember['default'].computed('model.status.id', function () {
      return this.get('model.status.id') === _quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.DEACTIVATED;
    }),

    mayNotEditSummary: _ember['default'].computed('', function () {
      return !this.get('permissionService').checkCompanyUserPermission(_quantuvisCmSpaConstantsPermissions.PERMISSIONS.ID.EDIT_SUMMARY);
    })
  });
});