define("quantuvis-cm-spa/constants/task-object-type", ["exports"], function (exports) {
  var TASK_OBJECT_TYPE = {
    USER_ACCOUNT: 1,
    CONTRACT: 2,
    WORKFLOW: 3,
    WORKFLOW_STEP: 4,
    WORKFLOW_SUBSTEP: 5,
    DOCUMENT_TYPE: 6
  };

  exports["default"] = TASK_OBJECT_TYPE;
});