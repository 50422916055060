define('quantuvis-cm-spa/mixins/initiate-sign-off', ['exports', 'ember', 'quantuvis-cm-spa/mixins/authenticated-route-mixin', 'quantuvis-cm-spa/constants/permissions', 'quantuvis-cm-spa/constants/http-statuses'], function (exports, _ember, _quantuvisCmSpaMixinsAuthenticatedRouteMixin, _quantuvisCmSpaConstantsPermissions, _quantuvisCmSpaConstantsHttpStatuses) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  exports['default'] = _ember['default'].Mixin.create(_quantuvisCmSpaMixinsAuthenticatedRouteMixin['default'], {
    permissionService: _ember['default'].inject.service(),
    contractService: _ember['default'].inject.service(),
    contractPermissionValidator: _ember['default'].inject.service(),
    acceptSignOffValidator: _ember['default'].inject.service(),
    initiateSignOffValidator: _ember['default'].inject.service(),
    store: _ember['default'].inject.service(),

    contract: {},
    versions: [],

    beforeModel: function beforeModel(transition) {
      this._super.apply(this, arguments);

      this.get('acceptSignOffValidator').removeValidations();
      this.get('initiateSignOffValidator').removeValidations();

      var contractId = transition.params['initiate-sign-off'].id;

      return this.verifyPermissionsAndGetContractWithVersions(contractId);
    },

    verifyPermissionsAndGetContractWithVersions: function verifyPermissionsAndGetContractWithVersions(contractId) {
      var _this = this;

      var versionsPromise = this.get('store').query('workflows/attachment-version', { contractId: contractId });
      var contractPromise = this.get('contractService').findById(contractId, true);
      var permissionsPromise = this.get('permissionService').getUserPermissions();
      var rejectInitiateSignOff = function rejectInitiateSignOff() {
        return Promise.reject({
          status: _quantuvisCmSpaConstantsHttpStatuses['default'].FORBIDDEN,
          payload: {
            message: 'Initiate sign off is forbidden'
          }
        });
      };

      return Promise.all([contractPromise, permissionsPromise, versionsPromise]).then(function (_ref) {
        var _ref2 = _slicedToArray(_ref, 3);

        var contract = _ref2[0];
        var versions = _ref2[2];

        var companyId = contract.get('company.id');
        var hasUserPermission = _this.get('permissionService').checkCompanyUserPermission(_quantuvisCmSpaConstantsPermissions.PERMISSIONS.ID.INITIATE_SIGN_OFF, companyId);

        if (!hasUserPermission) {
          return rejectInitiateSignOff();
        }

        var isSignAvailable = _this.get('contractPermissionValidator').checkSignOffInitAvailability(contract, versions);

        if (!isSignAvailable) {
          return rejectInitiateSignOff();
        }

        _this.setProperties({ contract: contract, versions: versions });
      });
    }
  });
});