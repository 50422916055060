define('quantuvis-cm-spa/adapters/user-management/users/extended', ['exports', 'quantuvis-cm-spa/adapters/application', 'quantuvis-cm-spa/constants/user-query-type'], function (exports, _quantuvisCmSpaAdaptersApplication, _quantuvisCmSpaConstantsUserQueryType) {
  exports['default'] = _quantuvisCmSpaAdaptersApplication['default'].extend({
    urlForQuery: function urlForQuery(query) {
      var uri = this.urlPrefix() + '/user-management/users';

      if (query.type === _quantuvisCmSpaConstantsUserQueryType['default'].PERMISSION) {
        uri += '/with-permission';
      }

      uri += '/extended';

      query.type = undefined;

      return uri;
    },

    urlForFindRecord: function urlForFindRecord(id) {
      return this.get('host') + '/' + this.get('namespace') + '/user-management/users/' + id + '/extended';
    }
  });
});