define('quantuvis-cm-spa/adapters/application', ['exports', 'ember', 'ember-data', 'quantuvis-cm-spa/app-config', 'quantuvis-cm-spa/mixins/data-adapter-mixin', 'quantuvis-cm-spa/constants/http-statuses', 'ember-inflector'], function (exports, _ember, _emberData, _quantuvisCmSpaAppConfig, _quantuvisCmSpaMixinsDataAdapterMixin, _quantuvisCmSpaConstantsHttpStatuses, _emberInflector) {

  /**
   * Base rest API adapter
   */
  exports['default'] = _emberData['default'].RESTAdapter.extend(_quantuvisCmSpaMixinsDataAdapterMixin['default'], {
    host: _quantuvisCmSpaAppConfig['default'].apiUrl,
    namespace: _quantuvisCmSpaAppConfig['default'].apiPrefix,
    authorizer: 'authorizer:oauth2',
    unauthorizedInterceptor: _ember['default'].inject.service(),

    /**
     * Convert model type to path
     * @param type
     */
    pathForType: function pathForType(type) {
      var typeNameNormalized = _ember['default'].String.dasherize(type);

      return (0, _emberInflector.pluralize)(typeNameNormalized);
    },

    handleResponse: function handleResponse(status, headers, payload) {
      var adapterErrors = this.processedResponseErrors(status, payload);

      this.get('unauthorizedInterceptor').handleResponseStatus(status);

      return adapterErrors ? adapterErrors : this._super(status, headers, payload, {});
    },

    /**
     * Process error response from backend and
     * return adapter errors for incorrect codes
     */
    processedResponseErrors: function processedResponseErrors(status, payload) {
      var adapterErrors = null;

      switch (status) {
        case _quantuvisCmSpaConstantsHttpStatuses['default'].BAD_REQUEST:
          _ember['default'].Logger.warn('Validation error occurred:', payload);
          adapterErrors = this.collectAdapterErrors(payload);
          break;
        case _quantuvisCmSpaConstantsHttpStatuses['default'].NOT_FOUND:
        case _quantuvisCmSpaConstantsHttpStatuses['default'].CONFLICT:
        case _quantuvisCmSpaConstantsHttpStatuses['default'].FORBIDDEN:
        case _quantuvisCmSpaConstantsHttpStatuses['default'].UNAUTHORISED:
          adapterErrors = { status: status, payload: payload };
          break;
        case _quantuvisCmSpaConstantsHttpStatuses['default'].INTERNAL_SERVER_ERROR:
          _ember['default'].Logger.error('Internal server error:', payload);
          adapterErrors = { status: status, payload: payload };
          break;

        default:
          break;
      }

      return adapterErrors;
    },

    /**
     * Collect errors from payload to InvalidError object
     *
     * @param payload
     * @returns {DS.InvalidError}
     */
    collectAdapterErrors: function collectAdapterErrors(payload) {
      var _this = this;

      var errors = payload.errors.map(function (error) {
        return _this.proceedValidationError(error);
      });

      return new _emberData['default'].InvalidError(errors);
    },

    /**
     * Proceed validation error
     */
    proceedValidationError: function proceedValidationError(error) {
      return this.adapterErrorFactory(error.field, error.message, error.code);
    },

    adapterErrorFactory: function adapterErrorFactory(pointer, message, code) {
      return {
        detail: this.interpolateErrorMessage(message, code),
        source: { pointer: pointer }
      };
    },

    /**
     * Retrieve user friendly message from dictionary by code
     * Should be impalement after backend refactoring
     */
    interpolateErrorMessage: function interpolateErrorMessage(message) {
      return message;
    },

    remove: function remove(type, id) {
      return this.ajax(this.buildURL(type, id), 'DELETE');
    },

    put: function put(type, id, options) {
      return this.ajax(this.buildURL(type, id), 'PUT', options);
    },

    post: function post(type, id, options) {
      return this.ajax(this.buildURL(type, id), 'POST', options);
    }
  });
});