define('quantuvis-cm-spa/components/cm-contract-details/cm-contract-details-navbar', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['cm-contract-details-navbar', 'cm-shadow-box-navbar'],

    actions: {
      back: function back() {
        this.sendAction('backButtonClick');
      }
    }
  });
});