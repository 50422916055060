define('quantuvis-cm-spa/core-ui/components/cm-multiline-ellipsis/component', ['exports', 'ember'], function (exports, _ember) {
  var _this = this;

  exports['default'] = _ember['default'].Component.extend({
    /*
    * For do multiline elipse sholud add max-height to element what you want to ellipse
    * And use helper "text-to-span"
     */
    classNames: ['text-ellipsis'],

    classname: 'js-shave',
    character: '…',
    spaces: false,

    init: function init() {
      this._super.apply(this, arguments);
      _ember['default'].$(window).on('resize', _ember['default'].run.bind(this, this.shaveIfPresent));
    },

    shave: function shave(target, maxHeight) {
      if (!maxHeight) {
        throw Error('maxHeight is required');
      }
      var elementsToEllipse = typeof target === 'string' ? document.querySelectorAll(target) : target;

      var spaces = this.get('spaces');
      var charHtml = this.getEndOfOutputTemplate();

      this.ellipseElements(elementsToEllipse, spaces, charHtml, maxHeight);
      if (!('length' in elementsToEllipse)) {
        elementsToEllipse = [elementsToEllipse];
      }
    },

    getEndOfOutputTemplate: function getEndOfOutputTemplate() {
      var character = this.get('character');

      return '<span class="js-shave-char">' + character + '</span>';
    },

    ellipseElements: function ellipseElements(elementsToEllipse, spaces, charHtml, maxHeight) {
      for (var index = 0; index < elementsToEllipse.length; index += 1) {
        var element = elementsToEllipse[index];
        var styles = element.style;
        var span = element.querySelector('.' + this.get('classname'));
        var elementTextAttribute = this.getElementTextAttribute(element);

        var fullText = this.alreadyShaved(span, element, elementTextAttribute);
        var words = spaces ? fullText.split(' ') : fullText;

        this.addWrapperClass(fullText);

        // If 0 or 1 words, we're done
        if (words.length <= 1) {
          continue;
        }

        // Temporarily remove any CSS height for text height calculation
        var heightStyle = styles.height;

        styles.height = 'auto';
        var maxHeightStyle = styles.maxHeight;

        styles.maxHeight = 'none';

        // If already short enough, we're done
        if (element.offsetHeight <= maxHeight) {
          styles.height = heightStyle;
          styles.maxHeight = maxHeightStyle;
          continue;
        }

        var max = this.searchAllottedHeight(words, element, elementTextAttribute, spaces, charHtml, maxHeight);
        var diff = this.breakWord(element, elementTextAttribute, spaces, words, max, charHtml);

        element.insertAdjacentHTML('beforeend', this.getWordsDeferenceTemplate(diff));

        styles.height = heightStyle;
        styles.maxHeight = maxHeightStyle;
      }
    },

    getWordsDeferenceTemplate: function getWordsDeferenceTemplate(deference) {
      var classname = this.get('classname');

      return '<span class="' + classname + '" style="display:none;">' + deference + '</span>';
    },

    getElementTextAttribute: function getElementTextAttribute(element) {
      return element.textContent === undefined ? 'innerText' : 'textContent';
    },

    // If element text has already been shaved
    alreadyShaved: function alreadyShaved(span, element, elementTextAttribute) {
      if (span) {
        element.removeChild(element.querySelector('.js-shave-char'));
        element[elementTextAttribute] = element[elementTextAttribute];
      }

      return element[elementTextAttribute];
    },

    // Binary search for number of words which can fit in allotted height
    searchAllottedHeight: function searchAllottedHeight(words, el, elementTextAttribute, spaces, charHtml, maxHeight) {
      var maxWordsToShow = words.length - 1;
      var min = 0;
      var pivot = undefined;
      var pivostOffsetWithSpaces = 1;
      var pivostOffsetWithoutSpaces = 2;

      while (min < maxWordsToShow) {
        pivot = min + maxWordsToShow + 1 >> 1; // eslint-disable-line no-bitwise
        el[elementTextAttribute] = spaces ? words.slice(0, pivot).join(' ') : words.slice(0, pivot);
        el.insertAdjacentHTML('beforeend', charHtml);
        if (el.offsetHeight > maxHeight) {
          maxWordsToShow = spaces ? pivot - pivostOffsetWithSpaces : pivot - pivostOffsetWithoutSpaces;
        } else min = pivot;
      }

      return maxWordsToShow;
    },

    breakWord: function breakWord(wrappedElement, elementTextAttribute, spaces, words, max, charHtml) {
      wrappedElement[elementTextAttribute] = spaces ? words.slice(0, max).join(' ') : words.slice(0, max);
      wrappedElement.insertAdjacentHTML('beforeend', charHtml);

      return spaces ? words.slice(max).join(' ') : words.slice(max);
    },

    didRender: function didRender() {
      this.shaveIfPresent();
    },

    addWrapperClass: function addWrapperClass(fullText) {
      if (!/\s/.test(fullText.trim())) {
        this.$().addClass('text-ellipsis-no-wrap');
      }
    },

    checkCollapseColumnObserver: _ember['default'].observer('checkCollapseColumn', function () {
      return _this.shaveIfPresent;
    }),

    shaveIfPresent: function shaveIfPresent() {
      var elements = this.$();

      if (elements) {
        this.shave(elements, this.get('maxHeight'));
      }
    }
  });
});