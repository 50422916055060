define('quantuvis-cm-spa/core-ui/components/cm-icon/component', ['exports', 'ember', 'quantuvis-cm-spa/mixins/add-test-id-attribute'], function (exports, _ember, _quantuvisCmSpaMixinsAddTestIdAttribute) {
  exports['default'] = _ember['default'].Component.extend(_quantuvisCmSpaMixinsAddTestIdAttribute['default'], {
    tagName: 'i',
    classNames: ['cm-icon', 'noselect'],
    classNameBindings: ['bindIconClass', 'multicolor'],
    bindIconClass: _ember['default'].computed('icon-name', function () {
      return this.createIconClassName(this.get('icon-name'));
    }),

    multicolor: _ember['default'].computed('paths', function () {
      return this.get('paths') > 0 ? true : false;
    }),

    createIconClassName: function createIconClassName(iconName) {
      return 'icon-' + iconName;
    },

    /**
     * Send click action
     *
     * @param event
     */
    click: function click(event) {
      this.sendAction('on-click', event);
    },

    /**
     * Send mouse enter action
     *
     * @param event
     */
    mouseEnter: function mouseEnter(event) {
      this.sendAction('on-mouse-enter', event);
    },

    numOfPaths: _ember['default'].computed('paths', function () {
      var paths = parseInt(this.get('paths'));

      if (Number.isInteger(paths)) {
        return new Array(paths);
      } else {
        return [];
      }
    })
  });
});