define('quantuvis-cm-spa/mixins/query-params-url-cleaner', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({
    window: _ember['default'].inject.service(),

    beforeModel: function beforeModel(transition) {
      var parentPromises = this._super.apply(this, arguments);

      if (Object.keys(transition.queryParams).length) {
        this.removeQueryParams(transition);
      }

      return _ember['default'].RSVP.hash({ parentPromises: parentPromises });
    },

    removeQueryParams: function removeQueryParams() {
      var location = this.get('window').getLocation();

      history.replaceState({}, '', '' + location.origin + location.pathname);
    }
  });
});