define('quantuvis-cm-spa/services/file', ['exports', 'ember', 'quantuvis-cm-spa/constants/attachment-type', 'quantuvis-cm-spa/constants/permissions', 'quantuvis-cm-spa/helpers/prevent-events', 'quantuvis-cm-spa/constants/file-types', 'quantuvis-cm-spa/constants/applications'], function (exports, _ember, _quantuvisCmSpaConstantsAttachmentType, _quantuvisCmSpaConstantsPermissions, _quantuvisCmSpaHelpersPreventEvents, _quantuvisCmSpaConstantsFileTypes, _quantuvisCmSpaConstantsApplications) {
  exports['default'] = _ember['default'].Service.extend({
    POLL_TIMEOUT: 5000,
    store: _ember['default'].inject.service(),
    dialog: _ember['default'].inject.service(),
    contractDetails: _ember['default'].inject.service(),
    attachment: null,
    documentName: null,
    documentURI: null,
    documentId: null,
    previewErrorMessage: null,
    pollForPreviewInterval: null,

    fileQueue: _ember['default'].inject.service(),
    uploadPromise: null,
    fileTypeMatcher: _quantuvisCmSpaConstantsFileTypes.FILE_ALLOWED_TYPES.FILE_TYPE_MATCHER,
    maxFileSize: _quantuvisCmSpaConstantsFileTypes.MAX_FILE_SIZE,

    actions: {
      printPdf: function printPdf() {
        var _this = this;

        var context = this.get('contextObject');

        if (context.isAttachmentNotForHistory()) {
          this.actions.printResource.call(this);
        } else {
          return new Promise(function (resolve) {
            return context.printDocumentVersion(context.attachment.id, context.documentId, _ember['default'].get(context.attachment, 'version')).then(function () {
              _this.actions.printResource.call(_this);
            })['catch'](function () {
              return resolve(false);
            });
          });
        }
      },

      printResource: function printResource() {
        var printFrame = _ember['default'].$('#print-frame');
        var timeoutForDomUpdating = 50;

        var previewCanvas = _ember['default'].$('.cm-document-preview .pdfViewer canvas');
        var contractCanvas = _ember['default'].$('.cm-contract-content-preview .pdfViewer canvas');

        var canvasPages = previewCanvas.length > 0 ? previewCanvas : contractCanvas;

        printFrame.empty();

        canvasPages.each(function (index, value) {
          printFrame.append('<br><img src=\'' + value.toDataURL() + '\'/>"');
        });

        setTimeout(window.print, timeoutForDomUpdating);
      },

      downloadCurrentVersion: function downloadCurrentVersion() {
        var context = this.get('contextObject');

        return new Promise(function (resolve) {
          return context.downloadDocumentVersion(context.attachment.id, context.documentId, _ember['default'].get(context.attachment, 'version')).then(function () {
            resolve(true);
          })['catch'](function () {
            return resolve(false);
          });
        });
      }
    },

    isAttachmentNotForHistory: function isAttachmentNotForHistory() {
      return this.get('type') === _quantuvisCmSpaConstantsAttachmentType['default'].SUMMARY_ATTACHMENT;
    },

    /**
     * Get preassigned URL
     *
     * @param fileKey
     * @param version
     * @param contractId
     */
    getFileUrl: function getFileUrl(fileKey, version) {
      var contractId = arguments.length <= 2 || arguments[2] === undefined ? this.getContractId() : arguments[2];

      return this.get('store').queryRecord('contracts/file/download-presigned-url', {
        key: fileKey,
        version: version,
        contractId: contractId
      });
    },

    getContractId: function getContractId() {
      return this.get('documentId') ? this.get('documentId') : this.get('contractDetails.contractId');
    },

    unfocusElements: function unfocusElements() {
      _ember['default'].$(':focus').blur();
    },

    previewTemplate: function previewTemplate(attachment) {
      this.unfocusElements();
      _quantuvisCmSpaHelpersPreventEvents['default'].preventTab();

      this.set('attachment', attachment);
      this.set('documentId', attachment.id);
      this.set('documentURI', null);
      this.set('documentName', attachment.get('name'));
      this.set('previewErrorMessage', this.get('attachment.previewErrorMessage'));
      var options = {
        actions: this.get('actions')
      };

      this.get('dialog').show('dialogs/preview/cm-template-preview-template', null, this, options).then(function () {
        return _quantuvisCmSpaHelpersPreventEvents['default'].unbindPreventedTab();
      })['catch'](function () {
        return _quantuvisCmSpaHelpersPreventEvents['default'].unbindPreventedTab();
      });

      this.setTemplatePresignedUrl(attachment.get('filePreviewKey'));
    },

    setTemplatePresignedUrl: function setTemplatePresignedUrl(previewKey) {
      var _this2 = this;

      this.get('store').queryRecord('contracts/file-templates/download-presigned-url', {
        previewKey: previewKey
      }).then(function (response) {
        _this2.set('documentURI', response.get('url'));
      })['catch'](function () {});
    },

    previewDocument: function previewDocument(attachment, documentType, documentId, companyId) {
      var _this3 = this;

      var predefinedSearch = arguments.length <= 4 || arguments[4] === undefined ? '' : arguments[4];

      this.set('attachment', attachment);
      this.set('documentURI', null);
      this.set('documentId', documentId);
      this.set('type', documentType);
      this.set('documentName', this.getDocumentName());
      this.set('previewErrorMessage', this.get('attachment.previewErrorMessage'));

      var options = {
        actions: this.get('actions'),
        printPermissionsName: _quantuvisCmSpaConstantsPermissions.PERMISSIONS.ID.PRINT_DOCUMENT,
        companyId: companyId,
        documentId: documentId,
        findControllerWrapper: _ember['default'].Object.create({}),
        predefinedSearch: predefinedSearch
      };

      this.get('dialog').show('dialogs/preview/cm-document-preview-template', null, this, options).then(function () {})['catch'](function () {
        clearInterval(_this3.get('pollForPreviewInterval'));
      });
      this.initDocumentUrl();
    },

    showPrivacyPolicy: function showPrivacyPolicy() {
      var _this4 = this;

      this.get('store').adapterFor('user-management/applications/resource').privacyPolicy(_quantuvisCmSpaConstantsApplications['default'].CONTRACT_MANAGEMENT_ID).then(function (_ref) {
        var data = _ref.data;

        _this4.showResourceDialog(data, 'Privacy Policy');
      });
    },

    showTermsOfUse: function showTermsOfUse() {
      var _this5 = this;

      this.get('store').adapterFor('user-management/applications/resource').termsOfUse(_quantuvisCmSpaConstantsApplications['default'].CONTRACT_MANAGEMENT_ID).then(function (_ref2) {
        var data = _ref2.data;

        _this5.showResourceDialog(data, 'Terms of Use');
      });
    },

    showResourceDialog: function showResourceDialog(data, title) {
      this.set('documentURI', data.uri);
      this.set('documentName', title);

      var options = { actions: this.get('actions') };

      this.get('dialog').show('dialogs/preview/cm-template-preview-resource', null, this, options);
    },

    getDocumentName: function getDocumentName() {
      var documentId = this.get('documentId');
      var type = this.get('type');

      switch (type) {
        case _quantuvisCmSpaConstantsAttachmentType['default'].CONTRACT_ATTACHMENT:
          return this.get('store').peekRecord('search/contract', documentId).get('name');
        case _quantuvisCmSpaConstantsAttachmentType['default'].TIMELINE_ELEMENT_ATTACHMENT:
        case _quantuvisCmSpaConstantsAttachmentType['default'].SUMMARY_ATTACHMENT:
        case _quantuvisCmSpaConstantsAttachmentType['default'].AMENDMENT:
          return this.get('store').peekRecord('contract', this.get('contractDetails.contractId')).get('name');
        default:
          return '';
      }
    },

    initDocumentUrl: function initDocumentUrl() {
      var key = this.get('attachment.previewKey');
      var version = this.get('attachment.version');
      var errorMessage = this.get('attachment.previewErrorMessage');

      if (!this.checkFilePreview(key, errorMessage, version)) {
        this.set('pollForPreviewInterval', setInterval(this.pollForFilePreview.bind(this), this.get('POLL_TIMEOUT')));
      }
    },

    setPresignedUrlByKey: function setPresignedUrlByKey(key, version) {
      var _this6 = this;

      if (this.isAttachmentNotForHistory()) {
        this.downloadPresignedUrl(key, version);
      } else {
        this.previewDocumentVersion(this.get('attachment.id'), this.getContractId(), version).then(function () {
          _this6.downloadPresignedUrl(key, version);
        });
      }
    },

    downloadPresignedUrl: function downloadPresignedUrl(key, version) {
      var _this7 = this;

      this.get('store').queryRecord('contracts/file/download-presigned-url', {
        key: key,
        version: version,
        contractId: this.getContractId()
      }).then(function (response) {
        _this7.set('documentURI', response.get('url'));
      })['catch'](function () {});
    },

    getAttachmentUrl: function getAttachmentUrl() {
      switch (this.get('type')) {
        case _quantuvisCmSpaConstantsAttachmentType['default'].CONTRACT_ATTACHMENT:
          return 'contracts/attachment';
        case _quantuvisCmSpaConstantsAttachmentType['default'].TIMELINE_ELEMENT_ATTACHMENT:
          return 'workflows/attachment';
        case _quantuvisCmSpaConstantsAttachmentType['default'].SUMMARY_ATTACHMENT:
          return 'contracts/attachment';
        default:
          break;
      }
    },

    pollForFilePreview: function pollForFilePreview() {
      var _this8 = this;

      var attachmentUrl = this.getAttachmentUrl();

      this.get('store').findRecord(attachmentUrl, this.get('attachment.id')).then(function (response) {
        _this8.checkFilePreview(response.get('previewKey'), response.get('errorMessage'), response.get('version'));
      })['catch'](function () {});
    },

    checkFilePreview: function checkFilePreview(previewKey, errorMessage, version) {
      this.set('previewErrorMessage', errorMessage);
      if (previewKey) {
        this.setPresignedUrlByKey(previewKey, version);
      }
      if (previewKey || errorMessage) {
        clearInterval(this.get('pollForPreviewInterval'));

        return true;
      }

      return false;
    },

    /**
     *
     * @param fi
     * @param file
     * @param changeset
     * @param options forceValidateField, fileTypeMatcher, maxFileSize
     */
    uploadTemplate: function uploadTemplate(fi, file, changeset, options) {
      var _this9 = this;

      var forceValidateFiled = undefined;
      var fileTypeMatcher = this.get('fileTypeMatcher');
      var maxFileSize = this.get('maxFileSize');

      if (options) {
        maxFileSize = options.maxFileSize || maxFileSize;
        fileTypeMatcher = options.fileTypeMatcher || fileTypeMatcher;
        forceValidateFiled = options.forceValidateField;
      }
      fi.model.addError('fileKey', ['']);
      if (file.get('size') > maxFileSize) {
        fi.model.addError('fileKey', ['File exceeds 25MB limit']);
        fi.model.set('fileName', null);
        this.get('fileQueue').set('files', []);

        return;
      }
      if (!fileTypeMatcher.test(file.get('name'))) {
        fi.model.addError('fileKey', ['This file format is not supported']);
        fi.model.set('fileName', null);
        this.get('fileQueue').set('files', []);

        return;
      }
      var fileKey = undefined;

      this.get('store').queryRecord('contracts/file/upload-presigned-url', { key: file.get('name') }).then(function (response) {
        fileKey = response.get('fileKey');
        _this9.set('uploadPromise', file.uploadBinary(response.get('url'), { method: 'PUT' }));

        return _this9.get('uploadPromise');
      }).then(function () {
        fi.model.set('fileKey', fileKey);
        fi.model.set('fileName', file.get('name'));
        _this9.get('fileQueue').set('files', []);
        // force filename validation
        if (forceValidateFiled) {
          changeset.set(forceValidateFiled, changeset.get(forceValidateFiled));
        }
      })['catch'](function () {
        fi.model.addError('fileKey', ['Upload Failed']);
        _this9.get('fileQueue').set('files', []);
      });
    },

    cancelUpload: function cancelUpload() {
      this.get('uploadPromise').cancel();
      this.get('fileQueue').set('files', []);
    },

    standardFileDropHandler: function standardFileDropHandler(fi, evt, func, context) {
      var uploadFunction = func || this.uploadTemplate;
      var uploadContext = context || this;

      evt.queue.set('onfileadd', uploadFunction.bind(uploadContext, fi));

      if (evt.dataTransfer.files.length > 1) {
        evt.dataTransfer = { files: [] };
        fi.model.addError('fileKey', ['Only one file can be chosen']);
      }
    },

    downloadDocumentVersion: function downloadDocumentVersion(fileId, documentId, version) {
      return this.get('store').adapterFor('contracts/file').download(this.createVersionMetaData(fileId, documentId, version));
    },
    previewDocumentVersion: function previewDocumentVersion(fileId, documentId, version) {
      return this.get('store').adapterFor('contracts/file').preview(this.createVersionMetaData(fileId, documentId, version));
    },
    viewDocumentVersion: function viewDocumentVersion(fileId, documentId, version) {
      return this.get('store').adapterFor('contracts/file').view(this.createVersionMetaData(fileId, documentId, version));
    },

    printDocumentVersion: function printDocumentVersion(fileId, documentId, version) {
      return this.get('store').adapterFor('contracts/file').print(this.createVersionMetaData(fileId, documentId, version));
    },

    createVersionMetaData: function createVersionMetaData(fileId, documentId, version) {
      return {
        id: fileId,
        version: version,
        documentId: documentId
      };
    }
  });
});