define("quantuvis-cm-spa/constants/key-code", ["exports"], function (exports) {
  var KEY_CODES = {
    ARROW_UP: 38,
    ARROW_DOWN: 40,
    F3: 114,
    F: 70,
    ESC: 27
  };

  exports["default"] = KEY_CODES;
});