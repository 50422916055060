define('quantuvis-cm-spa/models/workflows/event', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    date: _emberData['default'].attr('unix-timestamp'),
    executorId: _emberData['default'].attr('number'),
    document: _emberData['default'].attr(),
    attachments: _emberData['default'].hasMany('workflows/attachment', { inverse: null, async: false }),
    properties: _emberData['default'].hasMany('workflows/event-property', { inverse: null, async: false }),
    eventType: _emberData['default'].attr(),
    executor: _emberData['default'].belongsTo('user-management/users/extended'),
    company: _emberData['default'].attr()
  });
});