define('quantuvis-cm-spa/components/cm-profile-menu/cm-contact-quantuvis', ['exports', 'ember', 'ember-changeset', 'ember-changeset-validations', 'quantuvis-cm-spa/validations/contact-quantuvis'], function (exports, _ember, _emberChangeset, _emberChangesetValidations, _quantuvisCmSpaValidationsContactQuantuvis) {
  exports['default'] = _ember['default'].Component.extend({
    store: _ember['default'].inject.service(),
    contactsInfo: [{
      label: 'General Information',
      content: ['Quantuvis LLC', '1775 Tysons Blvd, 6th Floor', 'Tysons, VA 22102', '877-426-1099', 'info@quantuvis.net']
    }, {
      label: 'Sales',
      content: ['877-426-1099, press 2', 'sales@quantuvis.net']
    }, {
      label: 'Customer Support',
      content: ['877-426-1099, press 3', 'support@quantuvis.net']
    }],
    changeset: {},
    model: {},

    init: function init() {
      this._super.apply(this, arguments);
      this.set('model', {
        subject: null,
        message: null
      });

      this.initChangeset();
    },

    initChangeset: function initChangeset() {
      var changeset = new _emberChangeset['default'](this.get('model'), (0, _emberChangesetValidations['default'])(_quantuvisCmSpaValidationsContactQuantuvis['default']), _quantuvisCmSpaValidationsContactQuantuvis['default'], {
        skipValidate: true
      });

      this.set('changeset', changeset);
    },

    actions: {
      submit: function submit() {
        var _this = this;

        var changeset = this.get('changeset');

        changeset.save().then(function () {
          return _this.sendEmail();
        });
      }
    },

    sendEmail: function sendEmail() {
      var _this2 = this;

      this.set('contextObject.showDialogSpinner', true);
      var emailRecord = this.get('store').createRecord('emails/contact-quantuvis', this.get('model'));

      emailRecord.save().then(function () {
        _this2.sendAction('accepted');
      })['catch'](function () {
        _this2.sendAction('accepted');
      });
    }
  });
});