define("quantuvis-cm-spa/constants/contract-alert-type", ["exports"], function (exports) {
  var CONTRACT_ALERT_TYPE = {
    SINGLE_DEPARTMENT_REVIEW_ID: 1,
    MULTI_DEPARTMENT_REVIEW_ID: 2,
    CONTRACT_LEAD_REVIEW_ID: 3,
    COMPARE_AND_COMBINE_ID: 4,
    SIGN_OFF_NOTIFICATION_ID: 5,
    TERMINATED_ID: 6,
    EXECUTED_ID: 7,
    DEACTIVATED_ID: 8,
    EXPIRED_ID: 9,
    PENDING_NEXT_STEP_ID: 10,
    SIGN_OFF_IN_PROGRESS_ID: 11,
    CONTRACT_RECEIVED_ID: 12,
    PENDING_TERMINATION_ID: 13
  };

  exports.CONTRACT_ALERT_TYPE = CONTRACT_ALERT_TYPE;
});