define('quantuvis-cm-spa/mixins/accept-sign-off', ['exports', 'ember', 'quantuvis-cm-spa/mixins/initiate-sign-off'], function (exports, _ember, _quantuvisCmSpaMixinsInitiateSignOff) {
  exports['default'] = _ember['default'].Mixin.create(_quantuvisCmSpaMixinsInitiateSignOff['default'], {
    beforeModel: function beforeModel(transition) {
      var signOffId, signOff;
      return regeneratorRuntime.async(function beforeModel$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            signOffId = transition.params['accept-sign-off'].id;
            context$1$0.next = 3;
            return regeneratorRuntime.awrap(this.get('store').findRecord('signoff', signOffId, { reload: true }));

          case 3:
            signOff = context$1$0.sent;

            this.set('signOff', signOff);

            return context$1$0.abrupt('return', this.verifyPermissionsAndGetContractWithVersions(signOff.get('contractId')));

          case 6:
          case 'end':
            return context$1$0.stop();
        }
      }, null, this);
    }
  });
});