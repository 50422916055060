define('quantuvis-cm-spa/components/create-document/cm-upload-file', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: 'cm-upload-file',

    file: _ember['default'].inject.service(),
    // TODO move filetypes to constant and add dynamic fileTypeMatcher
    fileTypes: ['.pdf', '.doc', '.docx', '.odt', '.txt', '.rtf'],

    /**
     * @todo add tests for actions and
     * rendering all elements: progress bar, percentage, remove button
     */
    actions: {
      uploadDocumentTemplate: function uploadDocumentTemplate(fi, file) {
        this.get('file').uploadTemplate(fi, file, this.get('changeset'), { forceValidateField: 'templateComboValue' });
      },
      removeFile: function removeFile(changeset) {
        changeset.set('fileKey', null);
        changeset.set('fileName', null);
      },
      cancelUpload: function cancelUpload() {
        this.get('file').cancelUpload();
      }
    }
  });
});