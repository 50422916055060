define('quantuvis-cm-spa/components/cm-contract-details/sidebar-menus/history-menu', ['exports', 'ember', 'quantuvis-cm-spa/components/cm-contract-details/sidebar-menus/sidebar-base-menu', 'quantuvis-cm-spa/constants/permissions'], function (exports, _ember, _quantuvisCmSpaComponentsCmContractDetailsSidebarMenusSidebarBaseMenu, _quantuvisCmSpaConstantsPermissions) {
  exports['default'] = _quantuvisCmSpaComponentsCmContractDetailsSidebarMenusSidebarBaseMenu['default'].extend({
    PERMISSIONS: _quantuvisCmSpaConstantsPermissions.PERMISSIONS,
    classNames: ['history-menu'],
    contractDetails: _ember['default'].inject.service('contract-details'),
    store: _ember['default'].inject.service(),
    downloadFile: _ember['default'].inject.service(),
    loadingSpinner: _ember['default'].inject.service(),

    getHistoryExportUrl: function getHistoryExportUrl() {
      return this.get('store').findRecord('contracts/history/print', this.get('contractDetails.contractId'), { reload: true });
    },

    actions: {
      'export': function _export() {
        var _this = this;

        this.get('loadingSpinner').show('contract-details-spinner');

        this.getHistoryExportUrl().then(function (response) {
          _this.get('loadingSpinner').hide('contract-details-spinner');

          _this.get('downloadFile').download(response.get('url'));

          _this.get('store').unloadAll('contracts/history/print');
        });
      }
    }
  });
});