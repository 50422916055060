define('quantuvis-cm-spa/components/cm-send-document/recipients-list', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    sendDocument: _ember['default'].inject.service(),
    classNames: ['recipients-list'],
    tagName: 'ul',

    editIndexNumber: _ember['default'].computed('changesetToEditIndex', function () {
      var index = this.get('changesetToEditIndex');
      var editIndex = undefined;

      if (index) {
        editIndex = Number(index);
      }

      return editIndex;
    }),

    actions: {
      editItem: function editItem(item) {
        this.sendAction('onEditItem', item);
      },

      deleteItem: function deleteItem(index) {
        this.get('sendDocument').deleteItemFromRecipientsList(index);
      }
    }
  });
});