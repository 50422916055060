define('quantuvis-cm-spa/services/document-wizard', ['exports', 'ember', 'moment', 'quantuvis-cm-spa/constants/contract-type', 'quantuvis-cm-spa/constants/contract-origin', 'quantuvis-cm-spa/mixins/workflow-request-builder', 'quantuvis-cm-spa/constants/date-format', 'quantuvis-cm-spa/constants/create-document-file-source'], function (exports, _ember, _moment, _quantuvisCmSpaConstantsContractType, _quantuvisCmSpaConstantsContractOrigin, _quantuvisCmSpaMixinsWorkflowRequestBuilder, _quantuvisCmSpaConstantsDateFormat, _quantuvisCmSpaConstantsCreateDocumentFileSource) {
  exports['default'] = _ember['default'].Service.extend(_quantuvisCmSpaMixinsWorkflowRequestBuilder['default'], {
    store: _ember['default'].inject.service(),
    fileTemplateService: _ember['default'].inject.service(),
    workflowTemplate: _ember['default'].inject.service(),
    contactPerson: _ember['default'].inject.service(),

    model: null,
    prepopulatedProperties: {},
    wizardSteps: null,
    amendmentContract: null,

    activateMode: false,
    executedMode: false,

    init: function init() {
      this.setInitData();
    },

    initialiseAmendmentContract: function initialiseAmendmentContract(amendmentLinkId, contractTypeId) {
      var _this = this;

      if (parseInt(contractTypeId) === _quantuvisCmSpaConstantsContractType['default'].AMENDMENT && amendmentLinkId) {
        return this.get('store').findRecord('search/contract', amendmentLinkId, { reload: true }).then(function (contract) {
          return _this.set('amendmentContract', contract);
        });
      } else {
        this.set('amendmentContract', null);
      }
    },

    setInitData: function setInitData() {
      this.set('model', this.getEmptyModel());
      this.set('wizardSteps', this.getInitWizardSteps());
      this.get('workflowTemplate').resetTemplatesAndSteps();
    },

    clearModel: function clearModel() {
      var model = this.get('model');
      var emptyModel = this.getEmptyModel();

      for (var prop in model) {
        if (!this.isNotResettableProperty(prop)) {
          model[prop] = emptyModel[prop];
        }
      }

      this.get('workflowTemplate').resetSelectedTemplate();
    },

    isNotResettableProperty: function isNotResettableProperty(prop) {
      return prop === 'type';
    },

    getEmptyModel: function getEmptyModel() {
      return {
        contractOrigin: _quantuvisCmSpaConstantsContractOrigin['default'].CREATED_INTERNALLY,
        contractName: null,
        contractDescription: null,
        selectedBids: null,
        bids: null,
        lineOfBusiness: null,
        contractLead: null,
        startDate: null,
        endDate: null,
        evergreen: false,
        contractId: null,
        templateComboValue: _quantuvisCmSpaConstantsCreateDocumentFileSource['default'].UPLOAD,
        fileKey: null,
        fileName: null,
        company: null,
        contactPersons: [],
        templateId: null,
        steps: null,
        type: null,
        linkToContract: null,
        linkedDocuments: []
      };
    },

    getInitWizardSteps: function getInitWizardSteps() {
      return {
        company: {
          position: 1,
          name: 'Company Details',
          componentName: 'create-document/cm-company',
          isCompleted: false
        },
        general: {
          position: 2,
          name: 'Document Details',
          componentName: 'create-document/cm-general',
          isCompleted: false
        },
        workflow: {
          position: 3,
          name: 'Define Workflow',
          componentName: 'create-document/cm-workflow',
          isCompleted: false
        },
        amendmentGeneral: {
          position: 1,
          name: 'Document Details',
          componentName: 'create-document/cm-amendment-general',
          isCompleted: false
        }
      };
    },

    getWizardSteps: function getWizardSteps(documentType) {
      var steps = [];

      switch (this.normalizeDocumentType(documentType)) {
        case _quantuvisCmSpaConstantsContractType['default'].AMENDMENT:
          if (this.get('executedMode')) {
            steps = [this.wizardSteps.amendmentGeneral];
            break;
          }
          steps = [this.wizardSteps.amendmentGeneral, this.wizardSteps.workflow];
          break;
        default:
          if (this.get('executedMode')) {
            steps = [this.wizardSteps.company, this.wizardSteps.general];
            break;
          }
          steps = [this.wizardSteps.company, this.wizardSteps.general, this.wizardSteps.workflow];
          break;
      }

      return steps;
    },

    normalizeDocumentType: function normalizeDocumentType(documentType) {
      return parseInt(documentType);
    },

    saveContract: function saveContract() {
      var _this2 = this;

      var requestObj = this.getRequestObj();

      var contractCreatedPromise = new _ember['default'].RSVP.Promise(function (resolve, reject) {
        if (_this2.get('model.templateComboValue') === _quantuvisCmSpaConstantsCreateDocumentFileSource['default'].SELECT) {
          _this2.saveContractWithApplyingTemplate(requestObj, resolve, reject);
        } else {
          _this2.saveContractBySpecificMode(requestObj, resolve, reject);
        }
      });

      return contractCreatedPromise.then(function (obj) {
        _this2.get('store').unloadAll('contract');
        _this2.get('fileTemplateService').set('selectedTemplateId', null);

        return obj;
      });
    },

    saveContractWithApplyingTemplate: function saveContractWithApplyingTemplate(requestObj, resolve, reject) {
      var _this3 = this;

      this.get('store').adapterFor('application').post('contracts/file-templates/apply', this.get('fileTemplateService.selectedTemplateId'), { data: requestObj }).then(function (_ref) {
        var data = _ref.data;

        requestObj.startDate = _moment['default'].utc(requestObj.startDate).format(_quantuvisCmSpaConstantsDateFormat['default'].MM_DD_YYYY);
        requestObj.endDate = _moment['default'].utc(requestObj.endDate).format(_quantuvisCmSpaConstantsDateFormat['default'].MM_DD_YYYY);
        requestObj.file.set('key', data.name);

        _this3.saveContractBySpecificMode(requestObj, resolve, reject);
      })['catch'](function (error) {
        return reject(error);
      });
    },

    saveContractBySpecificMode: function saveContractBySpecificMode(requestObj, resolve, reject) {
      var documentPromise = undefined;

      if (this.get('activateMode')) {
        documentPromise = this.activateDocument(requestObj);
      } else if (this.get('executedMode')) {
        documentPromise = this.createExecutedContract(requestObj);
      } else {
        documentPromise = this.createContract(requestObj);
      }

      documentPromise.then(function (contract) {
        resolve(contract);
      })['catch'](function (error) {
        reject(error);
      });
    },

    activateDocument: function activateDocument(requestObj) {
      return this.get('store').adapterFor('application').put('contracts/new-activate', this.get('model.id'), { data: requestObj });
    },

    createExecutedContract: function createExecutedContract(requestObj) {
      var contract = this.get('store').createRecord('contracts/executed-contract', requestObj);

      return contract.save();
    },

    createContract: function createContract(requestObj) {
      var contract = this.get('store').createRecord('contract', requestObj);

      return contract.save();
    },

    getRequestObj: function getRequestObj() {
      var model = this.get('model');

      var type = { id: model.type.id };
      var origin = { id: model.contractOrigin };

      var workflowSteps = this.toPlainObject(this.get('model.steps'));
      var workflow = this.get('executedMode') ? null : { steps: workflowSteps };
      var contactPersons = [];
      var companyId = null;
      var lineOfBusiness = null;
      var contractId = undefined;

      if (parseInt(model.type.id) === _quantuvisCmSpaConstantsContractType['default'].AMENDMENT) {
        contractId = model.linkToContract.id;
      } else {
        companyId = model.company.id;
        contactPersons = this.getContactPersons(model.contactPersons, companyId);
        lineOfBusiness = { id: model.lineOfBusiness.id };
      }

      var bids = model.selectedBids !== null ? this.getBids([model.selectedBids]) : null;
      var leadIds = model.contractLead.map(function (lead) {
        return lead.id;
      });
      var file = this.createFile(model.fileKey, model.fileName);

      var linkedDocuments = this.getLinkedDocuments(model.linkedDocuments);

      return {
        number: model.contractId,
        bids: bids,
        name: model.contractName,
        description: model.contractDescription,
        origin: origin,
        evergreen: model.evergreen,
        file: file,
        startDate: parseInt(_moment['default'].utc(model.startDate).format('x')),
        endDate: parseInt(_moment['default'].utc(model.endDate).format('x')),
        companyId: companyId,
        lineOfBusiness: lineOfBusiness,
        leadIds: leadIds,
        type: type,
        linkedDocuments: linkedDocuments,
        contactPersons: contactPersons,
        contractId: contractId,
        workflow: workflow
      };
    },

    createFile: function createFile(key, name) {
      return this.get('store').createRecord('contracts/file', { key: key, name: name });
    },

    getLinkedDocuments: function getLinkedDocuments(linkedDocuments) {
      return linkedDocuments ? linkedDocuments.map(function (document) {
        return { id: document.get('id') };
      }) : [];
    },

    getContactPersons: function getContactPersons(contactPersons, companyId) {
      var _this4 = this;

      return contactPersons.map(function (contactPerson) {
        var contactPersonClone = _ember['default'].copy(contactPerson);

        var isContactPersonChanged = _this4.get('contactPerson').isContactPersonChanged(contactPersonClone);

        contactPersonClone.personId = isContactPersonChanged ? null : contactPersonClone.id;
        contactPersonClone.id = null;
        contactPersonClone.companyId = companyId;
        contactPersonClone.name = contactPersonClone.contactPerson.name ? contactPersonClone.contactPerson.name : contactPersonClone.contactPerson;
        contactPersonClone.contactPerson = null;

        return contactPersonClone;
      });
    },

    getBids: function getBids(bids) {
      return bids.map(function (bid) {
        return {
          id: null,
          bidId: bid.get('id'),
          title: bid.get('title'),
          contractEvergreen: bid.get('contractEvergreen'),
          contractStartDate: parseInt(_moment['default'].utc(bid.get('contractStartDate')).format('x')),
          contractEndDate: parseInt(_moment['default'].utc(bid.get('contractEndDate')).format('x')),
          lineOfBusiness: { id: bid.get('lineOfBusiness').id }
        };
      });
    },

    processActivateContractId: function processActivateContractId(documentId) {
      if (documentId) {
        return Promise.all([this.receivePrepopulatedProperties(documentId)]);
      } else {
        return Promise.resolve();
      }
    },

    receivePrepopulatedProperties: function receivePrepopulatedProperties(documentId) {
      var _this5 = this;

      this.set('activateMode', true);

      return this.get('store').findRecord('contract', documentId).then(function (model) {
        if (model.get('type.id') === _quantuvisCmSpaConstantsContractType['default'].AMENDMENT) {
          model.set('lineOfBusiness', null);
        } else {
          _this5.set('prepopulatedProperties.lineOfBusinessId', model.get('lineOfBusiness.id').toString());
        }

        _this5.setProperties({
          'prepopulatedProperties.companyId': model.get('company.id').toString(),
          'prepopulatedProperties.evergreen': model.get('evergreen') ? 'true' : 'false',
          'model': _this5.getPredefinedModel(model)
        });
      });
    },

    getPredefinedModel: function getPredefinedModel(model) {
      return {
        id: model.get('id'),
        contractOrigin: model.get('origin.id'),
        lineOfBusiness: model.get('lineOfBusiness'),
        startDate: (0, _moment['default'])(model.get('startDate')).format(_quantuvisCmSpaConstantsDateFormat['default'].MM_DD_YYYY),
        endDate: (0, _moment['default'])(model.get('endDate')).format(_quantuvisCmSpaConstantsDateFormat['default'].MM_DD_YYYY),
        evergreen: model.get('evergreen'),
        templateComboValue: 'upload',
        fileKey: model.get('file.key'),
        fileName: model.get('file.name'),
        contactPersons: [],
        type: model.get('type')
      };
    },

    clearPrepopulatedValues: function clearPrepopulatedValues() {
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _len = arguments.length, properties = Array(_len), _key = 0; _key < _len; _key++) {
          properties[_key] = arguments[_key];
        }

        for (var _iterator = properties[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var prop = _step.value;

          this.get('prepopulatedProperties')[prop] = undefined;
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator['return']) {
            _iterator['return']();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }
    }
  });
});