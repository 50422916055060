define('quantuvis-cm-spa/adapters/user-management/company', ['exports', 'ember', 'quantuvis-cm-spa/adapters/application', 'quantuvis-cm-spa/constants/permissions'], function (exports, _ember, _quantuvisCmSpaAdaptersApplication, _quantuvisCmSpaConstantsPermissions) {
  exports['default'] = _quantuvisCmSpaAdaptersApplication['default'].extend({
    permissionService: _ember['default'].inject.service(),

    urlForQuery: function urlForQuery() {
      var isQuantuvisAdmin = this.get('permissionService').checkAdminPermissionByApplication(_quantuvisCmSpaConstantsPermissions.PERMISSIONS.ID.SA_READ, _quantuvisCmSpaConstantsPermissions.PERMISSIONS.APPLICATION.UM);

      var partnersUrl = this.urlPrefix() + '/user-management/companies/self/partners';
      var companiesUrl = this.urlPrefix() + '/user-management/companies';

      return isQuantuvisAdmin ? companiesUrl : partnersUrl;
    }
  });
});