define('quantuvis-cm-spa/validations/document-summary', ['exports', 'ember-changeset-validations/validators', 'quantuvis-cm-spa/validations/custom-validators'], function (exports, _emberChangesetValidationsValidators, _quantuvisCmSpaValidationsCustomValidators) {
  exports['default'] = {
    name: [(0, _emberChangesetValidationsValidators.validatePresence)(true), (0, _emberChangesetValidationsValidators.validateLength)({ max: 150 }), (0, _emberChangesetValidationsValidators.validateFormat)({ regex: /^[\w\d!\-_.*'() ]+$/ })],
    number: [(0, _emberChangesetValidationsValidators.validatePresence)(true), (0, _emberChangesetValidationsValidators.validateLength)({ max: 25 })],
    lineOfBusiness: [(0, _emberChangesetValidationsValidators.validatePresence)(true)],
    startDate: [(0, _emberChangesetValidationsValidators.validatePresence)(true), (0, _quantuvisCmSpaValidationsCustomValidators.validateDateFormat)(), (0, _quantuvisCmSpaValidationsCustomValidators.validateAmendmentStartDate)()],
    endDate: [(0, _emberChangesetValidationsValidators.validatePresence)(true), (0, _quantuvisCmSpaValidationsCustomValidators.validateDateFormat)(), (0, _quantuvisCmSpaValidationsCustomValidators.validateEndDate)(), (0, _quantuvisCmSpaValidationsCustomValidators.validateAmendmentEndDate)()],
    evergreen: [(0, _emberChangesetValidationsValidators.validatePresence)(true)],
    description: [(0, _emberChangesetValidationsValidators.validateLength)({ allowBlank: true, max: 255 })],
    leads: [(0, _emberChangesetValidationsValidators.validatePresence)(true), (0, _emberChangesetValidationsValidators.validateLength)({ max: 25 })]
  };
});