define('quantuvis-cm-spa/components/cm-notes/cm-notes-content', ['exports', 'ember', 'quantuvis-cm-spa/constants/permissions'], function (exports, _ember, _quantuvisCmSpaConstantsPermissions) {
  exports['default'] = _ember['default'].Component.extend({
    PERMISSIONS: _quantuvisCmSpaConstantsPermissions.PERMISSIONS,
    classNames: ['cm-notes-content'],
    contractDetails: _ember['default'].inject.service('contract-details'),
    emptyListMessage: 'There are no notes for this contract',

    init: function init() {
      this._super.apply(this, arguments);
      this.get('contractDetails').getContractNotes();
    }
  });
});