define('quantuvis-cm-spa/components/cm-contract-expand-block', ['exports', 'ember', 'quantuvis-cm-spa/constants/tooltip-messages'], function (exports, _ember, _quantuvisCmSpaConstantsTooltipMessages) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['cm-contract-expand-block'],
    tagName: 'div',
    TOOLTIP_MESSAGES: _quantuvisCmSpaConstantsTooltipMessages['default'],
    actions: {
      toggleRow: function toggleRow(index) {
        if (this.get('_expandedRowIndexes') && this.get('_expandedRowIndexes').includes(index)) {
          this.sendAction('collapseRow', index);
        } else {
          this.sendAction('expandRow', index);
        }
      }
    }
  });
});