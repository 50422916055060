define('quantuvis-cm-spa/services/workflow-step-menu', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Service.extend({
    store: _ember['default'].inject.service(),
    alertService: _ember['default'].inject.service(),
    contractDetails: _ember['default'].inject.service(),
    loadingSpinner: _ember['default'].inject.service(),
    spinnerId: 'contract-details-spinner',

    showSpinner: function showSpinner() {
      this.get('loadingSpinner').show(this.get('spinnerId'));
    },
    hideSpinner: function hideSpinner() {
      this.get('loadingSpinner').hide(this.get('spinnerId'));
    },

    voidStep: function voidStep(stepId) {
      var _this = this;

      this.showSpinner();
      this.get('store').adapterFor('workflows/steps/change-state').put('workflows/steps/' + stepId + '/void', '').then(function () {
        _this.get('contractDetails').loadTimelineItems();
        _this.get('alertService').loadAlerts();
        _this.hideSpinner();
      })['catch'](function () {
        _this.hideSpinner();
      });
    },

    completeStep: function completeStep(stepId) {
      var _this2 = this;

      this.showSpinner();
      this.get('store').adapterFor('workflows/steps/change-state').put('workflows/steps/' + stepId + '/complete', '').then(function () {
        _this2.get('contractDetails').loadTimelineItems();
        _this2.get('alertService').loadAlerts();
        _this2.hideSpinner();
      })['catch'](function () {
        _this2.hideSpinner();
      });
    },

    completeSubstep: function completeSubstep(substepId) {
      var _this3 = this;

      this.showSpinner();
      this.get('store').adapterFor('workflows/steps/change-state').put('workflows/substeps/' + substepId + '/complete', '').then(function () {
        _this3.get('contractDetails').loadTimelineItems();
        _this3.get('alertService').loadAlerts();
        _this3.hideSpinner();
      })['catch'](function () {
        _this3.hideSpinner();
      });
    },

    voidSubstep: function voidSubstep(substepId) {
      var _this4 = this;

      this.showSpinner();
      this.get('store').adapterFor('workflows/steps/change-state').put('workflows/substeps/' + substepId + '/void', '').then(function () {
        _this4.get('contractDetails').loadTimelineItems();
        _this4.get('alertService').loadAlerts();
        _this4.hideSpinner();
      })['catch'](function () {
        _this4.hideSpinner();
      });
    },

    deleteWorkflowStep: function deleteWorkflowStep(id) {
      var _this5 = this;

      this.showSpinner();
      this.get('store').adapterFor('workflows/step').remove('workflows/step', id).then(function () {
        _this5.get('contractDetails').loadTimelineItems();
        _this5.get('alertService').loadAlerts();
        _this5.hideSpinner();
      })['catch'](function () {
        _this5.hideSpinner();
      });
    }
  });
});