define('quantuvis-cm-spa/services/signature-status-service', ['exports', 'ember', 'quantuvis-cm-spa/constants/signature-status'], function (exports, _ember, _quantuvisCmSpaConstantsSignatureStatus) {
  exports['default'] = _ember['default'].Service.extend({
    processSigners: function processSigners(signers) {
      var _this = this;

      return signers.map(function (signer) {
        _this.defineStateText(signer);

        return signer;
      });
    },

    defineStateText: function defineStateText(signer) {
      signer.stateText = _quantuvisCmSpaConstantsSignatureStatus.SIGNATURE_STATUS_NAMES[signer.statusId] + ' by ' + signer.name;
    }
  });
});