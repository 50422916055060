define('quantuvis-cm-spa/components/sign-off/accept-form', ['exports', 'ember', 'moment', 'ember-changeset', 'ember-changeset-validations', 'quantuvis-cm-spa/constants/sign-off/tooltip-messages', 'quantuvis-cm-spa/validations/accept-sign-off', 'quantuvis-cm-spa/components/sign-off/form'], function (exports, _ember, _moment, _emberChangeset, _emberChangesetValidations, _quantuvisCmSpaConstantsSignOffTooltipMessages, _quantuvisCmSpaValidationsAcceptSignOff, _quantuvisCmSpaComponentsSignOffForm) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  var CHANGESETS_KEYS = {
    internalParty: 'internal-party',
    externalParty: 'external-party'
  };

  exports['default'] = _quantuvisCmSpaComponentsSignOffForm['default'].extend({
    layoutName: 'components/sign-off/form',

    acceptSignOff: _ember['default'].inject.service(),
    timelineAttachment: _ember['default'].inject.service(),
    acceptSignOffValidator: _ember['default'].inject.service(),

    tooltipMessages: _quantuvisCmSpaConstantsSignOffTooltipMessages['default'],

    changesetModel: {
      documentVersion: {},
      parties: [],
      dueDate: '',
      subject: '',
      message: ''
    },

    init: function init() {
      this._super.apply(this, arguments);

      this.initChangeset();
      this.setCompanies();
      this.prepopulateSelectedVersion();
      this.prepopulateSelectedDueDate();
      this.generateAndSetPartiesToChangeset();
    },

    initChangeset: function initChangeset() {
      var changeset = new _emberChangeset['default'](this.get('changesetModel'), (0, _emberChangesetValidations['default'])(_quantuvisCmSpaValidationsAcceptSignOff['default']), _quantuvisCmSpaValidationsAcceptSignOff['default'], {
        skipValidate: true
      });

      this.set('changeset', changeset);
      this.get('acceptSignOffValidator').add(this.elementId, changeset);
    },

    prepopulateSelectedVersion: function prepopulateSelectedVersion() {
      var _this = this;

      var timelineAttachmentService = this.get('timelineAttachment');
      var preparedVersions = this.get('timelineAttachment').prepareVersionsForDropdown(this.get('versions'));
      var selectedVersion = preparedVersions.find(function (version) {
        return Number(version.get('id')) === Number(_this.get('signOff.attachmentId'));
      });

      this.set('changeset.documentVersion', timelineAttachmentService.prepareLastVersion(selectedVersion));
    },

    prepopulateSelectedDueDate: function prepopulateSelectedDueDate() {
      var selectedDueDate = this.get('signOff.dueDate');

      this.set('changeset.dueDate', _moment['default'].utc(selectedDueDate).format('MM/DD/YYYY'));
    },

    setCompanies: function setCompanies() {
      this.setProperties({
        internalCompany: this.get('account'),
        externalCompany: _ember['default'].Object.create(this.get('contract.company'))
      });
    },

    generateAndSetPartiesToChangeset: function generateAndSetPartiesToChangeset() {
      var acceptSignOffValidator = this.get('acceptSignOffValidator');

      var _getProperties = this.getProperties('internalCompany', 'externalCompany');

      var internalCompany = _getProperties.internalCompany;
      var externalCompany = _getProperties.externalCompany;

      var parties = this.get('acceptSignOff').generateParties({
        companies: [internalCompany, externalCompany],
        internalCompanyId: internalCompany.get('id')
      });

      var _parties = _slicedToArray(parties, 2);

      var internalParty = _parties[0];
      var externalParty = _parties[1];

      acceptSignOffValidator.add(CHANGESETS_KEYS.internalParty, internalParty);
      acceptSignOffValidator.add(CHANGESETS_KEYS.externalParty, externalParty);

      this.setProperties({
        internalParty: internalParty,
        externalParty: externalParty,
        'changeset.parties': parties
      });
    },

    actions: {
      submit: function submit() {
        var data = this.get('acceptSignOff').generateDataToSave({
          changeset: this.get('changeset'),
          originContractId: this.get('contract.id')
        });

        this.sendAction('onSubmit', data);
      }
    }
  });
});