define('quantuvis-cm-spa/services/sign-off', ['exports', 'ember', 'moment', 'quantuvis-cm-spa/constants/date-format'], function (exports, _ember, _moment, _quantuvisCmSpaConstantsDateFormat) {
  exports['default'] = _ember['default'].Service.extend({
    store: _ember['default'].inject.service(),
    dialog: _ember['default'].inject.service(),
    window: _ember['default'].inject.service(),
    userSession: _ember['default'].inject.service(),
    profile: _ember['default'].inject.service(),

    sendExternallyMode: false,

    save: function save(data) {
      var record = this.get('store').createRecord('initiate-sign-off', data);

      return record.save();
    },

    getQuote: function getQuote(contractId, companyId) {
      return this.get('store').adapterFor('signoff').getQuote(contractId).then(function (_ref) {
        var data = _ref.data;

        return data.find(function (quote) {
          return Number(quote.companyId) === companyId;
        });
      });
    },

    request: function request(data) {
      var record = this.get('store').createRecord('initiate-sign-off-request', data);

      return record.save();
    },

    accept: function accept(signOffId, data) {
      return this.get('store').adapterFor('initiate-sign-off').post('signoff/' + signOffId + '/accept', null, { data: data });
    },

    cancel: function cancel(signOffId, data) {
      return this.get('store').adapterFor('initiate-sign-off').post('signoff/' + signOffId + '/cancel', null, { data: data });
    },

    edit: function edit(signOffId, contractId) {
      var lock, expirationDate;
      return regeneratorRuntime.async(function edit$(context$1$0) {
        var _this = this;

        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            context$1$0.next = 2;
            return regeneratorRuntime.awrap(this.getLock(contractId));

          case 2:
            lock = context$1$0.sent;
            expirationDate = lock.get('expirationDate');

            if (!expirationDate) {
              context$1$0.next = 9;
              break;
            }

            this.showLockNotification(expirationDate);

            return context$1$0.abrupt('return', Promise.reject());

          case 9:
            return context$1$0.abrupt('return', this.get('userSession').refreshToken().then(function () {
              return _this.get('store').adapterFor('signoff').edit(signOffId).then(function (_ref2) {
                var data = _ref2.data;

                _this.get('window').getLocation().href = data.correctViewUrl;
              });
            }));

          case 10:
          case 'end':
            return context$1$0.stop();
        }
      }, null, this);
    },

    showLockNotification: function showLockNotification(expirationDate) {
      var date = _moment['default'].utc(expirationDate, _quantuvisCmSpaConstantsDateFormat['default'].ISO_DATETIME).tz(this.get('profile').getUserTimezone());

      var options = {
        title: 'Notification',
        confirmMessage: 'Sign off for this document locked by DocuSign until ' + date.format(_quantuvisCmSpaConstantsDateFormat['default'].DATETIME),
        hideDeclineButton: true
      };

      this.get('dialog').show('dialogs/cm-dialog', 'dialog-templates/cm-confirmation', this, options);
    },

    getLock: function getLock(contractId) {
      this.get('store').unloadAll('signoff/contract-signoff/lock');

      return this.get('store').findRecord('signoff/contract-signoff/lock', contractId, { reload: true });
    },

    resendEmails: function resendEmails(id) {
      return this.get('store').adapterFor('signoff').resendEmails(id);
    }
  });
});