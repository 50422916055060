define('quantuvis-cm-spa/services/search', ['exports', 'ember', 'quantuvis-cm-spa/models/search/attribute', 'quantuvis-cm-spa/models/search/option', 'quantuvis-cm-spa/constants/contract-status', 'quantuvis-cm-spa/constants/search-attributes'], function (exports, _ember, _quantuvisCmSpaModelsSearchAttribute, _quantuvisCmSpaModelsSearchOption, _quantuvisCmSpaConstantsContractStatus, _quantuvisCmSpaConstantsSearchAttributes) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  var DEFAULT_STATUS_FILTER = [_quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.NEW, _quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.IN_REVIEW, _quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.IN_SIGNATURE, _quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.PARTIALLY_EXECUTED_INTERNALLY, _quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.PARTIALLY_EXECUTED_EXTERNALLY, _quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.EXECUTED];

  exports['default'] = _ember['default'].Service.extend({
    profile: _ember['default'].inject.service(),
    store: _ember['default'].inject.service(),
    queryParams: null,
    attributes: new Map(),
    textSearch: null,
    dateRangeSearch: {
      startDateFrom: null,
      startDateTo: null,
      endDateFrom: null,
      endDateTo: null
    },
    dashboardTextSearchModeEnabled: false,
    totalSubElements: 0,
    totalElements: 0,
    accounts: [],
    accountsFilterActive: false,
    searchInVersion: false,

    statusesFilterInitiated: false,

    init: function init() {
      this.createAttributes();
    },

    setQueryParams: function setQueryParams(params) {
      this.queryParams = params;
      this.dateRangeSearch.startDateTo = params.startDateTo;
      this.dateRangeSearch.endDateFrom = params.endDateFrom;
    },

    createAttributes: function createAttributes() {
      this.addAttribute(new _quantuvisCmSpaModelsSearchAttribute['default'](_quantuvisCmSpaConstantsSearchAttributes['default'].STATUS.ID, _quantuvisCmSpaConstantsSearchAttributes['default'].STATUS.LABEL, _quantuvisCmSpaConstantsSearchAttributes['default'].STATUS.POSITION, true, false));

      this.addAttribute(new _quantuvisCmSpaModelsSearchAttribute['default'](_quantuvisCmSpaConstantsSearchAttributes['default'].COMPANY.ID, _quantuvisCmSpaConstantsSearchAttributes['default'].COMPANY.LABEL, _quantuvisCmSpaConstantsSearchAttributes['default'].COMPANY.POSITION, true, false, function (a, b) {
        if (a.id === _quantuvisCmSpaModelsSearchOption.ALL_OPTION_ID) return -1;
        if (b.id === _quantuvisCmSpaModelsSearchOption.ALL_OPTION_ID) return 1;

        return b.isActive - a.isActive || a.name.localeCompare(b.name);
      }));

      this.addAttribute(new _quantuvisCmSpaModelsSearchAttribute['default'](_quantuvisCmSpaConstantsSearchAttributes['default'].TYPE.ID, _quantuvisCmSpaConstantsSearchAttributes['default'].TYPE.LABEL, _quantuvisCmSpaConstantsSearchAttributes['default'].TYPE.POSITION));

      this.addAttribute(new _quantuvisCmSpaModelsSearchAttribute['default'](_quantuvisCmSpaConstantsSearchAttributes['default'].LINE_OF_BUSINESS.ID, _quantuvisCmSpaConstantsSearchAttributes['default'].LINE_OF_BUSINESS.LABEL, _quantuvisCmSpaConstantsSearchAttributes['default'].LINE_OF_BUSINESS.POSITION));

      this.addAttribute(new _quantuvisCmSpaModelsSearchAttribute['default'](_quantuvisCmSpaConstantsSearchAttributes['default'].LEAD.ID, _quantuvisCmSpaConstantsSearchAttributes['default'].LEAD.LABEL, _quantuvisCmSpaConstantsSearchAttributes['default'].LEAD.POSITION));
    },

    updateByMyAccounts: function updateByMyAccounts(accounts) {
      var _this = this;

      this.set('accounts', accounts);
      this.set('accountsFilterActive', _ember['default'].isPresent(accounts));

      return this.fetchAttributesOptions().then(function () {
        var attribute = _this.getAttribute(_quantuvisCmSpaConstantsSearchAttributes['default'].COMPANY.ID);

        if (_this.get('accountsFilterActive')) {
          attribute.selectOptions(accounts);
        } else {
          attribute.reset();
          attribute.toggleShowAll(true);
        }
      });
    },

    /**
     * On option change listener
     *
     * @param {AttributeOption} option
     * @param {Attribute} attribute
     */
    onChange: function onChange(option, attribute) {
      if (option.isActive) {
        attribute.toggleOption(option);
        this.fireUpdated();
      }
    },

    /**
     * Fire manually attributes changed event
     * (Performance reason)
     */
    fireUpdated: function fireUpdated() {
      this.notifyPropertyChange('attributes');
    },

    fireTextSearchUpdated: function fireTextSearchUpdated() {
      this.set('dashboardTextSearchModeEnabled', false);
      this.notifyPropertyChange('textSearch');
    },

    /**
     * Add search attribute
     *
     * @param {Attribute} attribute
     */
    addAttribute: function addAttribute(attribute) {
      this.attributes.set(attribute.id, attribute);
    },

    getAttribute: function getAttribute(id) {
      return this.attributes.get(id);
    },

    /**
     * Reset search attributes
     */
    reset: function reset() {
      this.attributes.forEach(function (attribute) {
        attribute.reset();
        attribute.toggleShowAll(true);
      });
      this.set('dateRangeSearch', {
        startDateFrom: null,
        startDateTo: null,
        endDateFrom: null,
        endDateTo: null
      });
      this.fireUpdated();
    },

    /**
     * Retrieve search attributes collection
     */
    fetchAttributesOptions: function fetchAttributesOptions() {
      var _this2 = this;

      return _ember['default'].RSVP.hash([this.fetchStatusOptions(), this.fetchCompanyOptions(), this.fetchTypeOptions(), this.fetchLeadOptions(), this.fetchLineOfBusinessOptions()]).then(function () {
        return _this2.sortAttributesByPriority(_this2.attributes);
      });
    },

    sortAttributesByPriority: function sortAttributesByPriority(attributes) {
      return Array.from(attributes.values()).sort(function (prev, next) {
        return prev.position - next.position;
      });
    },

    fetchCompanyOptions: function fetchCompanyOptions() {
      var _this3 = this;

      var attribute = this.getAttribute(_quantuvisCmSpaConstantsSearchAttributes['default'].COMPANY.ID);
      var selection = attribute.getAttributeSelection();

      if (attribute.options.get(_quantuvisCmSpaModelsSearchOption.ALL_OPTION_ID).isSelected) {
        selection = [];
      }

      return this.fetchOptions(attribute, 'user-management/company', 'search/company', 'name').then(function () {
        _this3.restoreAttributeSelection(attribute, selection);
      });
    },

    fetchLeadOptions: function fetchLeadOptions() {
      var _this4 = this;

      var attribute = this.getAttribute(_quantuvisCmSpaConstantsSearchAttributes['default'].LEAD.ID);
      var selection = attribute.getAttributeSelection();

      return this.fetchOptions(attribute, 'user-management/user', 'search/lead', 'fullName', null, null).then(function () {
        _this4.restoreAttributeSelection(attribute, selection);
      });
    },

    fetchTypeOptions: function fetchTypeOptions() {
      var _this5 = this;

      var attribute = this.getAttribute(_quantuvisCmSpaConstantsSearchAttributes['default'].TYPE.ID);
      var selection = attribute.getAttributeSelection();

      return this.fetchOptions(attribute, 'contracts/types/available-type', 'search/type', 'name').then(function () {
        _this5.restoreAttributeSelection(attribute, selection);
      });
    },

    fetchStatusOptions: function fetchStatusOptions() {
      var _this6 = this;

      var attribute = this.getAttribute(_quantuvisCmSpaConstantsSearchAttributes['default'].STATUS.ID);
      var selection = attribute.getAttributeSelection();

      return this.fetchOptions(attribute, 'contracts/status', 'search/status', 'name', function (optionModel) {
        return parseInt(optionModel.id) !== _quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.DELETED;
      }).then(function () {
        _this6.get('statusesFilterInitiated') ? _this6.restoreAttributeSelection(attribute, selection) : _this6.applyDefaultStatusesFilter(attribute);
      });
    },

    fetchLineOfBusinessOptions: function fetchLineOfBusinessOptions() {
      var _this7 = this;

      var attribute = this.getAttribute(_quantuvisCmSpaConstantsSearchAttributes['default'].LINE_OF_BUSINESS.ID);
      var selection = attribute.getAttributeSelection();

      return this.fetchOptions(attribute, 'contracts/line-of-business', 'search/line-of-business', 'name').then(function () {
        _this7.restoreAttributeSelection(attribute, selection);
      });
    },

    /**
     * Fetch attribute options
     *
     * @param attribute                 Attribute to populate with options
     * @param optionsModel              Options model name for store
     * @param aggregatedOptionsModel    Aggregated options model name for store
     * @param labelProperty             Property for option label in search panel
     * @param filter                    Filter function (default undefined)
     *                                  returns bool
     * @param query                     Additional Query for options
     */
    fetchOptions: function fetchOptions(attribute, optionsModel, aggregatedOptionsModel, labelProperty, filter, query) {
      var _this8 = this;

      return this.get('store').query(optionsModel, query || {}).then(function (options) {
        return _this8.get('store').query(aggregatedOptionsModel, _this8.getQuery()).then(function (aggregated) {
          var availableOptions = _this8.getAvailableOptions(aggregated);

          attribute.clearOptions();

          options.forEach(function (optionModel) {
            var isActive = availableOptions.includes(optionModel.id) || _this8.get('accounts').includes(optionModel.id);

            if (!_this8.get('accountsFilterActive') || isActive) {

              filter ? _this8.addAttributeOptionByFilter(attribute, optionModel, labelProperty, isActive, filter) : _this8.addAttributeOption(attribute, optionModel, labelProperty, isActive, filter);
            }
          });

          _this8.preselectOptionsByQueryParams(attribute);
        });
      });
    },

    addAttributeOptionByFilter: function addAttributeOptionByFilter(attribute, optionModel, labelProperty, isActive, filter) {
      if (filter(optionModel)) {
        this.addAttributeOption(attribute, optionModel, labelProperty, isActive);
      }
    },

    addAttributeOption: function addAttributeOption(attribute, optionModel, labelProperty, isActive) {
      attribute.addOption(new _quantuvisCmSpaModelsSearchOption.AttributeOption(optionModel.id, optionModel.get(labelProperty), false, isActive));
    },

    preselectOptionsByQueryParams: function preselectOptionsByQueryParams(attribute) {
      if (this.queryParams && this.queryParams[attribute.id]) {
        attribute.selectOptions(this.queryParams[attribute.id].split(','));
      }
    },

    restoreAttributeSelection: function restoreAttributeSelection(attribute, selection) {
      if (!_ember['default'].isEmpty(selection)) {
        attribute.selectOptions(selection);
      }
    },

    applyDefaultStatusesFilter: function applyDefaultStatusesFilter(attribute) {
      var _this9 = this;

      var defaultOptions = Array.from(attribute.options).filter(function (_ref) {
        var _ref2 = _slicedToArray(_ref, 2);

        var option = _ref2[1];
        return _this9.isSelectedStatus(option);
      }).map(function (_ref3) {
        var _ref32 = _slicedToArray(_ref3, 1);

        var id = _ref32[0];
        return id;
      });

      attribute.selectOptions(defaultOptions);
      this.set('statusesFilterInitiated', true);
    },

    isSelectedStatus: function isSelectedStatus(option) {
      return option.isActive && DEFAULT_STATUS_FILTER.includes(parseInt(option.id));
    },

    getAvailableOptions: function getAvailableOptions(aggregated) {
      return aggregated.map(function (aggregatedOption) {
        return aggregatedOption.get('id');
      });
    },

    getQuery: function getQuery() {
      var query = {};

      if (this.get('accountsFilterActive')) {
        Object.assign(query, { companies: this.get('accounts').join(',') });
      }

      return query;
    }
  });
});