define('quantuvis-cm-spa/controllers/create-contract', ['exports', 'ember'], function (exports, _ember) {

  // TODO: add tests for 'handleCancelClick' and 'navigateToContractsOrDetails'
  // and for 'handleBackClick' on first step (calling 'navigateToContractsOrDetails')
  exports['default'] = _ember['default'].Controller.extend({
    queryParams: ['amendmentLinkId', 'activateContractId', 'executed'],
    currentStepIndex: 0,
    forcedStepIndex: null,
    dialog: _ember['default'].inject.service(),
    documentWizard: _ember['default'].inject.service(),
    workflow: _ember['default'].inject.service('workflow-template'),

    actions: {
      handleBackClick: function handleBackClick() {
        var currentToForcedStepOffset = 1;
        var currentStepIndex = this.get('currentStepIndex');

        if (currentStepIndex === 0) {
          this.send('navigateToContractsOrDetails');
        } else {
          this.set('forcedStepIndex', this.get('currentStepIndex') - currentToForcedStepOffset);
        }
      },
      handleCancelClick: function handleCancelClick() {
        this.send('navigateToContractsOrDetails');
      },
      navigateToContractsOrDetails: function navigateToContractsOrDetails() {
        var _this = this;

        var options = {
          confirmMessage: 'Are you sure you want to navigate out of the wizard?\n\n          All the data already entered will be lost.'
        };

        this.get('dialog').show('dialogs/cm-dialog', 'dialog-templates/cm-confirmation', this, options).then(function () {
          var amendmentContract = _this.get('documentWizard').get('amendmentContract');
          var transitionPromise = amendmentContract ? _this.transitionToRoute('contract-details', amendmentContract.get('id')) : _this.transitionToRoute('contracts');

          transitionPromise.then(function () {
            _this.get('documentWizard').setInitData();
            _this.get('workflow').reloadWorkflowFromTemplate();
            _this.set('currentStepIndex', 0);
          });
        })['catch'](function () {});
      },
      handleWizardFinish: function handleWizardFinish(response) {
        var _this2 = this;

        if (response) {
          var contractId = response.id || response.data.id;

          this.transitionToRoute('contract-details', contractId).then(function () {
            _this2.get('workflow').reloadWorkflowFromTemplate();
            _this2.get('documentWizard').setInitData();
            _this2.set('currentStepIndex', 0);
          });
        }
      }
    }
  });
});