define('quantuvis-cm-spa/adapters/contracts/file', ['exports', 'quantuvis-cm-spa/adapters/application', 'ember-inflector'], function (exports, _quantuvisCmSpaAdaptersApplication, _emberInflector) {

  var FILE_DOWNLOAD_TYPE = 'contracts/file/download';
  var FILE_VIEW_TYPE = 'contracts/file/view';
  var FILE_PREVIEW_TYPE = 'contracts/file/preview';
  var FILE_PRINT_TYPE = 'contracts/file/print';

  exports['default'] = _quantuvisCmSpaAdaptersApplication['default'].extend({
    pathForType: function pathForType(type) {
      return (0, _emberInflector.singularize)(type);
    },

    download: function download(options) {
      return this.ajax(this.buildURL(FILE_DOWNLOAD_TYPE), 'POST', { data: options });
    },

    preview: function preview(options) {
      return this.ajax(this.buildURL(FILE_PREVIEW_TYPE), 'POST', { data: options });
    },

    print: function print(options) {
      return this.ajax(this.buildURL(FILE_PRINT_TYPE), 'POST', { data: options });
    },

    view: function view(options) {
      return this.ajax(this.buildURL(FILE_VIEW_TYPE), 'POST', { data: options });
    }
  });
});