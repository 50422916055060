define('quantuvis-cm-spa/components/cm-timeline/cm-timeline-item/cm-item-content', ['exports', 'ember', 'quantuvis-cm-spa/constants/workflow-step-state'], function (exports, _ember, _quantuvisCmSpaConstantsWorkflowStepState) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['cm-item-content'],
    showArrow: true,
    classNameBindings: ['bindStateClass', 'overdue:content-overdue'],

    // TODO add tests for computed
    bindStateClass: _ember['default'].computed('state', function () {
      var stateId = this.get('state.id');

      switch (stateId) {
        case _quantuvisCmSpaConstantsWorkflowStepState['default'].IN_PROGRESS:
          return 'content-progress';
        case _quantuvisCmSpaConstantsWorkflowStepState['default'].PENDING:
          return 'content-pending';
        case _quantuvisCmSpaConstantsWorkflowStepState['default'].COMPLETED:
          return 'content-completed';
        case _quantuvisCmSpaConstantsWorkflowStepState['default'].VOIDED:
          return 'content-voided';
        default:
          return '';
      }
    })
  });
});