define('quantuvis-cm-spa/components/thank-you-page/constants/events', ['exports', 'quantuvis-cm-spa/constants/sign-events'], function (exports, _quantuvisCmSpaConstantsSignEvents) {

  var events = {
    'default': {
      type: 'default',
      title: 'Thank you for using Quantuvis!',
      description: 'Follow the contact link below to find more about Quantuvis products',
      icon: '/assets/icons/document.svg'
    },

    complete: {
      type: _quantuvisCmSpaConstantsSignEvents.SIGN_COMPLETED,
      title: 'You\'re done!',
      description: 'After all recipients finish signing, you will receive an email with a link to the document',
      icon: '/assets/icons/signing-complete-icon.svg'
    },

    decline: {
      type: _quantuvisCmSpaConstantsSignEvents.SIGN_DECLINED,
      title: 'You have declined to sign.',
      description: 'The sender has been notified that you declined to sign.\n      If you have any questions contact the sender.',
      icon: '/assets/icons/signing-declined-icon.svg'
    },

    finishLater: {
      type: _quantuvisCmSpaConstantsSignEvents.SIGN_FINISH_LATER,
      title: 'You\'ve saved your document to finish later.',
      description: 'To complete the document, use the link in the original notification inviting\n      you to review and sign the document',
      icon: '/assets/icons/signing-finish-later-icon.svg'
    }
  };

  exports['default'] = events;
});