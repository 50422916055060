define('quantuvis-cm-spa/services/document-summary', ['exports', 'ember', 'quantuvis-cm-spa/constants/workflow-step-type', 'quantuvis-cm-spa/constants/permissions', 'quantuvis-cm-spa/constants/user-query-type'], function (exports, _ember, _quantuvisCmSpaConstantsWorkflowStepType, _quantuvisCmSpaConstantsPermissions, _quantuvisCmSpaConstantsUserQueryType) {
  exports['default'] = _ember['default'].Service.extend({
    store: _ember['default'].inject.service(),
    timeline: _ember['default'].inject.service(),

    // TODO Add tests!!!!!!!!!
    getLinesOfBusiness: function getLinesOfBusiness() {
      return this.get('store').findAll('contracts/line-of-business');
    },

    // TODO Add tests!!!!!!!!!
    getUsers: function getUsers(companyId) {
      return this.get('store').query('user-management/user', {
        permissions: _quantuvisCmSpaConstantsPermissions.PERMISSION_GROUPS.LEAD,
        applicationId: _quantuvisCmSpaConstantsPermissions.PERMISSIONS.APPLICATION.CM,
        resourceId: _quantuvisCmSpaConstantsPermissions.PERMISSIONS.RESOURCE.COMPANY,
        resourceValue: companyId,
        type: _quantuvisCmSpaConstantsUserQueryType['default'].PERMISSION
      });
    },

    /**
     * Get contract reviewers from steps and sub-steps
     * only from Department review and Multi department review
     * @param {Object} workflow - Contract workflow Object
     * @param leadIds - ids of contract leads
     * @return {string[]} - reviewers list
     */
    getContractReviewers: function getContractReviewers(workflow) {
      var _this = this;

      var leadIds = arguments.length <= 1 || arguments[1] === undefined ? [] : arguments[1];

      var reviewersSet = new Set();

      if (!workflow || !workflow.get('steps')) {
        return Array.from(reviewersSet);
      }
      workflow.get('steps').forEach(function (step) {
        switch (step.get('type.id')) {
          case _quantuvisCmSpaConstantsWorkflowStepType['default'].DEPARTMENT_REVIEW_ID:
          case _quantuvisCmSpaConstantsWorkflowStepType['default'].MULTI_DEPARTMENT_REVIEW_ID:
            _this.addReviewersFromStepToSet(step, reviewersSet, leadIds);
            break;
          default:
            break;
        }
      });

      return Array.from(reviewersSet);
    },

    /**
     * Add reviewers strings from step and substeps to reviewers set
     * @param {Object} step - workflow step
     * @param {Set<string>} reviewersSet - reviwers set strings
     * @param leadIds - ids of contract leads
     */
    addReviewersFromStepToSet: function addReviewersFromStepToSet(step, reviewersSet, leadIds) {
      var _this2 = this;

      this.addResponsiblePersonsNamesToSet(reviewersSet, step, leadIds);

      if (step.get('substeps')) {
        step.get('substeps').forEach(function (substep) {
          _this2.addResponsiblePersonsNamesToSet(reviewersSet, substep, leadIds);
        });
      }
    },

    addResponsiblePersonsNamesToSet: function addResponsiblePersonsNamesToSet(reviewersSet, step, leadIds) {
      var _this3 = this;

      return step.get('responsiblePersonsAccounts').filter(function (person) {
        return !leadIds.includes(parseInt(person.id));
      }).forEach(function (person) {
        reviewersSet.add(_this3.get('timeline').createPersonInfo(person, '-'));
      });
    }
  });
});