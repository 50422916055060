define('quantuvis-cm-spa/core-ui/components/cm-accordion/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    classNames: ['cm-accordion'],
    openCounter: 0,
    elementToggle: {},
    penals: [],
    /**
     * Height of accordion panel header
     */
    panelHeaderHeight: 42,

    /**
     * Timeout in milliseconds to run recalculation digest
     */
    onResizeEventTimeOut: 100,

    animation: {
      duration: 200
    },

    init: function init() {
      this._super.apply(this, arguments);
      this.resetPenals();
      _ember['default'].$(window).on('resize', _ember['default'].run.bind(this, this.onResizeEventHandler.bind(this)));
    },

    resetPenals: function resetPenals() {
      this.set('penals', []);
    },

    didInsertElement: function didInsertElement() {
      this.openCounter = this.getOpenedPanelsLength();
      this.normalizeElementsHeight(false);
    },

    didDestroyElement: function didDestroyElement() {
      this._super.apply(this, arguments);
      _ember['default'].$(window).unbind('resize');
    },

    actions: {
      onPanelsStateSwitched: function onPanelsStateSwitched(panel) {
        panel.isCollapsed ? this.decrementProperty('openCounter') : this.incrementProperty('openCounter');
        this.togglePanelContentContainer(panel);
        this.expandRightPanel(panel);
      },
      addPanel: function addPanel(panel) {
        if (this.get('disableCollapseAll')) {
          this.get('penals').addObject(panel);
        }
      }
    },

    expandRightPanel: function expandRightPanel(panel) {
      if (this.get('disableCollapseAll')) {
        if (this.get('openCounter') === 0) {
          var shouldExpand = this.get('penals').filter(function (parentPanel) {
            return panel.id !== parentPanel.idHead;
          });

          if (shouldExpand[0]) {
            var togglePanel = shouldExpand[0].panel.get('toggleChecker');

            shouldExpand[0].panel.set('toggleChecker', !togglePanel);
          }
        }
      }
    },

    isVisibleObserver: _ember['default'].observer('forceRefresh', function () {
      this.normalizeElementsHeight(false);
    }),

    togglePanelContentContainer: function togglePanelContentContainer(panel) {
      var container = this.$('#' + panel.id).parent().find('.cm-panel-body');
      var normalizedHeight = this.getNormalizedBodyHeight();

      panel.isCollapsed ? container.css({ display: 'block' }).slideUp(this.animation) : container.css({ height: normalizedHeight }).slideDown(this.animation);

      this.normalizeElementsHeight(true);
    },

    onResizeEventHandler: function onResizeEventHandler() {
      _ember['default'].run.debounce(this, this.normalizeElementsHeight, this.onResizeEventTimeOut);
    },

    normalizeElementsHeight: function normalizeElementsHeight(useAnimation) {
      var options = useAnimation ? this.animation : { duration: 0 };
      var collection = this.$('.cm-accordion-panel .cm-panel-body');

      collection.animate({ height: this.getNormalizedBodyHeight() }, options);
    },

    getNormalizedBodyHeight: function getNormalizedBodyHeight() {
      return (this.getContainerHeight() - this.getPanelsLength() * this.panelHeaderHeight) / this.openCounter;
    },

    getPanelsLength: function getPanelsLength() {
      return this.$('.cm-accordion-panel').length;
    },

    getContainerHeight: function getContainerHeight() {
      return this.$().height();
    },

    getOpenedPanelsLength: function getOpenedPanelsLength() {
      return this.getPanelsLength() - this.$('.cm-accordion-panel.is-collapsed').length;
    }
  });
});