define('quantuvis-cm-spa/components/cm-timeline/cm-timeline-item/cm-timeline-lead-review', ['exports', 'ember', 'quantuvis-cm-spa/components/cm-timeline/cm-timeline-item/cm-workflow-step-base'], function (exports, _ember, _quantuvisCmSpaComponentsCmTimelineCmTimelineItemCmWorkflowStepBase) {
  exports['default'] = _quantuvisCmSpaComponentsCmTimelineCmTimelineItemCmWorkflowStepBase['default'].extend({
    classNames: ['cm-timeline-lead-review'],
    classNameBindings: ['hasSubItems:cm-timeline-multi'],

    hasSubItems: _ember['default'].computed('computedSubItems', function () {
      return this.get('computedSubItems').length > 0;
    }),

    computedSubItems: _ember['default'].computed.map('data.workflowStepElements', function (workflowStepElement) {
      return {
        template: 'cm-timeline/cm-timeline-item/cm-timeline-event',
        data: workflowStepElement.get('workflowStepEvent.event')
      };
    })
  });
});