define('quantuvis-cm-spa/routes/contracts', ['exports', 'ember', 'quantuvis-cm-spa/constants/contract-status', 'quantuvis-cm-spa/mixins/authenticated-route-mixin', 'quantuvis-cm-spa/constants/message-bus-event', 'quantuvis-cm-spa/constants/permissions'], function (exports, _ember, _quantuvisCmSpaConstantsContractStatus, _quantuvisCmSpaMixinsAuthenticatedRouteMixin, _quantuvisCmSpaConstantsMessageBusEvent, _quantuvisCmSpaConstantsPermissions) {
  var isBlank = _ember['default'].isBlank;
  exports['default'] = _ember['default'].Route.extend(_quantuvisCmSpaMixinsAuthenticatedRouteMixin['default'], {
    search: _ember['default'].inject.service(),
    messageBus: _ember['default'].inject.service('message-bus'),
    notifications: _ember['default'].inject.service(),
    permissionService: _ember['default'].inject.service(),
    profile: _ember['default'].inject.service(),
    switchAccounts: _ember['default'].inject.service(),
    contractDetailsSidebarNavigator: _ember['default'].inject.service(),

    defaultQuery: {
      size: 10,
      sort: 'modifiedDate,desc',
      status: _quantuvisCmSpaConstantsContractStatus.STATUS_DEFAULT_FILTER
    },
    userQuery: null,

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      _ember['default'].run(function () {
        _ember['default'].run.scheduleOnce('render', _this, _this.addSubscribers);
      });
    },

    addSubscribers: function addSubscribers() {
      this.get('messageBus').subscribe(_quantuvisCmSpaConstantsMessageBusEvent['default'].DOCUMENT_REMOVED, this, function () {
        this.refresh();
        this.get('notifications').refresh();
      });
    },

    activate: function activate() {
      var isQuantuvisAdmin = this.get('permissionService').checkAdminPermissionByApplication(_quantuvisCmSpaConstantsPermissions.PERMISSIONS.ID.SA_READ, _quantuvisCmSpaConstantsPermissions.PERMISSIONS.APPLICATION.UM);

      if (isQuantuvisAdmin) {
        this.get('router').transitionTo('business-admin');

        return;
      }

      this.get('notifications').refresh();
    },

    actions: {
      updateQueryParams: function updateQueryParams(data) {
        this.set('userQuery', data);
      }
    },

    model: function model(params) {
      this.resetAccountToggleIfNoAccounts();
      this.initSearchAttributes();
      this.get('contractDetailsSidebarNavigator').resetActiveTab();
      this.setQueryParams(params);

      var query = this.getQuery();

      return _ember['default'].RSVP.hash({
        query: query,
        contracts: this.get('store').query('search/contract', query),
        contractTypes: this.get('store').query('contracts/types/available-type', {}),
        profile: this.get('profile').getUserProfile()
      });
    },

    setQueryParams: function setQueryParams(params) {
      if (!this.userQuery) {
        Object.assign(this.defaultQuery, params);
        this.get('search').setQueryParams(params);
      }
    },

    getQuery: function getQuery() {
      var query = this.get('userQuery') || this.get('defaultQuery');
      var dateRangeModel = this.get('search.dateRangeSearch');

      if (this.get('switchAccounts.showMyAccounts')) {
        query.company = this.get('profile.myCompanies').join(',');
      }

      Object.keys(dateRangeModel).filter(function (value) {
        return !isBlank(dateRangeModel[value]);
      }).forEach(function (value) {
        query[value] = dateRangeModel[value];
      });

      return query;
    },

    deactivate: function deactivate() {
      this.controllerFor('contracts').set('isFiltersPanelOpen', false);
    },

    resetAccountToggleIfNoAccounts: function resetAccountToggleIfNoAccounts() {
      var showMyAccounts = this.get('switchAccounts.showMyAccounts');

      if (this.get('profile.myCompanies').length === 0 && showMyAccounts) {
        this.get('search').reset();
        this.set('search.accountsFilterActive', false);
        this.set('switchAccounts.showMyAccounts', false);
        this.set('userQuery', null);
      }
    },

    initSearchAttributes: function initSearchAttributes() {
      var showMyAccounts = this.get('switchAccounts.showMyAccounts');

      if (showMyAccounts) {
        this.get('search').updateByMyAccounts(this.get('profile.myCompanies'));
      } else {
        this.get('search').fetchAttributesOptions();
      }
    }
  });
});