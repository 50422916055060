define('quantuvis-cm-spa/components/cm-event-card', ['exports', 'ember', 'moment', 'quantuvis-cm-spa/constants/date-format'], function (exports, _ember, _moment, _quantuvisCmSpaConstantsDateFormat) {

  var EVENT_DATE_PLACEHOLDER = '{createdDate}';

  exports['default'] = _ember['default'].Component.extend({
    classNames: ['cm-event-card'],
    DATE_FORMAT: _quantuvisCmSpaConstantsDateFormat['default'],
    description: _ember['default'].computed('event.createdDate', 'event.description', function () {
      var date = (0, _moment['default'])(this.get('event.createdDate')).format(_quantuvisCmSpaConstantsDateFormat['default'].MM_DD_YYYY);

      return this.get('event.description').replace(EVENT_DATE_PLACEHOLDER, date);
    })
  });
});