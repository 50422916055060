define('quantuvis-cm-spa/validations/custom-validators/custom-contact-person', ['exports', 'quantuvis-cm-spa/constants/contact-type'], function (exports, _quantuvisCmSpaConstantsContactType) {
  exports['default'] = validateCustomContactPerson;

  var MAX_LENGTH = 100,
      ERRORS = { length: 'Name should be maximum ' + MAX_LENGTH + ' characters long' },
      SUCCESS = true;

  function validateCustomContactPerson() {
    return function (key, newValue, oldValue, changes, content) {
      var contactType = changes.contactType || content.contactType,
          contactPerson = changes.contactPerson || content.contactPerson,
          isCustomType = contactType === _quantuvisCmSpaConstantsContactType['default'].CUSTOM;

      if (isCustomType && contactPerson && contactPerson.length > MAX_LENGTH) {
        return ERRORS.length;
      }

      return SUCCESS;
    };
  }
});