define('quantuvis-cm-spa/models/workflows/substep', ['exports', 'ember', 'ember-data', 'quantuvis-cm-spa/constants/workflow-step-state', 'quantuvis-cm-spa/constants/workflow-substep-type'], function (exports, _ember, _emberData, _quantuvisCmSpaConstantsWorkflowStepState, _quantuvisCmSpaConstantsWorkflowSubstepType) {
  exports['default'] = _emberData['default'].Model.extend({
    name: _emberData['default'].attr('string'),
    position: _emberData['default'].attr('number'),
    dueDate: _emberData['default'].attr('unix-date'),
    notes: _emberData['default'].attr('string'),
    executorId: _emberData['default'].attr('number'),
    departmentId: _emberData['default'].attr('number'),
    completedDate: _emberData['default'].attr('unix-timestamp'),
    executor: _emberData['default'].belongsTo('user-management/users/extended'),
    type: _emberData['default'].attr(),
    state: _emberData['default'].attr(),
    attachments: _emberData['default'].hasMany('workflows/attachment', { inverse: null, async: false }),
    responsiblePersons: _emberData['default'].attr(),
    responsiblePersonsAccounts: _emberData['default'].hasMany('user-management/users/extended', { inverse: null, async: false }),

    isFinished: _ember['default'].computed('state', function () {
      var state = this.get('state.id');

      return state === _quantuvisCmSpaConstantsWorkflowStepState['default'].COMPLETED || state === _quantuvisCmSpaConstantsWorkflowStepState['default'].VOIDED || state === _quantuvisCmSpaConstantsWorkflowStepState['default'].REJECTED;
    }),

    isContractLeadReviewSubstep: _ember['default'].computed('type', function () {
      return this.get('type.id') === _quantuvisCmSpaConstantsWorkflowSubstepType.CONTRACT_LEAD_REVIEW_ID;
    })
  });
});