define('quantuvis-cm-spa/components/create-document/cm-template-select', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['cm-template-select'],
    fileTemplateService: _ember['default'].inject.service(),

    init: function init() {
      this._super.apply(this, arguments);
      this.fetchTemplates();
    },

    fetchTemplates: function fetchTemplates() {
      var _this = this;

      this.set('isLoading', true);
      this.get('fileTemplateService').fetchTemplates().then(function (templates) {
        _this.set('templates', templates);
        _this.set('isLoading', false);
      })['catch'](function () {
        _this.set('templates', []);
        _this.set('isLoading', false);
      });
    },

    actions: {
      handleTemplateSelected: function handleTemplateSelected(template) {
        this.get('fileTemplateService').set('selectedTemplateId', template.get('id'));
        this.get('changeset').set('fileKey', template.get('fileKey'));
        this.get('changeset').set('fileName', template.get('fileName'));
      }
    }
  });
});