define('quantuvis-cm-spa/routes/reset-password', ['exports', 'ember', 'ember-simple-auth/mixins/unauthenticated-route-mixin', 'ember-changeset', 'ember-changeset-validations', 'quantuvis-cm-spa/validations/reset-password'], function (exports, _ember, _emberSimpleAuthMixinsUnauthenticatedRouteMixin, _emberChangeset, _emberChangesetValidations, _quantuvisCmSpaValidationsResetPassword) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsUnauthenticatedRouteMixin['default'], {

    credentials: {
      newPassword: '',
      confirmationPassword: '',
      answer: ''
    },

    initChangeset: function initChangeset() {
      var changeset = new _emberChangeset['default'](this.credentials, (0, _emberChangesetValidations['default'])(_quantuvisCmSpaValidationsResetPassword['default']), _quantuvisCmSpaValidationsResetPassword['default'], {
        skipValidate: true
      });

      return changeset;
    },

    model: function model(params) {
      return {
        credentials: this.initChangeset(),
        application: params.application,
        token: params.token
      };
    }
  });
});