define('quantuvis-cm-spa/services/user-session', ['exports', 'ember'], function (exports, _ember) {
  var getOwner = _ember['default'].getOwner;
  exports['default'] = _ember['default'].Service.extend({
    session: _ember['default'].inject.service(),

    getCurrentUserId: function getCurrentUserId() {
      return this.get('session.session.authenticated.user_id');
    },

    refreshToken: function refreshToken() {
      var authorizer = getOwner(this).lookup('authenticator:oauth2');
      var sessionData = this.get('session.session.authenticated');

      return authorizer.makeRefreshTokenRequest(sessionData['expires_in'], sessionData['refresh_token']);
    }
  });
});