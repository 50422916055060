define('quantuvis-cm-spa/components/cm-contract-details/cm-contract-details-sidebar-note', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    contractDetails: _ember['default'].inject.service(),
    contractDetailsSidebarNavigator: _ember['default'].inject.service(),

    //TODO: remove cm-icon className!!!!!
    classNames: ['cm-icon', 'cm-contract-details-sidebar-note', 'cursor-pointer'],

    count: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.getCountOfUnreadContractNotes();
    },

    getCountOfUnreadContractNotes: function getCountOfUnreadContractNotes() {
      var _this = this;

      this.get('contractDetails').getCountOfUnreadContractNotes().then(function (response) {
        _this.set('count', response.get('count'));
      })['catch'](function () {});
    },

    actions: {
      itemSelected: function itemSelected(urlHash) {
        this.get('contractDetailsSidebarNavigator').setUrlHash(urlHash);
        this.set('count', null);
      }
    }
  });
});