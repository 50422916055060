define('quantuvis-cm-spa/components/cm-document-preview/custom-pdf-document', ['exports', 'ember', 'ember-pdf-js/components/pdf-js'], function (exports, _ember, _emberPdfJsComponentsPdfJs) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  var get = _ember['default'].get;
  var set = _ember['default'].set;

  /* jshint undef: false */
  var _PDFJS = PDFJS;
  var PDFLinkService = _PDFJS.PDFLinkService;
  var PDFViewer = _PDFJS.PDFViewer;
  var PDFHistory = _PDFJS.PDFHistory;
  var PDFFindController = _PDFJS.PDFFindController;

  /* jshint undef: true */

  /**
   * Margin Offset from top of screen to matching result
   * @type {number}
   */
  var MATCHED_RESULT_OFFESET = 100;

  exports['default'] = _emberPdfJsComponentsPdfJs['default'].extend({
    overflowContainer: '',
    toolbarComponent: 'cm-document-preview/empty-toolbar/custom-empty-pdf-js-toolbar',

    didInsertElement: function didInsertElement() {
      var _element$getElementsByClassName = this.element.getElementsByClassName('pdfViewerContainer');

      var _element$getElementsByClassName2 = _slicedToArray(_element$getElementsByClassName, 1);

      var container = _element$getElementsByClassName2[0];

      this.set('_container', container);
      var pdfLinkService = new PDFLinkService();

      this.set('pdfLinkService', pdfLinkService);
      var pdfViewer = new PDFViewer({
        container: container,
        linkService: pdfLinkService
      });

      this.set('pdfViewer', pdfViewer);
      pdfLinkService.setViewer(pdfViewer);
      var pdfHistory = new PDFHistory({
        linkService: pdfLinkService
      });

      this.set('pdfHistory', pdfHistory);
      pdfLinkService.setHistory(pdfHistory);
      var pdfFindController = new PDFFindController({
        pdfViewer: pdfViewer
      });

      if (this.get('overflowContainer')) {
        PDFFindController.prototype.updateMatchPosition = this.updateMatchPosition.bind(pdfFindController, this.get('overflowContainer'));
      }

      this.set('pdfFindController', pdfFindController);

      pdfViewer.setFindController(pdfFindController);
      pdfViewer.currentScaleValue = 'page-fit';

      pdfFindController.onUpdateResultsCount = (function (total) {
        this.set('matchCount', total);
      }).bind(this);

      if (this.get('findControllerWrapper')) {
        this.get('findControllerWrapper').set('findController', pdfFindController);
      }

      var src = get(this, 'src');

      if (src) {
        var loadAction = this.actions.load.bind(this);

        loadAction(src, pdfViewer, pdfLinkService);
      }

      this._super.apply(this, arguments);
    },

    actions: {
      load: function load(src, viewer, linkService) {
        var _this = this;

        if (!src) {
          return;
        }
        var loadingTask = get(this, 'pdfLib').getDocument(src);

        loadingTask.onPassword = function (updateCallback, reason) {
          _this.sendAction('onPassword', updateCallback, reason);
        };

        loadingTask = loadingTask.then(function (pdfDocument) {
          if (_this.get('isDestroyed')) {
            return;
          }
          set(_this, 'pdfDocument', pdfDocument);
          viewer.setDocument(pdfDocument);
          linkService.setDocument(pdfDocument);
          set(_this, 'pdfTotalPages', linkService.pagesCount);
          set(_this, 'pdfPage', linkService.page);
        });

        set(this, 'loadingTask', loadingTask);

        return loadingTask;
      }
    },

    updateMatchPosition: function updateMatchPosition(overflowContainer, pageIndex, index, elements, beginIdx) {
      if (this.selected.matchIdx === index && this.selected.pageIdx === pageIndex) {
        var container = _ember['default'].$(overflowContainer);
        var _scrollTo = _ember['default'].$(elements[beginIdx]);
        var matchedResultOffset = _scrollTo.offset().top - container.offset().top + container.scrollTop();
        var offset = matchedResultOffset > MATCHED_RESULT_OFFESET ? matchedResultOffset - MATCHED_RESULT_OFFESET : MATCHED_RESULT_OFFESET;

        container.scrollTop(offset);
      }
    }
  });
});
/*global PDFJS*/