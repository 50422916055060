define('quantuvis-cm-spa/components/sign-off/initiate-form', ['exports', 'ember', 'ember-changeset', 'ember-changeset-validations', 'quantuvis-cm-spa/constants/company-type', 'quantuvis-cm-spa/constants/sign-off/tooltip-messages', 'quantuvis-cm-spa/constants/attachment-status', 'quantuvis-cm-spa/validations/initiate-sign-off', 'quantuvis-cm-spa/components/sign-off/form'], function (exports, _ember, _emberChangeset, _emberChangesetValidations, _quantuvisCmSpaConstantsCompanyType, _quantuvisCmSpaConstantsSignOffTooltipMessages, _quantuvisCmSpaConstantsAttachmentStatus, _quantuvisCmSpaValidationsInitiateSignOff, _quantuvisCmSpaComponentsSignOffForm) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  var CHANGESETS_KEYS = {
    internalParty: 'internal-party',
    externalParty: 'external-party'
  };

  exports['default'] = _quantuvisCmSpaComponentsSignOffForm['default'].extend({
    layoutName: 'components/sign-off/form',

    timelineAttachment: _ember['default'].inject.service(),
    initiateSignOff: _ember['default'].inject.service(),
    initiateSignOffValidator: _ember['default'].inject.service(),
    signOffService: _ember['default'].inject.service('sign-off'),

    COMPANY_TYPE: _quantuvisCmSpaConstantsCompanyType['default'],
    tooltipMessages: _quantuvisCmSpaConstantsSignOffTooltipMessages['default'],
    ALREADY_SIGNED_MESSAGE: 'has already signed this version',

    changesetModel: {
      documentVersion: {},
      parties: [],
      dueDate: '',
      subject: '',
      message: ''
    },

    internalCompany: {},
    externalCompany: {},

    internalParty: {},
    externalParty: {},

    recipients: [],
    versions: [],

    isAnyPartySelected: _ember['default'].computed('changeset.parties.@each.isChecked', function () {
      return this.get('changeset.parties').some(function (_ref) {
        var isChecked = _ref.isChecked;
        return isChecked;
      });
    }),

    partiesListObserver: _ember['default'].observer('changeset.parties.@each.isChecked', 'changeset.documentVersion', function () {
      if (this.get('changeset.error.partiesList')) {
        this.get('changeset').validate('partiesList');
      }
    }),

    documentVersionObserver: _ember['default'].on('init', _ember['default'].observer('changeset.documentVersion', function () {
      var _getProperties = this.getProperties('internalCompany', 'externalCompany', 'changeset', 'account', 'initiateSignOff');

      var internalCompany = _getProperties.internalCompany;
      var externalCompany = _getProperties.externalCompany;
      var changeset = _getProperties.changeset;
      var initiateSignOff = _getProperties.initiateSignOff;

      var _changeset$getProperties = changeset.getProperties('parties', 'documentVersion');

      var parties = _changeset$getProperties.parties;
      var documentVersion = _changeset$getProperties.documentVersion;

      var companies = [internalCompany, externalCompany];

      initiateSignOff.set('documentVersion', documentVersion);

      parties.forEach(function (party) {
        var company = companies.find(function (_ref2) {
          var id = _ref2.id;
          return party.get('id') === Number(id);
        });
        var isDisabled = initiateSignOff.checkIfCompanyDisabled(company);

        party.set('isDisabled', isDisabled);

        if (isDisabled) {
          party.set('isChecked', false);
        }
      });
    })),

    init: function init() {
      this._super.apply(this, arguments);

      this.initChangeset();
      this.setCompanies();
      this.setAccountAndContractToService();
      this.prepareDocumentVersions();
      this.generateAndSetPartiesToChangeset();
      this.set('signOffService.sendExternallyMode', false);
    },

    initChangeset: function initChangeset() {
      var changeset = new _emberChangeset['default'](this.get('changesetModel'), (0, _emberChangesetValidations['default'])(_quantuvisCmSpaValidationsInitiateSignOff['default']), _quantuvisCmSpaValidationsInitiateSignOff['default'], {
        skipValidate: true
      });

      this.set('changeset', changeset);
      this.get('initiateSignOffValidator').add(this.elementId, changeset);
    },

    setCompanies: function setCompanies() {
      var _getProperties2 = this.getProperties('account', 'contract');

      var internalCompany = _getProperties2.account;
      var contract = _getProperties2.contract;

      var externalCompany = _ember['default'].Object.create(contract.get('company'));

      this.setProperties({
        internalCompany: internalCompany,
        externalCompany: externalCompany
      });
    },

    setAccountAndContractToService: function setAccountAndContractToService() {
      var _getProperties3 = this.getProperties('account', 'contract');

      var account = _getProperties3.account;
      var contract = _getProperties3.contract;

      this.get('initiateSignOff').setProperties({ account: account, contract: contract });
    },

    generateAndSetPartiesToChangeset: function generateAndSetPartiesToChangeset() {
      var _getProperties4 = this.getProperties('initiateSignOff', 'initiateSignOffValidator');

      var initiateSignOff = _getProperties4.initiateSignOff;
      var initiateSignOffValidator = _getProperties4.initiateSignOffValidator;

      var documentVersion = this.get('changeset.documentVersion');

      var _getProperties5 = this.getProperties('internalCompany', 'externalCompany');

      var internalCompany = _getProperties5.internalCompany;
      var externalCompany = _getProperties5.externalCompany;

      initiateSignOff.set('documentVersion', documentVersion);

      var parties = initiateSignOff.generateParties([internalCompany, externalCompany]);

      var _parties = _slicedToArray(parties, 2);

      var internalParty = _parties[0];
      var externalParty = _parties[1];

      initiateSignOffValidator.add(CHANGESETS_KEYS.internalParty, internalParty);
      initiateSignOffValidator.add(CHANGESETS_KEYS.externalParty, externalParty);

      this.setProperties({
        internalParty: internalParty,
        externalParty: externalParty,
        'changeset.parties': parties
      });
    },

    prepareDocumentVersions: function prepareDocumentVersions() {
      var _getProperties6 = this.getProperties('versions', 'timelineAttachment');

      var versions = _getProperties6.versions;
      var timelineAttachment = _getProperties6.timelineAttachment;

      var documentVersions = timelineAttachment.prepareVersionsForDropdown(versions);

      this.setProperties({
        documentVersions: documentVersions.filter(function (version) {
          return version.get('status.id') !== _quantuvisCmSpaConstantsAttachmentStatus['default'].DECLINED;
        }),
        'changeset.documentVersion': this.defineLastDocumentVersion(documentVersions)
      });
    },

    defineLastDocumentVersion: function defineLastDocumentVersion(versions) {
      var timelineAttachment = this.get('timelineAttachment');

      if (versions.get('lastObject.status.id') === _quantuvisCmSpaConstantsAttachmentStatus['default'].DECLINED) {
        return versions.toArray().reverse().find(function (version) {
          return version.get('status.id') !== _quantuvisCmSpaConstantsAttachmentStatus['default'].DECLINED;
        });
      } else {
        return timelineAttachment.prepareLastVersion(versions.get('lastObject'));
      }
    },

    actions: {
      submit: function submit() {
        var _getProperties7 = this.getProperties('changeset', 'contract', 'initiateSignOff');

        var changeset = _getProperties7.changeset;
        var contract = _getProperties7.contract;
        var initiateSignOff = _getProperties7.initiateSignOff;

        var data = initiateSignOff.generateDataToSave({ changeset: changeset, originContractId: contract.get('id') });

        this.sendAction('onSubmit', data, this.get('hasActiveLinkedContract'));
      },

      onPartyCheckboxClick: function onPartyCheckboxClick(selectedParty) {
        this.get('changeset.parties').filter(function (party) {
          return party.get('id') !== selectedParty.get('id');
        }).forEach(function (party) {
          return party.set('isChecked', false);
        });

        this.set('signOffService.sendExternallyMode', selectedParty.get('id') === this.get('externalParty.id'));
      }
    }
  });
});