define('quantuvis-cm-spa/components/create-document/cm-workflow', ['exports', 'ember', 'quantuvis-cm-spa/core-ui/components/cm-wizard/cm-step/component', 'quantuvis-cm-spa/constants/department-status', 'quantuvis-cm-spa/constants/workflow-step-type', 'quantuvis-cm-spa/constants/error-messages', 'quantuvis-cm-spa/models/dropdown-type-options', 'quantuvis-cm-spa/mixins/scroll-to-validation-error'], function (exports, _ember, _quantuvisCmSpaCoreUiComponentsCmWizardCmStepComponent, _quantuvisCmSpaConstantsDepartmentStatus, _quantuvisCmSpaConstantsWorkflowStepType, _quantuvisCmSpaConstantsErrorMessages, _quantuvisCmSpaModelsDropdownTypeOptions, _quantuvisCmSpaMixinsScrollToValidationError) {
  exports['default'] = _quantuvisCmSpaCoreUiComponentsCmWizardCmStepComponent['default'].extend(_quantuvisCmSpaMixinsScrollToValidationError['default'], {
    store: _ember['default'].inject.service(),
    workflowTemplate: _ember['default'].inject.service(),
    workflow: _ember['default'].inject.service(),
    dialog: _ember['default'].inject.service(),
    documentWizard: _ember['default'].inject.service(),
    loadingSpinner: _ember['default'].inject.service(),

    fadeDuration: 200,
    departments: [],
    leads: [],
    companyId: null,

    changesetMap: new Map(),

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      this.setCompanyId();

      this.get('loadingSpinner').show('create-contract-spinner');
      var promises = [];

      promises.push(this.fetchTemplates());
      promises.push(this.fetchDepartments());
      promises.push(this.fetchLeads());

      Promise.all(promises).then(function () {
        return _this.get('loadingSpinner').hide('create-contract-spinner');
      })['catch'](function () {
        return _this.get('loadingSpinner').hide('create-contract-spinner');
      });
    },

    fetchTemplates: function fetchTemplates() {
      return this.get('workflowTemplate').fetchTemplates();
    },

    /**
     * Workflow templates
     */
    templates: _ember['default'].computed.alias('workflowTemplate.templates'),

    /**
     * Defined workflow for contract
     */
    current: _ember['default'].computed.alias('workflowTemplate.selectedTemplate'),

    /**
     * Workflow steps
     */
    steps: _ember['default'].computed('workflowTemplate.steps', function () {
      return this.get('workflowTemplate').get('steps');
    }),

    setCompanyId: function setCompanyId() {
      var documentCompanyId = this.get('documentWizard.model.company.id');
      var parentDocumentCompanyId = this.get('documentWizard.model.linkToContract.companyId');

      this.set('companyId', documentCompanyId || parentDocumentCompanyId);
    },

    workflowStepsRequired: _ember['default'].computed.alias('workflowTemplate.workflowStepsRequired'),
    workflowStepsSorted: _ember['default'].computed.alias('workflowTemplate.workflowStepsSorted'),

    createEmptyStep: function createEmptyStep() {
      var step = this.get('workflow').createStep();

      step.set('type', new _quantuvisCmSpaModelsDropdownTypeOptions.DropdownOption(_quantuvisCmSpaConstantsWorkflowStepType.DEPARTMENT_REVIEW));

      return step;
    },

    switchTemplate: function switchTemplate(template) {
      this.get('workflowTemplate').switchWorkflow(template);
    },

    actions: {
      onSelectWorkflowTemplate: function onSelectWorkflowTemplate(template) {
        var _this2 = this;

        var options = {
          confirmMessage: 'Are you sure you want to navigate out of the current template?\n          All the data already entered will be lost'
        };

        var templateStepsLength = this.get('current').get('steps.length');
        var workflowStepsLength = this.get('steps').get('length');

        var isChanged = templateStepsLength !== workflowStepsLength;

        this.changesetMap.forEach(function (ch) {
          isChanged = isChanged || ch.get('isDirty');
        });

        if (isChanged) {
          this.get('dialog').show('dialogs/cm-dialog', 'dialog-templates/cm-confirmation', this, options).then(function () {
            _this2.switchTemplate(template);
          })['catch'](function () {});
        } else {
          this.switchTemplate(template);
        }
      },
      /**
       * Add new workflow step logic
       * @param data
       */
      addWorkflowStep: function addWorkflowStep(data) {
        var _this3 = this;

        this.get('workflowTemplate').addStep(this.createEmptyStep(), data.position);
        _ember['default'].run(function () {
          return _this3.updateStepsModel();
        });
        this.get('workflow').scrollToStepByIndex(data.position);
      },

      /**
       * Remove workflow step from service
       * Display confirm dialog and delete step when user confirm operation
       * @param data
       */
      onWorkflowStepDelete: function onWorkflowStepDelete(data) {
        var _this4 = this;

        var options = {
          confirmMessage: 'Do you want to remove this step?'
        };

        this.get('dialog').show('dialogs/cm-dialog', 'dialog-templates/cm-confirmation', this, options).then(function () {
          _this4.get('workflowTemplate').removeStep(data.position);
          _this4.updateStepsModel();
        })['catch'](function () {});
      },
      dragStarted: function dragStarted() {
        this.$('.cm-add-step').fadeTo(this.fadeDuration, 0);
        this.$('.time-line-item').addClass('hide');
      },
      dragStopped: function dragStopped() {
        this.$('.cm-add-step').fadeTo(this.fadeDuration, 1);
        this.$('.time-line-item').removeClass('hide');
      },
      reorderItems: function reorderItems(itemModels) {
        this.set('workflowStepsSorted', itemModels);
        this.updateStepsModel();
      },
      handleMoveDownClick: function handleMoveDownClick(position) {
        this.get('workflowTemplate').moveStepDown(position);
        this.updateStepsModel();
      },
      handleMoveUpClick: function handleMoveUpClick(position) {
        this.get('workflowTemplate').moveStepUp(position);
        this.updateStepsModel();
      }
    },

    /**
     * Return length of required steps
     */
    getRequiredElementsLength: function getRequiredElementsLength() {
      return this.get('workflowStepsRequired').length;
    },

    finish: function finish(resolve) {
      var _this5 = this;

      this.get('loadingSpinner').show('create-contract-spinner');
      this.validateForms().then(function (contract) {
        _this5.get('loadingSpinner').hide('create-contract-spinner');
        resolve(contract);
      })['catch'](function () {
        _this5.scrollToValidationError();
        _this5.get('loadingSpinner').hide('create-contract-spinner');
      });
    },

    validateForms: function validateForms() {
      var _this6 = this;

      var mapWithChangesets = this.get('changesetMap');
      var promises = [];

      mapWithChangesets.forEach(function (changeset) {
        promises.push(changeset.validate());
      });

      return Promise.all(promises).then(function () {
        var invalidChangesets = Array.from(mapWithChangesets.values()).filter(function (changeset) {
          return changeset.get('isInvalid');
        });

        if (invalidChangesets.length !== 0) {
          invalidChangesets.forEach(function (item) {
            item.get('expandOnInvalid') && item.get('expandOnInvalid')();
          });

          return Promise.reject(false);
        }

        _this6.saveChangesets();
        _this6.set('documentWizard.model.templateId', _this6.get('current.id'));
        _this6.set('documentWizard.model.steps', _this6.get('workflowTemplate').get('steps'));
        _this6.set('documentWizard.wizardSteps.workflow.isCompleted', true);

        return _this6.get('documentWizard').saveContract()['catch'](function (e) {
          var errorMessage = _quantuvisCmSpaConstantsErrorMessages.DEFAULT_ERROR_MESSAGE;

          if (e.errors && e.errors.length) {
            errorMessage = e.errors[0].detail;
          }
          var options = {
            errorMessage: errorMessage
          };

          _this6.get('dialog').show('dialogs/cm-error', null, _this6, options);
        });
      });
    },

    back: function back() {
      this.saveChangesets();
      this._super.apply(this, arguments);
    },

    saveChangesets: function saveChangesets() {
      var mapWithChangesets = this.get('changesetMap');

      mapWithChangesets.forEach(function (changeset) {
        changeset.execute();
      });
    },

    /**
     * Fetch workflow step department
     */
    fetchDepartments: function fetchDepartments() {
      var _this7 = this;

      return this.get('store').query('user-management/companies/department', { statusId: _quantuvisCmSpaConstantsDepartmentStatus['default'].ACTIVE_ID }).then(function (departments) {
        return _this7.set('departments', departments);
      });
    },

    fetchLeads: function fetchLeads() {
      var _this8 = this;

      return this.get('workflow').fetchResponsibleUsers(this.get('companyId')).then(function (leads) {
        return _this8.set('leads', leads);
      });
    },

    updateStepsModel: function updateStepsModel() {
      this.set('workflowTemplate.steps', this.get('workflowTemplate').getCombinedSteps());
    }
  });
});