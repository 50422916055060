define("quantuvis-cm-spa/constants/line-of-business", ["exports"], function (exports) {
  var LINE_OF_BUSINESS = {
    MEDICARE: 1,
    MEDICAID: 2,
    COMMERCIAL: 3,
    EXCHANGES: 4,
    MEDICAL: 5,
    NO_LINE_OF_BUSINESS: 6
  };

  exports["default"] = LINE_OF_BUSINESS;
});