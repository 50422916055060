define('quantuvis-cm-spa/mixins/sign-off/validator', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({
    changesetsMap: new Map(),

    init: function init() {
      this.set('changesetsMap', new Map());
    },

    removeValidations: function removeValidations() {
      this.get('changesetsMap').clear();
    },

    add: function add(key, changeset) {
      this.get('changesetsMap').set(key, changeset);
    },

    validate: function validate() {
      var changesetsMap = this.get('changesetsMap');
      var changesetsToValidate = Array.from(changesetsMap.values()).filter(function (changeset) {
        var isPartyChangeset = changeset.hasOwnProperty('signatories');

        if (!isPartyChangeset) {
          return changeset;
        } else if (isPartyChangeset && changeset.get('isChecked')) {
          return changeset;
        }
      }).reduce(function (changesetsArray, changeset) {
        var isPartyChangeset = changeset.hasOwnProperty('signatories');
        var changesetToConcat = isPartyChangeset ? changeset.get('signatories') : changeset;

        return changesetsArray.concat(changesetToConcat);
      }, []);
      var validatedChangesetsPromises = changesetsToValidate.map(function (changeset) {
        return changeset.validate();
      });

      return Promise.all(validatedChangesetsPromises).then(function () {
        return changesetsToValidate.every(function (changeset) {
          return changeset.get('isValid');
        });
      });
    }
  });
});