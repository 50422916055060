define('quantuvis-cm-spa/services/alert-service', ['exports', 'ember', 'moment', 'quantuvis-cm-spa/constants/permissions', 'quantuvis-cm-spa/constants/contract-status', 'quantuvis-cm-spa/constants/contract-type'], function (exports, _ember, _moment, _quantuvisCmSpaConstantsPermissions, _quantuvisCmSpaConstantsContractStatus, _quantuvisCmSpaConstantsContractType) {
  exports['default'] = _ember['default'].Service.extend({
    PERMISSIONS: _quantuvisCmSpaConstantsPermissions.PERMISSIONS,
    store: _ember['default'].inject.service(),
    permissionService: _ember['default'].inject.service(),
    contractDetails: _ember['default'].inject.service(),
    alerts: [],

    inactiveAmendmentStatuses: [_quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.EXECUTED, _quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.DEACTIVATED, _quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.TERMINATED, _quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.NEW],

    loadAlerts: function loadAlerts() {
      var _this = this;

      if (this.get('permissionService').checkCompanyUserPermission(_quantuvisCmSpaConstantsPermissions.PERMISSIONS.ID.VIEW_ALERT_BARS)) {
        this.get('store').unloadAll('contracts/alert');
        this.get('store').findAll('contracts/alert').then(function (alerts) {
          _this.set('alerts', alerts);
        })['catch'](function () {
          _this.alerts = [];
        });
      } else {
        this.alerts = [];
      }
    },

    isAlertExpiring: function isAlertExpiring(endDate, dueDate) {
      var _this2 = this;

      var document = this.get('contractDetails').getContract();
      var documentIsNotAmendment = document.get('type.id') !== _quantuvisCmSpaConstantsContractType['default'].AMENDMENT;
      var documentHasNoActiveAmendments = !document.get('contracts') || document.get('contracts').every(function (_ref) {
        var id = _ref.status.id;
        return _this2.get('inactiveAmendmentStatuses').includes(id);
      });
      var threeMonths = 90;

      return documentIsNotAmendment && documentHasNoActiveAmendments && (0, _moment['default'])(endDate).diff((0, _moment['default'])(), 'days') < threeMonths && (!_moment['default'].utc(dueDate).isValid() || endDate !== parseInt(_moment['default'].utc(dueDate).format('x')));
    }
  });
});