define('quantuvis-cm-spa/components/cm-contract-details/cm-contract-alert', ['exports', 'ember', 'moment', 'quantuvis-cm-spa/constants/contract-alert-type', 'quantuvis-cm-spa/constants/contract-type', 'quantuvis-cm-spa/constants/date-format', 'quantuvis-cm-spa/constants/permissions'], function (exports, _ember, _moment, _quantuvisCmSpaConstantsContractAlertType, _quantuvisCmSpaConstantsContractType, _quantuvisCmSpaConstantsDateFormat, _quantuvisCmSpaConstantsPermissions) {
  exports['default'] = _ember['default'].Component.extend({
    CONTRACT_TYPE: _quantuvisCmSpaConstantsContractType['default'],
    classNames: ['cm-contract-alert', 'v-align-wrapper'],
    uploadVersionService: _ember['default'].inject.service('upload-version'),
    workflowStepMenu: _ember['default'].inject.service(),
    permissionService: _ember['default'].inject.service(),
    dialog: _ember['default'].inject.service(),
    contractDetails: _ember['default'].inject.service(),
    contractDetailsSidebarNavigator: _ember['default'].inject.service(),
    alertService: _ember['default'].inject.service(),
    store: _ember['default'].inject.service(),
    date: _ember['default'].inject.service(),

    actions: {
      onUpload: function onUpload() {
        var fulfillingId = this.getFulfillingId();

        this.get('uploadVersionService').showModal(fulfillingId);
      },
      onMarkAsCompleted: function onMarkAsCompleted() {
        var substepId = this.get('alert.workflowSubStepId');
        var stepId = this.get('alert.workflowStepId');

        if (substepId) {
          this.get('workflowStepMenu').completeSubstep(substepId);
        } else if (stepId) {
          this.get('workflowStepMenu').completeStep(stepId);
        }
      },
      disbandExpiringAlert: function disbandExpiringAlert(alertId) {
        var _this = this;

        this.get('store').adapterFor('contracts/alert').put('contracts/alerts/' + alertId + '/disband-expiring', '', {}).then(function () {
          _this.sendAction('refreshAlerts');
        });
      },
      createAmendment: function createAmendment() {
        this.sendAction('createAmendment');
      },
      executeEvergreen: function executeEvergreen() {
        var _this2 = this;

        var newDate = (0, _moment['default'])(this.get('alert.payload.contractEndDate')).add(1, 'y').format(_quantuvisCmSpaConstantsDateFormat['default'].MM_DD_YYYY);
        var options = {
          title: 'Confirmation',
          confirmMessage: 'Are you sure you want to extend the contract for one year? New Contract End Date: ' + newDate + '.'
        };

        this.get('dialog').show('dialogs/cm-dialog', 'dialog-templates/cm-confirmation', this, options).then(function () {
          _this2.get('store').adapterFor('contracts').put('contracts/' + _this2.get('contractDetails').get('contractId') + '/prolong', '', {}).then(function () {
            _this2.get('contractDetails').reloadContract();
            _this2.sendAction('refreshAlerts');
            _this2.get('contractDetailsSidebarNavigator').setDefaultActiveTab();
          });
        });
      },
      disbandAndDisableEvergreen: function disbandAndDisableEvergreen(alertId) {
        var _this3 = this;

        var options = {
          title: 'Confirmation',
          confirmMessage: 'Are you sure you would like to turn off the Evergreen option for this contract?'
        };

        this.get('dialog').show('dialogs/cm-dialog', 'dialog-templates/cm-confirmation', this, options).then(function () {
          _this3.get('store').adapterFor('contracts/alert').put('contracts/alerts/' + alertId + '/disband-expiring', '', {}).then(function () {
            _this3.get('contractDetails').reloadContract();
            _this3.sendAction('refreshAlerts');
          });
        });
      }
    },

    init: function init() {
      this._super.apply(this, arguments);
    },

    title: _ember['default'].computed('alert', function () {
      var alert = this.get('alert');
      var payload = alert.get('payload');

      switch (alert.get('type').id) {
        case _quantuvisCmSpaConstantsContractAlertType.CONTRACT_ALERT_TYPE.EXPIRED_ID:
        case _quantuvisCmSpaConstantsContractAlertType.CONTRACT_ALERT_TYPE.DEACTIVATED_ID:
        case _quantuvisCmSpaConstantsContractAlertType.CONTRACT_ALERT_TYPE.TERMINATED_ID:
        case _quantuvisCmSpaConstantsContractAlertType.CONTRACT_ALERT_TYPE.PENDING_TERMINATION_ID:
        case _quantuvisCmSpaConstantsContractAlertType.CONTRACT_ALERT_TYPE.PENDING_NEXT_STEP_ID:
        case _quantuvisCmSpaConstantsContractAlertType.CONTRACT_ALERT_TYPE.CONTRACT_RECEIVED_ID:
          return alert.get('name');
        case _quantuvisCmSpaConstantsContractAlertType.CONTRACT_ALERT_TYPE.EXECUTED_ID:
          return this.get('alertService').isAlertExpiring(payload.contractEndDate, alert.get('dueDate')) ? 'Expiring' : alert.get('name');
        case _quantuvisCmSpaConstantsContractAlertType.CONTRACT_ALERT_TYPE.SIGN_OFF_NOTIFICATION_ID:
        case _quantuvisCmSpaConstantsContractAlertType.CONTRACT_ALERT_TYPE.CONTRACT_LEAD_REVIEW_ID:
        case _quantuvisCmSpaConstantsContractAlertType.CONTRACT_ALERT_TYPE.MULTI_DEPARTMENT_REVIEW_ID:
        case _quantuvisCmSpaConstantsContractAlertType.CONTRACT_ALERT_TYPE.SINGLE_DEPARTMENT_REVIEW_ID:
        case _quantuvisCmSpaConstantsContractAlertType.CONTRACT_ALERT_TYPE.COMPARE_AND_COMBINE_ID:
        case _quantuvisCmSpaConstantsContractAlertType.CONTRACT_ALERT_TYPE.SIGN_OFF_IN_PROGRESS_ID:
          {
            var _alert$getProperties = alert.getProperties('name', 'dueDate');

            var _name = _alert$getProperties.name;
            var dueDate = _alert$getProperties.dueDate;

            var _status = this.get('date').isOverdue(dueDate) ? 'Overdue' : 'In Progress';

            return _name + ' Review ' + _status;
          }
        default:
          return '';
      }
    }),

    description: _ember['default'].computed('alert', function () {
      var alert = this.get('alert');
      var payload = alert.get('payload');
      var responsibleNames = this.getResponsibleNames(alert);
      var expiringText = '';
      var effectiveDate = undefined;

      switch (alert.get('type').id) {
        case _quantuvisCmSpaConstantsContractAlertType.CONTRACT_ALERT_TYPE.EXPIRED_ID:
        case _quantuvisCmSpaConstantsContractAlertType.CONTRACT_ALERT_TYPE.DEACTIVATED_ID:
        case _quantuvisCmSpaConstantsContractAlertType.CONTRACT_ALERT_TYPE.TERMINATED_ID:
        case _quantuvisCmSpaConstantsContractAlertType.CONTRACT_ALERT_TYPE.CONTRACT_RECEIVED_ID:
          return 'on ' + this.get('date').formatDate(alert.get('date'));
        case _quantuvisCmSpaConstantsContractAlertType.CONTRACT_ALERT_TYPE.PENDING_TERMINATION_ID:
          effectiveDate = this.get('date').formatDateWithUtcTimezone(alert.get('effectiveDate'));

          return 'on ' + this.get('date').formatDate(alert.get('date')) + ' effective ' + effectiveDate;
        case _quantuvisCmSpaConstantsContractAlertType.CONTRACT_ALERT_TYPE.EXECUTED_ID:
          if (this.get('alertService').isAlertExpiring(payload.contractEndDate, alert.get('dueDate'))) {
            return 'on ' + this.get('date').formatDateWithUtcTimezone(payload.contractEndDate);
          } else {
            expiringText = ' expiring ' + this.get('date').formatDateWithUtcTimezone(payload.contractEndDate);

            return 'on ' + this.get('date').formatDate(alert.get('date')) + expiringText;
          }
        case _quantuvisCmSpaConstantsContractAlertType.CONTRACT_ALERT_TYPE.PENDING_NEXT_STEP_ID:
          return '';
        case _quantuvisCmSpaConstantsContractAlertType.CONTRACT_ALERT_TYPE.SIGN_OFF_IN_PROGRESS_ID:
        case _quantuvisCmSpaConstantsContractAlertType.CONTRACT_ALERT_TYPE.SIGN_OFF_NOTIFICATION_ID:
          return '' + this.getDueDateMessage(alert.get('dueDate'));
        case _quantuvisCmSpaConstantsContractAlertType.CONTRACT_ALERT_TYPE.CONTRACT_LEAD_REVIEW_ID:
        case _quantuvisCmSpaConstantsContractAlertType.CONTRACT_ALERT_TYPE.MULTI_DEPARTMENT_REVIEW_ID:
        case _quantuvisCmSpaConstantsContractAlertType.CONTRACT_ALERT_TYPE.SINGLE_DEPARTMENT_REVIEW_ID:
        case _quantuvisCmSpaConstantsContractAlertType.CONTRACT_ALERT_TYPE.COMPARE_AND_COMBINE_ID:
          return this.getDueDateMessage(alert.get('dueDate')) + ' - ' + responsibleNames;
        default:
          return '';
      }
    }),

    contractExpiring: _ember['default'].computed('alert', function () {
      var alert = this.get('alert');
      var payload = alert.get('payload');

      return alert.get('type').id === _quantuvisCmSpaConstantsContractAlertType.CONTRACT_ALERT_TYPE.EXECUTED_ID && payload && this.get('alertService').isAlertExpiring(payload.contractEndDate, alert.get('dueDate'));
    }),

    evergreenEnabled: function evergreenEnabled() {
      return this.get('alert.payload.contractEvergreen');
    },

    hasCreateContractPermission: function hasCreateContractPermission() {
      return this.get('permissionService').checkCompanyUserPermission(_quantuvisCmSpaConstantsPermissions.PERMISSIONS.ID.CREATE_DOCUMENT);
    },

    displayExtend: _ember['default'].computed('alert', function () {
      return this.hasCreateContractPermission() && !this.evergreenEnabled();
    }),

    displayExecuteEvergreen: _ember['default'].computed('alert', function () {
      return this.hasCreateContractPermission() && this.evergreenEnabled();
    }),

    // TODO: write tests when real logic will be implemented
    displayUploadButton: _ember['default'].computed('alert', function () {
      if (!this.get('permissionService').checkCompanyUserPermission(_quantuvisCmSpaConstantsPermissions.PERMISSIONS.ID.UPLOAD_DOCUMENT)) {
        return false;
      }

      var alert = this.get('alert');

      switch (alert.get('type').id) {
        case _quantuvisCmSpaConstantsContractAlertType.CONTRACT_ALERT_TYPE.CONTRACT_LEAD_REVIEW_ID:
        case _quantuvisCmSpaConstantsContractAlertType.CONTRACT_ALERT_TYPE.SIGN_OFF_NOTIFICATION_ID:
        case _quantuvisCmSpaConstantsContractAlertType.CONTRACT_ALERT_TYPE.MULTI_DEPARTMENT_REVIEW_ID:
        case _quantuvisCmSpaConstantsContractAlertType.CONTRACT_ALERT_TYPE.SINGLE_DEPARTMENT_REVIEW_ID:
        case _quantuvisCmSpaConstantsContractAlertType.CONTRACT_ALERT_TYPE.COMPARE_AND_COMBINE_ID:
          return true;
        default:
          return false;
      }
    }),

    // TODO: write tests when real logic will be implemented
    displayMarkAsCompletedButton: _ember['default'].computed('alert', function () {
      if (!this.get('permissionService').checkCompanyUserPermission(_quantuvisCmSpaConstantsPermissions.PERMISSIONS.ID.COMPLETE_STEPS)) {
        return false;
      }

      var alert = this.get('alert');

      switch (alert.get('type').id) {
        case _quantuvisCmSpaConstantsContractAlertType.CONTRACT_ALERT_TYPE.CONTRACT_LEAD_REVIEW_ID:
        case _quantuvisCmSpaConstantsContractAlertType.CONTRACT_ALERT_TYPE.SIGN_OFF_NOTIFICATION_ID:
        case _quantuvisCmSpaConstantsContractAlertType.CONTRACT_ALERT_TYPE.MULTI_DEPARTMENT_REVIEW_ID:
        case _quantuvisCmSpaConstantsContractAlertType.CONTRACT_ALERT_TYPE.SINGLE_DEPARTMENT_REVIEW_ID:
        case _quantuvisCmSpaConstantsContractAlertType.CONTRACT_ALERT_TYPE.COMPARE_AND_COMBINE_ID:
          return true;
        default:
          return false;
      }
    }),

    getDueDateMessage: function getDueDateMessage(dueDate) {
      if ((0, _moment['default'])(dueDate).isValid()) {
        return this.get('date').isOverdue(dueDate) ? 'was due on ' + this.get('date').formatDate(dueDate) : 'due on ' + this.get('date').formatDate(dueDate);
      } else {
        return '';
      }
    },

    getResponsibleNames: function getResponsibleNames(alert) {
      var names = [];

      alert.get('responsiblePersons').forEach(function (responsiblePerson) {
        names.push(responsiblePerson.firstName + ' ' + responsiblePerson.lastName);
      });

      return names.join(', ');
    },

    getFulfillingId: function getFulfillingId() {
      return this.get('alert.workflowStepId') || this.get('alert.workflowSubStepId');
    }
  });
});