define("quantuvis-cm-spa/constants/event-property-name", ["exports"], function (exports) {
  var EVENT_PROPERTY_NAME = {
    EFFECTIVE_DATE: 1,
    DOCUMENT_ID: 2,
    COMPANY_ID: 3,
    SENT_FILE_VERSION: 4,
    SENT_FILE_STATUS_ID: 5,
    SENT_FILE_RECEIVER: 6,
    SUBJECT: 7,
    MESSAGE: 8,
    DUE_DATE: 9,
    SIGN_OFF_ID: 10,
    SIGN_OFF_STATUS_ID: 11,
    SIGN_OFF_RECEIVER: 12,
    SIGN_OFF_REQUEST_DECLINE_REASON: 13,
    SIGN_OFF_CANCEL_REASON: 14,
    SIGNER_NAME: 15,
    SIGNER_TITLE: 16,
    SIGN_OFF_DECLINE_REASON: 17,
    SIGN_OFF_REASSIGN_REASON: 18,
    SIGN_OFF_ASSIGNER_NAME: 19,
    SIGN_OFF_ASSIGNER_EMAIL: 20,
    SIGN_OFF_ASSIGNER_TITLE: 21,
    SIGN_OFF_ASSIGNEE: 22,
    DOCUMENT_TERMINATE_REASON: 23
  };

  exports["default"] = EVENT_PROPERTY_NAME;
});