define('quantuvis-cm-spa/core-ui/components/cm-power-select-typeahead/trigger/component', ['exports', 'ember-power-select-typeahead/components/power-select-typeahead/trigger', 'ember'], function (exports, _emberPowerSelectTypeaheadComponentsPowerSelectTypeaheadTrigger, _ember) {
  var isBlank = _ember['default'].isBlank;
  var run = _ember['default'].run;
  exports['default'] = _emberPowerSelectTypeaheadComponentsPowerSelectTypeaheadTrigger['default'].extend({
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      var oldSelect = this.get('oldSelect');
      var newSelect = this.set('oldSelect', this.get('select'));
      var selectId = '#ember-power-select-typeahead-input-' + newSelect.uniqueId;

      // if no selection on init
      if (!oldSelect) {
        return;
      }

      if (newSelect.isActive) {
        var isOpen = newSelect.isOpen;
        var searchText = newSelect.searchText;
        var selectAPI = newSelect.actions;
        var resultsCount = newSelect.resultsCount;

        var showResultsWithEmptyInput = this.get('_targetObject.showResultsWithEmptyInput');
        var noMatchesMessage = this.get('_targetObject.noMatchesMessage');

        if (!searchText && resultsCount && showResultsWithEmptyInput && !isOpen) {
          run.schedule('actions', null, selectAPI.open);
        }

        if (!noMatchesMessage && !resultsCount && isOpen) {
          run.schedule('actions', null, selectAPI.close);
        }
      }

      /*
        * We need to update the input field with value of the selected option whenever we're closing
        * the select box.
        */
      if (oldSelect.isOpen && !newSelect.isOpen && newSelect.searchText) {
        var input = document.querySelector(selectId);
        var newText = this.getSelectedAsText();

        if (input.value !== newText) {
          input.value = newText;
        }

        this.set('text', newText);
      }

      if (newSelect.lastSearchedText !== oldSelect.lastSearchedText) {
        if (isBlank(newSelect.lastSearchedText)) {
          run.schedule('actions', null, newSelect.actions.close, null, true);
        } else {
          run.schedule('actions', null, newSelect.actions.open);
        }
      }
    },

    getSelectedAsText: function getSelectedAsText() {
      var labelPath = this.get('extra.labelPath');
      var allowCustomValue = this.get('_targetObject.allowCustomValue');
      var value = this.get('select.selected');

      if (labelPath && allowCustomValue) {
        var originalValue = this.get('select.selected');
        var isValueObject = originalValue instanceof Object;

        value = isValueObject ? this.get('select.selected.' + labelPath) : originalValue;
      } else if (labelPath && !allowCustomValue) {
        value = this.get('select.selected.' + labelPath);
      }

      return value ? value : '';
    },

    actions: {
      handleKeydown: function handleKeydown(e) {
        var keyCodes = {
          enter: 13,
          esc: 27,
          space: 32,
          z: 90,
          zero: 48,
          arrowUp: 38,
          arrowDown: 40
        };

        // up or down arrow and if not open, no-op and prevent parent handlers from being notified
        if ([keyCodes.arrowUp, keyCodes.arrowDown].indexOf(e.keyCode) > -1 && !this.get('select.isOpen')) {
          e.stopPropagation();

          return;
        }

        // Keys 0-9, a-z or SPACE
        var isLetter = e.keyCode >= keyCodes.zero && e.keyCode <= keyCodes.z || e.keyCode === keyCodes.space;

        // if isLetter, escape or enter, prevent parent handlers from being notified
        if (isLetter || [keyCodes.enter, keyCodes.esc].indexOf(e.keyCode) > -1) {
          var select = this.get('select');

          // open if loading msg configured
          if (!select.isOpen && this.get('loadingMessage')) {
            run.schedule('actions', null, select.actions.open);
          }

          if (e.keyCode === keyCodes.esc) {
            return e.stopPropagation();
          }

          e.stopPropagation();
        }

        // optional, passed from power-select
        var onkeydown = this.get('onKeydown');

        if (onkeydown && onkeydown(e) === false) {
          return false;
        }
      }
    }
  });
});