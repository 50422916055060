define('quantuvis-cm-spa/services/profile-settings', ['exports', 'ember', 'ember-changeset', 'ember-changeset-validations', 'quantuvis-cm-spa/validations/profile-settings'], function (exports, _ember, _emberChangeset, _emberChangesetValidations, _quantuvisCmSpaValidationsProfileSettings) {
  exports['default'] = _ember['default'].Service.extend({
    dialog: _ember['default'].inject.service(),
    store: _ember['default'].inject.service(),

    companiesOptions: {},

    createChangeset: function createChangeset(model) {
      var changeset = new _emberChangeset['default'](model, (0, _emberChangesetValidations['default'])(_quantuvisCmSpaValidationsProfileSettings['default']), _quantuvisCmSpaValidationsProfileSettings['default'], {
        skipValidate: true
      });

      this.set('changeset', changeset);

      return changeset;
    },

    showAddCompanyDialog: function showAddCompanyDialog(formInput) {
      var _this = this;

      var options = {
        title: 'Add Company',
        acceptText: 'Add',
        componentName: 'cm-profile-settings/add-company-dialog-body',
        className: 'add-company-dialog'
      };

      this.get('dialog').show('dialogs/cm-dialog', null, this, options).then(function () {
        var selectedComanyIds = _this.get('companiesOptions').getAttributeSelection();
        var selectedCompanyObjects = selectedComanyIds.map(function (id) {
          return _this.get('store').peekRecord('user-management/company', id);
        });

        var oldInputValue = formInput.value.map(function (item) {
          return item;
        }); //for getting data from relosved promise for first time

        oldInputValue.pushObjects(selectedCompanyObjects);
        formInput.update(oldInputValue);
        _this.set('companiesOptions', []);
      })['catch'](function () {
        _this.set('companiesOptions', []);
      });
    },

    /**
     * Clear all data saved in service related to Profile Settings page
     */
    cleanupData: function cleanupData() {
      this.set('companiesOptions', {});
      this.set('changeset', {});
    },

    showConfirmation: function showConfirmation() {
      var options = {
        title: 'Profile Settings',
        confirmMessage: 'Do you want to save changes in the settings?',
        acceptText: 'Yes'
      };

      return this.get('dialog').show('dialogs/cm-dialog', 'dialog-templates/cm-confirmation', this, options);
    }
  });
});