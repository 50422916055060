define('quantuvis-cm-spa/services/contract-status-service', ['exports', 'ember', 'quantuvis-cm-spa/constants/workflow-step-departments-state', 'quantuvis-cm-spa/constants/workflow-substep-type'], function (exports, _ember, _quantuvisCmSpaConstantsWorkflowStepDepartmentsState, _quantuvisCmSpaConstantsWorkflowSubstepType) {
  exports['default'] = _ember['default'].Service.extend({
    date: _ember['default'].inject.service(),

    processSubsteps: function processSubsteps(substeps) {
      var _this = this;

      return substeps.map(function (substep) {
        _this.defineStateText(substep);
        _this.defineDateText(substep);

        return substep;
      });
    },

    defineStateText: function defineStateText(substep) {
      switch (substep.get('typeId')) {
        case _quantuvisCmSpaConstantsWorkflowSubstepType.CONTRACT_LEAD_REVIEW_ID:
          substep.stateText = _quantuvisCmSpaConstantsWorkflowStepDepartmentsState.WORKFLOW_SUBSTEP_NAMES[substep.get('stateId')] + ' by Contract Lead';
          break;
        case _quantuvisCmSpaConstantsWorkflowSubstepType.DEPARTMENT_REVIEW_ID:
          substep.stateText = _quantuvisCmSpaConstantsWorkflowStepDepartmentsState.WORKFLOW_SUBSTEP_NAMES[substep.get('stateId')] + ' by ' + substep.get('departmentName');
          break;
        default:
          substep.stateText = '' + _quantuvisCmSpaConstantsWorkflowStepDepartmentsState.WORKFLOW_SUBSTEP_NAMES[substep.get('stateId')];
      }

      return substep.stateText;
    },

    defineDateText: function defineDateText(substep) {
      if (substep.get('stateId') === _quantuvisCmSpaConstantsWorkflowStepDepartmentsState.WORKFLOW_STEP_DEPARTMENT_STATE.PENDING || substep.get('stateId') === _quantuvisCmSpaConstantsWorkflowStepDepartmentsState.WORKFLOW_STEP_DEPARTMENT_STATE.IN_PROGRESS) {
        substep.isOverdue = this.get('date').isOverdue(substep.get('dueDate'));
        substep.dateText = substep.get('dueDate') ? 'due ' + this.get('date').formatDate(substep.get('dueDate')) : '';
      } else {
        substep.dateText = 'on ' + this.get('date').formatDate(substep.get('completedDate'));
      }
    }
  });
});