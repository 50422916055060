define('quantuvis-cm-spa/components/cm-timeline/cm-timeline-item/cm-timeline-manual-sign-off', ['exports', 'quantuvis-cm-spa/components/cm-timeline/cm-timeline-item/cm-workflow-step-base', 'ember', 'quantuvis-cm-spa/constants/workflow-step-type'], function (exports, _quantuvisCmSpaComponentsCmTimelineCmTimelineItemCmWorkflowStepBase, _ember, _quantuvisCmSpaConstantsWorkflowStepType) {
  exports['default'] = _quantuvisCmSpaComponentsCmTimelineCmTimelineItemCmWorkflowStepBase['default'].extend({
    classNames: ['cm-timeline-manual-sign-off'],
    stepName: _quantuvisCmSpaConstantsWorkflowStepType.MANUAL_SIGN_OFF.name,
    timeline: _ember['default'].inject.service(),

    computedUsers: _ember['default'].computed('data.responsiblePersonsAccounts', function () {
      return this.getResponsiblePersons();
    })
  });
});