define('quantuvis-cm-spa/models/user-management/users/profile', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {
  exports['default'] = _emberData['default'].Model.extend({
    firstName: _emberData['default'].attr('string', { defaultValue: '' }),
    lastName: _emberData['default'].attr('string', { defaultValue: '' }),
    company: _emberData['default'].belongsTo('user-management/company'),
    email: _emberData['default'].attr('string', { defaultValue: '' }),
    jobTitle: _emberData['default'].attr('string', { defaultValue: '' }),
    phoneNumber: _emberData['default'].attr('string', { defaultValue: '' }),
    departments: _emberData['default'].attr(),
    roles: _emberData['default'].attr(),
    timezone: _emberData['default'].belongsTo('user-management/users/timezone'),
    myAccounts: _emberData['default'].hasMany('user-management/company'),
    emailPreference: _emberData['default'].attr(),

    fullName: _ember['default'].computed('firstName', 'lastName', function () {
      return this.get('firstName') + ' ' + this.get('lastName');
    })
  });
});