define('quantuvis-cm-spa/components/business-admin/cm-template/cm-edit-template', ['exports', 'ember', 'ember-changeset', 'ember-changeset-validations', 'quantuvis-cm-spa/validations/file-template'], function (exports, _ember, _emberChangeset, _emberChangesetValidations, _quantuvisCmSpaValidationsFileTemplate) {
  exports['default'] = _ember['default'].Component.extend({
    store: _ember['default'].inject.service(),
    uniqueDocumentTemplateValidator: _ember['default'].inject.service(),
    changeset: {},
    model: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.set('model', this.get('contextObject.selectedTemplate'));
      this.initChangeset();
    },

    initChangeset: function initChangeset() {
      this.attachUniqueNameValidator(_quantuvisCmSpaValidationsFileTemplate['default']);
      var changeset = new _emberChangeset['default'](this.get('model'), (0, _emberChangesetValidations['default'])(_quantuvisCmSpaValidationsFileTemplate['default']), _quantuvisCmSpaValidationsFileTemplate['default'], {
        skipValidate: true
      });

      this.set('changeset', changeset);
    },

    attachUniqueNameValidator: function attachUniqueNameValidator(validators) {
      var uniqueness = this.get('uniqueDocumentTemplateValidator').validateUniqueness(this.get('model.id'));
      var index = validators.name.findIndex(function (validator) {
        return validator.toString() === uniqueness.toString();
      });

      if (index >= 0) {
        validators.name[index] = uniqueness;
      } else {
        validators.name.push(uniqueness);
      }
    },

    actions: {
      submit: function submit() {
        var _this = this;

        this.set('contextObject.showDialogSpinner', true);
        var changeset = this.get('changeset');

        changeset.save().then(function () {
          return _this.saveTemplate();
        });
      }
    },
    saveTemplate: function saveTemplate() {
      var _this2 = this;

      var template = this.get('model');

      template.save().then(function () {
        _this2.sendAction('accepted');
      })['catch'](function () {
        _this2.sendAction('accepted');
      });
    }
  });
});