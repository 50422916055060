define('quantuvis-cm-spa/services/navigation', ['exports', 'ember', 'quantuvis-cm-spa/constants/permissions'], function (exports, _ember, _quantuvisCmSpaConstantsPermissions) {
  exports['default'] = _ember['default'].Service.extend({
    permissionService: _ember['default'].inject.service(),
    items: [{
      title: 'Contracts',
      route: 'contracts',
      getIsAllowed: function getIsAllowed(permissionService) {
        var isQuantuvisAdmin = permissionService.checkAdminPermissionByApplication(_quantuvisCmSpaConstantsPermissions.PERMISSIONS.ID.SA_READ, _quantuvisCmSpaConstantsPermissions.PERMISSIONS.APPLICATION.UM);

        return !isQuantuvisAdmin;
      }
    }, {
      title: 'Business Admin',
      route: 'business-admin',
      getIsAllowed: function getIsAllowed(permissionService) {
        return permissionService.checkGlobalUserPermission(_quantuvisCmSpaConstantsPermissions.PERMISSIONS.ID.CM_ADMIN) || permissionService.checkAdminPermissionByApplication(_quantuvisCmSpaConstantsPermissions.PERMISSIONS.ID.CM_READ, _quantuvisCmSpaConstantsPermissions.PERMISSIONS.APPLICATION.CM);
      }
    }],
    allowedItems: _ember['default'].computed('', function () {
      var _this = this;

      return this.get('items').filter(function (item) {
        return item.getIsAllowed(_this.get('permissionService'));
      }).map(function (item) {
        return {
          'title': item.title,
          'route': item.route
        };
      });
    })
  });
});