define('quantuvis-cm-spa/core-ui/components/cm-form/form-config', ['exports'], function (exports) {
  exports['default'] = {
    label: {
      submit: 'Submit'
    },
    css: {
      form: 'cm-form',
      group: 'cm-form-group',
      control: 'cm-form-control',
      label: 'cm-form-label',
      radio: 'cm-radio',
      checkbox: 'cm-form-checkbox',
      customCheckbox: 'cm-custmom-checkbox',
      help: 'cm-help-block',
      button: 'cm-button cm-button-default',
      submit: 'cm-button cm-button-primary',
      error: 'cm-group-error',
      valid: 'cm-group-valid'
    }
  };
});