define('quantuvis-cm-spa/routes/application', ['exports', 'ember', 'ember-simple-auth/mixins/application-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsApplicationRouteMixin) {

  var MAIN_APPLICATION_SPINNER_ID = 'main-application-spinner';

  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsApplicationRouteMixin['default'], {
    loadingSpinner: _ember['default'].inject.service(),
    dialogManager: _ember['default'].inject.service(),

    spinner: null,

    actions: {
      /**
       * Show loading spinner for first time app loading
       */
      loading: function loading() {
        if (_ember['default'].$('#' + MAIN_APPLICATION_SPINNER_ID).length === 0) {
          var target = _ember['default'].$('body');
          var spinner = this.get('loadingSpinner').getJsSpinner().spin();

          target.append(spinner.el);
          this.set('spinner', spinner);
        }
        this.get('loadingSpinner').show(MAIN_APPLICATION_SPINNER_ID);
      },

      didTransition: function didTransition() {
        if (this.get('spinner')) {
          this.get('spinner').stop();
          this.set('spinner', null);
        }
        this.get('dialogManager').closeOpenDialogs();
        this.get('loadingSpinner').hide(MAIN_APPLICATION_SPINNER_ID);
      }
    }
  });
});