define('quantuvis-cm-spa/mixins/cm-multistep-data', ['exports', 'ember', 'quantuvis-cm-spa/constants/workflow-substep-type', 'quantuvis-cm-spa/constants/workflow-step-element-type'], function (exports, _ember, _quantuvisCmSpaConstantsWorkflowSubstepType, _quantuvisCmSpaConstantsWorkflowStepElementType) {
  exports['default'] = _ember['default'].Mixin.create({

    computedSubItems: _ember['default'].computed.map('data.workflowStepElements', function (workflowStepElement) {
      return {
        template: this.getWorkflowStepElementTemplate(workflowStepElement),
        data: this.getWorkflowStepElementData(workflowStepElement)
      };
    }),

    getSubstepElementTemplate: function getSubstepElementTemplate(substepTypeId) {
      switch (substepTypeId) {
        case _quantuvisCmSpaConstantsWorkflowSubstepType['default'].DEPARTMENT_REVIEW_ID:
          return 'cm-timeline/cm-timeline-item/cm-timeline-department-review';
        case _quantuvisCmSpaConstantsWorkflowSubstepType['default'].CONTRACT_LEAD_REVIEW_ID:
          return 'cm-timeline/cm-timeline-item/cm-timeline-lead-review';
        default:
          return null;
      }
    },
    getWorkflowStepElementTemplate: function getWorkflowStepElementTemplate(workflowStepElement) {
      switch (workflowStepElement.get('type.id')) {
        case _quantuvisCmSpaConstantsWorkflowStepElementType['default'].STEP_EVENT:
          return 'cm-timeline/cm-timeline-item/cm-timeline-event';
        case _quantuvisCmSpaConstantsWorkflowStepElementType['default'].SUBSTEP:
          return this.getSubstepElementTemplate(workflowStepElement.get('substep.type.id'));
        default:
          return null;
      }
    },

    getWorkflowStepElementData: function getWorkflowStepElementData(workflowStepElement) {
      switch (workflowStepElement.get('type.id')) {
        case _quantuvisCmSpaConstantsWorkflowStepElementType['default'].STEP_EVENT:
          return workflowStepElement.get('workflowStepEvent.event');
        case _quantuvisCmSpaConstantsWorkflowStepElementType['default'].SUBSTEP:
          return workflowStepElement.get('substep');
        default:
          throw 'Wrong workflow step element type id';
      }
    }
  });
});