define('quantuvis-cm-spa/components/cm-workflow-step/cm-extended', ['exports', 'ember', 'quantuvis-cm-spa/components/cm-workflow-step', 'ember-changeset', 'ember-changeset-validations', 'quantuvis-cm-spa/validations/workflow-step-extended', 'moment', 'quantuvis-cm-spa/constants/date-format', 'quantuvis-cm-spa/constants/workflow-step-departments-state', 'quantuvis-cm-spa/constants/workflow-substep-type', 'quantuvis-cm-spa/constants/workflow-step-type', 'quantuvis-cm-spa/constants/event-type', 'quantuvis-cm-spa/models/dropdown-type-options'], function (exports, _ember, _quantuvisCmSpaComponentsCmWorkflowStep, _emberChangeset, _emberChangesetValidations, _quantuvisCmSpaValidationsWorkflowStepExtended, _moment, _quantuvisCmSpaConstantsDateFormat, _quantuvisCmSpaConstantsWorkflowStepDepartmentsState, _quantuvisCmSpaConstantsWorkflowSubstepType, _quantuvisCmSpaConstantsWorkflowStepType, _quantuvisCmSpaConstantsEventType, _quantuvisCmSpaModelsDropdownTypeOptions) {
  exports['default'] = _quantuvisCmSpaComponentsCmWorkflowStep['default'].extend({
    classNames: ['cm-workflow-step-extended'],
    classNameBindings: ['positionClass'],
    positionClass: _ember['default'].computed('position', function () {
      return this.get('isFromRequired') ? '' : this.get('workflow').getStepPositionClass(this.get('position'));
    }),
    workflow: _ember['default'].inject.service(),
    selectableStepTypes: new _quantuvisCmSpaModelsDropdownTypeOptions.SelectDropdownOptions(_quantuvisCmSpaConstantsWorkflowStepType.SELECTABLE_STEP_TYPES).options,
    useDepartmentAnimation: false,
    finishedSubstepsStates: [_quantuvisCmSpaConstantsWorkflowStepDepartmentsState.WORKFLOW_STEP_DEPARTMENT_STATE.VOIDED, _quantuvisCmSpaConstantsWorkflowStepDepartmentsState.WORKFLOW_STEP_DEPARTMENT_STATE.COMPLETED],
    finishedSubstepsCount: 0,
    selectedDepartments: [],
    companyDepartments: _ember['default'].computed.alias('departments'),
    hasEvents: _ember['default'].computed.notEmpty('model.events'),
    leads: [],

    /**
     * Flag indicate disable or enable Workflow step type control
     */
    isChangeTypeDisabled: _ember['default'].computed('finishedSubstepsCount', function () {
      return this.get('finishedSubstepsCount') > 0 || this.get('hasEvents') || this.get('hasAttachments');
    }),

    isAbleToDelete: _ember['default'].computed('model.substeps', 'editable', function () {
      var hasActions = this.get('hasEvents') || this.get('hasAttachments') || this.get('model.hasCompletedSubsteps');

      return this.get('editable') && !hasActions;
    }),

    isChangeCountOfDepartmentsDisabled: _ember['default'].computed('hasAttachments', 'departmentLength', 'hasEvents', function () {
      return (this.get('hasAttachments') || this.get('hasEvents')) && this.get('model.departmentsCount') === 1 || this.get('isContractLeadReviewSubstepInProgress');
    }),

    isContractLeadReviewSubstepInProgress: _ember['default'].computed('model.substeps', function () {
      return this.get('isDepartmentReview') && this.get('model.substeps').length > 0 && this.get('model.substepsSortedByPosition.lastObject.type.id') === _quantuvisCmSpaConstantsWorkflowSubstepType.CONTRACT_LEAD_REVIEW_ID && this.get('model.substepsSortedByPosition.lastObject.state.id') === _quantuvisCmSpaConstantsWorkflowStepDepartmentsState.WORKFLOW_STEP_DEPARTMENT_STATE.IN_PROGRESS;
    }),

    hasUploadedNewVersion: _ember['default'].computed('model', function () {
      if (this.get('hasEvents')) {
        var events = this.get('model.events');
        var contractReceivedEvent = events.find(function (event) {
          var typeId = event.event.eventType.id;

          return typeId === _quantuvisCmSpaConstantsEventType.EVENT_TYPE.DOCUMENT_RECEIVED || typeId === _quantuvisCmSpaConstantsEventType.EVENT_TYPE.SIGNED_DOCUMENT_RECEIVED;
        });

        return !!contractReceivedEvent;
      }

      return false;
    }),

    /**
     * Calculate Departments Length options
     */
    departmentLength: _ember['default'].computed('departments', 'finishedSubstepsCount', function () {
      var substepsCountOffset = 1;
      // All available departments
      var departmentsLength = this.get('departments').get('length');
      // Finished departments (not editable)
      var finishedSubstepsCount = this.get('finishedSubstepsCount') === 0 ? substepsCountOffset : this.get('finishedSubstepsCount');
      // Count of options in dropdown
      var size = departmentsLength - finishedSubstepsCount + substepsCountOffset;

      // Adjust labels
      return new Array(size).fill().map(function (value, index) {
        return finishedSubstepsCount + index;
      });
    }),

    isDepartmentReview: _ember['default'].computed('changeset.type', function () {
      var id = this.get('changeset').get('type.id');

      return parseInt(id) === _quantuvisCmSpaConstantsWorkflowStepType.DEPARTMENT_REVIEW_ID;
    }),

    init: function init() {
      this.initStepType();
      this.initSebstepProperties();
      this.initDepartmentsLength();
      this._super.apply(this, arguments);
    },

    initStepType: function initStepType() {
      var id = this.get('model.type.id');

      if (id === _quantuvisCmSpaConstantsWorkflowStepType.MULTI_DEPARTMENT_REVIEW_ID) {
        this.set('model.type', _quantuvisCmSpaConstantsWorkflowStepType.DEPARTMENT_REVIEW);
      }
    },

    initSebstepProperties: function initSebstepProperties() {
      var _this = this;

      var selectedDepartments = [];
      var finishedSubstepsCount = 0;

      this.get('model').get('substepsSortedByPosition').forEach(function (substep) {
        var id = substep.get('department') ? parseInt(substep.get('department.id')) : substep.get('departmentId');

        if (id) {
          selectedDepartments.push(id);
        }
        if (_this.isFinishedSubstep(substep)) {
          ++finishedSubstepsCount;
        }
      });
      this.set('selectedDepartments', selectedDepartments);
      this.set('finishedSubstepsCount', finishedSubstepsCount);
    },

    isFinishedSubstep: function isFinishedSubstep(substep) {
      return substep.get('state') && this.get('finishedSubstepsStates').includes(substep.get('state.id')) || substep.get('attachments.length');
    },

    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);
      this.get('changesetMap')['delete'](this.elementId);
    },

    /**
     * Init Departments Length Start Value
     */
    initDepartmentsLength: function initDepartmentsLength() {
      var countableDepartments = this.get('model').get('substepsSortedByPosition').filter(function (substep) {
        return substep.get('type.id') !== _quantuvisCmSpaConstantsWorkflowSubstepType.CONTRACT_LEAD_REVIEW_ID;
      });

      var length = countableDepartments.length;

      this.set('model.departmentsCount', length);

      return length;
    },

    initChangeset: function initChangeset() {
      var model = this.get('model');

      if (this.get('expandOnErrors')) {
        model.set('expandOnInvalid', this.expandOnInvalid.bind(this));
      }

      this.prepareModelDatesProperties();
      var changeset = new _emberChangeset['default'](model, (0, _emberChangesetValidations['default'])(_quantuvisCmSpaValidationsWorkflowStepExtended['default']), _quantuvisCmSpaValidationsWorkflowStepExtended['default'], {
        skipValidate: true
      });

      this.set('changeset', changeset);
      this.get('changesetMap').set(this.elementId, changeset);
    },

    prepareModelDatesProperties: function prepareModelDatesProperties() {
      var date = this.get('model.dueDate'),
          isDateValid = (0, _moment['default'])(date).isValid();

      if (date && isDateValid) {
        this.set('model.dueDate', (0, _moment['default'])(date).format(_quantuvisCmSpaConstantsDateFormat['default'].MM_DD_YYYY));
      }
    },

    actions: {
      expandOnDepartmentInvalid: function expandOnDepartmentInvalid() {
        this.expandOnInvalid();
      },

      submit: function submit() {},

      onMoveDown: function onMoveDown() {
        this.sendAction('moveDownClick', this.get('position'));
      },

      onMoveUp: function onMoveUp() {
        this.sendAction('moveUpClick', this.get('position'));
      },

      deleteSubStep: function deleteSubStep(stepInfo) {
        var substeps = this.get('model.substepsSortedByPosition');

        substeps.removeAt(stepInfo.position);

        this.get('changeset').set('departmentsCount', this.initDepartmentsLength());
      },

      onStepTypeChange: function onStepTypeChange(updateObject) {
        this.set('model.type', updateObject.value);
        updateObject.control.update(updateObject.value);
        var typeID = updateObject.value.id;

        /**
         * Reset all added departments when step type was change
         */
        this.resetDepartments();

        if (typeID == _quantuvisCmSpaConstantsWorkflowStepType.DEPARTMENT_REVIEW_ID) {
          this.createEmptyDepartmentSubstep();
        }
      }
    },

    /**
     * Action for Department Length Control
     * @param control               Department Length control
     * @param departmentsLength     Selected value
     */
    onDepartmentLengthChange: function onDepartmentLengthChange(control, departmentsLength) {
      this.set('useDepartmentAnimation', true);
      /**
       * Update model value
       */
      control.update(departmentsLength);

      /**
       * Already added departments
       */
      var substeps = this.get('model.substepsSortedByPosition');
      var departmentReviewSubsteps = [];
      var contractLeadReviewSubsteps = [];

      substeps.forEach(function (substep) {
        if (substep.get('type.id') == _quantuvisCmSpaConstantsWorkflowSubstepType.CONTRACT_LEAD_REVIEW_ID) {
          contractLeadReviewSubsteps.push(substep);
        } else {
          departmentReviewSubsteps.push(substep);
        }
      });
      /**
       * Departments length difference
       * @type {number}
       */
      var departmentsAdjustNumber = departmentsLength - departmentReviewSubsteps.get('length');

      /**
       * If difference is positive we need adjust new departments
       */

      if (departmentsAdjustNumber > 0) {

        for (var departmentIndex = 1; departmentIndex <= departmentsAdjustNumber; ++departmentIndex) {
          var adjustDepartmentPosition = departmentIndex + departmentReviewSubsteps.get('length') - 1;

          departmentReviewSubsteps.push(this.createSubstepWithPosition(adjustDepartmentPosition));
        }
      } else {
        /**
         * If difference is negative number - we need to remove redundant departments from end of list
         */
        departmentReviewSubsteps.splice(departmentsAdjustNumber, Math.abs(departmentsAdjustNumber));
      }
      departmentReviewSubsteps.push.apply(departmentReviewSubsteps, contractLeadReviewSubsteps);
      this.set('model.substeps', departmentReviewSubsteps.slice());
    },

    createEmptyDepartmentSubstep: function createEmptyDepartmentSubstep() {
      this.set('model.substeps', [this.get('workflow').createSubstep()]);
    },

    resetDepartments: function resetDepartments() {
      var defaultDepartmentsCount = 1;

      this.get('changeset').set('departmentsCount', defaultDepartmentsCount);
      this.set('model.substeps', []);
    },

    createSubstepWithPosition: function createSubstepWithPosition(position) {
      var substep = this.get('workflow').createSubstep();

      substep.set('position', position);

      return substep;
    }
  });
});