define('quantuvis-cm-spa/serializers/notification', ['exports', 'quantuvis-cm-spa/serializers/application'], function (exports, _quantuvisCmSpaSerializersApplication) {
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  exports['default'] = _quantuvisCmSpaSerializersApplication['default'].extend({
    normalizeArrayResponse: function normalizeArrayResponse(store, primaryModelClass, payload, id, requestType) {
      payload = this.normalizePageableResponse(payload, primaryModelClass.modelName);

      return this._normalizeResponse(store, primaryModelClass, payload, id, requestType, false);
    },

    normalizePageableResponse: function normalizePageableResponse(payload, modelName) {
      var _ref;

      var meta = {
        totalElements: payload.totalElements,
        totalPages: payload.totalPages
      };

      return _ref = {}, _defineProperty(_ref, modelName, payload.items), _defineProperty(_ref, 'meta', meta), _ref;
    }
  });
});