define('quantuvis-cm-spa/components/cm-forgot-password', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['cm-forgot-password'],
    passwordService: _ember['default'].inject.service('password'),

    successFormSent: false,
    failFormSent: false,

    actions: {
      submit: function submit() {
        var _this = this;

        this.get('passwordService').sendForgotPassword(this.get('model.credentials.email'), this.get('model.application')).then(function () {
          return _this.set('successFormSent', true);
        })['catch'](function () {
          return _this.set('failFormSent', true);
        });
      }
    }
  });
});