define('quantuvis-cm-spa/components/cm-edit-workflow/timeline/digital-sign-off-step', ['exports', 'quantuvis-cm-spa/components/cm-timeline/cm-timeline-item/cm-timeline-digital-sign-off', 'quantuvis-cm-spa/constants/workflow-step-element-type'], function (exports, _quantuvisCmSpaComponentsCmTimelineCmTimelineItemCmTimelineDigitalSignOff, _quantuvisCmSpaConstantsWorkflowStepElementType) {
  exports['default'] = _quantuvisCmSpaComponentsCmTimelineCmTimelineItemCmTimelineDigitalSignOff['default'].extend({
    getWorkflowStepElementTemplate: function getWorkflowStepElementTemplate(workflowStepElement) {
      switch (workflowStepElement.type.id) {
        case _quantuvisCmSpaConstantsWorkflowStepElementType['default'].STEP_EVENT:
          return 'cm-edit-workflow/timeline/event';
        case _quantuvisCmSpaConstantsWorkflowStepElementType['default'].SUBSTEP:
          return 'cm-edit-workflow/timeline/digital-sign-off-substep';
        default:
          return null;
      }
    }
  });
});