define('quantuvis-cm-spa/services/timeline-attachment', ['exports', 'ember', 'quantuvis-cm-spa/constants/attachment-status'], function (exports, _ember, _quantuvisCmSpaConstantsAttachmentStatus) {
  exports['default'] = _ember['default'].Service.extend({
    hasSignedInternallyVersion: function hasSignedInternallyVersion(timelineItems) {
      var attachmentMatchFunction = function attachmentMatchFunction(attachment) {
        return _ember['default'].get(attachment, 'status.id') === _quantuvisCmSpaConstantsAttachmentStatus['default'].SIGNED_INTERNALLY;
      };

      return Boolean(timelineItems.find(function (item) {
        var eventAttachments = _ember['default'].get(item, 'event.attachments');
        var stepAttachments = _ember['default'].get(item, 'workflowStep.attachments');
        var stepEvents = _ember['default'].get(item, 'workflowStep.events');

        if (eventAttachments && eventAttachments.find(attachmentMatchFunction)) {
          return true;
        }

        if (stepAttachments && stepAttachments.find(attachmentMatchFunction)) {
          return true;
        }

        return stepEvents && stepEvents.find(function (stepEvent) {
          return stepEvent.get('event.attachments').find(attachmentMatchFunction);
        });
      }));
    },

    prepareVersionsForDropdown: function prepareVersionsForDropdown(versions) {
      var _this = this;

      versions.forEach(function (fileVersion) {
        var name = 'V ' + fileVersion.get('version') + _this.getVersionNamePostfix(fileVersion);

        fileVersion.set('name', name);
      });

      return versions;
    },

    getVersionNamePostfix: function getVersionNamePostfix(fileVersion) {
      switch (fileVersion.get('status.id')) {
        case _quantuvisCmSpaConstantsAttachmentStatus['default'].SIGNED_INTERNALLY:
          return ' (signed internally)';
        case _quantuvisCmSpaConstantsAttachmentStatus['default'].SIGNED_BY_BOTH:
          return ' (signed by both)';
        case _quantuvisCmSpaConstantsAttachmentStatus['default'].SIGNED_EXTERNALLY:
          return ' (signed externally)';
        case _quantuvisCmSpaConstantsAttachmentStatus['default'].DECLINED:
          return ' (declined)';
        default:
          return '';
      }
    },

    prepareLastVersion: function prepareLastVersion(lastVersion) {
      if (lastVersion.get('status.id') === _quantuvisCmSpaConstantsAttachmentStatus['default'].NOT_SIGNED) {
        lastVersion.set('name', lastVersion.get('name').concat(' (current)'));
      }

      return lastVersion;
    }
  });
});