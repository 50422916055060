define('quantuvis-cm-spa/mixins/workflow-steps', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({
    /**
     * Template steps
     */
    steps: [],

    /**
     * Add new step to workflow
     * @param step
     * @param position
     */
    addStep: function addStep(step, position) {
      var steps = this.get('workflowStepsSorted');

      steps.splice(position, 0, step);
      this.updateStepsConfiguration(steps);
    },

    /**
     * Remove step from workflow
     * @param index
     */
    removeStep: function removeStep(index) {
      var steps = this.get('workflowStepsSorted');

      steps.splice(index, 1);
      this.updateStepsConfiguration(steps);
    },

    /**
     * Move sortable step up in steps array
     * @param currentIndex
     */
    moveStepUp: function moveStepUp(currentIndex) {
      this.swapSteps(currentIndex, currentIndex - 1);
    },

    /**
     * Move sortable step down in steps array
     * @param currentIndex
     */
    moveStepDown: function moveStepDown(currentIndex) {
      this.swapSteps(currentIndex, currentIndex + 1);
    },

    /**
     * Swap sortable steps in array by positions
     * @param position1
     * @param position2
     */
    swapSteps: function swapSteps(position1, position2) {
      var sortableSteps = this.get('workflowStepsSorted');
      var currentStep = sortableSteps[position1];

      sortableSteps.splice(position1, 1);
      sortableSteps.splice(position2, 0, currentStep);
      this.updateStepsConfiguration(sortableSteps);
    },

    updateStepsConfiguration: function updateStepsConfiguration(steps) {
      this.set('workflowStepsSorted', steps.slice());
    },

    getCombinedSteps: function getCombinedSteps() {
      return this.get('workflowStepsRequired').concat(this.get('workflowStepsSorted'));
    }
  });
});