define('quantuvis-cm-spa/services/sign-handler', ['exports', 'ember', 'quantuvis-cm-spa/mixins/envelope-validator-mixin', 'quantuvis-cm-spa/constants/sign-events'], function (exports, _ember, _quantuvisCmSpaMixinsEnvelopeValidatorMixin, _quantuvisCmSpaConstantsSignEvents) {
  exports['default'] = _ember['default'].Service.extend(_quantuvisCmSpaMixinsEnvelopeValidatorMixin['default'], {
    store: _ember['default'].inject.service(),
    handleEvents: [_quantuvisCmSpaConstantsSignEvents.SIGN_DECLINED, _quantuvisCmSpaConstantsSignEvents.SIGN_COMPLETED, _quantuvisCmSpaConstantsSignEvents.SIGN_COMPLETED_VIEW],

    handleSign: function handleSign(request) {
      return this.isValidSignRequest(request) && this.syncSignOff(request.envelopeId, request.event);
    },

    isValidSignRequest: function isValidSignRequest(request) {
      return this.requestHasValidEvent(request) && this.requestHasValidEnvelope(request);
    },

    requestHasValidEnvelope: function requestHasValidEnvelope(request) {
      return request.hasOwnProperty('envelopeId') && this.isValidEnvelopeId(request.envelopeId);
    },

    requestHasValidEvent: function requestHasValidEvent(request) {
      return request.hasOwnProperty('event') && this.handleEvents.includes(request.event);
    },

    syncSignOff: function syncSignOff(envelopeId, eventName) {
      return this.get('store').adapterFor('signoff').syncSignOff(envelopeId)['catch'](function (error) {
        return _ember['default'].Logger.error('Failed to synchronise sign off', { envelopeId: envelopeId, eventName: eventName, error: error });
      });
    }
  });
});