define('quantuvis-cm-spa/router', ['exports', 'ember', 'quantuvis-cm-spa/config/environment'], function (exports, _ember, _quantuvisCmSpaConfigEnvironment) {

  var Router = _ember['default'].Router.extend({
    location: _quantuvisCmSpaConfigEnvironment['default'].locationType,
    rootURL: _quantuvisCmSpaConfigEnvironment['default'].rootURL
  });

  Router.map(function () {
    this.route('contracts', { path: '/' });
    this.route('workflow');
    this.route('create-contract', { path: 'create-contract/:contractTypeId' });
    this.route('contract-details', { path: 'contract-details/:contractId' });
    this.route('login', function () {
      this.route('rebate-negotiation');
      this.route('managment');
    });
    this.route('forgot-password', { path: 'forgot-password/:application' });
    this.route('reset-password', { path: 'reset-password/:application/:token' });
    this.route('document-summary', { path: 'document-summary/:contractId' });
    this.route('edit-workflow', { path: 'edit-workflow/:id' });
    this.route('notifications');
    this.route('contract-permissions', { path: 'document/:id/permissions' });
    this.route('profile-settings');
    this.route('rfp-contract-creation');
    this.route('business-admin', function () {
      this.route('document-types');
      this.route('document-templates');
      this.route('placeholders');
      this.route('workflow-templates');
    });
    this.route('rates');
    this.route('create-workflow-template', { path: 'business-admin/workflow-templates/create' });
    this.route('edit-workflow-template', { path: 'business-admin/workflow-templates/edit/:id' });
    this.route('initiate-sign-off', { path: 'contract/:id/initiate-sign-off' });
    this.route('accept-sign-off', { path: 'contract/sign-off/:id/accept' });
    this.route('thank-you-page', { path: 'thank-you' });
    this.route('error-page', { path: 'error' });
    this.route('application-error');
    this.route('error-occurred');
    this.route('access-denied');
    this.route('not-found');
    this.route('404', { path: '/*path' });
    this.route('application-prohibited');
  });

  exports['default'] = Router;
});