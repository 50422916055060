define("quantuvis-cm-spa/constants/attachment-status", ["exports"], function (exports) {
  var ATTACHMENT_STATUS = {
    NOT_SIGNED: 1,
    SIGNED_INTERNALLY: 2,
    SIGNED_EXTERNALLY: 3,
    SIGNED_BY_BOTH: 4,
    DECLINED: 5
  };

  exports["default"] = ATTACHMENT_STATUS;
});