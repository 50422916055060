define('quantuvis-cm-spa/models/dropdown-type-options', ['exports', 'ember'], function (exports, _ember) {
  var _createClass = (function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ('value' in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; })();

  function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError('Cannot call a class as a function'); } }

  /** Class representing an dropdown option . */

  var DropdownOption = (function () {
    /**
     * Create an option.
     * @param {Object} option - Select option
     * @param {string} option.id - Option ID
     * @param {string} option.name - Option Name
     */

    function DropdownOption(option) {
      _classCallCheck(this, DropdownOption);

      this.id = _ember['default'].get(option, 'id');
      this.name = _ember['default'].get(option, 'name');
    }

    /** Class representing an array of dropdown options */

    /**
     * Method for checking items equality by ID for Ember Power Select:
     * http://www.ember-power-select.com/docs/how-to-use-it
     * @param {DropdownOption} other - dropdown option for comparison.
     * @return {boolean} True `true` if the other item equal current by ID
     */

    _createClass(DropdownOption, [{
      key: 'isEqual',
      value: function isEqual(other) {
        return this.id == _ember['default'].get(other, 'id');
      }
    }]);

    return DropdownOption;
  })();

  var SelectDropdownOptions =
  /**
   * Create an array of dropdown options.
   * @param {Object[]} options - Array of raw options
   * @param {string} option.id - Option ID
   * @param {string} option.name - Option Name
   */
  function SelectDropdownOptions(options) {
    _classCallCheck(this, SelectDropdownOptions);

    this.options = options.map(function (option) {
      return new DropdownOption(option);
    });
  };

  exports.DropdownOption = DropdownOption;
  exports.SelectDropdownOptions = SelectDropdownOptions;
});