define('quantuvis-cm-spa/core-ui/components/cm-accordion/panel/header/component', ['exports', 'ember', 'quantuvis-cm-spa/mixins/add-test-id-attribute'], function (exports, _ember, _quantuvisCmSpaMixinsAddTestIdAttribute) {
  exports['default'] = _ember['default'].Component.extend(_quantuvisCmSpaMixinsAddTestIdAttribute['default'], {
    classNames: 'cm-accordion-header',
    controlIconName: _ember['default'].computed('parentView.parentView.isCollapsed', function () {
      return this.get('parentView.parentView.isCollapsed') ? 'expand' : 'minimize';
    }),
    click: function click() {
      var isCollapsed = !this.get('parentView.parentView.isCollapsed');

      this.get('parentView').parentView.send('panelChange', {
        id: this.elementId,
        isCollapsed: isCollapsed
      });
    },
    init: function init() {
      this._super.apply(this, arguments);
      if (this.get('parentView')) {
        this.get('parentView').parentView.send('addPanel', this.elementId);
      }
    },
    checkCollapseObserver: _ember['default'].observer('parentView.parentView.toggleChecker', function () {
      var isCollapsed = !this.get('parentView.parentView.isCollapsed');

      this.get('parentView').parentView.send('panelChange', {
        id: this.elementId,
        isCollapsed: isCollapsed
      });
    })
  });
});