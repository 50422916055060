define('quantuvis-cm-spa/components/cm-contracts-table', ['exports', 'ember', 'quantuvis-cm-spa/constants/date-format'], function (exports, _ember, _quantuvisCmSpaConstantsDateFormat) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['cm-contracts-table'],

    loadingSpinner: _ember['default'].inject.service(),
    search: _ember['default'].inject.service(),
    processedColumns: [],
    changeStateColumns: false,
    DATE_FORMAT: _quantuvisCmSpaConstantsDateFormat['default'],
    /**
     * Timeout in milliseconds to run recalculation digest
     */
    onResizeEventTimeOut: 200,

    autoHidingColumns: true,

    columns: _ember['default'].computed(function () {
      return [{
        propertyName: 'name',
        title: 'Document Name',
        template: 'table-cells/contract-name-cell',
        className: 'contract-name-cell',
        minWidth: 180,
        calculatedWidth: 0,
        mayBeHidden: false,
        isHidden: false,
        priority: 1,
        amendmentTitle: 'Amendment Name',
        amendmentTemplate: 'table-cells/amendment-name-cell',
        versionTitle: 'Versions:',
        versionTemplate: 'table-cells/version-name-cell',
        dataTestId: 'contract-name-th'
      }, {
        propertyName: 'number',
        title: 'Document ID',
        className: 'contract-id-cell',
        minWidth: 125,
        calculatedWidth: 0,
        mayBeHidden: true,
        isHidden: false,
        priority: 3,
        amendmentTitle: 'Amendment ID',
        isHiddenInVersionTable: true,
        dataTestId: 'contract-id-th'
      }, {
        propertyName: 'type',
        title: 'Type',
        template: 'table-cells/contract-type',
        className: 'type-cell',
        minWidth: 90,
        sortedBy: 'type.name',
        calculatedWidth: 0,
        mayBeHidden: true,
        isHidden: false,
        filterString: this.get('query.type'),
        priority: 4,
        amendmentTitle: '',
        isHiddenInAmendmentTable: true,
        isHiddenInVersionTable: true,
        dataTestId: 'contract-type-th'
      }, {
        propertyName: 'company',
        title: 'Company',
        template: 'table-cells/contract-company',
        className: 'company-cell',
        minWidth: 150,
        sortedBy: 'company.name',
        filterString: this.get('query.company'),
        calculatedWidth: 0,
        mayBeHidden: true,
        isHidden: false,
        priority: 5,
        amendmentTitle: '',
        isHiddenInAmendmentTable: true,
        isHiddenInVersionTable: true,
        dataTestId: 'contract-company-th'
      }, {
        propertyName: 'lineOfBusiness',
        title: 'Line of Business',
        template: 'table-cells/contract-line-of-business',
        className: 'line-of-business-cell',
        minWidth: 120,
        calculatedWidth: 0,
        mayBeHidden: true,
        isHidden: false,
        priority: 6,
        amendmentTitle: '',
        sortedBy: 'lineOfBusiness.name',
        filterString: this.get('query.lineOfBusiness'),
        isHiddenInAmendmentTable: true,
        isHiddenInVersionTable: true,
        dataTestId: 'line-of-business-th'
      }, {
        propertyName: 'modifiedDate',
        title: 'Date Modified',
        template: 'table-cells/date-and-time',
        className: 'modified-date-cell',
        minWidth: 130,
        sortDirection: 'desc',
        sortPrecedence: 1,
        calculatedWidth: 0,
        mayBeHidden: true,
        isHidden: false,
        priority: 7,
        amendmentTitle: 'Date Modified',
        isHiddenInVersionTable: true,
        dataTestId: 'date-modified-th'
      }, {
        propertyName: 'startDate',
        title: 'Start Date',
        template: 'table-cells/date',
        className: 'start-date-cell',
        minWidth: 108,
        calculatedWidth: 0,
        mayBeHidden: true,
        isHidden: false,
        priority: 8,
        amendmentTitle: 'Start Date',
        isHiddenInVersionTable: true,
        dataTestId: 'start-date-th'
      }, {
        propertyName: 'endDate',
        title: 'End Date',
        template: 'table-cells/contract-end-date',
        className: 'end-date-cell',
        minWidth: 108,
        calculatedWidth: 0,
        mayBeHidden: true,
        isHidden: false,
        priority: 9,
        amendmentTitle: 'End Date',
        isHiddenInVersionTable: true,
        dataTestId: 'end-date-th'
      }, {
        propertyName: 'leads',
        title: 'Lead',
        template: 'table-cells/contract-lead',
        disableSorting: true,
        className: 'lead-cell',
        minWidth: 130,
        calculatedWidth: 0,
        mayBeHidden: true,
        isHidden: false,
        filterString: this.get('query.leads'),
        priority: 10,
        amendmentTitle: 'Lead',
        isHiddenInVersionTable: true
      }, {
        propertyName: 'status',
        title: 'Status',
        template: 'table-cells/contract-status-cell',
        className: 'status-cell',
        minWidth: 200,
        sortedBy: 'status',
        filterString: this.get('query.status'),
        calculatedWidth: 0,
        mayBeHidden: true,
        isHidden: false,
        priority: 2,
        amendmentTitle: 'Status',
        amendmentTemplate: 'table-cells/amendment-status-cell',
        isHiddenInVersionTable: true,
        dataTestId: 'contract-status-th'
      }];
    }),

    classes: _ember['default'].Object.create({
      outerTableWrapper: 'outer-table-wrapper',
      innerTableWrapper: 'inner-table-wrapper',
      table: 'contracts-table cm-table cm-table-striped cm-table-hover',
      globalFilterWrapper: 'global-filter-wrapper',
      columnsDropdownWrapper: 'columns-dropdown-wrapper',
      columnsDropdownButtonWrapper: 'columns-dropdown-button-wrapper',
      columnsDropdown: 'columns-dropdown',
      theadCell: 'table-header',
      theadCellNoSorting: 'table-header-no-sorting',
      theadCellNoFiltering: 'table-header-no-filtering',
      tfooterWrapper: 'table-footer clearfix',
      footerSummary: 'table-summary',
      footerSummaryNumericPagination: '',
      footerSummaryDefaultPagination: '',
      pageSizeWrapper: '',
      pageSizeSelectWrapper: '',
      paginationWrapper: 'table-nav',
      paginationWrapperNumeric: '',
      paginationWrapperDefault: '',
      buttonDefault: '',
      noDataCell: '',
      collapseRow: 'collapse-row',
      collapseAllRows: 'collapse-all-rows',
      expandRow: 'expand-row',
      expandAllRows: 'expand-all-rows',
      thead: '',
      input: '',
      clearFilterIcon: '',
      clearAllFiltersIcon: '',
      globalFilterDropdownWrapper: 'global-filter-dropdown-wrapper'
    }),

    messages: _ember['default'].Object.create({
      'noDataToShow': 'No result found'
    }),

    icons: _ember['default'].Object.create({
      'sort-asc': 'cm-column-sort cm-sort-direction-asc',
      'sort-desc': 'cm-column-sort cm-sort-direction-desc'
    }),

    recalculateTableBodyHeight: function recalculateTableBodyHeight() {
      var tableWrapperHeight = this.$('.inner-table-wrapper').get(0).clientHeight;
      var theadHeight = this.$('.inner-table-wrapper thead').height();
      var tbodyHeight = tableWrapperHeight - theadHeight;

      this.$('.inner-table-wrapper tbody').height(tbodyHeight);
    },

    recalculateTableColsWidth: function recalculateTableColsWidth() {
      var tbodyWidth = this.$('.inner-table-wrapper tbody').get(0).clientWidth;

      var minWidthSum = 0;

      this.processedColumns.forEach(function (column) {
        if (!column.isHidden) {
          minWidthSum += column.minWidth;
        }
      });

      var ratio = tbodyWidth / minWidthSum;
      var degreeOfAccuracy = 1000;

      ratio = Math.floor(ratio * degreeOfAccuracy) / degreeOfAccuracy;

      var columnStyles = this.createColumnStyles(this.processedColumns, ratio);
      var newStyles = '<style id="columns" type="text/css">' + columnStyles + '</style>';
      var columnsStyleTag = _ember['default'].$('head #columns');

      if (columnsStyleTag.length) {
        columnsStyleTag.replaceWith(newStyles);
      } else {
        _ember['default'].$('head').append(newStyles);
      }
    },

    createColumnStyles: function createColumnStyles(columns, ratio) {
      var roundDelta = 0;
      var contractStyles = columns.map(function (column, index) {
        var calculatedWidth = column.minWidth * ratio;
        var roundedWidth = Math.round(calculatedWidth);

        roundDelta += calculatedWidth - roundedWidth;
        column.calculatedWidth = roundedWidth;

        if (index === columns.length - 1) {
          column.calculatedWidth = roundedWidth + roundDelta;
        }

        if (column.calculatedWidth < column.minWidth) {
          column.calculatedWidth = column.minWidth;
        }

        return {
          'class': column.className,
          'minWidth': column.minWidth,
          'width': column.calculatedWidth,
          'maxWidth': column.calculatedWidth
        };
      });

      contractStyles = contractStyles.concat(this.getAmendmentsStyles(contractStyles, columns));

      return this.mapStyleObjectsToStrings(contractStyles).join('');
    },

    getAmendmentsStyles: function getAmendmentsStyles(contractStyles, columns) {
      var lastVisibleColumn = undefined;

      columns.forEach(function (column) {
        if (!column.isHidden) {
          lastVisibleColumn = column.className;
        }
      });

      var firstVisibleColumn = columns.filter(function (column) {
        return !column.isHidden;
      })[0].className;

      var firstColumnStyle = contractStyles.filter(function (elem) {
        return elem['class'] === firstVisibleColumn;
      })[0];

      var lastColumnStyle = contractStyles.filter(function (elem) {
        return elem['class'] === lastVisibleColumn;
      })[0];

      var leftTableMargin = 20;

      var amendmentFirstColumnStyles = {
        'class': 'expand-row .' + firstColumnStyle['class'],
        minWidth: firstColumnStyle.minWidth - leftTableMargin,
        width: firstColumnStyle.width - leftTableMargin,
        maxWidth: firstColumnStyle.maxWidth - leftTableMargin
      };

      var amendmentLastColumnStyles = {
        'class': 'expand-row .' + lastColumnStyle['class'],
        minWidth: lastColumnStyle.minWidth - leftTableMargin,
        width: lastColumnStyle.width - leftTableMargin,
        maxWidth: lastColumnStyle.maxWidth - leftTableMargin
      };

      return [amendmentFirstColumnStyles, amendmentLastColumnStyles];
    },

    mapStyleObjectsToStrings: function mapStyleObjectsToStrings(stylesArray) {
      return stylesArray.map(function (obj) {
        return '.' + obj['class'] + '{\n          min-width: ' + obj.minWidth + 'px;\n          width: ' + obj.width + 'px;\n          max-width: ' + obj.maxWidth + 'px;\n          }';
      });
    },

    handleResize: function handleResize() {
      _ember['default'].run.debounce(this, this.hideColumnsByPriority, this.onResizeEventTimeOut);
      _ember['default'].run.debounce(this, this.recalculateTableColsWidth, this.onResizeEventTimeOut);
      _ember['default'].run.debounce(this, this.recalculateTableBodyHeight, this.onResizeEventTimeOut);
    },

    init: function init() {
      this._super.apply(this, arguments);

      var processedColumns = this.get('columns').map(function (column) {
        return {
          className: column.className,
          isHidden: column.isHidden,
          priority: column.priority,
          minWidth: column.minWidth,
          mayBeHidden: column.mayBeHidden
        };
      });

      this.set('processedColumns', processedColumns);

      _ember['default'].$(window).on('resize', _ember['default'].run.bind(this, this.handleResize));
    },

    hideColumnsByPriority: function hideColumnsByPriority() {
      var _this = this;

      // Skip hiding columns if autoHidingColumns set to false
      if (!this.get('autoHidingColumns')) {
        return;
      }
      var scrollWidth = 30;
      var innerTableWrapperWidth = this.$('.inner-table-wrapper').width() - scrollWidth;
      var processedColumns = this.get('processedColumns');
      var sortedProcessedColumns = [];
      var minWidthSum = 0;

      sortedProcessedColumns = processedColumns.slice().sort(function (columnA, columnB) {
        return columnB.priority < columnA.priority ? 1 : columnB.priority > columnA.priority ? -1 : 0;
      });

      sortedProcessedColumns.forEach(function (column) {
        minWidthSum += column.minWidth;
      });

      if (minWidthSum >= innerTableWrapperWidth) {
        (function () {
          var tmpWidth = 0;

          sortedProcessedColumns.forEach(function (column) {
            tmpWidth += column.minWidth;
            if (column.mayBeHidden) {
              column.isHidden = !(tmpWidth < innerTableWrapperWidth);
            }
          });
          _this.set('processedColumns', processedColumns.slice());
        })();
      } else {
        processedColumns.forEach(function (column) {
          column.isHidden = false;
        });
        this.set('processedColumns', processedColumns.slice());
      }
    },

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.handleResize();
    },

    didRender: function didRender() {
      this._super.apply(this, arguments);
      this.recalculateTableColsWidth();
      this.recalculateTableBodyHeight();
    },

    didDestroyElement: function didDestroyElement() {
      this._super.apply(this, arguments);
      _ember['default'].$(window).unbind('resize');
    },

    forceRecalculateColumnObserver: _ember['default'].observer('isFiltersOpen', function () {
      var leftOffsetOfTablePanels = 287;

      var animationWidth = this.get('isFiltersOpen') ? leftOffsetOfTablePanels : 0;

      _ember['default'].$('.cm-contracts-table-container').animate({ marginLeft: animationWidth }, {
        complete: this.handleResize.bind(this)
      });
    }),

    actions: {
      displayDataChanged: function displayDataChanged(data) {
        this.sendAction('displayDataChanged', this.createQueryParams(data));
      },
      handleLoadingChange: function handleLoadingChange(value) {
        this.get('search').set('dashboardTextSearchModeEnabled', this.get('search.textSearch') && !value);
        this.get('loadingSpinner').setVisible('contracts-table-spinner', value);
      },

      columnsVisibilityChanged: function columnsVisibilityChanged(columnsInfo) {
        var _this2 = this;

        this.set('autoHidingColumns', false);
        columnsInfo.forEach(function (columnInfo, index) {
          _this2.processedColumns[index].isHidden = columnInfo.isHidden;
        });
        this.recalculateTableColsWidth();
        this.toggleProperty('changeStateColumns');
      }
    },

    createQueryParams: function createQueryParams(tablesChangedParams) {
      var sort = tablesChangedParams.sort;
      var query = {
        size: tablesChangedParams.pageSize,
        page: tablesChangedParams.currentPageNumber,
        sort: 'modifiedDate,desc'
      };

      Object.assign(query, tablesChangedParams.columnFilters);

      if (sort.length) {
        Object.assign(query, this.createSortParam(sort));
      }

      return query;
    },

    createSortParam: function createSortParam(sortChangedParam) {
      var sort = {};

      if (sortChangedParam[0]) {
        Object.assign(sort, { sort: sortChangedParam[0].replace(':', ',') });
      }

      return sort;
    }
  });
});