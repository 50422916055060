define('quantuvis-cm-spa/components/cm-timeline/menus/cm-progress-step-menu', ['exports', 'quantuvis-cm-spa/components/cm-timeline/menus/cm-menu-base', 'ember', 'quantuvis-cm-spa/constants/permissions', 'quantuvis-cm-spa/mixins/add-step-position-mixin'], function (exports, _quantuvisCmSpaComponentsCmTimelineMenusCmMenuBase, _ember, _quantuvisCmSpaConstantsPermissions, _quantuvisCmSpaMixinsAddStepPositionMixin) {
  exports['default'] = _quantuvisCmSpaComponentsCmTimelineMenusCmMenuBase['default'].extend(_quantuvisCmSpaMixinsAddStepPositionMixin['default'], {
    classNames: ['cm-progress-step-menu'],
    contractDetails: _ember['default'].inject.service(),
    uploadVersionService: _ember['default'].inject.service('upload-version'),

    uploadVersionDisabled: _ember['default'].computed('data.responsiblePersonsAccounts', function () {
      return !this.hasPermissionToUploadNewVersion() || !this.isResponsibleForReview();
    }),

    hasPermissionToUploadNewVersion: function hasPermissionToUploadNewVersion() {
      return this.get('permissionService').checkCompanyUserPermission(_quantuvisCmSpaConstantsPermissions.PERMISSIONS.ID.UPLOAD_DOCUMENT);
    },

    isResponsibleForReview: function isResponsibleForReview() {
      return this.get('contractDetails').isResponsibleFor(this.get('data'));
    },

    changeStateButtonDisabled: _ember['default'].computed('data.responsiblePersonsAccounts', function () {
      return !this.get('contractDetails').isResponsibleFor(this.get('data'));
    }),

    actions: {
      onUpload: function onUpload() {
        this.get('uploadVersionService').showModal(this.get('data.id'));
      }
    }
  });
});