define('quantuvis-cm-spa/routes/edit-workflow', ['exports', 'ember', 'quantuvis-cm-spa/mixins/authenticated-route-mixin', 'quantuvis-cm-spa/constants/department-status', 'quantuvis-cm-spa/constants/permissions', 'quantuvis-cm-spa/constants/contract-status'], function (exports, _ember, _quantuvisCmSpaMixinsAuthenticatedRouteMixin, _quantuvisCmSpaConstantsDepartmentStatus, _quantuvisCmSpaConstantsPermissions, _quantuvisCmSpaConstantsContractStatus) {
  exports['default'] = _ember['default'].Route.extend(_quantuvisCmSpaMixinsAuthenticatedRouteMixin['default'], {
    contractDetails: _ember['default'].inject.service(),
    workflow: _ember['default'].inject.service(),
    permissionService: _ember['default'].inject.service(),
    deniedStatuses: [_quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.EXECUTED, _quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.TERMINATED],

    model: function model(params) {
      this.initializeContractDetailsService(params.id);

      return this.getRsvpModel(params);
    },

    afterModel: function afterModel(model) {
      var _this = this;

      if (!this.hasUserPermission(model.contract)) {
        this.transitionTo('access-denied');
      } else {
        var companyId = model.contract.get('company.id');

        this.get('permissionService').set('companyId', companyId);
        if (this.isWorkflowEditDenied(model.contract)) {
          this.transitionTo('contract-details', model.contract.get('id'));
        }
      }

      return this.get('workflow').fetchResponsibleUsers(model.contract.get('company.id')).then(function (leads) {
        return _this.controllerFor('editWorkflow').set('leads', leads);
      });
    },

    hasUserPermission: function hasUserPermission(contract) {
      var companyId = contract.get('company.id');

      return this.get('permissionService').checkCompanyUserPermissions([_quantuvisCmSpaConstantsPermissions.PERMISSIONS.ID.EDIT_WORKFLOW, _quantuvisCmSpaConstantsPermissions.PERMISSIONS.ID.READ_TIMELINE], companyId);
    },

    isWorkflowEditDenied: function isWorkflowEditDenied(contract) {
      return this.get('deniedStatuses').indexOf(contract.get('status.id')) !== -1;
    },

    initializeContractDetailsService: function initializeContractDetailsService(contractDetailsId) {
      this.get('contractDetails').setContractId(contractDetailsId);
    },

    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.setProperties({
          step: null,
          newStepPosition: null
        });
      }
    },

    getRsvpModel: function getRsvpModel(params) {
      return _ember['default'].RSVP.hash({
        id: params.id,
        scrollToStep: params.step,
        newStepPosition: params.newStepPosition,
        contract: this.get('store').findRecord('contract', params.id),
        workflow: this.get('store').queryRecord('workflow', { contractId: params.id }),
        departments: this.get('store').query('user-management/companies/department', {
          statusId: _quantuvisCmSpaConstantsDepartmentStatus['default'].ACTIVE_ID })
      });
    }
  });
});