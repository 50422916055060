define('quantuvis-cm-spa/validations/custom-validators/end-date', ['exports', 'quantuvis-cm-spa/constants/date-format', 'moment', 'ember'], function (exports, _quantuvisCmSpaConstantsDateFormat, _moment, _ember) {
  exports['default'] = endDateValidation;

  function endDateValidation() {
    var endDateMessage = 'Must be after start date';

    return function (key, newValue, oldValue, changes, content) {
      var startDate = dateForCheck(_ember['default'].get(changes, 'startDate'), _ember['default'].get(content, 'startDate'));
      var endDate = newValue ? (0, _moment['default'])(newValue, _quantuvisCmSpaConstantsDateFormat['default'].MM_DD_YYYY) : dateForCheck(_ember['default'].get(changes, 'endDate'), _ember['default'].get(content, 'endDate'));

      if (startDate.isAfter(endDate)) {
        return endDateMessage;
      }

      return true;
    };
  }

  function dateForCheck(changesDate, contentDate) {
    return (0, _moment['default'])(changesDate ? changesDate : contentDate, _quantuvisCmSpaConstantsDateFormat['default'].MM_DD_YYYY);
  }
});