define('quantuvis-cm-spa/controllers/login/index', ['exports', 'ember', 'quantuvis-cm-spa/constants/server-error-response', 'quantuvis-cm-spa/constants/error-messages'], function (exports, _ember, _quantuvisCmSpaConstantsServerErrorResponse, _quantuvisCmSpaConstantsErrorMessages) {

  var SPINNER_ID = 'login-spinner';

  exports['default'] = _ember['default'].Controller.extend({
    loadingSpinner: _ember['default'].inject.service(),
    session: _ember['default'].inject.service(),
    dialog: _ember['default'].inject.service(),

    errorMessage: '',

    handleAuthenticateError: function handleAuthenticateError(response) {
      var errorMessage = undefined;

      var error = response.error || response.message;

      switch (error) {
        case _quantuvisCmSpaConstantsServerErrorResponse['default'].INVALID_TOKEN:
          {
            errorMessage = _quantuvisCmSpaConstantsErrorMessages['default'].INVALID_CREDENTIALS_MESSAGE;
            break;
          }
        case _quantuvisCmSpaConstantsServerErrorResponse['default'].ACCESS_DENIED:
          {
            errorMessage = _quantuvisCmSpaConstantsErrorMessages['default'].ACCOUNT_LOCKED_MESSAGE;
            break;
          }
        default:
          {
            errorMessage = _quantuvisCmSpaConstantsErrorMessages['default'].DEFAULT_ERROR_MESSAGE;
          }
      }

      this.set('errorMessage', errorMessage);
    },

    actions: {
      authenticate: function authenticate(credentials) {
        var _this = this;

        this.get('loadingSpinner').setVisible(SPINNER_ID, true);
        this.set('errorMessage', '');

        this.get('session').authenticate('authenticator:oauth2', credentials.get('username'), credentials.get('password'))['catch'](function (response) {
          _this.get('loadingSpinner').setVisible(SPINNER_ID, false);
          _this.handleAuthenticateError(response);
        });
      },

      forgotPassword: function forgotPassword() {
        var options = {
          title: 'Forgot Password',
          infoMessage: 'Please contact Quantuvis administrator to reset your password',
          hideDeclineButton: true,
          acceptText: 'Ok'
        };

        this.get('dialog').show('dialogs/cm-dialog', 'dialog-templates/cm-info', this, options);
      }
    }
  });
});