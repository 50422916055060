define("quantuvis-cm-spa/constants/sign-off-status", ["exports"], function (exports) {
  var SIGN_OFF_STATUS = {
    IN_PROGRESS_ID: 1,
    COMPLETED_ID: 2,
    CANCELLED_ID: 3,
    DECLINED_ID: 4,
    EXPIRED_ID: 5,
    DRAFT_ID: 6,
    PENDING_APPROVAL_ID: 7
  };

  exports["default"] = SIGN_OFF_STATUS;
});