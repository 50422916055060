define('quantuvis-cm-spa/components/cm-edit-workflow/timeline', ['exports', 'ember', 'quantuvis-cm-spa/components/cm-timeline/cm-timeline-content', 'quantuvis-cm-spa/constants/timeline-item-type', 'quantuvis-cm-spa/constants/workflow-step-type', 'quantuvis-cm-spa/constants/workflow-step-state'], function (exports, _ember, _quantuvisCmSpaComponentsCmTimelineCmTimelineContent, _quantuvisCmSpaConstantsTimelineItemType, _quantuvisCmSpaConstantsWorkflowStepType, _quantuvisCmSpaConstantsWorkflowStepState) {
  exports['default'] = _quantuvisCmSpaComponentsCmTimelineCmTimelineContent['default'].extend({
    layoutName: 'components/cm-timeline/cm-timeline-content',
    showPendingNextStep: false,
    computedTimelineItems: _ember['default'].computed('timelineItems', function () {
      var _this = this;

      var computedItems = [];

      this.get('timelineItems').forEach(function (timeLineItem) {
        var type = timeLineItem.get('type.id');

        if (type === _quantuvisCmSpaConstantsTimelineItemType['default'].WORKFLOW_STEP) {
          if (_this.isVisible(timeLineItem.get('workflowStep.state.id'))) {
            computedItems.push(_this.timelineFactory(timeLineItem));
          }
        } else {
          computedItems.push(_this.timelineFactory(timeLineItem));
        }
      });

      return computedItems;
    }),

    /**
     * @todo Add tests
     */
    isVisible: function isVisible(state) {
      return state !== _quantuvisCmSpaConstantsWorkflowStepState['default'].IN_PROGRESS && state !== _quantuvisCmSpaConstantsWorkflowStepState['default'].PENDING;
    },
    /**
     * @todo Add tests
     */
    timelineFactory: function timelineFactory(timeline) {
      return {
        template: this.getTimelineTemplate(timeline),
        data: this.getTimelineItemData(timeline)
      };
    },

    /**
    * @todo Add tests
    */
    getTimelineTemplate: function getTimelineTemplate(timelineItem) {
      switch (timelineItem.get('type').id) {
        case _quantuvisCmSpaConstantsTimelineItemType['default'].EVENT:
          return 'cm-edit-workflow/timeline/event';
        case _quantuvisCmSpaConstantsTimelineItemType['default'].WORKFLOW_STEP:
          return this.getWorkflowStepTemplate(timelineItem.get('workflowStep'));
        default:
          return null;
      }
    },

    /**
    * @todo Add tests
    */
    getWorkflowStepTemplate: function getWorkflowStepTemplate(workflowStep) {
      switch (workflowStep.get('type.id')) {
        case _quantuvisCmSpaConstantsWorkflowStepType['default'].CONTRACT_LEAD_REVIEW_ID:
          return 'cm-edit-workflow/timeline/lead-review-step';
        case _quantuvisCmSpaConstantsWorkflowStepType['default'].DEPARTMENT_REVIEW_ID:
          return 'cm-edit-workflow/timeline/department-review-step';
        case _quantuvisCmSpaConstantsWorkflowStepType['default'].MULTI_DEPARTMENT_REVIEW_ID:
          return 'cm-edit-workflow/timeline/multi-department-step';
        case _quantuvisCmSpaConstantsWorkflowStepType['default'].DIGITAL_SIGN_OFF_ID:
          return 'cm-edit-workflow/timeline/digital-sign-off-step';
        case _quantuvisCmSpaConstantsWorkflowStepType['default'].MANUAL_SIGN_OFF_ID:
          return 'cm-timeline/cm-timeline-item/cm-timeline-manual-sign-off';
        default:
          return null;
      }
    }
  });
});