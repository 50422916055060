define('quantuvis-cm-spa/core-ui/components/cm-progress-bar/component', ['exports', 'ember'], function (exports, _ember) {

  /**
   * Component for displaying progress-bar
   *
   * Input attributes:
   * - value: value of the progress bar (float).
   *          Possible values: from 0 to 100.
   * - color: color of the progress bar.
   *          Possible values: 'red', 'green', 'purple'.
   */

  exports['default'] = _ember['default'].Component.extend({
    classNames: ['cm-progress-bar'],

    progressValue: _ember['default'].computed('value', function () {
      var value = parseFloat(this.get('value'));

      return value ? value : '';
    }),

    progressWidth: _ember['default'].computed('progressValue', function () {
      var value = this.get('progressValue');
      var style = 'width: ' + value + '%;';

      return _ember['default'].String.htmlSafe('' + (value ? style : ''));
    }),

    progressClass: _ember['default'].computed('color', function () {
      var color = this.get('color');

      switch (color) {
        case 'purple':
          return 'progress-purple';
        case 'green':
          return 'progress-green';
        case 'red':
          return 'progress-red';
        default:
          return 'progress-default';
      }
    })
  });
});