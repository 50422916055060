define('quantuvis-cm-spa/components/cm-notifications/notifications', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['cm-notifications-content'],

    isLoading: false,

    pendingNotifications: _ember['default'].computed.filterBy('notifications', 'isCompleted', false),
    completedNotifications: _ember['default'].computed.filterBy('notifications', 'isCompleted', true),

    newPendingNotificationsLength: _ember['default'].computed('pendingNotifications', function () {
      return this.get('pendingNotifications').filter(function (notification) {
        return notification.get('isNew');
      }).length;
    }),

    newCompletedNotificationsLength: _ember['default'].computed('completedNotifications', function () {
      return this.get('completedNotifications').filter(function (notification) {
        return notification.get('isNew');
      }).length;
    })
  });
});