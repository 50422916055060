define('quantuvis-cm-spa/constants/icon-name', ['exports'], function (exports) {
  var ICON_NAME = {
    SM: 'SM',
    RN: 'RN',
    IA: 'IA',
    CM: 'CM',
    IQ: 'IQ',
    SA: 'SA'
  };

  exports['default'] = ICON_NAME;
});