define('quantuvis-cm-spa/validations/initiate-sign-off', ['exports', 'ember-changeset-validations/validators', 'quantuvis-cm-spa/validations/custom-validators', 'moment'], function (exports, _emberChangesetValidationsValidators, _quantuvisCmSpaValidationsCustomValidators, _moment) {

  var MAX_YEARS_FOR_DUE_DATE = 2;

  function validateParties() {
    return function (key, newValue, oldValue, changes) {
      var parties = changes.parties;

      var isAnyChecked = parties.some(function (_ref) {
        var isChecked = _ref.isChecked;
        return isChecked;
      });

      if (!isAnyChecked) {
        return 'You must select a party';
      }

      return true;
    };
  }

  function validateDueDateForDocuSign() {
    return function (key, newValue) {
      var yearsBetween = (0, _moment['default'])(newValue).diff((0, _moment['default'])(), 'years');

      if (yearsBetween >= MAX_YEARS_FOR_DUE_DATE) {
        return 'Due date can not exceed 2 years';
      }

      return true;
    };
  }

  exports['default'] = {
    documentVersion: [(0, _emberChangesetValidationsValidators.validatePresence)(true)],
    dueDate: [(0, _emberChangesetValidationsValidators.validatePresence)(true), (0, _quantuvisCmSpaValidationsCustomValidators.validateDueDate)(), validateDueDateForDocuSign()],
    subject: [(0, _emberChangesetValidationsValidators.validatePresence)(true), (0, _emberChangesetValidationsValidators.validateLength)({ max: 70 })],
    message: [(0, _emberChangesetValidationsValidators.validatePresence)(true), (0, _emberChangesetValidationsValidators.validateLength)({ max: 1000 })],
    partiesList: [validateParties()]
  };
});