define('quantuvis-cm-spa/models/contracts/contact-person', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    personId: _emberData['default'].attr('number'),
    name: _emberData['default'].attr('string'),
    email: _emberData['default'].attr('string'),
    role: _emberData['default'].attr('string'),
    phone: _emberData['default'].attr('string'),
    cell: _emberData['default'].attr('string'),
    companyId: _emberData['default'].attr('number')
  });
});