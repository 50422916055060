define('quantuvis-cm-spa/helpers/read-dialog-const', ['exports', 'ember', 'quantuvis-cm-spa/constants/dialog'], function (exports, _ember, _quantuvisCmSpaConstantsDialog) {
  exports.readDialogConst = readDialogConst;

  function readDialogConst(params) {
    if (params.length === 0) {
      return undefined;
    }

    return _ember['default'].Object.create(_quantuvisCmSpaConstantsDialog['default']).get(params[0]);
  }

  exports['default'] = _ember['default'].Helper.helper(readDialogConst);
});