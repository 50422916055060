define('quantuvis-cm-spa/constants/date-format', ['exports'], function (exports) {
  var DATE = 'MM/DD/YYYY';
  var TIME = 'hh:mm A';

  var DATE_FORMAT = {
    MM_DD_YYYY: DATE,
    HH_MM_A: TIME,
    DATETIME: DATE + ' ' + TIME,
    ISO_DATETIME: 'YYYY-MM-DDTHH:mm:ss'
  };

  exports['default'] = DATE_FORMAT;
});