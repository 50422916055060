define('quantuvis-cm-spa/components/cm-contract-name', ['exports', 'ember', 'quantuvis-cm-spa/constants/permissions'], function (exports, _ember, _quantuvisCmSpaConstantsPermissions) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['cm-contract-name', 'contract-name'],
    classNameBindings: ['overdued'],
    permissionService: _ember['default'].inject.service(),
    date: _ember['default'].inject.service(),
    search: _ember['default'].inject.service(),
    router: _ember['default'].inject.service('-routing'),

    record: {},
    overdued: false,

    init: function init() {
      this._super.apply(this, arguments);
      this.checkForOverdue();
    },

    checkForOverdue: function checkForOverdue() {
      var date = this.get('date'),
          workflowStep = this.get('record.workflowStep');

      if (!workflowStep) {
        return;
      }

      var isWorkflowStepOverdued = !workflowStep.get('completedDate') && date.isOverdue(workflowStep.get('dueDate')),
          isAnySubstepOverdued = workflowStep.get('substeps') ? workflowStep.get('substeps').any(function (substep) {
        return !substep.get('completedDate') && date.isOverdue(substep.get('dueDate'));
      }) : false;

      return this.set('overdued', isWorkflowStepOverdued || isAnySubstepOverdued);
    },

    actions: {
      navigateToContract: function navigateToContract(record) {
        var recordId = record.get('id');
        var hasPermission = this.get('permissionService').checkCompanyUserPermission(_quantuvisCmSpaConstantsPermissions.PERMISSIONS.ID.READ_DOCUMENT, record.get('company.id'));

        if (hasPermission) {
          this.get('search').set('searchInVersion', false);
          this.get('router').transitionTo('contract-details', [recordId]);
        } else {
          this.get('router').transitionTo('access-denied');
        }
      }
    }
  });
});