define('quantuvis-cm-spa/core-ui/components/cm-accordion/panel/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    classNames: ['cm-accordion-panel'],
    classNameBindings: ['isCollapsed:is-collapsed'],
    actions: {
      panelChange: function panelChange(panel) {
        this.set('isCollapsed', panel.isCollapsed);
        this.get('parentView').send('onPanelsStateSwitched', panel);
      },
      addPanel: function addPanel(idChildPanel) {
        var panel = {
          panel: this,
          idHead: idChildPanel
        };

        this.set('toggleChecker', this.get('isCollapsed'));
        this.get('parentView').send('addPanel', panel);
      }
    }
  });
});