define('quantuvis-cm-spa/components/cm-edit-workflow/timeline/multi-department-step', ['exports', 'quantuvis-cm-spa/components/cm-timeline/cm-timeline-item/cm-timeline-multi-department-review', 'quantuvis-cm-spa/constants/workflow-step-element-type'], function (exports, _quantuvisCmSpaComponentsCmTimelineCmTimelineItemCmTimelineMultiDepartmentReview, _quantuvisCmSpaConstantsWorkflowStepElementType) {
  exports['default'] = _quantuvisCmSpaComponentsCmTimelineCmTimelineItemCmTimelineMultiDepartmentReview['default'].extend({
    getWorkflowStepElementTemplate: function getWorkflowStepElementTemplate(workflowStepElement) {
      switch (workflowStepElement.get('type.id')) {
        case _quantuvisCmSpaConstantsWorkflowStepElementType['default'].STEP_EVENT:
          return 'cm-edit-workflow/timeline/event';
        case _quantuvisCmSpaConstantsWorkflowStepElementType['default'].SUBSTEP:
          return 'cm-edit-workflow/timeline/department-review-step';
        default:
          return null;
      }
    }
  });
});