define("quantuvis-cm-spa/constants/permissions", ["exports"], function (exports) {
  var PERMISSIONS = {
    ID: {
      READ_TIMELINE: 1,
      READ_DOCUMENT: 2,
      READ_NOTES: 3,
      READ_HISTORY: 4,
      EXPORT_HISTORY: 5,
      READ_DOWNLOAD_CURRENT_VERSION: 7,
      READ_DOWNLOAD_PREVIOUS_VERSIONS: 8,
      ADD_ATTACHMENTS: 9,
      PRINT_DOCUMENT: 14,
      UPLOAD_DOCUMENT: 15,
      COMPLETE_STEPS: 16,
      ADD_NOTES: 17,
      SEND_DOCUMENT_INTERNALLY: 18,
      VIEW_EVENTS_NOTIFICATIONS: 19,
      VIEW_ALERT_BARS: 20,
      VOID_WORKFLOW_STEPS: 21,
      EDIT_WORKFLOW: 22,
      EDIT_SUMMARY: 23,
      CREATE_DOCUMENT: 24,
      TERMINATE_DOCUMENT: 26,
      ACTIVATE_DEACTIVATE_DELETE_DOCUMENT: 27,
      SEND_DOCUMENT_EXTERNALLY: 28,
      CM_ADMIN: 30,
      CM_WRITE: 51,
      SA_READ: 54,
      INITIATE_SIGN_OFF: 56,
      CM_READ: 52,
      CM_ACCESS: 58
    },
    APPLICATION: {
      CM: 2,
      UM: 4
    },
    RESOURCE: {
      GLOBAL: 1,
      COMPANY: 2
    }
  };

  var PERMISSION_GROUPS = {
    LEAD: [PERMISSIONS.ID.ADD_ATTACHMENTS, PERMISSIONS.ID.UPLOAD_DOCUMENT, PERMISSIONS.ID.COMPLETE_STEPS, PERMISSIONS.ID.ADD_NOTES, PERMISSIONS.ID.SEND_DOCUMENT_INTERNALLY, PERMISSIONS.ID.VIEW_EVENTS_NOTIFICATIONS, PERMISSIONS.ID.VIEW_ALERT_BARS, PERMISSIONS.ID.VOID_WORKFLOW_STEPS, PERMISSIONS.ID.EDIT_WORKFLOW, PERMISSIONS.ID.EDIT_SUMMARY, PERMISSIONS.ID.TERMINATE_DOCUMENT, PERMISSIONS.ID.ACTIVATE_DEACTIVATE_DELETE_DOCUMENT, PERMISSIONS.ID.SEND_DOCUMENT_EXTERNALLY, PERMISSIONS.ID.INITIATE_SIGN_OFF],
    REVIEWER: [PERMISSIONS.ID.COMPLETE_STEPS]
  };

  exports.PERMISSIONS = PERMISSIONS;
  exports.PERMISSION_GROUPS = PERMISSION_GROUPS;
});