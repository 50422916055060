define('quantuvis-cm-spa/routes/business-admin/document-types', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      return this.get('store').findAll('contracts/type');
    },
    actions: {
      refreshModel: function refreshModel() {
        this.refresh();
      }
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);
      controller.set('documentTypeSelected', null);
    }
  });
});