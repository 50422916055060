define('quantuvis-cm-spa/components/cm-timeline/cm-timeline-item/cm-timeline-event', ['exports', 'ember', 'quantuvis-cm-spa/constants/event-type', 'quantuvis-cm-spa/constants/attachment-status', 'quantuvis-cm-spa/constants/event-property-name', 'quantuvis-cm-spa/constants/sign-off-status'], function (exports, _ember, _quantuvisCmSpaConstantsEventType, _quantuvisCmSpaConstantsAttachmentStatus, _quantuvisCmSpaConstantsEventPropertyName, _quantuvisCmSpaConstantsSignOffStatus) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['cm-timeline-item', 'cm-timeline-event'],
    datePrefix: 'on',
    state: null,
    classNameBindings: ['bindEventClass'],
    contractDetails: _ember['default'].inject.service(),
    timeline: _ember['default'].inject.service(),
    timelineEventTitle: _ember['default'].inject.service(),

    menuComponent: _ember['default'].computed('data.eventType.id', function () {
      var eventTypeId = this.get('data.eventType.id');

      switch (eventTypeId) {
        case _quantuvisCmSpaConstantsEventType.EVENT_TYPE.DOCUMENT_SIGNED_INTERNALLY:
          return 'cm-timeline/menus/event-menus/cm-signed-internally-menu';
        case _quantuvisCmSpaConstantsEventType.EVENT_TYPE.SIGN_OFF_REQUEST_RECEIVED:
          return this.getSignOffRequestReceivedMenu();
        default:
          return null;
      }
    }),

    getSignOffRequestReceivedMenu: function getSignOffRequestReceivedMenu() {
      var event = this.get('data');
      var pendingSignoffId = this.get('contractDetails.pendingSignOff.id');
      var property = event.get('properties').find(function (property) {
        return Number(property.get('name.id')) === _quantuvisCmSpaConstantsEventPropertyName['default'].SIGN_OFF_ID;
      });

      return property && property.get('value') === pendingSignoffId ? 'cm-timeline/menus/event-menus/cm-sign-off-request-received-menu' : null;
    },

    title: _ember['default'].computed('data', function () {
      return this.get('timelineEventTitle').buildTitle(this.get('data'));
    }),

    eventState: _ember['default'].computed('state', function () {
      var state = this.get('state');

      return _ember['default'].isEmpty(state) ? this.set('state', { name: this.get('timelineEventTitle').buildSecondaryTitle(this.get('data')) }) : this.get('state');
    }),

    eventBodyComponent: _ember['default'].computed('data.eventType.id', function () {
      var eventTypeId = this.get('data.eventType.id');

      var basicComponentPath = 'cm-timeline/cm-timeline-item/event-bodies/cm-basic';
      var newReceivedPath = 'cm-timeline/cm-timeline-item/event-bodies/cm-new-received';
      var componentWithDocumentLinkPath = 'cm-timeline/cm-timeline-item/event-bodies/cm-document-link';
      var sentComponentPath = 'cm-timeline/cm-timeline-item/event-bodies/cm-sent';
      var signOffUpdatedPath = 'cm-timeline/cm-timeline-item/event-bodies/cm-sign-off-updated';
      var terminatedComponentPath = 'cm-timeline/cm-timeline-item/event-bodies/cm-terminated';
      var signOffHandleDetailsComponentPath = 'cm-timeline/cm-timeline-item/event-bodies/sign-off-handle-details';

      switch (eventTypeId) {
        case _quantuvisCmSpaConstantsEventType.EVENT_TYPE.AMENDMENT_ADDED:
        case _quantuvisCmSpaConstantsEventType.EVENT_TYPE.EXECUTED_AMENDMENT_ADDED:
        case _quantuvisCmSpaConstantsEventType.EVENT_TYPE.AMENDMENT_EXECUTED:
        case _quantuvisCmSpaConstantsEventType.EVENT_TYPE.PARENT_CONTRACT_EXECUTED:
        case _quantuvisCmSpaConstantsEventType.EVENT_TYPE.AMENDMENT_TERMINATED:
        case _quantuvisCmSpaConstantsEventType.EVENT_TYPE.AMENDMENT_REINSTATED:
          return componentWithDocumentLinkPath;
        case _quantuvisCmSpaConstantsEventType.EVENT_TYPE.DOCUMENT_SENT_TO_COMPANY:
        case _quantuvisCmSpaConstantsEventType.EVENT_TYPE.DOCUMENT_SENT_FOR_SIGN_OFF:
        case _quantuvisCmSpaConstantsEventType.EVENT_TYPE.DOCUMENT_SENT_READY_FOR_SIGN_OFF:
        case _quantuvisCmSpaConstantsEventType.EVENT_TYPE.SIGNED_DOCUMENT_SENT:
        case _quantuvisCmSpaConstantsEventType.EVENT_TYPE.SIGN_OFF_REQUEST_SENT:
          return sentComponentPath;
        case _quantuvisCmSpaConstantsEventType.EVENT_TYPE.SIGN_OFF_UPDATED:
          return signOffUpdatedPath;
        case _quantuvisCmSpaConstantsEventType.EVENT_TYPE.DOCUMENT_EXECUTED:
        case _quantuvisCmSpaConstantsEventType.EVENT_TYPE.DOCUMENT_EXPIRED:
        case _quantuvisCmSpaConstantsEventType.EVENT_TYPE.DOCUMENT_REINSTATED:
        case _quantuvisCmSpaConstantsEventType.EVENT_TYPE.SIGN_OFF_COMPLETED:
        case _quantuvisCmSpaConstantsEventType.EVENT_TYPE.SIGN_OFF_EXPIRED:
        case _quantuvisCmSpaConstantsEventType.EVENT_TYPE.SIGN_OFF_REASSIGNED:
        case _quantuvisCmSpaConstantsEventType.EVENT_TYPE.SIGN_OFF_DECLINED:
          return null;
        case _quantuvisCmSpaConstantsEventType.EVENT_TYPE.DOCUMENT_TERMINATED:
          return terminatedComponentPath;
        case _quantuvisCmSpaConstantsEventType.EVENT_TYPE.DOCUMENT_RECEIVED:
        case _quantuvisCmSpaConstantsEventType.EVENT_TYPE.SIGNED_DOCUMENT_RECEIVED:
        case _quantuvisCmSpaConstantsEventType.EVENT_TYPE.DOCUMENT_RECEIVED_READY_FOR_SIGN_OFF:
          return this.defineDocumentReceivedPath(basicComponentPath, newReceivedPath);
        case _quantuvisCmSpaConstantsEventType.EVENT_TYPE.SIGN_OFF_REQUEST_RECEIVED:
          return newReceivedPath;
        case _quantuvisCmSpaConstantsEventType.EVENT_TYPE.SIGN_OFF_REQUEST_ACCEPTED_COUNTERPARTY:
        case _quantuvisCmSpaConstantsEventType.EVENT_TYPE.SIGN_OFF_REQUEST_ACCEPTED:
        case _quantuvisCmSpaConstantsEventType.EVENT_TYPE.SIGN_OFF_REQUEST_DECLINED_COUNTERPARTY:
        case _quantuvisCmSpaConstantsEventType.EVENT_TYPE.SIGN_OFF_REQUEST_DECLINED:
        case _quantuvisCmSpaConstantsEventType.EVENT_TYPE.SIGN_OFF_CANCELLED_COUNTERPARTY:
        case _quantuvisCmSpaConstantsEventType.EVENT_TYPE.SIGN_OFF_CANCELLED:
        case _quantuvisCmSpaConstantsEventType.EVENT_TYPE.SIGN_OFF_UPDATED_COUNTERPARTY:
          return signOffHandleDetailsComponentPath;
        default:
          return basicComponentPath;
      }
    }),

    getDeclinedAttachment: function getDeclinedAttachment() {
      var attachments = this.get('data.attachments');

      return attachments.find(function (attachment) {
        return _ember['default'].get(attachment, 'status.id') === _quantuvisCmSpaConstantsAttachmentStatus['default'].DECLINED;
      });
    },

    defineDocumentReceivedPath: function defineDocumentReceivedPath(basicComponentPath, newReceivedPath) {
      if (this.get('data.position') === 0 || this.get('data.attachments') && this.get('data.attachments').length === 0) {
        return newReceivedPath;
      } else {
        return basicComponentPath;
      }
    },

    isSignOffDeclined: function isSignOffDeclined() {
      var properties = this.get('data.properties');

      var signOffStatusId = Number(this.get('timeline').getEventPropertyValueByName(properties, _quantuvisCmSpaConstantsEventPropertyName['default'].SIGN_OFF_STATUS_ID));

      return signOffStatusId === _quantuvisCmSpaConstantsSignOffStatus['default'].DECLINED_ID || signOffStatusId === _quantuvisCmSpaConstantsSignOffStatus['default'].CANCELLED_ID || signOffStatusId === _quantuvisCmSpaConstantsSignOffStatus['default'].EXPIRED_ID;
    },

    isSignOffStatusIdPropertyPresent: function isSignOffStatusIdPropertyPresent() {
      var properties = this.get('data.properties');

      return this.get('timeline').isPresentEventPropertyByName(properties, _quantuvisCmSpaConstantsEventPropertyName['default'].SIGN_OFF_STATUS_ID);
    },

    bindEventClass: _ember['default'].computed('data.eventType.id', function () {
      var eventTypeId = this.get('data.eventType.id');

      switch (eventTypeId) {
        case _quantuvisCmSpaConstantsEventType.EVENT_TYPE.DOCUMENT_EXECUTED:
        case _quantuvisCmSpaConstantsEventType.EVENT_TYPE.AMENDMENT_EXECUTED:
        case _quantuvisCmSpaConstantsEventType.EVENT_TYPE.DOCUMENT_REINSTATED:
        case _quantuvisCmSpaConstantsEventType.EVENT_TYPE.AMENDMENT_REINSTATED:
          return 'document-executed';
        case _quantuvisCmSpaConstantsEventType.EVENT_TYPE.DOCUMENT_TERMINATED:
        case _quantuvisCmSpaConstantsEventType.EVENT_TYPE.AMENDMENT_TERMINATED:
        case _quantuvisCmSpaConstantsEventType.EVENT_TYPE.DOCUMENT_EXPIRED:
          return 'document-terminated';
        case _quantuvisCmSpaConstantsEventType.EVENT_TYPE.DOCUMENT_DEACTIVATED:
          return 'document-deactivated';
        case _quantuvisCmSpaConstantsEventType.EVENT_TYPE.DOCUMENT_SIGNED_INTERNALLY:
          return this.getDeclinedAttachment() ? 'document-signed-internally-declined' : '';
        case _quantuvisCmSpaConstantsEventType.EVENT_TYPE.SIGN_OFF_REQUEST_SENT:
        case _quantuvisCmSpaConstantsEventType.EVENT_TYPE.SIGN_OFF_REQUEST_RECEIVED:
        case _quantuvisCmSpaConstantsEventType.EVENT_TYPE.SIGN_OFF_REQUEST_ACCEPTED:
        case _quantuvisCmSpaConstantsEventType.EVENT_TYPE.SIGN_OFF_REQUEST_ACCEPTED_COUNTERPARTY:
        case _quantuvisCmSpaConstantsEventType.EVENT_TYPE.SIGN_OFF_SIGNED_BY_SIGNATORY:
        case _quantuvisCmSpaConstantsEventType.EVENT_TYPE.SIGN_OFF_COMPLETED:
        case _quantuvisCmSpaConstantsEventType.EVENT_TYPE.SIGN_OFF_UPDATED:
        case _quantuvisCmSpaConstantsEventType.EVENT_TYPE.SIGN_OFF_UPDATED_COUNTERPARTY:
        case _quantuvisCmSpaConstantsEventType.EVENT_TYPE.SIGN_OFF_REASSIGNED:
          return this.isSignOffStatusIdPropertyPresent() && this.isSignOffDeclined() ? 'sign-off-declined' : 'sign-off';
        case _quantuvisCmSpaConstantsEventType.EVENT_TYPE.SIGN_OFF_CANCELLED:
        case _quantuvisCmSpaConstantsEventType.EVENT_TYPE.SIGN_OFF_CANCELLED_COUNTERPARTY:
        case _quantuvisCmSpaConstantsEventType.EVENT_TYPE.SIGN_OFF_DECLINED:
        case _quantuvisCmSpaConstantsEventType.EVENT_TYPE.SIGN_OFF_REQUEST_DECLINED:
        case _quantuvisCmSpaConstantsEventType.EVENT_TYPE.SIGN_OFF_REQUEST_DECLINED_COUNTERPARTY:
        case _quantuvisCmSpaConstantsEventType.EVENT_TYPE.SIGN_OFF_EXPIRED:
          return 'sign-off-declined';
        default:
          return '';
      }
    })
  });
});