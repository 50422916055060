define('quantuvis-cm-spa/models/initiate-sign-off', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    originAttachmentId: _emberData['default'].attr('number'),
    originContractId: _emberData['default'].attr('number'),
    companyId: _emberData['default'].attr('number'),
    dueDate: _emberData['default'].attr('unix-date'),
    emailSubject: _emberData['default'].attr('string'),
    emailMessage: _emberData['default'].attr('string'),
    signers: _emberData['default'].attr()
  });
});