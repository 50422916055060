define('quantuvis-cm-spa/components/cm-document-preview/custom-pdf-template-document', ['exports', 'ember', 'ember-pdf-js/components/pdf-js'], function (exports, _ember, _emberPdfJsComponentsPdfJs) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  var get = _ember['default'].get;
  var set = _ember['default'].set;

  /* jshint undef: false */
  var _PDFJS = PDFJS;
  var PDFLinkService = _PDFJS.PDFLinkService;
  var PDFViewer = _PDFJS.PDFViewer;
  var PDFHistory = _PDFJS.PDFHistory;
  var PDFFindController = _PDFJS.PDFFindController;

  /* jshint undef: true */

  exports['default'] = _emberPdfJsComponentsPdfJs['default'].extend({
    templatePlaceholderService: _ember['default'].inject.service(),
    toolbarComponent: 'cm-document-preview/empty-toolbar/custom-empty-pdf-js-toolbar',

    /**
     * Runs as a hook in Ember each time when the element for this component
     * has been changed.
     *
     * @method  didRender
     * @return void
     */
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var _element$getElementsByClassName = this.element.getElementsByClassName('pdfViewerContainer');

      var _element$getElementsByClassName2 = _slicedToArray(_element$getElementsByClassName, 1);

      var container = _element$getElementsByClassName2[0];

      this.set('_container', container);
      var pdfLinkService = new PDFLinkService();

      this.set('pdfLinkService', pdfLinkService);
      var pdfViewer = new PDFViewer({
        container: container,
        linkService: pdfLinkService
      });

      this.set('pdfViewer', pdfViewer);
      pdfLinkService.setViewer(pdfViewer);
      var pdfHistory = new PDFHistory({
        linkService: pdfLinkService
      });

      this.set('pdfHistory', pdfHistory);
      pdfLinkService.setHistory(pdfHistory);
      var pdfFindController = new PDFFindController({
        pdfViewer: pdfViewer
      });

      this.set('pdfFindController', pdfFindController);

      pdfViewer.setFindController(pdfFindController);
      pdfViewer.currentScaleValue = 'page-fit';

      PDFFindController.prototype.updateMatchPosition = function () {};

      pdfFindController.onUpdateResultsCount = (function (total) {
        this.set('matchCount', total);
      }).bind(this);

      pdfViewer.eventBus.on('pagesloaded', function () /*evt*/{
        pdfViewer.currentScaleValue = 1;
      });

      var src = get(this, 'src');

      if (src) {
        var loadAction = this.actions.load.bind(this);

        loadAction(src, pdfViewer, pdfLinkService);
      }
    },

    actions: {
      load: function load(src, viewer, linkService) {
        var _this = this;

        if (!src) {
          return;
        }
        var loadingTask = get(this, 'pdfLib').getDocument(src);

        loadingTask.onPassword = function (updateCallback, reason) {
          _this.sendAction('onPassword', updateCallback, reason);
        };

        loadingTask = loadingTask.then(function (pdfDocument) {
          set(_this, 'pdfDocument', pdfDocument);
          viewer.setDocument(pdfDocument);
          linkService.setDocument(pdfDocument);
          set(_this, 'pdfTotalPages', linkService.pagesCount);
          set(_this, 'pdfPage', linkService.page);
          _this.get('templatePlaceholderService').loadPlaceholders().then(function (records) {
            var query = records.map(function (record) {
              return record.get('placeholder');
            }).join(' ').toLowerCase();

            _this.get('pdfFindController').executeCommand('find', {
              caseSensitive: false,
              findPrevious: true,
              highlightAll: true,
              phraseSearch: false,
              query: query
            });
          });
        });

        set(this, 'loadingTask', loadingTask);

        return loadingTask;
      }
    }
  });
});
/*global PDFJS*/