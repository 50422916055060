define('quantuvis-cm-spa/services/workflow-template', ['exports', 'ember', 'quantuvis-cm-spa/mixins/workflow-steps', 'quantuvis-cm-spa/constants/workflow-substep-type', 'quantuvis-cm-spa/constants/workflow-step-type', 'quantuvis-cm-spa/constants/due-date-events', 'quantuvis-cm-spa/models/workflows/workflow-template-substep-preset', 'quantuvis-cm-spa/constants/workflow-step-state'], function (exports, _ember, _quantuvisCmSpaMixinsWorkflowSteps, _quantuvisCmSpaConstantsWorkflowSubstepType, _quantuvisCmSpaConstantsWorkflowStepType, _quantuvisCmSpaConstantsDueDateEvents, _quantuvisCmSpaModelsWorkflowsWorkflowTemplateSubstepPreset, _quantuvisCmSpaConstantsWorkflowStepState) {
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  exports['default'] = _ember['default'].Service.extend(_quantuvisCmSpaMixinsWorkflowSteps['default'], {
    store: _ember['default'].inject.service(),
    workflowDueDate: _ember['default'].inject.service(),
    workflowDueDateValidator: _ember['default'].inject.service(),

    /**
     * All available templates in session
     */
    templates: [],

    /**
     * Active template
     */
    selectedTemplate: null,

    /**
     * Template steps
     */
    steps: [],

    stepsChanged: _ember['default'].observer('steps', function () {
      this.set('workflowStepsRequired', this.get('steps').filter(function (step) {
        return step.get('isRequired');
      }));
      this.set('workflowStepsSorted', this.get('steps').filter(function (step) {
        return !step.get('isRequired');
      }));
    }),

    reloadWorkflowFromTemplate: function reloadWorkflowFromTemplate() {
      if (this.get('templates').get('length') > 0) {
        this.setInitialData(this.get('templates').get('firstObject'));
      }
    },

    setInitialData: function setInitialData(firstObject) {
      if (!this.get('selectedTemplate')) {
        this.switchWorkflow(firstObject);
      }
    },

    createStepFromTemplate: function createStepFromTemplate(template) {
      var _this = this;

      template.substeps = template.substeps.map(function (substep) {
        return _this.get('store').createRecord('workflows/substep', substep);
      });

      return this.get('store').createRecord('workflows/step', template);
    },

    /**
     * Create copy of workflow steps from template
     * @param template
     */
    switchWorkflow: function switchWorkflow(template) {
      var _this2 = this;

      var templates = template.get('stepsSortedByPosition');
      var steps = [];

      templates.forEach(function (stepTemplate) {
        steps.push(_ember['default'].copy(stepTemplate));
      });

      this.get('workflowDueDate').populateDueDate(steps);

      this.set('steps', steps.map(function (step) {
        var createdStep = _this2.createStepFromTemplate(step);

        _this2.populateAdditionalDataToCreatedStep(createdStep);

        return createdStep;
      }));
      this.set('selectedTemplate', template);
    },

    populateAdditionalDataToCreatedStep: function populateAdditionalDataToCreatedStep(step) {
      step.set('state', { id: _quantuvisCmSpaConstantsWorkflowStepState['default'].PENDING });
    },

    /**
     * Fetch workflow templates from backend
     */
    fetchTemplates: function fetchTemplates() {
      var _this3 = this;

      return this.get('store').query('workflows/template', {}).then(function (templates) {
        _this3.set('templates', templates);

        _this3.setInitialData(templates.get('firstObject'));
      });
    },

    unloadTemplates: function unloadTemplates() {
      var _this4 = this;

      this.flush();

      return _ember['default'].run(function () {
        return _this4.get('store').unloadAll('workflows/template');
      });
    },

    create: function create(template, changesets) {
      var _this5 = this;

      return this.validate(changesets, template).then(function () {
        return _this5.normalizeTemplate(template).save();
      });
    },

    update: function update(template, changesets) {
      var _this6 = this;

      return this.validate(changesets, template).then(function () {
        _this6.normalizeTemplate(template);

        return template.save();
      });
    },

    /**
     * Flush local stores
     */
    flush: function flush() {
      this.get('store').unloadAll('workflows/template/step');
      this.get('store').unloadAll('workflows/template/substep');
    },

    normalizeTemplate: function normalizeTemplate(template) {
      get(template, 'steps').forEach(this.normalizeStep.bind(this));

      return template;
    },

    normalizeSubstep: function normalizeSubstep(substep, position) {
      set(substep, 'position', position);

      if (get(substep, 'type.id') === _quantuvisCmSpaConstantsWorkflowSubstepType.DEPARTMENT_REVIEW_ID) {
        var department = get(substep, 'department.id');

        set(substep, 'departmentId', department);
        this.normalizeResponsiblePersons(substep);
      }
    },

    normalizeStep: function normalizeStep(step, position) {
      set(step, 'position', position);
      get(step, 'substeps').forEach(this.normalizeSubstep.bind(this));
    },

    normalizeResponsiblePersons: function normalizeResponsiblePersons(substep) {
      var persons = get(substep, 'leads').map(function (lead) {
        return { userId: lead.id };
      });

      set(substep, 'responsiblePersons', persons);
    },

    validate: function validate(changeSetList, template) {
      var _this7 = this;

      var promises = [];

      changeSetList.forEach(function (changeSet) {
        promises.push(changeSet.validate());
      });

      return Promise.all(promises).then(function () {
        var invalidList = Array.from(changeSetList.values()).filter(function (changeSet) {
          return changeSet.get('isInvalid');
        });
        var isInvalidDueDates = !_this7.get('workflowDueDateValidator').isValid(get(template, 'steps'));

        if (invalidList.length !== 0 || isInvalidDueDates) {
          invalidList.forEach(function (item) {
            item.get('expandOnInvalid') && item.get('expandOnInvalid')();
          });

          return Promise.reject(false);
        }
        _this7.saveChangesetsList(changeSetList);
      });
    },

    saveChangesetsList: function saveChangesetsList(changeSetList) {
      changeSetList.forEach(function (changeSet) {
        changeSet.execute();
      });
    },

    getTemplatePreset: function getTemplatePreset() {
      return this.get('store').createRecord('workflows/template', {
        name: '',
        description: '',
        steps: [this.createGeneralOverviewStep()]
      });
    },

    createStep: function createStep() {
      return this.get('store').createRecord('workflows/template/step', {
        name: '',
        type: _quantuvisCmSpaConstantsWorkflowStepType.DEPARTMENT_REVIEW,
        substeps: [this.createSubstep()],
        isCollapsed: false,
        isRequired: false,
        dueDateTemplate: {
          units: 10,
          dueDateEvent: _quantuvisCmSpaConstantsDueDateEvents.DAYS_AFTER_PREVIOUS_STEP_EVENT
        }
      });
    },

    createGeneralOverviewStep: function createGeneralOverviewStep() {
      return this.get('store').createRecord('workflows/template/step', {
        name: '',
        position: 0,
        isRequired: true,
        notes: null,
        type: {
          id: _quantuvisCmSpaConstantsWorkflowStepType.CONTRACT_LEAD_REVIEW_ID,
          name: 'Contract Lead Review'
        },
        substeps: [],
        dueDateTemplate: {
          units: 10,
          dueDateEvent: _quantuvisCmSpaConstantsDueDateEvents.DAYS_FROM_CONTRACT_CREATION_EVENT
        }
      });
    },

    createSubstep: function createSubstep() {
      return this.get('store').createRecord('workflows/template/substep', new _quantuvisCmSpaModelsWorkflowsWorkflowTemplateSubstepPreset['default']());
    },

    resetSelectedTemplate: function resetSelectedTemplate() {
      this.set('selectedTemplate', null);
    },

    resetTemplatesAndSteps: function resetTemplatesAndSteps() {
      this.resetSelectedTemplate();
      this.unloadTemplates();
      this.clearSteps();
    },

    clearSteps: function clearSteps() {
      this.set('steps', []);
    }
  });
});