define('quantuvis-cm-spa/components/cm-send-document/recipients-list/item', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['recipients-list-item'],
    classNameBindings: ['active:active'],
    tagName: 'li',

    changeset: {},

    actions: {
      onEdit: function onEdit() {
        var _getProperties = this.getProperties('index', 'changeset');

        var index = _getProperties.index;
        var changeset = _getProperties.changeset;

        this.sendAction('onEdit', {
          changeset: changeset,
          index: index
        });
      },

      onDelete: function onDelete() {
        this.sendAction('onDelete', this.get('index'));
      }
    }
  });
});