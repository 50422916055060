define('quantuvis-cm-spa/services/workflow-due-date', ['exports', 'ember', 'moment', 'quantuvis-cm-spa/constants/due-date-events', 'quantuvis-cm-spa/constants/date-format'], function (exports, _ember, _moment, _quantuvisCmSpaConstantsDueDateEvents, _quantuvisCmSpaConstantsDateFormat) {
  exports['default'] = _ember['default'].Service.extend({
    steps: [],
    contractCreationDate: null,
    dueDatePropertyName: 'dueDate',

    populateDueDate: function populateDueDate(steps) {
      this.set('contractCreationDate', (0, _moment['default'])().startOf('day'));
      this.set('steps', steps);

      steps.forEach(this.populateStepDueDate, this);
    },

    populateStepDueDate: function populateStepDueDate(step, index) {
      var _this = this;

      if (step.dueDateTemplate) {
        _ember['default'].set(step, this.dueDatePropertyName, this.createStepDueDateByTemplate(step.dueDateTemplate, index));
      }

      step.substeps.forEach(function (substep, substepIndex) {
        return _this.populateSubStepDueDate(substep, index, substepIndex);
      });
    },

    populateSubStepDueDate: function populateSubStepDueDate(substep, stepIndex, substepIndex) {
      var dueDate = this.isFirstSubstep(substepIndex) ? this.createStepDueDateByTemplate(substep.dueDateTemplate, stepIndex) : this.createSubstepDueDateByTemplate(substep.dueDateTemplate, stepIndex, substepIndex);

      _ember['default'].set(substep, this.dueDatePropertyName, dueDate);
    },

    createStepDueDateByTemplate: function createStepDueDateByTemplate(template, stepIndex) {
      var dueDate = undefined;

      switch (template.dueDateEvent.id) {
        case _quantuvisCmSpaConstantsDueDateEvents.DAYS_FROM_CONTRACT_CREATION_EVENT.id:
          dueDate = this.createDateWithDaysAfterCurrent(template.units);
          break;

        case _quantuvisCmSpaConstantsDueDateEvents.WEEKS_FROM_CONTRACT_CREATION_EVENT.id:
          dueDate = this.createDateWithWeeksAfterCurrent(template.units);
          break;

        case _quantuvisCmSpaConstantsDueDateEvents.DAYS_AFTER_PREVIOUS_STEP_EVENT.id:
          dueDate = this.createDateWithDaysAfterPreviousStep(template.units, stepIndex - 1);
          break;

        default:
          dueDate = this.getCurrentDate();
      }

      return dueDate.format(_quantuvisCmSpaConstantsDateFormat['default'].MM_DD_YYYY);
    },

    createSubstepDueDateByTemplate: function createSubstepDueDateByTemplate(template, stepIndex, substepIndex) {
      var dueDate = undefined;

      switch (template.dueDateEvent.id) {
        case _quantuvisCmSpaConstantsDueDateEvents.DAYS_FROM_CONTRACT_CREATION_EVENT.id:
          dueDate = this.createDateWithDaysAfterCurrent(template.units);
          break;

        case _quantuvisCmSpaConstantsDueDateEvents.WEEKS_FROM_CONTRACT_CREATION_EVENT.id:
          dueDate = this.createDateWithWeeksAfterCurrent(template.units);
          break;

        case _quantuvisCmSpaConstantsDueDateEvents.DAYS_AFTER_PREVIOUS_STEP_EVENT.id:
          dueDate = this.createDateWithDaysAfterPreviousSubstep(template.units, stepIndex, substepIndex - 1);
          break;

        default:
          dueDate = this.getCurrentDate();
      }

      return dueDate.format(_quantuvisCmSpaConstantsDateFormat['default'].MM_DD_YYYY);
    },

    createDateWithDaysAfterPreviousStep: function createDateWithDaysAfterPreviousStep(days, previousStepIndex) {
      var dueDate = this.get('steps')[previousStepIndex].dueDate;

      return (0, _moment['default'])(dueDate).add(days, 'days');
    },

    createDateWithDaysAfterPreviousSubstep: function createDateWithDaysAfterPreviousSubstep(units, stepIndex, substepIndex) {
      var dueDate = this.get('steps')[stepIndex]['substeps'][substepIndex].dueDate;

      return (0, _moment['default'])(dueDate).add(units, 'days');
    },

    createDateWithDaysAfterCurrent: function createDateWithDaysAfterCurrent(days) {
      return this.getCurrentDate().add(days, 'days');
    },

    createDateWithWeeksAfterCurrent: function createDateWithWeeksAfterCurrent(weeks) {
      return this.getCurrentDate().add(weeks, 'weeks');
    },

    getCurrentDate: function getCurrentDate() {
      return (0, _moment['default'])(this.get('contractCreationDate'));
    },

    isFirstSubstep: function isFirstSubstep(substepIndex) {
      return substepIndex === 0;
    }
  });
});