define('quantuvis-cm-spa/core-ui/components/cm-wizard/cm-timeline/progress-item/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'li',
    classNames: ['cm-progress-item'],

    isActive: _ember['default'].computed('current', function () {
      return this.get('current').position === this.get('step').position;
    }),

    isCompleted: _ember['default'].computed('current', function () {
      return this.get('step').position < this.get('current').position;
    })
  });
});