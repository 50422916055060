define('quantuvis-cm-spa/services/contract-details-sidebar-navigator/menu-items', ['exports', 'quantuvis-cm-spa/constants/details-menu-items'], function (exports, _quantuvisCmSpaConstantsDetailsMenuItems) {
  exports['default'] = [{
    id: _quantuvisCmSpaConstantsDetailsMenuItems['default'].TIMELINE,
    iconName: 'calendar-with-clock',
    title: 'Timeline',
    urlHash: 'timeline',
    template: 'cm-timeline/cm-timeline',
    menuComponent: 'cm-contract-details/sidebar-menus/timeline-menu',
    dataTestId: 'timeline-icon'
  }, {
    id: _quantuvisCmSpaConstantsDetailsMenuItems['default'].SUMMARY,
    iconName: 'info-filled',
    title: 'Summary',
    urlHash: 'summary',
    template: 'cm-contract-details/cm-summary',
    menuComponent: 'cm-contract-details/sidebar-menus/summary-menu',
    dataTestId: 'summury-icon'
  }, {
    id: _quantuvisCmSpaConstantsDetailsMenuItems['default'].NOTES,
    iconName: 'notes',
    title: 'Internal Notes',
    urlHash: 'notes',
    template: 'cm-notes/cm-notes',
    menuComponent: 'cm-contract-details/sidebar-menus/notes-menu',
    componentItem: 'cm-contract-details/cm-contract-details-sidebar-note',
    dataTestId: 'notes-icon'
  }, {
    id: _quantuvisCmSpaConstantsDetailsMenuItems['default'].AMENDMENTS,
    iconName: 'document-2',
    title: 'Amendments',
    urlHash: 'amendments',
    template: 'cm-contract-details/cm-amendments',
    dataTestId: 'amendment-icon'
  }, {
    id: _quantuvisCmSpaConstantsDetailsMenuItems['default'].HISTORY,
    iconName: 'list',
    title: 'History',
    urlHash: 'history',
    menuComponent: 'cm-contract-details/sidebar-menus/history-menu',
    dataTestId: 'history-icon',
    template: 'cm-contract-details/cm-history/cm-history'
  }];
});