define('quantuvis-cm-spa/validations/general-details-custom', ['exports', 'ember-changeset-validations/validators', 'quantuvis-cm-spa/validations/custom-validators'], function (exports, _emberChangesetValidationsValidators, _quantuvisCmSpaValidationsCustomValidators) {
  exports['default'] = {
    contractOrigin: [(0, _emberChangesetValidationsValidators.validatePresence)(true)],
    contractName: [(0, _emberChangesetValidationsValidators.validatePresence)(true), (0, _emberChangesetValidationsValidators.validateLength)({ max: 150 }), (0, _emberChangesetValidationsValidators.validateFormat)({ regex: /^[\w\d!\-_.*'() ]+$/ })],
    contractDescription: [(0, _emberChangesetValidationsValidators.validateLength)({ allowBlank: true, max: 255 })],
    lineOfBusiness: [(0, _emberChangesetValidationsValidators.validatePresence)(true)],
    contractLead: [(0, _emberChangesetValidationsValidators.validatePresence)(true), (0, _emberChangesetValidationsValidators.validateLength)({ max: 25 })],
    startDate: [(0, _emberChangesetValidationsValidators.validatePresence)(true), (0, _quantuvisCmSpaValidationsCustomValidators.validateDateFormat)()],
    endDate: [(0, _emberChangesetValidationsValidators.validatePresence)(true), (0, _quantuvisCmSpaValidationsCustomValidators.validateDateFormat)(), (0, _quantuvisCmSpaValidationsCustomValidators.validateEndDate)()],
    evergreen: [(0, _emberChangesetValidationsValidators.validatePresence)(true)],
    contractId: [(0, _emberChangesetValidationsValidators.validatePresence)(true), (0, _emberChangesetValidationsValidators.validateLength)({ max: 25 })],
    templateComboValue: (0, _quantuvisCmSpaValidationsCustomValidators.validateFileKey)()
  };
});