define('quantuvis-cm-spa/components/cm-contract-details/summary-components/contact-info', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: 'contact-info',

    actions: {
      toggleCollapse: function toggleCollapse() {
        // TODO add e2e test for slide toggle
        this.$('.collapse-container').slideToggle();
        this.$('.toggle-button').toggleClass('rotated');
      }
    }
  });
});