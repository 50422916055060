define('quantuvis-cm-spa/components/cm-document-preview/cm-template-preview', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    file: _ember['default'].inject.service(),

    actions: {
      previewDocument: function previewDocument() {
        this.get('file').previewTemplate(this.get('document'));
      }
    }
  });
});