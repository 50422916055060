define('quantuvis-cm-spa/services/permission-service', ['exports', 'ember', 'quantuvis-cm-spa/constants/permissions'], function (exports, _ember, _quantuvisCmSpaConstantsPermissions) {
  exports['default'] = _ember['default'].Service.extend({
    store: _ember['default'].inject.service(),
    companyId: null,
    assignedPermissions: null,

    checkCompanyUserPermissions: function checkCompanyUserPermissions(permissions, companyId) {
      var _this = this;

      return !permissions.find(function (name) {
        return !_this.checkCompanyUserPermission(name, companyId);
      });
    },

    checkCompanyUserPermission: function checkCompanyUserPermission(permissionId, companyId) {
      var idToCheck = companyId;

      if (!companyId) {
        var storedId = this.get('companyId');

        if (!storedId) {
          return false;
        } else {
          idToCheck = storedId;
        }
      }

      var assignedPermissions = this.get('assignedPermissions');

      if (!assignedPermissions) {
        return false;
      }

      var companyGrant = assignedPermissions.get('companyPermissionGrants').find(function (grant) {
        return grant.entityId === idToCheck;
      });

      if (!companyGrant) {
        return false;
      }

      var permission = companyGrant.permissions.find(function (permission) {
        return permission.id === permissionId && permission.resource.id === _quantuvisCmSpaConstantsPermissions.PERMISSIONS.RESOURCE.COMPANY && permission.application.id === _quantuvisCmSpaConstantsPermissions.PERMISSIONS.APPLICATION.CM;
      });

      return !!permission;
    },

    checkGlobalUserPermission: function checkGlobalUserPermission(permissionId) {
      var assignedPermissions = this.get('assignedPermissions');

      if (!assignedPermissions) {
        return false;
      }

      var grant = assignedPermissions.get('globalPermissionGrants')[0];

      if (!grant) {
        return false;
      }

      var permission = grant.permissions.find(function (permission) {
        return permission.id === permissionId && permission.resource.id === _quantuvisCmSpaConstantsPermissions.PERMISSIONS.RESOURCE.GLOBAL && permission.application.id === _quantuvisCmSpaConstantsPermissions.PERMISSIONS.APPLICATION.CM;
      });

      return !!permission;
    },

    checkAdminPermissionByApplication: function checkAdminPermissionByApplication(permissionId, applicationId) {
      var assignedPermissions = this.get('assignedPermissions');

      if (!assignedPermissions) {
        return false;
      }

      var adminPermissionGrants = assignedPermissions.get('adminPermissionGrants');

      if (!adminPermissionGrants.length) {
        return false;
      }

      return adminPermissionGrants.get('firstObject.permissions').some(function (_ref) {
        var id = _ref.id;
        var application = _ref.application;

        return id === permissionId && application.id === applicationId;
      });
    },

    hasPermissionForAnyCompany: function hasPermissionForAnyCompany(permissionId) {
      var assignedPermissions = this.get('assignedPermissions');

      var permissionMatch = function permissionMatch(permission) {
        return permission.id === permissionId && permission.resource.id === _quantuvisCmSpaConstantsPermissions.PERMISSIONS.RESOURCE.COMPANY && permission.application.id === _quantuvisCmSpaConstantsPermissions.PERMISSIONS.APPLICATION.CM;
      };

      var grantMatch = function grantMatch(grant) {
        return grant.permissions && !!grant.permissions.find(permissionMatch);
      };

      return assignedPermissions && assignedPermissions.get('companyPermissionGrants') && !!assignedPermissions.get('companyPermissionGrants').find(grantMatch);
    },

    getUserPermissions: function getUserPermissions() {
      var _this2 = this;

      return this.get('store').queryRecord('user-management/applications/permissions/permission-grants/my-grant', {}).then(function (data) {
        _this2.set('assignedPermissions', data);

        return data;
      });
    },

    getPermissionList: function getPermissionList(params) {
      return this.get('store').query('user-management/applications/permission', params);
    }
  });
});