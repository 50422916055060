define('quantuvis-cm-spa/validations/reset-password', ['exports', 'ember-changeset-validations/validators'], function (exports, _emberChangesetValidationsValidators) {

  var passwordRegex = /^(?=.*?[A-Z])(?=(.*[a-z])+)(?=(.*[\d])+)(?=(.*[\W_])+).{8,}$/;
  var stringDefaultLength = 255;

  exports['default'] = {
    answer: [(0, _emberChangesetValidationsValidators.validatePresence)(true), (0, _emberChangesetValidationsValidators.validateLength)({ max: 50 })],
    newPassword: [(0, _emberChangesetValidationsValidators.validatePresence)(true), (0, _emberChangesetValidationsValidators.validateLength)(stringDefaultLength), validatePasswordFormat()],
    confirmationPassword: [(0, _emberChangesetValidationsValidators.validatePresence)(true), (0, _emberChangesetValidationsValidators.validateLength)(stringDefaultLength), validatePasswordConfirmation()]
  };

  function validatePasswordFormat() {
    return function (key, newValue) {
      if (newValue && !passwordRegex.test(newValue)) {
        return 'Password does not follow the rule: at least 8 characters uppercase and lowercase letters, digit and' + ' at least one special character.';
      }

      return true;
    };
  }

  function validatePasswordConfirmation() {
    return function (key, newValue, oldValue, changes) {
      if (newValue && newValue !== changes.newPassword) {
        return 'Passwords did not match. Please check the spelling and try again.';
      }

      return true;
    };
  }
});