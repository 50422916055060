define('quantuvis-cm-spa/adapters/user-management/applications/resource', ['exports', 'quantuvis-cm-spa/adapters/application'], function (exports, _quantuvisCmSpaAdaptersApplication) {
  exports['default'] = _quantuvisCmSpaAdaptersApplication['default'].extend({
    termsOfUse: function termsOfUse(id) {
      var uri = this.urlPrefix() + '/user-management/applications/' + id + '/terms-of-use';

      return this.ajax(uri, 'GET');
    },
    privacyPolicy: function privacyPolicy(id) {
      var uri = this.urlPrefix() + '/user-management/applications/' + id + '/privacy-policy';

      return this.ajax(uri, 'GET');
    },
    redirectPath: function redirectPath(id) {
      var uri = this.urlPrefix() + '/user-management/applications/' + id + '/url';

      return this.ajax(uri, 'GET');
    }
  });
});