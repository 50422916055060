define('quantuvis-cm-spa/controllers/business-admin/document-types', ['exports', 'ember', 'quantuvis-cm-spa/constants/permissions', 'ember-changeset', 'ember-changeset-validations', 'quantuvis-cm-spa/validations/document-type'], function (exports, _ember, _quantuvisCmSpaConstantsPermissions, _emberChangeset, _emberChangesetValidations, _quantuvisCmSpaValidationsDocumentType) {
  var CANCEL_POPUP_TEXT = 'Are you sure you want to navigate out of the Edit Document Type pop-up? All the changes will be lost.';
  exports.CANCEL_POPUP_TEXT = CANCEL_POPUP_TEXT;
  var CONFIRM_POPUP_TEXT = 'Are you sure you want to apply changes to this document type? This action will affect all the users of CM';

  exports.CONFIRM_POPUP_TEXT = CONFIRM_POPUP_TEXT;
  exports['default'] = _ember['default'].Controller.extend({
    permissionService: _ember['default'].inject.service(),
    dialog: _ember['default'].inject.service(),
    store: _ember['default'].inject.service(),
    uniqueDocumentTypeValidator: _ember['default'].inject.service(),
    isDocumentTypeValidating: _ember['default'].computed.alias('uniqueDocumentTypeValidator.isLoading'),

    tableColumns: [{
      columnName: 'Title',
      index: 'name',
      component: 'business-admin/cm-table/columns/linked-column',
      className: 'document-type'
    }, {
      columnName: 'Description',
      index: 'description'
    }],
    changeset: {},
    showSpinner: false,
    documentTypeModel: {},

    isUserWithCmWrite: _ember['default'].computed('permissionService.assignedPermissions', function () {
      return this.get('permissionService').checkAdminPermissionByApplication(_quantuvisCmSpaConstantsPermissions.PERMISSIONS.ID.CM_WRITE, _quantuvisCmSpaConstantsPermissions.PERMISSIONS.APPLICATION.CM);
    }),

    attachUniqueDocumentTypeValidator: function attachUniqueDocumentTypeValidator(validators) {
      var uniquenessValidator = this.get('uniqueDocumentTypeValidator').validateUniqueness(this.get('documentTypeSelected') ? this.get('documentTypeSelected.id') : null);
      var present = validators.name.findIndex(function (validator) {
        return validator.toString() === uniquenessValidator.toString();
      });

      /**
       * Override validator if present
       */
      present === -1 ? validators.name.push(uniquenessValidator) : validators.name[present] = uniquenessValidator;
    },

    initChangeset: function initChangeset(documentType) {
      this.set('documentTypeModel', {
        id: documentType ? documentType.get('id') : '',
        name: documentType ? documentType.get('name') : '',
        description: documentType ? documentType.get('description') : ''
      });

      this.attachUniqueDocumentTypeValidator(_quantuvisCmSpaValidationsDocumentType['default']);

      var changeset = new _emberChangeset['default'](this.get('documentTypeModel'), (0, _emberChangesetValidations['default'])(_quantuvisCmSpaValidationsDocumentType['default']), _quantuvisCmSpaValidationsDocumentType['default'], {
        skipValidate: true
      });

      this.set('changeset', changeset);
    },

    actions: {
      addType: function addType() {
        this.initChangeset();

        this.openDialog({
          title: 'Add Document Type',
          handlerAction: 'save'
        });
      },

      editType: function editType() {
        this.initChangeset(this.get('documentTypeSelected'));

        this.openDialog({
          title: 'Edit Document Type',
          handlerAction: 'update',
          declineHandler: 'documentTypeDeclineHandler'
        });
      },

      onSelectDocumentType: function onSelectDocumentType(documentType) {
        this.set('documentTypeSelected', documentType.record);
      },

      documentTypeHandler: function documentTypeHandler(presenter) {
        var _this = this;

        var changeset = this.get('changeset');

        changeset.validate().then(function () {
          if (changeset.get('isInvalid')) {
            return Promise.reject();
          } else {

            if (presenter.handlerAction === 'save') {
              _this.saveDocumentType(presenter);
            }
            if (presenter.handlerAction === 'update') {
              _this.showConfirmDialog(CONFIRM_POPUP_TEXT).then(function () {
                return _this.updateDocumentType(presenter);
              });
            }
          }
        }).then()['catch']();
      },

      documentTypeDeclineHandler: function documentTypeDeclineHandler(presenter) {
        var oldModel = this.get('documentTypeModel');
        var changes = this.get('changeset.change');

        if (Object.keys(changes).some(function (key) {
          return changes[key] !== oldModel[key];
        })) {
          this.showConfirmDialog(CANCEL_POPUP_TEXT).then(function () {
            return presenter.decline();
          });
        } else {
          presenter.decline();
        }
      }
    },

    openDialog: function openDialog(additionalOptions) {
      var options = Object.assign({
        className: 'document-type-dialog',
        componentName: 'business-admin/document-types/type',
        acceptText: 'Save',
        acceptHandler: 'documentTypeHandler'
      }, additionalOptions);

      this.get('dialog').show('dialogs/cm-dialog', null, this, options);
    },

    saveDocumentType: function saveDocumentType(presenter) {
      this.beforeAction();
      this.createAction(this.get('store').createRecord('contracts/type', this.get('documentTypeModel')).save(), presenter);
    },

    updateDocumentType: function updateDocumentType(presenter) {
      this.beforeAction();
      this.createAction(this.get('store').adapterFor('application').put('contracts/type', this.get('documentTypeModel.id'), {
        data: this.get('documentTypeModel')
      }), presenter);
    },

    beforeAction: function beforeAction() {
      this.set('showSpinner', true);
      this.get('changeset').save();
    },

    createAction: function createAction(action, presenter) {
      var _this2 = this;

      var afterAction = function afterAction() {
        presenter.accept();
        _this2.send('refreshModel');
        _this2.set('showSpinner', false);
      };

      action.then(function () {
        return afterAction();
      })['catch'](function () {
        return afterAction();
      });
    },

    showConfirmDialog: function showConfirmDialog(confirmMessage) {
      return this.get('dialog').show('dialogs/cm-dialog', 'dialog-templates/cm-confirmation', this, { confirmMessage: confirmMessage });
    }

  });
});