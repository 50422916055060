define('quantuvis-cm-spa/components/cm-contract-details/cm-history/cm-history-item', ['exports', 'ember', 'quantuvis-cm-spa/constants/date-format'], function (exports, _ember, _quantuvisCmSpaConstantsDateFormat) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['history-item'],
    DATE_FORMAT: _quantuvisCmSpaConstantsDateFormat['default'],
    dialog: _ember['default'].inject.service(),

    getDetailsWindowOptions: function getDetailsWindowOptions() {
      return {
        className: 'cm-history-details-dialog',
        componentName: 'cm-contract-details/cm-history/cm-history-details',
        title: 'History Details',
        history: this.get('history'),
        hideAcceptButton: true
      };
    },

    actions: {
      viewDetails: function viewDetails() {
        this.get('dialog').show('dialogs/cm-dialog', null, this, this.getDetailsWindowOptions());
      }
    }
  });
});