define('quantuvis-cm-spa/routes/document-summary', ['exports', 'ember', 'quantuvis-cm-spa/mixins/authenticated-route-mixin', 'quantuvis-cm-spa/constants/permissions'], function (exports, _ember, _quantuvisCmSpaMixinsAuthenticatedRouteMixin, _quantuvisCmSpaConstantsPermissions) {
  exports['default'] = _ember['default'].Route.extend(_quantuvisCmSpaMixinsAuthenticatedRouteMixin['default'], {
    permissionService: _ember['default'].inject.service(),
    session: _ember['default'].inject.service(),
    contractDetails: _ember['default'].inject.service(),

    model: function model(params) {
      return _ember['default'].RSVP.hash({
        contract: this.get('store').findRecord('contract', params.contractId),
        workflow: this.get('store').queryRecord('workflow', { contractId: params.contractId })
      });
    },

    afterModel: function afterModel(model) {
      if (!this.hasUserPermission(model.contract)) {
        this.transitionTo('access-denied');
      } else {
        var companyId = model.contract.get('company.id');

        this.get('permissionService').set('companyId', companyId);
      }
    },

    hasUserPermission: function hasUserPermission(contract) {
      var companyId = contract.get('company.id');

      return this.get('permissionService').checkCompanyUserPermission(_quantuvisCmSpaConstantsPermissions.PERMISSIONS.ID.EDIT_SUMMARY, companyId);
    }
  });
});