define('quantuvis-cm-spa/services/print-document', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Service.extend({
    dialog: _ember['default'].inject.service(),
    file: _ember['default'].inject.service(),
    documentVersion: 0,

    printTimeline: false,
    printSummary: false,
    printNotes: false,
    printAmendments: false,
    printHistory: false,

    showModal: function showModal(model) {
      var _this = this;

      var options = {
        className: 'cm-print-document-dialog',
        componentName: 'cm-contract-details/cm-print-document',
        title: 'Print Document',
        acceptText: 'Print'
      };

      this.set('documentVersion', model.get('file.version'));
      this.get('dialog').show('dialogs/cm-dialog', null, this, options).then(function () {
        return _this.resetParams();
      })['catch'](function () {
        return _this.resetParams();
      });
    },

    resetParams: function resetParams() {
      this.set('printTimeline', false);
      this.set('printSummary', false);
      this.set('printNotes', false);
      this.set('printAmendments', false);
      this.set('printHistory', false);
      this.set('documentVersion', 0);
    },

    printImmediately: function printImmediately(model) {
      // TODO: this is temporary solution. Should be refactored by XXXX issue.
      this.set('file.attachment', {
        id: model.get('file.id'),
        version: model.get('file.version')
      });
      this.set('file.documentId', model.id);
      this.get('file').set('contextObject', this.get('file'));
      this.get('file').actions.printPdf.bind(this.get('file'))();
    },

    actions: {
      accept: function accept(presenter) {
        this.get('file').actions.printPdf.bind(this.get('file'))();
        presenter.accept();
      }
    }
  });
});