define('quantuvis-cm-spa/validations/custom-validators/due-date', ['exports', 'moment', 'quantuvis-cm-spa/constants/date-format'], function (exports, _moment, _quantuvisCmSpaConstantsDateFormat) {
  exports['default'] = dueDate;

  var ERROR_MESSAGE = 'Due Date can\'t be past';

  function dueDate() {
    return function (key, newValue) {
      var currentDate = (0, _moment['default'])().format(_quantuvisCmSpaConstantsDateFormat['default'].MM_DD_YYYY);
      var isDueDateSameAsCurrentDate = (0, _moment['default'])(currentDate).isSame(newValue);
      var isDueDateAfterCurrentDate = (0, _moment['default'])(newValue).isAfter(currentDate);

      if (isDueDateSameAsCurrentDate || isDueDateAfterCurrentDate) {
        return true;
      }

      return ERROR_MESSAGE;
    };
  }
});