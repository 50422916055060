define('quantuvis-cm-spa/services/attachment', ['exports', 'ember', 'ember-changeset', 'ember-changeset-validations', 'quantuvis-cm-spa/validations/add-attachment', 'quantuvis-cm-spa/constants/file-source', 'quantuvis-cm-spa/constants/attachment-source', 'quantuvis-cm-spa/constants/error-messages'], function (exports, _ember, _emberChangeset, _emberChangesetValidations, _quantuvisCmSpaValidationsAddAttachment, _quantuvisCmSpaConstantsFileSource, _quantuvisCmSpaConstantsAttachmentSource, _quantuvisCmSpaConstantsErrorMessages) {
  exports['default'] = _ember['default'].Service.extend({
    dialog: _ember['default'].inject.service(),
    store: _ember['default'].inject.service(),
    contractDetails: _ember['default'].inject.service(),
    attachmentSources: [{
      key: _quantuvisCmSpaConstantsAttachmentSource['default'].UPLOAD,
      label: 'Upload a file'
    }, {
      key: _quantuvisCmSpaConstantsAttachmentSource['default'].BID,
      label: 'Add bid data'
    }],
    bidPrintTypes: [],

    model: {},
    changeset: {},
    contractId: null,

    showDialogSpinner: false,
    attachments: null,
    showAttachmentSources: false,

    showModal: function showModal(attachments) {
      var document = this.get('contractDetails').getContract();
      var hasBids = document.get('bids').length > 0;

      this.set('showAttachmentSources', hasBids);
      this.set('attachments', attachments);
      this.initModel();
      this.initChangeset();

      if (hasBids) {
        this.loadPrintTypes();
      }

      var options = {
        className: 'add-attachment-dialog',
        componentName: 'cm-contract-details/summary-components/add-attachment',
        title: 'Add Attachment',
        acceptText: 'Add',
        changeset: this.get('changeset')
      };

      this.get('dialog').show('dialogs/cm-dialog', null, this, options);
    },

    loadPrintTypes: function loadPrintTypes() {
      var _this = this;

      this.set('showDialogSpinner', true);
      this.get('store').query('rebate-negotiations/bid-print-type', {}).then(function (types) {
        _this.set('bidPrintTypes', types);
        _this.set('showDialogSpinner', false);
      })['catch'](function () {
        return _this.set('showDialogSpinner', false);
      });
    },

    initModel: function initModel() {
      this.set('model.fileKey', null);
      this.set('model.fileName', null);
      this.set('model.attachmentSource', this.get('attachmentSources.firstObject.key'));
      this.set('model.source', { id: _quantuvisCmSpaConstantsFileSource['default'].UNKNOWN_SOURCE });
      this.set('model.bidPrintType', null);
    },

    initChangeset: function initChangeset() {
      var changeset = new _emberChangeset['default'](this.get('model'), (0, _emberChangesetValidations['default'])(_quantuvisCmSpaValidationsAddAttachment['default']), _quantuvisCmSpaValidationsAddAttachment['default'], {
        skipValidate: true
      });

      this.set('changeset', changeset);
    },

    sendAttachment: function sendAttachment(presenter) {
      var _this2 = this;

      var requestObj = this.createRequestObj();

      this.set('showDialogSpinner', true);
      var attachment = this.get('store').createRecord('contracts/attachment', requestObj);

      return attachment.save().then(function () {
        _this2.get('attachments').pushObject(attachment);
        _this2.set('showDialogSpinner', false);
        presenter.accept();
      })['catch'](function () {
        _this2.set('showDialogSpinner', false);
        var options = {
          errorMessage: _quantuvisCmSpaConstantsErrorMessages.DEFAULT_ERROR_MESSAGE,
          acceptHandler: 'exitFromDialog'
        };

        _this2.get('dialog').show('dialogs/cm-error', null, _this2, options);
      });
    },

    createRequestObj: function createRequestObj() {
      var changeset = this.get('changeset');
      var key = changeset.get('fileKey');
      var name = changeset.get('fileName');
      var source = changeset.get('source');
      var bidPrintType = { id: changeset.get('bidPrintType.id') };

      return {
        contractId: this.get('contractDetails').get('contractId'),
        key: key,
        name: name,
        source: source,
        bidPrintType: bidPrintType
      };
    },

    actions: {
      accept: function accept(presenter) {
        var _this3 = this;

        var changeset = this.get('changeset');

        changeset.validate().then(function () {
          if (changeset.get('isInvalid')) {
            return Promise.reject();
          } else {
            _this3.sendAttachment(presenter);
          }
        });
      },
      exitFromDialog: function exitFromDialog(presenter) {
        presenter.accept();
      }
    }
  });
});