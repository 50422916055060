define('quantuvis-cm-spa/routes/contract-permissions', ['exports', 'ember', 'quantuvis-cm-spa/mixins/authenticated-route-mixin', 'quantuvis-cm-spa/mixins/query-params-url-cleaner'], function (exports, _ember, _quantuvisCmSpaMixinsAuthenticatedRouteMixin, _quantuvisCmSpaMixinsQueryParamsUrlCleaner) {
  exports['default'] = _ember['default'].Route.extend(_quantuvisCmSpaMixinsAuthenticatedRouteMixin['default'], _quantuvisCmSpaMixinsQueryParamsUrlCleaner['default'], {
    permissionService: _ember['default'].inject.service(),
    contractDetails: _ember['default'].inject.service(),
    previousRoute: null,
    contractId: null,

    beforeModel: function beforeModel(transition) {
      var parentPromises = this._super.apply(this, arguments);

      this.set('previousRoute', transition.queryParams.previousRoute);

      return _ember['default'].RSVP.hash(parentPromises);
    },

    model: function model(params) {
      var companyId = this.get('permissionService.companyId');
      var contract = this.get('contractDetails').getContract();

      this.set('contractId', params.id);

      if (!companyId || !contract) {
        this.transitionTo('contract-details', params.id);
      }

      return _ember['default'].RSVP.hash({
        users: this.get('store').findAll('user-management/users/extended'),
        permissionGrants: this.get('store').queryRecord('user-management/applications/permissions/permission-grant', { companyIds: companyId })
      });
    },

    actions: {
      handleBackClick: function handleBackClick() {
        var contractId = this.get('contractId');

        if (this.previousRoute) {
          this.transitionTo(this.previousRoute, contractId);
        } else {
          this.transitionTo('contract-details', contractId);
        }
      }
    }
  });
});