define('quantuvis-cm-spa/services/enrollments-service', ['exports', 'ember', 'quantuvis-cm-spa/constants/enrollments'], function (exports, _ember, _quantuvisCmSpaConstantsEnrollments) {
  exports['default'] = _ember['default'].Service.extend({
    store: _ember['default'].inject.service(),
    enrollmentsEntity: null,

    loadEnrollments: function loadEnrollments() {
      var _this = this;

      return this.get('store').queryRecord('user-management/applications/company-enrollment', {}).then(function (data) {
        if (!Array.isArray(data)) {
          _this.set('enrollmentsEntity', data);
        }
      })['catch'](function (error) {
        return _ember['default'].Logger.error('Error occurred on retrieve URL', error);
      });
    },

    hasIaEnrollments: function hasIaEnrollments() {
      var enrollmentsEntity = this.get('enrollmentsEntity');
      var hasIaEnrollments = false;

      if (enrollmentsEntity) {
        var enrollments = enrollmentsEntity.get('enrollments');

        hasIaEnrollments = enrollments.some(function (_ref) {
          var id = _ref.id;
          return id === _quantuvisCmSpaConstantsEnrollments['default'].ACCESS_TO_INVOICING || id === _quantuvisCmSpaConstantsEnrollments['default'].ACCESS_TO_INVOICE_PACKAGING;
        });
      }

      return hasIaEnrollments;
    },

    hasSmEnrollments: function hasSmEnrollments() {
      var enrollmentsEntity = this.get('enrollmentsEntity');

      return Boolean(enrollmentsEntity);
    }
  });
});