define('quantuvis-cm-spa/controllers/business-admin/workflow-templates', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    noRecordsMessage: 'List of Workflow templates is empty. Press the “+”  to add template',
    selectedTemplate: null,
    dialog: _ember['default'].inject.service(),

    tableColumns: [{
      columnName: 'Title',
      index: 'name',
      component: 'business-admin/cm-table/columns/linked-column',
      className: 'workflow-title'
    }, {
      columnName: 'Description',
      index: 'description'
    }],

    actions: {
      recordSelected: function recordSelected(data) {
        this.set('selectedTemplate', data.record);
      },
      editTemplate: function editTemplate(id) {
        this.transitionToRoute('edit-workflow-template', id);
      },
      deleteTemplate: function deleteTemplate(template) {
        var _this = this;

        var options = { confirmMessage: this.confirmationPopupText(template) };

        this.get('dialog').show('dialogs/cm-dialog', 'dialog-templates/cm-confirmation', this, options).then(function () {
          template.destroyRecord().then(function () {
            _this.set('selectedTemplate', null);
            _this.send('refreshModel');
          });
        })['catch'](function () {});
      }
    },

    confirmationPopupText: function confirmationPopupText(template) {
      return 'Are you sure you would like to delete "' + template.get('name') + '" template.\nThis action is permanent and irreversible.';
    }
  });
});