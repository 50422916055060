define('quantuvis-cm-spa/constants/file-types', ['exports'], function (exports) {
  var TEMPLATE_ALLOWED_TYPES = {
    FILE_TYPES: ['.doc', '.docx', '.odt', '.rtf'],
    FILE_TYPE_MATCHER: /\.(doc|docx|odt|rtf)$/i
  };
  var FILE_ALLOWED_TYPES = {
    FILE_TYPES: ['.pdf', '.doc', '.docx', '.odt', '.txt', '.rtf'],
    FILE_TYPE_MATCHER: /\.(pdf|doc|docx|odt|txt|rtf)$/i
  };

  var BYTES_IN_KILOBYTE = 1024;
  var KILOBYTES_IN_MEGABYTE = 1024;
  var MAX_FILE_SIZE_IN_MEGABYTES = 25;
  var MAX_FILE_SIZE = MAX_FILE_SIZE_IN_MEGABYTES * KILOBYTES_IN_MEGABYTE * BYTES_IN_KILOBYTE;

  exports.TEMPLATE_ALLOWED_TYPES = TEMPLATE_ALLOWED_TYPES;
  exports.FILE_ALLOWED_TYPES = FILE_ALLOWED_TYPES;
  exports.MAX_FILE_SIZE = MAX_FILE_SIZE;
});