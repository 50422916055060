define('quantuvis-cm-spa/models/workflows/step', ['exports', 'ember', 'ember-data', 'quantuvis-cm-spa/constants/workflow-step-state', 'quantuvis-cm-spa/constants/workflow-step-departments-state'], function (exports, _ember, _emberData, _quantuvisCmSpaConstantsWorkflowStepState, _quantuvisCmSpaConstantsWorkflowStepDepartmentsState) {
  exports['default'] = _emberData['default'].Model.extend({
    positionSortingAsc: ['position:asc'],

    name: _emberData['default'].attr('string'),
    position: _emberData['default'].attr('number'),
    isRequired: _emberData['default'].attr('boolean'),
    dueDate: _emberData['default'].attr('unix-date'),
    notes: _emberData['default'].attr('string'),
    executorId: _emberData['default'].attr('number'),
    completedDate: _emberData['default'].attr('unix-timestamp'),
    isHidden: _emberData['default'].attr('boolean'),
    type: _emberData['default'].attr(),
    state: _emberData['default'].attr(),
    attachments: _emberData['default'].hasMany('workflows/attachment', { inverse: null, async: false }),
    events: _emberData['default'].hasMany('workflows/step-event', { inverse: null, async: false }),
    substeps: _emberData['default'].hasMany('workflows/substep', { inverse: null, async: false }),
    responsiblePersonsAccounts: _emberData['default'].hasMany('user-management/users/extended', { inverse: null, async: false }),
    substepsSortedByPosition: _ember['default'].computed.sort('substeps', 'positionSortingAsc'),

    isInProgress: _ember['default'].computed('state', function () {
      return parseInt(this.get('state.id')) === _quantuvisCmSpaConstantsWorkflowStepState['default'].IN_PROGRESS;
    }),

    isPending: _ember['default'].computed('state', function () {
      return parseInt(this.get('state.id')) === _quantuvisCmSpaConstantsWorkflowStepState['default'].PENDING;
    }),

    isAvailableToSort: _ember['default'].computed('state', 'hasAttachments', function () {
      var state = parseInt(this.get('state.id'));
      var doesntHasAttachments = !this.get('hasAttachments');
      var doesntHasEvents = !_ember['default'].isPresent(this.get('events'));

      return (state === _quantuvisCmSpaConstantsWorkflowStepState['default'].PENDING || state === _quantuvisCmSpaConstantsWorkflowStepState['default'].IN_PROGRESS && doesntHasAttachments && doesntHasEvents) && !this.get('isRequired') && !this.get('hasCompletedSubsteps');
    }),

    hasCompletedSubsteps: _ember['default'].computed('substeps', function () {
      return this.get('substeps').any(function (substep) {
        return substep.get('state.id') === _quantuvisCmSpaConstantsWorkflowStepDepartmentsState.WORKFLOW_STEP_DEPARTMENT_STATE.COMPLETED;
      });
    }),

    isInactive: _ember['default'].computed('state', function () {
      return [_quantuvisCmSpaConstantsWorkflowStepState['default'].VOIDED, _quantuvisCmSpaConstantsWorkflowStepState['default'].COMPLETED, _quantuvisCmSpaConstantsWorkflowStepState['default'].REJECTED].includes(parseInt(this.get('state.id')));
    }),

    hasAttachments: _ember['default'].computed('attachments', 'substeps', function () {
      var stepHasAttachments = _ember['default'].isPresent(this.get('attachments'));

      if (stepHasAttachments === false) {
        stepHasAttachments = this.substepsHasAttachments(this.get('substeps'));
      }

      return stepHasAttachments;
    }),

    substepsHasAttachments: function substepsHasAttachments(substeps) {
      var substep = substeps.find(function (substep) {
        return _ember['default'].isPresent(substep.get('attachments'));
      });

      return substep !== undefined;
    }
  });
});