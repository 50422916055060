define('quantuvis-cm-spa/services/date', ['exports', 'ember', 'moment', 'quantuvis-cm-spa/constants/date-format'], function (exports, _ember, _moment, _quantuvisCmSpaConstantsDateFormat) {
  exports['default'] = _ember['default'].Service.extend({
    profile: _ember['default'].inject.service(),
    DEFAULT_TIMEZONE: 'UTC',

    /**
     * Format date to default format MM_DD_YYYY
     * @param {any} date
     */
    formatDate: function formatDate(date) {
      return (0, _moment['default'])(date).isValid() ? (0, _moment['default'])(date).format(_quantuvisCmSpaConstantsDateFormat['default'].MM_DD_YYYY) : '';
    },

    formatDateWithUtcTimezone: function formatDateWithUtcTimezone(date) {
      return (0, _moment['default'])(date).isValid() ? _moment['default'].tz(date, 'UTC').format(_quantuvisCmSpaConstantsDateFormat['default'].MM_DD_YYYY) : '';
    },

    /**
     * Check date for overdue state
     * @param {*} date
     * @returns {boolean} - indicate that date is overdue or not
     */
    isOverdue: function isOverdue(date) {
      if (!(0, _moment['default'])(date).isValid()) {
        return false;
      }

      return _moment['default'].tz(date, this.getTimezone()).isBefore(_moment['default'].tz(this.getTimezone()), 'day');
    },

    getTimezone: function getTimezone() {
      var timezone = this.get('profile').getUserTimezone();

      return timezone ? timezone : this.DEFAULT_TIMEZONE;
    }
  });
});