define('quantuvis-cm-spa/components/cm-profile-settings/add-company-dialog-body', ['exports', 'ember', 'quantuvis-cm-spa/models/search/attribute', 'quantuvis-cm-spa/models/search/option'], function (exports, _ember, _quantuvisCmSpaModelsSearchAttribute, _quantuvisCmSpaModelsSearchOption) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['add-company-dialog-body'],
    filterTerm: '',
    isLoading: false,

    store: _ember['default'].inject.service(),
    profileSettings: _ember['default'].inject.service(),

    searchTermObserver: _ember['default'].observer('filterTerm', function () {
      this.send('filterOptions');
    }),

    init: function init() {
      this._super.apply(this, arguments);
      this.initCompanyList();
    },

    /**
     * Init company list:
     * Get already saved accounts from changest (from contextObject)
     * Fetch all companies from server and remove from this list alredy saved
     * Create companies options instance and add options
     */
    initCompanyList: function initCompanyList() {
      var _this = this;

      this.set('isLoading', true);
      var companiesOptions = new _quantuvisCmSpaModelsSearchAttribute['default']('company', 'Company', 0, false, false);

      return this.get('store').query('user-management/company', {}).then(function (companies) {
        var filteredCompanies = _this.removeAlreadySavedCompanies(companies);

        filteredCompanies.forEach(function (company) {
          companiesOptions.addOption(new _quantuvisCmSpaModelsSearchOption.AttributeOption(company.id, company.get('name'), false, true));
        });
        _this.get('contextObject').set('companiesOptions', companiesOptions);
        _this.set('isLoading', false);
      })['catch'](function () {
        _this.set('isLoading', false);
      });
    },

    /**
     * Remove already saved (in changeset) companies from incoming array
     */
    removeAlreadySavedCompanies: function removeAlreadySavedCompanies(companies) {
      var alreadySelectedCompanies = this.get('contextObject.changeset.myAccounts');

      return companies.reject(function (company) {
        return alreadySelectedCompanies.isAny('id', company.id);
      });
    },

    actions: {
      /**
       * Filter options by filter term
       */
      filterOptions: function filterOptions() {
        this.get('contextObject').get('companiesOptions').filterOptions(this.get('filterTerm'));
      },
      /**
       * Select all filtered options
       */
      selectAll: function selectAll() {
        this.get('contextObject').get('companiesOptions').selectFilteredOptions();
      },
      /**
       * Clear all options
       */
      clearAll: function clearAll() {
        this.get('contextObject').get('companiesOptions').clearAllSelection();
      },
      /**
       * Handle options selection change
       *
       * @param option
       */
      onOptionStateChange: function onOptionStateChange(option) {
        var isSelected = !option.isSelected;

        /**
         * Update option selection
         */
        _ember['default'].set(option, 'isSelected', isSelected);
      }
    }
  });
});