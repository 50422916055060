define('quantuvis-cm-spa/components/cm-timeline/menus/event-menus/cm-sign-off-request-received-menu', ['exports', 'ember', 'ember-changeset', 'ember-changeset-validations', 'quantuvis-cm-spa/validations/decline-sign-off-request', 'quantuvis-cm-spa/constants/event-property-name', 'quantuvis-cm-spa/constants/contract-status', 'quantuvis-cm-spa/constants/sign-off-status'], function (exports, _ember, _emberChangeset, _emberChangesetValidations, _quantuvisCmSpaValidationsDeclineSignOffRequest, _quantuvisCmSpaConstantsEventPropertyName, _quantuvisCmSpaConstantsContractStatus, _quantuvisCmSpaConstantsSignOffStatus) {

  var ERROR_MESSAGES = {
    SIGN_OFF_IN_PROGRESS: 'Currently you have sign off in progress. Please finish it to proceed.',
    EXECUTED_INTERNALLY: 'There is partially executed version of this document. Please decline it to sign a new version.'
  };

  exports['default'] = _ember['default'].Component.extend({
    store: _ember['default'].inject.service(),
    router: _ember['default'].inject.service('-routing'),
    dialog: _ember['default'].inject.service(),
    timeline: _ember['default'].inject.service(),
    contractDetails: _ember['default'].inject.service(),
    dialogManager: _ember['default'].inject.service(),

    declineSignOffChangeset: {},

    signOffId: _ember['default'].computed('properties', function () {
      return this.get('timeline').getEventPropertyValueByName(this.get('properties'), _quantuvisCmSpaConstantsEventPropertyName['default'].SIGN_OFF_ID);
    }),

    contractStatusId: _ember['default'].computed('contractDetails.contractId', function () {
      return this.get('contractDetails').getContract().get('status.id');
    }),

    isSignOffActionsDisabled: _ember['default'].computed('properties', function () {
      var _getProperties = this.getProperties('properties', 'timeline');

      var properties = _getProperties.properties;
      var timeline = _getProperties.timeline;

      var signOffStatus = timeline.getEventPropertyValueByName(properties, _quantuvisCmSpaConstantsEventPropertyName['default'].SIGN_OFF_STATUS_ID);

      return Number(signOffStatus) !== _quantuvisCmSpaConstantsSignOffStatus['default'].PENDING_APPROVAL_ID || this.get('contractStatusId') === _quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.EXECUTED;
    }),

    showSignOffActionError: function showSignOffActionError(errorMessage) {
      return this.get('dialog').show('dialogs/cm-error', null, this, { errorMessage: errorMessage });
    },

    declineSignOffRequest: function declineSignOffRequest() {
      var _this = this;

      this.initDeclineSignOffRequestChangeset();

      var reasonOptions = {
        className: 'decline-sign-off-request-dialog',
        componentName: 'cm-timeline/menus/dialog/decline-sign-off-request-dialog',
        title: 'Decline Sign Off',
        acceptText: 'Decline Sign Off',
        declineText: 'Close',
        acceptHandler: 'sendDeclineSignOffRequest'
      };

      this.get('dialogManager').showDialogConfirmation('Do you want to decline this request?').then(function () {
        _this.get('dialog').show('dialogs/cm-dialog', null, _this, reasonOptions);
      });
    },

    initDeclineSignOffRequestChangeset: function initDeclineSignOffRequestChangeset() {
      var model = _ember['default'].Object.create({
        reason: ''
      });
      var changeset = new _emberChangeset['default'](model, (0, _emberChangesetValidations['default'])(_quantuvisCmSpaValidationsDeclineSignOffRequest['default']), _quantuvisCmSpaValidationsDeclineSignOffRequest['default'], {
        skipValidate: true
      });

      this.set('declineSignOffChangeset', changeset);
    },

    actions: {
      acceptSignOff: function acceptSignOff() {
        switch (this.get('contractStatusId')) {
          case _quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.IN_SIGNATURE:
            {
              return this.showSignOffActionError(ERROR_MESSAGES.SIGN_OFF_IN_PROGRESS);
            }

          case _quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.PARTIALLY_EXECUTED_INTERNALLY:
            {
              return this.showSignOffActionError(ERROR_MESSAGES.EXECUTED_INTERNALLY);
            }

          default:
            {
              return this.get('router').transitionTo('accept-sign-off', [this.get('signOffId')]);
            }
        }
      },
      declineSignOff: function declineSignOff() {
        this.declineSignOffRequest();
      },

      sendDeclineSignOffRequest: function sendDeclineSignOffRequest(presenter) {
        var _this2 = this;

        this.set('showDialogSpinner', true);
        var changeset = this.get('declineSignOffChangeset');
        var data = {
          reason: changeset.get('reason')
        };

        changeset.validate().then(function () {
          if (changeset.get('isInvalid')) {
            return Promise.reject();
          }

          return _this2.get('store').adapterFor('application').post('signoff/' + _this2.get('signOffId') + '/decline', '', { data: data });
        }).then(function () {
          _this2.set('showDialogSpinner', false);
          _this2.get('contractDetails').loadTimelineItems();
          _this2.get('contractDetails').set('pendingSignOff', null);
          presenter.accept();
        })['catch'](function () {
          return _this2.set('showDialogSpinner', false);
        });
      }
    }
  });
});