define('quantuvis-cm-spa/core-ui/components/cm-dropdown-menu/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['cm-dropdown-menu'],
    classNameBindings: ['isMenuOpen:menu-open'],

    // default vertial offset
    contentVerticalOffset: 22,

    isMenuOpen: false,

    contentVerticalOffsetComputed: _ember['default'].computed('contentVerticalOffset', function () {
      var contentVerticalOffset = this.get('contentVerticalOffset');

      if (_ember['default'].$.isNumeric(contentVerticalOffset)) {
        return contentVerticalOffset;
      } else {
        return 0;
      }
    }),

    actions: {
      registerAPI: function registerAPI(test) {
        this.set('dropdownPublicAPI', test);
      },
      handleItemSelect: function handleItemSelect() {
        this.get('dropdownPublicAPI').actions.close();
      },
      onOpen: function onOpen() {
        this.set('isMenuOpen', true);
        this.sendAction('onOpen');
      },
      onClose: function onClose() {
        this.set('isMenuOpen', false);
        this.sendAction('onClose');
      }
    }
  });
});