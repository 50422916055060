define('quantuvis-cm-spa/components/sign-off/quote-exceeded', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['quote-exceeded'],

    dialog: _ember['default'].inject.service(),

    actions: {
      contactQuantuvis: function contactQuantuvis() {
        var options = {
          className: 'cm-contact-quantuvis',
          componentName: 'cm-profile-menu/cm-contact-quantuvis',
          title: 'Contact Quantuvis',
          acceptText: 'Send',
          declineText: 'Close',
          acceptHandler: 'contactQuantuvisAcceptHandler',
          declineHandler: 'contactQuantuvisDeclineHandler'
        };

        this.get('dialog').show('dialogs/cm-dialog', null, this, options);
      },

      contactQuantuvisAcceptHandler: function contactQuantuvisAcceptHandler(presenter) {
        this.currentFormPresenter = presenter;

        _ember['default'].$('#contact-quantuvis-form').submit();
        this.get('parentView').accept();
      },

      contactQuantuvisDeclineHandler: function contactQuantuvisDeclineHandler(presenter) {
        presenter.accept();
        this.get('parentView').accept();
      },

      accepted: function accepted() {
        var presenter = this.get('currentFormPresenter');

        presenter.accept();
      }
    }
  });
});