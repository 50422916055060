define('quantuvis-cm-spa/controllers/rfp-contract-creation', ['exports', 'ember', 'quantuvis-cm-spa/constants/contract-type'], function (exports, _ember, _quantuvisCmSpaConstantsContractType) {
  exports['default'] = _ember['default'].Controller.extend({

    actions: {
      createContract: function createContract() {
        this.transitionToRoute('create-contract', _quantuvisCmSpaConstantsContractType['default'].CONTRACT, {
          queryParams: {
            companyId: 304,
            contractName: 'MED D Pharma ABC',
            evergreen: false,
            startDate: '01/01/2018',
            endDate: '12/31/2018',
            lineOfBusinessId: 1,
            leadIds: 714,
            bidId: '5d4977be-cb48-4e70-ac9d-bfac1c2b9797'
          }
        });
      }
    }
  });
});