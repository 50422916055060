define('quantuvis-cm-spa/components/cm-contract-details/cm-history/cm-history', ['exports', 'ember', 'quantuvis-cm-spa/constants/permissions'], function (exports, _ember, _quantuvisCmSpaConstantsPermissions) {
  exports['default'] = _ember['default'].Component.extend({
    PERMISSIONS: _quantuvisCmSpaConstantsPermissions.PERMISSIONS,
    classNames: ['cm-history'],
    contractDetails: _ember['default'].inject.service(),
    history: _ember['default'].computed.alias('contractDetails.history'),
    isLoading: _ember['default'].computed.alias('contractDetails.isHistoryLoading'),

    init: function init() {
      this._super.apply(this, arguments);
      this.loadHistory();
    },

    contractIdObserver: _ember['default'].observer('contractDetails.contractId', function () {
      this.loadHistory();
    }),

    loadHistory: function loadHistory() {
      this.get('contractDetails').loadHistory();
    }
  });
});