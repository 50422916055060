define('quantuvis-cm-spa/validations/upload-version', ['exports', 'ember-changeset-validations/validators', 'quantuvis-cm-spa/constants/version-source'], function (exports, _emberChangesetValidationsValidators, _quantuvisCmSpaConstantsVersionSource) {
  exports['default'] = {
    versionSource: [(0, _emberChangesetValidationsValidators.validatePresence)(true)],
    fileKey: [(0, _emberChangesetValidationsValidators.validatePresence)({ presence: true, message: 'Please, upload new file' })],
    fulfilling: [validateFulfilling()],
    otherPartyFulfilling: [validateOtherPartyFulfilling()]
  };

  function validateFulfilling() {
    return function (key, newValue, oldValue, changes, content) {
      if (!content.versionSource || changes.versionSource === _quantuvisCmSpaConstantsVersionSource['default'].OTHER) {
        return true;
      } else {
        if (changes.fulfilling || content.fulfilling) {
          return true;
        } else {
          return 'Required';
        }
      }
    };
  }

  function validateOtherPartyFulfilling() {
    return function (key, newValue, oldValue, changes, content) {
      if (!changes.versionSource || changes.versionSource === _quantuvisCmSpaConstantsVersionSource['default'].INTERNAL) {
        return true;
      } else {
        if (changes.otherPartyFulfilling || content.otherPartyFulfilling) {
          return true;
        } else {
          return 'Required';
        }
      }
    };
  }
});