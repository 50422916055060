define('quantuvis-cm-spa/components/cm-timeline/cm-timeline-item/cm-timeline-digital-sign-off', ['exports', 'quantuvis-cm-spa/constants/workflow-step-type', 'quantuvis-cm-spa/components/cm-timeline/cm-timeline-item/cm-timeline-multi-step', 'quantuvis-cm-spa/constants/workflow-step-element-type'], function (exports, _quantuvisCmSpaConstantsWorkflowStepType, _quantuvisCmSpaComponentsCmTimelineCmTimelineItemCmTimelineMultiStep, _quantuvisCmSpaConstantsWorkflowStepElementType) {
  exports['default'] = _quantuvisCmSpaComponentsCmTimelineCmTimelineItemCmTimelineMultiStep['default'].extend({
    classNames: ['cm-timeline-digital-sign-off'],
    stepName: _quantuvisCmSpaConstantsWorkflowStepType.DIGITAL_SIGN_OFF.name,

    getWorkflowStepElementTemplate: function getWorkflowStepElementTemplate(workflowStepElement) {
      switch (workflowStepElement.type.id) {
        case _quantuvisCmSpaConstantsWorkflowStepElementType['default'].STEP_EVENT:
          return 'cm-timeline/cm-timeline-item/cm-timeline-event';
        case _quantuvisCmSpaConstantsWorkflowStepElementType['default'].SUBSTEP:
          return 'cm-timeline/cm-timeline-item/cm-timeline-digital-sign-off-substep';
        default:
          return null;
      }
    }
  });
});