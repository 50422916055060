define('quantuvis-cm-spa/services/loading-spinner', ['exports', 'ember'], function (exports, _ember) {

  // TODO: add tests for service!
  exports['default'] = _ember['default'].Service.extend({
    options: {
      lines: 7,
      length: 0,
      width: 16,
      radius: 28,
      scale: 0.75,
      corners: 1,
      opacity: 0,
      rotate: 0,
      speed: 1.1,
      trail: 70,
      color: '#4e297d'
    },

    spinners: new Map(),
    /**
     * Global Application Spiner
     */
    applicationSpinner: null,

    registerComponent: function registerComponent(spinner) {
      this.get('spinners').set(spinner.id, spinner);
    },
    unregisterComponent: function unregisterComponent(spinner) {
      this.get('spinners')['delete'](spinner.id);
    },
    show: function show(id) {
      var spinner = this.get('spinners').get(id);

      if (spinner) {
        spinner.show();
      }
    },
    hide: function hide(id) {
      var spinner = this.get('spinners').get(id);

      if (spinner) {
        spinner.hide();
      }
    },
    setVisible: function setVisible(id, value) {
      var spinner = this.get('spinners').get(id);

      if (spinner) {
        spinner.setVisible(value);
      }
    },

    getJsSpinner: function getJsSpinner() {
      if (!this.get('applicationSpinner')) {
        this.set('applicationSpinner', new Spinner(this.get('options')));
      }

      return this.get('applicationSpinner');
    },

    stopGlobal: function stopGlobal() {
      var applicationSpinner = this.get('applicationSpinner');

      if (applicationSpinner) {
        applicationSpinner.stop();
      }
    }
  });
});
/* global Spinner */