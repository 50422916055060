define('quantuvis-cm-spa/components/cm-contract-details/cm-print-document', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    printDocument: _ember['default'].inject.service(),
    classNames: ['cm-print-document'],

    actions: {
      toggleCheckbox: function toggleCheckbox(property) {
        this.get('printDocument').toggleProperty(property);
      }
    }
  });
});