define('quantuvis-cm-spa/components/cm-send-document/recipient-form', ['exports', 'ember', 'ember-changeset', 'ember-changeset-validations', 'quantuvis-cm-spa/validations/cm-send-document'], function (exports, _ember, _emberChangeset, _emberChangesetValidations, _quantuvisCmSpaValidationsCmSendDocument) {
  exports['default'] = _ember['default'].Component.extend({
    session: _ember['default'].inject.service(),
    sendDocument: _ember['default'].inject.service(),

    options: [],
    changesetToEdit: null,

    isAddAvailable: _ember['default'].computed.not('addNewDisabled'),
    selectedRecipients: _ember['default'].computed.alias('sendDocument.changeset.recipients'),
    optionsWithoutSelected: _ember['default'].computed('options', 'selectedRecipients.@each.id', function () {
      var selectedItemsIds = this.get('selectedRecipients').filterBy('id').mapBy('id');
      var currentUserId = this.get('session.session.authenticated.user_id');

      return this.get('options').filter(function (option) {
        return !selectedItemsIds.includes(option.get('id')) && option.get('id') !== currentUserId;
      });
    }),

    editObserver: _ember['default'].observer('changesetToEdit', 'isInternally', function () {
      this.resetSearchText();

      var _getProperties = this.getProperties('changeset', 'changesetToEdit');

      var changeset = _getProperties.changeset;
      var changesetToEdit = _getProperties.changesetToEdit;

      if (changesetToEdit) {
        var _changesetToEdit$getProperties = changesetToEdit.getProperties('id', 'name', 'email');

        var id = _changesetToEdit$getProperties.id;
        var _name = _changesetToEdit$getProperties.name;
        var email = _changesetToEdit$getProperties.email;

        changeset.setProperties({ id: id, name: _name, email: email });
      }
    }),

    typeObserver: _ember['default'].observer('isInternally', function () {
      this.clearPreviousRecipient();
    }),

    init: function init() {
      this._super.apply(this, arguments);

      this.initChangeset();
    },

    initChangeset: function initChangeset() {
      var changeset = this.createChangeset();

      this.set('changeset', changeset);
    },

    createChangeset: function createChangeset() {
      return new _emberChangeset['default'](_ember['default'].Object.create({ id: null, name: '', email: '' }), (0, _emberChangesetValidations['default'])((0, _quantuvisCmSpaValidationsCmSendDocument['default'])()), (0, _quantuvisCmSpaValidationsCmSendDocument['default'])(), {
        skipValidate: true
      });
    },

    clearPreviousRecipient: function clearPreviousRecipient() {
      this.get('changeset').rollback();
    },

    resetChangesetToEdit: function resetChangesetToEdit() {
      this.sendAction('onFinishEdit');
    },

    resetSearchText: function resetSearchText() {
      var select = this.get('select');

      if (select) {
        this.get('select').actions.search('');
      }
    },

    scrollToLastListItem: function scrollToLastListItem() {
      var $list = _ember['default'].$('.recipients-list');

      $list.animate({ scrollTop: $list.prop('scrollHeight') });
    },

    validateEmailUnique: function validateEmailUnique() {
      var _getProperties2 = this.getProperties('changeset', 'selectedRecipients', 'changesetToEdit');

      var selectedRecipients = _getProperties2.selectedRecipients;
      var changeset = _getProperties2.changeset;
      var changesetToEdit = _getProperties2.changesetToEdit;

      var emailToValidate = this.get('changeset.email');
      var selectedRecipientsEmails = selectedRecipients.mapBy('email');
      var error = 'Email must be unique';
      var optionsToValidate = selectedRecipientsEmails;

      if (changesetToEdit) {
        optionsToValidate = selectedRecipientsEmails.filter(function (email) {
          return email !== changesetToEdit.get('email');
        });
      }

      if (optionsToValidate.includes(emailToValidate)) {
        changeset.pushErrors('email', error);

        return Promise.reject();
      }

      return Promise.resolve();
    },

    actions: {
      addRecipient: function addRecipient() {
        var _this = this;

        var _getProperties3 = this.getProperties('changeset', 'sendDocument');

        var changeset = _getProperties3.changeset;
        var sendDocument = _getProperties3.sendDocument;

        changeset.validate().then(function () {
          if (changeset.get('isInvalid')) {
            return Promise.reject();
          }

          _this.validateEmailUnique().then(function () {
            var newRecipientChangeset = _this.createChangeset();

            var _changeset$getProperties = changeset.getProperties('id', 'name', 'email');

            var id = _changeset$getProperties.id;
            var name = _changeset$getProperties.name;
            var email = _changeset$getProperties.email;

            newRecipientChangeset.setProperties({ id: id, name: name, email: email });
            sendDocument.addRecipient(newRecipientChangeset);
            sendDocument.get('changeset').validate('recipients');

            _this.resetSearchText();
            _this.clearPreviousRecipient();
            _this.scrollToLastListItem();
          });
        });
      },

      changeRecipient: function changeRecipient() {
        var _this2 = this;

        var _getProperties4 = this.getProperties('changeset', 'changesetToEditIndex', 'sendDocument');

        var changeset = _getProperties4.changeset;
        var changesetToEditIndex = _getProperties4.changesetToEditIndex;
        var sendDocument = _getProperties4.sendDocument;

        changeset.validate().then(function () {
          if (changeset.get('isInvalid')) {
            return Promise.reject();
          }

          _this2.validateEmailUnique().then(function () {
            sendDocument.changeRecipient({ index: changesetToEditIndex, recipient: changeset });

            _this2.clearPreviousRecipient();
            _this2.resetChangesetToEdit();
            _this2.resetSearchText();
          });
        });
      },

      setSelect: function setSelect(select) {
        if (this.get('select')) {
          return;
        }

        this.set('select', select);
      },

      setRecipient: function setRecipient(value) {
        var changeset = this.get('changeset');

        var _value$getProperties = value.getProperties('id', 'name', 'email');

        var id = _value$getProperties.id;
        var name = _value$getProperties.name;
        var email = _value$getProperties.email;

        changeset.setProperties({ id: id, name: name, email: email });
      },

      setCustomRecipient: function setCustomRecipient(value) {
        var changeset = this.get('changeset');

        if (changeset.get('id')) {
          this.clearPreviousRecipient();
        }

        changeset.set('name', value);
      }
    }
  });
});