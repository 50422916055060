define('quantuvis-cm-spa/models/workflow', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {
  exports['default'] = _emberData['default'].Model.extend({
    userId: _emberData['default'].attr('number'),

    contractId: _emberData['default'].attr('number'),
    steps: _emberData['default'].hasMany('workflows/step', { inverse: null, async: false }),

    positionSortingAsc: ['position:asc'],
    stepsSortedByPosition: _ember['default'].computed.sort('steps', 'positionSortingAsc')
  });
});