define('quantuvis-cm-spa/components/cm-contract-details/cm-details-sidebar-menu', ['exports', 'ember', 'quantuvis-cm-spa/constants/contract-type', 'quantuvis-cm-spa/constants/details-menu-items'], function (exports, _ember, _quantuvisCmSpaConstantsContractType, _quantuvisCmSpaConstantsDetailsMenuItems) {
  exports['default'] = _ember['default'].Component.extend({
    window: _ember['default'].inject.service(),
    contractDetailsSidebarNavigator: _ember['default'].inject.service(),

    classNames: ['cm-details-sidebar-menu', 'cm-contract-details-navbar', 'cm-shadow-box-navbar'],
    amendmentMenuItemId: _quantuvisCmSpaConstantsDetailsMenuItems['default'].AMENDMENTS,

    isAmendment: false,

    modelObserver: _ember['default'].observer('model', function () {
      this.recalculateIsAmendment();
    }),

    init: function init() {
      this._super.apply(this, arguments);
      this.recalculateIsAmendment();
    },

    didInsertElement: function didInsertElement() {
      this.get('contractDetailsSidebarNavigator').setActiveTab();
      this.addHashObserver();
    },

    willDestroyElement: function willDestroyElement() {
      this.removeHashObserver();
    },

    addHashObserver: function addHashObserver() {
      var _this = this;

      _ember['default'].$(this.get('window').getWindow()).on('hashchange.sidebar', function () {
        return _this.get('contractDetailsSidebarNavigator').setActiveTab();
      });
    },

    removeHashObserver: function removeHashObserver() {
      _ember['default'].$(this.get('window').getWindow()).off('hashchange.sidebar');
    },

    recalculateIsAmendment: function recalculateIsAmendment() {
      this.set('isAmendment', this.get('model.type.id') === _quantuvisCmSpaConstantsContractType['default'].AMENDMENT);
    },

    actions: {
      itemSelected: function itemSelected(index, urlHash) {
        if (this.get('isAmendment') && this.get('contractDetailsSidebarNavigator.menuItems').objectAt(index).id === _quantuvisCmSpaConstantsDetailsMenuItems['default'].AMENDMENTS) {
          return;
        }

        this.get('contractDetailsSidebarNavigator').setUrlHash(urlHash);
      }
    }
  });
});