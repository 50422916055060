define('quantuvis-cm-spa/serializers/application', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  exports['default'] = _emberData['default'].RESTSerializer.extend({
    /**
     * Normalize Collection response
     *
     * @param store
     * @param primaryModelClass
     * @param payload
     * @param id
     * @param requestType
     * @returns {*|Object}
     */
    normalizeArrayResponse: function normalizeArrayResponse(store, primaryModelClass, payload, id, requestType) {
      if (payload.content) {
        payload = this.normalizePageableResponse(payload, primaryModelClass.modelName);
      } else if (Array.isArray(payload)) {
        payload = this.normalizeNonObjectResponse(payload, primaryModelClass.modelName);
      }

      return this._normalizeResponse(store, primaryModelClass, payload, id, requestType, false);
    },

    normalizeSingleResponse: function normalizeSingleResponse(store, primaryModelClass, payload, id, requestType) {
      payload = payload || {};
      /**
       * This property indicate that id was generated or not
       */
      payload.__isGeneratedPrimaryID = false;

      if (!payload.id) {
        payload.id = new Date().getTime();
        payload.__isGeneratedPrimaryID = true;
      }
      payload = this.normalizeNonObjectResponse(payload, primaryModelClass.modelName);

      return this._normalizeResponse(store, primaryModelClass, payload, id, requestType, true);
    },

    /**
     * All delete methods should return empty response
     * This methods return hash of document for empty response
     * @returns object
     */
    normalizeDeleteRecordResponse: function normalizeDeleteRecordResponse() {
      return {
        data: null,
        included: []
      };
    },

    normalizePageableResponse: function normalizePageableResponse(payload, modelName) {
      var _ref;

      var meta = {
        totalElements: payload.totalElements,
        totalPages: payload.totalPages,
        sort: payload.sort,
        numberOfElements: payload.numberOfElements,
        size: payload.size,
        number: payload.number,
        totalSubElements: payload.numberOfVersions
      };

      return _ref = {}, _defineProperty(_ref, modelName, payload.content), _defineProperty(_ref, 'meta', meta), _ref;
    },

    normalizeNonObjectResponse: function normalizeNonObjectResponse(payload, modelName) {
      return _defineProperty({}, modelName, payload);
    },

    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      return this._super(store, primaryModelClass, payload.data, id, requestType);
    },

    serializeIntoHash: function serializeIntoHash(hash, type, record, options) {
      _ember['default'].merge(hash, this.serialize(record, options));
    }

  });
});