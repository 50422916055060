define('quantuvis-cm-spa/validations/custom-validators/date-format', ['exports', 'moment', 'quantuvis-cm-spa/constants/date-format'], function (exports, _moment, _quantuvisCmSpaConstantsDateFormat) {
  exports['default'] = dateFormat;

  function dateFormat() {

    var errorMessage = 'Invalid date format';

    var validateNewValue = function validateNewValue(newValue) {
      var isNewValueValid = (0, _moment['default'])(newValue, _quantuvisCmSpaConstantsDateFormat['default'].MM_DD_YYYY, true).isValid();

      if (isNewValueValid) {
        return true;
      } else {
        return errorMessage;
      }
    };

    /**
     * @todo should refactor after fixing issue with wizard and date
     */

    // const validateContent = (key, content) => {
    //   const isContentValid = moment(content[key], DATE_FORMAT.MM_DD_YYYY, true).isValid();
    //   if (isContentValid) {
    //     return true;
    //   } else {
    //     return errorMessage;
    //   }
    // }

    return function (key, newValue) {
      if (newValue) {
        return validateNewValue(newValue);
      }
      /**
       * @todo should refactor after fixing issue with wizard and date
       */
      // if (content[key]) {
      //   return validateContent(key, content);
      // }

      return true;
    };
  }
});