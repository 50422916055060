define('quantuvis-cm-spa/core-ui/components/cm-input/component', ['exports', 'ember', 'ember-validated-form/components/validated-input', 'quantuvis-cm-spa/mixins/add-test-id-attribute', 'ember-uuid'], function (exports, _ember, _emberValidatedFormComponentsValidatedInput, _quantuvisCmSpaMixinsAddTestIdAttribute, _emberUuid) {
  exports['default'] = _emberValidatedFormComponentsValidatedInput['default'].extend(_quantuvisCmSpaMixinsAddTestIdAttribute['default'], {
    classNameBindings: ['label::without-label', 'iconName:with-icon'],
    uuid: null,
    showControlLabel: _ember['default'].computed('type', function () {
      switch (this.get('type')) {
        case 'checkbox':
        case 'cm-switch':
          return false;
        default:
          return true;
      }
    }),

    init: function init() {
      this._super.apply(this, arguments);
      this.set('uuid', (0, _emberUuid.v1)());
    },

    showError: _ember['default'].computed('isValid', 'dirty', 'submitted', 'alwaysShowError', function () {
      return !this.get('isValid') && (this.get('alwaysShowError') || this.get('dirty') || this.get('submitted'));
    })
  });
});