define('quantuvis-cm-spa/components/cm-edit-workflow/workflow', ['exports', 'ember', 'ember-changeset', 'ember-changeset-validations', 'quantuvis-cm-spa/mixins/workflow-steps', 'quantuvis-cm-spa/constants/edit-workflow', 'quantuvis-cm-spa/constants/contract-status'], function (exports, _ember, _emberChangeset, _emberChangesetValidations, _quantuvisCmSpaMixinsWorkflowSteps, _quantuvisCmSpaConstantsEditWorkflow, _quantuvisCmSpaConstantsContractStatus) {
  exports['default'] = _ember['default'].Component.extend(_quantuvisCmSpaMixinsWorkflowSteps['default'], {
    fadeDuration: 200,
    scrollDuration: 600,
    dialog: _ember['default'].inject.service(),
    workflow: _ember['default'].inject.service(),
    classNames: ['cm-edit-workflow-steps'],

    startPosition: _ember['default'].computed('workflowData.steps', function () {
      var inProgressStep = this.get('workflowData.steps').find(function (step) {
        return step.get('isInProgress');
      });

      return inProgressStep ? inProgressStep.get('position') : 0;
    }),

    init: function init() {
      this._super.apply(this, arguments);
      this.set('steps', this.get('workflowData.stepsSortedByPosition'));

      this.initWorkflowDataChangeset();
      this.updateStepsModel();
    },

    /**
     * Init Workflow Data Changeset
     * This functions create changeset based on incoming "workflowData"
     * and push it to incoming (from controller) "changesetMap".
     * For every event with steps we run updateStepsModel() method and update
     * this "VIRTUAL_WORKFLOW_CHANGESET" changest.
     * It can halp with handle if there is any
     * changes in steps (e.g. remove/add/moveUp/moveDown) for
     * showing confirmation popup on navigating to different route.
     */
    initWorkflowDataChangeset: function initWorkflowDataChangeset() {
      var model = this.get('workflowData');
      var changeset = new _emberChangeset['default'](model, (0, _emberChangesetValidations['default'])({}), {}, { skipValidate: true });

      this.set(_quantuvisCmSpaConstantsEditWorkflow.VIRTUAL_WORKFLOW_CHANGESET, changeset);
      this.get('changesetMap').set(_quantuvisCmSpaConstantsEditWorkflow.VIRTUAL_WORKFLOW_CHANGESET, changeset);
    },

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.runScrollAction();
    },

    willDestroyElement: function willDestroyElement() {
      var workflow = this.get('workflowData');

      if (workflow) {
        workflow.rollbackAttributes();
        this.get('workflow').flush();
      }
    },

    runScrollAction: function runScrollAction() {
      var position = this.get('newStepPosition');
      var step = this.get('scrollToStep');

      if (position) {
        var index = this.calculateStepIndex(position);

        this.send('addWorkflowStep', { position: index });
        this.animate(this.get('workflow').scrollToStepByIndex.bind(this.get('workflow'), index));
      } else if (step) {
        this.animate(this.get('workflow').scrollToStepById.bind(this.get('workflow'), this.get('scrollToStep')));
      }
      this.sendAction('scrollCompleted', { id: this.get('workflowData.contractId') });
    },

    calculateStepIndex: function calculateStepIndex(position) {
      var index = position - this.get('startPosition');

      if (!this.get('requiredStepsProcessed')) {
        --index;
      }

      return index < 0 ? 0 : index;
    },

    animate: function animate(action) {
      setTimeout(action, this.get('scrollDuration'));
    },

    workflowStepsRequired: _ember['default'].computed('steps', function () {
      return this.get('steps').filter(function (step) {
        return !step.get('isAvailableToSort');
      });
    }),

    workflowStepsSorted: _ember['default'].computed('steps', function () {
      return this.get('steps').filter(function (step) {
        return step.get('isAvailableToSort');
      });
    }),

    isPendingForNewStep: _ember['default'].computed('contract', 'workflowData.steps', function () {
      return this.isPendingNextStepContract() && this.get('requiredStepsProcessed') && _ember['default'].isEmpty(this.get('workflowStepsSorted'));
    }),

    activeRequiredStep: _ember['default'].computed('workflowStepsRequired', function () {
      return this.get('workflowStepsRequired').find(function (step) {
        return step.get('isPending') || step.get('isInProgress');
      });
    }),

    requiredStepsProcessed: _ember['default'].computed.empty('activeRequiredStep'),

    isPendingNextStepContract: function isPendingNextStepContract() {
      var contractStatus = this.get('contract.status.id');
      var contractHasPendingNextStep = undefined;

      switch (contractStatus) {
        case _quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.IN_REVIEW:
        case _quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.IN_SIGNATURE:
        case _quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.PARTIALLY_EXECUTED_EXTERNALLY:
        case _quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.PARTIALLY_EXECUTED_INTERNALLY:
          contractHasPendingNextStep = true;
          break;
        default:
          contractHasPendingNextStep = false;
      }

      return contractHasPendingNextStep;
    },

    actions: {
      /**
       * Add new workflow step logic
       * @param data
       */
      addWorkflowStep: function addWorkflowStep(data) {
        var _this = this;

        this.addStep(this.get('workflow').createStep(), data.position);
        _ember['default'].run(function () {
          return _this.updateStepsModel();
        });
        this.get('workflow').scrollToStepByIndex(data.position);
      },

      /**
       * Remove workflow step from service
       * Display confirm dialog and delete step when user confirm operation
       * @param data
       */
      onWorkflowStepDelete: function onWorkflowStepDelete(data) {
        var _this2 = this;

        var options = {
          confirmMessage: 'Do you want to remove this step?'
        };

        this.get('dialog').show('dialogs/cm-dialog', 'dialog-templates/cm-confirmation', this, options).then(function () {

          if (data.isRequired) {
            _this2.removeRequiredStep(data.position);
          } else {
            _this2.removeStep(data.position);
            _this2.updateStepsModel();
          }
        })['catch'](function () {});
      },
      dragStarted: function dragStarted() {
        this.$('.cm-add-step').fadeTo(this.fadeDuration, 0);
        this.$('.time-line-item').addClass('hide');
      },
      dragStopped: function dragStopped() {
        this.$('.cm-add-step').fadeTo(this.fadeDuration, 1);
        this.$('.time-line-item').removeClass('hide');
      },
      reorderItems: function reorderItems(itemModels) {
        this.set('workflowStepsSorted', itemModels);
        this.updateStepsModel();
      },
      handleMoveDownClick: function handleMoveDownClick(position) {
        this.moveStepDown(position);
        this.updateStepsModel();
      },
      handleMoveUpClick: function handleMoveUpClick(position) {
        this.moveStepUp(position);
        this.updateStepsModel();
      }
    },

    removeRequiredStep: function removeRequiredStep(position) {
      var steps = this.get('workflowStepsRequired');

      steps.splice(position, 1);
      this.set('workflowStepsRequired', steps.slice());
      this.updateStepsModel();
    },

    updateStepsModel: function updateStepsModel() {
      var combined = this.getCombinedSteps();

      this.set('workflowData.steps', combined);

      /**
       * Update steps in VIRTUAL_WORKFLOW_CHANGESET.
       * Check jsDoc on "initWorkflowDataChangeset"
       * for additional information.
       */
      this.get(_quantuvisCmSpaConstantsEditWorkflow.VIRTUAL_WORKFLOW_CHANGESET).set('steps', combined);
    }
  });
});