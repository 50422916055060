define("quantuvis-cm-spa/constants/http-statuses", ["exports"], function (exports) {
  var HTTP_STATUSES = {
    NOT_FOUND: 404,
    BAD_REQUEST: 400,
    UNAUTHORISED: 401,
    FORBIDDEN: 403,
    CONFLICT: 409,
    INTERNAL_SERVER_ERROR: 500,
    OK: 200
  };

  exports["default"] = HTTP_STATUSES;
});