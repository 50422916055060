define('quantuvis-cm-spa/models/workflows/attachment', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    version: _emberData['default'].attr('number'),
    fileId: _emberData['default'].attr('number'),
    ownerId: _emberData['default'].attr('number'),
    owner: _emberData['default'].attr(),
    uploadDate: _emberData['default'].attr('unix-timestamp'),
    key: _emberData['default'].attr('string'),
    previewKey: _emberData['default'].attr('string'),
    name: _emberData['default'].attr('string'),
    errorMessage: _emberData['default'].attr('string'),
    source: _emberData['default'].attr(),
    status: _emberData['default'].attr()
  });
});