define('quantuvis-cm-spa/components/cm-contract-details/cm-history/cm-history-details-property', ['exports', 'ember', 'moment', 'quantuvis-cm-spa/constants/date-format', 'quantuvis-cm-spa/constants/history-property-type'], function (exports, _ember, _moment, _quantuvisCmSpaConstantsDateFormat, _quantuvisCmSpaConstantsHistoryPropertyType) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'tr',
    classNames: ['cm-history-details-property'],
    date: _ember['default'].inject.service(),

    originalValue: _ember['default'].computed('property', function () {
      var property = this.get('property');

      return this.normalizeValue(property.originValue, property.type.id);
    }),

    newValue: _ember['default'].computed('property', function () {
      var property = this.get('property');

      return this.normalizeValue(property.newValue, property.type.id);
    }),

    normalizeValue: function normalizeValue(value, type) {
      var normalizedValue = '';

      switch (type) {
        case _quantuvisCmSpaConstantsHistoryPropertyType['default'].DATE:
          normalizedValue = this.formatDatePropertyValue(value);
          break;
        case _quantuvisCmSpaConstantsHistoryPropertyType['default'].STRING:
        default:
          normalizedValue = String(value);
          break;
      }

      return normalizedValue;
    },

    formatDatePropertyValue: function formatDatePropertyValue(value) {
      var date = (0, _moment['default'])(value, _quantuvisCmSpaConstantsDateFormat['default'].ISO_DATETIME);

      return this.get('date').formatDate(date);
    }
  });
});