define('quantuvis-cm-spa/routes/create-contract', ['exports', 'ember', 'ember-query-params-reset/mixins/query-params-reset-route', 'quantuvis-cm-spa/mixins/authenticated-route-mixin'], function (exports, _ember, _emberQueryParamsResetMixinsQueryParamsResetRoute, _quantuvisCmSpaMixinsAuthenticatedRouteMixin) {
  exports['default'] = _ember['default'].Route.extend(_emberQueryParamsResetMixinsQueryParamsResetRoute['default'], _quantuvisCmSpaMixinsAuthenticatedRouteMixin['default'], {
    documentWizard: _ember['default'].inject.service(),

    beforeModel: function beforeModel(transition) {
      var authMixinPromise = this._super.apply(this, arguments);

      var params = transition.queryParams;

      this.get('documentWizard').setProperties({
        activateMode: false,
        executedMode: params.executed,
        prepopulatedProperties: params
      });

      if (this.controller) {
        this.controller.setProperties({ currentStepIndex: 0, forcedStepIndex: 0 });
        this.get('documentWizard').setInitData();
      }

      var processActivateContractPromise = this.get('documentWizard').processActivateContractId(params.activateContractId);

      return _ember['default'].RSVP.hash({
        authMixinPromise: authMixinPromise,
        processActivateContractPromise: processActivateContractPromise
      });
    },

    model: function model(params) {
      var contractTypeId = params.contractTypeId;
      var amendmentLinkId = params.amendmentLinkId;

      var wizardSteps = this.get('documentWizard').getWizardSteps(contractTypeId);

      Object.keys(wizardSteps).forEach(function (key) {
        if (wizardSteps[key].set) {
          wizardSteps[key].set('isCompleted', false);
        }
      });

      return _ember['default'].RSVP.hash({
        wizardSteps: wizardSteps,
        type: this.getDocumentType(contractTypeId),
        amendmentContract: this.get('documentWizard').initialiseAmendmentContract(amendmentLinkId, contractTypeId)
      });
    },

    getDocumentType: function getDocumentType(typeID) {
      var _this = this;

      return this.get('store').findAll('contracts/types/available-type').then(function (contractTypes) {
        var contractType = contractTypes.find(function (type) {
          return type.id === typeID;
        });

        if (contractType) {
          _this.set('documentWizard.model.type', contractType);

          return contractType;
        } else {
          _this.send('error');
        }
      });
    }
  });
});