define('quantuvis-cm-spa/components/cm-timeline/cm-timeline-content', ['exports', 'ember', 'quantuvis-cm-spa/constants/timeline-item-type', 'quantuvis-cm-spa/constants/workflow-step-type', 'quantuvis-cm-spa/constants/workflow-step-state', 'quantuvis-cm-spa/constants/contract-status', 'quantuvis-cm-spa/constants/permissions'], function (exports, _ember, _quantuvisCmSpaConstantsTimelineItemType, _quantuvisCmSpaConstantsWorkflowStepType, _quantuvisCmSpaConstantsWorkflowStepState, _quantuvisCmSpaConstantsContractStatus, _quantuvisCmSpaConstantsPermissions) {
  exports['default'] = _ember['default'].Component.extend({
    PERMISSIONS: _quantuvisCmSpaConstantsPermissions.PERMISSIONS,
    classNames: 'cm-timeline-content',
    contractDetails: _ember['default'].inject.service(),
    isTimelineItemsLoading: _ember['default'].computed.alias('contractDetails.isTimelineItemsLoading'),

    init: function init() {
      this._super.apply(this, arguments);
      this.loadTimelineItems();
    },

    timelineItems: _ember['default'].computed('contractDetails.timelineItems', function () {
      return this.get('contractDetails.timelineItems');
    }),

    contractId: _ember['default'].computed('contractDetails.contractId', function () {
      return this.get('contractDetails').contractId;
    }),

    contractIdObserver: _ember['default'].observer('contractDetails.contractId', function () {
      this.loadTimelineItems();
      this.$().scrollTop(0);
    }),

    loadTimelineItems: function loadTimelineItems() {
      this.get('contractDetails').loadTimelineItems();
    },

    computedTimelineItems: _ember['default'].computed.map('timelineItems', function (timelineItem) {
      return {
        template: this.getTimelineTemplate(timelineItem),
        data: this.getTimelineItemData(timelineItem)
      };
    }),

    showPendingNextStep: _ember['default'].computed('computedTimelineItems', function () {
      var timelineItems = this.get('timelineItems');

      if (timelineItems.length === 0) {
        return false;
      }
      var contractStatusId = this.get('contractDetails').getContract().get('status.id');

      switch (contractStatusId) {
        case _quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.IN_REVIEW:
        case _quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.IN_SIGNATURE:
        case _quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.PARTIALLY_EXECUTED_EXTERNALLY:
        case _quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.PARTIALLY_EXECUTED_INTERNALLY:
          return !this.hasStepInProgress(timelineItems);
        default:
          return false;
      }
    }),

    hasSubstepInProgress: function hasSubstepInProgress(workflowStep) {
      var substeps = workflowStep.get('substeps');

      if (substeps && substeps.length > 0) {
        var inProgressSubstep = substeps.find(function (substep) {
          return substep.get('state.id') === _quantuvisCmSpaConstantsWorkflowStepState['default'].IN_PROGRESS;
        });

        return !!inProgressSubstep;
      }
    },

    isInProgressItem: function isInProgressItem(workflowStep) {
      return workflowStep.get('state.id') === _quantuvisCmSpaConstantsWorkflowStepState['default'].IN_PROGRESS || this.hasSubstepInProgress(workflowStep);
    },

    hasStepInProgress: function hasStepInProgress(timelineItems) {
      var _this = this;

      var inProgressItem = timelineItems.find(function (item) {
        var isWorkflowStep = item.get('type.id') === _quantuvisCmSpaConstantsTimelineItemType['default'].WORKFLOW_STEP;

        return isWorkflowStep ? _this.isInProgressItem(item.get('workflowStep')) : false;
      });

      return !!inProgressItem;
    },

    getTimelineTemplate: function getTimelineTemplate(timelineItem) {
      switch (timelineItem.get('type').id) {
        case _quantuvisCmSpaConstantsTimelineItemType['default'].EVENT:
          return 'cm-timeline/cm-timeline-item/cm-timeline-event';
        case _quantuvisCmSpaConstantsTimelineItemType['default'].WORKFLOW_STEP:
          return this.getWorkflowStepTemplate(timelineItem.get('workflowStep'));
        default:
          return null;
      }
    },

    getWorkflowStepTemplate: function getWorkflowStepTemplate(workflowStep) {
      switch (workflowStep.get('type.id')) {
        case _quantuvisCmSpaConstantsWorkflowStepType['default'].CONTRACT_LEAD_REVIEW_ID:
          return 'cm-timeline/cm-timeline-item/cm-timeline-lead-review';
        case _quantuvisCmSpaConstantsWorkflowStepType['default'].DEPARTMENT_REVIEW_ID:
          return 'cm-timeline/cm-timeline-item/cm-timeline-department-review';
        case _quantuvisCmSpaConstantsWorkflowStepType['default'].MULTI_DEPARTMENT_REVIEW_ID:
          return 'cm-timeline/cm-timeline-item/cm-timeline-multi-department-review';
        case _quantuvisCmSpaConstantsWorkflowStepType['default'].DIGITAL_SIGN_OFF_ID:
          return 'cm-timeline/cm-timeline-item/cm-timeline-digital-sign-off';
        case _quantuvisCmSpaConstantsWorkflowStepType['default'].MANUAL_SIGN_OFF_ID:
          return 'cm-timeline/cm-timeline-item/cm-timeline-manual-sign-off';
        default:
          return null;
      }
    },

    getTimelineItemData: function getTimelineItemData(timelineItem) {
      switch (timelineItem.get('type').id) {
        case _quantuvisCmSpaConstantsTimelineItemType['default'].EVENT:
          return timelineItem.get('event');
        case _quantuvisCmSpaConstantsTimelineItemType['default'].WORKFLOW_STEP:
          return timelineItem.get('workflowStep');
        default:
          throw 'Wrong timeline item type id';
      }
    }
  });
});