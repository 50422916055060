define('quantuvis-cm-spa/components/cm-workflow-templates', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: 'cm-workflow-templates',

    actions: {
      selectWorkflowTemplate: function selectWorkflowTemplate(template) {
        this.sendAction('select', template);
      }
    }
  });
});