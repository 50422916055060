define('quantuvis-cm-spa/core-ui/components/cm-navbar/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['cm-navbar', 'cm-shadow-box-navbar'],
    actions: {
      cancelClick: function cancelClick() {
        this.sendAction('cancel');
      },
      backClick: function backClick() {
        this.sendAction('back');
      }
    }
  });
});