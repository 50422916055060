define('quantuvis-cm-spa/initializers/ember-spinner', ['exports', 'quantuvis-cm-spa/config/environment'], function (exports, _quantuvisCmSpaConfigEnvironment) {

  var emberSpinnerPrefix = {
    modulePrefix: _quantuvisCmSpaConfigEnvironment['default'].modulePrefix
  };

  exports['default'] = {
    name: 'ember-spinner-prefix',

    initialize: function initialize() {
      var application = arguments[1] || arguments[0];
      application.register('ember-spinner:main', emberSpinnerPrefix, { instantiate: false });
      application.inject('component:ember-spinner', 'emberSpinnerPrefixConfig', 'ember-spinner:main');
    }
  };
});