define('quantuvis-cm-spa/controllers/profile-settings', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    profileSettings: _ember['default'].inject.service(),

    actions: {
      handleSaveProfile: function handleSaveProfile() {
        var _this = this;

        this.transitionToRoute('contracts').then(function () {
          _this.get('profileSettings').cleanupData();
        });
      },

      handleCancel: function handleCancel() {
        var _this2 = this;

        var isChangesetDirty = this.get('profileSettings').get('changeset').get('isDirty');

        if (isChangesetDirty) {
          this.showConfirmationPopup();
        } else {
          this.transitionToRoute('contracts').then(function () {
            _this2.get('profileSettings').cleanupData();
          });
        }
      }
    },

    showConfirmationPopup: function showConfirmationPopup() {
      var _this3 = this;

      var options = {
        confirmMessage: 'You have not yet saved your changes. All the data entered will be lost.\n       Are you sure you want to navigate away?'
      };

      this.get('dialog').show('dialogs/cm-dialog', 'dialog-templates/cm-confirmation', this, options).then(function () {
        _this3.transitionToRoute('contracts').then(function () {
          _this3.get('profileSettings').cleanupData();
        })['catch'](function () {
          _ember['default'].Logger.warn('Something went wronk during transition to Contracts page');
        });
      })['catch'](function () {});
    }
  });
});