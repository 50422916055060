define('quantuvis-cm-spa/adapters/signoff', ['exports', 'quantuvis-cm-spa/adapters/application'], function (exports, _quantuvisCmSpaAdaptersApplication) {
  exports['default'] = _quantuvisCmSpaAdaptersApplication['default'].extend({
    updateSignOffByEnvelope: function updateSignOffByEnvelope(envelopeId) {
      var uri = this.urlPrefix() + '/signoff/envelope/' + envelopeId + '/start';

      return this.ajax(uri, 'POST');
    },

    syncSignOff: function syncSignOff(envelopeId) {
      var uri = this.urlPrefix() + '/signoff/envelope/' + envelopeId + '/sync';

      return this.ajax(uri, 'POST');
    },

    correctSignOff: function correctSignOff(envelopeId) {
      var uri = this.urlPrefix() + '/signoff/envelope/' + envelopeId + '/correct';

      return this.ajax(uri, 'POST');
    },

    getActiveByContractId: function getActiveByContractId(id) {
      var _this = this;

      var uri = this.urlPrefix() + '/signoff/contract/' + id + '/current';

      return this.ajax(uri, 'GET').then(function (_ref) {
        var data = _ref.data;

        var store = _this.get('store');

        return store.push(store.normalize('signoff', data));
      });
    },

    getPendingByContractId: function getPendingByContractId(id) {
      var _this2 = this;

      var uri = this.urlPrefix() + '/signoff/contract/' + id + '/pending-approval';

      return this.ajax(uri, 'GET').then(function (_ref2) {
        var data = _ref2.data;

        var store = _this2.get('store');

        return store.push(store.normalize('signoff', data));
      });
    },

    edit: function edit(id) {
      var uri = this.urlPrefix() + '/signoff/' + id + '/correct-view';

      return this.ajax(uri, 'GET');
    },

    resendEmails: function resendEmails(signOffId) {
      var uri = this.urlPrefix() + '/signoff/' + signOffId + '/resend-emails';

      return this.ajax(uri, 'PUT');
    },

    getQuote: function getQuote(contractId) {
      var uri = this.urlPrefix() + '/signoff/contract-signoff/' + contractId + '/quotes';

      return this.ajax(uri, 'GET');
    }
  });
});