define('quantuvis-cm-spa/core-ui/components/cm-dropdown-menu/cm-menu-item/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['cm-menu-item-wrapper'],
    classNameBindings: ['disabled'],
    attributeBindings: ['data-test-id'],

    route: '',
    dataTestId: '',
    param: null,
    queryParams: null,
    disabled: false,

    click: function click(data) {
      if (!this.get('disabled')) {
        this.sendAction('onSelect', data);
        this.sendAction('onSelectPrivate');
      }
    }
  });
});