define('quantuvis-cm-spa/core-ui/components/cm-templates-list/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['cm-template-list'],
    store: _ember['default'].inject.service(),
    downloadFile: _ember['default'].inject.service(),
    selectFirstTemplate: false,
    didRender: function didRender() {
      if (this.get('selectFirstTemplate') && !this.get('selectedKey')) {
        this.sendAction('templateSelected', this.get('templates.firstObject'));
      }
    },
    actions: {
      templateSelected: function templateSelected(template) {
        this.sendAction('templateSelected', template);
      },
      downloadTemplate: function downloadTemplate(template) {
        var _this = this;

        this.get('store').queryRecord('contracts/file-templates/download-presigned-url', {
          previewKey: template.get('fileKey')
        }).then(function (response) {
          _this.get('downloadFile').download(response.get('url'));
        })['catch'](function (error) {
          _ember['default'].Logger.error('Error occured on retrieve URL', error);
        });
      }
    }
  });
});