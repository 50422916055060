define('quantuvis-cm-spa/routes/contract-details', ['exports', 'ember', 'quantuvis-cm-spa/mixins/authenticated-route-mixin', 'quantuvis-cm-spa/mixins/query-params-url-cleaner', 'quantuvis-cm-spa/constants/contract-type', 'quantuvis-cm-spa/constants/contract-status'], function (exports, _ember, _quantuvisCmSpaMixinsAuthenticatedRouteMixin, _quantuvisCmSpaMixinsQueryParamsUrlCleaner, _quantuvisCmSpaConstantsContractType, _quantuvisCmSpaConstantsContractStatus) {
  exports['default'] = _ember['default'].Route.extend(_quantuvisCmSpaMixinsAuthenticatedRouteMixin['default'], _quantuvisCmSpaMixinsQueryParamsUrlCleaner['default'], {
    contractDetails: _ember['default'].inject.service(),
    permissionService: _ember['default'].inject.service(),
    alertService: _ember['default'].inject.service(),
    signOffView: _ember['default'].inject.service(),
    contractDetailsSidebarNavigator: _ember['default'].inject.service(),
    previousDocumentId: null,

    beforeModel: function beforeModel(transition) {
      this.set('previousDocumentId', transition.queryParams.previousDocumentId);
      var authMixinPromise = this._super.apply(this, arguments);
      var handleSenderViewCallbackPromise = this.get('signOffView').handleViewCallback(transition.queryParams);

      return _ember['default'].RSVP.hash({
        authMixinPromise: authMixinPromise,
        handleSenderViewCallbackPromise: handleSenderViewCallbackPromise
      });
    },

    model: function model(params) {
      var contract, parentContractId, promises;
      return regeneratorRuntime.async(function model$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            this.resetActiveSignOff();

            context$1$0.next = 3;
            return regeneratorRuntime.awrap(this.get('store').findRecord('contract', params.contractId, { reload: true }));

          case 3:
            contract = context$1$0.sent;
            parentContractId = contract.get('contractId');

            if (parentContractId) {
              this.get('contractDetails').loadParentContract(parentContractId);
            }

            this.presetProperties(contract, params.contractId);
            this.get('alertService').loadAlerts();
            this.get('contractDetails').loadVersions();

            promises = [];

            promises.push(this.loadPendingSignOff(contract.get('id')));

            if (contract.get('status.id') === _quantuvisCmSpaConstantsContractStatus.CONTRACT_STATUS.IN_SIGNATURE) {
              promises.push(this.loadActiveSignOff(contract.get('id')));
            }

            context$1$0.next = 14;
            return regeneratorRuntime.awrap(Promise.all(promises));

          case 14:
            return context$1$0.abrupt('return', _ember['default'].RSVP.hash({ contract: contract }));

          case 15:
          case 'end':
            return context$1$0.stop();
        }
      }, null, this);
    },

    presetProperties: function presetProperties(data, id) {
      this.get('contractDetails').setContractId(id);
      this.get('contractDetails').set('notes', []);
      this.get('permissionService').set('companyId', data.get('company.id'));
      this.get('contractDetails').set('currentVersion', data.get('file.version'));
    },

    resetActiveSignOff: function resetActiveSignOff() {
      if (this.get('contractDetails.activeSignOff')) {
        return this.get('contractDetails').set('activeSignOff', null);
      }
    },

    loadActiveSignOff: function loadActiveSignOff(contractId) {
      return this.get('contractDetails').loadActiveSignOff(contractId);
    },

    loadPendingSignOff: function loadPendingSignOff(contractId) {
      return this.get('contractDetails').loadPendingSignOff(contractId);
    },

    actions: {
      handleBackClick: function handleBackClick() {
        var _this = this;

        this.get('permissionService').set('companyId', null);
        this.get('contractDetails').set('currentVersion', null);

        if (this.previousDocumentId) {
          this.transitionTo('contract-details', this.previousDocumentId).then(function () {
            return _this.get('contractDetailsSidebarNavigator').setDefaultActiveTab();
          });
        } else {
          this.transitionTo('contracts');
        }
      },
      createAmendment: function createAmendment() {
        this.get('permissionService').set('companyId', null);
        this.get('contractDetails').set('currentVersion', null);
        this.transitionTo('create-contract', _quantuvisCmSpaConstantsContractType['default'].AMENDMENT, {
          queryParams: {
            amendmentLinkId: this.get('contractDetails').get('contractId')
          }
        });
      }
    }
  });
});