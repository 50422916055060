define('quantuvis-cm-spa/components/cm-document-preview/cm-document-preview', ['exports', 'ember', 'quantuvis-cm-spa/constants/attachment-type', 'quantuvis-cm-spa/constants/tooltip-messages'], function (exports, _ember, _quantuvisCmSpaConstantsAttachmentType, _quantuvisCmSpaConstantsTooltipMessages) {
  exports['default'] = _ember['default'].Component.extend({
    notifications: _ember['default'].inject.service(),
    date: _ember['default'].inject.service(),
    file: _ember['default'].inject.service(),
    isTaskOverdue: false,
    documentType: _quantuvisCmSpaConstantsAttachmentType['default'].CONTRACT_ATTACHMENT,
    TOOLTIP_MESSAGES: _quantuvisCmSpaConstantsTooltipMessages['default'],

    tasksObserver: _ember['default'].on('init', _ember['default'].observer('notifications.tasks.@each', function () {
      var _this = this;

      this.checkForOverdueTask().then(function (isTaskOverdue) {
        if (!_this.get('isDestroyed')) {
          _this.set('isTaskOverdue', isTaskOverdue);
        }
      });
    })),

    checkForOverdueTask: function checkForOverdueTask() {
      var _this2 = this;

      var tasksPromise = this.get('notifications.tasks'),
          contractId = this.get('contractId');

      return tasksPromise.then(function (tasks) {

        return tasks.filter(function (task) {
          return _this2.get('date').isOverdue(task.get('dueDate'));
        }).mapBy('contractId').includes(Number(contractId));
      });
    },

    actions: {
      previewDocument: function previewDocument() {
        var contractId = this.get('contractId');
        var companyId = this.get('companyId');
        var predefinedSearch = this.get('predefinedSearch') || '';

        this.get('file').previewDocument(this.get('document'), this.get('documentType'), contractId, companyId, predefinedSearch);
      }
    }
  });
});