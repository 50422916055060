define('quantuvis-cm-spa/components/cm-workflow-template', ['exports', 'ember', 'ember-changeset', 'ember-changeset-validations', 'quantuvis-cm-spa/mixins/workflow-steps', 'quantuvis-cm-spa/validations/workflow-template'], function (exports, _ember, _emberChangeset, _emberChangesetValidations, _quantuvisCmSpaMixinsWorkflowSteps, _quantuvisCmSpaValidationsWorkflowTemplate) {

  var WORKFLOW_TEMPLATE_SPINNER_ID = 'cm-workflow-template-spinner';

  exports['default'] = _ember['default'].Component.extend(_quantuvisCmSpaMixinsWorkflowSteps['default'], {
    WORKFLOW_TEMPLATE_SPINNER_ID: WORKFLOW_TEMPLATE_SPINNER_ID,
    dialog: _ember['default'].inject.service(),
    workflowTemplate: _ember['default'].inject.service(),
    workflow: _ember['default'].inject.service(),
    uniqueWorkflowTemplateValidator: _ember['default'].inject.service(),
    fadeDuration: 200,
    changesetMap: new Map(),

    init: function init() {
      this.set('steps', this.get('template.stepsSortedByPosition'));
      this.initWorkflowTemplateSteps();
      this.initWorkflowTemplateForm();
      this._super.apply(this, arguments);
    },

    initWorkflowTemplateSteps: function initWorkflowTemplateSteps() {
      this.set('workflowStepsRequired', this.getRequiredSteps());
      this.set('workflowStepsSorted', this.getSortedSteps());
    },

    initWorkflowTemplateForm: function initWorkflowTemplateForm() {
      this.attachUniqueNameValidator(_quantuvisCmSpaValidationsWorkflowTemplate['default']);
      var changeset = new _emberChangeset['default'](this.get('template'), (0, _emberChangesetValidations['default'])(_quantuvisCmSpaValidationsWorkflowTemplate['default']), _quantuvisCmSpaValidationsWorkflowTemplate['default'], { skipValidate: true });

      this.get('changesetMap').set(this.elementId, changeset);
      this.set('changeset', changeset);
    },

    willDestroyElement: function willDestroyElement() {
      this.get('workflowTemplate').flush();
    },

    attachUniqueNameValidator: function attachUniqueNameValidator(validators) {
      var uniqueness = this.get('uniqueWorkflowTemplateValidator').validateUniqueness(this.get('template.id'));
      var present = validators.name.findIndex(function (validator) {
        return validator.toString() === uniqueness.toString();
      });

      /**
       * Override validator if present
       */
      present === -1 ? validators.name.push(uniqueness) : validators.name[present] = uniqueness;
    },

    isStepsChanged: _ember['default'].observer('steps', function () {
      this.initWorkflowTemplateSteps();
    }),

    getRequiredSteps: function getRequiredSteps() {
      return this.get('steps').filter(function (step) {
        return step.get('isRequired');
      });
    },

    getSortedSteps: function getSortedSteps() {
      return this.get('steps').filter(function (step) {
        return !step.get('isRequired');
      });
    },

    actions: {
      saveWorkflowTemplate: function saveWorkflowTemplate() {
        this.updateStepsModel();

        this.sendAction('saveWorkflowTemplate', {
          template: this.get('template'),
          changesetMap: this.get('changesetMap')
        });
      },

      backToWorkflowTemplates: function backToWorkflowTemplates() {
        this.sendAction('backToWorkflowTemplates');
      },
      /**
       * Add new workflow step logic
       * @param data
       */
      addWorkflowStep: function addWorkflowStep(data) {
        var _this = this;

        this.addStep(this.get('workflowTemplate').createStep(), data.position);
        _ember['default'].run(function () {
          return _this.updateStepsModel();
        });
        this.get('workflow').scrollToStepByIndex(data.position);
      },

      /**
       * Remove workflow step from service
       * Display confirm dialog and delete step when user confirm operation
       * @param data
       */
      onWorkflowStepDelete: function onWorkflowStepDelete(data) {
        var _this2 = this;

        var options = {
          confirmMessage: 'Do you want to remove this step?'
        };

        this.get('dialog').show('dialogs/cm-dialog', 'dialog-templates/cm-confirmation', this, options).then(function () {
          _this2.removeStep(data.position);
          _this2.updateStepsModel();
        })['catch'](function () {});
      },
      dragStarted: function dragStarted() {
        this.$('.cm-add-step').fadeTo(this.fadeDuration, 0);
        this.$('.time-line-item').addClass('hide');
      },
      dragStopped: function dragStopped() {
        this.$('.cm-add-step').fadeTo(this.fadeDuration, 1);
        this.$('.time-line-item').removeClass('hide');
      },
      reorderItems: function reorderItems(itemModels) {
        this.set('workflowStepsSorted', itemModels);
        this.updateStepsModel();
      },
      handleMoveDownClick: function handleMoveDownClick(position) {
        this.moveStepDown(position);
        this.updateStepsModel();
      },
      handleMoveUpClick: function handleMoveUpClick(position) {
        this.moveStepUp(position);
        this.updateStepsModel();
      }
    },

    getRequiredElementsLength: function getRequiredElementsLength() {
      return this.get('workflowStepsRequired').length;
    },

    updateStepsModel: function updateStepsModel() {
      this.set('template.steps', this.getCombinedSteps());
    }
  });
});