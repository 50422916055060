define('quantuvis-cm-spa/routes/forgot-password', ['exports', 'ember', 'ember-simple-auth/mixins/unauthenticated-route-mixin', 'ember-changeset', 'ember-changeset-validations', 'quantuvis-cm-spa/validations/forgot-password'], function (exports, _ember, _emberSimpleAuthMixinsUnauthenticatedRouteMixin, _emberChangeset, _emberChangesetValidations, _quantuvisCmSpaValidationsForgotPassword) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsUnauthenticatedRouteMixin['default'], {

    credentials: {
      email: ''
    },

    initChangeset: function initChangeset() {
      var changeset = new _emberChangeset['default'](this.credentials, (0, _emberChangesetValidations['default'])(_quantuvisCmSpaValidationsForgotPassword['default']), _quantuvisCmSpaValidationsForgotPassword['default'], {
        skipValidate: true
      });

      return changeset;
    },

    model: function model(params) {
      return {
        credentials: this.initChangeset(),
        application: params.application
      };
    }
  });
});