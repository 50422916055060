define('quantuvis-cm-spa/models/contracts/alert', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    name: _emberData['default'].attr('string'),
    date: _emberData['default'].attr('unix-timestamp'),
    dueDate: _emberData['default'].attr('unix-date'),
    effectiveDate: _emberData['default'].attr('unix-date'),
    workflowStepId: _emberData['default'].attr('number'),
    workflowSubStepId: _emberData['default'].attr('number'),
    type: _emberData['default'].attr(),
    responsiblePersons: _emberData['default'].attr(),
    payload: _emberData['default'].attr()
  });
});