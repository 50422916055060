define('quantuvis-cm-spa/components/cm-contract-details/summary-components/linked-documents', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['linked-documents'],
    router: _ember['default'].inject.service('-routing'),
    contractDetails: _ember['default'].inject.service(),
    contractDetailsSidebarNavigator: _ember['default'].inject.service(),

    actions: {
      navigateToContract: function navigateToContract(redirectToDocumentId) {
        var _this = this;

        this.get('router').transitionTo('contract-details', [redirectToDocumentId], { 'previousDocumentId': this.get('contractDetails').getContract().id }).then(function () {
          return _this.get('contractDetailsSidebarNavigator').setDefaultActiveTab();
        });
      }
    }
  });
});