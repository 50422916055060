define('quantuvis-cm-spa/components/business-admin/cm-template/cm-template-form', ['exports', 'ember', 'quantuvis-cm-spa/constants/file-types'], function (exports, _ember, _quantuvisCmSpaConstantsFileTypes) {
  exports['default'] = _ember['default'].Component.extend({
    store: _ember['default'].inject.service(),
    fileQueue: _ember['default'].inject.service(),
    file: _ember['default'].inject.service(),
    fileTypes: _quantuvisCmSpaConstantsFileTypes.TEMPLATE_ALLOWED_TYPES.FILE_TYPES,
    templateTypeMatcher: _quantuvisCmSpaConstantsFileTypes.TEMPLATE_ALLOWED_TYPES.FILE_TYPE_MATCHER,

    actions: {
      onFileDrop: function onFileDrop(fi, evt) {
        this.get('file').standardFileDropHandler(fi, evt);
      },
      uploadNewVersion: function uploadNewVersion(fi, file) {
        this.get('file').uploadTemplate(fi, file, null, { fileTypeMatcher: this.get('templateTypeMatcher') });
      },
      moreThenOneFilesWarning: function moreThenOneFilesWarning(fi) {
        fi.model.addError('fileKey', ['Only one file can be chosen']);
        this.get('fileQueue').set('files', []);
      },
      removeFile: function removeFile(changeset) {
        changeset.set('fileKey', null);
        changeset.set('fileName', null);
      },
      submit: function submit() {
        this.sendAction('submit');
      },
      cancelUpload: function cancelUpload() {
        this.get('file').cancelUpload();
      }
    }
  });
});