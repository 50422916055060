define('quantuvis-cm-spa/components/cm-filter-options', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: '',

    actions: {
      filterOptions: function filterOptions(attribute, value) {
        this.sendAction('searchOptions', attribute, value);
      }
    }
  });
});