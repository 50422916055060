define('quantuvis-cm-spa/components/cm-contract-details/cm-document-version', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['document-version'],
    downloadVersions: _ember['default'].inject.service(),

    actions: {
      toggle: function toggle(documentVersion) {
        this.set('downloadVersions.isValid', true);
        documentVersion.toggleProperty('isChecked');
      }
    }
  });
});