define('quantuvis-cm-spa/services/toast-notification', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Service.extend({
    containerPosition: 'top-right',
    items: _ember['default'].A([]),

    add: function add(item) {
      this.get('items').pushObject(_ember['default'].Object.create(item));
    },

    remove: function remove(index) {
      this.get('items').removeAt(index);
    },

    removeAll: function removeAll() {
      this.get('items').clear();
    }
  });
});