define('quantuvis-cm-spa/constants/details-menu-items', ['exports'], function (exports) {
  var DETAILS_MENU_ITEMS = {
    TIMELINE: 'timeline',
    SUMMARY: 'summary',
    NOTES: 'notes',
    AMENDMENTS: 'amendments',
    HISTORY: 'history'
  };

  exports['default'] = DETAILS_MENU_ITEMS;
});