define('quantuvis-cm-spa/components/cm-reset-password', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['cm-reset-password'],
    passwordService: _ember['default'].inject.service('password'),

    successFormSent: false,
    failFormSent: false,
    invalidToken: false,
    secretQuestion: '',

    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      this._super.apply(this, arguments);
      this.get('passwordService').getSecretQuestion(this.get('model.token')).then(function (response) {
        _this.set('invalidToken', response.get('invalidToken'));
        _this.set('secretQuestion', response.get('question'));
      })['catch'](function () {
        return _this.set('failFormSent', true);
      });
    },

    actions: {
      submit: function submit() {
        var _this2 = this;

        this.get('passwordService').sendResetPassword(this.get('model.token'), {
          password: this.get('model.credentials.newPassword'),
          answer: this.get('model.credentials.answer')
        }).then(function (_ref) {
          var data = _ref.data;

          _this2.set('invalidToken', data.invalidToken);
          if (data.invalidAnswer) {
            _this2.get('model.credentials').pushErrors('answer', 'You have entered a wrong security question\'s answer.');
          } else {
            _this2.set('successFormSent', !data.invalidToken);
          }
        })['catch'](function () {
          return _this2.set('failFormSent', true);
        });
      }
    }
  });
});