define('quantuvis-cm-spa/app', ['exports', 'ember', '@sentry/browser', 'quantuvis-cm-spa/resolver', 'ember-load-initializers', 'quantuvis-cm-spa/config/environment', 'quantuvis-cm-spa/models/inflector/custom-inflector-rules', 'quantuvis-cm-spa/reopens/link-component'], function (exports, _ember, _sentryBrowser, _quantuvisCmSpaResolver, _emberLoadInitializers, _quantuvisCmSpaConfigEnvironment, _quantuvisCmSpaModelsInflectorCustomInflectorRules, _quantuvisCmSpaReopensLinkComponent) {

  var App = undefined;

  _ember['default'].MODEL_FACTORY_INJECTIONS = true;

  App = _ember['default'].Application.extend({
    modulePrefix: _quantuvisCmSpaConfigEnvironment['default'].modulePrefix,
    podModulePrefix: _quantuvisCmSpaConfigEnvironment['default'].podModulePrefix,
    Resolver: _quantuvisCmSpaResolver['default'],
    ready: function ready() {
      if (_quantuvisCmSpaConfigEnvironment['default'].sentry.enabled) {
        _sentryBrowser['default'].init({
          dsn: _quantuvisCmSpaConfigEnvironment['default'].sentry.dsn,
          enabled: _quantuvisCmSpaConfigEnvironment['default'].sentry.enabled,
          release: _quantuvisCmSpaConfigEnvironment['default'].sentry.release,
          environment: location.host
        });
      }
    }
  });

  (0, _emberLoadInitializers['default'])(App, _quantuvisCmSpaConfigEnvironment['default'].modulePrefix);

  exports['default'] = App;
});