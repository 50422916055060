define('quantuvis-cm-spa/components/cm-timeline/cm-timeline-item-attachments', ['exports', 'ember', 'quantuvis-cm-spa/constants/date-format', 'quantuvis-cm-spa/constants/attachment-type', 'quantuvis-cm-spa/constants/file-source', 'quantuvis-cm-spa/constants/tooltip-messages', 'quantuvis-cm-spa/constants/permissions'], function (exports, _ember, _quantuvisCmSpaConstantsDateFormat, _quantuvisCmSpaConstantsAttachmentType, _quantuvisCmSpaConstantsFileSource, _quantuvisCmSpaConstantsTooltipMessages, _quantuvisCmSpaConstantsPermissions) {
  exports['default'] = _ember['default'].Component.extend({
    DATE_FORMAT: _quantuvisCmSpaConstantsDateFormat['default'],
    PERMISSIONS: _quantuvisCmSpaConstantsPermissions.PERMISSIONS,
    TOOLTIP_MESSAGES: _quantuvisCmSpaConstantsTooltipMessages['default'],
    classNames: ['cm-timeline-item-attachments'],
    file: _ember['default'].inject.service(),
    contractDetails: _ember['default'].inject.service(),
    downloadFile: _ember['default'].inject.service(),

    computedAttachments: _ember['default'].computed.map('attachments', function (attachment) {
      _ember['default'].set(attachment, 'title', this.getTitleBySource(attachment));

      return attachment;
    }),

    getTitleBySource: function getTitleBySource(attachment) {
      switch (attachment.get('source.id')) {
        case _quantuvisCmSpaConstantsFileSource['default'].RESPONSIBLE_PERSON:
          return attachment.get('owner.firstName') + ' ' + attachment.get('owner.lastName');
        case _quantuvisCmSpaConstantsFileSource['default'].OTHER_COMPANY:
          {
            var companyName = this.getCompanyName();

            return 'from ' + companyName;
          }
        default:
          return '';
      }
    },

    getCompanyName: function getCompanyName() {
      return this.get('contractDetails').getContract().get('company.name');
    },

    actions: {
      previewDocument: function previewDocument(attachment) {
        this.get('file').previewDocument(attachment, _quantuvisCmSpaConstantsAttachmentType['default'].TIMELINE_ELEMENT_ATTACHMENT, this.get('contractDetails.contractId'));
      },
      downloadDocument: function downloadDocument(attachment) {
        var _this = this;

        this.get('file').downloadDocumentVersion(_ember['default'].get(attachment, 'fileId'), this.get('contractDetails.contractId'), _ember['default'].get(attachment, 'version')).then(function () {
          _this.downloadAttachment(attachment);
        });
      }
    },

    downloadAttachment: function downloadAttachment(attachment) {
      var _this2 = this;

      this.get('file').getFileUrl(_ember['default'].get(attachment, 'key'), _ember['default'].get(attachment, 'version'), this.get('contractDetails.contractId')).then(function (response) {
        _this2.get('downloadFile').download(response.get('url'));
      })['catch'](function (error) {
        _ember['default'].Logger.error('Error occured on retrieve URL', error);
      });
    }
  });
});