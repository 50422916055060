define('quantuvis-cm-spa/core-ui/components/cm-date-picker/component', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['cm-date-picker'],

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      var incomingDate = this.get('date');

      this.set('incomingDate', incomingDate);
      this.set('newAttributes', incomingDate !== this.get('prevDate'));
    },

    willRender: function willRender() {
      this._super.apply(this, arguments);
      var datepicker = this.$('.datepicker');

      if (!datepicker) {
        return;
      }
      var incomingDate = this.get('incomingDate');
      var currentStringValue = this.$(datepicker).val();
      var shouldUpdate = incomingDate !== currentStringValue;

      if ((incomingDate || incomingDate === '') && shouldUpdate && this.$(datepicker) && this.get('newAttributes')) {
        this.setDate(incomingDate);
        this.set('newAttributes', false);
      }
    },

    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);
      this.$('.datepicker').datepicker({
        showOtherMonths: true,
        firstDay: 1,
        disabled: this.get('disabled'),
        showOn: 'button',
        buttonImage: '',
        buttonImageOnly: false,
        buttonText: '',
        onSelect: function onSelect(date) {
          _this.handleDateChange(date);
        },
        /**
         * Calculate offset of datepicker relatively to input field:
         * if datepicker is above input field - offset is negative.
         * if datepicker is below input field - offset is positive.
         */
        beforeShow: function beforeShow(textbox, instance) {
          var txtBoxOffset = _this.$(textbox).offset();
          var txtBoxOffsetTop = txtBoxOffset.top;

          setTimeout(function () {
            var pickerOffsetTop = parseInt(instance.dpDiv.css('top'), 10);
            var offsetValue = 9;
            var calculatedOffset = pickerOffsetTop > txtBoxOffsetTop ? offsetValue : -offsetValue;

            instance.dpDiv.css({
              'z-index': 9999,
              top: pickerOffsetTop + calculatedOffset
            });
          }, 0);
        }
      });

      this.set('datepicker', this.$('.datepicker'));

      var incomingDate = this.get('incomingDate');

      if (incomingDate) {
        this.setDate(incomingDate);
      }
    },

    setDate: function setDate(incomingDate) {
      if ((0, _moment['default'])(incomingDate).isValid()) {
        this.$(this.datepicker).datepicker('setDate', incomingDate);
      } else {
        this.$(this.datepicker).val(incomingDate); // for setting invalid date like '05/05/yyyy'
      }
    },

    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.$('.datepicker').datepicker('destroy');
    },

    handleDateChange: function handleDateChange(date) {
      this.sendAction('onDateChange', date);
      if (this.get('onUpdate')) {
        this.get('onUpdate')(date);
      }
      this.set('prevDate', date);
    },

    actions: {
      focusOut: function focusOut(date) {
        if (this.get('prevDate') !== date) {
          // IE11 temp fix
          if (date === 'mm/dd/yyyy') {
            if (this.get('prevDate') === '') {
              return;
            }
            this.handleDateChange('');

            return;
          }
          this.handleDateChange(date);
        }
      }
    }
  });
});