define('quantuvis-cm-spa/services/company', ['exports', 'ember'], function (exports, _ember) {
  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  exports['default'] = _ember['default'].Service.extend({
    /**
     * Extract info from company object and return array of strings
     * @param {Object} company - company object
     * @returns {String[]} - array of strings
     */
    joinCompanyData: function joinCompanyData(company) {
      if (!company) {
        return [];
      }
      var companyInfoArray = [];

      companyInfoArray.push(company.name);
      companyInfoArray.push.apply(companyInfoArray, _toConsumableArray(this.joinCompanyAddress(company.address)));
      if (company.phone) {
        companyInfoArray.push(company.phone);
      }

      return companyInfoArray;
    },

    // TODO review formatting address and check required fields
    /**
     * Extract info from address object and return array of strings
     * @param {Object} addressObj - address object
     * @returns {String[]} - array of strings
     */
    joinCompanyAddress: function joinCompanyAddress(addressObj) {
      var addressArray = [];

      if (!addressObj) {
        return addressArray;
      }

      var streetInfo = '';

      streetInfo += addressObj.streetAddress1 ? '' + addressObj.streetAddress1 : '';
      // add space separator between two Street addresses
      streetInfo += addressObj.streetAddress1 && addressObj.streetAddress2 ? ' ' : '';
      streetInfo += addressObj.streetAddress2 ? '' + addressObj.streetAddress2 : '';

      if (streetInfo) {
        addressArray.push(streetInfo);
      }

      var cityInfo = '';

      cityInfo += addressObj.city ? '' + addressObj.city : '';
      cityInfo += addressObj.city && addressObj.state ? ' ' : '';
      cityInfo += addressObj.state ? '' + addressObj.state.abbreviation : '';
      cityInfo += addressObj.zip ? ' ' + addressObj.zip : '';

      if (cityInfo) {
        addressArray.push(cityInfo);
      }

      return addressArray;
    }
  });
});