define('quantuvis-cm-spa/routes/edit-workflow-template', ['exports', 'ember', 'quantuvis-cm-spa/mixins/authenticated-route-mixin', 'quantuvis-cm-spa/constants/department-status'], function (exports, _ember, _quantuvisCmSpaMixinsAuthenticatedRouteMixin, _quantuvisCmSpaConstantsDepartmentStatus) {
  exports['default'] = _ember['default'].Route.extend(_quantuvisCmSpaMixinsAuthenticatedRouteMixin['default'], {
    model: function model(params) {
      return _ember['default'].RSVP.hash({
        departments: this.get('store').query('user-management/companies/department', {
          statusId: _quantuvisCmSpaConstantsDepartmentStatus['default'].ACTIVE_ID }),
        leads: this.get('store').findAll('user-management/users/extended'),
        template: this.get('store').findRecord('workflows/template', params.id, { reload: true })
      });
    }
  });
});