define('quantuvis-cm-spa/models/rebate-negotiations/bid', ['exports', 'ember-data', 'ember', 'moment', 'quantuvis-cm-spa/constants/date-format'], function (exports, _emberData, _ember, _moment, _quantuvisCmSpaConstantsDateFormat) {
  exports['default'] = _emberData['default'].Model.extend({
    title: _emberData['default'].attr('string'),
    contractEvergreen: _emberData['default'].attr('boolean'),
    contractStartDate: _emberData['default'].attr('unix-date'),
    contractEndDate: _emberData['default'].attr('unix-date'),
    lineOfBusiness: _emberData['default'].attr(),

    fullTitle: _ember['default'].computed('title', 'contractStartDate', 'contractEndDate', 'lineOfBusiness.name', function () {
      var startDate = (0, _moment['default'])(this.get('contractStartDate')).format(_quantuvisCmSpaConstantsDateFormat['default'].MM_DD_YYYY);
      var endDate = (0, _moment['default'])(this.get('contractEndDate')).format(_quantuvisCmSpaConstantsDateFormat['default'].MM_DD_YYYY);

      return this.get('title') + ' (' + startDate + '-' + endDate + ') - ' + this.get('lineOfBusiness.name');
    })
  });
});