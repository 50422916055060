define('quantuvis-cm-spa/models/search/contract', ['exports', 'ember', 'quantuvis-cm-spa/models/contract', 'ember-data'], function (exports, _ember, _quantuvisCmSpaModelsContract, _emberData) {
  exports['default'] = _quantuvisCmSpaModelsContract['default'].extend({
    company: _emberData['default'].attr(),
    leads: _emberData['default'].attr(),
    isWaitingForActivation: _emberData['default'].attr('boolean'),
    totalSteps: _emberData['default'].attr('number'),
    completedSteps: _emberData['default'].attr('number'),
    workflowStep: _emberData['default'].belongsTo('search/workflow-step'),
    versions: _emberData['default'].attr(),
    file: _emberData['default'].attr(),
    contracts: _emberData['default'].hasMany('search/contract', { inverse: null, async: false }),
    signOff: _emberData['default'].belongsTo('search/signoff'),

    sortedVersions: _ember['default'].computed('versions', function () {
      return this.get('versions').sort(function (v1, v2) {
        return v2.file.version - v1.file.version;
      });
    })
  });
});