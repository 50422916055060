define('quantuvis-cm-spa/components/cm-workflow-step', ['exports', 'ember', 'ember-changeset', 'ember-changeset-validations', 'moment', 'quantuvis-cm-spa/validations/workflow-step', 'quantuvis-cm-spa/constants/date-format', 'quantuvis-cm-spa/constants/tooltip-messages'], function (exports, _ember, _emberChangeset, _emberChangesetValidations, _moment, _quantuvisCmSpaValidationsWorkflowStep, _quantuvisCmSpaConstantsDateFormat, _quantuvisCmSpaConstantsTooltipMessages) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['cm-workflow-step'],
    classNameBindings: ['isNew:is-new'],
    isNew: _ember['default'].computed.not('model.id'),
    TOOLTIP_MESSAGES: _quantuvisCmSpaConstantsTooltipMessages['default'],

    hasAttachments: _ember['default'].computed('model.attachments', 'model.substeps', function () {
      var stepHasAttachments = _ember['default'].isPresent(this.get('model.attachments'));

      if (stepHasAttachments === false) {
        stepHasAttachments = this.substepsHasAttachments(this.get('model.substeps'));
      }

      return stepHasAttachments;
    }),

    collapseIconClass: _ember['default'].computed('model.isCollapsed', function () {
      return this.get('model.isCollapsed') ? 'step-expand' : 'step-minimize';
    }),

    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);
      this.get('changesetMap')['delete'](this.elementId);
    },

    init: function init() {
      this._super.apply(this, arguments);
      this.initElementID();
      this.initChangeset();
    },

    initElementID: function initElementID() {
      var id = this.get('model.id');

      if (id) {
        this.set('elementId', 'workflow-step-' + id);
      }
    },

    initChangeset: function initChangeset() {
      var model = this.get('model');

      if (this.get('expandOnErrors')) {
        model.set('expandOnInvalid', this.expandOnInvalid.bind(this));
      }

      this.prepareModelDatesProperties();
      var changeset = new _emberChangeset['default'](model, (0, _emberChangesetValidations['default'])(_quantuvisCmSpaValidationsWorkflowStep['default']), _quantuvisCmSpaValidationsWorkflowStep['default'], {
        skipValidate: true
      });

      this.set('changeset', changeset);
      this.get('changesetMap').set(this.elementId, changeset);
    },

    substepsHasAttachments: function substepsHasAttachments(substeps) {
      var substep = substeps.find(function (substep) {
        return _ember['default'].isPresent(substep.get('attachments'));
      });

      return substep !== undefined;
    },

    expandOnInvalid: function expandOnInvalid() {
      var model = this.get('model');
      var $errors = this.$('.cm-group-error:not(.cm-step-name)');

      if (model.get('isCollapsed') && $errors.length) {
        model.toggleProperty('isCollapsed');
      }
    },

    prepareModelDatesProperties: function prepareModelDatesProperties() {
      var date = this.get('model.dueDate');
      var isDateValid = (0, _moment['default'])(date).isValid();

      if (date && isDateValid) {
        this.set('model.dueDate', (0, _moment['default'])(date).format(_quantuvisCmSpaConstantsDateFormat['default'].MM_DD_YYYY));
      }
    },

    actions: {
      onCollapseStateChange: function onCollapseStateChange() {
        this.toggleProperty('model.isCollapsed');
      },
      onDelete: function onDelete(isRequired) {
        this.sendAction('onWorkflowStepDelete', {
          position: this.get('position'),
          isRequired: isRequired
        });
      },
      submit: function submit() {}
    }
  });
});