define('quantuvis-cm-spa/components/create-document/cm-general', ['exports', 'ember', 'quantuvis-cm-spa/core-ui/components/cm-wizard/cm-step/component', 'ember-changeset', 'ember-changeset-validations', 'quantuvis-cm-spa/validations/general-details', 'quantuvis-cm-spa/constants/contract-origin', 'quantuvis-cm-spa/constants/create-document-file-source', 'moment', 'quantuvis-cm-spa/constants/date-format', 'quantuvis-cm-spa/constants/permissions', 'quantuvis-cm-spa/constants/user-query-type', 'quantuvis-cm-spa/constants/error-messages', 'quantuvis-cm-spa/core-ui/components/cm-form/form-config', 'quantuvis-cm-spa/mixins/scroll-to-validation-error', 'ember-concurrency', 'quantuvis-cm-spa/constants/line-of-business'], function (exports, _ember, _quantuvisCmSpaCoreUiComponentsCmWizardCmStepComponent, _emberChangeset, _emberChangesetValidations, _quantuvisCmSpaValidationsGeneralDetails, _quantuvisCmSpaConstantsContractOrigin, _quantuvisCmSpaConstantsCreateDocumentFileSource, _moment, _quantuvisCmSpaConstantsDateFormat, _quantuvisCmSpaConstantsPermissions, _quantuvisCmSpaConstantsUserQueryType, _quantuvisCmSpaConstantsErrorMessages, _quantuvisCmSpaCoreUiComponentsCmFormFormConfig, _quantuvisCmSpaMixinsScrollToValidationError, _emberConcurrency, _quantuvisCmSpaConstantsLineOfBusiness) {
  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  exports['default'] = _quantuvisCmSpaCoreUiComponentsCmWizardCmStepComponent['default'].extend(_quantuvisCmSpaMixinsScrollToValidationError['default'], {
    CreateDocumentFileSource: _quantuvisCmSpaConstantsCreateDocumentFileSource['default'],
    store: _ember['default'].inject.service(),
    documentWizard: _ember['default'].inject.service(),
    uniqueDocumentValidator: _ember['default'].inject.service(),
    loadingSpinner: _ember['default'].inject.service(),
    dialog: _ember['default'].inject.service(),
    formConfig: _quantuvisCmSpaCoreUiComponentsCmFormFormConfig['default'],

    documentType: _ember['default'].computed.alias('documentWizard.model.type.name'),
    contractTemplateUploadMode: true,
    contractLeads: [],
    templateTypes: [],

    tooltipMessages: {
      contractName: 'Name or nickname this contract internally. Other company will not see this name',
      selectedBids: 'Link this contract to an existing bid from Quantuvis Rebate Negotiation',
      contractLead: 'Select someone who will manage the contract creation and editing process',
      startDate: 'Date that a contract will become effective',
      endDate: 'Date on which contract will end',
      evergreen: 'Agreement between two parties that automatically\n      renews the contractual provisions after the Contract End Date,\n      until a new contract is agreed upon or the contact is terminated',
      contractId: 'Unique contract number in the system'
    },

    documentOrigins: [],

    fileUploadModeRadioDisabled: _ember['default'].computed('changeset.fileKey', 'changeset.fileName', 'changeset.templateComboValue', 'changeset.contractOrigin', function () {
      return this.get('changeset.fileKey') && this.get('changeset.fileName') && this.get('changeset.templateComboValue') === _quantuvisCmSpaConstantsCreateDocumentFileSource['default'].UPLOAD || this.get('changeset.contractOrigin') === _quantuvisCmSpaConstantsContractOrigin['default'].RECEIVED_FROM_OTHER_PARTY;
    }),

    contractOriginRadioDisabled: _ember['default'].computed('documentWizard.activateMode', 'changeset.templateComboValue', function () {
      return this.get('documentWizard.activateMode') || this.get('changeset.templateComboValue') === _quantuvisCmSpaConstantsCreateDocumentFileSource['default'].SELECT;
    }),

    changedFields: new Set(),

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      this.setDataBasedOnDocumentType();
      this.resetChangedFields();
      this.initChangeset();
      this.prepopulateRequiredData();

      this.get('loadingSpinner').show('create-contract-spinner');
      var promises = [];

      promises.push(this.fetchLinesOfBusiness());
      promises.push(this.fetchLeads());
      promises.push(this.fetchBids(this.get('changeset').get('company.id')));

      Promise.all(promises).then(function () {
        return _this.get('loadingSpinner').hide('create-contract-spinner');
      })['catch'](function () {
        return _this.get('loadingSpinner').hide('create-contract-spinner');
      });
    },

    setDataBasedOnDocumentType: function setDataBasedOnDocumentType() {
      var documentType = this.get('documentType');

      this.set('documentOrigins', [{
        key: _quantuvisCmSpaConstantsContractOrigin['default'].CREATED_INTERNALLY,
        label: 'Creating New ' + documentType
      }, {
        key: _quantuvisCmSpaConstantsContractOrigin['default'].RECEIVED_FROM_OTHER_PARTY,
        label: documentType + ' received from other party'
      }]);

      this.set('templateTypes', [{
        key: _quantuvisCmSpaConstantsCreateDocumentFileSource['default'].UPLOAD,
        label: 'Upload a ' + documentType
      }, {
        key: _quantuvisCmSpaConstantsCreateDocumentFileSource['default'].SELECT,
        label: 'Select from ' + documentType + ' Templates'
      }]);
    },

    initChangeset: function initChangeset() {
      this.attachUniqueNumberValidator(_quantuvisCmSpaValidationsGeneralDetails['default']);

      var changeset = new _emberChangeset['default'](this.get('documentWizard.model'), (0, _emberChangesetValidations['default'])(_quantuvisCmSpaValidationsGeneralDetails['default']), _quantuvisCmSpaValidationsGeneralDetails['default'], {
        skipValidate: true
      });

      this.set('changeset', changeset);
    },

    attachUniqueNumberValidator: function attachUniqueNumberValidator(validators) {
      validators.contractId = [this.get('uniqueDocumentValidator').validateUniqueness(null)];
    },

    prepopulateRequiredData: function prepopulateRequiredData() {
      this.set('changeset.contractName', this.get('documentWizard.prepopulatedProperties.contractName'));
      this.set('changeset.startDate', this.get('documentWizard.prepopulatedProperties.startDate'));
      this.set('changeset.endDate', this.get('documentWizard.prepopulatedProperties.endDate'));

      if (this.get('documentWizard.prepopulatedProperties.evergreen')) {
        this.set('changeset.evergreen', this.get('documentWizard.prepopulatedProperties.evergreen') === 'true');
      }

      this.get('documentWizard').clearPrepopulatedValues('contractName', 'startDate', 'endDate', 'evergreen');
    },

    fetchLinesOfBusiness: function fetchLinesOfBusiness() {
      var _this2 = this;

      return this.get('store').findAll('contracts/line-of-business').then(function (data) {
        _this2.set('lineOfBusinessesOptions', _this2.sortLineOfBusiness(data));
        _this2.populateLineOfBusinessIfNeeded(data);
      })['catch'](function (error) {
        _ember['default'].Logger.warn('fetchLinesOfBusiness Error:', error);
      });
    },

    sortLineOfBusiness: function sortLineOfBusiness(lineOfBusiness) {
      var noLineOfBusiness = lineOfBusiness.find(function (lob) {
        return Number(lob.get('id')) === _quantuvisCmSpaConstantsLineOfBusiness['default'].NO_LINE_OF_BUSINESS;
      });

      var otherLineOfBusiness = lineOfBusiness.filter(function (lob) {
        return Number(lob.get('id')) !== _quantuvisCmSpaConstantsLineOfBusiness['default'].NO_LINE_OF_BUSINESS;
      }).sort(function (lob1, lob2) {
        return lob1.get('name').localeCompare(lob2.get('name'));
      });

      return [noLineOfBusiness].concat(_toConsumableArray(otherLineOfBusiness));
    },

    fetchLeads: function fetchLeads() {
      var _this3 = this;

      return this.get('store').query('user-management/user', {
        permissions: _quantuvisCmSpaConstantsPermissions.PERMISSION_GROUPS.LEAD,
        applicationId: _quantuvisCmSpaConstantsPermissions.PERMISSIONS.APPLICATION.CM,
        resourceId: _quantuvisCmSpaConstantsPermissions.PERMISSIONS.RESOURCE.COMPANY,
        resourceValue: this.get('documentWizard.model.company.id'),
        type: _quantuvisCmSpaConstantsUserQueryType['default'].PERMISSION
      }).then(function (data) {
        _this3.set('contractLeads', data);
        _this3.setActiveLeads(data);
      })['catch'](function (error) {
        _ember['default'].Logger.warn('fetchLeads Error:', error);
      });
    },

    searchContract: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0(term) {
      var searchRequestTimeout;
      return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            searchRequestTimeout = 600;
            context$1$0.next = 3;
            return (0, _emberConcurrency.timeout)(searchRequestTimeout);

          case 3:
            return context$1$0.abrupt('return', this.get('store').query('search/contracts/available-for-linking', {
              companyId: this.get('documentWizard.model.company.id'),
              searchText: term
            }));

          case 4:
          case 'end':
            return context$1$0.stop();
        }
      }, callee$0$0, this);
    })),

    fetchBids: function fetchBids(companyId) {
      var _this4 = this;

      return this.get('store').query('rebate-negotiations/bid', { companyId: companyId }).then(function (data) {
        _this4.set('bids', data);
        _this4.populateBidIfNeeded(data);
      })['catch'](function (error) {
        _ember['default'].Logger.warn('fetchBids Error:', error);
      });
    },

    /**
     * @todo Add tests for populateBidIfNeeded
     */
    populateBidIfNeeded: function populateBidIfNeeded(bids) {
      var prepopulatedId = this.get('documentWizard.prepopulatedProperties.bidId');

      if (prepopulatedId) {
        var matchingLines = bids.filter(function (bid) {
          return bid.id === prepopulatedId;
        });

        if (matchingLines.length > 0) {
          this.set('changeset.selectedBids', matchingLines[0]);
        }

        this.get('documentWizard').clearPrepopulatedValues('bidId');
      }
    },

    /**
     * @todo Add tests for populateLineOfBusinessIfNeeded
     */
    populateLineOfBusinessIfNeeded: function populateLineOfBusinessIfNeeded(linesOfBusiness) {
      var prepopulatedId = this.get('documentWizard.prepopulatedProperties.lineOfBusinessId');

      if (prepopulatedId) {
        var matchingLines = linesOfBusiness.filter(function (lob) {
          return lob.id === prepopulatedId;
        });

        if (matchingLines.length > 0) {
          this.set('changeset.lineOfBusiness', matchingLines[0]);
        }

        this.get('documentWizard').clearPrepopulatedValues('lineOfBusinessId');
      }
    },

    /**
     *
     * @todo Add tests for setActiveLeads method
     */
    setActiveLeads: function setActiveLeads(users) {
      var _this5 = this;

      var prepopulatedIds = this.get('documentWizard.prepopulatedProperties.leadIds');

      if (prepopulatedIds) {
        (function () {
          var leadIds = prepopulatedIds.split(',');
          var leads = users.filter(function (user) {
            return leadIds.includes(user.id);
          });

          _this5.set('changeset.contractLead', leads);

          _this5.get('documentWizard').clearPrepopulatedValues('leadIds');
        })();
      }
    },

    /**
     *
     * @todo Add tests for clearChangesetFile method
     */
    clearChangesetFile: function clearChangesetFile() {
      this.get('changeset').set('fileKey', null);
      this.get('changeset').set('fileName', null);
    },

    actions: {
      submit: function submit(model) {
        if (this.get('documentWizard.executedMode')) {
          return this.processFinishButton(model);
        }

        this.processNextButton(model);
      },
      onContractTemplateModeChange: function onContractTemplateModeChange() {
        this.clearChangesetFile();
      },
      onBidSelect: function onBidSelect(fi, value) {
        fi && fi.update(value);

        this.prepopulateDataFromSelectedBid();
      },
      onLineOfBusinessSelect: function onLineOfBusinessSelect(fi, value) {
        fi && fi.update(value);

        this.markFieldAsChanged('lineOfBusiness');
      },
      onStartDateUpdate: function onStartDateUpdate(fi, value) {
        fi && fi.update(value);

        this.markFieldAsChanged('startDate');
      },
      onEndDateUpdate: function onEndDateUpdate(fi, value) {
        fi && fi.update(value);

        this.markFieldAsChanged('endDate');
      },
      onEvergreenChange: function onEvergreenChange() {
        this.markFieldAsChanged('evergreen');
      }
    },

    processNextButton: function processNextButton(model) {
      var _this6 = this;

      var resolveFunc = this.get('resolve');

      model.save().then(function () {
        _this6.markStepAsComplete();
        resolveFunc(true);
      })['catch'](function () {
        // set server errors
      });
    },

    processFinishButton: function processFinishButton(model) {
      var _this7 = this;

      var resolveFunc = this.get('resolve');

      model.set('steps', []);
      this.get('loadingSpinner').show('create-contract-spinner');

      return model.save().then(function () {
        return _this7.saveExecutedContract(resolveFunc);
      })['catch'](function () {
        _this7.get('loadingSpinner').hide('create-contract-spinner');
        var options = {
          errorMessage: _quantuvisCmSpaConstantsErrorMessages.DEFAULT_ERROR_MESSAGE
        };

        _this7.get('dialog').show('dialogs/cm-error', null, _this7, options);
      });
    },

    prepopulateDataFromSelectedBid: function prepopulateDataFromSelectedBid() {
      var selectedBid = this.get('changeset.selectedBids');

      if (selectedBid) {
        if (!this.isChanged('lineOfBusiness')) {
          this.set('changeset.lineOfBusiness', selectedBid.get('lineOfBusiness'));
        }
        if (!this.isChanged('startDate')) {
          this.set('changeset.startDate', _moment['default'].utc(selectedBid.get('contractStartDate')).format(_quantuvisCmSpaConstantsDateFormat['default'].MM_DD_YYYY));
        }
        if (!this.isChanged('endDate')) {
          this.set('changeset.endDate', _moment['default'].utc(selectedBid.get('contractEndDate')).format(_quantuvisCmSpaConstantsDateFormat['default'].MM_DD_YYYY));
        }
        if (!this.isChanged('evergreen')) {
          this.set('changeset.evergreen', selectedBid.get('contractEvergreen'));
        }
      }
    },

    resetChangedFields: function resetChangedFields() {
      this.changedFields.clear();
    },

    markFieldAsChanged: function markFieldAsChanged(fieldName) {
      this.changedFields.add(fieldName);
    },

    isChanged: function isChanged(fieldName) {
      return this.changedFields.has(fieldName);
    },

    back: function back() {
      var validChanges = this.get('changeset.changes');
      var model = this.get('documentWizard.model');

      validChanges.forEach(function (change) {
        model[change.key] = change.value;
      });
      this._super.apply(this, arguments);
    },

    next: function next(resolve) {
      if (!this.get('resolve')) {
        this.set('resolve', resolve);
      }
      this.$('#general-data-form').submit();
      this.scrollToValidationError();
    },

    finish: function finish(resolve) {
      this.set('resolve', resolve);
      this.$('#general-data-form').submit();
    },

    saveExecutedContract: function saveExecutedContract(resolveFunc) {
      var _this8 = this;

      return this.get('documentWizard').saveContract().then(function (contract) {
        _this8.get('loadingSpinner').hide('create-contract-spinner');
        resolveFunc(contract);
      });
    },

    uniqueDocumentValidatorObserve: _ember['default'].observer('uniqueDocumentValidator.isLoading', function (self) {
      var isLoading = self.get('uniqueDocumentValidator').get('isLoading');

      if (isLoading) {
        self.get('loadingSpinner').show('create-contract-spinner');
      } else {
        self.get('loadingSpinner').hide('create-contract-spinner');
      }
    })
  });
});