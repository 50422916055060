define('quantuvis-cm-spa/controllers/document-summary', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    dialog: _ember['default'].inject.service(),
    editSummary: _ember['default'].inject.service(),
    contractDetails: _ember['default'].inject.service(),

    actions: {
      handleCancelClick: function handleCancelClick() {
        var isChangesetDirty = this.get('editSummary').isModelDirty();

        if (isChangesetDirty) {
          this.showConfirmationDialog();
        } else {
          this.get('store').unloadRecord(this.get('model.contract'));
          this.moveToDetails();
        }
      },
      handleSaveClick: function handleSaveClick() {
        this.moveToDetails();
      }
    },
    showConfirmationDialog: function showConfirmationDialog() {
      var _this = this;

      var options = {
        confirmMessage: 'You have not saved your changes yet.\n\n            All the data entered will be lost.\n\n            Are you sure you want to navigate away?'
      };

      this.get('dialog').show('dialogs/cm-dialog', 'dialog-templates/cm-confirmation', this, options).then(function () {
        _this.get('store').unloadRecord(_this.get('model.contract'));
        _this.moveToDetails();
      })['catch']({});
    },
    moveToDetails: function moveToDetails() {
      var _this2 = this;

      this.transitionToRoute('contract-details', this.get('model.contract.id')).then(function () {
        return _this2.get('editSummary').reset();
      });
    }
  });
});