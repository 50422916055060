define('quantuvis-cm-spa/components/cm-workflow-step/workflow-step-type-input', ['exports', 'ember', 'quantuvis-cm-spa/constants/tooltip-messages'], function (exports, _ember, _quantuvisCmSpaConstantsTooltipMessages) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['cm-workflow-step-type-input'],
    TOOLTIP_MESSAGES: _quantuvisCmSpaConstantsTooltipMessages['default'],
    changeWorkflowStepType: function changeWorkflowStepType(control, stepType) {
      this.sendAction('onchange', {
        control: control,
        value: stepType
      });
    }
  });
});