define('quantuvis-cm-spa/routes/business-admin/workflow-templates', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      return this.get('store').query('workflows/template', {}).then(function (templates) {
        return templates.filter(function (template) {
          return !template.get('isSystem');
        });
      });
    },

    actions: {
      refreshModel: function refreshModel() {
        this.refresh();
      }
    }
  });
});